import React, { Children, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, Typography } from '@material-ui/core';
import { DetailsTab } from '..';
import { useSelectedTheme, useTranslate } from '../../../../../Hooks';

// styles
import useStyles from './styles';
// import { CustomButton } from '../../../CustomButton';
import {
  CustomButton,
  CustomIconButton,
  CustomInput,
  CustomPopover,
} from '../../../..';
import {
  CloseXIcon,
  DropdownIcon,
  WhatsappIcon,
} from '../../../../../assets/icons';

const a11yProps = (index) => ({
  'aria-controls': `simple-tabpanel-${index}`,
  id: `Property-tab-${index}`,
});

const TabPanel = ({ children, index, value, ...other }) => {
  return (
    <div
      aria-labelledby={`Property-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role='tabpanel'
      style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 578px)' }}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

function PropertySideMenuTabs({
  handleTabChange,
  tabValue,
  activeItem,
  expandMenu,
  landmarksKeys,
  setCategory,
  radius,
  setRadius,
}) {
  const styles = useStyles();
  const { translate } = useTranslate('NewContactsView');
  const [anchorRef, setAnchorRef] = useState(null);
  const [localRadius, setLocalRadius] = useState(radius);
  const handleClick = (e) => {
    if (anchorRef) setAnchorRef(null);
    else setAnchorRef(e.target);
  };
  const {
    theme: { palette },
  } = useSelectedTheme();
  const popoverCloseHandler = () => {
    setAnchorRef(null);
  };
  useEffect(() => {
    if (localRadius !== radius) {
      setLocalRadius(radius);
    }
  }, [radius]);
  return (
    <>
      <Tabs
        TabIndicatorProps={{ style: { display: 'none' } }}
        aria-label='PropertyDetailsSideMenuTabs'
        className={styles.tabs}
        onChange={handleTabChange}
        scrollButtons={'off'}
        value={tabValue}
        variant='standard'
        classes={{ flexContainer: styles.flexContainer }}
      >
        <Tab
          className={styles.tabButton}
          disableRipple
          classes={{ selected: styles.tab }}
          label={
            <div className={styles.tabButtonContainer}>
              <span className={clsx(styles.tabLabel, styles.font14)}>
                {translate('DETAILS')}
              </span>
            </div>
          }
          {...a11yProps(0)}
        />
        <Tab
          className={styles.tabButton}
          disableRipple
          classes={{ selected: styles.tab }}
          label={
            <div className={styles.tabButtonContainer}>
              <span className={clsx(styles.tabLabel, styles.font14)}>
                {translate('Unit models')}
              </span>
            </div>
          }
          {...a11yProps(0)}
        />
      </Tabs>
      <TabPanel index={0} value={tabValue}>
        <DetailsTab activeItem={activeItem} />
      </TabPanel>

      <TabPanel index={1} value={tabValue}>
        <></>
      </TabPanel>
      <Box className={clsx(styles.nearbyBox)}>
        <Box className={styles.nearbyBoxTitle}>
          <Typography className={styles.nearbyTitle}>Near By</Typography>
          <Box>
            <CustomButton
              onClick={handleClick}
              size={'md'}
              boxShadow={'xs'}
              color={'secondaryGray'}
              variant={'outlined'}
              endIcon={
                <DropdownIcon
                  width={20}
                  height={20}
                  fill={palette.button.secondary_fg}
                />
              }
              // className={styles.radiusButton}
            >
              Radius: {radius} Km
            </CustomButton>
            <CustomPopover
              popoverClasses={styles.popover}
              customElevation={0}
              attachedWith={anchorRef}
              handleClose={popoverCloseHandler}
              component={
                <Box className={styles.popoverBox}>
                  <Box className='box-header'>
                    <Typography>Radius</Typography>

                    <CustomIconButton
                      variant='text'
                      size='xs'
                      boxShadow='none'
                      color='tertiaryColor'
                      hideHoverBg
                      onClick={popoverCloseHandler}
                    >
                      <CloseXIcon
                        width='20'
                        height='20'
                        fill={palette.button.tertiaryColor_fg}
                      />
                    </CustomIconButton>
                  </Box>
                  <CustomInput
                    placeholder='Km  Enter'
                    hasSearchIcon={false}
                    value={localRadius}
                    onChange={(e) => setLocalRadius(e.target.valueAsNumber)}
                    type='number'
                  />
                  <Box className='box-footer'>
                    <CustomButton
                      size={'lg'}
                      color={'secondary-gray'}
                      variant={'outlined'}
                      onClick={() => {
                        popoverCloseHandler();
                        setLocalRadius(radius);
                      }}
                    >
                      Cancel
                    </CustomButton>
                    <CustomButton
                      size={'lg'}
                      onClick={() => {
                        popoverCloseHandler();
                        setRadius(localRadius);
                      }}
                    >
                      Confirm
                    </CustomButton>
                  </Box>
                </Box>
              }
            />
          </Box>
        </Box>
        <Box className={styles.nearbyButtonsBox}>
          {Children.toArray(
            landmarksKeys.map(({ key, icon }) => (
              <CustomButton
                className={styles.landmarkButton}
                onClick={() => {
                  expandMenu();
                  setCategory(key);
                }}
                boxShadow={'xs'}
                size={'sm'}
              >
                <img src={icon} alt={key} width={20} height={20} />
                <span>{key}</span>
              </CustomButton>
            ))
          )}
        </Box>
      </Box>
    </>
  );
}

PropertySideMenuTabs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  activeItem: PropTypes.objectOf(PropTypes.any),
  handleTabChange: PropTypes.func,
  expandMenu: PropTypes.func,
  tabValue: PropTypes.number,
};

PropertySideMenuTabs.defaultProps = {
  handleTabChange: () => {},
  expandMenu: () => {},
  tabValue: 0,
  activeItem: {},
};

export default PropertySideMenuTabs;
