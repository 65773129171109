import React, { useEffect, useState } from 'react';
import { Button, ButtonBase, Popover } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import {
  ContactFormTypeEnum,
  ContactTypeEnum,
  QuickAddEnum,
} from '../../../../../../Enums';
import { PopoverComponent } from '../../../../../../Components';
import { People, Person } from '@material-ui/icons';

export const QuickAddPopoverComponent = ({
  parentTranslationPath,
  translationPath,
  setDialogSelect,
}) => {
  const { t } = useTranslation([parentTranslationPath]);
  const [anchorEl, setAnchorEl] = useState(null);
  const quickAddHandler = (key) => (e) => {
    if (key === QuickAddEnum.Contact.key) {
      setAnchorEl(e.currentTarget);
      return;
    }
    setDialogSelect(key);
  };

  const [quickAddListWithPermissions, setQuickAddListWithPermissions] =
    useState([]);
  useEffect(() => {
    const quickAdd = Object.values(QuickAddEnum);
    let userPermissions = localStorage.getItem('session');
    if (userPermissions) {
      userPermissions = JSON.parse(localStorage.getItem('session')).permissions;
      var res = quickAdd.filter((item1) =>
        userPermissions.some(
          (item2) => item2.permissionsId === item1.permissionsId
        )
      );
      setQuickAddListWithPermissions(res);
    } else {
      setQuickAddListWithPermissions([]);
    }
  }, []);
  const handleTypeClick = (type) =>{
    setDialogSelect(QuickAddEnum.Contact.key, type)
    setAnchorEl(null);
  }
  return (
    // <div></div>
    <div className='quick-add-popover-wrapper'>
      {quickAddListWithPermissions &&
        quickAddListWithPermissions.map((item, index) => (
          <div
            key={`quickAddRef${index + 1}`}
            className='quick-add-item-wrapper'
          >
            <ButtonBase
              id={item.id}
              className='btns quick-add-button'
              onClick={quickAddHandler(item.key)}
            >
              <span className={item.icon} />
            </ButtonBase>
            <label htmlFor={item.id} className='quick-add-text'>
              {t(`${translationPath}${item.value}`)}
            </label>
          </div>
        ))}
      <PopoverComponent
        attachedWith={anchorEl}
        handleClose={() => setAnchorEl(null)}
        style={{ zIndex: 9999 }}
        component={
          <div className='d-flex gap-4'>
            <Button
              className='capitalize'
              size='large'
              color='primary'
              onClick={() =>
                handleTypeClick(ContactFormTypeEnum.INDIVIDUAL.value)
              }
            >
              <Person fontSize='small' className='mx-1' />
              individual
            </Button>
            <Button
              className='capitalize'
              size='large'
              color='primary'
              onClick={() =>
                handleTypeClick(ContactFormTypeEnum.CORPORATE.value)
              }
            >
              <People fontSize='small' className='mx-1' />
              corporate
            </Button>
          </div>
        }
      />
    </div>
  );
};
QuickAddPopoverComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setDialogSelect: PropTypes.func.isRequired,
};
