import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    gridContainer: {
      display: 'flex',
      flex: '1 1 100%',
      flexWrap: 'wrap',
      gap: '18px',
      justifyContent: "center",
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    divider: {
      backgroundColor: theme.palette.border.secondary,
    },
  };
});
