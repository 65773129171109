import React from "react";
import { FeaturesOverviewComponent } from "../";
import { Box, Typography } from "@material-ui/core";
import { useTranslate } from "../../../../../../../../Hooks";
import { CustomButton } from "../../../../../../../../Components";

// styles
import useStyle from "./styles";

function AmenitiesFacilitiesComponent({ setTabValue, propretyResponseJson }) {
  const styles = useStyle();
  const { translate } = useTranslate("NewPropertiesView");

  return (
    <Box className={styles.wrapperAmenitiesFacilitiesComponent}>
      <Box className={styles.containerHeader}>
        <Typography className={styles.containerMainTitle}>
          {translate("AmenitiesAndfacilities")}
        </Typography>
        <CustomButton
          boxShadow="none"
          size="sm"
          variant="text"
          color="tertiaryGray"
          style={{ minWidth: "fit-content" }}
          onClick={() => {
            setTabValue(8);
          }}
        >
          {translate("View-All")}
        </CustomButton>
      </Box>
      <Box className={styles.cardWapper}>
        <FeaturesOverviewComponent
          title={"Amenities"}
          data={propretyResponseJson?.property?.amenities || []}
        />
        <FeaturesOverviewComponent
          title={"Facilities"}
          data={propretyResponseJson?.property?.facilities || []}
        />
      </Box>
    </Box>
  );
}
export default AmenitiesFacilitiesComponent;
