import { formatStandardDate, formatStandardTimezoneDate } from "../formatDate";

const coverDetailsDtoMapper = (properties) => {

  const firstProperty = properties[0];
  if (!firstProperty) return {};

  const cover = firstProperty.submittedForm?.CoverPage || {};
  const data = firstProperty.submittedForm?.CoverPage?.data || {};

  return {
    coverImage: cover.coverImage?.url,
    coverPageDetails: {
      propertyName: data.propertyName || null,
      completionDate: formatStandardDate(data.completionDate),
      proposalDate: formatStandardTimezoneDate(data.proposalDate),
      developerName: data.developerName || null,
      developerLogo: data.developerLogo || null,
      propertyLocation: data.propertyLocation || null,
      agentContactDetails: data.agentContactDetails || null,
      companyContactDetails: data.companyContactDetails || null,
      branchDetails: data.branchDetails || null,
      showLogo: cover.showLogo || false,
    },
  };
};

export default coverDetailsDtoMapper;
