import React, {
  useState,
  useCallback,
  useReducer,
  useEffect,
  useRef,
} from 'react';
import moment from 'moment';
import Joi from 'joi';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
  Tab,
  Tabs,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess, GetParams, getErrorByName } from '../../../Helper';
import {
  Spinner,
  Inputs,
  DatePickerComponent,
  AutocompleteComponent,
  SelectComponet,
} from '../../../Components';
import {
  CreatePropertyCampaign,
  UpdatePropertyCampaign,
  lookupItemsGetId,
  lookupItemsGet,
  GetAllBranches,
  MarkCampaignRequestAsComplete,
  GetLookupItemsByLookupTypeName,
} from '../../../Services';
import { ApplicationUserSearch } from '../../../Services/userServices';
import { StaticLookupsIds } from '../../../assets/json/StaticLookupsIds';
import { GetAllCampaignTeamMembers } from '../../../Services';

export const CampaignMarketingDialog = ({
  parentTranslationPath,
  translationPath,
  activeItem,
  isDialogOpen,
  onClose,
  onSave,
  withMarkCampaignAsCompleted,
  marketingCampaignsId,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  console.log('parentTranslationPath: ', parentTranslationPath);
  const searchTimer = useRef(null);
  const useStyles = makeStyles((theme) => ({
    root: {
      padding: '0px',
      paddingBottom: '5px',
      textTransform: 'initial',
    },
    indicator: {
      backgroundColor: 'var(--c-primary)',
    },
    labelIcon: {
      minHeight: 'unset',
    },
    wrapper: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'row',
    },
    fixed: {
      width: 'fit-content',
      margin: 'auto',
    },
    scroller: {
      flex: 'unset',
    },
  }));
  const classes = useStyles();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [allBranches, setAllBranches] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [state, setState] = useReducer(reducer, {
    name: null,
    campaignStartDate: moment().add(2, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
    campaignEndDate: moment().add(2, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
    campaignLanguages: [],
    mediaTypeId: null,
    mediaUrl: null,
    campaignManagerId: null,
    mediaDetailsId: null,
    campaignStatus: true,
    campaignTypeIds: [],
    budgetAmount: 0,
    spentAmount: 0,
    budgetLevel: 0,
    budgetLevelAdminIds: [],
    expectedNumberOfLeads: 0,
    campaignBranches: [],
    eventTypeId: null,
    countryId: null,
    cityId: null,
    propertyId: null,
    budgetAmount: null,
    dailyBudget: 0,
    unitSaleTypeId: null,
  });
  const [selected, setSelected] = useReducer(reducer, {
    campaignLanguages: [],
    mediaTypeId: null,
    campaignManagerId: null,
    mediaDetailsId: null,
    campaignTypes: [],
    budgetLevelAdmins: [],
    campaignBranches: [],
    eventTypeSelected: null,
    countrySelected: null,
    citySelected: null,
  });
  const [isLoading, setIsLoading] = useReducer(reducer, {
    campaignLanguages: false,
    mediaTypes: false,
    mediaDetails: false,
    users: false,
    campaignTypes: false,
    branches: false,
    eventTypeLoading: false,
    countryLoading: false,
    cityLoading: false,
    saleTypes: false
  });
  const [data, setData] = useReducer(reducer, {
    campaignLanguages: [],
    mediaTypes: [],
    mediaDetails: [],
    users: [],
    campaignTypes: [],
    eventTypeData: [],
    countryData: [],
    cityData: [],
    teamMembers: [],
    unitSaleTypes: [],
  });
  const [tabIndex, setTabIndex] = useState(0);

  const schema = Joi.object({
    campaignStartDate: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}campaign-start-date-is-required`),
        'state.required': t(
          `${translationPath}campaign-start-date-is-required`
        ),
      }),
    campaignTypeIds: Joi.array()
      .required()
      .min(1)
      .messages({
        'array.base': t(`${translationPath}campaign-type-is-required`),
        'array.empty': t(`${translationPath}campaign-type-is-required`),
        'array.min': t(`${translationPath}campaign-type-is-required`),
        'state.required': t(`${translationPath}campaign-type-is-required`),
      }),
    campaignManagerId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}campaign-Manager-is-required`),
        'state.required': t(`${translationPath}campaign-Manager-is-required`),
      }),
    budgetLevel: Joi.number()
      .custom((value, helpers) => {
        if (state.budgetLevel > state.budgetAmount)
          return helpers.error('budget-level-value');
        return value;
      })
      .messages({
        'budget-level-value': t(
          `${translationPath}budget-level-should-be-less-or-equal-than-budget-amount`
        ),
      }),
      unitSaleTypeId : Joi.number().required()  .messages({
        'number.base': t(`unit-sale-type-is-required`),
        'state.required': t(`unit-sale-type-is-required`),
      }),
    budgetAmount: Joi.number(),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const getCampaignTypes = async () => {
    setIsLoading({ id: 'campaignTypes', value: true });

    const res = await lookupItemsGet({
      lookupTypeName: 'CampaignType',
      pageIndex: 1,
      pageSize: 50,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: 'campaignTypes',
        value: res.result,
      });
    } else {
      setData({
        id: 'campaignTypes',
        value: [],
      });
    }
    setIsLoading({ id: 'campaignTypes', value: false });
  };

  const getLanguages = async () => {
    setIsLoading({ id: 'campaignLanguages', value: true });

    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Languages,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: 'campaignLanguages',
        value: res,
      });
    } else {
      setData({
        id: 'campaignLanguages',
        value: [],
      });
    }
    setIsLoading({ id: 'campaignLanguages', value: false });
  };

  const getAllEventsTypes = async () => {
    setIsLoading({ id: 'eventTypeLoading', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'EventType',
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'eventTypeData', value: res.result || [] });
    } else setData({ id: 'eventTypeData', value: [] });
    setIsLoading({ id: 'eventTypeLoading', value: false });
  };

  const getAllBranches = useCallback(async () => {
    setIsLoading({ id: 'branches', value: true });
    const res = await GetAllBranches({ pageSize: 25, pageIndex: 1 });
    if (!(res && res.status && res.status !== 200)) setAllBranches(res.result);
    else setAllBranches([]);
    setIsLoading({ id: 'branches', value: false });
  }, []);

  const getAllmediaTypes = useCallback(async () => {
    setIsLoading({ id: 'mediaTypes', value: true });
    const res = await lookupItemsGetId({ lookupTypeId: 8 });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'mediaTypes', value: res });
    } else {
      setData({ id: 'mediaTypes', value: [] });
    }
    setIsLoading({ id: 'mediaTypes', value: false });
  }, []);

  const getAllMediaDetails = useCallback(async (mediaTypeId) => {
    setIsLoading({ id: 'mediaDetails', value: true });
    const res = await lookupItemsGetId({
      lookupTypeId: 1240,
      lookupParentId: mediaTypeId,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'mediaDetails', value: res });
    } else {
      setData({ id: 'mediaDetails', value: [] });
    }
    setIsLoading({ id: 'mediaDetails', value: false });
  }, []);

  const getAllUsers = useCallback(async (searchValue) => {
    setIsLoading({ id: 'users', value: true });
    const res = await ApplicationUserSearch({
      pageIndex: 0,
      pageSize: 25,
      name: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({
          id: 'users',
          value: localValue,
        });
      } else {
        setData({
          id: 'users',
          value: [],
        });
      }

      setIsLoading({ id: 'users', value: false });
    }
  }, []);

  const getAllCountries = async () => {
    setIsLoading({ id: 'countryLoading', value: true });

    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Country,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'countryData', value: res });
    else setData({ id: 'countryData', value: [] });

    setIsLoading({ id: 'countryLoading', value: false });
  };

  const getAllCities = async () => {
    setIsLoading({ id: 'cityLoading', value: true });

    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Cities,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'cityData', value: res });
    else setData({ id: 'cityData', value: [] });

    setIsLoading({ id: 'cityLoading', value: false });
  };

  const saveHandler = async (event) => {
    setIsSubmitted(true)
    event.preventDefault();
    if (schema?.error?.message) {
      showError(schema.error.message);
      return;
    }
    setIsSubmitLoading(true);
    const body = { ...state };
    let res;
    if (activeItem && !withMarkCampaignAsCompleted) {
      res = await UpdatePropertyCampaign({
        campaignId: activeItem.propertyCampaignId,
        body,
      });
    } else if (withMarkCampaignAsCompleted) {
      res = await MarkCampaignRequestAsComplete(
        { id: marketingCampaignsId || activeItem?.propertyCampaignId },
        body
      );
    } else {
      res = await CreatePropertyCampaign(body);
    }
    setIsSubmitLoading(false);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(
        t(
          `${translationPath}${
            (activeItem && `campaign-updated-successfully`) ||
            `campaign-created-successfully`
          }`
        )
      );
      if (onSave) onSave();
    } else {
      showError(
        t(
          `${translationPath}${
            (activeItem && `campaign-updated-failed`) ||
            `campaign-created-failed`
          }`
        )
      );
    }
  };

  const getTeamMembers = async () => {
    if (marketingCampaignsId || activeItem?.propertyCampaignId) {
      const res = await GetAllCampaignTeamMembers({
        requestId: marketingCampaignsId || activeItem?.propertyCampaignId,
      });
      if (!(res && res.status && res.status !== 200)) {
        setData({ id: 'teamMembers', value: res });
      }
    }
  };

  const handleTabClick = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleMediaDetailsValue = (mediaTypeId) => {
    getAllmediaTypes();
    setSelected({ id: 'mediaDetailsId', value: null });
    setState({ id: 'mediaDetailsId', value: null });

    if (mediaTypeId) getAllMediaDetails(mediaTypeId);
    else getAllMediaDetails();
  };

  const handleMediaTypeValue = (mediaDetailsItem) => {
    if (mediaDetailsItem) {
      const mediaTypeItem = {
        lookupItemId: mediaDetailsItem.parentLookupItemId,
        lookupItemName: mediaDetailsItem.parentLookupItemName,
      };
      setSelected({ id: 'mediaTypeId', value: mediaTypeItem });
      setState({ id: 'mediaTypeId', value: mediaTypeItem.lookupItemId });

      setData({ id: 'mediaTypes', value: [mediaTypeItem] });
      getAllMediaDetails(mediaTypeItem.lookupItemId);
    } else {
      getAllmediaTypes();
    }
  };

  const getUnitSaleTypes = async () => {
    setIsLoading({ id: 'saleTypes', value: true });
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'UnitSaleType',
      pageSize: 100,
      pageIndex: 1,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'unitSaleTypes', value: res?.result || [] });
    } else setData({ id: 'unitSaleTypes', value: [] });
    setIsLoading({ id: 'saleTypes', value: false });
  };

  useEffect(() => {
    if (activeItem && activeItem.mediaTypeId && activeItem.mediaDetailsId) {
      getAllMediaDetails(activeItem.mediaTypeId);

      const mediaTypeItem = {
        lookupItemId: activeItem.mediaTypeId,
        lookupItemName: activeItem.mediaTypeName,
      };
      setSelected({ id: 'mediaTypeId', value: mediaTypeItem });
      setState({ id: 'mediaTypeId', value: mediaTypeItem.lookupItemId });
      setData({ id: 'mediaTypes', value: [mediaTypeItem] });

      const mediaDetailsItem = {
        lookupItemId: activeItem.mediaDetailsId,
        lookupItemName: activeItem.mediaDetailsName,
      };
      setSelected({ id: 'mediaDetailsId', value: mediaDetailsItem });
      setState({ id: 'mediaDetailsId', value: mediaDetailsItem.lookupItemId });
    }
    if (activeItem && activeItem.mediaTypeId) {
      getAllMediaDetails(activeItem.mediaTypeId);

      const mediaTypeItem = {
        lookupItemId: activeItem.mediaTypeId,
        lookupItemName: activeItem.mediaTypeName,
      };
      setSelected({ id: 'mediaTypeId', value: mediaTypeItem });
      setState({ id: 'mediaTypeId', value: mediaTypeItem.lookupItemId });
      setData({ id: 'mediaTypes', value: [mediaTypeItem] });
    } else {
      getAllmediaTypes();
      getAllMediaDetails();
    }
  }, [activeItem]);

  useEffect(() => {
    if (activeItem) {
      let stateCampaignLanguageIds = [];
      if (activeItem.propertyCampaignLanguages) {
        stateCampaignLanguageIds = activeItem.propertyCampaignLanguages.map(
          (item) => item.languageId
        );
      } else if (activeItem.campaignLanguage) {
        stateCampaignLanguageIds = activeItem.campaignLanguage?.map(
          ({ lookupItemId }) => lookupItemId
        );
      }

      const stateBudgetLevelAdminsIds =
        activeItem.campaignBudgetLevelAdminDtos?.map((item) => item.userId);

      const stateCampaignTypeIds = activeItem.campaignTypeChoicesDtos?.map(
        (item) => item.campaignTypeId
      );

      const statePropertyCampaignBranchesIds =
        activeItem.propertyCampaignBranches?.map((item) => item.branchId);

      setState({
        id: 'edit',
        value: {
          name: activeItem.name,
          campaignStartDate: moment(activeItem.campaignStartDate)
            .add(2, 'minutes')
            .format('YYYY-MM-DDTHH:mm:ss'),
          campaignEndDate: moment(activeItem.campaignEndDate)
            .add(2, 'minutes')
            .format('YYYY-MM-DDTHH:mm:ss'),
          campaignLanguages: stateCampaignLanguageIds,
          mediaTypeId: activeItem.mediaTypeId || null,
          mediaUrl: activeItem.mediaUrl,
          campaignManagerId: activeItem.campaignManagerId,
          cityId: activeItem.cityId || null,
          mediaDetailsId: activeItem.mediaDetailsId,
          campaignStatus: activeItem.campaignStatus,
          campaignBranches: statePropertyCampaignBranchesIds || [],
          campaignTypeIds: stateCampaignTypeIds || [],
          budgetAmount:
            activeItem.budgetAmount ?? activeItem.campaignBudget ?? 0,
          spentAmount: activeItem.spentAmount || 0,
          budgetLevel: activeItem.budgetLevel || 0,
          budgetLevelAdminIds: stateBudgetLevelAdminsIds || [],
          expectedNumberOfLeads: activeItem.expectedNumberOfLeads || 0,
          actualNumberOfLeads: activeItem.actualNumberOfLeads || 0,
          isCampaignRequestId: activeItem.isCampaignRequestId,
          departmentName: activeItem.departmentName,
          departmentId: activeItem.departmentId,
          eventTypeId: activeItem.eventTypeId ?? null,
          countryId: activeItem.countryId ?? null,
          dailyBudget: activeItem.dailyBudget,
          unitSaleTypeId :activeItem.unitSaleTypeId,
          propertyId:
            activeItem && activeItem.propertyId ? activeItem.propertyId : null,
          budgetAmount: activeItem.budgetAmount,
        },
      });
    }
  }, [activeItem]);

  useEffect(() => {
    if (activeItem && !withMarkCampaignAsCompleted) {
      const selectedCampaignLanguages =
        activeItem.propertyCampaignLanguages?.map((item) => ({
          lookupItemId: item.languageId,
          lookupItemName: item.languageName,
        }));
      setSelected({
        id: 'campaignLanguages',
        value: selectedCampaignLanguages,
      });
      const selectedBudgetLevelAdmins =
        activeItem.campaignBudgetLevelAdminDtos?.map((item) => ({
          applicationUserId: item.userId,
          fullName: item.userName,
        }));
      setSelected({
        id: 'budgetLevelAdmins',
        value: selectedBudgetLevelAdmins,
      });

      const selectedCampaignTypes = activeItem.campaignTypeChoicesDtos?.map(
        (item) => ({
          lookupItemId: item.campaignTypeId,
          lookupItemName: item.campaignTypeName,
        })
      );
      setSelected({ id: 'campaignTypes', value: selectedCampaignTypes });

      setSelected({
        id: 'campaignBranches',
        value: activeItem.propertyCampaignBranches,
      });

      if (!selected.campaignManagerId && activeItem.campaignManagerId) {
        const campaignManagerItem = {
          applicationUserId: activeItem.campaignManagerId,
          fullName: activeItem.campaignManagerName,
        };
        setSelected({ id: 'campaignManagerId', value: campaignManagerItem });
      }
      if (state.eventTypeId) {
        const selectedEvent = data.eventTypeData.find(
          (event) => event.lookupItemId === state.eventTypeId
        );
        setSelected({ id: 'eventTypeSelected', value: selectedEvent });
      }
      if (state.cityId) {
        const selectedCity = data.cityData.find(
          (city) => city.lookupItemId === state.cityId
        );
        setSelected({ id: 'citySelected', value: selectedCity });
      }
      if (state.countryId) {
        const country = data.countryData.find(
          (c) => c.lookupItemId === state.countryId
        );
        setSelected({ id: 'countrySelected', value: country });
      }
    } else if (activeItem && withMarkCampaignAsCompleted) {
      setState({ id: '' });
      const selectedCampaignLanguages = activeItem.campaignLanguage?.map(
        ({ lookupItemId, lookupItemName }) => ({
          lookupItemId,
          lookupItemName,
        })
      );
      setSelected({
        id: 'campaignLanguages',
        value: selectedCampaignLanguages,
      });

      const selectedBudgetLevelAdmins =
        activeItem.campaignBudgetLevelAdminDtos?.map((item) => ({
          applicationUserId: item.userId,
          fullName: item.userName,
        }));
      setSelected({
        id: 'budgetLevelAdmins',
        value: selectedBudgetLevelAdmins,
      });

      setSelected({
        id: 'campaignBranches',
        value: activeItem?.propertyCampaignBranches,
      });

      if (!selected.campaignManagerId && data.teamMembers.length > 0) {
        const campaignManager =
          data.teamMembers?.find(
            (member) =>
              member?.campaignTaskUserType?.toLowerCase() === 'campaign manager'
          ) ?? activeItem.marketingApprovalUser;
        setSelected({
          id: 'campaignManagerId',
          value: {
            fullName: campaignManager?.name,
            id: campaignManager?.userId,
          },
        });
        setState({ id: 'campaignManagerId', value: campaignManager?.userId });
      }
    }
  }, [activeItem, data]);

  useEffect(() => {
    if (
      withMarkCampaignAsCompleted &&
      activeItem?.campaignLength &&
      state.campaignStartDate
    ) {
      setState({
        id: 'campaignEndDate',
        value: moment(state.campaignStartDate)
          .add(activeItem?.campaignLength, 'day')
          .format('YYYY-MM-DDTHH:mm:ss'),
      });
    }
  }, [state.campaignStartDate]);

  useEffect(() => {
    getAllEventsTypes();
    getLanguages();
    getCampaignTypes();
    getAllUsers();
    getAllBranches();
    getAllCountries();
    getAllCities();
    getTeamMembers();
    getUnitSaleTypes();
  }, []);

  return (
    <div>
      <Dialog
        open={isDialogOpen}
        onClose={onClose}
        className='activities-management-dialog-wrapper'
      >
        <Spinner
          isActive={isSubmitLoading}
          isAbsolute
        />
        <form
          noValidate
          onSubmit={saveHandler}
        >
          <DialogTitle id='alert-dialog-slide-title'>
            {t(
              `${translationPath}${
                (activeItem &&
                  !withMarkCampaignAsCompleted &&
                  'edit-campaign') ||
                'add-new-campaign'
              }`
            )}
          </DialogTitle>
          <DialogContent>
            <>
              <Tabs
                value={tabIndex}
                onChange={handleTabClick}
                aria-label='icon label tabs'
                textColor='primary'
                classes={{
                  indicator: classes.indicator,
                  scroller: classes.scroller,
                  fixed: classes.fixed,
                }}
              >
                <Tab
                  classes={{
                    root: classes.root,
                    wrapper: classes.wrapper,
                    labelIcon: classes.labelIcon,
                  }}
                  icon={<span className='mr-2 mdi mdi-format-list-checks ' />}
                  label={t(`${translationPath}Details`)}
                />
                <Tab
                  classes={{
                    root: classes.root,
                    wrapper: classes.wrapper,
                    labelIcon: classes.labelIcon,
                  }}
                  icon={<span className='mr-2 mdi mdi-currency-usd' />}
                  label={t(`${translationPath}Budget&Leads`)}
                />
              </Tabs>
              {tabIndex === 0 && (
                <div>
                  <div className='d-flex'>
                    <div className='w-50 mx-2'>
                      <div className='my-2'>
                        <Inputs
                          idRef='campaignNameRef'
                          labelValue={t(`${translationPath}campaign-name`)}
                          value={state.name}
                          inputPlaceholder={t(`${translationPath}Add-text`)}
                          onInputChanged={(event) => {
                            const { value } = event.target;
                            setState({
                              id: 'name',
                              value: value,
                            });
                          }}
                          isDisabled={withMarkCampaignAsCompleted}
                        />
                      </div>
                      <div className='my-2'>
                        <AutocompleteComponent
                          idRef='mediaDetailsRef'
                          labelValue={t(`${translationPath}media-details`)}
                          selectedValues={selected.mediaDetailsId || []}
                          isDisabled={isLoading.mediaDetails}
                          data={data.mediaDetails || []}
                          displayLabel={(option) => option.lookupItemName || ''}
                          multiple={false}
                          withoutSearchButton
                          onChange={(event, newValue) => {
                            setState({
                              id: 'mediaDetailsId',
                              value: newValue && newValue.lookupItemId,
                            });
                            setSelected({
                              id: 'mediaDetailsId',
                              value: newValue,
                            });
                            handleMediaTypeValue(newValue);
                          }}
                          isLoading={isLoading.mediaDetails}
                        />
                      </div>
                      <div className='my-3'>
                        <DatePickerComponent
                          idRef='campaignStartDateRef'
                          labelValue={t(`${translationPath}start-date`)}
                          labelClasses='Requierd-Color'
                          placeholder='DD/MM/YYYY'
                          format='YYYY/MM/DD'
                          parentTranslationPath={parentTranslationPath}
                          value={
                            state.campaignStartDate
                              ? state.campaignStartDate
                              : moment().format('YYYY-MM-DDTHH:mm:ss')
                          }
                          minDate={moment().format('YYYY-MM-DDTHH:mm:ss')}
                          onDateChanged={(newValue) => {
                            setState({
                              id: 'campaignStartDate',
                              value:
                                (newValue &&
                                  moment(newValue).format(
                                    'YYYY-MM-DDTHH:mm:ss'
                                  )) ||
                                null,
                            });

                            const isEndDateBeforeStartDate = moment(
                              state.campaignEndDate
                            ).isBefore(newValue);
                            if (isEndDateBeforeStartDate) {
                              setState({
                                id: 'campaignEndDate',
                                value:
                                  (newValue &&
                                    moment(newValue).format(
                                      'YYYY-MM-DDTHH:mm:ss'
                                    )) ||
                                  null,
                              });
                            }
                          }}
                        />
                      </div>
                      <div className='my-1'>
                        <AutocompleteComponent
                          idRef='campaignLanguagesRef'
                          labelValue={t(`${translationPath}campaign-languages`)}
                          isDisabled={
                            isLoading.campaignLanguages ||
                            activeItem?.campaignLanguage?.length > 0
                          }
                          selectedValues={selected.campaignLanguages || []}
                          data={data.campaignLanguages || []}
                          chipsLabel={(option) => option.lookupItemName || ''}
                          displayLabel={(option) => option.lookupItemName || ''}
                          filterOptions={(options) => {
                            const isFind = (id) =>
                              selected.campaignLanguages &&
                              selected.campaignLanguages.findIndex(
                                (w) => w.lookupItemId === id
                              ) === -1;
                            return options.filter((w) =>
                              isFind(w.lookupItemId)
                            );
                          }}
                          multiple={true}
                          withoutSearchButton
                          isWithError
                          onChange={(event, newValue) => {
                            const languageLookupIds =
                              newValue &&
                              newValue.map((item) => item.lookupItemId);
                            setState({
                              id: 'campaignLanguages',
                              value: languageLookupIds || [],
                            });
                            setSelected({
                              id: 'campaignLanguages',
                              value: newValue,
                            });
                          }}
                          isLoading={isLoading.campaignLanguages}
                        />
                      </div>
                      <div className='my-3'>
                        <SelectComponet
                          idRef={`CampaignStatusRef`}
                          data={[
                            {
                              value: true,
                              text: 'Active',
                            },
                            {
                              value: false,
                              text: 'In-active',
                            },
                          ]}
                          value={state.campaignStatus}
                          labelValue='campaign-status'
                          valueInput='value'
                          textInput='text'
                          onSelectChanged={(value) => {
                            setState({
                              id: 'campaignStatus',
                              value: value,
                            });
                          }}
                          emptyItem={{
                            value: null,
                            text: '',
                          }}
                          translationPathForData={translationPath}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                        />
                      </div>
                      <div className='my-3'>
                        <AutocompleteComponent
                          idRef='branchRef'
                          multiple
                          isDisabled={isLoading.branches}
                          isLoading={isLoading.branches}
                          labelValue={t(`${translationPath}branchs`)}
                          data={allBranches || []}
                          selectedValues={selected.campaignBranches || []}
                          chipsLabel={(option) =>
                            (option && option.branchName) || ''
                          }
                          displayLabel={(option) =>
                            (option && option.branchName) || ''
                          }
                          withoutSearchButton
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          filterOptions={(options) => {
                            const isFind = (id) =>
                              selected?.campaignBranches?.findIndex(
                                (w) => w.branchId === id
                              ) === -1;
                            if (selected?.campaignBranches) {
                              return options.filter((w) => isFind(w.branchId));
                            } else {
                              return options;
                            }
                          }}
                          onChange={(event, newValue) => {
                            const languageLookupIds =
                              newValue && newValue.map((item) => item.branchId);
                            setState({
                              id: 'campaignBranches',
                              value: languageLookupIds || [],
                            });
                            setSelected({
                              id: 'campaignBranches',
                              value: newValue,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className='w-50 mx-2'>
                      <div className='my-2'>
                        <AutocompleteComponent
                          idRef='mediaTypesRef'
                          labelValue={t(`${translationPath}media`)}
                          isDisabled={isLoading.mediaTypes}
                          selectedValues={selected.mediaTypeId || []}
                          data={data.mediaTypes || []}
                          chipsLabel={(option) => option.lookupItemName || ''}
                          displayLabel={(option) => option.lookupItemName || ''}
                          multiple={false}
                          withoutSearchButton
                          onChange={(event, newValue) => {
                            setState({
                              id: 'mediaTypeId',
                              value: newValue && newValue.lookupItemId,
                            });
                            setSelected({
                              id: 'mediaTypeId',
                              value: newValue,
                            });
                            handleMediaDetailsValue(
                              newValue && newValue.lookupItemId
                            );
                          }}
                          isLoading={isLoading.mediaTypes}
                        />
                      </div>
                      <div className='my-2'>
                        <AutocompleteComponent
                          idRef='CampaignManagerRef'
                          labelValue='campaign-manager'
                          selectedValues={selected.campaignManagerId}
                          isDisabled={isLoading.users}
                          labelClasses='Requierd-Color'
                          multiple={false}
                          data={data.users || []}
                          displayLabel={(option) => option.fullName || ''}
                          renderOption={(option) =>
                            ((option.userName || option.fullName) &&
                              `${option.fullName} (${option.userName})`) ||
                            ''
                          }
                          getOptionSelected={(option) =>
                            option.applicationUserId === state.campaignManagerId
                          }
                          withoutSearchButton
                          isLoading={isLoading.users}
                          onKeyDown={() => {
                            setSelected({
                              id: 'campaignManagerId',
                              value: null,
                            });
                          }}
                          onInputKeyUp={(e) => {
                            const searchText = e?.target?.value;
                            if (searchTimer.current)
                              clearTimeout(searchTimer.current);
                            searchTimer.current = setTimeout(() => {
                              getAllUsers(searchText);
                            }, 700);
                          }}
                          isWithError
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          onChange={(event, newValue) => {
                            setSelected({
                              id: 'campaignManagerId',
                              value: newValue,
                            });
                            setState({
                              id: 'campaignManagerId',
                              value:
                                (newValue && newValue.applicationUserId) ||
                                null,
                            });
                          }}
                        />
                      </div>
                      <div className='my-2'>
                        <DatePickerComponent
                          idRef='campaignEndDateRef'
                          labelValue={t(`${translationPath}end-date`)}
                          placeholder='DD/MM/YYYY'
                          format='YYYY/MM/DD'
                          labelClasses='mt-3'
                          parentTranslationPath={parentTranslationPath}
                          value={state.campaignEndDate}
                          minDate={state.campaignStartDate || ''}
                          onDateChanged={(newValue) => {
                            setState({
                              id: 'campaignEndDate',
                              value:
                                (newValue &&
                                  moment(newValue).format(
                                    'YYYY-MM-DDTHH:mm:ss'
                                  )) ||
                                null,
                            });
                          }}
                          isDisabled={withMarkCampaignAsCompleted}
                        />
                      </div>
                      <div className='mt-3'>
                        <Inputs
                          idRef='mediaLinkRef'
                          labelValue={t(`${translationPath}media-link`)}
                          inputPlaceholder={`${translationPath}Add-text`}
                          translationPath={translationPath}
                          parentTranslationPath={parentTranslationPath}
                          value={state.mediaUrl}
                          onInputChanged={(event) => {
                            const { value } = event.target;
                            setState({
                              id: 'mediaUrl',
                              value: value,
                            });
                          }}
                        />
                      </div>
                      <div className='mt-2'>
                        <AutocompleteComponent
                          idRef='campaignTypeRef'
                          labelValue={t(`${translationPath}campaign-type`)}
                          isDisabled={isLoading.campaignTypes}
                          labelClasses='Requierd-Color'
                          selectedValues={selected.campaignTypes || []}
                          data={data.campaignTypes || []}
                          chipsLabel={(option) => option.lookupItemName || ''}
                          displayLabel={(option) => option.lookupItemName || ''}
                          filterOptions={(options) => {
                            const isFind = (id) =>
                              selected.campaignTypes &&
                              selected.campaignTypes.findIndex(
                                (w) => w.lookupItemId === id
                              ) === -1;
                            return options.filter((w) =>
                              isFind(w.lookupItemId)
                            );
                          }}
                          multiple={true}
                          withoutSearchButton
                          isWithError
                          onChange={(event, newValue) => {
                            const languageLookupIds =
                              newValue &&
                              newValue.map((item) => item.lookupItemId);
                            setState({
                              id: 'campaignTypeIds',
                              value: languageLookupIds || [],
                            });
                            setSelected({
                              id: 'campaignTypes',
                              value: newValue,
                            });
                          }}
                          isLoading={isLoading.campaignTypes}
                        />
                      </div>
                      <div className='mt-3'>
                        <SelectComponet
                          idRef='branchRef'
                          isLoading={isLoading.saleTypes}
                          labelValue={t(`unit-sale-types`)}
                          data={data.unitSaleTypes}
                          valueInput='lookupItemId'
                          textInput='lookupItemName'
                          emptyItem={{
                            value: null,
                            text: 'select-unit-sale-types',
                            isDisabled: false,
                          }}
                          value={state.unitSaleTypeId}
                          onSelectChanged={(value) => setState({ id: 'unitSaleTypeId', value })}
                          parentTranslationPath={parentTranslationPath}
                          helperText={getErrorByName(schema, 'unitSaleTypeId').message}
                          error={getErrorByName(schema, 'unitSaleTypeId').error}
                          isWithError
                          isSubmitted={isSubmitted}
                          labelClasses='Requierd-Color'
                        />
                      </div>
                      {withMarkCampaignAsCompleted && (
                        <div className='my-2'>
                          <Inputs
                            idRef='departmentNameRef'
                            labelValue={t(`${translationPath}department-name`)}
                            value={state.departmentName}
                            isDisabled
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{ height: '2px', backgroundColor: 'gray' }}
                    ></div>
                    <div className='mx-1 mt-2'>
                      <AutocompleteComponent
                        idRef='EventTypeRef'
                        labelValue={t(`${translationPath}event-type`)}
                        selectedValues={selected.eventTypeSelected}
                        isDisabled={isLoading.eventTypeLoading}
                        multiple={false}
                        data={data.eventTypeData || []}
                        displayLabel={(option) => option.lookupItemName || ''}
                        renderOption={(option) => option.lookupItemName || ''}
                        getOptionSelected={(option) =>
                          option.lookupItemName === state.eventTypeId
                        }
                        withoutSearchButton
                        isLoading={isLoading.eventTypeLoading}
                        onKeyDown={() => {
                          setSelected({ id: 'eventTypeSelected', value: null });
                        }}
                        onInputKeyUp={(e) => {
                          const searchText = e?.target?.value;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getAllUsers(searchText);
                          }, 700);
                        }}
                        isWithError
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          setSelected({
                            id: 'eventTypeSelected',
                            value: newValue,
                          });
                          setState({
                            id: 'eventTypeId',
                            value: newValue.lookupItemId || null,
                          });
                        }}
                      />
                      <div className=' d-flex flex-v-between h-center'>
                        <div className='w-50 mr-1 mt-2'>
                          <AutocompleteComponent
                            idRef='CountryRef'
                            labelValue={t(`${translationPath}Country`)}
                            selectedValues={selected.countrySelected}
                            isDisabled={isLoading.countryLoading}
                            multiple={false}
                            data={data.countryData || []}
                            displayLabel={(option) =>
                              option.lookupItemName || ''
                            }
                            renderOption={(option) =>
                              option.lookupItemName || ''
                            }
                            getOptionSelected={(option) =>
                              option.lookupItemName === state.countryId
                            }
                            withoutSearchButton
                            isLoading={isLoading.countryLoading}
                            onKeyDown={() => {
                              setSelected({
                                id: 'countrySelected',
                                value: null,
                              });
                            }}
                            onInputKeyUp={(e) => {
                              const searchText = e?.target?.value;
                              if (searchTimer.current)
                                clearTimeout(searchTimer.current);
                              searchTimer.current = setTimeout(() => {
                                getAllUsers(searchText);
                              }, 700);
                            }}
                            isWithError
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            onChange={(event, newValue) => {
                              setSelected({
                                id: 'countrySelected',
                                value: newValue,
                              });
                              setState({
                                id: 'countryId',
                                value: newValue.lookupItemId || null,
                              });
                            }}
                          />
                        </div>

                        <div className='w-50 ml-2 mt-2'>
                          <AutocompleteComponent
                            idRef='CityRef'
                            labelValue={t(`${translationPath}City`)}
                            selectedValues={selected.citySelected}
                            isDisabled={isLoading.cityLoading}
                            multiple={false}
                            data={data.cityData || []}
                            displayLabel={(option) =>
                              option.lookupItemName || ''
                            }
                            renderOption={(option) =>
                              option.lookupItemName || ''
                            }
                            getOptionSelected={(option) =>
                              option.lookupItemName === state.cityId
                            }
                            withoutSearchButton
                            isLoading={isLoading.cityLoading}
                            onKeyDown={() => {
                              setSelected({
                                id: 'citySelected',
                                value: null,
                              });
                            }}
                            onInputKeyUp={(e) => {
                              const searchText = e?.target?.value;
                              if (searchTimer.current)
                                clearTimeout(searchTimer.current);
                              searchTimer.current = setTimeout(() => {
                                getAllUsers(searchText);
                              }, 700);
                            }}
                            isWithError
                            parentTranslationPath={parentTranslationPath}
                            translationPath={translationPath}
                            onChange={(event, newValue) => {
                              setSelected({
                                id: 'citySelected',
                                value: newValue,
                              });
                              setState({
                                id: 'cityId',
                                value: newValue.lookupItemId || null,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {tabIndex === 1 && (
                <div className='d-flex'>
                  <div className='w-50 mx-2'>
                    <div className='my-2'>
                      <Inputs
                        idRef='budgetAmountRef'
                        labelValue={t(`${translationPath}budget-amount`)}
                        value={state.budgetAmount}
                        // labelClasses='Requierd-Color'
                        type='number'
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          const isNumeralValue = !isNaN(+value);
                          if (isNumeralValue) {
                            setState({
                              id: 'budgetAmount',
                              value: +value,
                            });
                          } else {
                            setState({
                              id: 'budgetAmount',
                              value: 0,
                            });
                          }
                        }}
                      />
                    </div>
                    <div className='my-2'>
                      <Inputs
                        idRef='budgetLevelRef'
                        labelValue={t(`${translationPath}budget-level`)}
                        value={state.budgetLevel}
                        type='number'
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          const isNumeralValue = !isNaN(+value);
                          if (+value <= state.budgetAmount) {
                            if (isNumeralValue) {
                              setState({
                                id: 'budgetLevel',
                                value: +value,
                              });
                            } else {
                              setState({
                                id: 'budgetLevel',
                                value: 0,
                              });
                            }
                          }
                        }}
                      />
                    </div>
                    <div className='my-2'>
                      <Inputs
                        idRef='expectedNumberOfLeadsRef'
                        labelValue={t(`${translationPath}Expected-No-of-Leads`)}
                        value={state.expectedNumberOfLeads}
                        type='number'
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          const isNumeralValue = !isNaN(+value);
                          const isIntegerValue = Number.isInteger(+value);
                          if (isNumeralValue && isIntegerValue) {
                            setState({
                              id: 'expectedNumberOfLeads',
                              value: +value,
                            });
                          } else {
                            setState({
                              id: 'expectedNumberOfLeads',
                              value: 0,
                            });
                          }
                        }}
                      />
                    </div>
                    <div className='my-2'>
                      <Inputs
                        idRef='dailyBudgetRef'
                        labelValue='daily-budget'
                        value={state.dailyBudget}
                        endAdornment={
                          <InputAdornment
                            position='end'
                            className='px-2'
                          >
                            AED
                          </InputAdornment>
                        }
                        type='number'
                        min={0}
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onInputChanged={(event) => {
                          let value = event?.target?.value;
                          setState({ id: 'dailyBudget', value: +value });
                        }}
                        isDisabled={withMarkCampaignAsCompleted}
                      />
                    </div>
                  </div>
                  <div className='w-50 mx-2'>
                    <div className='my-2'>
                      <Inputs
                        idRef='spentAmountRef'
                        labelValue={t(`${translationPath}spent-amount`)}
                        value={state.spentAmount}
                        type='number'
                        onInputChanged={(event) => {
                          const { value } = event.target;
                          const isNumeralValue = !isNaN(+value);
                          if (isNumeralValue) {
                            setState({
                              id: 'spentAmount',
                              value: +value,
                            });
                          } else {
                            setState({
                              id: 'spentAmount',
                              value: 0,
                            });
                          }
                        }}
                        isDisabled={
                          activeItem && activeItem.campaignStatus == false
                        }
                      />
                    </div>
                    <div className='my-2'>
                      <AutocompleteComponent
                        idRef='budgetLevelAdminsRef'
                        labelValue='budget-level-admins'
                        selectedValues={selected.budgetLevelAdmins || []}
                        isDisabled={isLoading.users}
                        multiple={true}
                        data={data.users || []}
                        chipsLabel={(option) => option.fullName || ''}
                        displayLabel={(option) => option.fullName || ''}
                        renderOption={(option) =>
                          ((option.userName || option.fullName) &&
                            `${option.fullName} (${option.userName})`) ||
                          ''
                        }
                        withoutSearchButton
                        isLoading={isLoading.users}
                        onKeyDown={() => {
                          setSelected({ id: 'budgetLevelAdmins', value: null });
                        }}
                        onInputKeyUp={(e) => {
                          const searchText = e?.target?.value;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getAllUsers(searchText);
                          }, 700);
                        }}
                        isWithError
                        parentTranslationPath={parentTranslationPath}
                        translationPath={translationPath}
                        onChange={(event, newValue) => {
                          const budgetLevelAdminsIds =
                            newValue &&
                            newValue.map((item) => item.applicationUserId);

                          setSelected({
                            id: 'budgetLevelAdmins',
                            value: newValue,
                          });
                          setState({
                            id: 'budgetLevelAdminIds',
                            value: budgetLevelAdminsIds || null,
                          });
                        }}
                      />
                    </div>
                    {activeItem && (
                      <div className='my-3'>
                        <Inputs
                          idRef='actualNumberOfLeadsRef'
                          labelValue={t(
                            `${translationPath}actual-number-Of-leads`
                          )}
                          value={state.actualNumberOfLeads}
                          isDisabled
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          </DialogContent>
          <DialogActions>
            <ButtonBase
              onClick={onClose}
              className='btns theme-solid bg-cancel'
            >
              {t(`${translationPath}cancel`)}
            </ButtonBase>
            <ButtonBase
              className='btns theme-solid'
              onClick={saveHandler}
              type='submit'
            >
              {t(`${translationPath}save`)}
            </ButtonBase>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
