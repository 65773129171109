import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  PlatinumIcon,
  GoldIcon,
  SilverIcon,
  BronzeIcon,
} from "../../../assets/icons";

const useStyles = makeStyles((theme) => ({
  flexRow: {
    display: "flex",
    gap: "4px",
    alignItems: "center",
  },
  percentage: {
    fontSize: "12px",
    fontWeight: 700,
    color: theme.palette.text.brand_tertiary,
  },
  text: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.primary,
    display:'flex',
    alignItems:'center'
  },
}));

const getPropertyIcon = (propertyRating ,width ,height) => {
  if (propertyRating >= 90 && propertyRating <= 100) {
    return <PlatinumIcon width={width} height={height} />;
  } else if (propertyRating >= 80 && propertyRating < 90) {
    return <GoldIcon width={width} height={height}/>;
  } else if (propertyRating >= 70 && propertyRating < 80) {
    return <SilverIcon width={width} height={height} />;
  } else {
    return <BronzeIcon width={width} height={height}/>;
  }
};

const PropertyRating = ({ rating , width ='19' , height ='18' ,percentageStyle ,textStyle}) => {
  const classes = useStyles();

  return (
    <Box className={classes.flexRow}>
      {getPropertyIcon(rating ,width , height)}
      <Box className={clsx(classes.text, textStyle)}>
        {rating}
        <span className={clsx(classes.percentage, percentageStyle)}>%</span>
      </Box>
    </Box>
  );
};

export default PropertyRating;
