import { config } from "../config/config";
import { HttpServices } from "../Helper";


const GetUnitPublishedImages = async (unitId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/SmartProposal/GetUnitPublishedImages/${unitId}`
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

const GetUnitFloorPlanImages = async (unitId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/SmartProposal/GetUnitFloorPlanImages/${unitId}`
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

const GetProposalUnitDetails = async (unitId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/SmartProposal/GetProposalUnitDetails/${unitId}`
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

const GetProjectDescription = async (propertyId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/SmartProposal/GetProjectDescription/${propertyId}`
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

const GetNearestLandmarks = async (propertyId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/SmartProposal/GetNearestLandmarks/${propertyId}`
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

const GetPropertyPaymentPlans = async (propertyId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/SmartProposal/GetPropertyPaymentPlans/${propertyId}`
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

const GetPropertyPublishedImages = async (propertyId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/SmartProposal/GetPropertyPublishedImages/${propertyId}`
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};


const GetMultiProposalUnitsDetails = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/SmartProposal/GetMultiProposalUnitsDetails`,
        body
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

const GetPropertyFacilitiesAndAmenities = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/SmartProposal/GetPropertyFacilitiesAndAmenities`, body)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

const GetProposalContactDetails = async (propertyId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/SmartProposal/GetProposalContactDetails?propertyId=${propertyId}`
    )
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

const GenerateProposal = async (body) => {
    const result = await HttpServices.post(
        `${config.server_address}/CrmDfm/SmartProposal/GenerateProposal`, body)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

const GetPropertyLocation = async (propertyId) => {
    const result = await HttpServices.get(
        `${config.server_address}/CrmDfm/SmartProposal/GetPropertyLocation/${propertyId}`)
        .then((data) => data)
        .catch((error) => error.response);
    return result;
};

export {
    GetPropertyPublishedImages,
    GetUnitPublishedImages,
    GetUnitFloorPlanImages,
    GetProposalUnitDetails,
    GetProjectDescription,
    GetPropertyPaymentPlans,
    GetMultiProposalUnitsDetails,
    GetNearestLandmarks,
    GetPropertyFacilitiesAndAmenities,
    GetProposalContactDetails,
    GenerateProposal,
    GetPropertyLocation
}