
const defaultCoverImage = {
    url: "https://psi-crm.com/ProposalIcons/defaultCover.jpg",
    id: null,
  };
const useInitialValues = () => {
    const initialFormSelection = {
        CoverPage: {
            showLogo: true,
            coverImage: defaultCoverImage,
        },
        UnitsDetails: {
            showFinancialFees: true,
            unitImage: defaultCoverImage,
            financialFees: {
                showTransferFees: true,
                showAgencyFees: true,
                showNOCFeeBuyer: true,
                showServiceCharge: true,
                showChillerCharges: true,
                showLeased: true,
                showCurrentRentAmount: true,
                showRentalExpiryDate: true,
            }
        },
        MultiUnitsDetails: {
            selectedUnits: [],
            showTableView: false,
            showCardsView: false,
        },
        PropertyDescription: {
            showPage: true,
            showGeneralFacts: true,
            showProjectFacts: true,
        },
        UnitImages: {
            showPage: true,
            architectureImages: [],
            interiorImages: [],
            showArchitectureImages: true,
            showInteriorImages: true,
        },
        LocationAndamenities: {
            showPage: true,
            showLocation: true,
            showNearBy: true,
            showFacilities: true,
            showLocationAndamenitiesPage: true,
            selectedFacilitiesAndAmenities: [],
            selectednNearestLandmarks: [],
        },
        FloorPlan: {
            showPage: true,
            floorPlanImages: [],
        },
        PaymentPlan: {
            showPage: true,
            checkedPaymentPlans: [],
        },
    };

    return { initialFormSelection };
}

export default useInitialValues;
