import { useEffect } from "react";
import { SmartProposalActions } from "../../../../store/SmartProposal/Actions";
import { useDispatch } from "react-redux";

const useSmartProposalActions = ({ proposalUnits }) => {
  const dispatch = useDispatch();
  
  const updateGlobalState = () => {
    const smartProposalState = {
      checkedUnits: proposalUnits,
    };

    dispatch(SmartProposalActions.SmartProposalRequest(smartProposalState));
  };

  useEffect(() => {
    updateGlobalState();
  }, [proposalUnits]);


};

export default useSmartProposalActions;
