const placeholderImageURL =
  "https://apigateway.dev.psi-crm.com/FileManager/File/DownloadPublicFile/70c9ef1a-3cbf-4785-9558-8888f81412dd";
const placeholderBgColor = `#f8f9fa`;

export const UnitImagesTemplate = `
<div class="unitImages_wrapper">
  {{#UnitImages.showPage}}
  {{#UnitImages.showArchitectureImages}}
  {{#UnitImages.architectureImages.0.url}}
  <div class="unitImages_section">
    <p class="title">Architecture</p>

    {{#UnitImages.isSelectAllArchitecture}}
    <div class="select_all_container">
      {{#UnitImages.architectureImages}}
      <div class="images-content-section images-content-section-2" 
        style="background: url({{{url}}}) center / cover no-repeat,
         ${placeholderBgColor} url(${placeholderImageURL}) center / 50% no-repeat;">
      </div>
      {{/UnitImages.architectureImages}}
    </div>
    {{/UnitImages.isSelectAllArchitecture}}

    {{^UnitImages.isSelectAllArchitecture}}
    <div class="images-container">
      {{#UnitImages.architectureImages.0.url}}
      <div class="images-sidebar" style="background: url({{{UnitImages.architectureImages.0.url}}}) center / cover no-repeat,
       ${placeholderBgColor} url(${placeholderImageURL}) center / 50% no-repeat;">
      </div>
      {{/UnitImages.architectureImages.0.url}}
      <div class="images-content" data-isMultiImage="{{#UnitImages.architectureImages.1.url}}true{{/UnitImages.architectureImages.1.url}}">
        {{#UnitImages.architectureImages.1.url}}
        <div class="images-content-section images-content-section-1" 
        style="background: url({{{UnitImages.architectureImages.1.url}}}) center / cover no-repeat,
         ${placeholderBgColor} url(${placeholderImageURL}) center / 50% no-repeat;"></div>
        {{/UnitImages.architectureImages.1.url}}
        {{#UnitImages.architectureImages.2.url}}
        <div class="images-content-section images-content-section-2" 
        style="background: url({{{UnitImages.architectureImages.2.url}}}) center / cover no-repeat,
         ${placeholderBgColor} url(${placeholderImageURL}) center / 50% no-repeat;">
        </div>
        {{/UnitImages.architectureImages.2.url}}
      </div>
    </div>
    {{/UnitImages.isSelectAllArchitecture}}

  </div>
  {{/UnitImages.architectureImages.0.url}}
  {{/UnitImages.showArchitectureImages}}

    {{#UnitImages.showInteriorImages}}
    {{#UnitImages.interiorImages.0.url}}
      <div class="unitImages_section interior_section reverse">
        <p class="title">Interior</p>

        {{#UnitImages.isSelectAllInterior}}
          <div class="select_all_container">
            {{#UnitImages.interiorImages}}
              <div class="images-content-section images-content-section-2" 
                style="background: url({{{url}}}) center / cover no-repeat,
                ${placeholderBgColor} url(${placeholderImageURL}) center / 50% no-repeat;">
              </div>
            {{/UnitImages.interiorImages}}
          </div>
        {{/UnitImages.isSelectAllInterior}}

        {{^UnitImages.isSelectAllInterior}}
          <div class="images-container">
            <div class="images-content" data-isMultiImage="{{#UnitImages.interiorImages.0.url}}true{{/UnitImages.interiorImages.0.url}}">
              {{#UnitImages.interiorImages.0.url}}
                <div class="images-content-section images-content-section-1" 
                  style="background: url({{{UnitImages.interiorImages.0.url}}}) center / cover no-repeat,
                  ${placeholderBgColor} url(${placeholderImageURL}) center / 50% no-repeat;">
                </div>
              {{/UnitImages.interiorImages.0.url}}
              {{#UnitImages.interiorImages.1.url}}
                <div class="images-content-section images-content-section-2" 
                  style="background: url({{{UnitImages.interiorImages.1.url}}}) center / cover no-repeat,
                  ${placeholderBgColor} url(${placeholderImageURL}) center / 50% no-repeat;">
                </div>
              {{/UnitImages.interiorImages.1.url}}
            </div>
            {{#UnitImages.interiorImages.2.url}}
              <div class="images-sidebar" 
                style="background: url({{{UnitImages.interiorImages.2.url}}}) center / cover no-repeat,
                ${placeholderBgColor} url(${placeholderImageURL}) center / 50% no-repeat;">
              </div>
            {{/UnitImages.interiorImages.2.url}}
          </div>
        {{/UnitImages.isSelectAllInterior}}
      </div>
    {{/UnitImages.interiorImages.0.url}}
    {{/UnitImages.showInteriorImages}}
  {{/UnitImages.showPage}}
</div>
`;
