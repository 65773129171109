export const FloorPlanStyle = `
.FloorPlan_Container {
  display: flex;
  flex-direction: column;
  width: 2480px;
  justify-content: center;
  align-items: center;
}

.title {
  color: var(--2-d-3-e-50, #2D3E50);
  font-size: 72px;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.FloorPlan {
  width: 100%;
  padding: 120px 120px 174px 120px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.floorPlan_image{
    width: 2240px;
    height: 1370px;
    background-color: gray;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
`;
