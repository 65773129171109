import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { CustomDialog } from "../../../../CustomDialog";
import { CustomAutocomplete } from "../../../../CustomAutocomplete";
import { useTranslate } from "../../../../../../Hooks";
import {
  GetActivityTypeById,
  GetAllActivityTypes,
  GetAllSystemTemplateByCategoryId,
  OrganizationUserSearch,
} from "../../../../../../Services";
import AddNewActivityFormDialog from "../AddNewActivityFormDialog/AddNewActivityFormDialog";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { TemplateCategoryEnum } from "../../../../../../Enums";
import { GetTeamLeadsByUserId } from "../../../../../../Services/userServices";

//styles
import useStyles from "./styles";

const AddNewActivityModalDialog = ({
  onOpen,
  onClose,
  activeItem,
  setActiveItem,
  onSave,
  isGeneralDialog,
  actionItemId,
  leadClass,
  leadStageId,
  contactId,
  contactClassificationId,
  unitSaleTypeId,
  unitOperationId,
  unitTypeId,
  DialogSelect,
  setDialogSelect,
  setisOpenDialog,
}) => {
  const { translate } = useTranslate("HeaderView");
  const styles = useStyles();
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const searchTimer = useRef(null);
  const { pathname } = useLocation();
  const [paggination] = useState({ pageIndex: 0, pageSize: 25 });
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const userInfo = JSON.parse(localStorage.getItem("session"));
  const [leadId, setLeadId] = useState(null);
  const [isAddNewActivityFormOpenedDialog, setAddNewActivityFormOpenedDialog] =
    useState(null);
  const [idForTeamLeads, setIdForTeamLeads] = useState(null);
  const [isEdit, setIsEdit] = useState(null);
  const [isWithReminder, setIsWithReminder] = useState();
  const [slaAppliedFor, setSlaAppliedFor] = useState();
  const [roles, setRoles] = useState(null);
  const [withDateTime, setWithDateTime] = useState(false);
  const [withDuration, setWithDuration] = useState(false);
  const [categoryName, setCategoryName] = useState(null);
  const [activityRelatedToList, setActivityRelatedToList] = useState([]);
  const [isRelatedToNotSelected, setIsRelatedToNotSelected] = useState(true);
  const [defaultReminders, setDefaultReminders] = useState(null);
  const [isDateTimeCustom, setIsDateTimeCustom] = useState(false);
  const [filter] = useState({ pageIndex: 0, pageSize: 25 });
  const [data, setData] = useReducer(reducer, {
    activityAssignments: [],
    activityTypes: [],
    reminderTemplates: [],
    relatedLead: [],
    relatedContact: [],
    relatedUnit: [],
    relatedPortfolio: [],
    relatedWorkOrder: [],
    relatedMaintenanceContract: [],
  });
  const [selected, setSelected] = useReducer(reducer, {
    activityAssign: null,
    activityType: null,
    relatedUnit: null,
    relatedLead: null,
    relatedContact: null,
    relatedPortfolio: null,
    relatedWorkOrder: null,
    relatedMaintenanceContract: null,
    reminderForOptions: [],
    reminderTypeOptions: [],
    durationType: 1,
    durationAmount: 0,
    ImageCreatedByName: loginResponse?.profileImg || null,
    comment: "",
    ReminderforWhom: null,
    templete: [],
    activityReminders: [],
  });
  const [remindersWithoutEmptyItems, setRemindersWithoutEmptyItems] = useState(
    []
  );
  const [classifications, setClassifications] = useReducer(reducer, {
    leadCategories: [],
    operationTypes: [],
    saleTypes: [],
    unitTypes: [],
    contactClass: [],
  });

  const RelateType = {
    UnitType: 1,
    UnitOperation: 2,
    UnitSaleType: 3,
    Contact: 4,
    Lead: 5,
  };
  const [saveDto, setSaveDto] = useReducer(reducer, {
    assignAgentId: null,
    activityTypeId: null,
    unitId: null,
    relatedUnitNumberId: null,
    relatedLeadNumberId: null,
    relatedContactNumberId: null,
    relatedPortfolioId: null,
    relatedWorkOrderId: null,
    relatedMaintenanceContractId: null,
    activityDate: moment().add(2, "minutes").format("YYYY-MM-DDTHH:mm:ss"),
    activityEndDate: null,
    activityDuration: null,
    subject: null,
    comments: null,
    isOpen: true,
    activityReminders: [],
    createdByName: loginResponse?.fullName || null,
  });
  const creatorInfo = {
    id: userInfo?.userId,
    value: userInfo?.fullName,
    type: "creator",
  };
  const [leadChangeHandler, setLeadChangeHandler] = useState(false);
  const isSaleActivitiesView = pathname.includes("activities-sales");
  const isLeaseActivitiesView = pathname.includes("activities-lease");
  const isContactCenterActivitiesView =
    pathname.split("/home/")[1] === "Activities";
  const isLeadActivitiesView =
    pathname.includes("lead-profile-edit") ||
    pathname.includes("lead-sales") ||
    pathname.includes("lead-lease") ||
    pathname.includes("leads") ||
    pathname.includes("mortgage-leads") ||
    pathname.includes("lead-owner-lead");
  const isMortgageActivitiesView = pathname.includes("activities-mortgage");
  const isUnitActivitiesView = pathname.includes("unit");
  const isContactActivitiesView =
    pathname.includes("contact-profile-edit") ||
    pathname.includes("Contacts-CRM/view");
  const isLeadOwnerActivitiesView = pathname.includes("lead-owner-activity");

  const getAllActivityTypes = useCallback(async () => {
    const res = await GetAllActivityTypes();

    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "activityTypes",
        value: res || [],
      });
    } else {
      setData({
        id: "activityTypes",
        value: [],
      });
    }
  }, []);

  const getAllActivityAssignments = useCallback(
    async (value) => {
      const res = await OrganizationUserSearch({
        ...paggination,
        rolesIds: roles,
        name: (value && value.value) || "",
        userStatusId: 2,
      });
      if (!(res && res.status && res.status !== 200)) {
        const localValue = (res && res.result) || [];
        if (localValue.length > 0) {
          setData({
            id: "activityAssignments",
            value: localValue,
          });
        } else {
          setData({
            id: "activityAssignments",
            value: [],
          });
        }
      }
    },
    [paggination, roles]
  );
  const resetRelatedTo = () => {
    setSaveDto({ id: "activityReminders", value: [] });
    setSaveDto({ id: "relatedUnitNumberId", value: null });
    setSaveDto({ id: "relatedLeadNumberId", value: null });
    setSaveDto({ id: "relatedContactNumberId", value: null });
    setSaveDto({ id: "relatedPortfolioId", value: null });
    setSaveDto({ id: "relatedWorkOrderId", value: null });
    setSaveDto({ id: "relatedMaintenanceContractId", value: null });
    setSaveDto({ id: "relatedUnit", value: null });
    setSaveDto({ id: "relatedLead", value: null });
    setSaveDto({ id: "relatedContact", value: null });
    setSaveDto({ id: "relatedPortfolio", value: null });
    setSaveDto({ id: "relatedWorkOrder", value: null });
    setSaveDto({ id: "relatedMaintenanceContract", value: null });
    setSaveDto({ id: "activityAssign", value: null });
  };
  const handleReminderForAgentOptions = (newValue) => {
    let itemToPush = {};
    const localReminderForOptions = [...selected.reminderForOptions];
    const assignToReminderIndex = localReminderForOptions.findIndex(
      (item) => item.type === "agent"
    );
    if (newValue) {
      itemToPush = {
        id: newValue.id,
        value: newValue.fullName,
        type: "agent",
      };
    }

    if (!newValue && assignToReminderIndex !== -1) {
      localReminderForOptions.splice(assignToReminderIndex, 1);
    }

    if (newValue && assignToReminderIndex !== -1) {
      localReminderForOptions[assignToReminderIndex] = itemToPush;
    }

    if (newValue && assignToReminderIndex == -1) {
      localReminderForOptions.push(itemToPush);
    }

    setSelected({ id: "reminderForOptions", value: localReminderForOptions });
  };
  async function initialActivityAssigTo() {
    const res = await OrganizationUserSearch({
      ...paggination,
      rolesIds: roles,
      userName: userInfo?.userName || null,
      name: userInfo?.fullName || null,
      email: userInfo?.email || null,
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setSelected({
          id: "activityAssign",
          value: localValue[0],
        });
      } else {
        setSelected({
          id: "activityAssign",
          value: null,
        });
      }

      setIdForTeamLeads(localValue[0]?.id || null);
      setSaveDto({
        id: "assignAgentId",
        value: (localValue[0] && localValue[0].id) || null,
      });

      handleReminderForAgentOptions(localValue[0]);
    }
  }

  const handleActivityRelatedToList = (relatedTo) => {
    if (relatedTo) {
      let filteredRelatedTo = [];

      if (
        isGeneralDialog ||
        isContactCenterActivitiesView ||
        isLeadOwnerActivitiesView ||
        isSaleActivitiesView ||
        isLeaseActivitiesView
      ) {
        filteredRelatedTo = relatedTo;
        setActivityRelatedToList(filteredRelatedTo || []);
        return;
      }

      if (isContactActivitiesView) {
        filteredRelatedTo = relatedTo.filter(
          (item) =>
            item.relatedToName === "Contact" ||
            item.relatedToName === "Lead" ||
            item.relatedToName === "Unit"
        );
        setActivityRelatedToList(filteredRelatedTo || []);
        return;
      }

      if (isLeadActivitiesView) {
        filteredRelatedTo = relatedTo.filter(
          (item) => item.relatedToName !== "Contact"
        );
        setActivityRelatedToList(filteredRelatedTo || []);
        return;
      }

      if (isUnitActivitiesView) {
        filteredRelatedTo = relatedTo.filter(
          (item) =>
            item.relatedToName == "Lead" ||
            item.relatedToName == "Unit" ||
            item.relatedToName == "Contact"
        );
        setActivityRelatedToList(filteredRelatedTo || []);
        return;
      }

      if (isMortgageActivitiesView) {
        filteredRelatedTo = relatedTo.filter(
          (item) =>
            item.relatedToName == "Lead" || item.relatedToName == "Contact"
        );
        setActivityRelatedToList(filteredRelatedTo || []);
        return;
      }
    }

    setActivityRelatedToList([]);
  };

  const getActivityTypeById = useCallback(async () => {
    setIsEdit(activeItem ? true : false);

    if (saveDto.activityTypeId !== null) {
      const res = await GetActivityTypeById(saveDto.activityTypeId, leadId);
      if (!(res && res.status && res.status !== 200)) {
        const assignToUserRoles = res.assignedToActivityTypeRoles
          ? res.assignedToActivityTypeRoles.map((item) => item.rolesId)
          : [];
        setRoles(assignToUserRoles);
        setIsWithReminder(res.withReminder);
        setCategoryName(res.categoryName);
        setWithDateTime(res.withDateTime);
        setWithDuration(res.withDuration);
        setIsDateTimeCustom(res.withDateTimeCustom ?? false);
        setSlaAppliedFor(res?.slaAppliedFor);
        setDefaultReminders(
          res?.leadStageActivityTypeReminder
            ? res?.leadStageActivityTypeReminder?.map((reminder) => ({
                reminderTo:
                  reminder?.customActivityTypeReminderRecipient[0]
                    ?.recipientType,
                reminderTime: reminder?.reminderTime,
                reminderType: reminder?.reminderType,
                templateId: reminder?.systemTemplateId,
                templateName: reminder?.templateName,
              }))
            : res.activityTypeReminders
        );
        handleActivityRelatedToList(res.relatedTo);
        if (res.activityTypeRelates?.length > 0) {
          setClassifications({
            id: "edit",
            value: {
              leadCategories:
                res.activityTypeRelates
                  ?.filter((item) => item.relateType === RelateType.Lead)
                  .map((item) => item.enumValue) ?? [],
              operationTypes:
                res.activityTypeRelates
                  ?.filter(
                    (item) => item.relateType === RelateType.UnitOperation
                  )
                  .map((item) => item.lookupsId) ?? [],
              saleTypes:
                res.activityTypeRelates
                  ?.filter(
                    (item) => item.relateType === RelateType.UnitSaleType
                  )
                  .map((item) => item.lookupsId) ?? [],
              unitTypes:
                res.activityTypeRelates
                  ?.filter((item) => item.relateType === RelateType.UnitType)
                  .map((item) => item.lookupsId) ?? [],
              contactClass:
                res.activityTypeRelates
                  ?.filter((item) => item.relateType === RelateType.Contact)
                  .map((item) => item.lookupsId) ?? [],
              contactClassNames:
                res.activityTypeRelates
                  ?.filter((item) => item.relateType === RelateType.Contact)
                  .map((item) => item.lookupsName) ?? [],
            },
          });
        }
        setSelected({ id: "activityType", value: res });
      }
    }
  }, [saveDto.activityTypeId, leadId]);

  const getAllSystemTemplateByCategoryId = useCallback(async () => {
    const res = await GetAllSystemTemplateByCategoryId(
      TemplateCategoryEnum.Activity.key
    );
    if (!(res && res.status && res.status !== 200)) {
      const mappedTemplatesData =
        res &&
        res.map((item) => ({
          templateName: item.systemTemplateName,
          templateId: item.systemTemplateId,
        }));
      setData({ id: "reminderTemplates", value: mappedTemplatesData || [] });
    } else setData({ id: "reminderTemplates", value: [] });
  }, [filter]);

  const GetTeamLeadsByUserIdAPICall = async () => {
    if (idForTeamLeads) {
      const res = await GetTeamLeadsByUserId(idForTeamLeads);
      if (!(res && res.status && res.status !== 200)) {
        const tempReminderForOptions = selected?.reminderForOptions;
        const filteredData = tempReminderForOptions?.filter(
          (obj) => obj?.type !== "teamLead"
        );

        const newTeamLeads =
          res?.map((item) => {
            return {
              id: item.teamLeadId,
              value: item.teamLeadName,
              type: "teamLead",
            };
          }) || [];

        const allItemsTogether = [
          ...filteredData,
          ...newTeamLeads,
          creatorInfo,
        ];

        const hasLead = allItemsTogether.some((item) => item?.type === "lead");

        if (!hasLead) {
          const relatedLead = {
            id: selected?.relatedLead?.leadId,
            value: selected?.relatedLead?.lead?.contact_name?.name,
            type: "lead",
          };

          allItemsTogether.push(relatedLead);
        }

        const hasCreator = allItemsTogether.some(
          (item) => item?.type === "creator"
        );

        if (!hasCreator) {
          allItemsTogether.push(creatorInfo);
        }

        setSelected({
          id: "reminderForOptions",
          value: allItemsTogether,
        });

        if (!activeItem) {
          setSaveDto({ id: "activityReminders", value: [] });
        }

        if (!hasLead) {
          setLeadChangeHandler(!leadChangeHandler);
        }
      } else {
      }
    }
  };
  useEffect(() => {
    if (leadId) {
      getActivityTypeById();
    }
  }, [leadId]);
  useEffect(() => {
    if (roles) getAllActivityAssignments();
    if (roles && !activeItem) initialActivityAssigTo();
  }, [getAllActivityAssignments, activeItem]);

  useEffect(() => {
    getAllActivityTypes();
    getAllSystemTemplateByCategoryId();
  }, []);
  useEffect(() => {
    getActivityTypeById();
  }, [saveDto.activityTypeId]);

  useEffect(() => {
    if (idForTeamLeads) {
      const filteredData = selected?.reminderForOptions?.filter(
        (obj) => obj?.type !== "teamLead"
      );
      setSelected({
        id: "reminderForOptions",
        value: filteredData,
      });

      GetTeamLeadsByUserIdAPICall();
    }
  }, [idForTeamLeads]);

  return (
    <>
      <CustomDialog
        open={onOpen}
        onClose={() => {
          onClose();
        }}
        title={translate("Add-new-activity")}
        subtitle={translate(
          "Create-and-schedule-new-activity-to-enhance-engagement-with-your-contacts-and-leads"
        )}
        cancelText={translate("Close")}
        confirmText={translate("Add-new-Activity")}
        isDisabled={!saveDto.activityTypeId}
        onConfirm={() => {
          setAddNewActivityFormOpenedDialog(true);
          setDialogSelect("");
        }}
        width={"688px"}
        content={
          <Box className={styles.wrapperAddNewActivityModal}>
            <Box className={styles.containerFiledItem}>
              <Typography className={styles.titleItem}>
                {translate("Activity-name")}*
              </Typography>
              <CustomAutocomplete
                placeholder={translate("Select")}
                options={data?.activityTypes || []}
                onChange={(e, newValue) => {
                  setSaveDto({
                    id: "edit",
                    value: {
                      ...saveDto,
                      activityTypeId: newValue?.activityTypeId || null,
                      subject: newValue?.activityTypeName || null,
                      assignAgentId: null,
                    },
                  });

                  setSelected({
                    id: "edit",
                    value: {
                      ...selected,
                      reminderForOptions: [],
                      activityAssign: null,
                      activityType: newValue,
                    },
                  });
                  resetRelatedTo();
                  const assignedToActivityTypeRoles =
                    newValue?.assignedToActivityTypeRoles || [];

                  const rolesIds = assignedToActivityTypeRoles.map(
                    (item) => item.rolesId || []
                  );

                  setRoles(rolesIds);
                }}
                value={selected?.activityType || null}
                getOptionLabel={(option) => option.activityTypeName || ""}
                getOptionValue={(option) => option.id === saveDto.assignAgentId}
                renderOption={(option) => option.activityTypeName || ""}
              />
            </Box>

            <Box className={styles.containerFiledItem}>
              <Typography className={styles.titleItem}>
                {translate("Activity-assign-to")}*
              </Typography>
              <CustomAutocomplete
                variant={"icon"}
                isDisabled={saveDto.activityTypeId == null}
                options={data?.activityAssignments || []}
                value={selected?.activityAssign || null}
                getOptionLabel={(option) => option.fullName || ""}
                getOptionValue={(option) => option.id === saveDto.assignAgentId}
                renderOption={(option) =>
                  ((option.userName || option.fullName) &&
                    `${option.fullName} (${option.userName})`) ||
                  ""
                }
                onInputKeyUp={(e) => {
                  const onSearch = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllActivityAssignments(onSearch);
                  }, 700);
                }}
                onChange={(e, newValue) => {
                  setSelected({
                    id: "activityAssign",
                    value: newValue || null,
                  });
                  setIdForTeamLeads(newValue?.id || null);
                  setSaveDto({
                    id: "assignAgentId",
                    value: (newValue && newValue?.id) || null,
                  });
                  handleReminderForAgentOptions(newValue);
                }}
              />
            </Box>
          </Box>
        }
      />
      {isAddNewActivityFormOpenedDialog && (
        <AddNewActivityFormDialog
          onOpen={isAddNewActivityFormOpenedDialog}
          onClose={() => {
            setAddNewActivityFormOpenedDialog(false);
            setDialogSelect("");
            setisOpenDialog((item) => ({ ...item, ActivitiesDialog: false }));
          }}
          selected={selected}
          setSelected={setSelected}
          saveDto={saveDto}
          setSaveDto={setSaveDto}
          data={data}
          setData={setData}
          isRelatedToNotSelected={isRelatedToNotSelected}
          setIsRelatedToNotSelected={setIsRelatedToNotSelected}
          activityRelatedToList={activityRelatedToList}
          actionItemId={actionItemId}
          isGeneralDialog={isGeneralDialog}
          activeItem={activeItem}
          classifications={classifications}
          setLeadId={setLeadId}
          leadId={leadId}
          isWithReminder={isWithReminder}
          withDateTime={withDateTime}
          idForTeamLeads={idForTeamLeads}
          setIdForTeamLeads={setIdForTeamLeads}
          isDateTimeCustom={isDateTimeCustom}
          defaultReminders={defaultReminders}
          withDuration={withDuration}
          slaAppliedFor={slaAppliedFor}
          setSlaAppliedFor={setSlaAppliedFor}
          categoryName={categoryName}
          filter={filter}
          userInfo={userInfo}
          isEdit={isEdit}
          creatorInfo={creatorInfo}
          setRemindersWithoutEmptyItems={setRemindersWithoutEmptyItems}
          remindersWithoutEmptyItems={remindersWithoutEmptyItems}
        />
      )}
    </>
  );
};
export default AddNewActivityModalDialog;
