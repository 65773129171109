import { makeStyles } from '@material-ui/core/styles';
import { useIsAr } from '../../../Hooks';

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    contactSideMenu: {
      position: 'fixed',
      top: 0,
      ...(isAr ? { left: 0 } : { right: 0 }),
      width: (props) => (props.isExpanded ? '1000px' : '507px'),
      height: '100%',
      backgroundColor: theme.palette.background.primary,
      zIndex: theme.zIndex.drawer + 2,
      transition: 'transform 0.3s ease-in-out',
      boxShadow: theme.shadows[7],
      padding: '32px 0px',
      borderRight: `1px solid ${theme.palette.border.primary}`,
      borderLeft: `1px solid ${theme.palette.border.primary}`,
      borderRadius: theme.borderRadius[0],
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      overflowY: 'auto',
    },
    hidden: {
      transform: isAr ? 'translateX(-100%)' : 'translateX(100%)',
      visibility: 'hidden',
      opacity: 0,
    },
    headingContainer: {
      padding: '0px 32px 16px',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down(321)]: {
        padding: '0px 12px 16px',
      },
    },
    menuHeading: {
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '38px',
      color: theme.palette.text.primary,
      [theme.breakpoints.down(321)]: {
        fontSize: '24px',
      },
    },
    detailsContainer: {
      position: 'relative',
    },
    detailsBg: {
      background: theme.palette.utility.brand_200,
      height: '68px',
      marginBottom: '57px',
    },
    detailsContent: {
      padding: '0px 32px',
      position: 'absolute',
      // top: "20px",
      width: '100%',
      [theme.breakpoints.down(321)]: {
        padding: '0px 12px',
      },
    },
    avatarWithIcon: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    starIcon: {
      marginTop: '5px',
      cursor: 'pointer',
    },

    // avatar styles
    avatarWrapper: {
      position: 'relative',
      alignItems: 'center',
      backgroundColor: '#FFF',
      borderRadius: theme.borderRadius[10],
      boxShadow: theme.shadows[4],
      display: 'flex',
      height: '104px',
      width: '104px',
      justifyContent: 'center',
    },
    defaultAvatar: {
      background: theme.palette.background.secondary_hover,
      border: `1px solid ${theme.palette.border.avatarContrast}`,
      height: '96px',
      width: '96px',

      '& img': {
        height: '48px',
        width: '48px',
      },
    },
    avatar: {
      background: theme.palette.background.secondary_hover,
      border: `1px solid ${theme.palette.border.avatarContrast}`,
      height: '96px',
      width: '96px',
    },
    // contactDetails: {
    //   marginTop: "16px",
    // },

    nameContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '4px',
      margin: '16px 0 4px',
      height: '64px',
    },
    prefix: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '32px',
      color: theme.palette.text.primary,
      direction: 'ltr',
    },
    fullName: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '32px',
      color: theme.palette.text.primary,
      [theme.breakpoints.down(321)]: {
        fontSize: '20px',
      },
    },

    actionsContainer: {
      display: 'flex',
      gap: '12px',
      marginBottom: '16px',
    },

    badgesContainer: {
      marginBottom: '16px',
      display: 'flex',
      gap: '6px',
    },

    socialLinksContainer: {
      margin: '16px 0',
    },

    contactInfoContainer: {
      marginBottom: '16px',
      display: 'flex',
      gap: '4px',
      alignItems: 'baseline',
      flexDirection: 'column',
    },
    contactTypeLabel: {
      fontWeight: 400,
      fontSize: '16px',
      color: theme.palette.text.tertiary,
    },
    isBulk: {
      fontWeight: 700,
      fontSize: '14px',
      color: theme.palette.text.tertiary,
    },
    badgeSection: {
      display: 'flex',
      gap: '8px',
    },
    idSection: {
      alignItems: 'center',
      color: theme.palette.button.tertiary_fg,
      display: 'flex',
      gap: '8px',
      justifyContent: 'center',

      '& label': {
        fontSize: '16px',
        fontWeight: 600,
      },
    },
    propertyType: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
      color: theme.palette.text.secondary,
    },
    rating: {
      zIndex: 2,
      position: 'absolute',
      bottom: '20px',
      right: '32px',
    },
    favoriteIcon: {
      zIndex: 2,
      position: 'absolute',
      top: '20px',
      right: '32px',
    },
    priceText: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: '20px',
      fontWeight: 600,
      color: theme.palette.text.brand_tertiary,
      '& span': {
        color: theme.palette.utility.brand_300,
        fontSize: '14px',
        fontWeight: 500,
      },
    },
    mainContainer: {
      display: 'flex',
    },
    mainContainerItem: {
      flex: 1,
    },
    landmarkSearchInput: {
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
    },
    landmarksContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      padding: '0 32px ',
      margin: '0 auto',
    },
    borderBottom: {
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
      paddingBottom: '8px',
    },
    headTitle: {
      fontFamily: 'Inter',
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '38px',
      color: theme.palette.text.primary,
    },
    resultStyle: {
      fontFamily: 'Inter',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '28px',
      color: theme.palette.text.primary,
      paddingBottom: '20px',
      width: '75%',
    },
    landmarksContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      overflowY: 'auto',
      maxHeight: '100dvh',
    },
    landmarkName: {
      color: theme.palette.text.primary,
      fontFamily: 'Inter',
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '30px',
    },

    landmarkCategory: {
      overflow: 'hidden',
      color: theme.palette.text.tertiary,
      textOverflow: 'ellipsis',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      marginTop: '2px',
      marginBottom: '16px',
    },
    landmarkAddress: {
      overflow: 'hidden',
      color: theme.palette.text.secondary,
      textOverflow: 'ellipsis',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
    },
  };
});
