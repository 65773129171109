import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    root: {
      alignSelf: "flex-start",
    },
    label: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.text.secondary,
    },
    labelSide: {
      minWidth: "280px",
      maxWidth: "280px",
      fontWeight: 600,
    },
    labelAbove: {
      marginBottom: "6px",
    },
    helperInfo: {
      maxWidth: "280px",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.text.tertiary,
    },
  };
});
