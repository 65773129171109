import { makeStyles } from '@material-ui/core/styles';
import { useIsAr } from '../../../../../Hooks';
import { fill } from 'lodash';

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    tabButtonContainer: {
      display: 'flex',
      gap: '8px',
      padding: '0 4px 10px',
    },
    nearbyButtonsBox: {
      display: 'flex',
      gap: '8px',
      overflowX: 'auto',
    },
    landmarkButton: {
      flex: 1,
      '& .MuiButton-label': {
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
      },
      '& svg': {
        fill: theme.palette.button.secondary_fg,
      },
      maxWidth: '100px',
      color: theme.palette.button.secondary_fg,
      background: theme.palette.background.paper,
      padding: '8px 12px',
      border: '1px #D0D5DD solid',
      borderRadius: '8px',
    },
    notificationCounter: {
      fontFamily: 'Inter',
      alignItems: 'center',
      alignSelf: 'center',
      background: theme.palette.background.brand_50,
      border: `1px solid ${theme.palette.border.brand_200}`,
      borderRadius: '50%',
      color: theme.palette.text.brand_700,
      display: 'flex',
      fontSize: '12px',
      height: '22px',
      justifyContent: 'center',
      width: '24px',
    },
    inactiveNotificationCounter: {
      fontFamily: 'Inter',
      alignItems: 'center',
      alignSelf: 'center',
      background: theme.palette.background.gray_50,
      border: `1px solid ${theme.palette.border.gray_200}`,
      borderRadius: '50%',
      color: theme.palette.text.gray_700,
      display: 'flex',
      fontSize: '12px',
      height: '22px',
      justifyContent: 'center',
      width: '24px',
    },
    tabButton: {
      opacity: 1,
      color: theme.palette.text.text_quarterary, // not selected tab button color
      lineHeight: '20px',
      fontFamily: 'Inter',
      display: 'flex !important',
      flexDirection: 'row !important',
      gap: '5px !important',
      width: 'fit-content !important',
      minWidth: 'fit-content !important',
      minHeight: 'fit-content',
      margin: 0,
      padding: 0,
      fontWeight: 600,
      textTransform: 'none',
      '& .MuiTab-wrapper': {
        alignItems: 'flex-start !important',
      },
    },
    tabs: {
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
      minHeight: 'fit-content',
      marginBottom: '24px',
    },
    flexContainer: {
      gap: '8px',
    },
    tab: {
      color: theme.palette.text.brand_secondary, // selected tab button color
      lineHeight: '20px',
      fontFamily: 'Inter',
      fontWeight: 600,
      borderBottom: `2px solid ${theme.palette.border.brand_primary_alt}`,
    },
    tabLabel: {
      textTransform: 'none',
    },
    font14: {
      fontFamily: 'Inter',
      fontSize: '14px',
    },
    nearbyBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: '24px',
      padding: '12px 0',
    },
    nearbyTitle: {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '28px',
      color: theme.palette.text.primary,
    },
    nearbyBoxTitle: {
      borderBottom: `1px solid ${theme.palette.border.secondary}`,
      paddingBottom: '8px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    popover: {
      '& .MuiPopover-paper': {
        borderRadius: '0px',
      },
    },
    popoverBox: {
      display: 'flex',
      width: '320px',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '9.6px',
      background: theme.palette.background.paper,
      padding: '19.2px',
      boxShadow:
        '0px 16px 19.2px -3.2px rgba(16, 24, 40, 0.08), 0px 6.4px 6.4px -3.2px rgba(16, 24, 40, 0.03)',
      '& .MuiTypography-root': {
        color: theme.palette.text.primary,
        fontFamily: 'Inter',
        fontSize: '14.4px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '22.4px',
      },
      '& .box-header': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '16px',
      },
      '& .box-footer': {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '25.6px',
        width: '100%',
        gap: '9.6px',
        marginTop: '16px',
        '& .MuiButton-root': {
          flex: 1,
        },
      },
    },
    radiusButton: {
      padding: '10px 14px',
    },
  };
});
