import React, { act, memo } from "react";
import "./PropertyNavigationBar.scss";

const PropertyNavigationBar = memo(
  ({ navigationHandler, propertiesList, activePropertyIndex }) => {

    const activeProperty = propertiesList[activePropertyIndex];

    const checkIsUpNext = (index) => {
      let lastSubmittedIndex = -1;
      if (typeof Array.prototype.findLastIndex === "function")
        lastSubmittedIndex = propertiesList.findLastIndex(
          (item) => item.isSubmitted
        );
      const isUpNext = index === lastSubmittedIndex + 1;
      return isUpNext;
    };

    const navItemStatusClass = (item, index) => {
      if (item?.propertyId === activeProperty?.propertyId) return "active";
      else if (item.isSubmitted) return "available";
      else if (!item.isSubmitted || checkIsUpNext(index)) return "disabled";
    };

    return (
      <div className="PropertyNavigationBar">
        {(Array.isArray(propertiesList) && propertiesList.length > 1)
          ? propertiesList.map((item, index) => (
              <div
                className={`NavigationBar_Item ${navItemStatusClass(
                  item,
                  index
                )}`}
                key={item.propertyId}
                onClick={() => {
                  if (item.isSubmitted || checkIsUpNext(index))
                    navigationHandler(item.isSubmitted, index);
                }}
              >
                <span>{item.propertyName}</span>
              </div>
            ))
          : null}
      </div>
    );
  }
);

export default PropertyNavigationBar;
