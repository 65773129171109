import React from "react";
import moment from "moment";
import { Typography, Divider, Box } from "@material-ui/core";
import { CustomCard } from "../../../../../../../Components";
import { useTranslate } from "../../../../../../../Hooks";

// Styles
import { sharedDetailsStyles } from "./styles";

function SourceFile({ details }) {
  const styles = sharedDetailsStyles();

  const { translate } = useTranslate("NewContactsView");

  return (
    <CustomCard
      borderRadius="xl"
      boxShadow="xs"
      borderColor="secondary"
      classes={styles.container}
    >
      <Typography className={styles.heading} variant="h3">
        {translate("source-file")}
      </Typography>
      <Divider className={styles.divider} />
      <Box className={styles.infoContainer}>
        <Box className={styles.infoItem}>
          <Typography className={styles.infoLabel} variant="body1">
            {translate("source-file-name")}
          </Typography>
          <Typography className={styles.infoValue} variant="body1">
            {details?.sourceFileName ?? "N/A"}
          </Typography>
        </Box>
        <Box className={styles.infoItem}>
          <Typography className={styles.infoLabel} variant="body1">
            {translate("source-file-date")}:
          </Typography>
          <Typography className={styles.infoValue} variant="body1">
            {details?.sourceFileDate
              ? moment(details?.sourceFileDate).format("DD/MM/YYYY")
              : "N/A"}
          </Typography>
        </Box>
        <Box className={styles.infoItem}>
          <Typography className={styles.infoLabel} variant="body1">
            {translate("source-file-year")}:
          </Typography>
          <Typography className={styles.infoValue} variant="body1">
            {details?.sourceFileYear ?? "N/A"}
          </Typography>
        </Box>
        <Box className={styles.infoItem}>
          <Typography className={styles.infoLabel} variant="body1">
            {translate("source-by")}:
          </Typography>
          <Typography className={styles.infoValue} variant="body1">
            {details?.sourceBy ?? "N/A"}
          </Typography>
        </Box>
        <Box className={styles.infoItem}>
          <Typography className={styles.infoLabel} variant="body1">
            {translate("community-source-file")}:
          </Typography>
          <Typography className={styles.infoValue} variant="body1">
            {details?.communitySourceFile ?? "N/A"}
          </Typography>
        </Box>
        <Box className={styles.infoItem}>
          <Typography className={styles.infoLabel} variant="body1">
            {translate("property-source-file")}:
          </Typography>
          <Typography className={styles.infoValue} variant="body1">
            {details?.propertySourceFile ?? "N/A"}
          </Typography>
        </Box>
      </Box>
    </CustomCard>
  );
}

export default SourceFile;
