import React, { useCallback, useEffect, useReducer, useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import {
  Box,
  ButtonBase,
  Collapse,
  IconButton,
  Typography,
} from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { GetAgentHistoryDetails } from '../../../../../Services';
import { PaginationComponent, Spinner } from '../../../../../Components';
import './AgentHistoryTabStyles.scss';

function AgentHistoryTab({ activeItem, parentTranslationPath }) {
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return { ...action.value };
  }, []);
  const { t: translate } = useTranslation([
    parentTranslationPath,
    'GlobalHistoryComponent',
  ]);

  const [expandedRow, setExpandedRow] = useState(null);
  const [differences, setDifferences] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [filter, setFilter] = useReducer(reducer, {
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
  });
  const [data, setData] = useState({ result: [], totalCount: 0 });

  const headerData = [
    { id: 1, title: translate('date-and-time-viewed'), align: 'left' },
    { id: 2, title: translate('user'), align: 'center' },
    { id: 3, title: translate('type'), align: 'right' },
  ];

  const changeHeaderData = [
    { id: 1, title: translate('field-name'), align: 'left' },
    { id: 2, title: translate('changed-from'), align: 'center' },
    { id: 3, title: translate('changed-to'), align: 'right' },
  ];

  const getAgentHistory = async () => {
    setIsLoading(true);
    const res = await GetAgentHistoryDetails(
      activeItem.agentId,
      filter.pageIndex + 1,
      filter.pageSize
    );
    if (!(res && res.status && res.status !== 200)) {
      setData(res);
    } else {
      setData({ result: [], totalCount: 0 });
    }
    setIsLoading(false);
  };

  const onExpandClicked = (item) => {
    if (item.actionType.toLowerCase() !== 'read') {
      const newExpandedRow =
        item.agentHistoryId === expandedRow ? null : item.agentHistoryId;
      setExpandedRow(newExpandedRow);
      if (newExpandedRow) {
        const newDifferences = processAgentHistory(item);
        setDifferences((prev) => ({
          ...prev,
          [item.agentHistoryId]: newDifferences,
        }));
      }
    }
  };

  const processAgentHistory = (item) => {
    const differences = [];
    const keysToCheck = [
      'LeadCapacity',
      'IsInRotation',
      'ReasonName',
      'Notes',
      'ForgivenPeriodInMinutes',
      'VipForgivenessPeriodInMinutes',
      'DailyCapacity',
      'MonthlyCapacity',
      'WeeklyCapacity',
      'LeadPoolCapacity',
      'DailyPoolCapacity',
      'MonthlyPoolCapacity',
      'WeeklyPoolCapacity',
      'IsBlocked',
      'BlockingReasonName',
      'BlockingRemarks',
      'BlockingDateFrom',
      'BlockingDateTo',
      'UnBlockingRemarks'
    ];

    const obj1 = item?.responseBefore ?? {};
    const obj2 = item?.responseAfter ?? {};

    keysToCheck.forEach((key) => {
      if (obj1[key] !== obj2[key]) {
        differences.push({
          field: key,
          oldValue: obj1[key] ?? null,
          newValue: obj2[key] ?? null,
        });
      }
    });

    const schedulesBefore = obj1.AgentRotationSchedules || [];
    const schedulesAfter = obj2.AgentRotationSchedules || [];

    if (JSON.stringify(schedulesBefore) !== JSON.stringify(schedulesAfter)) {
      const maxLength = Math.max(schedulesBefore.length, schedulesAfter.length);

      const getSuffix = (n) => {
        if (n === 1) return 'st';
        if (n === 2) return 'nd';
        if (n === 3) return 'rd';
        return 'th';
      };

      const formatTimeToAMPM = (time) => {
        const [hours, minutes] = time
          ?.split(':')
          ?.map((num) => parseInt(num, 10));
        if (
          isNaN(hours) ||
          isNaN(minutes) ||
          hours < 0 ||
          hours > 23 ||
          minutes < 0 ||
          minutes > 59
        ) {
          return 'Invalid time';
        }
        const period = hours >= 12 ? 'PM' : 'AM';
        const hours12 = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const res = `${hours12}:${formattedMinutes} ${period}`;
        return res;
      };

      for (let i = 0; i < maxLength; i++) {
        const before = schedulesBefore[i] || {};
        const after = schedulesAfter[i] || {};
        const indexLabel = `${i + 1}${getSuffix(i + 1)}`;
        const timeValues = `${getSuffix(i + 1)}`;

        Object.keys({ ...before, ...after }).forEach((key) => {
          if (
            before[key] !== after[key] &&
            key !== 'MediaNameId' &&
            key !== 'MediaDetailsId'
          ) {
            differences.push({
              field: `${indexLabel} ${translate(
                'AgentRotationSchedules'
              )} ${translate(key)}`,
              oldValue: before[key]
                ? key === 'FromDate' || key === 'ToDate'
                  ? moment(before[key]).format('DD-MM-YYYY')
                  : key === 'FromTime' || key === 'ToTime'
                  ? formatTimeToAMPM(before[key])
                  : before[key]
                : null,
              newValue: after[key]
                ? key === 'FromDate' || key === 'ToDate'
                  ? moment(after[key]).format('DD-MM-YYYY')
                  : key === 'FromTime' || key === 'ToTime'
                  ? formatTimeToAMPM(after[key])
                  : after[key]
                : null,
            });
          }
        });
      }
    }

    return differences;
  };

  const onPageIndexChanged = (pageIndex) => {
    setFilter({ id: 'pageIndex', value: pageIndex });
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter({ id: 'pageSize', value: pageSize });
  };

  useEffect(() => {
    getAgentHistory();
  }, [filter]);

  const MAX_LENGTH = 30;

  const isTimeOrDateField = (field) =>
    field.toLowerCase().includes('time') ||
    field.toLowerCase().includes('date');

  const FormatValueWithViewMore = ({ value, isTimeOrDate }) => {
    const [expanded, setExpanded] = useState(false);

    if (isTimeOrDate) return value || 'N/A';

    const stringValue = String(value);
    if (stringValue.toLowerCase() === 'true') return 'On';
    if (stringValue.toLowerCase() === 'false') return 'Off';

    const translatedValue = translate(stringValue) || 'N/A';

    if (translatedValue.length > MAX_LENGTH && !expanded) {
      return (
        <>
          {translatedValue.substring(0, MAX_LENGTH)}...
          <ButtonBase
            onClick={(e) => {
              e.stopPropagation();
              setExpanded(true);
            }}
          >
            View More
          </ButtonBase>
        </>
      );
    }

    return translatedValue;
  };

  return (
    <>
      <div className='agent-history-tab-wrapper p-relative'>
        <Spinner
          isAbsolute
          isActive={isLoading}
        />
        <div>
          <TableContainer>
            <Table className='global-history-component-table-wrapper'>
              <TableHead>
                <TableRow>
                  {headerData?.map((item) => (
                    <TableCell
                      align={item.align}
                      key={item.id}
                      className='header-table-cell'
                    >
                      {translate(item?.title) || ''}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.result?.map((item, index) => (
                  <React.Fragment key={item.agentHistoryId}>
                    <TableRow
                      className={`global-history-component-row ${
                        index % 2 === 0 ? 'odd-row' : 'even-row'
                      }`}
                      onClick={() => onExpandClicked(item)}
                    >
                      <TableCell
                        className='table-body-cell'
                        align='left'
                      >
                        <IconButton
                          aria-label='expand row'
                          size='small'
                          disabled={item?.actionType?.toLowerCase() === 'read'}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (item?.actionType?.toLowerCase() !== 'read')
                              onExpandClicked(item);
                          }}
                        >
                          {expandedRow === item?.agentHistoryId ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                        {moment(item.actionDate).format(
                          'DD/MM/YYYY - hh:mm A'
                        ) || 'N/A'}
                      </TableCell>
                      <TableCell
                        className='table-body-cell'
                        align='center'
                      >
                        {item.actionBy || 'N/A'}
                      </TableCell>
                      <TableCell
                        className='table-body-cell'
                        align='right'
                      >
                        {translate(item.actionType) || 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={expandedRow === item?.agentHistoryId}
                          timeout='auto'
                          unmountOnExit
                        >
                          <Box margin={1}>
                            <Table
                              size='small'
                              aria-label='purchases'
                            >
                              <TableHead>
                                <TableRow>
                                  {changeHeaderData?.map((header) => (
                                    <TableCell
                                      align={header.align}
                                      key={header.id}
                                      className='header-table-cell'
                                    >
                                      {translate(header?.title) || ''}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {differences[item.agentHistoryId]?.map(
                                  (diff, index) => (
                                    <TableRow
                                      key={index}
                                      className={`global-history-component-row ${
                                        index % 2 === 0 ? 'odd-row' : 'even-row'
                                      }`}
                                    >
                                      <TableCell align='left'>
                                        {translate(diff.field)}
                                      </TableCell>
                                      <TableCell align='center'>
                                        <FormatValueWithViewMore
                                          value={diff?.oldValue}
                                          isTimeOrDate={isTimeOrDateField(
                                            diff.field
                                          )}
                                        />
                                      </TableCell>
                                      <TableCell align='right'>
                                        <FormatValueWithViewMore
                                          value={diff?.newValue}
                                          isTimeOrDate={isTimeOrDateField(
                                            diff.field
                                          )}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className='pagination-wrapper-for-agent-history-tab'>
          <PaginationComponent
            pageIndex={filter?.pageIndex || 0}
            pageSize={filter?.pageSize || 25}
            totalCount={data?.totalCount || 0}
            onPageIndexChanged={onPageIndexChanged}
            onPageSizeChanged={onPageSizeChanged}
          />
        </div>
      </div>
    </>
  );
}

export default AgentHistoryTab;
