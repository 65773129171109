import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase, Button } from '@material-ui/core';
import { AgentsTabelView } from './AgentsTabelView/AgentsTabelView';
import { Inputs, PermissionsComponent, SelectComponet, AutocompleteComponent } from '../../../Components';
import { useTitle } from '../../../Hooks';
import { AgentsPermissions } from '../../../Permissions';
import { GetTeam } from '../../../Services';
import { GetAllTeamLeadUsers } from '../../../Services/userServices';

const parentTranslationPath = 'Agents';
const translationPath = '';

export const AgentsView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };

    return {
      ...action.value,
    };
  }, []);
  const [searchedItem, setSearchedItem] = useState('');
  const [searchedEmailItem, setSearchedEmailItem] = useState('');
  const [searchedBranchNameItem, setSearchedBranchNameItem] = useState('');
  const [searchedRotationSchemaItem, setSearchedRotationSchemaItem] = useState('');
  const [selected, setSelected] = useReducer(reducer, {
    TeamName: null,
    TeamLead: null,
  });

  const [data, setData] = useReducer(reducer, {
    TeamName: [],
    TeamLead: [],
  });
  const [loading, setIsLoading] = useReducer(reducer, {
    TeamName: false,
    TeamLead: false,
  });
  const searchTimer = useRef(null);
  const [orderBy, setOrderBy] = useState(null);
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: null,
    orderBy: null,
  });
  const [actionType, setActionType] = useState({ id: null, value: null });
  const [selectedAgentIds, setSelectedAgentIds] = useState([]);
  const [isStatusBulkUpdateDialogOpen, setIsStatusBulkUpdateDialogOpen] = useState(false);

  useTitle(t(`${translationPath}Agents`));

  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    filterBy: null,
    orderBy: null,
    agentName: '',
    email: '',
    isInRotation: null,
    branchName: '',
    teamLeadId: null,
    teamId: null,
    isBlocked:null

  });
  const getTeam = useCallback(async (searchItem) => {
    setIsLoading({
      id: 'TeamName',
      value: true
    });
    const response = await GetTeam(1, 25, (searchItem || ''));
    if (!(response && response.status && response.status !== 200)) {
      setData({
        id: 'TeamName',
        value: response && response.result
      });
    } else {
      setData({
        id: 'TeamName',
        value: []
      });
    }

    setIsLoading({
      id: 'TeamName',
      value: false
    });
  }, []);
  const getAllTeamLeadUsers = useCallback(async (teamLeadSearch) => {
    setIsLoading({
      id: 'TeamLead',
      value: true
    });
    const res = await GetAllTeamLeadUsers({ pageIndex: 1, pageSize: 25, search: teamLeadSearch });
    if (!(res && res.status && res.status !== 200))
      setData({
        id: 'TeamLead',
        value: res && res.result
      })
    else
      setData({
        id: 'TeamLead',
        value: []
      })
    setIsLoading({
      id: 'TeamLead',
      value: false
    });
  }, []);
  useEffect(() => {
    getTeam()
  }, [])
  const searchHandler = () => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({
        ...item, agentName: searchedItem,
      }));
    }, 700);
  };

  const searchEmailHandler = () => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({
        ...item, email: searchedEmailItem,
      }));
    }, 700);
  };

  const searchBranchNameHandler = () => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({
        ...item, branchName: searchedBranchNameItem,
      }));
    }, 700);
  };

  const searchRotationSchemaHandler = () => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setFilter((item) => ({
        ...item, schemaName: searchedRotationSchemaItem || null,
      }));
    }, 700);
  };



  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, orderBy: value }));
  };

  const orderBySubmitted = (event) => {
    event.preventDefault();
    if (!selectedOrderBy.filterBy || !selectedOrderBy.orderBy) {
      if (orderBy.filterBy || orderBy.orderBy) setOrderBy({});
      return;
    }

    setFilter((item) => ({
      ...item,
      orderBy: selectedOrderBy.orderBy,
      filterBy: selectedOrderBy.filterBy

    }));
  };

  const actionTypeChanged = (newValue) => {
    setActionType((item) => ({ ...item, id: newValue }));
  }

  const onActionsButtonClicked = (newValue) => {

    if (newValue?.id === 'bulk-status-update') {
      setIsStatusBulkUpdateDialogOpen(true);
    }
    setActionType((item) => ({ ...item, id: null }));
  };

  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );

  const ChangeRotationStateActions = (value) => {
    setFilter((item) => ({ ...item, isInRotation: value }));
  };

  return (
    <div className='Agents-wrapper view-wrapper'>
      <div className='w-100 px-2'>
        <PermissionsComponent
          permissionsList={Object.values(AgentsPermissions)}
          permissionsId={AgentsPermissions.ViewAllAgents.permissionsId}
        >
          <div className='d-flex-v-center-h-between'>
            <div className='w-50'>
              <div className='d-flex-v-center w-50 mx-3'>
                {!actionType.id && <span className='fw-simi-bold'>
                  {t(`${translationPath}agent-actions`)}
                  :
                </span>}
                {actionType.id && <Button
                  disabled={!selectedAgentIds || (selectedAgentIds && selectedAgentIds.length === 0)}
                  type='button'
                  onClick={() => onActionsButtonClicked(actionType)}
                  className="btns theme-solid action-button"
                >
                  <span>{t(`${translationPath}${actionType.id}`)}</span>
                </Button>}
                <SelectComponet
                  idRef='filterByRefbulk-status-update"'
                  data={[
                    { id: "bulk-status-update", value: "bulk-status-update" },
                  ]}
                  valueInput='id'
                  textInput='value'
                  value={actionType.id}
                  onSelectChanged={actionTypeChanged}
                  wrapperClasses=' w-50 mx-2'
                  emptyItem={{ value: null, text: 'select', isDisabled: false }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>

            </div>
            <div className='w-100'>
              <div className='px-2'>
                <Inputs
                  value={searchedItem}
                  onKeyUp={searchHandler}
                  onInputChanged={(e) => setSearchedItem(e.target.value)}
                  idRef='AgentsRef'
                  label={t(`${translationPath}search-in-Agents`)}
                  inputPlaceholder={t(`${translationPath}search-Agents`)}
                  beforeIconClasses='mdi mdi-account-search-outline mdi-24px c-gray-primary'
                />
              </div>
              <div className='d-flex-column ml-4'>

              
                <div className='d-flex'>
                  <div className='w-50 mr-1'>
                    <Inputs
                      value={searchedEmailItem}
                      onKeyUp={searchEmailHandler}
                      onInputChanged={(e) => setSearchedEmailItem(e.target.value)}
                      idRef='AsssstsemailRef'
                      inputPlaceholder={t(`${translationPath}Agents-email`)}
                    />
                  </div>
                  <div className='w-50 mr-1'>
                    <Inputs
                      value={searchedBranchNameItem}
                      onKeyUp={searchBranchNameHandler}
                      onInputChanged={(e) => setSearchedBranchNameItem(e.target.value)}
                      idRef='AsearchedbranchNameItemRef'
                      inputPlaceholder={t(`${translationPath}branchName`)}
                    />
                  </div>
                  <div className='w-50 mr-1'>
                    <Inputs
                      value={searchedRotationSchemaItem}
                      onKeyUp={searchRotationSchemaHandler}
                      onInputChanged={(e) => setSearchedRotationSchemaItem(e.target.value)}
                      idRef='RotationSchemaRef'
                      inputPlaceholder={t(`${translationPath}RotationSchema`)}
                    />
                  </div>
                </div>


                <div className='d-flex'>
                  <div className='mr-1'>
                    <SelectComponet
                      idRef='ONfilterByRefOff'
                      data={[
                        { id: true, filterBy: 'ON' },
                        { id: false, filterBy: 'Off' },
                      ]}
                      wrapperClasses="w-auto"
                      value={filter.isInRotation}
                      onSelectChanged={ChangeRotationStateActions}
                      valueInput='id'
                      textInput='filterBy'
                      emptyItem={{
                        value: null,
                        text: 'all-Rotation',
                        isDisabled: false,
                      }}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      translationPathForData={translationPath}
                    />
                  </div>
                  <div className='mr-1'>
                    <SelectComponet
                      idRef='agentsBlockedRef'
                      data={[
                        { id: 'true', value: 'blocked' },
                        { id: 'false', value: 'active' },
                      ]}
                      wrapperClasses="w-auto"
                      value={filter.isBlocked}
                      onSelectChanged={(value)=>setFilter((prev) => ({ ...prev, isBlocked: value }))}
                      valueInput='id'
                      textInput='value'
                      emptyItem={{
                        value: null,
                        text: 'select-status',
                        isDisabled: false,
                      }}
                      parentTranslationPath={parentTranslationPath}
                      themeClass="theme-default"
                    />
                  </div>
                  <div className='w-50 mr-1'>
                    <AutocompleteComponent
                      idRef="TeamNameRef"
                      selectedValues={selected.TeamName || ''}
                      multiple={false}
                      data={data.TeamName}
                      displayLabel={(option) => option.teamsName}
                      onOpen={() => {
                        if (data.teamName && data.teamName.length === 0)
                          getTeam()
                      }}
                      onChange={(event, newValue) => {
                        setSelected({
                          id: 'TeamName',
                          value: newValue || null,
                        })
                        setFilter((item) => ({
                          ...item, teamId: newValue?.teamsId || null,
                        }));
                      }}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer) clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                          getTeam(value);
                        }, 1200);
                      }}
                      withoutSearchButton
                      inputPlaceholder={t(`${translationPath}TeamName`)}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      isLoading={loading.TeamName}
                    />

                  </div>
                  <div className='w-50'>
                    <AutocompleteComponent
                      idRef="TeamNameRef"
                      selectedValues={selected.TeamLead || null}
                      multiple={false}
                      data={data.TeamLead}
                      displayLabel={(option) => option.teamLeadName}
                      onOpen={() => {
                        if (data.TeamLead && data.TeamLead.length === 0)
                          getAllTeamLeadUsers()
                      }}
                      onChange={(event, newValue) => {
                        setSelected({
                          id: 'TeamLead',
                          value: newValue || null,
                        })
                        setFilter((item) => ({
                          ...item, teamLeadId: newValue?.applicationUserId || null,
                        }));
                      }}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer) clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                          getAllTeamLeadUsers(value);
                        }, 1200);
                      }}
                      withoutSearchButton
                      inputPlaceholder={t(`${translationPath}TeamLeadName`)}
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onKeyDown={() => {
                        setSelected({ id: 'TeamLead', value: null });
                      }}
                      isLoading={loading.TeamLead}
                    />

                  </div>
                </div>


              </div>
            </div>
          </div>
          <div className='d-flex px-2'>
            <span className='mx-2 mt-1'>{t(`${translationPath}select`)}</span>
            <span className='separator-v s-primary s-reverse s-h-25px mt-1' />
            <span className='px-2 d-flex'>
              <span className='texts-large mt-1'>
                {t(`${translationPath}order-by`)}
                :
              </span>
              <div className='px-2'>
                <SelectComponet
                  idRef='select-filter-by-filterByRef'
                  data={[
                    { id: 'CreatedOn', filterBy: 'created-on' },
                    { id: 'UpdateOn', filterBy: 'last-updated' },
                  ]}
                  wrapperClasses='mb-3'
                  isRequired
                  value={selectedOrderBy.filterBy}
                  onSelectChanged={filterByChanged}
                  valueInput='id'
                  textInput='filterBy'
                  emptyItem={{
                    value: null,
                    text: 'select-filter-by',
                    isDisabled: false,
                  }}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div className='px-2'>
                <SelectComponet
                  idRef='orderByRef'
                  data={[
                    { id: 1, orderBy: 'ascending' },
                    { id: 2, orderBy: 'descending' },
                  ]}
                  value={selectedOrderBy.orderBy}
                  onSelectChanged={orderByChanged}
                  emptyItem={{ value: null, text: 'select-sort-by', isDisabled: false }}
                  wrapperClasses='mb-3'
                  isRequired
                  valueInput='id'
                  textInput='orderBy'
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  translationPathForData={translationPath}
                />
              </div>
              <div className='mt-1'>
                <ButtonBase
                  className='btns theme-solid'
                  onClick={orderBySubmitted}
                  id='action_apply'
                  disabled={!selectedOrderBy.filterBy || !selectedOrderBy.orderBy}
                >
                  <span>{t(`${translationPath}apply`)}</span>
                </ButtonBase>
              </div>
            </span>

          </div>
          <AgentsTabelView
            filter={filter}
            setFilter={setFilter}
            actionType={actionType}
            isStatusBulkUpdateDialogOpen={isStatusBulkUpdateDialogOpen}
            setIsStatusBulkUpdateDialogOpen={setIsStatusBulkUpdateDialogOpen}
            selectedAgentIds={selectedAgentIds}
            setSelectedAgentIds={setSelectedAgentIds}
            setSearchedItem={setSearchedItem}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </PermissionsComponent>
      </div>
    </div>
  );
};
