import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation ,useHistory } from "react-router-dom";
import { PermissionsComponent, Tables } from '../../../../Components';
import { LeadsPoolContext } from '../LeadsPoolContext';
import {
  ActionsButtonsEnum,
  TableActions,
  TableFilterTypesEnum,
} from '../../../../Enums';
import { GlobalHistory } from '../../../../Helper';
import { returnPropsByPermissions } from '../../../../Helper';
import { LeadsPoolSalesPermissions, LeadsPoolCallCenterPermissions, LeadsPoolLeasePermissions } from '../../../../Permissions';
import LeadAssignmentStatusDialog from './LeadAssignmentStatusDialog';
export const LeadsPoolTable = ({ parentTranslationPath, translationPath }) => {
  const {
    data,
    filter,
    setFilter,
    isDataLoading,
    nationalities,
    languages,
    viewType,
    checkedCardsIds,
    setCheckedCards,
    activeAction,
    leadCategories,
    permissions,
    leadTypes,
    isClearFiltersClicked,
    setIsClearFiltersClicked
  } = useContext(LeadsPoolContext);

  const location = useLocation();
  const history = useHistory()
  const [sortBy, setSortBy] = useState(null);

  const [jobId, setJobId] = useState(null); 
  const [isLeadStatusDialogOpen , setIsLeadStatusDialogOpen] = useState(false)
  const ViewTypePermissions = {
    contact: [
      LeadsPoolCallCenterPermissions.ViewLeadName.permissionsId,
      LeadsPoolCallCenterPermissions.ViewRemarksColumn.permissionsId,
      LeadsPoolCallCenterPermissions.OpenLeadFile.permissionsId
    ],
    lease: [
      LeadsPoolLeasePermissions.ViewLeadName.permissionsId,
      LeadsPoolLeasePermissions.ViewRemarksColumn.permissionsId,
      LeadsPoolLeasePermissions.OpenLeadFile.permissionsId
    ],
    sales: [
      LeadsPoolSalesPermissions.ViewLeadName.permissionsId,
      LeadsPoolSalesPermissions.ViewRemarksColumn.permissionsId,
      LeadsPoolSalesPermissions.OpenLeadFile.permissionsId
    ]
  };
  const headerData = [
    {
      label: 'lead-name',
      input: 'leadName',
      isSortable: true,
      filterType: TableFilterTypesEnum.textInput.key,
      searchableKey: 'LeadName',
      permissions: [
        LeadsPoolSalesPermissions.ViewLeadName.permissionsId,
        LeadsPoolLeasePermissions.ViewLeadName.permissionsId,
        LeadsPoolCallCenterPermissions.ViewLeadName.permissionsId
      ]
    },
    {
      label: 'lead-id',
      input: 'leadId',
      isNumber: true,
      filterType: TableFilterTypesEnum.numberInput.key,
      isSortable: true,
      searchableKey: 'LeadId',
    },
    {
      label: 'lead-category',
      input: 'leadType',
      filterType: TableFilterTypesEnum.selectOption.key,
      optionFilterList: leadCategories.map((item) => ({
        key: item.id,
        value: item.name,
      })),
      searchableKey: 'leadCategoryId',
    },
    {
      label: 'lead-type',
      input: 'leadCategory',
      filterType: TableFilterTypesEnum.selectOption.key,
      optionFilterList: leadTypes.map((item) => ({
        key: item.id,
        value: item.name,
      })),
      searchableKey: 'leadTypeId',
    },
    {
      label: 'nationality',
      input: 'nationality',
      filterType: TableFilterTypesEnum.selectOption.key,
      optionFilterList: nationalities.map((item) => ({
        key: item.lookupItemName,
        value: item.lookupItemName,
      })),
      isSortable: true,
      searchableKey: 'Nationality',
    },
    {
      label: 'language',
      input: 'language',
      filterType: TableFilterTypesEnum.selectOption.key,
      optionFilterList: languages.map((item) => ({
        key: item.lookupItemName,
        value: item.lookupItemName,
      })),
      isSortable: true,
      searchableKey: 'Language',
    },
    {
      label: 'bedrooms',
      input: 'bedrooms',
      filterType: TableFilterTypesEnum.textInput.key,
      isSortable: true,
      searchableKey: 'Bedrooms',
    },
    {
      label: 'size',
      input: 'areaSize',
      filterType: TableFilterTypesEnum.textInput.key,
      searchableKey: 'size',
      displayPath: 'AreaSize',
    },
    {
      label: 'size-from',
      input: 'areaSizeFrom',
      filterType: TableFilterTypesEnum.textInput.key,
      searchableKey: 'AreaSizeFrom',
    },
    {
      label: 'size-to',
      input: 'areaSizeTo',
      filterType: TableFilterTypesEnum.textInput.key,
      searchableKey: 'AreaSizeTo',
    },
    {
      label: 'price-from',
      input: 'priceFrom',
      filterType: TableFilterTypesEnum.textInput.key,
      searchableKey: 'PriceFrom',
    },
    {
      label: 'price-to',
      input: 'priceTo',
      filterType: TableFilterTypesEnum.textInput.key,
      searchableKey: 'PriceTo',
    },
    {
      label: 'property-name',
      input: 'propertyName',
      filterType: TableFilterTypesEnum.textInput.key,
      isSortable: true,
      searchableKey: 'PropertyName',
    },
    {
      label: 'remarks',
      input: 'remarks',
      filterType: TableFilterTypesEnum.textInput.key,
      isSortable: true,
      searchableKey: 'Remarks',
      permissions: [
        LeadsPoolSalesPermissions.ViewRemarksColumn.permissionsId,
        LeadsPoolLeasePermissions.ViewRemarksColumn.permissionsId,
        LeadsPoolCallCenterPermissions.ViewRemarksColumn.permissionsId
      ]
    }
  ];

  const showColumnsHasPermission = (viewType) => {
    return headerData.filter((item) => {
      if (!item.permissions) return true;
  
      const viewPermissions = ViewTypePermissions[viewType];
  
      return item.permissions.some(permissionId => viewPermissions.includes(permissionId) && returnPropsByPermissions(permissionId));
    }).map((item, i) => ({
      id: i + 1,
      key: item.displayPath ?? item.searchableKey,
      ...item,
    }));
  };
  
  const filteredHeaderDataDependOnPermission = showColumnsHasPermission(viewType);
  

  const tableActionClicked = (actionEnum, item) => {

    if (actionEnum === TableActions.openFile.key) {
      if (viewType === 'sales') {
        GlobalHistory.push(
          `/home/lead-sales/lead-profile-edit?formType=${item.formTypeId}&id=${item.leadId}&operationType=${filter.operationLeadType}`
        );
      } else {
        GlobalHistory.push(
          `/home/lead-lease/lead-profile-edit?formType=${item.formTypeId}&id=${item.leadId}&operationType=${filter.operationLeadType}`
        );
      }
    }
  };
  const getActionTableWithPermissionsDependOnViewType = (viewType) => {
    const ViewTypePermissionsActionTable = {
      contact: [
        LeadsPoolCallCenterPermissions.OpenLeadFile.permissionsId,
      ],
      lease: [
        LeadsPoolLeasePermissions.OpenLeadFile.permissionsId
      ],
      sales: [
        LeadsPoolSalesPermissions.OpenLeadFile.permissionsId
      ]
    };
    const list = [];
    const viewPermissions = ViewTypePermissionsActionTable[viewType];
    if (viewPermissions) {
      viewPermissions.forEach(permissionId => {
        const hasPermission = returnPropsByPermissions(permissionId);
        if (hasPermission) {
          if (!list.some(action => action.enum === TableActions.openFile.key)) {
            list.push({
              enum: TableActions.openFile.key,
              isDisabled: false,
              externalComponent: null,
            });
          }
        }
      });
    } 
    return list;
  };
  useEffect(() => {
    if (sortBy) {
      setFilter((filter) => ({
        ...filter,
        filterBy: sortBy.orderBy,
        orderBy: sortBy.orderType,
      }));
    }
  }, [sortBy]);
  const getIsSelected = useCallback(
    (row) =>
      checkedCardsIds &&
      checkedCardsIds.findIndex((item) => item === row.leadId) !== -1,
    [checkedCardsIds]
  );
  const getIsSelectedAll = useCallback(() => {
    const returnSelect =
      (checkedCardsIds &&
        data.result.findIndex((item) => !checkedCardsIds.includes(item.id)) ===
        -1) ||
      false;
    return returnSelect;
  });
  const onSelectAllClicked = () => {
    const cardItems = [];
    const isSelectOneOnly = activeAction === 'qualify-lead';
    if (isSelectOneOnly) return;

    if (!getIsSelectedAll()) {
      if (data && data.result) {
        data.result.map((item) => {
          if (!getIsSelected(item)) {
            checkedCardsIds.push(item.id);
            cardItems.push({ ...item });
          }
        });
      }
    } else {
      setCheckedCards([]);
      data.result.map((item) => {
        if (getIsSelected(item)) {
          const isSelectedIndex = checkedCardsIds.findIndex(
            (element) => element === item.id
          );
          if (isSelectedIndex !== -1)
            checkedCardsIds.splice(isSelectedIndex, 1);
        }
      });
    }
    setCheckedCards(cardItems);
  };
  const onSelectClicked = useCallback(
    (row) => {
      const itemIndex = checkedCardsIds.findIndex(
        (item) => item === row.leadId
      );

      if (itemIndex !== -1) {
        checkedCardsIds.splice(itemIndex, 1);
        setCheckedCards((items) => {
          const elementIndex = items.findIndex(
            (item) => item.leadId === row.leadId
          );
          if (elementIndex !== -1) items.splice(elementIndex, 1);
          return [...items];
        });
      } else {
        setCheckedCards((items) => {
          return items.concat(row);
        });
      }
    },
    [checkedCardsIds, activeAction]
  );

  useEffect(() => {
    const newJobId = location.state?.jobId;

    if (newJobId) {
      setJobId(newJobId); 
      setIsLeadStatusDialogOpen(true);
  
      history.replace({ pathname: location.pathname, state: {} });
    }
  }, [location.state?.jobId]); 

  return (
    <PermissionsComponent
      permissionsList={Object.values(permissions)}
      permissionsId={permissions.ViewLeadsPool.permissionsId}
    >
      <div className='w-100'>
        <Tables
          filterData={filteredHeaderDataDependOnPermission.map((item) => ({
            key: item.key,
            id: item.id,
            filterType: item.filterType,
            searchableKey: item.searchableKey ?? item.input,
            isHiddenFilter: item.isHiddenFilter,
            optionFilterList: item.optionFilterList,
            filterBy: item.filterBy,
          }))}
          selectAllOptions={
            activeAction && activeAction !== ActionsButtonsEnum[1].id
              ? {
                  selectedRows: checkedCardsIds,
                  getIsSelected,
                  disabledRows: [],
                  withCheckAll: true,
                  onSelectAllClicked,
                  isSelectAll: getIsSelectedAll(),
                  onSelectClicked,
                }
              : undefined
          }
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          data={data.result}
          totalItems={data.totalCount}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex - 1}
          onPageIndexChanged={(index) =>
            setFilter((filter) => ({ ...filter, pageIndex: index + 1 }))
          }
          onPageSizeChanged={(size) =>
            setFilter((filter) => ({ ...filter, pageSize: size }))
          }
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          isWithFilter
          defaultActions={getActionTableWithPermissionsDependOnViewType(
            viewType
          )}
          FilterDisabledButton
          isLoading={isDataLoading}
          headerData={filteredHeaderDataDependOnPermission}
          onFilterValuesChanged={(e) => {
            if (e && !!Object.values(e).length) {
              const { value, searchableKey } = Object.values(e)[0];
              setFilter((filter) => ({
                ...filter,
                [searchableKey]: value,
              }));
            } else {
              setFilter((filter) => {
                const updatedFilter = Object.entries(filter).filter(
                  ([_key, value]) => !!value
                );
                return Object.fromEntries(updatedFilter);
              });
            }
          }}
          setSortBy={setSortBy}
          isWithOrderBy
          isClearFiltersClicked={isClearFiltersClicked}
          setIsClearFiltersClicked={setIsClearFiltersClicked}
        />
      </div>
      {isLeadStatusDialogOpen && (
        <LeadAssignmentStatusDialog
          isOpen
          onClose={() => setIsLeadStatusDialogOpen(false)}
          jobId={jobId}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </PermissionsComponent>
  );  
};
