export const AMLTransactionTypeEnum = {
  1: {
    key: 1,
    name: "sale",
    value: "sale",
    classes: "c-success-light",
  },
  2: {
    key: 2,
    name: "reserved-sale",
    value: "ReservedSale",
    classes: "c-primary",
  },
  3: {
    key: 3,
    name: "lease",
    value: "Lease",
    classes: "c-primary",
  },
  4: {
    key: 4,
    name: "reserved-lease",
    value: "ReservedLease",
    classes: "c-primary",
  },
};
