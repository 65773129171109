import React, { useReducer, useCallback, useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { GetLeadsReferredByData } from "../../../../../../../../Helper";
import {
  AddNewLeadDialog,
  MyLeadFieldsValidations,
} from "../../../../../../MyLeadsView/MyLeadsUtilities";
import { LeadsStatusEnum } from "../../../../../../../../Enums";
import { GetLookupItemsByLookupTypeName } from "../../../../../../../../Services";

const parentTranslationPath = "MyLeadView";
const translationPath = "";
export const LeadQuickAddDialog = ({ isOpen, close }) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const [schema, setSchema] = useState({});
  const loginResponse = useSelector((state) => state.login.loginResponse);

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const reducer2 = useCallback((selected, action) => {
    if (action.id !== "edit") return { ...selected, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const defaultState = {
    leadClassId: null,
    salutationId: null,
    firstName: null,
    lastName: null,
    mobileNumbers: [],
    emailAddresses: [],
    languageId: null,
    nationalityId: null,
    cityId: null,
    districtId: null,
    communityId: null,
    propertyId: null,
    unitTypeId: null,
    numberOfBedrooms: [],
    numberOfBathrooms: [],
    priceFrom: null,
    priceTo: null,
    areaFrom: null,
    areaTo: null,
    leadStatusId: LeadsStatusEnum.Open.status,
    leadRatingId: null,
    mediaNameId: null,
    mediaDetailId: null,
    referredTo: null,
    referredBy: loginResponse && loginResponse.userId,
    isForAutoRotation: false,
    MethodOfContact: null,
    closedReasonId: null,
    data_completed: 16,
    propertyCampaignId: null,
    contactClassId: null,
    isSmartRotation : false
  };

  const [state, setState] = useReducer(reducer, defaultState);
  const deafultSelected = {
    nationality: null,
    property: null,
    unitType: null,
    leadStatus: null,
    leadRating: null,
    clientSource: null,
    referredTo: null,
    referredBy: loginResponse,
    closeLeadResoun: null,
    campaign_name: null,
    language: null,
    contactClass: null,
  };

  const [selected, setSelected] = useReducer(reducer2, deafultSelected);
  const onSelectedChangeHandler = (valueId, newValue) => {
    setSelected({ id: valueId, value: newValue });
  };

  useEffect(() => {
    setSchema(MyLeadFieldsValidations(state, translationPath, t));
  }, [state, t]);

  const GetLeadsReferredBy = useCallback(async () => {
    const leadReferred = await GetLeadsReferredByData();
    if (leadReferred) {
      setSelected({ id: "referredBy", value: leadReferred });
      setState({ id: "referredBy", value: leadReferred.id });
    }
  }, []);

  const GetLeadStatus = useCallback(async () => {
    let openLeadStatus = null;
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: "LeadStatus",
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      openLeadStatus =
        res.result &&
        res.result.find((s) => s.lookupItemName === LeadsStatusEnum.Open.key);
      if (openLeadStatus) {
        setSelected({ id: "leadStatus", value: openLeadStatus });
        setState({ id: "leadStatusId", value: openLeadStatus.lookupItemId });
      } else {
        setSelected({ id: "leadStatus", value: null });
        setState({ id: "leadStatusId", value: null });
      }
    } else {
      setSelected({ id: "leadStatus", value: null });
      setState({ id: "leadStatusId", value: null });
    }
  }, []);

  useEffect(() => {
    GetLeadsReferredBy();
    GetLeadStatus();
  }, []);

  return (
    <AddNewLeadDialog
      isOpenChanged={() => {
        close();
        setState({ id: "edit", value: defaultState });
        setSelected({ id: "edit", value: defaultState });
      }}
      reloadData={() => {
        close();
        setState({ id: "edit", value: defaultState });
        setSelected({ id: "edit", value: defaultState });
      }}
      isQuickAdd
      state={state}
      schema={schema}
      isOpen={isOpen}
      selected={selected}
      setState={setState}
      translationPath={translationPath}
      parentTranslationPath={parentTranslationPath}
      onSelectedChangeHandler={onSelectedChangeHandler}
      setSelected={setSelected}
    />
  );
};
LeadQuickAddDialog.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
