import React, { memo, useCallback, useState } from "react";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { showWarn } from "../../../../../Helper";
import ProposalThemeDialog from "./ProposalThemeDialog";
import { useSelector } from "react-redux";

const ProposalStartButton = memo(({ singleUnit, buttonType, checkboxSelectHandler }) => {
    const parentTranslationPath = "SmartProposal";
    const translationPath = "ProposalThemeDialog.";
    const { t } = useTranslation(parentTranslationPath);


    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const { checkedUnits } = useSelector(
      (state) => state.SmartProposalReducer);

    const openThemeDialog = useCallback((event) => {
      event.stopPropagation();
      setIsDialogOpen(true);
    }, []);

    const activeCheckboxesHandler = ({ event, isActive }) => {
      event.stopPropagation();
      checkboxSelectHandler(isActive);
    }

    const getCheckedPropertyCount = () => {
      let count = 0;
      if(!checkedUnits) return count;
      const localCheckedProperties = {};
      checkedUnits.forEach((item) => {
        if (localCheckedProperties[item.property_name?.id]) {
          localCheckedProperties[item.property_name?.id].units.push(item.id);
        } else
          localCheckedProperties[item.property_name?.id] = {
            propertyId: item.property_name?.id,
            propertyName: item.property_name?.name,
            units: [item.id],
          };
      });
      count = Object.values(localCheckedProperties).length;
      return count;
    }
    

    const counterBtnClickHandler = (event) => { 
           
      if(getCheckedPropertyCount() == 0 ) showWarn(t(`${translationPath}You-must-select-at-least-one-unit`));
      else if(getCheckedPropertyCount() > 10 ) showWarn(t(`${translationPath}You-can-select-ten-properties-at-max`));
      else openThemeDialog(event);
    };

    
    return (
      <>
        {buttonType === "icon" && (
          <div className="archive-bbt">
            <Button
              onClick={openThemeDialog}
              className="MuiButtonBase-root MuiButton-root MuiButton-text btns-icon theme-solid mx-2 p-2"
              title={t(`${translationPath}smart-proposal`)}
            >
              <span className="MuiButton-label">
                <span className="mdi mdi-rocket" />
              </span>
              <span className="MuiTouchRipple-root" />
            </Button>
          </div>
        )}
        {buttonType === "solid" && (
          <Button onClick={openThemeDialog} className="btns theme-solid px-3">
            <span className="mdi mdi-rocket px-1" />
            <span>{t(`${translationPath}smart-proposal`)}</span>
          </Button>
        )}
        {buttonType === "select-outline" && (
          <Button
            onClick={(event) => activeCheckboxesHandler({ event, isActive: true })}
            className="btns theme-outline c-secondary px-2 Proposal_selection__Action_btn"
          >
            <span className="mdi mdi-rocket p-1" />
            <span>{t(`${translationPath}smart-proposal`)}</span>
          </Button>
        )}
        {buttonType === "select-counter" && (
          <Button
            onClick={counterBtnClickHandler}
            className="btns theme-outline c-secondary px-2 Proposal_selection__Action_btn"
          >
            <span className="d-flex-center gap-4">
              <span>{`(${checkedUnits?.length || 0})`}</span>
              <span>{t(`${translationPath}move-to-smart-proposal`)}</span>
              <span
                className="mdi mdi-close-circle"
                onClick={(event) =>
                  activeCheckboxesHandler({event, isActive: false })
                }
              ></span>
            </span>
          </Button>
        )}
        {isDialogOpen ? (
          <ProposalThemeDialog
            singleUnit={singleUnit}
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={setIsDialogOpen}
            checkboxSelectHandler={checkboxSelectHandler}
          />
        ) : null}
      </>
    )}, (prevProps, nextProps) => {
  return prevProps.buttonType === nextProps.buttonType }
)

export default ProposalStartButton;

ProposalStartButton.propTypes = {
  singleUnit: PropTypes.object,
  buttonType: PropTypes.oneOf([
    "icon",
    "solid",
    "select-outline",
    "select-counter",
  ]),
};
ProposalStartButton.defaultProps = {
  buttonType: "icon",
};
