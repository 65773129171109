import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import {
  ProprtyInfoComponent,
  PropertyTimelineComponent,
  PropertyDetailsComponent,
  UnitesComponent,
  LocationComponent,
  PaymentPlanComponent,
  UnitsModelsComponent,
  PropertySpecificationComponent,
  AmenitiesFacilitiesComponent,
} from "./UI";
import { GetPropertyMarketingByPropertyId } from "../../../../../../Services";

//  styles
import useStyles from "./styles";

function PropertyInfo({
  setTabValue,
  propretyId,
  propretyResponseJson,
  propretyResponse,
}) {
  const styles = useStyles();
  const [FullPropertyMarketing, setFullPropertyMarketing] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getPropertyMarketingByPropertyId = useCallback(async () => {
    setIsLoading(true);
    const res = await GetPropertyMarketingByPropertyId(propretyId);

    if (!(res && res.status && res.status !== 200)) {
      if (res && res.length > 0) {
        setFullPropertyMarketing(res);
      }
    }
    setIsLoading(false);
  }, [propretyId]);

  useEffect(() => {
    getPropertyMarketingByPropertyId();
  }, []);

  return (
    <Box className={styles.containerPropretyInfo}>
      <Box className={styles.containerFirstCol}>
        <ProprtyInfoComponent
          propretyId={propretyId}
          propretyResponseJson={propretyResponseJson}
          propretyResponse={propretyResponse}
          fullPropertyMarketing={FullPropertyMarketing}
        />
        <Box className={styles.containerPropretyDetailTimeLine}>
          <PropertyDetailsComponent
            propretyId={propretyId}
            propretyResponseJson={propretyResponseJson}
            propretyResponse={propretyResponse}
          />
          <PropertyTimelineComponent
            propretyId={propretyId}
            propretyResponseJson={propretyResponseJson}
            propretyResponse={propretyResponse}
          />
        </Box>
        <UnitesComponent setTabValue={setTabValue} propretyId={propretyId} />
        <PaymentPlanComponent
          setTabValue={setTabValue}
          propretyId={propretyId}
        />
        <AmenitiesFacilitiesComponent
          setTabValue={setTabValue}
          propretyId={propretyId}
          propretyResponseJson={propretyResponseJson}
        />
        <UnitsModelsComponent
          propretyId={propretyId}
          setTabValue={setTabValue}
        />
      </Box>
      <Box className={styles.containerSecCol}>
        <LocationComponent />
        <PropertySpecificationComponent setTabValue={setTabValue} propretyId={propretyId}/>
      </Box>
    </Box>
  );
}

export default PropertyInfo;
