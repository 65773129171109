export const CoverPageTemplate = `
<div class="cover-x">
  <div class="frame">
    <div class="div">
      <div class="frame-2">
        <div class="frame-3">
          <div class="frame-4">
            <div class="text-wrapper">
              {{#CoverPage.data.completionDate}}Completion - {{CoverPage.data.completionDate}}{{/CoverPage.data.completionDate}}
            </div>
            <div class="text-wrapper-2">{{CoverPage.data.propertyName}}</div>
          </div>
          <p class="p">{{CoverPage.data.propertyLocation}}</p>
        </div>
        <div class="frame-5">
          {{#CoverPage.data.branchDetails.licenceId}}
          <div class="frame-6">
            <div class="frame-7">
              <div class="text">ID</div>
            </div>
            <div class="text-2">{{CoverPage.data.branchDetails.licenceId}}</div>
          </div>
          {{/CoverPage.data.branchDetails.licenceId}}
          <div class="frame-8">
          {{#CoverPage.data.proposalDate}}
            <div class="frame-7">
              <div class="text">Date</div>
            </div>
            <div class="text-2">{{CoverPage.data.proposalDate}}</div>
          {{/CoverPage.data.proposalDate}}
          </div>
        </div>
        <div class="frame-9">
          <img class="img" src={{{CoverPage.data.developerLogo}}} alt=""/>
          <div class="div-wrapper">
            <div class="text-wrapper-3">{{CoverPage.data.developerName}}</div>
          </div>
        </div>
      </div>
      <div class="rectangle"></div>
    </div>
  </div>
  <div class="frame-wrapper">
    <div class="frame-10">
      <div class="frame-11">
        <p class="text-wrapper-4">{{CoverPage.data.currentYear}} Property Shop Investment LLC @@All right reserved.</p>
      </div>
      <div class="frame-12">
        {{#CoverPage.data.branchDetails.branchPhoneNumber}}
          <div class="frame-13">
            <img class="img-2" src="https://psi-crm.com/ProposalIcons/phone.svg" alt=""/>
            <div class="frame-13">
              <div class="text-wrapper-4">{{CoverPage.data.branchDetails.branchPhoneNumber}}</div>
            </div>
          </div>
        {{/CoverPage.data.branchDetails.branchPhoneNumber}}
        {{#CoverPage.data.branchDetails.branchEmail}}
          <div class="frame-13">
            <img class="img-2" src="https://psi-crm.com/ProposalIcons/mail-01.svg" alt=""/>
            <div class="frame-14">
              <div class="text-wrapper-4">{{CoverPage.data.branchDetails.branchEmail}}</div>
            </div>
          </div>
        {{/CoverPage.data.branchDetails.branchEmail}}
        {{#CoverPage.data.branchDetails.branch}}
          <div class="frame-13">
            <img class="img-2" src="https://psi-crm.com/ProposalIcons/marker-pin-01.svg" alt=""/>
            <div class="frame-15">
              <div class="text-wrapper-4">{{CoverPage.data.branchDetails.branch}}</div>
            </div>
          </div>
        {{/CoverPage.data.branchDetails.branch}}
      </div>
    </div>
  </div>
  <div class="frame-16">
    <div class="avatar-label-group">
      <div class="avatar">
        <div class="contrast-border">
        {{^CoverPage.data.contactDetails.avatarImage}}
          {{CoverPage.data.contactDetails.initials}}
        {{/CoverPage.data.contactDetails.avatarImage}}
        </div>
      </div>
      <div class="text-and-supporting">
        <div class="text-3">{{CoverPage.data.contactDetails.name}}</div>
        <div class="supporting-text">{{CoverPage.data.contactDetails.jobTitle}}</div>
      </div>
    </div>
    <div class="frame-17">
      {{#CoverPage.data.contactDetails.phone}}
        <div class="frame-13">
          <div class="frame-7">
            <div class="text-and-icon">
              <img class="img-3" src="https://psi-crm.com/ProposalIcons/phone-call-01.svg" alt=""/>
            </div>
            <div class="text-wrapper-6">Phone</div>
          </div>
          <div class="text-4">{{CoverPage.data.contactDetails.phone}}</div>
        </div>
      {{/CoverPage.data.contactDetails.phone}}
      {{#CoverPage.data.contactDetails.whatsApp}}
        <div class="frame-13">
          <div class="frame-7">
            <div class="img-wrapper">
              <img class="img-3" src="https://psi-crm.com/ProposalIcons/whatsapp.svg" alt=""/>
            </div>
            <div class="text-wrapper-6">Whatsapp</div>
          </div>
          <div class="text-4">{{CoverPage.data.contactDetails.whatsApp}}</div>
        </div>
      {{/CoverPage.data.contactDetails.whatsApp}}
      {{#CoverPage.data.contactDetails.email}}
        <div class="frame-13">
          <div class="frame-7">
            <div class="img-wrapper">
              <img class="img-3" src="https://psi-crm.com/ProposalIcons/mail.svg" alt=""/>
            </div>
            <div class="text-wrapper-6">Email</div>
          </div>
          <div class="text-4">{{CoverPage.data.contactDetails.email}}</div>
        </div>
      {{/CoverPage.data.contactDetails.email}}
    </div>
    {{#CoverPage.showLogo}}
    <div class="logo">
      <img class="logo-psi" src="https://psinv.net/ConsentForm/assets/images/logo.png" alt=""/>
      <div class="text-wrapper-6">www.psinv.net</div>
    </div>
    {{/CoverPage.showLogo}}
  </div>
</div>
`;
