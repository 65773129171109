import { makeStyles } from "@material-ui/core/styles";

import { useIsAr } from "../../../../../../Hooks";

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    gridContainer: {
      display: "flex",
      flex: "1 1 100%",
      flexWrap: "wrap",
      gap: "18px",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    flex: {
      display: "flex",
      gap: "12px",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: "24px",
    },
    customSelect: {
      width: "101px",
    },
    container: {
      display: "flex",
      gap: "12px",
      alignItems: "center",
    },
    galleryImg: {
      border: `1px solid ${theme.palette.border.secondary}`,
      width: "63px",
      height: "40px",
      borderRadius: "6px",

      "& img": {
        objectFit: "contain",
      },
    },
    detailsColumn: {
      display: "flex",
      flexDirection: "column",
    },
    nameRow: {
      display: "flex",
      alignItems: "center",
      gap: "6px",
      cursor: "pointer",
    },
    nameText: {
      lineHeight: "24px",
      fontSize: "16px",
      fontWeight: 600,
      color: theme.palette.text.primary,
    },
    communityText: {
      lineHeight: "20px",
      fontSize: "14px",
      fontWeight: 500,
      margin: 0,
    },
    unitDetails: {
      display: "flex",
      gap: "8px",
      flexWrap: "wrap",
    },
    detailItem: {
      display: "flex",
      alignItems: "center",
      gap: "4px",
      fontSize: "14px",
      fontWeight: 500,
      color: theme.palette.text.tertiary,
    },
    tableActionsBox: {
      display: "flex",
      gap: "4px",
      width: "fit-content",
      height: "72px",
      padding: "16px",
      alignItems: "center",
      background: theme.palette.background.paper,
      boxShadow:
        "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    },
    actionsContainer: (props) => ({
      position: "fixed",
      right: isAr ? "unset" : props?.isDesktop ? "41px" : "25px",
      zIndex: 50,
    }),
    button: {
      minWidth: "fit-content",
      padding: "10px",
      borderRadius: "6px",
    },
  };
});
