import React, { useCallback, useState, useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import { showError } from "../../../../../../Helper";
import { QeuriesComponents } from "./Components";
import { Spinner } from "../../../../../../Components";
const parentTranslationPath = "ClientsSegmentation";
const translationPath = "";

export const AddCondationLeadForm = ({
  LeadFormData,
  setLeadFormData,
  data,
  SelectedData,
  Opation,
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [isOpenSaveDialog, setIsOpenSaveDialog] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [searchResponse, setSearchResponse] = useState({
    result: [],
    totalCount: 0,
  });

  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: null,
    orderBy: null,
  });

  const [orderTypeBy, setOrderTypeBy] = useState({
    index: 3,
    order: 2,
    searchKey: "createdOn",
    orderByCategory: "Contacts",
  });

  const [fields, setFields] = useState([]);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
    pageIndex: 0,
    search: "",
  });
  const reducer = (state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [ifHaveAnyErrors, setIfHaveAnyErrors] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [isSearchFirst, setIsSearchFirst] = useState(false);

  const [state, setState] = useReducer(reducer, {
    conditions: [
      {
        category: null,
        categoryType: null,
        searchKey: null,
        displayPath: null,
        specialKey: null,
        value: null,
        operator: null,
        operand: "AND",
        fieldName: null,
      },
    ],
  });

  const schema = Joi.array()
    .items(
      Joi.object({
        category: Joi.any().custom((value, helpers) => {
          if (!value) return helpers.error("state.userNotSet");
          return value;
        }),
        categoryType: Joi.number().required(),
        fieldName: Joi.object().required(),
        value: Joi.any()
          .allow("")
          .custom((value, helpers) => {
            if (!value) return helpers.error("state.typeNotSet");
            return value;
          }),
        operator: Joi.number().required(),
      })
    )
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state.conditions);

  const localSchema = Joi.object({
    clientSegmantationName: Joi.string()
      .required()
      .messages({
        "string.empty": t(
          `${translationPath}client-segmantation-name-is-required`
        ),
        "string.base": t(
          `${translationPath}client-segmantation-name-is-required`
        ),
      }),
    ownerId: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}owner-is-required`),
        "number.empty": t(`${translationPath}owner-is-required`),
        "number.required": t(`${translationPath}owner-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const contantValue = (conditionItem) => {
    if (conditionItem) {
      if (
        conditionItem.fieldName &&
        conditionItem.fieldName &&
        conditionItem.fieldName.field &&
        conditionItem.fieldName.field.id &&
        (conditionItem.fieldName.field.id === "createdBy" ||
          conditionItem.fieldName.field.id === "updatedBy")
      )
        return conditionItem.value && conditionItem.value.id;

      return (
        conditionItem.value &&
        (conditionItem.value.lookupItemName ||
          conditionItem.value.name ||
          conditionItem.value)
      );
    }
  };
  const conditionsWithAngryBirdList = [
    {
      category: "contacts",
      operand: "AND",
      operator: 1,
      searchKey: "angry_bird",
      value: state.isAngryBird ? "Yes" : "No",
    },
    {
      category: "contacts",
      operand: null,
      operator: 1,
      searchKey: "high_profile_management",
      value: state.isHighProfileMangement ? "Yes" : "No",
    },
  ];

  const returnFinalObject = (isSearch) => {
    const conditions = [];
    let finalObj = null;
    state.conditions.map((element) => {
      conditions.push({
        category:
          element.category !== "activities" ? element.category : "leads",
        categoryType: element.categoryType !== 8 ? element.categoryType : 6,
        formId: element.categoryType,
        searchKey: element.searchKey,
        displayPath: element.displayPath || null,
        specialKey: element.specialKey || null, 
        value: contantValue(element),
        operator: element.operator,
        operand: element.operand,
      });
    });
    if (isSearch) {
      finalObj = {
        report: {
          conditions: [...conditions, ...conditionsWithAngryBirdList],
          fields: state.fields,
          systemReportFilters: {
            fromDate: state.fromDate,
            toDate: state.toDate,
            dateType: selectedOrderBy && selectedOrderBy.filterBy,
          },
        },
      };
      return finalObj;
    }
    const conditionsWithAngryBird = [
      ...state.conditions,
      ...conditionsWithAngryBirdList,
    ];
    finalObj = {
      conditionsJson: state.conditions
        ? JSON.stringify(conditionsWithAngryBird)
        : "",
      fieldsJson: state.fields ? JSON.stringify(state.fields) : "",
      clientSegmentationName: state.clientSegmantationName,
      clientSegmentationFiles: [],
      systemReportFilters: JSON.stringify({
        fromDate: state && state.fromDate,
        toDate: state && state.toDate,
        dateType: selectedOrderBy && selectedOrderBy.filterBy,
      }),
    };
    return finalObj;
  };

  const searchHandler = useCallback(async () => {
    if (!state.fields.length) {
      showError(t(`${translationPath}please_select_columns`));
      return;
    }

    if (schema.error) {
      showError(t("Shared:please-fix-all-errors"));
      return;
    }

    if (orderTypeBy && orderTypeBy.searchKey) {
      const findIndexOfOrderBy = tableColumns.indexOf(
        tableColumns.find(
          (f) => f.searchableKey === (orderTypeBy && orderTypeBy.searchKey)
        )
      );

      if (findIndexOfOrderBy === -1) {
        showError(t(`${translationPath}please_select_columns`));
        return;
      }
    }
    const reportJson = returnFinalObject(true);
    const searchObj = { spName: "GetSegmentationContacts", reportJson };
    // setIsLoading(true);
    // const res = await GetSystemReportsResultClientsSegmentation(filter.pageIndex + 1, filter.pageSize, searchObj);
    // if (!(res && res.status && res.status !== 200)) {
    //   setSearchResponse({ result: res, totalCount: (res && res.length ? res[0].TotalCount : 0) });
    //   showSuccess(t(`${translationPath}search-clients-segmentation-successfully`));
    //   setIsSearchFirst(true);
    //   setIsLoading(false);
    // } else {
    //   setSearchResponse({ result: [], totalCount: 0 });
    //   showError(t(`${translationPath}search-clients-segmentation-failure`));
    // }
    // setIsLoading(false);
  }, [state, tableColumns, sortBy, filter, orderTypeBy, selectedOrderBy]);

  const saveHandler = useCallback(async () => {
    setIsSubmitted(true);
    if (!orderTypeBy || !state.fields.length) {
      showError(t(`${translationPath}please_select_columns`));
      return;
    }
    if (localSchema.error) {
      showError(t("Shared:please-fix-all-errors"));
      return;
    } else if (schema.error) {
      showError(t("Shared:please-fix-all-errors"));
      return;
    }
    if (orderTypeBy && orderTypeBy.searchKey) {
      const findIndexOfOrderBy = tableColumns.indexOf(
        tableColumns.find(
          (f) => f.searchableKey === (orderTypeBy && orderTypeBy.searchKey)
        )
      );

      if (findIndexOfOrderBy === -1) {
        showError(t(`${translationPath}please_select_columns`));
        return;
      }
    }
    // setIsLoading(true);
    // const addCilent = returnFinalObject(false);
    // const res = await CreateClientSigmantationServices(addCilent);
    // if (!(res && res.status && res.status !== 200)) {
    //   showSuccess(t(`${translationPath}add-clients-segmentation-successfully`));
    //   setState({ id: 'clientSegmantationName', value: null });
    //   GlobalHistory.goBack();
    //   setIsOpenSaveDialog(false);
    // } else showError(t(`${translationPath}add-clients-segmentation-failure`));
    // setIsLoading(false);
  }, [state]);

  useEffect(() => {
    const currentState = { ...state };
    setState({ id: "edit", value: currentState });
    setFilter((item) => ({ ...item, pageIndex: 0 }));
  }, [orderTypeBy]);

  useEffect(() => {
    if (isSearchFirst) searchHandler();
  }, [
    filter,
    state.fields,
    state.fromDate,
    state.toDate,
    state.isAngryBird,
    state.isHighProfileMangement,
  ]);

  useEffect(() => {
    // if ()
    setLeadFormData(state);
  }, [state]);

  useEffect(() => {
    const newOperand = state.conditions[0]?.operand;
    if (newOperand) {
      const updatelist = state.conditions.map((condition) => ({
        ...condition,
        operand: newOperand,
      }));
      setState({
        id: "conditions",
        value: updatelist,
      });
    }
  }, [state.conditions[0]?.operand]);

  useEffect(() => {
    if (Opation === "Edit" && data?.DTO?.length > 0) {
      const mappedConditions = data.DTO.map((item) => ({
        category: item.FULLValue?.category || null,
        categoryType: item.FULLValue?.categoryType || null,
        searchKey: item.FULLValue?.searchKey || null,
        value: item.FULLValue?.value || null,
        operator: item.FULLValue?.operator || null,
        operand: item.FULLValue?.operand || "AND",
        fieldName: item.FULLValue?.fieldName || null,
        displayPath: (item && item.displayPath) || null, 
        specialKey: (item && item.specialKey)  || null,
      }));

      setState({
        id: "conditions",
        value: mappedConditions,
      });
    }
  }, [data, Opation]);

  return (
    <div className="ClientsSegmentationView-wrapper view-wrapper">
      <Spinner isActive={isLoading} />
      <div className="w-100 px-2">
        <div className="WorkFlow-Details-wrapper">
          <QeuriesComponents
            state={state}
            LeadFormData={LeadFormData}
            SelectedData={SelectedData}
            schema={schema}
            setState={setState}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            ifHaveAnyErrors={ifHaveAnyErrors}
            setIfHaveAnyErrors={setIfHaveAnyErrors}
            fields={fields}
            setFields={setFields}
            searchHandler={searchHandler}
            saveHandler={saveHandler}
            setIsOpenSaveDialog={setIsOpenSaveDialog}
          />
        </div>
      </div>
    </div>
  );
};
