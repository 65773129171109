import React, {
  useState,
  useCallback,
  useReducer,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase, DialogActions } from '@material-ui/core';
import {
  AddSavedShareUnitsToCriteria,
  CreateSavedShareUnitCriteriaApi,
  ListSavedShareUnitCriteria,
} from '../../../../Services';
import { DialogComponent, Inputs, Spinner } from '../../../../Components';
import { showError, showSuccess } from '../../../../Helper';
import Checkbox from '@material-ui/core/Checkbox';

export const AddToFavouriteShareUnitsDialog = ({
  isOpen,
  closeHandler,
  parentTranslationPath,
  checkedDetailed,
  reest,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [activeItem, setActiveItem] = useState(null);
  const [CreateSavedShareUnitCriteria, setCreateSavedShareUnitCriteria] =
    useReducer(reducer, {
      name: '',
      tags: '',
    });
  const [isLoading, setIsLoading] = useReducer(reducer, {
    sending: false,
    users: false,
    contacts: false,
    Create: false,
  });

  const [data, setData] = useReducer(reducer, {
    ListSavedShareUnitCriteria: [],
    contacts: [],
  });
  const CreateSavedShareUnitCriteriaService = useCallback(
    async (CreateSavedShareUnitCriteria) => {
      setIsLoading({ id: 'sending', value: true });
      const res = await CreateSavedShareUnitCriteriaApi(
        CreateSavedShareUnitCriteria
      );
      if (!(res && res.status && res.status !== 200)) {
        showSuccess(t('Create-successfully'));
        getListSavedShareUnitCriteria();
        setCreateSavedShareUnitCriteria({ id: 'name', value: '' });
        setCreateSavedShareUnitCriteria({ id: 'tags', value: '' });
      } else {
        showError(t('Create-failed'));
      }

      setIsLoading({ id: 'sending', value: false });
    },
    []
  );

  const getListSavedShareUnitCriteria = useCallback(async (searchValue) => {
    setIsLoading({ id: 'users', value: true });
    const res = await ListSavedShareUnitCriteria({
      search: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res) || [];
      if (localValue) {
        setData({
          id: 'ListSavedShareUnitCriteria',
          value: localValue,
        });
      } else {
        setData({
          id: 'ListSavedShareUnitCriteria',
          value: [],
        });
      }

      setIsLoading({ id: 'users', value: false });
    }
  }, []);

  const AddSavedShareUnitsToCriteriaHandler = useCallback(
    async (shareUnitsIds, savedShareUnitCriteriaId) => {
      setIsLoading({ id: 'Create', value: true });
      const body = {
        savedShareUnitCriteriaId: savedShareUnitCriteriaId,
        shareUnitsIds: shareUnitsIds.map((item) => item.shareUnitId),
      };
      const res = await AddSavedShareUnitsToCriteria(body);
      if (!(res && res.status && res.status !== 200)) {
        showSuccess(t('Create-sent-successfully'));
        reest();
        closeHandler();
      } else {
        showError(t('Create-failed'));
      }

      setIsLoading({ id: 'Create', value: false });
    },
    [checkedDetailed, closeHandler, t]
  );

  useEffect(() => {
    getListSavedShareUnitCriteria();
  }, [getListSavedShareUnitCriteria]);

  return (
    <DialogComponent
      titleText='List-of-Favourite'
      saveText='send'
      maxWidth='sm'
      dialogContent={
        <div className='share-template-dialog'>
          <Spinner
            isActive={isLoading.sending}
            isAbsolute
          />
          <div className='d-flex w-100 mr-1-reversed'>
            <Inputs
              idRef='leadIdFilterRef'
              fieldClasses='inputs theme-solid'
              parentTranslationPath={parentTranslationPath}
              value={CreateSavedShareUnitCriteria.name}
              onInputChanged={(event) => {
                const { value } = event.target;
                setCreateSavedShareUnitCriteria({ id: 'name', value });
              }}
              inputPlaceholder={t(`name`)}
            />
            <div className='m-2'></div>
            <Inputs
              idRef='leadIdFilterRef'
              fieldClasses='inputs theme-solid'
              parentTranslationPath={parentTranslationPath}
              value={CreateSavedShareUnitCriteria.tags}
              onInputChanged={(event) => {
                const { value } = event.target;
                setCreateSavedShareUnitCriteria({ id: 'tags', value });
              }}
              inputPlaceholder={t(`tags`)}
            />
            <div className='m-2'>
              <ButtonBase
                className='MuiButtonBase-root btns theme-solid '
                disabled={
                  CreateSavedShareUnitCriteria.tags === '' ||
                  CreateSavedShareUnitCriteria.name === ''
                }
                onClick={() =>
                  CreateSavedShareUnitCriteriaService(
                    CreateSavedShareUnitCriteria
                  )
                }
              >
                {t(`save`)}
                <span className='mdi mdi-content-save-all ml-2'></span>
              </ButtonBase>
            </div>
          </div>
          <div>
            <div className='data-container Favourite'>
              {data &&
                data.ListSavedShareUnitCriteria.map((item) => (
                  <div
                    className={`data-item vara d-inline-flex flex-h-between w-100 ${
                      activeItem === item.savedShareUnitCriteriaId ? 'act' : ''
                    }`}
                    key={item.savedShareUnitCriteriaId}
                  >
                    <span className='heart'>
                      <span className='heart mdi mdi-heart'></span>
                    </span>
                    <div className='w-50'>
                      <strong>{t(`name`)} &nbsp;:&nbsp;</strong>
                      &nbsp;{item?.name || 'N/A'}
                    </div>
                    <div className='w-50'>
                      <strong>{t(`#`)}&nbsp;&nbsp;</strong>
                      &nbsp;{item?.tags || 'N/A'}
                    </div>
                    <div className='w-10 cuostam-check'>
                      <Checkbox
                        className='checkbox-wrapper'
                        checkedIcon={<span className='mdi mdi-check' />}
                        indeterminateIcon={<span className='mdi mdi-minus' />}
                        checked={activeItem === item.savedShareUnitCriteriaId}
                        onClick={(event) => {
                          setActiveItem(item.savedShareUnitCriteriaId);
                        }}
                      />
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <DialogActions>
            <div className='unit-template-action-btns'>
              <ButtonBase
                className='MuiButtonBase-root btns bg-cancel'
                onClick={() => closeHandler()}
              >
                {t(`cancel`)}
              </ButtonBase>
              <ButtonBase
                className='MuiButtonBase-root btns theme-solid'
                disabled={!checkedDetailed || !activeItem}
                onClick={() =>
                  AddSavedShareUnitsToCriteriaHandler(
                    checkedDetailed,
                    activeItem
                  )
                }
              >
                {t(`Add-to-List`)}
                <span className='heart mdi mdi-heart ml-2'></span>
              </ButtonBase>
            </div>
          </DialogActions>
        </div>
      }
      isOpen={isOpen}
      onCloseClicked={() => closeHandler()}
      parentTranslationPath={parentTranslationPath}
    />
  );
};
