export const DisplayCaptionInFormBuilderField = (propertyJson) => {
  const propertyJsonObj = JSON.parse(propertyJson);
  if (
    propertyJsonObj &&
    propertyJsonObj.schema &&
    propertyJsonObj.schema.caption
  )
    return propertyJsonObj.schema.caption;
  else  return false;
};

export const DisplayCaptionInAdvanceSearch = (field) => {
  const caption = field?.data?.caption || field?.label || field?.data?.title.replace("*", "");
  return caption;
};

