import React, { memo, useRef, useState } from "react";
import ChipsViewBox from "./ChipsViewBox";
import { AutocompleteComponent } from "../../../../../Components";
import { Box } from "@material-ui/core";

const MultiSelectField = memo(
  ({
    options = [],
    labelKey,
    idKey,
    selectedItems = [],
    selectHandler,
    maxSelection = 6,
  }) => {

    const selectChangeHandler = (_, newValue) => {
      const isExceededMax = newValue.length > maxSelection;
      if (!isExceededMax) selectHandler(newValue);
    };

    return (
      <Box mt={2}>
        <AutocompleteComponent
          data={options}
          displayLabel={(option) => option[labelKey] || ""}
          onChange={selectChangeHandler}
          selectedValues={selectedItems}
          renderTags={() => null}
          multiple
          withoutSearchButton
        />
        <span className="select_counter">{`${selectedItems.length}/${maxSelection}`}</span>
        <ChipsViewBox
          selectedItems={selectedItems}
          selectHandler={selectHandler}
          labelKey={labelKey}
          idKey={idKey}
        />
      </Box>
    );
  },
  (prevProps, newProps) => {
    return prevProps.selectedItems.length === newProps.selectedItems.length &&
    prevProps.options.length === newProps.options.length;
  }
);

export default MultiSelectField;
