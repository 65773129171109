import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DragDropPicker from "../../Utilities/DragDropPicker/DragDropPicker";
import { getPublicDownloadableLink } from "../../../../../Helper";
import { SwitchComponent } from "../../../../../Components";
import { UnitImagesTypes } from "../../Enums/UnitImagesTypes";

const UnitImagesForm = ({ localFormChangeHandler, localFormSelection }) => {
  const parentTranslationPath = "SmartProposal";
  const translationPath = "UnitImagesForm.";
  const { t } = useTranslation(parentTranslationPath);
  const maxCheckedNo = 4;

  const [state, setState] = useState(null);

  const initializeState = () => {
    if (!state && localFormSelection?.data) setState({ ...localFormSelection });
  }


  const syncUnitImagesData = (selectedImages, imageType) => {

    let imagesData = [];
    
    if(imageType === UnitImagesTypes.Architecture)
      imagesData = [ ...(state?.data?.propertyPublishedImages || [])];
    else if(imageType === UnitImagesTypes.Interior) 
      imagesData = [ ...(state?.data?.unitPublishedImages || [])];

    let imagesDataClone = [...imagesData];

    imagesDataClone.forEach((item, index) => {
      const lastCheckedIndex = imagesData.reduce(
        (lastIndex, currentItem, idx) =>
          currentItem.isChecked ? idx : lastIndex,
        -1
      );
      const targetIndex = lastCheckedIndex + 1;
      const selectedImageIndex = index;

      const isImageSelected =
        selectedImages.findIndex((element) => element.id === item.fileId) !==
        -1;

      if (isImageSelected) {
        const checkedItem = {
          ...item,
          isChecked: true,
        };
        imagesData.splice(selectedImageIndex, 1);
        imagesData.splice(targetIndex, 0, checkedItem);
      }
    });

    return imagesData;
  };



  const unitPublishedImages = useMemo(() => {
    const selectedArchitectureImages = [
      ...(state?.architectureImages || []),
    ];
    const selectedInteriorImages = [
      ...(state?.interiorImages || []),
    ];

    return {
      architectureImages: syncUnitImagesData(selectedArchitectureImages, UnitImagesTypes.Architecture) || [],
      interiorImages: syncUnitImagesData(selectedInteriorImages, UnitImagesTypes.Interior) || [],
    };
  }, [state]);


  const updateStateHandler = (unitImages, key) => {
    
    setState(prevState=> {
      let updatedState = { ...prevState, [key]: unitImages };

      switch (key) {
        case "architectureImages": {
          updatedState.isSelectAllArchitecture = unitImages.length === maxCheckedNo;
          break;
        }
        case "interiorImages": {
          updatedState.isSelectAllInterior = unitImages.length === maxCheckedNo;
          break;
        }
        default:
          break;
      }
    return updatedState;
  });
  }
  
  
  const unitImagesSelectHandler = useCallback(({ result, key }) => {
    
    const pickedImagesIds =
      (result && Array.isArray(result) && result.map((item) => item.fileId)) ||
      [];
    const unitImagesURLs = pickedImagesIds.map((id) =>
      id? getPublicDownloadableLink(id) : null
    );

    const unitImages = pickedImagesIds.map((_, index) => ({
      url: unitImagesURLs[index],
      id: pickedImagesIds[index],
    }));

    updateStateHandler(unitImages, key);

  }, []);

  useEffect(() => {
    initializeState();
  }, [localFormSelection]);

  useEffect(() => {
    if (state) localFormChangeHandler("edit", { ...state });
  }, [state]);

  return (
    <>
      <div className="ProposalForm__field_box">
        <div className="ProposalForm__field_label">
          <p>Architecture images</p>
          <p>{`You can select up to ${maxCheckedNo}`}</p>
        </div>
        <div>
          <SwitchComponent
            idRef="showArchitectureImagesRef"
            wrapperClasses="mb-3"
            themeClass="thick-theme"
            isChecked={state?.showArchitectureImages || false}
            labelValue={`${state?.showArchitectureImages ? "show" : "hide"}`}
            parentTranslationPath={parentTranslationPath}
            translationPath={""}
            onChangeHandler={(_, isChecked) =>
              setState(prevState => ({
                ...(prevState || {}),
                showArchitectureImages: isChecked,
              }))}
          />
          <DragDropPicker
            onChange={(result) =>
              unitImagesSelectHandler({ result, key: "architectureImages" })
            }
            itemsList={unitPublishedImages?.architectureImages}
            maxChecked={maxCheckedNo}
          />
        </div>
      </div>
      {(localFormSelection?.data?.isSingleUnit === true) ? 
      <div className="ProposalForm__field_box">
        <div className="ProposalForm__field_label">
          <p>Interior images</p>
          <p>{`You can select up to ${maxCheckedNo}`}</p>
        </div>
        <div>
        <SwitchComponent
            idRef="showInteriorImagesRef"
            themeClass="thick-theme"
            wrapperClasses="mb-3"
            isChecked={state?.showInteriorImages || false}
            labelValue={`${state?.showInteriorImages ? "show" : "hide"}`}
            parentTranslationPath={parentTranslationPath}
            translationPath={""}
            onChangeHandler={(_, isChecked) =>
              setState(prevState => ({
                ...(prevState || {}),
                showInteriorImages: isChecked,
              }))}
          />
          <DragDropPicker
            onChange={(result) =>
              unitImagesSelectHandler({ result, key: "interiorImages" })
            }
            itemsList={unitPublishedImages?.interiorImages}
            maxChecked={maxCheckedNo}
          />
        </div>
      </div> : null}
    </>
  );
};

export default UnitImagesForm;
