import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    wrapperPropretyDetails: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "24px",
    },
    containerInfoDetails: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    containerDataItem: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "16px",
    },
    divider: {
      marginTop: "20px",
    },
    valueItem: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
      color: theme.palette.text.secondary,
    },
    titleItem: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      color: theme.palette.text.secondary,
    },
    titleItemExpiryDays: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px",
      color: theme.palette.text.error_primary,
    },
    valueItemExpiryDays: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
      color: theme.palette.text.error_primary,
    },ItemTitleHeader :{
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "28px",
      color: theme.palette.text.primary,
    }
  };
});
