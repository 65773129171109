import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProviderWrapper } from './Components';
import { GlobalToasterGenerator, Middleware, SetGlobalRerender } from './Helper';
import { SwitchRoute } from './Components/Route/SwitchRoute';
import { AppRoutes } from './routes/AppRoutes/AppRoute';
import { preventSelectAll } from './Helper/PreventSelectAll.Helper';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux';
import { config } from './config';

const App = () => {
  const [render, setRender] = useState(false);
  preventSelectAll();
  SetGlobalRerender(setRender, render);

  const { loginResponse } = useSelector((state) => state.login);



  if (!config.DisableRightClicking) {
    mixpanel.identify(loginResponse && loginResponse.userId || '')
    mixpanel.people.set({
      '$name': loginResponse && loginResponse.fullName || '',
      '$email': loginResponse && loginResponse.email || '', 'plan':
        'test'
    });

    mixpanel.init('08ebd10c12a26bf0ac8b373abcda23f3', {
      autocapture: {
        pageview: "full-url",
        click: true,
        input: true,
        scroll: true,
        submit: true,
        capture_text_content: false,
      },
    });
  }
  return (
    <Router>
      <ThemeProviderWrapper>
        <GlobalToasterGenerator />
        <Middleware />
        <SwitchRoute routes={AppRoutes} />
      </ThemeProviderWrapper>
    </Router>
  );
};

export default App;
