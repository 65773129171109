import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NumberWithCommas } from "../../../../../../Helper";

export const GaugeChart = ({
  minPrice,
  maxPrice,
  medianPrice,
  psiPrice,
  activeData,
  translationPath,
  marketComparison,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const getNeedleClass = () => {
    if (marketComparison === "Above Market") {
      return "animate-red";
    } else if (marketComparison === "Less than Market") {
      return "animate-green";
    } else if (marketComparison === "Within Market") {
      if (psiPrice > medianPrice) {
        return "animate-red-after";
      } else if (psiPrice < medianPrice) {
        return "animate-green-after";
      } else if (psiPrice === medianPrice) {
        return "animate-center";
      }
    }

    return "animate";
  };

  const [needleClass, setNeedleClass] = useState(getNeedleClass());

  useEffect(() => {
    setNeedleClass(getNeedleClass());
  }, [activeData, psiPrice, medianPrice, marketComparison]);

  function formatNumberWithCommas(number) {
    if (!number) return "";
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div className="unit-mpi-chart">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        width="100%"
        viewBox="0 0 366 184"
        enableBackground="new 0 0 366 184"
        xmlSpace="preserve"
      >
        <path
          fill="#FFFFFE"
          opacity="1.000000"
          stroke="none"
          d=" M280.000000,185.000000   C186.666672,185.000000 93.833359,185.000000 1.000025,185.000000   C1.000017,123.666687 1.000017,62.333370 1.000008,1.000041   C122.999969,1.000028 244.999939,1.000028 366.999939,1.000014   C366.999939,62.333294 366.999939,123.666588 366.999969,184.999939   C338.166656,185.000000 309.333344,185.000000 280.000000,185.000000  M178.971619,93.121445   C181.918488,93.055565 184.865372,92.989677 188.622696,93.167587   C201.776276,94.339005 214.296555,97.693687 226.557571,104.329613   C256.504486,121.909576 271.573364,148.413345 273.198486,182.721985   C303.301666,182.721985 333.011505,182.721985 362.981689,182.721985   C362.476227,151.452209 355.432678,122.161270 340.004181,95.194870   C322.518005,64.632034 298.777100,40.303467 266.533478,23.766796   C263.549408,22.290110 260.617371,20.695045 257.572845,19.355803   C236.710632,10.178787 215.235352,3.572002 191.557068,3.334979   C185.390793,3.338726 179.224518,3.342473 172.235001,3.274395   C170.431732,3.513124 168.635284,3.849005 166.824188,3.975893   C142.672913,5.667965 120.141968,12.776847 98.239304,24.343014   C32.813305,58.869747 1.650341,127.762695 3.576441,182.685944   C33.266548,182.685944 62.960705,182.685944 92.889214,182.685944   C93.402779,169.501328 95.998390,157.086746 101.370155,145.279816   C109.671997,127.032700 123.185905,113.730965 140.850220,103.485031   C152.530319,97.373459 165.085800,94.359848 178.971619,93.121445  z"
        />
        <path
          fill="#FF0301"
          opacity="1.000000"
          stroke="none"
          d=" M267.126862,24.185934   C298.777100,40.303467 322.518005,64.632034 340.004181,95.194870   C355.432678,122.161270 362.476227,151.452209 362.981689,182.721985   C333.011505,182.721985 303.301666,182.721985 273.198486,182.721985   C271.573364,148.413345 256.504486,121.909576 226.240082,103.795822   C227.067856,100.874176 228.140778,98.448395 229.369614,96.104340   C241.940857,72.123932 254.537384,48.156784 267.126862,24.185934  z"
        />
        <path
          fill="#92D052"
          opacity="1.000000"
          stroke="none"
          d=" M140.265106,103.734833   C123.185905,113.730965 109.671997,127.032700 101.370155,145.279816   C95.998390,157.086746 93.402779,169.501328 92.889214,182.685944   C62.960705,182.685944 33.266548,182.685944 3.576441,182.685944   C1.650341,127.762695 32.813305,58.869747 98.646896,24.621517   C102.510544,31.504997 105.969193,38.108627 109.422211,44.715199   C119.704361,64.387787 129.984299,84.061539 140.265106,103.734833  z"
        />
        <path
          fill="#E9897D"
          opacity="1.000000"
          stroke="none"
          d=" M266.830170,23.976364   C254.537384,48.156784 241.940857,72.123932 229.369614,96.104340   C228.140778,98.448395 227.067856,100.874176 225.941910,103.630066   C214.296555,97.693687 201.776276,94.339005 188.231079,92.582611   C189.264740,62.665810 190.690018,33.333977 192.115295,4.002144   C215.235352,3.572002 236.710632,10.178787 257.572845,19.355803   C260.617371,20.695045 263.549408,22.290110 266.830170,23.976364  z"
        />
        <path
          fill="#E98A7E"
          opacity="1.000000"
          stroke="none"
          d=" M140.557678,103.609932   C129.984299,84.061539 119.704361,64.387787 109.422211,44.715199   C105.969193,38.108627 102.510544,31.504997 98.978165,24.562279   C120.141968,12.776847 142.672913,5.667965 166.824188,3.975893   C168.635284,3.849005 170.431732,3.513124 172.693665,3.707375   C173.383728,5.779480 173.742599,7.412025 173.827591,9.058709   C175.270996,37.024876 176.680435,64.992798 178.097366,92.960335   C165.085800,94.359848 152.530319,97.373459 140.557678,103.609932  z"
        />
        <path
          fill="#7232A1"
          opacity="1.000000"
          stroke="none"
          d=" M178.534485,93.040894   C176.680435,64.992798 175.270996,37.024876 173.827591,9.058709   C173.742599,7.412025 173.383728,5.779480 173.105286,3.743288   C179.224518,3.342473 185.390793,3.338726 191.836182,3.668562   C190.690018,33.333977 189.264740,62.665810 187.825836,92.460716   C184.865372,92.989677 181.918488,93.055565 178.534485,93.040894  z"
        />
      </svg>
      <div className="chart-sub ">
        <div className="low-minPrice">
          {formatNumberWithCommas(minPrice) || ""}
        </div>{" "}
        <div className="high-Price">
          {formatNumberWithCommas(maxPrice) || ""}
        </div>
        <div className="low">Low</div> <div className="high">High</div>{" "}
        <div className="AssumedMarketPrice">
          Assumed Market Price{" "}
          <div>{formatNumberWithCommas(medianPrice) || ""}</div>
        </div>
      </div>
      <div className="chart">
        <div key={activeData} className={`needle ${needleClass}`}>
          {" "}
          <div className="chart-psiPrice ">
            <span className="psiPrice">
              {formatNumberWithCommas(psiPrice) || ""}
            </span>
          </div>{" "}
        </div>{" "}
      </div>
    </div>
  );
};

export default GaugeChart;
