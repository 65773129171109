import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { GetParams } from "../../../../../Helper";

const translationPath = "";
export const ImportDetailsHubSpotContacts = ({}) => {
  const { t } = useTranslation("ImportDetailsView");

  const [fileDetails, setFileDetails] = useState({
    listId: GetParams("listId") || 0,
    failedProccess: GetParams("failedProccess") || 0,
    TotalCount: +GetParams("TotalCount") || 0,
    successProccess: GetParams("successProccess") || 0,
    alreadyExists : GetParams("alreadyExists") || 0,
  });

  return (
    <div className="import-details-view">
      <div className="header-section">
        <div className="item-section">
          <div className="box-wrapper">
            <span className="mdi mdi-clock-time-three mdi-36px c-blue-lighter px-2" />
            <div className="d-inline-flex-column px-2">
              <span className="fw-bold mb-2 fz-18px">
                {t(`${translationPath}total-count`)}
                <span className="px-2 c-blue-lighter fw-bold">
                  {" : "}
                  {fileDetails?.TotalCount}
                </span>
              </span>
              <span className="fw-bold c-green mb-2 fz-20px">{`${t(
                `${translationPath}import-status`
              )}`}</span>
              <span className="c-danger fw-bold mb-2 fz-18px">
                {t(`${translationPath}failed-add-to-hubSpot-list`) +
                  " : " +
                  fileDetails?.failedProccess}
              </span>
              <span className="c-blue-lighter fw-bold mb-2 fz-18px">
                {t(`${translationPath}successfully-add-to-hubSpot-list`) +
                  " : " +
                  fileDetails?.successProccess}
              </span>
              <span className="c-primary fw-bold mb-2 fz-18px">
                {t(`${translationPath}already-exists-in-hubSpot-list`) +
                  " : " +
                  fileDetails?.alreadyExists}
              </span>
            </div>
          </div>
        </div>
        <div className="item-section">
          <div className="box-wrapper d-flex-column">
            <span className="mdi mdi-check mdi-24px icon-circle px-2" />
            <div className="d-inline-flex-column px-2">
              <span>{t(`${translationPath}number-of-success`)}</span>
              <span className="c-success d-flex-center fw-bold fz-22px">
                {fileDetails && fileDetails?.successProccess}
              </span>
            </div>
          </div>
        </div>
        <div className="item-section">
          <div className="box-wrapper">
            <span className="mdi mdi-message-alert mdi-flip-h mdi-36px c-danger px-2" />
            <div className="d-inline-flex-column px-2">
              <span>{t(`${translationPath}number-of-failed`)}</span>
              <span className="d-flex-center c-danger fz-22px fw-bold">
                {fileDetails && fileDetails?.failedProccess}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
