import { Fab, Tooltip } from '@material-ui/core';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Inputs } from '../../../../../../../Components';
import { useTitle } from '../../../../../../../Hooks';
import { GetAllFormFieldsByFormId } from '../../../../../../../Services';
import { DisplayCaptionInFormBuilderField } from '../../../../../../../Helper' ; 

export const TemplateTreeFieldsView = ({
  translationPath,
  parentTranslationPath,
  setDragItem,
  setDragItemDragItemValue,
  Data,
}) => {
  const { t } = useTranslation('ReportsBuilderg');
  const [isCollapseData, setisCollapseData] = useState(false);
  const [state, setState] = useState([]);
  const [inshalstate, setinshalstate] = useState([]);
  const [openclose, setOpenClose] = useState([]);
  const LoacalColumusResultTemp = JSON.parse(localStorage.getItem('ColumusResultTemp')) || [];

  const getAllFormFieldsByFormIdAPI = useCallback(async (id, name, tableName, formId) => {
    // setIsLoadingProgress(true);
    // eslint-disable-next-line prefer-const
    let result = await GetAllFormFieldsByFormId(id);

    if (!(result && result.status && result.status !== 200)) {
      setState((items) => {
        items.push({
          id, name, result, tableName
        });
        return [...items];
      });
      setinshalstate((items) => {
        items.push({
          id, name, result, tableName
        });
        return [...items];
      });
    } else
      setState([]);
    // setIsLoadingProgress(false);
  }, []);
  // eslint-disable-next-line consistent-return
  const onClickedArrow = (name) => {
    if (name) {
      const Response = inshalstate.filter((value) => value.name === name);
      const ids = Response.map((o) => o.id);
      const filtered = Response.filter(({ id }, index) => !ids.includes(id, index + 1));
      return filtered;
    }
  };


  const onclick = (item, index) => {
    if (!openclose.includes(index)) {
      setOpenClose((data) => {
        data.push(index);
        return [...data];
      });
    } else {
      setOpenClose((data) => {
        data.splice(data.indexOf(index), 1);
        return [...data];
      });
    }
  };

  const countSheep = (limit) => {
    for (let i = 1; i < limit; i += 1) {
      if (openclose.find((element) => element === i + 1))
        return;
      setOpenClose((data) => {
        data.push(i);
        return [...data];
      });
    }
  };

  const CollapseData = () => {
    setisCollapseData(!isCollapseData);
    if (!isCollapseData)
      countSheep(Data.length + 1);
    else
      setOpenClose([]);
  };

  const searchHandler = (value) => {
    const Array = [];
    // eslint-disable-next-line no-unused-expressions
    state && state.map((item) => {
      Array.push({
        id: item.id,
        name: item.name,
        tableName: item.tableName,
        result: item.result
          .filter((x) => x.uiWidgetType !== "UploadFiles")
          .filter(
            (word) =>
              word.formFieldTitle.toLowerCase().includes(value.toLowerCase()) ||
              (word?.propertyJson &&
                DisplayCaptionInFormBuilderField(word.propertyJson) &&
                DisplayCaptionInFormBuilderField(word.propertyJson)
                  .toLowerCase()
                  .includes(value.toLowerCase()))
          ),
      });
    });
    setinshalstate(Array || []);
    countSheep(Data.length + 1);
  };
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions, react/prop-types, max-len

    Data.map((item) => (getAllFormFieldsByFormIdAPI(item.formId, item.form, item.tableName, item.formId)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Data]);

  const ItemIfExists = (items, tableName, name) => {
    const finalvalue = LoacalColumusResultTemp.find((keyofitem) => keyofitem.searchKey === items.searchableKey && tableName.toLowerCase() === keyofitem.category.toLowerCase());
    return finalvalue;
  };
  // items.searchableKey && keyofitem.categoryType

  return (
    <div className='TreeFieldsView-container templet-w ' onMouseEnter={() => { setDragItem(false); }}>
      <div className='d-flex-column-center mt-2 title-h'>
        <span className='main-title '>
          {' '}
          {t(`${translationPath}Fields`)}
        </span>
      </div>
      <Inputs
        idRef='DatasystemReportFormsRef'
        inputPlaceholder={t(`${translationPath}search-fields`)}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        onKeyUp={(e) => searchHandler(e.target.value)}
        startAdornment={<span className='mdi mdi-magnify mdi-24px c-gray' />}
        endAdornment={(
          <div className='mx-2'>
            {/* {
              ((!isCollapseData) && (

                <span className='mdi mdi-arrow-expand ' onClick={CollapseData} />
              )) ||
              <span className='mdi mdi-arrow-collapse' onClick={CollapseData} />
            } */}

            {
              ((!isCollapseData) && (
                <Tooltip
                  size='small'
                  title={t(`${translationPath}open`)}
                  onClick={CollapseData}
                >
                  <Fab>
                    <span className='mdi mdi-arrow-expand ' />
                  </Fab>
                </Tooltip>

              )) || (
                <Tooltip
                  size='small'
                  title={t(`${translationPath}close`)}
                  onClick={CollapseData}
                >
                  <Fab>
                    <span className='mdi mdi-arrow-collapse' />
                  </Fab>
                </Tooltip>
              )
            }

          </div>
        )}
      />
      <div />
      <div className='d-flex-column TreeFieldsView istemplet'>
        <div className='c-danger'>
          {t(`${translationPath}drag-and-drop`)}
        </div>
        <div>
          <p>
            {' '}
            {t(`${translationPath}Caption-Here`)}
            {' '}
          </p>
          <ul className='tree'>

            {((Data) || []).map((item, index) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li onClick={() => onclick(item.name, index + 1)} index={index + 1}>
                {' '}
                {openclose.find((element) => element === index + 1) ?
                  <span className='mdi mdi-minus-box-outline pointercursor' /> : <span className='mdi mdi-plus-box-outline pointercursor' />}
                <span className='form-name'>
                  {' '}
                  {(item && item.form) || 'N/A'}
                </span>
                {' '}
                <ul
                  className={`item-in ${openclose.find((element) => element === index + 1) ?
                    'is-open' : ''
                    }`}
                >
                  {onClickedArrow(item.form || '').map((itemsss) => (
                    (itemsss && itemsss.result && itemsss.result.length === 0 && (
                      <div className='no-data' disabled>
                        {t(`${translationPath}no-data-found-in`)}
                        {' '}

                        {item.form}
                      </div>
                    )) ||
                    itemsss.result.map((itemData, indexs) => (
                      (
                        (itemData.uiWidgetType !== 'UploadFiles' && (
                          (ItemIfExists(itemData, itemsss.tableName, item.form) && (

                            <li>
                              {' '}

                              <span className='formFieldKey displaydrage'>
                                {' '}
                                {(itemData?.propertyJson && DisplayCaptionInFormBuilderField(itemData.propertyJson)) || (itemData.formFieldTitle && itemData.formFieldTitle.replace('*', '')) || itemData.formFieldName}
                              </span>
                            </li>
                          )) || (
                            <li
                              index={indexs + 1}
                              className='file'
                              draggable
                              onDoubleClick={() => {
                                const ctl = document.getElementById('refs');
                                const startPos = ctl.selectionStart;
                                // onStateChanged(typeInTextarea(oldval, startPos, (` [  ${MergedfieldsNamehHandler(mergedfieldsName)}.${items.formFieldTitle} ]  `)));
                              }}
                              onMouseEnter={() => {
                                const sel = document.getSelection();
                                sel.removeAllRanges();
                              }}
                              onDragStart={(e) => {
                                const captionOrDisplayPath = (itemData.propertyJson && DisplayCaptionInFormBuilderField(itemData.propertyJson)) || itemData.displayPath ; 
                                e.dataTransfer.setData('text', `{${captionOrDisplayPath}}`);
                                setDragItem(true);
                                setDragItemDragItemValue({
                                  // tableName: itemsss.tableName,
                                  searchKey: itemData.searchableKey,
                                  keyName: itemData.displayPath,
                                  FormId: itemData.formsId,
                                  caption:
                                    itemData?.propertyJson &&
                                    DisplayCaptionInFormBuilderField(
                                      itemData.propertyJson
                                    ) || null ,
                                });
                              }}
                              onDragEnd={() => setDragItem(false)}
                            // onDrag={() => onDropHandler(itemData, itemsss.tableName)}
                            >

                              {' '}
                              {(itemData?.propertyJson &&   DisplayCaptionInFormBuilderField(itemData.propertyJson)) || (itemData.formFieldTitle && itemData.formFieldTitle.replace('*', '')) || itemData.formFieldName}
                            </li>
                          )

                        )) || '')
                    ))
                  ))}
                </ul>
                {' '}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

TemplateTreeFieldsView.defaultProps = {
  translationPath: '',
  parentTranslationPath: '',
  setDragItem: () => { },
  setdrageisnide: () => { },
  Data: [],
};
