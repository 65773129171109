import React, { useEffect, useState } from 'react';
import { DialogComponent, Tables } from '../../../../Components';
import DeactiveUserLogHeader from './DeactiveUserHeaderData';
import { GetDeActiveUsersLog } from '../../../../Services';
import { TableActions } from '../../../../Enums';
import { DeActiveUserLogHistoryTable } from './DeActiveUserLogHistoryView/DeActiveUserLogHistoryTable';
import { useTranslation } from 'react-i18next';

export const DeactiveUserLogTableView = ({ parentTranslationPath }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [filter, setFilter] = useState({ pageIndex: 1, pageSize: 25 });

  const [response, setResponse] = useState({
    result: [],
    totalCount: 0,
  });
  const { t } = useTranslation(parentTranslationPath);
  const getDeActiveUsersLog = async () => {
    setIsLoading(true);

    const res = await GetDeActiveUsersLog(filter);

    if (!(res && res.status && res.status !== 200)) {
      setResponse(res);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getDeActiveUsersLog();
  }, [filter]);

  const tableActionClicked = (actionType, row) => {
    setActiveItem(row);
    setOpenDialog(true);
  };

  return (
    <>
      <Tables
        parentTranslationPath={parentTranslationPath}
        data={response?.result || []}
        headerData={DeactiveUserLogHeader || []}
        isLoading={isLoading}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex - 1}
        onPageIndexChanged={(index) =>
          setFilter((filter) => ({ ...filter, pageIndex: index + 1 }))
        }
        onPageSizeChanged={(index) =>
          setFilter((filter) => ({ ...filter, pageSize: index }))
        }
        totalItems={response.totalCount}
        defaultActions={[{ enum: TableActions.viewDetailsApproval.key }]}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
      />
      <DialogComponent
        isOpen={openDialog}
        titleText='Approvals'
        onCloseClicked={() => {
          setOpenDialog(false);
          getDeActiveUsersLog();
        }}
        dialogContent={
          <DeActiveUserLogHistoryTable
            data={activeItem?.history}
            parentTranslationPath={parentTranslationPath}
          />
        }
      />
    </>
  );
};
