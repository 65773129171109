import React, { useCallback, useEffect, useState } from 'react';
import { DialogComponent, Spinner } from '../../../../Components';
import './styles.scss';
import { GetShareUnitDetails } from '../../../../Services';
import { isAdminInquiries } from '../../../../Helper/isAdmin.Helper';
import LocationIcon from '../../DuplicatedUnitOverview/Sections/MiddleSectionDuplicated/Icons/LocationIcon';
import { EmailIcon, PhoneIcon } from '../../../../assets/icons';
import { Button } from '@material-ui/core';
import { ShareTemplateUnitDialog } from '../../ShareUnit/ShareUnitUtitities/ShareTemplateUnitDialog';
import { AddToFavouriteShareUnitsDialog } from '../../ShareUnit/ShareUnitUtitities/AddToFavouriteShareUnitsDialog';
import { CopyToClipboardComponents } from '../../../../ReusableComponents/UtilityComponents';

export default function SharedUnitsDialogV2({
  isOpen,
  activeItem,
  onClose,
  parentTranslationPath,
  t,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [unitDetails, setUnitDetails] = useState({});
  const [isFavouriteDialogOpen, setIsFavouriteDialogOpen] = useState(false);
  const [isProposalDialogOpen, setIsProposalDialogOpen] = useState(false);
  const isAdminInquiriesValue = isAdminInquiries();

  const getShareUnitDetailsData = useCallback(async (activeItem) => {
    setIsLoading(true);
    const res = await GetShareUnitDetails(
      activeItem.shareUnitId,
      activeItem.unitOperationSource,
      isAdminInquiriesValue
    );
    if (!(res && res.status && res.status !== 200)) {
      setIsLoading(false);
      setUnitDetails(res);
    } else {
      setIsLoading(false);
      setUnitDetails({});
    }
  }, []);

  useEffect(() => {
    if (activeItem) getShareUnitDetailsData(activeItem);
  }, [activeItem]);

  const requiredKeys = {
    unit_ref_no: 'unit-ref-no',
    operation_type: 'operation-type',
    unit_type: 'unit-type',
    furnished: 'furnished',
    bedroom: 'bedrooms',
    bathroom: 'bathrooms',
    builtup_area_sqft: 'built-up-area',
    sale_type: 'sale-type',
    selling_price: 'selling-price',
    rentPerYear: 'rent-per-year',
  };

  const filteredKeys = Object.entries(requiredKeys).filter(([key]) => {
    if (activeItem?.operation_type?.toLowerCase() === 'sale')
      return key !== 'rentPerYear';
    if (activeItem?.operation_type?.toLowerCase() === 'rent')
      return key !== 'selling_price' && key !== 'sale_type';
    if (activeItem?.operation_type === 'SaleAndRent') return true;
    return true;
  });
  const renderAgentDetails = () => {
    const isSale = unitDetails?.unitOperationSource === 1;
    const agentEmail = isSale
      ? unitDetails?.listing_agent_email
      : unitDetails?.rent_listing_agent_email;
    const agentPhone = isSale
      ? unitDetails?.listing_agent_phone
      : unitDetails?.rent_listing_agent_phone;
    const agentBranch = isSale
      ? unitDetails?.listing_agent_branch
      : unitDetails?.rent_listing_agent_branch;

    return (
      <>
        {agentEmail && (
          <span className='shared-details-icons'>
            <EmailIcon
              width='16'
              height='16'
              fill='#8E8E93'
            />
            <CopyToClipboardComponents
              data={agentEmail}
              childrenData={agentEmail}
              CustomizationClassName='copy-styled'
            />
          </span>
        )}
        {agentPhone && (
          <span className='shared-details-icons'>
            <PhoneIcon
              width='16'
              height='16'
              fill='#8E8E93'
            />
            <CopyToClipboardComponents
              data={agentPhone}
              childrenData={agentPhone}
              CustomizationClassName='copy-styled'
            />
          </span>
        )}
        {agentBranch && (
          <span className='shared-details-icons'>
            <LocationIcon
              width='16'
              height='16'
              fill='#8E8E93'
            />
            {agentBranch}
          </span>
        )}
      </>
    );
  };

  return (
    <>
      <Spinner isActive={isLoading} />
      <DialogComponent
        titleText={'unit-details'}
        SmothMove
        isOpen={isOpen}
        maxWidth='md'
        dialogContent={
          <div className='shared-details-wrapper'>
            <div className='shared-details-container'>
              <div className='shared-details-title'>{activeItem?.unitName}</div>
              <div className='shared-details-subTitle'>
                {activeItem?.property_name}
              </div>
              <div className='shared-details-image'>
                <img
                  src={activeItem?.imageUrl || unitDetails?.imageUrl}
                  alt='unit-image'
                />
              </div>
              <div className='shared-details-location'>
                <LocationIcon
                  width='24'
                  height='24'
                  fill={'#8E8E93'}
                />
                <span>{`${unitDetails?.city}, ${unitDetails?.district}, ${unitDetails?.community}, ${unitDetails?.subCommunity}`}</span>
              </div>
              <div className='shared-details-box-wrapper'>
                {filteredKeys.map(([key, label]) => {
                  if (!(key in unitDetails)) return null;
                  let value = unitDetails[key] ?? '';
                  if (value == null) value = '';
                  if (key === 'builtup_area_sqft' && value !== '')
                    value = `${value} sqft`;
                  if (
                    ['rentPerYear', 'selling_price'].includes(key) &&
                    value !== ''
                  ) {
                    value = `AED ${value.toLocaleString()}`;
                  }
                  const isFurnished = key === 'furnished';
                  const furnishedColor = isFurnished
                    ? value === 'No'
                      ? '#DB504A'
                      : '#28A745'
                    : 'inherit';

                  return (
                    <div
                      className='shared-details-box'
                      key={key}
                    >
                      <span className='shared-details-box-label'>
                        {t(label)}
                      </span>
                      <span
                        className='shared-details-box-value'
                        style={{ color: furnishedColor }}
                      >
                        {key === 'unit_ref_no' ? (
                          <CopyToClipboardComponents
                            data={value}
                            childrenData={value}
                            CustomizationClassName='copy-styled'
                          />
                        ) : (
                          value
                        )}
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className='shared-details-description'>
                <span className='shared-details-label'>{t('description')}</span>
                <span className='shared-details-value'>
                  {unitDetails?.description}
                </span>
              </div>
              <div className='shared-details-description'>
                <span className='shared-details-label'>{t('amenities')}</span>
                <div className='shared-details-box-wrapper'>
                  {unitDetails?.amenities?.split(',').map((amenity, index) => {
                    const trimmedAmenity = amenity.trim();
                    return (
                      <div
                        className='shared-details-box'
                        key={index}
                      >
                        <span className='shared-details-box-value'>
                          {trimmedAmenity}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className='shared-details-divider'></div>
              <div className='shared-details-footer-wrapper'>
                <div className='shared-details-footer'>
                  <div className='shared-details-listing-agent-wrapper'>
                    <span className='shared-details-agent'>
                      {unitDetails?.unitOperationSource === 1
                        ? unitDetails?.listing_agent
                        : unitDetails?.rent_listing_agent}
                    </span>
                    <span className='shared-details-agent-label'>
                      Listing agent
                    </span>
                  </div>
                  <div className='shared-details-contactInfo-wrapper'>
                    {renderAgentDetails()}
                  </div>
                </div>
              </div>
            </div>
            <div className='shared-details-actions-wrapper'>
              <Button
                className='btns'
                onClick={() => onClose()}
              >
                {t('close')}
              </Button>
              <div className='shared-details-actions'>
                <Button
                  className='btns edit-btn'
                  onClick={() => setIsFavouriteDialogOpen(true)}
                >
                  {t('add-to-favorite')}
                </Button>
                <Button
                  className='btns theme-solid confirm-btn'
                  onClick={() => setIsProposalDialogOpen(true)}
                >
                  {t('send-proposal')}
                </Button>
              </div>
            </div>
            {isProposalDialogOpen && (
              <ShareTemplateUnitDialog
                isOpenShareTemplateUnitDialog={isProposalDialogOpen}
                unitId={activeItem.unitId || null}
                operationType={activeItem?.operation_type}
                closeHandler={() => {
                  setIsProposalDialogOpen(false);
                }}
                parentTranslationPath={parentTranslationPath}
              />
            )}
            {isFavouriteDialogOpen && (
              <AddToFavouriteShareUnitsDialog
                isOpen={isFavouriteDialogOpen}
                checkedDetailed={[activeItem] || []}
                reest={() => {}}
                onSave={() => {}}
                parentTranslationPath={parentTranslationPath}
                closeHandler={() => {
                  setIsFavouriteDialogOpen(false);
                }}
              />
            )}
          </div>
        }
        onCloseClicked={() => onClose()}
        parentTranslationPath={parentTranslationPath}
      />
    </>
  );
}
