import React, { useEffect, useState } from "react";
import { AutocompleteComponent, Inputs, Spinner } from "../../../Components";
import {
  GetLeadPoolConfiguration,
  UpdateLeadPoolConfiguration,
} from "../../../Services";
import { GlobalHistory, showError, showSuccess } from "../../../Helper";
import { useTranslation } from "react-i18next";
import { ApplicationUserSearch } from "../../../Services/userServices";
import { useDebouncedAction } from "../../../Hooks/DebouncedAction";
import SelectedChip from "./SelectedChip";
import { ButtonBase } from "@material-ui/core";
import FlowSwitch from "./FlowSwitch";
import * as Yup from "yup";

import "./styles.scss";
const translationPath = "LeadsPoolConfiguration";

function LeadsPoolConfigurationView() {
  const { t } = useTranslation(translationPath);
  const [isLoading, setIsLoading] = useState(false);
  const [referredByOptions, setReferredByOptions] = useState([]);
  const [values, setValues] = useState({
    isActive: false,
    activityTimeFrame: null,
    activeLeadDuration: null,
    leadPoolReferrals: [],
  });

  const unitValidationSchema = Yup.object().shape({
    activityTimeFrame: Yup.number(t("activity-time-frame-must-be-a-number"))
      .positive(t("activity-time-frame-cant-be-less-than-zero"))
      .integer(t("activity-time-frame-invalid-number"))
      .required(t("activity-time-frame-cant-be-empty")),

    activeLeadDuration: Yup.number(t("active-lead-duration-must-be-a-number"))
      .positive(t("active-lead-duration-cant-be-less-than-zero"))
      .integer(t("active-lead-duration-invalid-number"))
      .required(t("active-lead-duration-cant-be-empty")),

    leadPoolReferrals: Yup.array().min(
      1,
      t("referrals-must-have-at-least-one-item")
    ),
  });

  async function GetLeadPoolConfigurations() {
    setIsLoading(false);
    const result = await GetLeadPoolConfiguration(1);

    if (!(result && result.status && result.status !== 200)) {
      setValues(result || {});
    } else {
      setValues({});
    }
    setIsLoading(false);
  }

  async function getAllReferredBy(searchValue) {
    const result = await ApplicationUserSearch({
      pageIndex: 1,
      pageSize: 25,
      name: searchValue || "",
    });

    if (!(result && result.status && result.status !== 200)) {
      setReferredByOptions(result.result || []);
    } else {
      setReferredByOptions([]);
    }
  }

  async function onSaveClicked() {
    setIsLoading(true);

    const body = {
      isActive: values.isActive,
      activityTimeFrame: +values.activityTimeFrame,
      activeLeadDuration: +values.activeLeadDuration,
      leadPoolReferrals: values.leadPoolReferrals,
    };
    try {
      await unitValidationSchema.validate(values, { abortEarly: false });
      const result = await UpdateLeadPoolConfiguration(body);
      if (!(result && result.status && result.status !== 200)) {
        showSuccess(t("new-configurations-saved-successfully"));
      }
    } catch (error) {
      const errorMessages = error.inner.map((err) => err.message);
      showError(errorMessages.join(", "));
    }

    setIsLoading(false);
  }

  async function onCanceledClicked() {
    GlobalHistory.goBack();
  }

  const debouncedGetAllReferredBy = useDebouncedAction(getAllReferredBy, 700);

  useEffect(() => {
    GetLeadPoolConfigurations();
    getAllReferredBy();
  }, []);

  return (
    <>
      <Spinner isAbsolute isActive={isLoading} />
      <div className="leadsPoolConfigurationView-title">
        <span>{t("leads-pool-configuration")}</span>
      </div>

      <div className="leads-pool-configuraion-wrapper">
        <div className="leadsPoolConfigurationView-item-wrapper">
          <span className="leadsPoolConfigurationView-text-styles">
            {t("flow-status")}
          </span>

          <FlowSwitch
            idRef="isActiveRef"
            checked={values.isActive}
            onChange={(e) => {
              e.persist();
              setValues({ ...values, isActive: !values?.isActive });
            }}
          />
        </div>
        <div className="leadsPoolConfigurationView-item-wrapper">
          <span className="leadsPoolConfigurationView-text-styles">
            {t("last-activity-timeframe")}
          </span>
          <Inputs
            idRef="activityTimeFrameRef"
            wrapperClasses={"w-50"}
            value={values.activityTimeFrame || null}
            onInputChanged={(e) => {
              const value = e?.target?.value || null;
              setValues({ ...values, activityTimeFrame: value });
            }}
            min={0}
            type="number"
            translationPath={translationPath}
            parentTranslationPath={translationPath}
          />
        </div>
        <div className="leadsPoolConfigurationView-item-wrapper">
          <span className="leadsPoolConfigurationView-text-styles">
            {t("active-lead-duration")}
          </span>
          <Inputs
            idRef="activeLeadDurationRef"
            wrapperClasses={"w-50"}
            value={values.activeLeadDuration || null}
            onInputChanged={(e) => {
              const value = e?.target?.value || null;
              setValues({ ...values, activeLeadDuration: value });
            }}
            min={0}
            type="number"
            translationPath={translationPath}
            parentTranslationPath={translationPath}
          />
        </div>
        <div className="leadsPoolConfigurationView-item-wrapper">
          <span className="leadsPoolConfigurationView-text-styles">
            {t("referred-by")}
          </span>
          <AutocompleteComponent
            idRef="leadPoolReferralsRef"
            wrapperClasses={"w-50"}
            data={referredByOptions || []}
            selectedValues={values?.leadPoolReferrals || []}
            chipsLabel={(option) => option?.referredByName || ""}
            displayLabel={(option) => option?.fullName || ""}
            renderTags={(e, getTagProps) => (
              <div className="flex chips-container p-1">
                {e.map((option, index) => (
                  <SelectedChip {...getTagProps({ index })}>
                    {option.referredByName || ""}
                  </SelectedChip>
                ))}
              </div>
            )}
            onInputKeyUp={(e) => {
              const value = e?.target?.value || null;
              debouncedGetAllReferredBy(value);
            }}
            getOptionSelected={(option) =>
              values?.leadPoolReferrals?.findIndex(
                (item) =>
                  item?.referredByUserId === option?.usersId ||
                  item?.referredByUserId === option?.referredByUserId
              ) !== -1 || ""
            }
            onChange={(e, newValue) => {
              const newReferredBy = newValue.map((item) => ({
                referredByName: item?.fullName || item?.referredByName || "",
                referredByUserId: item?.usersId || item?.referredByUserId || "",
              }));

              setValues({
                ...values,
                leadPoolReferrals: newReferredBy ?? [],
              });
            }}
            multiple={true}
            isWithError
            withoutSearchButton
            isLoading={referredByOptions.length < 1}
            translationPath={translationPath}
            parentTranslationPath={translationPath}
          />
        </div>
      </div>
      <div className="leadsPoolConfigurationView-buttons-wrappper">
        <ButtonBase
          onClick={() => {
            onCanceledClicked();
          }}
          className="leadsPoolConfigurationView-cancel-button"
        >
          <span>{t("cancel")}</span>
        </ButtonBase>
        <ButtonBase
          onClick={() => {
            onSaveClicked();
          }}
          className="leadsPoolConfigurationView-save-button"
        >
          <span>{t("save-configuration")}</span>
        </ButtonBase>
      </div>
    </>
  );
}

export default LeadsPoolConfigurationView;
