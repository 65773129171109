import React from "react";
import { useTranslation } from "react-i18next";

export const AddAlreadyExisting = ({
  parentTranslationPath,
  translationPath,
  selected,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <>
      <div className="w-100 d-flex-column already-existing mb-2 mt-2">
        <div className="d-flex-column">
          <span className="mt-1 mb-1">
            {" "}
            {t(`${translationPath}new-developer`)}
          </span>
          <span className="mt-1 mb-1 fw-bold">
            {(selected &&
              selected.developerName &&
              selected.developerName.name) ||
              "N/A"}
          </span>
        </div>
        <div className="d-flex-column">
          <span className="mt-1 mb-1">
            {t(`${translationPath}landLine-number`)}
          </span>
          <span className="mt-1 mb-1 fw-bold">
            {(selected && selected.landLineNumber) || "N/A"}
          </span>
        </div>

        <div className="d-flex-column">
          <span className="mt-1 mb-1"> {t(`${translationPath}language`)} </span>

          <span className="mt-1 mb-1 fw-bold">
            {(selected &&
              selected.language &&
              selected.language.lookupItemName) ||
              "N/A"}
          </span>
        </div>

        <div className="d-flex-column">
          <span className="mt-1 mb-1">{t(`${translationPath}country`)}</span>
          <span className="mt-1 mb-1 fw-bold">
            {(selected &&
              selected.country &&
              selected.country.lookupItemName) ||
              "N/A"}
          </span>
        </div>

        <div className="d-flex-column">
          <span className="mt-1 mb-1"> {t(`${translationPath}city`)} </span>
          <span className="mt-1 mb-1 fw-bold">
            {(selected && selected.city && selected.city.lookupItemName) ||
              "N/A"}
          </span>
        </div>
      </div>
    </>
  );
};
