export const MultiUnitsCardsStyle = `
/*CSS GENERETED BY ANIMA PPLUGIN */

:root {
--bg-page-light : linear-gradient(180deg, #F4F4F4 0%, #EDEDED 100%);
--bg-page-dark : #272963;
--bg-card-light : #ffffff;
--bg-card-dark : #161B26;
--border-clr-light : #EAECF0;
--border-clr-dark : #1F242F;
--border-clr-btn-light : #272963;
--border-clr-btn-dark : #8E8E93;
--text-secondery-light : #2D3E50;
--text-secondery-dark : #F0F0F0;
--icon-filter-light : unset;
--icon-filter-dark : brightness(0) invert(1);
}


[data-mode="light"] {
  --bg-page: var(--bg-page-light);
  --bg-card: var(--bg-card-light);
  --border-clr: var(--border-clr-light);
  --border-clr-btn: var(--border-clr-btn-light);
  --text-secondery: var(--text-secondery-light);
  --icon-filter: var(--icon-filter-light);
}

[data-mode="dark"] {
  --bg-page: var(--bg-page-dark);
  --bg-card: var(--bg-card-dark);
  --border-clr: var(--border-clr-dark);
  --border-clr-btn: var(--border-clr-btn-dark);
  --text-secondery: var(--text-secondery-dark);
  --icon-filter: var(--icon-filter-dark);
}


.MultiUnitsCards .cover-x {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background: var(--bg-page);
  min-height: 100vh;
}

.MultiUnitsCards .cover-x .content-wrapper {
  width: 2480px;
  height: 1754px;
}

.MultiUnitsCards .cover-x .content {
  display: flex;
  flex-direction: column;
  width: 2240px;
  height: 1514px;
  align-items: flex-start;
  gap: 120px;
  position: relative;
  top: 120px;
  left: 120px;
  border-radius: 50px;
}

.MultiUnitsCards .cover-x .frame {
  display: flex;
  flex-direction: column;
  height: 1514px;
  align-items: flex-start;
  gap: 120px;
  position: relative;
  align-self: stretch;
  width: 100%;
}

.MultiUnitsCards .cover-x .div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
}

.MultiUnitsCards .cover-x .div-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.MultiUnitsCards .cover-x .text-wrapper {
  position: relative;
  flex: 1;
  margin-top: -1.00px;
  font-family: "Inter", serif;
  font-weight: 700;
  color: var(--text-secondery);
  font-size: 72px;
  letter-spacing: 0;
  line-height: normal;
}

.MultiUnitsCards .cover-x .frame-2 {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: var(--3-spacing-spacing-6xl);
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.MultiUnitsCards .cover-x .blog-post-card {
  display: flex;
  width: 1095px;
  height: 660px;
  align-items: flex-start;
  position: relative;
  border-radius: 47.1px;
  overflow: hidden;
  border: 4px solid var(--border-clr);
  background-color: var(--bg-card);
}

.MultiUnitsCards .cover-x .image {
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
  background-size: cover;
  background-position: 50% 50%;
}

.MultiUnitsCards .cover-x .content-2 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
}

.MultiUnitsCards .cover-x .heading-and-text {
  flex-direction: column;
  gap: 5.89px;
  display: flex;
  align-items: flex-start;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  z-index: 1;
}

.MultiUnitsCards .cover-x .text-and-supporting {
  display: inline-flex;
  align-items: center;
  gap: 12.77px;
  position: relative;
  flex: 0 0 auto;
  z-index: 2;
}

.MultiUnitsCards .cover-x .text {
  position: relative;
  width: fit-content;
  margin-top: -3.19px;
  font-family: "Inter", serif;
  font-weight: 600;
  color: var(--text-secondery);
  font-size: 52px;
  letter-spacing: 0;
  line-height: 89.4px;
  white-space: nowrap;
}

.MultiUnitsCards .cover-x .supporting-text {
  position: relative;
  width: fit-content;
  font-family: "Inter", serif;
  font-weight: 400;
  color: #94969C;
  font-size: 44.694px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}

.MultiUnitsCards .cover-x .heading-and-icon {
  gap: 47.1px;
  display: flex;
  align-items: flex-start;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  z-index: 1;
}

.MultiUnitsCards .cover-x .heading {
  position: relative;
  flex: 1;
  margin-top: -2.94px;
  font-family: "Inter", serif;
  font-weight: 600;
  color: var(--text-secondery);
  font-size: 48px;
  letter-spacing: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.MultiUnitsCards .cover-x .frame-3 {
  display: inline-flex;
  align-items: center;
  gap: 31.92px;
  position: relative;
  flex: 0 0 auto;
  z-index: 0;
}

.MultiUnitsCards .cover-x .frame-4 {
  display: inline-flex;
  align-items: center;
  gap: 15.96px;
  position: relative;
  font-weight: 500;
  flex: 0 0 auto;
}

.MultiUnitsCards .cover-x .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -3.19px;
  font-family: "Inter", serif;
  font-weight: 500;
  color: #3b72d9;
  font-size: 40px;
  letter-spacing: 0;
  line-height: normal;
}

.MultiUnitsCards .cover-x .text-2 {
  position: relative;
  width: fit-content;
  margin-top: -3.19px;
  font-family: "Inter", serif;
  font-weight: 500;
  color: var(--text-secondery);
  font-size: 40px;
  letter-spacing: 0;
  line-height: 63.8px;
  white-space: nowrap;
}

.MultiUnitsCards .cover-x .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
    position: relative;
    align-self: stretch;
    width: 100%;
    flex: 0 0 auto;
    z-index: 0;
}

.MultiUnitsCards .cover-x .frame-5 {
    display: inline-flex;
    align-items: flex-end;
    gap: 12px;
}

.MultiUnitsCards .cover-x .img {
    position: relative;
    width: 50px;
    height: 50px;
}

.MultiUnitsCards .cover-x .list-item {
    color: #0D101B;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
}

.MultiUnitsCards .cover-x .text-wrapper-3 {
  position: relative;
  width: fit-content;
  margin-top: -3.19px;
  font-family: "Inter", serif;
  font-weight: 600;
  color: var(--text-secondery);
  font-size: 42px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.extra-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.extra-info p {
  display: flex;
  gap: 32px;
  color: #2D3E50;
  font-size: 40px;
  font-weight: 500;
  line-height: normal;
}

.extra-info .extra-info-title {
  color: #8E8E93;
}

.extra-info .area-unit {
  color: #0D101B;
  font-size: 32px;
  font-weight: 500;
  line-height: normal;
}

.download-btn{
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    border: 3px solid var(--border-clr-btn);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    width: fit-content;
    position: absolute;
    top: 32px;
    right: 32px;
    cursor: pointer;
}

.download-btn img{
    width: 40px;
    height: 40px;
    filter: var(--icon-filter);
}

`;
