import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import Joi from "joi";
import { useSelectedTheme, useTranslate } from "../../../../../../Hooks";
import { CustomDialog } from "../../../../CustomDialog";
import { Box, Typography, Divider, Table } from "@material-ui/core";
import { CustomAutocomplete } from "../../../../CustomAutocomplete";
import {
  LeadsStatusEnum,
  ReminderTypesEnum,
  UnitsOperationTypeEnum,
} from "../../../../../../Enums";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { UnitMapper } from "../../../../../../Views/Home/UnitsLeaseView/UnitLeaseMapper";
import {
  contactsDetailsGet,
  CreateActivity,
  EditActivity,
  GetAdvanceSearchContacts,
  GetAllLeads,
  GetAllLeadsByContactId,
  GetAllMaintenanceContract,
  GetAllPortfolio,
  GetAllUnits,
  GetAllWorkOrders,
  leadDetailsGet,
  MyLeadUpdateLead,
  unitDetailsGet,
} from "../../../../../../Services";
import {
  getDownloadableLink,
  getErrorByName,
  GetParams,
} from "../../../../../../Helper";
import { ContactsMapper } from "../../../../../../Views/Home";
import { DataFileAutocompleteComponent } from "../../../../DataFileAutocompleteComponent";
import {
  Calendar,
  ClockIcon,
  DeleteIcon,
  DropdownIcon,
  EditIcon,
  PlusIcon,
} from "../../../../../../assets/icons";
import { CustomSwitch } from "../../../../CustomSwitch";
import { DropzoneComponent } from "../../../../DropzoneComponent";
import { CustomTextArea } from "../../../../CustomTextArea";
import { ReminderTypeOptionsComponent } from "../../ReminderTypeOptionsComponent";
import { CustomSelect } from "../../../../CustomSelect";
import { CustomButton } from "../../../../CustomButton";
import { CustomDatePickerComponent } from "../../../../CustomDatePickerComponent";
import { CustomBadge } from "../../../../CustomBadge";
import clsx from "clsx";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";

//styles
import useStyles from "./styles";

const AddNewActivityFormDialog = ({
  onOpen,
  onClose,
  selected,
  saveDto,
  data,
  setData,
  setSelected,
  setSaveDto,
  isReplyDialog,
  isRelatedToNotSelected,
  setIsRelatedToNotSelected,
  activityRelatedToList,
  actionItemId,
  isGeneralDialog,
  activeItem,
  classifications,
  setLeadId,
  withDuration,
  defaultReminders,
  isDateTimeCustom,
  idForTeamLeads,
  withDateTime,
  isWithReminder,
  slaAppliedFor,
  setSlaAppliedFor,
  categoryName,
  filter,
  userInfo,
  isEdit,
  creatorInfo,
  setRemindersWithoutEmptyItems,
  remindersWithoutEmptyItems,
}) => {
  const { translate } = useTranslate("HeaderView");
  const {
    theme: { palette },
  } = useSelectedTheme();
  const styles = useStyles();
  const searchTimer = useRef(null);
  const { pathname } = useLocation();
  const { setAlertBoxContent } = useVerticalNav();
  const [isExpanded, setIsExpanded] = useState(false);
  const [activityAssignToOptions, setActivityAssignToOptions] = useState([]);
  const [qualificationData, setQualificationData] = useState(null);
  const [isOpenQualifyLeadDialog, setIsOpenQualifyLeadDialog] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [uploadedFileId, setUploadedFileId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedReminderForWhom, setSelectedReminderForWhom] = useState("");
  const isUnitActivitiesView = pathname.includes("unit");
  const isLeadActivitiesView =
    pathname.includes("lead-profile-edit") ||
    pathname.includes("lead-sales") ||
    pathname.includes("lead-lease") ||
    pathname.includes("leads") ||
    pathname.includes("mortgage-leads") ||
    pathname.includes("lead-owner-lead");
  const isContactActivitiesView =
    pathname.includes("contact-profile-edit") ||
    pathname.includes("Contacts-CRM/view");
  const isLeaseView = pathname.includes("lease");
  const isSaleView = pathname.includes("sale");
  const reminderAmountOfTimeTypes = [
    {
      key: 1,
      value: "minutes",
      momentKey: "minutes",
    },
    {
      key: 2,
      value: "hours",
      momentKey: "hours",
    },
    {
      key: 3,
      value: "days",
      momentKey: "days",
    },
  ];
  const durationTypes = [
    {
      key: 1,
      value: "minutes",
      momentKey: "minutes",
    },
    {
      key: 2,
      value: "hours",
      momentKey: "hours",
    },
    {
      key: 3,
      value: "days",
      momentKey: "days",
    },
  ];

  const defaultActivityReminderItem = useRef({
    reminderType: null,
    templateId: null,
    leadId: null,
    usersId: null,
    reminderTime: saveDto.activityDate,
    reminderAmountOfTimeType: 1,
  });
  const validateEmptyArray = (array) => {
    if (array?.length === 0 || !Array.isArray(array)) return null;
    else return array;
  };
  const handleOperationTypeValue = (array) => {
    if (isSaleView) {
      return UnitsOperationTypeEnum.sale.key;
    }
    if (isLeaseView) {
      return UnitsOperationTypeEnum.rent.key;
    } else if (array.length === 1) {
      return array[0];
    }
    return null;
  };

  const handleReminderForLeadOptions = (newValue) => {
    let itemToPush = {};
    const localReminderForOptions = [...selected.reminderForOptions];
    const leadReminderIndex = localReminderForOptions.findIndex(
      (item) => item.type === "lead"
    );
    if (newValue) {
      itemToPush = {
        id: newValue.leadId,
        value: newValue?.lead?.contact_name?.name,
        type: "lead",
      };
    }

    if (!newValue && leadReminderIndex !== -1) {
      localReminderForOptions.splice(leadReminderIndex, 1);
    }

    if (newValue && leadReminderIndex !== -1) {
      localReminderForOptions[leadReminderIndex] = itemToPush;
    }

    if (newValue && leadReminderIndex == -1) {
      localReminderForOptions.push(itemToPush);
    }

    setSelected({ id: "reminderForOptions", value: localReminderForOptions });
  };

  const getRelatedToData = (relatedToItem, searchValue) => {
    if (isGeneralDialog) {
      if (relatedToItem.relatedToName === "Unit")
        getAllRelatedUnits(searchValue);
      if (relatedToItem.relatedToName === "Lead")
        getAllRelatedLeads(searchValue);
      if (relatedToItem.relatedToName === "Contact" && !activeItem)
        getAllRelatedContacts(searchValue);
      if (relatedToItem.relatedToName === "Contact" && activeItem)
        getContactDetails(activeItem.relatedContactNumberId);
      if (relatedToItem.relatedToName === "Portfolio")
        getAllRelatedPortfolios(searchValue);
      if (relatedToItem.relatedToName === "WorkOrder")
        getAllRelatedWorkOrders(searchValue);
      if (relatedToItem.relatedToName === "MaintenanceContract")
        getAllRelatedMaintenanceContracts(searchValue);
      return;
    }

    if (relatedToItem.relatedToName === "Unit" && isUnitActivitiesView)
      getUnitDetails();
    if (relatedToItem.relatedToName === "Unit" && !isUnitActivitiesView)
      getAllRelatedUnits(searchValue);

    if (relatedToItem.relatedToName === "Lead" && isLeadActivitiesView)
      getLeadDetails();
    if (relatedToItem.relatedToName === "Lead" && isContactActivitiesView)
      getAllLeadsByContactId();
    if (
      relatedToItem.relatedToName === "Lead" &&
      !isLeadActivitiesView &&
      !isContactActivitiesView
    )
      getAllRelatedLeads(searchValue);

    if (relatedToItem.relatedToName === "Contact" && isContactActivitiesView)
      getContactDetails();
    if (
      relatedToItem.relatedToName === "Contact" &&
      !isContactActivitiesView &&
      activeItem &&
      activeItem.relatedContactNumberId
    )
      getContactDetails(activeItem.relatedContactNumberId);
    if (
      relatedToItem.relatedToName === "Contact" &&
      !isContactActivitiesView &&
      !activeItem
    )
      getAllRelatedContacts(searchValue);

    if (relatedToItem.relatedToName === "Portfolio")
      getAllRelatedPortfolios(searchValue);
    if (relatedToItem.relatedToName === "WorkOrder")
      getAllRelatedWorkOrders(searchValue);
    if (relatedToItem.relatedToName === "MaintenanceContract")
      getAllRelatedMaintenanceContracts(searchValue);
  };
  const handleInitialAutoFill = (newValue) => {
    if (isUnitActivitiesView)
      handleRelatedToChange({ relatedToType: "Unit", newValue });
    if (isLeadActivitiesView)
      handleRelatedToChange({ relatedToType: "Lead", newValue });
    if (isContactActivitiesView)
      handleRelatedToChange({ relatedToType: "Contact", newValue });
  };
  const handleRelatedToChange = ({ item, newValue, relatedToType }) => {
    setSaveDto({
      id:
        ((item?.relatedToName === "Unit" || relatedToType === "Unit") &&
          "relatedUnitNumberId") ||
        ((item?.relatedToName === "Lead" || relatedToType === "Lead") &&
          "relatedLeadNumberId") ||
        ((item?.relatedToName === "Contact" || relatedToType === "Contact") &&
          "relatedContactNumberId") ||
        ((item?.relatedToName === "Portfolio" ||
          relatedToType === "Portfolio") &&
          "relatedPortfolioId") ||
        ((item?.relatedToName === "WorkOrder" ||
          relatedToType === "WorkOrder") &&
          "relatedWorkOrder") ||
        ((item?.relatedToName === "MaintenanceContract" ||
          relatedToType === "MaintenanceContract") &&
          "relatedMaintenanceContractId") ||
        "",
      value:
        (newValue &&
          (((item?.relatedToName === "Unit" || relatedToType === "Unit") &&
            newValue.id) ||
            ((item?.relatedToName === "Lead" || relatedToType === "Lead") &&
              newValue.leadId) ||
            ((item?.relatedToName === "Contact" ||
              relatedToType === "Contact") &&
              newValue.contactsId) ||
            newValue.id ||
            ((item?.relatedToName === "Portfolio" ||
              relatedToType === "Portfolio") &&
              newValue.portfolioId) ||
            ((item?.relatedToName === "WorkOrder" ||
              relatedToType === "WorkOrder") &&
              newValue.id) ||
            ((item?.relatedToName === "MaintenanceContract" ||
              relatedToType === "MaintenanceContract") &&
              newValue.maintenanceContractId))) ||
        null,
    });

    setSelected({
      id:
        ((item?.relatedToName === "Unit" || relatedToType === "Unit") &&
          "relatedUnit") ||
        ((item?.relatedToName === "Lead" || relatedToType === "Lead") &&
          "relatedLead") ||
        ((item?.relatedToName === "Contact" || relatedToType === "Contact") &&
          "relatedContact") ||
        ((item?.relatedToName === "Portfolio" ||
          relatedToType === "Portfolio") &&
          "relatedPortfolio") ||
        ((item?.relatedToName === "WorkOrder" ||
          relatedToType === "WorkOrder") &&
          "relatedWorkOrder") ||
        "",
      value: newValue,
    });

    if (item?.relatedToName === "Lead" || relatedToType === "Lead")
      handleReminderForLeadOptions(newValue);
    if (item?.relatedToName === "Lead" || relatedToType === "Lead") {
      setLeadId(newValue.leadId);
    }
  };

  const getAllRelatedUnits = useCallback(
    async (value) => {
      const response = await GetAllUnits({
        ...filter,
        search: value,
        operationType: handleOperationTypeValue(classifications.operationTypes),
        saleTypes: validateEmptyArray(classifications.saleTypes),
        unitTypes: validateEmptyArray(classifications.unitTypes),
        excludeLeadOwnerInUnits: isContactActivitiesView ? false : true,
      });
      if (!(response && response.status && response.status !== 200)) {
        const unitMapped =
          (response && response.result.map((item) => UnitMapper(item))) || [];
        const unitFilterd = unitMapped.filter((item) => item);
        setData({
          id: "relatedUnit",
          value: unitFilterd || [],
        });
      } else setData({ id: "relatedUnit", value: [] });
    },
    [filter]
  );
  const getAllRelatedLeads = useCallback(
    async (value) => {
      const response = await GetAllLeads({
        ...filter,
        search: value,
        leadStatus: LeadsStatusEnum.Open.status,
        leadTypes: validateEmptyArray(classifications.leadCategories),
        activityTypeId: saveDto.activityTypeId,
      });
      if (!(response && response.status && response.status !== 200))
        setData({
          id: "relatedLead",
          value: (response && response.result) || [],
        });
      else setData({ id: "relatedLead", value: [] });
    },
    [filter]
  );

  const getAllRelatedContacts = useCallback(async (searchValue) => {
    var emailValidRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    let body = {
      criteria: {},
      filterBy: "createdOn",
      contactClasses:
        validateEmptyArray(classifications.contactClassNames) ?? undefined,
    };

    if (searchValue && searchValue.match(emailValidRegex)) {
      body = {
        ...body,
        criteria: {
          Email: [{ searchType: 2, value: searchValue }],
        },
        filterBy: "createdOn",
      };
    } else if (searchValue && !isNaN(searchValue)) {
      body = {
        ...body,
        criteria: {
          Ids: [{ searchType: 2, value: searchValue }],
        },
        filterBy: "createdOn",
      };
    } else if (searchValue) {
      body = {
        ...body,
        criteria: {
          FullName: [
            {
              searchType: 2,
              value: searchValue,
            },
          ],
        },
      };
    }
    const res = await GetAdvanceSearchContacts(filter, body);
    if (!(res && res.status && res.status !== 200)) {
      setData({
        id: "relatedContact",
        value: ((res && res.result) || []).map(
          (item) =>
            item.contactJson &&
            ContactsMapper(item, JSON.parse(item.contactJson).contact)
        ),
      });
    } else {
      setData({
        id: "relatedContact",
        value: [],
      });
    }
  }, []);

  const getContactDetails = useCallback(
    async (contactId) => {
      const id = actionItemId || contactId || +GetParams("id");
      const res = await contactsDetailsGet({ id });
      if (!(res && res.status && res.status !== 200)) {
        if (!activeItem) handleInitialAutoFill(res);
        setData({ id: "relatedContact", value: (res && [res]) || [] });
      } else setData({ id: "relatedContact", value: [] });
    },
    [actionItemId]
  );

  const getAllRelatedPortfolios = useCallback(
    async (value) => {
      const response = await GetAllPortfolio({ ...filter, search: value });

      if (!(response && response.status && response.status !== 200))
        setData({
          id: "relatedPortfolio",
          value: (response && response.result) || [],
        });
      else setData({ id: "relatedPortfolio", value: [] });
    },
    [filter]
  );

  const getAllRelatedWorkOrders = useCallback(
    async (value) => {
      const response = await GetAllWorkOrders({ ...filter, search: value });

      if (!(response && response.status && response.status !== 200))
        setData({
          id: "relatedWorkOrder",
          value: (response && response.result) || [],
        });
      else setData({ id: "relatedWorkOrder", value: [] });
    },
    [filter]
  );

  const getAllRelatedMaintenanceContracts = useCallback(
    async (value) => {
      const response = await GetAllMaintenanceContract({
        ...filter,
        search: value,
      });

      if (!(response && response.status && response.status !== 200))
        setData({
          id: "relatedMaintenanceContract",
          value: (response && response.result) || [],
        });
      else setData({ id: "relatedMaintenanceContract", value: [] });
    },
    [filter]
  );

  const getUnitDetails = useCallback(async () => {
    const unitId = actionItemId || +GetParams("id");
    const res = await unitDetailsGet({ id: unitId });
    if (!(res && res.status && res.status !== 200)) {
      const unitMapped = UnitMapper(res);
      setData({
        id: "relatedUnit",
        value: [unitMapped] || [],
      });
      if (!activeItem) handleInitialAutoFill(unitMapped);
    } else setData({ id: "relatedUnit", value: [] });
  }, [filter, pathname, actionItemId]);

  const getLeadDetails = useCallback(async () => {
    const leadId = actionItemId || +GetParams("id");
    const res = await leadDetailsGet({ id: leadId });

    if (!(res && res.status && res.status !== 200)) {
      if (res?.lead?.status?.lookupItemName === LeadsStatusEnum.Open.key) {
        if (!activeItem) handleInitialAutoFill(res);
        setData({ id: "relatedLead", value: [res] });
      } else setData({ id: "relatedLead", value: [] });
    } else setData({ id: "relatedLead", value: [] });
  }, [actionItemId]);

  const getAllLeadsByContactId = useCallback(async () => {
    const contactId = actionItemId || +GetParams("id");

    const response = await GetAllLeadsByContactId(
      { ...filter, leadStatus: LeadsStatusEnum.Open.status },
      contactId
    );
    if (!(response && response.status && response.status !== 200))
      setData({
        id: "relatedLead",
        value: (response && response.result) || [],
      });
    else setData({ id: "relatedLead", value: [] });
  }, [actionItemId]);

  const checkIsRelatedLeadClosed = () => {
    const relatedLeadStatus =
      selected?.relatedLead?.lead?.status?.lookupItemName;
    return activeItem?.relatedLeadNumberId && relatedLeadStatus === "Closed";
  };

  const getContactDisplayLabel = (option) => {
    let displayLabel = "";

    if (option?.contact?.company_name) {
      displayLabel = option.contact.company_name || "";
      return displayLabel;
    }

    if (option?.contact?.first_name) {
      displayLabel = `${option.contact.first_name || ""} ${
        option.contact.last_name || ""
      }`;
      return displayLabel;
    }

    if (option?.company_name) {
      displayLabel = option.company_name || "";
      return displayLabel;
    }

    if (option?.first_name) {
      displayLabel = `${option.first_name || ""} ${option.last_name || ""}`;
      return displayLabel;
    }

    return displayLabel;
  };
  const getReminderTypeOptions = () => {
    let reminderTypeOptions = {
      agent: Object.values(ReminderTypesEnum),
      lead: [],
    };

    if (selected.relatedLead) {
      const leadOptions = [];
      const leadHasEmailAddress =
        selected.relatedLead.lead?.contact_name?.email_address;
      const leadHasPhoneNumber =
        selected.relatedLead.lead?.contact_name?.mobile ||
        selected?.relatedLead.lead?.contact_name?.phone;

      if (leadHasEmailAddress) leadOptions.push(ReminderTypesEnum.Email);
      if (leadHasPhoneNumber) leadOptions.push(ReminderTypesEnum.Sms);

      reminderTypeOptions.lead = leadOptions;
    }
    setSelected({ id: "reminderTypeOptions", value: reminderTypeOptions });
  };

  const qualifyRelatedLead = useCallback(async () => {
    const body = {
      ...(qualificationData || {}),
    };
    const leadId = saveDto.relatedLeadNumberId;
    const res = await MyLeadUpdateLead({ id: leadId, body });

    if (!(res && res.status && res.status !== 200)) {
      const success = true;
      return success;
    } else
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "lead-unqualified",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
  }, [qualificationData, saveDto.relatedLeadNumberId]);

  const formattedTimeFun = (dateString) => {
    const formattedTime = new Intl.DateTimeFormat("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }).format(dateString);
    return formattedTime;
  };
  const handleQualifyLeadDialog = () => {
    const isQualifiedLeadRequired =
      selected?.activityType?.leadQualificationName === "Qualified lead";
    const leadClass = selected?.relatedLead?.lead?.leadClass;
    const isSeekerLead = leadClass === "Buyer" || leadClass === "Tenant";

    if (
      !activeItem &&
      isQualifiedLeadRequired &&
      isSeekerLead &&
      selected.relatedLead
    ) {
      setIsOpenQualifyLeadDialog(true);
    } else if (
      !activeItem &&
      isQualifiedLeadRequired &&
      !selected.relatedLead
    ) {
      setQualificationData(null);
    }
  };
  const getTimeValue = (item) => {
    if (!saveDto.activityDate) return 0;
    const currentTimeAmountType = durationTypes.find(
      (items) => items.key === (item.reminderAmountOfTimeType || 1)
    );

    return moment(saveDto.activityDate).diff(
      item.reminderTime,
      currentTimeAmountType.momentKey
    );
  };

  const activityTimeChangeHandler = (activityDate) => {
    if (saveDto.activityReminders) {
      const mappedActiveItemReminders = saveDto.activityReminders.map(
        (item) => {
          return {
            ...item,
            reminderTime: reminderTimeCalculateHandler(
              item,
              getTimeValue(item) || 0,
              item.reminderAmountOfTimeType,
              activityDate
            ),
          };
        }
      );
      setSaveDto({
        id: "activityReminders",
        value: mappedActiveItemReminders,
      });
    }
  };

  const reminderTimeCalculateHandler = useCallback(
    (item, value, type) => {
      const currentTimeAmountType = reminderAmountOfTimeTypes.find(
        (items) => items.key === (type || item.reminderAmountOfTimeType || 1)
      );
      let newReminderTime = moment(saveDto.activityDate)
        .add(-value, currentTimeAmountType.momentKey)
        .format("YYYY-MM-DDTHH:mm:ss");

      if (
        moment(newReminderTime).isBefore(moment().format("YYYY-MM-DDTHH:mm:ss"))
      ) {
        newReminderTime = null;
      }

      if (saveDto.activityReminders) {
        const remindersBeforeActivityDate = saveDto.activityReminders.filter(
          (item) => newReminderTime
        );

        if (remindersBeforeActivityDate.length == 0) {
          setSaveDto({
            id: "activityReminders",
            value: [{ ...defaultActivityReminderItem.current }],
          });
        }

        if (remindersBeforeActivityDate.length > 0) {
          setSaveDto({
            id: "activityReminders",
            value: remindersBeforeActivityDate,
          });
        }
      }
      return newReminderTime;
    },
    [reminderAmountOfTimeTypes, saveDto.activityDate, saveDto.activityReminders]
  );

  const createOrUpdateActivity = async () => {
    setIsSubmitted(true);
    try {
      const saveState = { ...saveDto };
      const filteredReminders = saveState?.activityReminders.filter(
        (item) =>
          (item.usersId || item.leadId) &&
          item.reminderType &&
          item.templateId &&
          item.reminderTime
      );

      const mappedReminders = filteredReminders?.map((item) => {
        const contactId = item.leadId
          ? selected.relatedLead?.lead?.contact_name?.id
          : null;
        return {
          activityReminderId: item.activityReminderId,
          reminderType: item.reminderType,
          reminderTime: item.reminderTime,
          templateId: item.templateId,
          usersId: item.usersId,
          contactId,
        };
      });

      saveState.activityReminders = mappedReminders;

      const res = activeItem?.activityId
        ? await EditActivity(activeItem.activityId, saveState)
        : await CreateActivity(saveState);
      if (!(res && res.status && res.status !== 200)) {
        if (activeItem && activeItem.activityId) {
          //  updated sucsess
          // setAlertBoxContent({
          //   display: true,
          //   variant: "success",
          //   title: translate("activity-updated-successfully"),
          //   onClose: () => {
          //     setAlertBoxContent(null);
          //   },
          // });
        } else {
          // created sucsess
          setAlertBoxContent({
            display: true,
            variant: "success",
            title: translate("activity-Created-successfully"),
            onClose: () => {
              setAlertBoxContent(null);
            },
          });
          onClose();
        }
      }
    } catch (error) {
      if (activeItem && activeItem.activityId) {
        // faild updated
        // setAlertBoxContent({
        //   display: true,
        //   variant: "success",
        //   title: translate("activity-update-failed"),
        //   onClose: () => {
        //     setAlertBoxContent(null);
        //   },
        // });
      } else {
        // faild Created
        setAlertBoxContent({
          display: true,
          variant: "success",
          title: translate("activity-create-failed"),
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      }
    } finally {
      setIsSubmitted(false);
    }
  };

  const saveHandler = async () => {
    try {
      if (withDateTime === true && !activeItem && !saveDto.activityDate) {
        setAlertBoxContent({
          display: true,
          variant: "error",
          title: translate("choose-time-after-now"),
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
        return;
      }
      const isLeadQualifyRequired =
        selected?.activityType?.leadQualificationName === "Qualified lead";
      if (isLeadQualifyRequired && qualificationData && !activeItem) {
        const leadQualifyRes = await qualifyRelatedLead();
        if (leadQualifyRes) {
          createOrUpdateActivity();
        } else return;
      } else createOrUpdateActivity();
    } catch (error) {
    } finally {
    }
  };

  const handleAddRemenders = () => {
    setSaveDto({
      id: "activityReminders",
      value: [
        {
          reminderType: null,
          templateId: null,
          leadId: null,
          usersId: null,
          reminderTime: "",
          reminderAmountOfTimeType: 1,
        },
        ...saveDto.activityReminders,
      ],
    });

    setSelected({
      id: "activityReminders",
      value: [
        {
          templete: [],
          ReminderforWhom: [],
          reminderType: null,
        },
        ...selected.activityReminders,
      ],
    });
    setSelectedReminderForWhom(1);
  };

  const handleDeleteLink = (ItemToDelete) => {
    const updatedReminders = saveDto.activityReminders.filter(
      (_, i) => i !== ItemToDelete
    );
    setSaveDto({
      id: "activityReminders",
      value: updatedReminders,
    });
  };
  const handleEditLink = (ItemToEdit) => {};

  const handleExpandMoreOptions = () => {
    setIsExpanded((prev) => !prev);
  };

  const handleFilesAccepted = (files) => {
    setUploadedFiles(files);
  };

  const formatUploadedDocumentToMapp = () => {
    if (uploadedFileId) {
      setSaveDto({
        id: "files",
        value: [
          {
            uuid: uploadedFileId,
            fileName: uploadedFiles[0].name,
            url: null,
            isUploaded: true,
            fileId: uploadedFileId,
          },
        ],
      });
    } else {
      return;
    }
  };
  const schema = Joi.object({
    activityDate:
      (!activeItem &&
        ((withDateTime &&
          Joi.date()
            .required()
            .greater(Date.now())
            .messages({
              "date.base": translate(`activity-date-is-required`),
              "date.greater": translate(`choose-time-after-now`),
            })) ||
          Joi.any())) ||
      Joi.any(),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(saveDto);

  useEffect(() => {
    formatUploadedDocumentToMapp();
  }, [uploadedFileId]);
  useEffect(() => {
    setIsRelatedToNotSelected(
      !(
        saveDto.relatedLeadNumberId ||
        saveDto.relatedContactNumberId ||
        saveDto.relatedUnitNumberId ||
        saveDto.relatedPortfolioId ||
        saveDto.relatedWorkOrderId ||
        saveDto.relatedMaintenanceContractId
      )
    );
  }, [saveDto]);

  useEffect(() => {
    if (
      activityRelatedToList &&
      activityRelatedToList.length &&
      !isReplyDialog
    ) {
      activityRelatedToList.forEach((item) => {
        getRelatedToData(item);
      });
    }
  }, [filter, activityRelatedToList, actionItemId]);

  useEffect(() => {
    var newActivityReminderFor = selected.reminderForOptions;

    const hasLead = newActivityReminderFor.some(
      (item) => item?.type === "lead"
    );

    if (!hasLead && selected.selected) {
      const relatedLead = {
        id: selected?.relatedLead?.leadId,
        value: selected?.relatedLead?.lead?.contact_name?.name,
        type: "lead",
      };

      newActivityReminderFor.push(relatedLead);
    }

    const hasCreator = newActivityReminderFor.some(
      (item) => item?.type === "creator"
    );

    if (!hasCreator) {
      newActivityReminderFor.push(creatorInfo);
    }

    newActivityReminderFor = newActivityReminderFor?.filter(
      (value, index, self) => index === self.findIndex((t) => t.id === value.id)
    );
    setActivityAssignToOptions(newActivityReminderFor || []);
  }, [selected.reminderForOptions]);

  useEffect(() => {
    getReminderTypeOptions();
  }, [selected.relatedLead]);

  useEffect(() => {
    if (!saveDto.activityReminders || saveDto.activityReminders.length === 0) {
      setSaveDto({
        id: "activityReminders",
        value: [
          {
            reminderType: null,
            templateId: null,
            leadId: null,
            usersId: null,
            reminderTime: "",
            reminderAmountOfTimeType: 1,
          },
        ],
      });
      setSelected({
        id: "activityReminders",
        value: [
          {
            templete: [],
            ReminderforWhom: [],
            reminderType: null,
          },
        ],
      });
    }
  }, [saveDto, selected]);

  return (
    <CustomDialog
      open={onOpen}
      onClose={() => {
        onClose();
      }}
      title={translate("Add-new-activity")}
      subtitle={translate(
        "Create-and-schedule-new-activity-to-enhance-engagement-with-your-contacts-and-leads"
      )}
      cancelText={translate("Close")}
      confirmText={translate("Add-new-Activity")}
      onConfirm={() => {
        saveHandler();
      }}
      contentDialogClass={styles.containerContentDialog}
      width={"1024px"}
      content={
        <Box className={styles.wrapperAddNewActivityForm}>
          <Box className={styles.containerInfoActivitey}>
            {categoryName && (
              <Box className={styles.containerInfoItem}>
                <Typography className={styles.containerInfoTitle}>
                  {translate("Category")}
                </Typography>
                <Typography className={styles.containerInfoValue}>
                  {categoryName}
                </Typography>
              </Box>
            )}
            <Box className={styles.containerInfoItem}>
              <Typography className={styles.containerInfoTitle}>
                {translate("activity-rating")}
              </Typography>
              <Typography className={styles.containerInfoValue}>
                <CustomBadge
                  className={clsx(styles.positive_negative_Status, {
                    [styles.Natural]:
                      selected?.activityType?.activityRateName === "Neutral",
                    [styles.positiveSuperPositve]:
                      selected?.activityType?.activityRateName === "Positive" ||
                      selected?.activityType?.activityRateName ===
                        "SuperPositive",
                    [styles.negativeSuperNegative]:
                      selected?.activityType?.activityRateName === "Negative" ||
                      selected?.activityType?.activityRateName ===
                        "SuperNegative",
                  })}
                  label={selected?.activityType?.activityRateName}
                  SizeVariant={"medium"}
                  BackgroundColor={
                    selected?.activityType?.activityRateName === "Neutral"
                      ? palette.utility.warning_50
                      : selected?.activityType?.activityRateName ===
                          "Positive" ||
                        selected?.activityType?.activityRateName ===
                          "SuperPositive"
                      ? palette.utility.success_50
                      : selected?.activityType?.activityRateName ===
                          "SuperNegative" ||
                        selected?.activityType?.activityRateName === "Negative"
                      ? palette.utility.error_50
                      : palette.utility.success_50
                  }
                  BorderColor={
                    selected?.activityType?.activityRateName === "Neutral"
                      ? palette.utility.warning_200
                      : selected?.activityType?.activityRateName ===
                          "SuperPositive" ||
                        selected?.activityType?.activityRateName === "Positive"
                      ? palette.utility.success_200
                      : selected?.activityType?.activityRateName ===
                          "Negative" ||
                        selected?.activityType?.activityRateName ===
                          "SuperNegative"
                      ? palette.utility.error_200
                      : palette.utility.success_200
                  }
                  Color={
                    selected?.activityType?.activityRateName === "Neutral"
                      ? palette.utility.warning_700
                      : selected?.activityType?.activityRateName ===
                          "SuperPositive" ||
                        selected?.activityType?.activityRateName === "Positive"
                      ? palette.utility.success_700
                      : selected?.activityType?.activityRateName ===
                          "Negative" ||
                        selected?.activityType?.activityRateName ===
                          "SuperNegative"
                      ? palette.utility.error_700
                      : palette.utility.success_700
                  }
                ></CustomBadge>
              </Typography>
            </Box>
            {selected?.activityType?.leadScore !== null && (
              <Box className={styles.containerInfoItem}>
                <Typography className={styles.containerInfoTitle}>
                  {translate("Lead-score")}
                </Typography>
                <Typography className={styles.containerInfoValue}>
                  <CustomBadge
                    className={clsx(styles.positive_negative_Status, {
                      [styles.positive]: selected?.activityType?.leadScore >= 0,
                      [styles.negative]: selected?.activityType?.leadScore < 0,
                    })}
                    label={
                      selected?.activityType?.leadScore > 0 ? (
                        <Box
                          className={styles.plsColor}
                        >{`+${selected?.activityType?.leadScore}`}</Box>
                      ) : (
                        <Box
                          className={styles.muinsColr}
                        >{`${selected?.activityType?.leadScore}`}</Box>
                      )
                    }
                    SizeVariant={"medium"}
                    BackgroundColor={
                      selected?.activityType?.leadScore >= 0
                        ? palette.utility.success_50
                        : palette.utility.error_50
                    }
                    BorderColor={
                      selected?.activityType?.leadScore >= 0
                        ? palette.utility.success_200
                        : palette.utility.error_200
                    }
                    Color={
                      selected?.activityType?.leadScore >= 0
                        ? palette.utility.success_700
                        : palette.utility.error_700
                    }
                  ></CustomBadge>
                </Typography>
              </Box>
            )}

            <Box className={styles.containerInfoItem}>
              <Typography className={styles.containerInfoTitle}>
                {translate("Created-by")}
              </Typography>
              <Box className={styles.containerCreatedBy}>
                <Box className={styles.containerImage}>
                  <img
                    className={styles.ImageWrapper}
                    alt="HeaderPSITemplet"
                    src={
                      getDownloadableLink(selected.ImageCreatedByName) || null
                    }
                  />
                </Box>
                <Typography className={styles.containerInfoValue}>
                  {saveDto.createdByName || ""}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={styles.containerfiledActivity}>
            <Box className={styles.containerInputFiled}>
              <Typography className={styles.titleInputFiled}>
                {translate("Activity-name")}*
              </Typography>
              <CustomAutocomplete
                isDisabled
                placeholder={translate("Select")}
                options={data?.activityTypes || []}
                onChange={(e, newValue) => {
                  setSaveDto({
                    id: "edit",
                    value: {
                      ...saveDto,
                      activityTypeId: newValue?.activityTypeId || null,
                      subject: newValue?.activityTypeName || null,
                      assignAgentId: null,
                    },
                  });

                  setSelected({
                    id: "edit",
                    value: {
                      ...selected,
                      reminderForOptions: [],
                      activityAssign: null,
                      activityType: newValue,
                    },
                  });
                  // resetRelatedTo();
                  const assignedToActivityTypeRoles =
                    newValue?.assignedToActivityTypeRoles || [];

                  const rolesIds = assignedToActivityTypeRoles.map(
                    (item) => item.rolesId || []
                  );

                  // setRoles(rolesIds);
                }}
                value={selected?.activityType || null}
                getOptionLabel={(option) => option.activityTypeName || ""}
                getOptionValue={(option) => option.id === saveDto.assignAgentId}
                renderOption={(option) => option.activityTypeName || ""}
              />
            </Box>
            <Divider />
            <Box className={styles.containerInputFiled}>
              <Typography className={styles.titleInputFiled}>
                {translate("Activity-assign-to")}*
              </Typography>
              <CustomAutocomplete
                variant={"avatar"}
                avatarIdProp={selected.ImageCreatedByName || null}
                isDisabled
                options={data?.activityAssignments || []}
                value={selected?.activityAssign || null}
                getOptionLabel={(option) => option.fullName || ""}
                getOptionValue={(option) => option.id === saveDto.assignAgentId}
                renderOption={(option) =>
                  ((option.userName || option.fullName) &&
                    `${option.fullName} (${option.userName})`) ||
                  ""
                }
                onInputKeyUp={(e) => {
                  const onSearch = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    // getAllActivityAssignments(onSearch);
                  }, 700);
                }}
                onChange={(e, newValue) => {
                  setSelected({
                    id: "activityAssign",
                    value: newValue || null,
                  });
                  // setIdForTeamLeads(newValue?.id || null);
                  setSaveDto({
                    id: "assignAgentId",
                    value: (newValue && newValue?.id) || null,
                  });
                  // handleReminderForAgentOptions(newValue);
                }}
              />
            </Box>
            <Divider />
            <Box className={styles.containerActivityRelatedToList}>
              {activityRelatedToList?.length > 0 &&
                activityRelatedToList.map((item, index) => (
                  <>
                    <Box className={styles.containerInputFiled}>
                      <Typography
                        key={index}
                        className={styles.titleInputFiled}
                      >
                        {translate(`${item.relatedToName}`)}
                      </Typography>
                      <DataFileAutocompleteComponent
                        idRef={`relatedToRef-${index}`}
                        isDisabled={
                          isReplyDialog ||
                          (item.relatedToName === "Lead" && activeItem) ||
                          (item.relatedToName === "Lead" &&
                            checkIsRelatedLeadClosed &&
                            checkIsRelatedLeadClosed())
                        }
                        selectedValues={
                          (item.relatedToName === "Unit" &&
                            selected.relatedUnit) ||
                          (item.relatedToName === "Lead" &&
                            selected.relatedLead) ||
                          (item.relatedToName === "Contact" &&
                            selected.relatedContact) ||
                          (item.relatedToName === "Portfolio" &&
                            selected.relatedPortfolio) ||
                          (item.relatedToName === "WorkOrder" &&
                            selected.relatedWorkOrder) ||
                          (item.relatedToName === "MaintenanceContract" &&
                            selected.relatedMaintenanceContract)
                        }
                        multiple={false}
                        data={
                          (item.relatedToName === "Unit" && data.relatedUnit) ||
                          (item.relatedToName === "Lead" && data.relatedLead) ||
                          (item.relatedToName === "Contact" &&
                            data.relatedContact) ||
                          (item.relatedToName === "Portfolio" &&
                            data.relatedPortfolio) ||
                          (item.relatedToName === "WorkOrder" &&
                            data.relatedWorkOrder) ||
                          (item.relatedToName === "MaintenanceContract" &&
                            data.relatedMaintenanceContract) ||
                          []
                        }
                        filterOptions={(options) => options}
                        displayLabel={(option) => {
                          switch (item.relatedToName) {
                            case "Unit":
                              return option?.unitRefNo || "";
                            case "Lead":
                              return (
                                (option.lead &&
                                  option.lead.contact_name &&
                                  option.lead.contact_name.name) ||
                                ""
                              );
                            case "Contact":
                              return getContactDisplayLabel(option);
                            case "Portfolio":
                              return option?.portfolioName || "";
                            case "MaintenanceContract":
                              return (
                                option?.maintenanceContractId || ""
                              ).toString();
                          }
                        }}
                        renderFor={
                          item.relatedToName && item.relatedToName.toLowerCase()
                        }
                        withoutSearchButton
                        onInputKeyUp={(e) => {
                          const { value } = e.target;
                          if (searchTimer.current)
                            clearTimeout(searchTimer.current);
                          searchTimer.current = setTimeout(() => {
                            getRelatedToData(item, value);
                          }, 1200);
                        }}
                        onKeyDown={() => {
                          const newValue = null;
                          handleRelatedToChange({ item, newValue });
                        }}
                        isWithError
                        onChange={async (_, newValue) =>
                          handleRelatedToChange({ item, newValue })
                        }
                      />
                    </Box>
                    <Divider />
                  </>
                ))}
            </Box>
            {(withDateTime === true || isDateTimeCustom) &&
              saveDto.activityTypeId &&
              ((
                <Box className={styles.containerInputFiled}>
                  <Typography className={styles.titleInputFiled}>
                    {translate("Activity-date-time")}
                  </Typography>
                  <Box className={styles.containerActivityDateTime}>
                    {/* date */}

                    <Box className={styles.containerDateActivity}>
                      <CustomDatePickerComponent
                        idRef="activityDateRef"
                        translate={translate}
                        value={
                          saveDto.activityDate
                            ? saveDto.activityDate
                            : moment().format("YYYY-MM-DDTHH:mm:ss")
                        }
                        placeholder={"dd/mm/yyyy"}
                        withIcon
                        helperText={
                          getErrorByName(schema, "activityDate").message
                        }
                        isSubmitted={isSubmitted}
                        error={getErrorByName(schema, "activityDate").error}
                        onDateChanged={(newValue) => {
                          const activityDateValue =
                            (newValue &&
                              moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) ||
                            moment()
                              .add(2, "minutes")
                              .format("YYYY-MM-DDTHH:mm:ss");

                          setSaveDto({
                            id: "activityDate",
                            value: activityDateValue,
                          });

                          if (activeItem) {
                            activityTimeChangeHandler(activityDateValue);
                          } else if (!activeItem) {
                            setSaveDto({ id: "activityReminders", value: [] });
                          }
                        }}
                        startAdornment={
                          <Calendar
                            width={20}
                            height={20}
                            fill={palette.foreground.quarterary}
                          />
                        }
                        minDate={
                          !activeItem
                            ? moment().format("YYYY-MM-DDTHH:mm:ss")
                            : (activeItem &&
                                activeItem.createdOn &&
                                moment(activeItem.createdOn).format(
                                  "YYYY-MM-DDTHH:mm:ss"
                                )) ||
                              ""
                        }
                        autoOk={translate("Confirm")}
                      />
                    </Box>

                    {/* time */}

                    <Box className={styles.containerTimeActivity}>
                      <CustomDatePickerComponent
                        idRef="activityTimeRef"
                        translate={translate}
                        value={
                          saveDto.activityDate
                            ? saveDto.activityDate
                            : moment()
                                .add(2, "minutes")
                                .format("YYYY-MM-DDTHH:mm:ss")
                        }
                        isTimePicker
                        placeholder={"00:00"}
                        withIcon
                        helperText={
                          getErrorByName(schema, "activityDate").message
                        }
                        error={getErrorByName(schema, "activityDate").error}
                        onDateChanged={(newValue) => {
                          setSaveDto({
                            id: "activityDate",
                            value:
                              (newValue &&
                                moment(newValue).format(
                                  "YYYY-MM-DDTHH:mm:ss"
                                )) ||
                              null,
                          });

                          if (activeItem)
                            activityTimeChangeHandler(
                              (newValue &&
                                moment(newValue).format(
                                  "YYYY-MM-DDTHH:mm:ss"
                                )) ||
                                null
                            );

                          if (!activeItem)
                            setSaveDto({ id: "activityReminders", value: [] });
                        }}
                        minDate={moment().format("YYYY-MM-DDTHH:mm:ss")}
                        startAdornment={
                          <ClockIcon
                            width="20"
                            height="20"
                            fill={palette.foreground.quarterary}
                          />
                        }
                        autoOk={translate("Confirm")}
                      />
                    </Box>
                  </Box>
                </Box>
              ) ||
                "")}

            {/* duration */}
            {withDateTime && withDuration && saveDto.activityTypeId && (
              <Box className={styles.containerDuration}>
                <Box className={styles.containerInputFiled}>
                  <Typography className={styles.titleInputFiled}>
                    {translate("Duration")}
                  </Typography>
                  <Box className={styles.containerDuratinActivity}>
                    <CustomDatePickerComponent
                      value={selected.durationType}
                      isTimePicker
                      placeholder={"00:00"}
                      onDateChanged={(newValue) => {
                        setSelected({
                          id: "durationType",
                          value:
                            (newValue &&
                              moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) ||
                            null,
                        });
                      }}
                      autoOk={translate("Confirm")}
                      isDisabled={!saveDto.activityDate}
                    />
                  </Box>
                </Box>
              </Box>
            )}

            {(withDateTime || isDateTimeCustom) &&
              saveDto.activityTypeId &&
              withDateTime &&
              withDuration &&
              saveDto.activityTypeId && <Divider />}

            {(isWithReminder || isDateTimeCustom) && (
              <>
                <Box className={styles.containerInputFiled}>
                  <>
                    <Box className={styles.containerTitiles}>
                      <Typography className={styles.titleInputFiled}>
                        {translate("Set-reminder")}
                      </Typography>
                      <Typography className={styles.subTitleInputFiled}>
                        {translate("Send-reminder-before")}
                      </Typography>
                    </Box>
                    <Box className={styles.containerSetReminderInputFiled}>
                      <Box>
                        <ReminderTypeOptionsComponent
                          data={
                            selected?.reminderTypeOptions?.agent ||
                            selected?.reminderTypeOptions?.lead ||
                            []
                          }
                          value={
                            selected.activityReminders[0]?.reminderType || null
                          }
                          valueInput="key"
                          onSelectChanged={(value) => {
                            setSaveDto({
                              id: "activityReminders",
                              value: saveDto.activityReminders.map(
                                (reminder, index) =>
                                  index === 0
                                    ? { ...reminder, reminderType: +value }
                                    : reminder
                              ),
                            });
                            setSelected({
                              id: "activityReminders",
                              value: selected.activityReminders.map(
                                (reminder, index) =>
                                  index === 0
                                    ? { ...reminder, reminderType: value }
                                    : reminder
                              ),
                            });
                          }}
                        />
                      </Box>
                      <CustomDatePickerComponent
                        value={
                          saveDto.activityReminders[0]?.reminderTime
                            ? String(saveDto.activityReminders[0]?.reminderTime)
                            : null
                        }
                        isTimePicker
                        placeholder={"00:00"}
                        withIcon
                        onDateChanged={(newValue) => {
                          const timeSelected =
                            (newValue &&
                              moment(newValue).format("YYYY-MM-DDTHH:mm:ss")) ||
                            null;
                          setSaveDto({
                            id: "activityReminders",
                            value: saveDto.activityReminders.map(
                              (reminder, index) =>
                                index === 0
                                  ? {
                                      ...reminder,
                                      reminderTime: timeSelected,
                                    }
                                  : reminder
                            ),
                          });
                        }}
                        startAdornment={
                          <ClockIcon
                            width="20"
                            height="20"
                            fill={palette.button.secondary_fg}
                          />
                        }
                        autoOk={translate("Confirm")}
                      />
                      <CustomAutocomplete
                        placeholder={translate("Select-reminder-title")}
                        options={data.reminderTemplates || []}
                        value={
                          selected?.activityReminders[0]?.templete[0] || ""
                        }
                        onChange={(e, value) => {
                          setSelected({
                            id: "templete",
                            value: [value],
                          });
                          setSaveDto({
                            id: "activityReminders",
                            value: saveDto.activityReminders.map(
                              (reminder, index) =>
                                index === 0
                                  ? {
                                      ...reminder,
                                      templateId: value.templateId,
                                    }
                                  : reminder
                            ),
                          });
                          setSelected({
                            id: "activityReminders",
                            value: selected.activityReminders.map(
                              (reminder, index) =>
                                index === 0
                                  ? {
                                      ...reminder,
                                      templete: [value],
                                    }
                                  : reminder
                            ),
                          });
                        }}
                        getOptionLabel={(option) =>
                          (option && option.templateName) || ""
                        }
                        getOptionValue={(option) =>
                          (option && option.templateId) || ""
                        }
                        renderOption={(option) => option?.templateName || ""}
                      />
                      <CustomSelect
                        placeholder={translate("Reminder-for-whom")}
                        style={{ width: "100%" }}
                        options={
                          activityAssignToOptions?.filter(
                            (option) => option?.id && option?.value
                          ) || []
                        }
                        defaultValue={selectedReminderForWhom}
                        getOptionLabel={(option) => option.value}
                        getOptionValue={(option) => option.id}
                        // emptyItem={{ id: 0, name: "Select", isDisabled: true }}
                        onValueChange={(selectedValues) => {
                          setSelectedReminderForWhom(selectedValues);
                          if (!selectedValues) return;
                          const selectedOption =
                            activityAssignToOptions?.find(
                              (option) => option.id === selectedValues
                            ) || null;
                          setSaveDto({
                            id: "activityReminders",
                            value: saveDto.activityReminders.map(
                              (reminder, index) =>
                                index === 0
                                  ? {
                                      ...reminder,
                                      usersId: selectedValues,
                                    }
                                  : reminder
                            ),
                          });
                          setSelected({
                            id: "activityReminders",
                            value: selected.activityReminders.map(
                              (reminder, index) =>
                                index === 0
                                  ? {
                                      ...reminder,
                                      ReminderforWhom: [selectedOption],
                                    }
                                  : reminder
                            ),
                          });
                        }}
                      />
                      <Box className={styles.containerAddReminders}>
                        <CustomButton
                          size="sm"
                          variant="text"
                          color="tertiaryGray"
                          style={{ display: "flex", gap: "12px" }}
                          startIcon={
                            <PlusIcon
                              width="20"
                              height="20"
                              fill={palette.foreground.quarterary}
                            />
                          }
                          onClick={() => {
                            handleAddRemenders();
                          }}
                          disabled={
                            !selected?.activityReminders[0]?.templete[0] ||
                            selected?.activityReminders[0]?.templete[0]
                              ?.templateName === "" ||
                            selected?.activityReminders[0]?.ReminderforWhom[0]
                              ?.value === "" ||
                            !selected?.activityReminders[0]
                              ?.ReminderforWhom[0] ||
                            !saveDto.activityReminders[0]?.reminderTime ||
                            !selected.activityReminders[0]?.reminderType
                          }
                        >
                          {translate("Add-Reminders")}
                        </CustomButton>
                      </Box>
                    </Box>
                  </>
                </Box>
                <Table className={styles.containerTableReminder}>
                  {saveDto.activityReminders.length > 1 && (
                    <>
                      <thead>
                        <tr className={styles.containerTableHeader}>
                          <th
                            className={clsx(
                              styles.containerTitleHeader,
                              styles.typeTitleReminderStyles
                            )}
                          >
                            {translate("For")}
                          </th>
                          <th
                            className={clsx(
                              styles.containerTitleHeader,
                              styles.typeTitleReminderStyles
                            )}
                          >
                            {translate("Type")}
                          </th>
                          <th
                            className={clsx(
                              styles.containerRimenderTitleHeader,
                              styles.typeTitleReminderStyles
                            )}
                          >
                            {translate("Reminder-title")}
                          </th>
                          <th
                            className={clsx(
                              styles.containerTitleHeader,
                              styles.typeTitleReminderStyles
                            )}
                          >
                            {translate("Duration")}
                          </th>
                          <th
                            className={clsx(
                              styles.containerTitleHeader,
                              styles.typeTitleReminderStyles
                            )}
                          >
                            {translate("Action")}
                          </th>
                        </tr>
                      </thead>

                      <tbody className={styles.containerTableBody}>
                        {saveDto?.activityReminders
                          .slice(1)
                          .map((ItemReminder, index) => (
                            <tr
                              key={index}
                              className={styles.containerTableRow}
                            >
                              {/* For */}
                              <td
                                className={clsx(
                                  styles.forValue,
                                  styles.timeReminderForPersonStyles
                                )}
                              >
                                {selected?.activityReminders
                                  ?.flatMap(
                                    (reminder) => reminder.ReminderforWhom
                                  )
                                  ?.find(
                                    (option) =>
                                      option?.id === ItemReminder.usersId ||
                                      option?.id === ItemReminder.leadId
                                  )?.value || null}
                              </td>

                              {/* Type */}
                              <td
                                className={clsx(
                                  styles.forValue,
                                  styles.typeTitleReminderStyles
                                )}
                              >
                                {selected?.reminderTypeOptions?.agent?.find(
                                  (option) =>
                                    option?.key === ItemReminder?.reminderType
                                )?.value ||
                                  selected?.reminderTypeOptions?.lead?.find(
                                    (option) =>
                                      option?.key === ItemReminder?.reminderType
                                  )?.value ||
                                  null}
                              </td>

                              {/* Reminder Title */}
                              <td
                                className={clsx(
                                  styles.reminderValue,
                                  styles.typeTitleReminderStyles
                                )}
                              >
                                {selected?.activityReminders
                                  ?.flatMap((reminder) => reminder.templete)
                                  ?.find(
                                    (option) =>
                                      option?.templateId ===
                                      ItemReminder?.templateId
                                  )?.templateName || null}
                              </td>

                              {/* Duration */}
                              <td
                                className={clsx(
                                  styles.forValue,
                                  styles.timeReminderForPersonStyles
                                )}
                              >
                                {ItemReminder?.reminderTime &&
                                ItemReminder?.reminderTime.trim() !== ""
                                  ? formattedTimeFun(
                                      new Date(ItemReminder?.reminderTime)
                                    )
                                  : ""}
                              </td>

                              {/* Action */}
                              <td className={styles.containerActionButton}>
                                <Box
                                  className={styles.containerIconActionButton}
                                >
                                  <DeleteIcon
                                    width="20"
                                    height="20"
                                    fill={palette.button.tertiaryGray_fg}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleDeleteLink(index + 1)}
                                  />
                                </Box>
                                <Box
                                  className={styles.containerIconActionButton}
                                >
                                  <EditIcon
                                    width="20"
                                    height="20"
                                    fill={palette.button.tertiaryGray_fg}
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleEditLink(ItemReminder?.index)
                                    }
                                  />
                                </Box>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </>
                  )}
                </Table>
              </>
            )}

            <Box className={styles.containerMoreOption}>
              <Typography>
                {isExpanded ? (
                  <Typography className={styles.MoreOptionTitle}>
                    {translate("View-less-options")}
                  </Typography>
                ) : (
                  <Typography className={styles.MoreOptionTitle}>
                    {translate("View-more-options")}
                  </Typography>
                )}
              </Typography>
              <DropdownIcon
                width="20"
                height="20"
                fill={palette.foreground.navigation["nav-item-button-icon-fg"]}
                onClick={handleExpandMoreOptions}
                style={{
                  transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                  transition: "transform 0.3s ease",
                }}
              />
            </Box>
            {isExpanded && (
              <Box className={styles.containerMoreOptionDetails}>
                <Box className={styles.containerSwitchAndTitle}>
                  <CustomSwitch
                    size={"md"}
                    checked={saveDto.isOpen}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      setSaveDto({ id: "isOpen", value: isChecked });
                    }}
                    isDisabled={false}
                  />
                  <Box className={styles.containerTitles}>
                    <Typography>
                      {saveDto.isOpen ? translate("open") : translate("closed")}
                    </Typography>
                    {saveDto.isOpen ? (
                      <Typography className={styles.statusTitle}>
                        {translate("I-am-open-and-avaliable")}
                      </Typography>
                    ) : (
                      <Typography className={styles.statusTitle}>
                        {translate("I-am-close-and-not-avaliable")}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Divider />
                <Box className={styles.containerInputFiled}>
                  <Typography className={styles.titleInputFiled}>
                    {translate("Attachment")}
                  </Typography>
                  <DropzoneComponent
                    title={translate("SVG-PNG-JPG-or-GIF-max-800x400px")}
                    setUploadedFileId={setUploadedFileId}
                    onFilesAccepted={handleFilesAccepted}
                  />
                </Box>
                <Box className={styles.containerInputFiled}>
                  <Typography className={styles.titleInputFiled}>
                    {translate("Comment")}
                  </Typography>
                  <CustomTextArea
                    maxRows={5}
                    minRows={5}
                    placeholder={translate("Enter-a-comments")}
                    onInputChange={(value) => {
                      setSaveDto({ id: "comments", value: value });
                      setSelected({ id: "comment", value: value });
                    }}
                    value={selected?.comment || ""}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      }
    />
  );
};
export default AddNewActivityFormDialog;
