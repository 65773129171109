import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useSelectedTheme, useTranslate } from "../../Hooks";
import moment from "moment/moment";
import {
  CheckIcon,
  CloseXIcon,
  EmailIcon,
  PhoneIcon,
  SMSIcon,
  WhatsappIcon,
} from "../../assets/icons";

export const NewMergeContactsContext = createContext({
  mergeRows: [],
  setMergeRows: () => {},
  translate: () => {},
  getRowValue: () => {},
  onRowClicked: () => {},
  isLoading: { contactData: false, isFormLoading: false, isMerging: false },
  allContactsInfo: [],
  setAllContactsInfo: () => {},
  originalContact: null,
  setOriginalContact: () => {},
  finalContactDetails: null,
  setFinalContactDetails: () => {},
  selectedRadioValues: {},
  setSelectedRadioValues: () => {},
  changeOriginalCotnact: () => {},
  activeContacts: [],
  setActiveContacts: () => {},
  currentIndex: null,
  setCurrentIndex: () => {},
});

function NewMergeContactsContextProvider({ children }) {
  const { translate } = useTranslate("NewMergeContacts");
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const {
    theme: { palette },
  } = useSelectedTheme();
  const [mergeRows, setMergeRows] = useState([]);
  const [allContactsInfo, setAllContactsInfo] = useState([]);
  const [originalContact, setOriginalContact] = useState(null);
  const [finalContactDetails, setFinalContactDetails] = useState(null);
  const [selectedRadioValues, setSelectedRadioValues] = useReducer(reducer, {});
  const [activeContacts, setActiveContacts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [isLoading, setIsLoading] = useReducer(reducer, {
    contactData: false,
    isFormLoading: false,
    isMerging: false,
  });

  function onRowClicked(row, contact) {
    const newValues = {
      ...selectedRadioValues,
      [row.fieldName]: contact.contactsId,
    };
    changeFinalContactValue(row, contact);
    setSelectedRadioValues({ id: "edit", value: newValues });
  }

  function getIsSelected(row, contact) {
    if (selectedRadioValues?.[row.fieldName] === contact?.contactsId) {
      return true;
    } else return false;
  }

  function GetAllContactsByClassificationIds(contact) {
    let classifications = "";
    let contactClassifications = contact?.contact?.contact_classifications;

    if (!contactClassifications || contactClassifications.length === 0)
      return classifications;

    classifications = contactClassifications
      .map((item) => item?.lookupItemName)
      .filter(Boolean)
      .join(", ");

    return classifications;
  }

  function GetAllContactPreference(contact) {
    const contactPreferences = contact?.contact?.contact_preference || [];

    const hasEmail = contactPreferences.some(
      (pref) => pref.lookupItemName.toLowerCase() === "email"
    );
    const hasSMS = contactPreferences.some(
      (pref) => pref.lookupItemName.toLowerCase() === "sms"
    );
    const hasWhatsApp = contactPreferences.some(
      (pref) => pref.lookupItemName.toLowerCase() === "whatsapp"
    );
    const hasCall = contactPreferences.some(
      (pref) => pref.lookupItemName.toLowerCase() === "call"
    );

    return (
      <>
        <div className="d-flex" style={{ gap: "8px" }}>
          <span>
            {hasCall ? (
              <PhoneIcon width="20" height="20" fill={palette.text.secondary} />
            ) : (
              ""
            )}
          </span>
          <span>
            {hasSMS ? (
              <SMSIcon width="20" height="20" fill={palette.text.secondary} />
            ) : (
              ""
            )}
          </span>
          <span>
            {hasEmail ? (
              <EmailIcon width="20" height="20" fill={palette.text.secondary} />
            ) : (
              ""
            )}
          </span>
          <span>
            {hasWhatsApp ? (
              <WhatsappIcon
                width="20"
                height="20"
                fill={palette.text.secondary}
              />
            ) : (
              ""
            )}
          </span>

          {!hasCall && !hasSMS && !hasEmail && !hasWhatsApp ? "N/A" : null}
        </div>
      </>
    );
  }

  function getAllContactsPerson(contact) {
    let people = "";
    let contactsPerson = contact?.contact?.contacts_person;

    if (!contactsPerson || contactsPerson.length === 0) return people;

    people = contactsPerson
      .map((item) => item?.name)
      .filter(Boolean)
      .join(", ");

    return people;
  }

  function getRowValue(contact, row) {
    let value = null;

    if (row.fieldType === "select") {
      if (
        [
          "angry_bird",
          "multi_property_owner",
          "high_profile_management",
          "promo_by_email",
          "promo_by_sms",
          "promo_by_whatsapp",
          "flagged_contact",
          "psi_opportunity",
          "owner_in_multiple_areas",
          "high_net_worth",
          "owner_for_one_unit",
          "investor",
          "promo_smsemail",
          "exclusive",
          "signing_branch",
          "audit_process_completed",
          "executed_agreement_sent_to_finance",
        ].includes(row.fieldName)
      ) {
        value = contact?.contact?.[row.fieldName];
      } else if (row.fieldName === "contact_classifications") {
        value = GetAllContactsByClassificationIds(contact);
      } else if (row.fieldName === "contact_preference") {
        value = GetAllContactPreference(contact);
      } else {
        value = contact?.contact?.[row.fieldName]?.lookupItemName;
      }
    } else if (row.fieldType === "textarea") {
      if (["contact_remarks", "special_requirements"].includes(row.fieldName)) {
        value = contact?.contact?.[row.fieldName];
      }
    } else if (row.fieldType === "communication") {
      if (
        [
          "fax",
          "mobile",
          "work_phone",
          "home_phone",
          "landline_number",
          "whatsapp_mobile",
          "other_contact_mobile_no",
        ].includes(row.fieldName)
      ) {
        value = contact?.contact?.[row.fieldName]?.phone;
      } else if (["email_address", "general_email"].includes(row.fieldName)) {
        value = contact?.contact?.[row.fieldName]?.email;
      } else if (
        [
          "linkedin_link",
          "facebook_link",
          "twitter_link",
          "tiktok_link",
          "snapchat_link",
          "instagram_link",
        ].includes(row.fieldName)
      ) {
        value = contact?.contact?.[row.fieldName]?.link;
      }
    } else if (row.fieldType === "address") {
      if (
        ["city", "country", "district", "community", "sub_community"].includes(
          row.fieldName
        )
      ) {
        value = contact?.contact?.[row.fieldName]?.lookupItemName;
      } else if (
        [
          "street",
          "near_by",
          "floor_no",
          "building_no",
          "building_name",
          "postalzip_code",
          "office_no",
        ].includes(row.fieldName)
      ) {
        value = contact?.contact?.[row.fieldName]?.value;
      } else {
        value = `${contact?.contact?.[row.fieldName]?.latitude || "N/A"}, ${
          contact?.contact?.[row.fieldName]?.longitude || "N/A"
        }`;
      }
    } else if (row.fieldType === "alt-date") {
      if (contact?.contact?.[row.fieldName]) {
        value = moment(contact?.contact?.[row.fieldName]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
    } else if (row.fieldType === "searchField") {
      if (
        [
          "works_company_name",
          "spouse_contact",
          "representative_contact",
          "property_name",
          "authorized_signatory",
          "key_contact",
        ].includes(row.fieldName)
      ) {
        value = contact?.contact?.[row.fieldName]?.name;
      } else if (row.fieldName === "contacts_person") {
        value = getAllContactsPerson(contact);
      }
    } else if (row.fieldType === "UploadFiles") {
      if (
        row.fieldName === "contact_image" ||
        row.fieldName === "company_logo"
      ) {
        if (
          selectedRadioValues?.[row.fieldName] === contact?.contactsId ||
          contact === finalContactDetails
        ) {
          value = "Active";
        } else value = "Not Active";
      }
    } else {
      value = contact?.contact?.[row.fieldName];
    }

    if (typeof value === "string" && value.toLowerCase() === "yes") {
      return (
        <CheckIcon width="20" height="20" fill={palette.text.success_primary} />
      );
    } else if (typeof value === "string" && value.toLowerCase() === "no") {
      return (
        <CloseXIcon width="20" height="20" fill={palette.text.error_primary} />
      );
    } else return value || "N/A";
  }

  function changeFinalContactValue(row, contact) {
    function checkForIcon(value) {
      if (typeof value === "string" && value.toLowerCase() === "yes") {
        return (
          <CheckIcon
            width="20"
            height="20"
            fill={palette.text.success_primary}
          />
        );
      } else if (typeof value === "string" && value.toLowerCase() === "no") {
        return (
          <CloseXIcon
            width="20"
            height="20"
            fill={palette.text.error_primary}
          />
        );
      } else return contact?.contact?.[row.fieldName];
    }

    const newFinalContactValue = {
      ...finalContactDetails,
      contact: {
        ...finalContactDetails.contact,
        [row.fieldName]: checkForIcon(contact?.contact?.[row.fieldName]),
      },
    };
    setFinalContactDetails(newFinalContactValue);
  }

  async function changeOriginalCotnact(newContact) {
    setOriginalContact(newContact);
    setFinalContactDetails(newContact);
    setCurrentIndex(0);
  }

  function swapContacts(array, oldId, newId) {
    const oldIndex = array.findIndex((item) => item.contactsId === oldId);
    const newIndex = array.findIndex((item) => item.contactsId === newId);

    if (oldIndex !== -1 && newIndex !== -1) {
      [array[oldIndex], array[newIndex]] = [array[newIndex], array[oldIndex]];
    }

    return array;
  }

  useEffect(() => {
    let newSelectedValues = {};
    if (originalContact?.contactsId) {
      const newData = swapContacts(
        allContactsInfo,
        allContactsInfo[0]?.contactsId,
        originalContact?.contactsId
      );

      setActiveContacts(newData.slice(currentIndex, currentIndex + 3));
    }

    Object.keys({ ...selectedRadioValues }).forEach((key) => {
      newSelectedValues = {
        ...newSelectedValues,
        [key]: originalContact?.contactsId,
      };
    });

    setSelectedRadioValues({ id: "edit", value: newSelectedValues });
  }, [originalContact]);

  return (
    <NewMergeContactsContext.Provider
      value={{
        mergeRows,
        setMergeRows,
        translate,
        getRowValue,
        onRowClicked,
        getIsSelected,
        isLoading,
        allContactsInfo,
        setAllContactsInfo,
        originalContact,
        setOriginalContact,
        finalContactDetails,
        setFinalContactDetails,
        selectedRadioValues,
        setSelectedRadioValues,
        changeOriginalCotnact,
        activeContacts,
        setActiveContacts,
        currentIndex,
        setCurrentIndex,
      }}
    >
      {children}
    </NewMergeContactsContext.Provider>
  );
}

export default NewMergeContactsContextProvider;
