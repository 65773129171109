import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GlobalHistory } from "../../../../Helper";
import { SmartProposalActions } from "../../../../store/SmartProposal/Actions";

const useRedirectOnInvalid = () => {

    const dispatch = useDispatch();
    const { proposalType, checkedProperties } = useSelector(
      (state) => state.SmartProposalReducer
    );

    const clearCacheHandler = () => {
        dispatch(SmartProposalActions.SmartProposalRequest({}));
    }
    
    
    const isInvalidProposalInfo = !checkedProperties || !proposalType || checkedProperties?.length == 0;

      useEffect(() => {
        if(isInvalidProposalInfo) GlobalHistory.goBack();
        // return () => {
        //   clearCacheHandler();
        // }
      }, []);  

      return { isInvalidProposalInfo }
}

export default useRedirectOnInvalid
