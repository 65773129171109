import React, {
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import _ from "lodash";
import moment from "moment";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  CustomDateRangePicker,
  CustomIconButton,
  CustomInput,
  CustomSelect,
  ViewModeSwitcher,
  RolePinSideTab,
  VIEW_MODES,
  CustomButton,
  SortingDropdown,
  CustomPermission,
  CustomDatePickerComponent,
} from "../../../../../Components";
import { ColumnsFilterPagesEnum } from "../../../../../Enums";
import { useVerticalNav } from "../../../../../Contexts/VerticalNavContext";
import { useSelectedTheme, useTranslate } from "../../../../../Hooks";
import { PropertiesPermissionsCRM } from "../../../../../Permissions";
import { useNewLayoutShared } from "../../../../../Contexts/NewLayoutSharedContext";
import { PropertiesLayoutContext } from "../../../../../Layouts/Home/NewPropertiesCrmLayout/PropertiesLayoutContext";
import { GetLookupItems } from "../../../../../Services";

// Styles
import useStyles from "./styles";

// Icons
import {
  ColumnsThree,
  SettingsFour,
  CloseXIcon,
  LinkIcon,
  Calendar,
} from "../../../../../assets/icons";

function QuickFilterSection({
  viewMode,
  onChangeViewMode,
  setUpdateSelectedColumnItems,
  allFormFields,
  tableColumns,
  initialTableColumnsState,
}) {
  const { setAdvancedSearchBody, advancedSearchBody } = useNewLayoutShared();
  const { propertiesData, isLoading } = useContext(PropertiesLayoutContext);
  const { translate } = useTranslate("ContactsView");
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    theme: { palette },
  } = useSelectedTheme();

  const styles = useStyles();

  const [isPinTabOpen, setIsPinTabOpen] = useState(false);
  const [publishDate, setPublishDate] = useState(null);
  const [selectedDateOption, setSelectedDateOption] = useState(1);

  const handleClosePinSideTab = () => {
    setIsPinTabOpen(false);
  };

  const fromDateKey = useMemo(() => {
    return selectedDateOption === 1 ? "fromDate" : "updatedFromDate";
  }, [selectedDateOption]);

  const toDateKey = useMemo(() => {
    return selectedDateOption === 1 ? "toDate" : "updatedToDate";
  }, [selectedDateOption]);

  const [showQuickFiltersSection, setShowQuickFiltersSection] = useState(true);

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: "selection",
    selectedDateType: 1,
  };
  const [dateFilter, setDateFilter] = useState(dateRangeDefault);

  const { setAlertBoxContent, actionableItems, setActionableItems } =
    useVerticalNav();

  const handleApplyDateFilter = (ranges) => {
    setAdvancedSearchBody((prev) => {
      const { [fromDateKey]: _, [toDateKey]: __, ...restBody } = prev;
      const isDateRangeEmpty = !ranges.startDate && !ranges.endDate;

      if (isDateRangeEmpty) {
        return {
          ...restBody,
        };
      }

      return {
        ...prev,
        [fromDateKey]: moment(ranges.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss"),
        [toDateKey]: moment(ranges.endDate).format("YYYY-MM-DDTHH:mm:ss"),
      };
    });
  };

  // const handleApplyPublishDate = (newValue) => {
  //   // Validate the date format
  //   const isValidDate = moment(newValue, "DD/MM/YYYY", true).isValid();

  //   if (!isValidDate) return;

  //   // Convert to required format: "YYYY-MM-DDT00:00:00"
  //   const formattedDate = moment(newValue, "DD/MM/YYYY").format(
  //     "YYYY-MM-DDT00:00:00"
  //   );

  //   setAdvancedSearchBody((prev) => {
  //     const { ...restCriteria } = prev.criteria;

  //     return {
  //       ...prev,
  //       criteria: {
  //         ...restCriteria,
  //         webPublishedDate: [{ searchType: 10, value: formattedDate }],
  //       },
  //     };
  //   });
  // };

  const handleApplyButton = () => {
    if (actionableItems.action === "bulk_update") {
      const filteredProperties = propertiesData?.result?.filter((item) =>
        actionableItems?.selectedIds?.includes(item?.id)
      );

      localStorage.setItem(
        "bulk-assign-ids",
        JSON.stringify(filteredProperties)
      );

      history.push("/home/Properties-CRM/property-bulk-update");
    }
  };

  useEffect(() => {
    if (!dateFilter.startDate && !dateFilter.endDate) {
      return;
    }

    setAdvancedSearchBody((prev) => {
      const { fromDate, updatedFromDate, toDate, updatedToDate, ...restBody } =
        prev;
      const isDateRangeEmpty = !dateFilter.startDate && !dateFilter.endDate;

      if (isDateRangeEmpty) {
        return {
          prev,
        };
      }

      return {
        ...restBody,
        [fromDateKey]: moment(dateFilter.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss"),
        [toDateKey]: moment(dateFilter.endDate).format("YYYY-MM-DDTHH:mm:ss"),
      };
    });
  }, [selectedDateOption]);

  const [propertyPlanList, setPropertyPlanList] = useState(null);

  useEffect(() => {
    const getLookupItem = async () => {
      const res = await GetLookupItems(1, 25, "Property Plan");
      if (!(res && res.data && res.data.ErrorId)) {
        setPropertyPlanList(res && res.result);
      } else {
        setPropertyPlanList([]);
      }
    };

    getLookupItem();
  }, []);

  const handleSelectTypeChange = (type, newValue) => {
    setActionableItems({
      selectedIds: [],
      action: null,
    });
    setAdvancedSearchBody((prev) => {
      const { [type]: _, ...restCriteria } = prev.criteria;

      // Check if the new value is 0 and the previous value was also 0
      if (!newValue && ![type]) {
        return prev; // No state update needed
      }

      return {
        ...prev,
        criteria: !newValue
          ? restCriteria
          : {
              ...restCriteria,
              [type]: [{ searchType: 1, value: newValue }],
            },
      };
    });
  };

  return (
    <>
      <Box className={styles.filtersContainer}>
        <Box className={styles.flex} style={{ flexFlow: "wrap" }}>
          {actionableItems?.selectedIds?.length > 0 && (
            <>
              <CustomIconButton
                variant="text"
                size="none"
                boxShadow="none"
                color="tertiaryColor"
                hideHoverBg
              >
                <CloseXIcon
                  onClick={() =>
                    setActionableItems({
                      selectedIds: [],
                      action: null,
                    })
                  }
                  width="20"
                  height="20"
                  fill={palette.button.secondary_fg}
                />
              </CustomIconButton>

              <Box className={styles.selectedCount}>
                {actionableItems?.selectedIds?.length} selected
              </Box>
            </>
          )}

          <CustomButton
            boxShadow="xs"
            size="lg"
            variant="outlined"
            color="secondary"
            onClick={() => {
              setActionableItems({
                selectedIds: [],
                action: "bulk_update",
              });
            }}
            startIcon={
              <LinkIcon
                width="20"
                height="20"
                fill={palette.button.secondary_fg}
              />
            }
            hasToolTip={true}
            toolTipMessage="You can select up to 25 properties."
          >
            Bulk update
          </CustomButton>

          {actionableItems?.action === "bulk_update" &&
            actionableItems?.selectedIds?.length > 0 && (
              <CustomButton
                size="lg"
                variant="text"
                color="tertiary"
                onClick={handleApplyButton}
              >
                Apply action
              </CustomButton>
            )}

          {actionableItems?.action && (
            <CustomButton
              size="lg"
              variant="text"
              color="tertiary"
              onClick={() => {
                setActionableItems({
                  selectedIds: [],
                  action: null,
                });
              }}
            >
              Cancel
            </CustomButton>
          )}
        </Box>

        <Box className={styles.flex}>
          <CustomPermission
            permissionsList={Object.values(PropertiesPermissionsCRM)}
            permissionsId={
              PropertiesPermissionsCRM.ViewAndSearchProperties.permissionsId
            }
          >
            <Box>
              <SortingDropdown
                isLoading={isLoading}
                setAdvancedSearchBody={setAdvancedSearchBody}
                advancedSearchBody={advancedSearchBody}
                translate={translate}
              />
            </Box>
            <CustomIconButton
              variant="outlined"
              size="md"
              boxShadow="none"
              color="secondary"
              onClick={() => setShowQuickFiltersSection((prev) => !prev)}
            >
              <SettingsFour
                width="20"
                height="20"
                fill={palette.button.secondary_fg}
              />
            </CustomIconButton>

            {viewMode === VIEW_MODES.TABLE && (
              <CustomIconButton
                variant="outlined"
                size="md"
                boxShadow="none"
                color="secondary"
                onClick={() => setIsPinTabOpen((prev) => !prev)}
              >
                <ColumnsThree
                  width="20"
                  height="20"
                  fill={palette.button.secondary_fg}
                />
              </CustomIconButton>
            )}

            <ViewModeSwitcher
              viewMode={viewMode}
              onChangeViewMode={onChangeViewMode}
              isLoading={isLoading}
            />
          </CustomPermission>
        </Box>
      </Box>

      {showQuickFiltersSection && (
        <CustomPermission
          permissionsList={Object.values(PropertiesPermissionsCRM)}
          permissionsId={
            PropertiesPermissionsCRM.ViewAndSearchProperties.permissionsId
          }
        >
          <Box className={styles.quickFilters}>
            <Box className={styles.fieldWrapper}>
              <Box className={styles.selectFields}>
                {/* filters */}
                {/* <CustomSelect
                  options={propertyPlanList?.map((item) => ({
                    id: item.lookupItemId,
                    name: item.lookupItemName,
                  }))}
                  onValueChange={(elementId) => {
                    handleSelectTypeChange(
                      "property_plan.lookupItemId",
                      elementId
                    );
                  }}
                  emptyItem={{
                    id: 0,
                    name: "Select property plan",
                    isDisabled: false,
                  }}
                  variant="default"
                  disabled={isLoading}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  customSelectClasses={styles.customSelect}
                /> */}
              </Box>
              <Box>{/* Address filter */}</Box>
            </Box>
            <Box
              className={styles.fieldWrapper}
              style={{ justifyContent: "flex-end" }}
            >
              <Box style={{ maxWidth: "165px" }}>
                <CustomSelect
                  width={"165px"}
                  options={[
                    { key: 1, value: translate("created-date") },
                    { key: 2, value: translate("update-on-date") },
                    // { key: 3, value: translate("publish-date") },
                  ]}
                  onValueChange={(e) => setSelectedDateOption(e)}
                  variant="default"
                  disabled={isLoading}
                  getOptionLabel={(option) => option.value}
                  getOptionValue={(option) => option.key}
                  defaultValue={selectedDateOption}
                />
              </Box>
              {/* 
              {selectedDateOption === 3 ? (
                <CustomDatePickerComponent
                  helperText=""
                  onError={() => null} // Disables error messages
                  value={publishDate}
                  customStyle={styles.datePickerBtn}
                  placeholder={"dd/mm/yyyy"}
                  withIcon
                  format={"DD/MM/YYYY"}
                  onDateChanged={(newValue) => {
                    setPublishDate(newValue);

                    handleApplyPublishDate(newValue);
                  }}
                  Icon={
                    <Calendar
                      width={20}
                      height={20}
                      fill={palette.button.secondary_fg}
                    />
                  }
                  autoOk={translate("Confirm")}
                />
              ) : ( */}
              <CustomDateRangePicker
                onClearClicked={() => {
                  setDateFilter(dateRangeDefault);

                  setAdvancedSearchBody((prev) => {
                    const {
                      [fromDateKey]: _,
                      [toDateKey]: __,
                      ...restBody
                    } = prev;

                    return {
                      ...restBody,
                    };
                  });
                }}
                ranges={[dateFilter]}
                onDateChanged={(selectedDate) => {
                  setDateFilter((item) => ({
                    ...item,
                    startDate:
                      selectedDate.selection &&
                      selectedDate.selection.startDate,
                    endDate: !selectedDate.selection?.endDate
                      ? null
                      : new Date(
                          moment(
                            selectedDate.selection &&
                              selectedDate.selection.endDate
                          ).endOf("day")
                        ),
                    key: "selection",
                  }));
                }}
                emptyLabel="dd/mm/yyyy"
                displayFormat="MMM D, YYYY"
                onSave={handleApplyDateFilter}
              />
              {/* )} */}
            </Box>
          </Box>
        </CustomPermission>
      )}

      {isPinTabOpen && (
        <RolePinSideTab
          isProperty={true}
          setUpdateSelectedColumnItems={setUpdateSelectedColumnItems}
          open={isPinTabOpen}
          onClose={handleClosePinSideTab}
          allFormFields={allFormFields}
          initialTableColumnsState={initialTableColumnsState}
          type={ColumnsFilterPagesEnum.properties.key}
        />
      )}
    </>
  );
}

export default QuickFilterSection;
