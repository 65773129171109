import React from "react";

//  styles
import useStyles from './styles'

function MPI({}) {
    const styles =useStyles()

    return(
        <>MPI</>
    )
}

export default MPI