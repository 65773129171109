import React, {
  useState,
  useCallback,
  useReducer,
  useEffect,
  useRef,
} from 'react';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { showError, showSuccess, GetParams } from '../../../../Helper';
import {
  Spinner,
  Inputs,
  AutocompleteComponent,
  SelectComponet,
} from '../../../../Components';
import {
  GetAllRoles,
  CreateExternalLink,
  UpdateExternalLink,
} from '../../../../Services';
import { ApplicationUserSearch } from '../../../../Services/userServices';

export const ExternalLinksManangementDialog = ({
  isDialogOpen,
  onClose,
  onSave,
  activeItem,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation('ExternalLinksView');
  const searchTimer = useRef(null);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [isLoading, setIsLoading] = useReducer(reducer, {
    users: false,
    roles: false,
    saving: false,
  });

  const [data, setData] = useReducer(reducer, {
    users: [],
    roles: [],
  });

  const [selected, setSelected] = useReducer(reducer, {
    externalLinkRoles: [],
    externalLinkUsers: [],
  });

  const [state, setState] = useReducer(reducer, {
    externalLinkName: null,
    url: null,
    description: null,
    externalLinkRoles: [],
    externalLinkUsers: [],
    applicationVisibility: null,
  });

  const getAllRoles = useCallback(async (searchValue) => {
    setIsLoading({ id: 'roles', value: true });
    const pageIndex = 1;
    const pageSize = 25;

    const response = await GetAllRoles(pageIndex, pageSize, searchValue);
    if (!(response && response.status && response.status !== 200)) {
      setData({ id: 'roles', value: response.result || [] });
    } else setData({ id: 'roles', value: [] });

    setIsLoading({ id: 'roles', value: false });
  }, []);

  const getAllUsers = useCallback(async (searchValue) => {
    setIsLoading({ id: 'users', value: true });
    const res = await ApplicationUserSearch({
      pageIndex: 1,
      pageSize: 25,
      rolesIds: [],
      name: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      const localResponse = (res && res.result) || [];
      if (localResponse.length > 0) {
        setData({ id: 'users', value: localResponse || [] });
      } else setData({ id: 'users', value: [] });
    }
    setIsLoading({ id: 'users', value: false });
  }, []);

  const saveHandler = async (event) => {
    event.preventDefault();
    if (!state.applicationVisibility) {
      state.applicationVisibility = 1
    }
    setIsLoading({ id: 'saving', value: true });
    const body = { ...state};
    const res =
      (activeItem &&
        (await UpdateExternalLink(body, activeItem.externalLinkId))) ||
      (await CreateExternalLink(body));
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(
        t(
          `${translationPath}${
            (activeItem && `external-link-updated-successfully`) ||
            `external-link-created-successfully`
          }`
        )
      );
      if (onSave) onSave();
    } else {
      showError(
        t(
          `${translationPath}${
            (activeItem && `external-link-update-failed`) ||
            `external-link-create-failed`
          }`
        )
      );
    }
    setIsLoading({ id: 'saving', value: false });
  };

  useEffect(() => {
    if (activeItem) {
      setState({
        id: 'edit',
        value: {
          externalLinkName: activeItem.externalLinkName,
          url: activeItem.url,
          description: activeItem.description,
          externalLinkRoles: activeItem.externalLinkRoles,
          externalLinkUsers: activeItem.externalLinkUsers,
          applicationVisibility: activeItem?.applicationVisibilityId,
        },
      });

      setSelected({
        id: 'externalLinkUsers',
        value: activeItem.externalLinkUsers || [],
      });
      setSelected({
        id: 'externalLinkRoles',
        value: activeItem.externalLinkRoles || [],
      });
    }
  }, [activeItem]);

  useEffect(() => {
    getAllRoles();
    getAllUsers();
  }, []);

  return (
    <Dialog
      open={isDialogOpen}
      onClose={onClose}
      className='activities-management-dialog-wrapper'
    >
      <Spinner
        isActive={isLoading.saving}
        isAbsolute
      />
      <form
        noValidate
        onSubmit={saveHandler}
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {t(
            `${translationPath}${
              (activeItem && 'edit-external-link') || 'add-new-external-link'
            }`
          )}
        </DialogTitle>
        <DialogContent>
          <div className='d-flex p-3'>
            <div className='w-50 mx-2'>
              <div className='my-1'>
                <Inputs
                  idRef='externalLinkNameIdRef'
                  labelValue='external-link-name'
                  value={state.externalLinkName || ''}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    setState({
                      id: 'externalLinkName',
                      value: event.target.value,
                    });
                  }}
                />
              </div>
              <div className='my-2'>
                <AutocompleteComponent
                  idRef='externalLinkRolesRef'
                  labelValue='external-link-roles'
                  selectedValues={selected.externalLinkRoles || []}
                  multiple
                  data={data.roles}
                  chipsLabel={(option) => option.rolesName || ''}
                  displayLabel={(option) => option.rolesName || ''}
                  getOptionSelected={(option) =>
                    selected.externalLinkRoles.findIndex(
                      (item) => item.rolesId === option.rolesId
                    ) !== -1 || ''
                  }
                  withoutSearchButton
                  inputPlaceholder={`Select-multiple`}
                  isWithError
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputKeyUp={(event) => {
                    const { value } = event.target;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getAllRoles(value);
                    }, 700);
                  }}
                  onChange={(event, newValue) => {
                    const mappedUsersList =
                      newValue &&
                      newValue.map((item) => ({
                        rolesId: item.rolesId,
                      }));
                    setState({
                      id: 'externalLinkRoles',
                      value: mappedUsersList || [],
                    });
                    setSelected({
                      id: 'externalLinkRoles',
                      value: newValue || [],
                    });
                  }}
                  isLoading={isLoading.roles}
                />
              </div>
              <div className='my-1'>
                <Inputs
                  idRef='descriptionIdRef'
                  labelValue='description'
                  value={state.description || ''}
                  multiline
                  rows={4}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    setState({ id: 'description', value: event.target.value });
                  }}
                />
              </div>
            </div>
            <div className='w-50 mx-2'>
              <div className='my-1'>
                <Inputs
                  idRef='urlIdRef'
                  labelValue='url-label-text'
                  value={state.url || ''}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputChanged={(event) => {
                    setState({ id: 'url', value: event.target.value });
                  }}
                />
              </div>
              <div className='my-2'>
                <AutocompleteComponent
                  idRef='externalLinkUsersIdRef'
                  labelValue='external-link-users'
                  selectedValues={selected.externalLinkUsers}
                  data={data.users || []}
                  chipsLabel={(option) =>
                    option.fullName || option.usersName || ''
                  }
                  displayLabel={(option) =>
                    option.fullName || option.usersName || ''
                  }
                  renderOption={(option) =>
                    (option.fullName
                      ? `${option.fullName}`
                      : `${option.usersName}`) || ''
                  }
                  getOptionSelected={(option) =>
                    selected.externalLinkUsers.findIndex(
                      (item) =>
                        item.usersId === option.usersId ||
                        item.applicationUserId === option.applicationUserId
                    ) !== -1 || ''
                  }
                  withoutSearchButton
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onInputKeyUp={(event) => {
                    const { value } = event.target;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getAllUsers(value);
                    }, 700);
                  }}
                  onChange={(event, newValue) => {
                    const mappedUsersList =
                      newValue &&
                      newValue.map((item) => ({
                        usersId: item.applicationUserId || item.usersId,
                      }));
                    setState({
                      id: 'externalLinkUsers',
                      value: mappedUsersList || [],
                    });
                    setSelected({
                      id: 'externalLinkUsers',
                      value: newValue || [],
                    });
                  }}
                  isLoading={isLoading.users}
                />
              </div>
              <div className='my-2'>
                <SelectComponet
                  idRef='visibilityRef'
                  labelValue='visibility-product'
                  value={state?.applicationVisibility}
                  parentTranslationPath={parentTranslationPath}
                  data={[
                    { id: 1, value: 'PropX CRM' },
                    { id: 2, value: 'MyLead' },
                    { id: 3, value: 'Both' },
                  ]}
                  valueInput='id'
                  textInput='value'
                  onSelectChanged={(value) => {
                    setState({ id: 'applicationVisibility', value });
                  }}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonBase
            onClick={onClose}
            className='btns theme-solid bg-cancel'
          >
            {t(`${translationPath}cancel`)}
          </ButtonBase>
          <ButtonBase
            className='btns theme-solid'
            onClick={saveHandler}
            type='submit'
          >
            {t(`${translationPath}save`)}
          </ButtonBase>
        </DialogActions>
      </form>
    </Dialog>
  );
};
