import React, {
  useState,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteComponent,
  Inputs,
  Spinner,
} from '../../../../../../Components';
import { StaticLookupsIds } from '../../../../../../assets/json/StaticLookupsIds';
import {
  GetContactDetailsFromIdCardDocument,
  GetContacts,
  GetScopeCategoryDocuments,
  UpdateContactDetailsForLeadStage,
  lookupItemsGetId,
} from '../../../../../../Services';
import {
  getDownloadableLink,
  getErrorByName,
  showError,
  showSuccess,
} from '../../../../../../Helper';
import { MultipleTypesDocumentsUploader } from '../../../../../../SharedComponents/MultipleTypesDocumentsUploader/MultipleTypesDocumentsUploader';
import { ButtonBase, DialogActions } from '@material-ui/core';
import { emailExpression, phoneExpression } from '../../../../../../Utils';
import { config } from '../../../../../../config';
import Joi from 'joi';
import './OpportunityLeadStageDialog.scss';
import { ScopeDocumentEnum } from '../../../../../../Enums';

function OpportunityLeadStageDialog({
  onClose,
  stageId,
  contactId,
  reload,
  leadId,
  activities,
}) {
  const parentTranslationPath = 'LeadAssignAgentsTracking';
  const translationPath = '';
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const IdCategoryLookup = {
    id: config.ContactDocsCategoryIds_IDCard,
    name: 'ID',
  };

  const passportCategoryLookup = {
    id: config.ContactDocsCategoryIds_Passport,
    name: 'Passport',
  };

  const KYCCategoryLookup = {
    id: config.ContactDocsCategoryIds_KYC,
    name: 'KYC',
  };
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [state, setState] = useReducer(reducer, {
    stageId,
    firstName: null,
    lastName: null,
    companyName: null,
    nationalityId: null,
    visaTypeId: null,
    passportNo: null,
    idCardNo: null,
    mobileNumber: null,
    emailAddress: null,
    documents: {
      scopeId: null,
      scopeTypeId: null,
      categoryFiles: [
        {
          categoryId: null,
          files: [
            {
              fileId: null,
              fileName: null,
              documentLink: null,
            },
          ],
        },
      ],
    },
  });
  const isCardRequired = useMemo(
    () => ![18072, 18069].includes(state.visaTypeId),
    [state.visaTypeId]
  );

  const [selected, setSelected] = useReducer(reducer, {
    nationality: null,
    visaType: null,
    documents: {
      IdDocuments: [],
      passportDocuments: [],
      KYCDocuments: [],
    },
  });

  const [data, setData] = useReducer(reducer, {
    visaTypes: [],
    nationalities: [],
  });
  const [filter, setFilter] = useReducer(reducer, {
    pageIndex: 0,
    pageSize: 25,
  });

  const [isUploading, setIsUploading] = useState(false);
  const [contactData, setContactData] = useReducer(reducer, {});
  const [loadings, setLoadings] = useReducer(reducer, {
    global: false,
    nationalities: false,
    visaTypes: false,
    uploading: false,
    contact: false,
    documents: false,
  });

  const schema = Joi.object({
    firstName: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}first-name-is-required`),
        'string.empty': t(`${translationPath}first-name-is-required`),
      }),

    lastName: Joi.string()
      .required()
      .messages({
        'string.base': t(`${translationPath}last-name-is-required`),
        'string.empty': t(`${translationPath}last-name-is-required`),
      }),
    nationalityId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}nationality-is-required`),
        'number.empty': t(`${translationPath}nationality-is-required`),
      }),
    visaTypeId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}visa-type-is-required`),
        'number.empty': t(`${translationPath}visa-type-is-required`),
      }),
    idCardNo: Joi.string().when('visaTypeId', {
      is: Joi.valid(18072, 18069),
      then: Joi.string().allow('').optional(),
      otherwise: Joi.string()
        .required()
        .messages({
          'string.base': t(`${translationPath}idcard-number-is-required`),
          'string.empty': t(`${translationPath}idcard-number-is-required`),
        }),
    }),
    emailAddress: Joi.string()
      .required()
      .regex(emailExpression)
      .messages({
        'string.pattern.base': t('invalid-email'),
        'string.empty': t('invalid-email'),
      }),
    mobileNumber: Joi.string()
      .required()
      .regex(phoneExpression)
      .messages({
        'string.empty': t(`${translationPath}mobile-number-is-required`),
        'string.pattern.base': t(`${translationPath}invalid-mobile-number`),
      }),
    passportNo: Joi.required()
      .custom((value, helpers) => {
        if (!state.passportNo)
          return helpers.error('passport-number-msg-value');
        return value;
      })
      .messages({
        'passport-number-msg-value': t(
          `${translationPath}passport-number-is-required`
        ),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const documentsSchema = Joi.object({
    IdDocuments: Joi.required()
      .custom((value, helpers) => {
        const isIdDocsEmpty = selected.documents.IdDocuments.length === 0;
        if (isIdDocsEmpty && isCardRequired) return helpers.error('ID-documents-msg-value');
        return value;
      })
      .messages({
        'ID-documents-msg-value': t(
          `${translationPath}ID-documents-are-required`
        ),
      }),
    passportDocuments: Joi.required()
      .custom((value, helpers) => {
        const isPassportDocsEmpty =
          selected.documents.passportDocuments.length == 0;

        if (isPassportDocsEmpty)
          return helpers.error('Passport-documents-msg-value');
        return value;
      })
      .messages({
        'Passport-documents-msg-value': t(
          `${translationPath}passport-documents-are-required`
        ),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(selected?.documents);

  const UpdateStates = () => {
    const newSelected = {
      nationality: contactData?.contact?.nationality,
      visaType: contactData?.contact?.visa_type,
      documents: selected.documents,
    };

    setSelected({ id: 'edit', value: newSelected });

    const newState = {
      stageId,
      firstName: contactData?.contact?.first_name,
      lastName: contactData?.contact?.last_name,
      companyName: contactData?.contact?.company_name,
      nationalityId: newSelected?.nationality?.lookupItemId,
      visaTypeId: newSelected?.visaType?.lookupItemId,
      passportNo: contactData?.contact?.passport_no,
      idCardNo: contactData?.contact?.id_card_no,
      mobileNumber: contactData?.contact?.mobile?.phone,
      emailAddress: contactData?.contact?.email_address?.email,
      documents: state.documents,
    };

    setState({ id: 'edit', value: newState });
  };

  const GetContact = async () => {
    setLoadings({ id: 'contact', value: true });
    const results = await GetContacts({
      pageIndex: filter.pageIndex,
      pageSize: filter.pageSize,
      search: contactId,
      isAdvance: false,
    });

    if (!(results && results.status && results.status !== 200)) {
      const item = results.result[0];
      setContactData({
        id: 'edit',
        value: item,
      });
    } else setContactData({});
    setLoadings({ id: 'contact', value: false });
  };

  const getAllNationalitiesAPICall = async () => {
    setLoadings({ id: 'nationalities', value: true });

    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.Country,
    });

    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'nationalities', value: res });
    } else {
      setData({ id: 'nationalities', value: res });
    }
    setLoadings({ id: 'nationalities', value: false });
  };

  const getVisaTypes = async () => {
    setLoadings({ id: 'visaTypes', value: true });
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.visaTypes,
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'visaTypes', value: res });
    else setData({ id: 'visaTypes', value: res });
    setLoadings({ id: 'visaTypes', value: false });
  };

  const getContactOldVisaDocuments = useCallback(async (documentCategory) => {
    setLoadings({ id: 'documents', value: true });

    const body = {
      scopeId: contactId,
      pageSize: 25,
      pageIndex: 0,
    };

    try {
      const [IdDocsResponse, passportDocsResponse, KYCDocsResponse] =
        await Promise.all([
          GetScopeCategoryDocuments({
            ...body,
            categoryId: IdCategoryLookup.id,
          }),
          GetScopeCategoryDocuments({
            ...body,
            categoryId: passportCategoryLookup.id,
          }),
          GetScopeCategoryDocuments({
            ...body,
            categoryId: KYCCategoryLookup.id,
          }),
        ]);

      const mappedIdDocuments = IdDocsResponse?.result?.map((item) => ({
        uuid: item.documentId,
        fileName: item.documentName,
      }));
      const mappedPassportDocuments = passportDocsResponse?.result?.map(
        (item) => ({
          uuid: item.documentId,
          fileName: item.documentName,
        })
      );
      const mappedKYCDocuments = KYCDocsResponse?.result?.map((item) => ({
        uuid: item.documentId,
        fileName: item.documentName,
      }));

      setSelected({
        id: 'documents',
        value: {
          IdDocuments: mappedIdDocuments || [],
          passportDocuments: mappedPassportDocuments || [],
          KYCDocuments: mappedKYCDocuments || [],
        },
      });
    } catch (error) {
      console.error('Error fetching documents:', error);
    } finally {
      setLoadings({ id: 'documents', value: false });
    }
  });

  const onSaveClickHandler = () => {
    setIsSubmitted(true);
    setLoadings({ id: 'global', value: true });
    if (schema?.error?.message) {
      showError(t('please-fill-all-required-field'));
      setLoadings({ id: 'global', value: false });
      return;
    } else if (documentsSchema?.error?.message) {
      showError(t('please-fill-all-required-field'));
      setLoadings({ id: 'global', value: false });
      return;
    }

    updateContactDetailsForOpportunityLeadStage();
    setLoadings({ id: 'global', value: false });
  };
  const onCloseClickedHandler = () => {
    onClose();
  };

  const updateContactDetailsForOpportunityLeadStage = async () => {
    let IdDocuments = [
      ...(selected.documents.IdDocuments.map((item) => ({
        fileId: item.uuid,
        fileName: item.fileName,
      })) || []),
    ];
    let passportDocuments = [
      ...(selected.documents.passportDocuments.map((item) => ({
        fileId: item.uuid,
        fileName: item.fileName,
      })) || []),
    ];
    let KYCDocuments = [
      ...(selected.documents.KYCDocuments.map((item) => ({
        fileId: item.uuid,
        fileName: item.fileName,
      })) || []),
    ];

    const body = {
      ...state,
      idCardNo: state.idCardNo || null,
      passportNo: state.passportNo || null,
      documents: {
        scopeId: contactId,
        scopeTypeId: ScopeDocumentEnum.Contact.scopeTypeId,
        categoryFiles: [
          {
            categoryId: IdCategoryLookup.id,
            files: IdDocuments,
          },
          {
            categoryId: passportCategoryLookup.id,
            files: passportDocuments,
          },
          {
            categoryId: KYCCategoryLookup.id,
            files: KYCDocuments,
          },
        ],
      },
      updateLeadStageActivityDetailsDto: activities
    };

    const res = await UpdateContactDetailsForLeadStage({ leadId, body });
    if (res) {
      onCloseClickedHandler();
      showSuccess(t(`contact-updated-successfully`));
      reload();
    } else {
      showError(t(`failed-to-update-contact`));
    }
  };

  const OCRReadHandler = async (fileId) => {
    setLoadings({ id: 'global', value: true });
    const result = await GetContactDetailsFromIdCardDocument({ fileId });
    const { fullName, nationalityId, idNo } = result;
    const index = fullName?.indexOf(' ');
    const firstName = fullName?.slice(0, index);
    const lastName = fullName?.slice(index + 1, fullName.length);
    setState({
      id: 'edit',
      value: {
        ...state,
        firstName,
        lastName,
        idCardNo: idNo,
        nationalityId,
      },
    });

    const newNationality = data.nationalities.filter(
      (item) => item.lookupItemId === nationalityId
    );
    setSelected({ id: 'nationality', value: newNationality[0] });
    setLoadings({ id: 'global', value: false });
  };

  useEffect(() => {
    UpdateStates();
  }, [contactData]);

  useEffect(() => {
    GetContact();
    getContactOldVisaDocuments();
  }, []);

  useEffect(() => {
    getAllNationalitiesAPICall();
    getVisaTypes();
  }, []);

  return (
    <div>
      <Spinner
        isActive={
          loadings.contact ||
          loadings.visaTypes ||
          loadings.nationalities ||
          loadings.documents ||
          loadings.global
        }
      />
      <div className='flex-column my-2'>
        <div className='my-2'>
          <AutocompleteComponent
            idRef='nationalityRef'
            labelValue='nationality'
            labelClasses='Requierd-Color'
            multiple={false}
            data={data?.nationalities || []}
            displayLabel={(option) => option.lookupItemName}
            renderOption={(option) => option.lookupItemName || ''}
            withoutSearchButton
            selectedValues={selected?.nationality}
            isLoading={loadings.nationalities}
            onChange={(event, newValue) => {
              setSelected({
                id: 'nationality',
                value: newValue,
              });
              setState({
                id: 'nationalityId',
                value: newValue?.lookupItemId || null,
              });
            }}
            isWithError
            isSubmitted={isSubmitted}
            helperText={getErrorByName(schema, 'nationalityId')?.message}
            error={getErrorByName(schema, 'nationalityId').error}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>

        <div>
          <fieldset className='fieldset-wrapper-for-dialog'>
            <legend className='autocomplete-legend-wrapper'>
              {t(`${translationPath}visa-details`)}
            </legend>
            <div className='d-flex gap-4'>
              <div className='w-33'>
                <Inputs
                  idRef='firstNameRef'
                  labelValue='first-name'
                  labelClasses='Requierd-Color'
                  value={state?.firstName}
                  onInputChanged={(event) => {
                    const { value } = event.target;
                    setState({
                      id: 'firstName',
                      value,
                    });
                  }}
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'firstName')?.message}
                  error={getErrorByName(schema, 'firstName').error}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              <div className='w-33 ml-2'>
                <Inputs
                  idRef='lastNameRef'
                  labelValue='last-name'
                  labelClasses='Requierd-Color'
                  value={state?.lastName || ''}
                  onInputChanged={(event) => {
                    const { value } = event.target;
                    setState({
                      id: 'lastName',
                      value,
                    });
                  }}
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'lastName')?.message}
                  error={getErrorByName(schema, 'lastName').error}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              <div className='w-50 ml-2'>
                <AutocompleteComponent
                  idRef='visaTypesRef'
                  labelValue='visa-type'
                  labelClasses='Requierd-Color'
                  multiple={false}
                  data={data?.visaTypes || []}
                  selectedValues={selected?.visaType}
                  isLoading={loadings?.visaTypes}
                  displayLabel={(option) => option.lookupItemName}
                  renderOption={(option) => option.lookupItemName || ''}
                  withoutSearchButton
                  onChange={(event, newValue) => {
                    setSelected({
                      id: 'visaType',
                      value: newValue,
                    });
                    setState({
                      id: 'visaTypeId',
                      value: newValue?.lookupItemId,
                    });
                  }}
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'visaTypeId')?.message}
                  error={getErrorByName(schema, 'visaTypeId').error}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              <div className='w-33 ml-2'>
                <Inputs
                  idRef='passportNumberRef'
                  labelValue='passport-number'
                  labelClasses='Requierd-Color'
                  value={state?.passportNo || ''}
                  onInputChanged={(event) => {
                    const { value } = event.target;
                    setState({
                      id: 'passportNo',
                      value: value,
                    });
                  }}
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'passportNo')?.message}
                  error={getErrorByName(schema, 'passportNo').error}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              {!['Visit', 'Non Residant'].includes(
                selected?.visaType?.lookupItemName
              ) && (
                <div className='w-33 ml-2'>
                  <Inputs
                    idRef='idCardNumberRef'
                    labelValue='idCard-number'
                    labelClasses='Requierd-Color'
                    value={state.idCardNo || ''}
                    onInputChanged={(event) => {
                      const { value } = event.target;
                      setState({
                        id: 'idCardNo',
                        value: value,
                      });
                    }}
                    isWithError
                    isSubmitted={isSubmitted}
                    helperText={getErrorByName(schema, 'idCardNo')?.message}
                    error={getErrorByName(schema, 'idCardNo').error}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                  />
                </div>
              )}
            </div>

            <div className='d-flex gap-4'>
              <div className='w-50 mr-2'>
                <Inputs
                  idRef='emailAddressRef'
                  labelValue='email-address'
                  labelClasses='Requierd-Color'
                  value={state?.emailAddress || ''}
                  onInputChanged={(event) => {
                    const { value } = event.target;
                    setState({
                      id: 'emailAddress',
                      value: value,
                    });
                  }}
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'emailAddress')?.message}
                  error={getErrorByName(schema, 'emailAddress').error}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
              <div className='w-50 ml-2'>
                <Inputs
                  idRef='mobileNumberRef'
                  labelValue='mobile-number'
                  labelClasses='Requierd-Color'
                  value={state?.mobileNumber}
                  onInputChanged={(event) => {
                    const { value } = event.target;
                    setState({
                      id: 'mobileNumber',
                      value: value,
                    });
                  }}
                  isWithError
                  isSubmitted={isSubmitted}
                  helperText={getErrorByName(schema, 'mobileNumber')?.message}
                  error={getErrorByName(schema, 'mobileNumber').error}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
            </div>
            {loadings.documents ? (
              <Spinner isActive />
            ) : (
              <div className='flex-column '>
              {isCardRequired &&  <div>
                  <MultipleTypesDocumentsUploader
                    isWithOCR
                    onOCRClick={OCRReadHandler}
                    initUploadedFiles={selected?.documents.IdDocuments ?? []}
                    labelValue='id-card-documents'
                    labelClasses='Requierd-Color'
                    uploadedChanged={(files) => {
                      setSelected({
                        id: 'documents',
                        value: {
                          ...selected.documents,
                          IdDocuments: files,
                        },
                      });
                    }}
                    setIsUploading={setIsUploading}
                    isUploading={isUploading}
                    multiple
                    isOpenGallery
                    idRef='importFileRef'
                    viewUploadedFilesCount={false}
                    openGallery
                    isDocuments
                    chipHandler={(value) => () => {
                      const link = document.createElement('a');
                      link.setAttribute('download', value.fileName);
                      link.href = getDownloadableLink(value.uuid);
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                    }}
                    isSubmitted={isSubmitted}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                  />
                </div>}

                <div className='my-2'>
                  <MultipleTypesDocumentsUploader
                    initUploadedFiles={
                      selected?.documents?.passportDocuments || []
                    }
                    labelValue='passport-documents'
                    labelClasses='Requierd-Color'
                    uploadedChanged={(files) => {
                      setSelected({
                        id: 'documents',
                        value: {
                          ...selected.documents,
                          passportDocuments: files,
                        },
                      });
                    }}
                    setIsUploading={setIsUploading}
                    isUploading={isUploading}
                    multiple
                    isOpenGallery
                    idRef='importFileRef'
                    viewUploadedFilesCount={false}
                    openGallery
                    isDocuments
                    chipHandler={(value) => () => {
                      const link = document.createElement('a');
                      link.setAttribute('download', value.fileName);
                      link.href = getDownloadableLink(value.uuid);
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                    }}
                    isSubmitted={isSubmitted}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                  />
                </div>
                <div className='my-2'>
                  <MultipleTypesDocumentsUploader
                    initUploadedFiles={selected?.documents?.KYCDocuments || []}
                    labelValue='KYC-documents'
                    uploadedChanged={(files) => {
                      setSelected({
                        id: 'documents',
                        value: {
                          ...selected.documents,
                          KYCDocuments: files,
                        },
                      });
                    }}
                    setIsUploading={setIsUploading}
                    isUploading={isUploading}
                    multiple
                    isOpenGallery
                    idRef='importFileRef'
                    viewUploadedFilesCount={false}
                    openGallery
                    isDocuments
                    chipHandler={(value) => () => {
                      const link = document.createElement('a');
                      link.setAttribute('download', value.fileName);
                      link.href = getDownloadableLink(value.uuid);
                      document.body.appendChild(link);
                      link.click();
                      link.remove();
                    }}
                    isSubmitted={isSubmitted}
                    translationPath={translationPath}
                    parentTranslationPath={parentTranslationPath}
                  />
                </div>
              </div>
            )}
          </fieldset>
        </div>
      </div>
      <>
        <DialogActions>
          <ButtonBase
            className='MuiButtonBase-root btns bg-cancel'
            onClick={() => onCloseClickedHandler()}
          >
            {t(`${translationPath}Shared:cancel`)}
          </ButtonBase>
          <ButtonBase
            className='MuiButtonBase-root btns theme-solid '
            onClick={() => onSaveClickHandler()}
          >
            {t(`${translationPath}Shared:save`)}
          </ButtonBase>
        </DialogActions>
      </>
    </div>
  );
}

export default OpportunityLeadStageDialog;
