import React from "react";

function Download02({ fill, ...restProps }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.757 1.713a.86.86 0 0 0-.542.544c-.037.104-.046.998-.047 5.009l-.001 4.883-1.825-1.821C6.098 9.087 5.469 8.482 5.367 8.43a.832.832 0 0 0-.946.158.832.832 0 0 0-.158.945c.108.211 5.159 5.263 5.37 5.371a.88.88 0 0 0 .734 0c.211-.108 5.262-5.16 5.37-5.371a.832.832 0 0 0-.158-.945.832.832 0 0 0-.946-.158c-.102.052-.731.657-1.974 1.897l-1.825 1.821-.009-4.932-.008-4.933-.072-.133c-.199-.373-.639-.567-.988-.437m-7.5 15.001c-.492.177-.72.703-.494 1.145.081.16.217.295.387.386l.133.072h15.434l.133-.072c.307-.164.464-.417.464-.745s-.157-.581-.464-.745l-.133-.072-7.667-.007c-6.376-.006-7.688.001-7.793.038"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default Download02;
