import React, { useCallback, useReducer, useEffect, useRef } from 'react';
import '../GroupsView.scss';
import xCloseIcon from '../../../../../assets/images/icons/x-close-dialog.svg';
import { DialogActions, DialogContent, ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getErrorByName, showError, showSuccess } from '../../../../../Helper';
import {
  Inputs,
  DialogComponent,
  AutocompleteComponent,
} from '../../../../../Components';
import {
  GetAllRoles,
  getAllPoliciesServices,
  CreateUsersGroup,
  UpdateUsersGroup,
} from '../../../../../Services';
import Joi from 'joi';
import { all } from 'core-js/fn/promise';

export const GroupManagementDialog = ({
  isDialogOpen,
  onClose,
  onSave,
  activeItem,
  parentTranslationPath,
  translationPath,
  isEdit,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [selected, setSelected] = useReducer(reducer, {
    name: null,
    policy: null,
    roles: [],
  });
  const [data, setData] = useReducer(reducer, {
    systemPolicies: [],
    systemRoles: [],
  });

  const getSystemPolicies = async (searchedItem) => {
    const res = await getAllPoliciesServices({
      pageIndex: 1,
      pageSize: 10,
      search: searchedItem || null,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'systemPolicies', value: res?.result || [] });
    } else setData({ id: 'systemPolicies', value: [] });
  };

  const getSystemRoles = async (searchedItem) => {
    const pageIndex = 1;
    const pageSize = 10;

    const res = await GetAllRoles(pageIndex, pageSize, searchedItem);
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'systemRoles', value: res?.result || [] });
    } else setData({ id: 'systemRoles', value: [] });
  };

  const schema = Joi.object({
    name: Joi.string()
      .required()
      .messages({
        'any.required': t(`is-required`, {
          field: t(`${translationPath}group-name`),
          ns: 'InquiryRotation',
        }),
        'string.empty': t(`is-required`, {
          field: t(`${translationPath}group-name`),
          ns: 'InquiryRotation',
        }),
        'string.base': t(`is-required`, {
          field: t(`${translationPath}group-name`),
          ns: 'InquiryRotation',
        }),
      }),
    policy: Joi.object().allow(null),
    roles: Joi.array()
      .min(1)
      .required()
      .messages({
        'any.required': t(`is-required`, {
          field: t(`${translationPath}roles`),
          ns: 'InquiryRotation',
        }),
        'array.min': t(`is-required`, {
          field: t(`${translationPath}roles`),
          ns: 'InquiryRotation',
        }),
      }),
  })
    .options({ abortEarly: false, allowUnknown: true })
    .validate(selected);

  const createUsersGroup = async () => {
    if (schema.error) {
      return showError(
        t(`Branches.please-fill-required-fields`, { ns: 'BranchView' })
      );
    }
    const body = convertSelectedToBody();

    const res = await CreateUsersGroup(body);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}group-created-successfully`));
      onSave();
    } else {
      showError(t(`${translationPath}group-create-failed`));
    }
  };

  const updateUsersGroup = async () => {
    if (schema.error) {
      return showError(
        t(`Branches.please-fill-required-fields`, { ns: 'BranchView' })
      );
    }
    const userGroupId = activeItem.userGroupId;
    const body = convertSelectedToBody();

    const res = await UpdateUsersGroup(body, userGroupId);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}group-updated-successfully`));
      onSave();
    } else {
      showError(t(`${translationPath}group-update-failed`));
    }
  };

  const convertStateToSelected = () => {
    const localSelected = {
      name: activeItem.name || '',
      policy: {
        policyDataId: activeItem.policyId,
        policyDataName: activeItem.policyName,
      },
      roles: activeItem.userGroupRoles,
    };

    setSelected({ id: 'edit', value: localSelected });
  };

  const convertSelectedToBody = () => {
    const userGroupRoles = selected.roles
      ? selected.roles.map((item) => ({
          rolesId: item.rolesId,
        }))
      : [];

    const body = {
      name: selected.name,
      policyId: selected.policy?.policyDataId || null,
      userGroupRoles,
    };

    return body;
  };

  const saveHandler = () => {
    if (activeItem) updateUsersGroup();
    else createUsersGroup();
  };

  useEffect(() => {
    if (activeItem) convertStateToSelected();
  }, [activeItem]);

  useEffect(() => {
    if (activeItem && !isEdit) {
      setSelected({
        id: 'edit',
        value: {
          name: null,
          policy: null,
          roles: [],
        },
      });
    }
  }, []);

  return (
    <div>
      <DialogComponent
        isOpen={isDialogOpen}
        maxWidth='sm'
        dialogTitle={
          <div className='user-groups-view add-task-dialog pb-3 w-100'>
            <div className='d-flex-v-center-h-between'>
              {activeItem ? (
                <div className='fw-simi-bold'>
                  {t(`${translationPath}update-group`)}
                </div>
              ) : (
                <div className='fw-simi-bold'>
                  {t(`${translationPath}create-group`)}
                </div>
              )}
              <div className='xCloseIcon pointer' onClick={onClose}>
                <img src={xCloseIcon} />
              </div>
            </div>
          </div>
        }
        dialogContent={
          <div className='user-groups-view '>
            <DialogContent>
              <>
                <div className='d-flex fj-start b-bottom'>
                  <div className='w-33 mt-2 pt-1'>
                    <span className='fw-simi-bold'>
                      {t(`${translationPath}group-name`)}
                    </span>
                  </div>
                  <div className='w-75 mt-2 mb-1 pt-1'>
                    <Inputs
                      idRef='nameRef'
                      value={selected.name}
                      inputPlaceholder='enter'
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      onInputChanged={(event) => {
                        setSelected({
                          id: 'name',
                          value: event?.target?.value,
                        });
                      }}
                      isWithError
                      error={getErrorByName(schema, 'name').error}
                      helperText={getErrorByName(schema, 'name').message}
                    />
                  </div>
                </div>

                <div className='d-flex fj-start b-bottom'>
                  <div className='w-33 mt-2 pt-1'>
                    <span className='fw-simi-bold'>
                      {t(`${translationPath}roles`)}
                    </span>
                  </div>
                  <div className='w-75 mt-2 mb-1 pt-1'>
                    <AutocompleteComponent
                      idRef='rolesRef'
                      inputPlaceholder={t(`${translationPath}select`)}
                      selectedValues={selected.roles || []}
                      wrapperClasses='w-100 mb-2'
                      data={data.systemRoles || []}
                      chipsLabel={(option) => option.rolesName || ''}
                      displayLabel={(option) => t(`${option.rolesName || ''}`)}
                      withoutSearchButton
                      multiple
                      getOptionSelected={(option) =>
                        selected?.roles?.findIndex(
                          (item) => item.rolesId === option.rolesId
                        ) !== -1 || ''
                      }
                      onChange={(event, newValue) => {
                        setSelected({ id: 'roles', value: newValue });
                      }}
                      onOpen={() => {
                        if (data.systemRoles && data.systemRoles.length == 0)
                          getSystemRoles();
                      }}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer) clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                          getSystemRoles(value);
                        }, 1200);
                      }}
                      onKeyDown={() => {
                        setSelected({ id: 'roles', value: null });
                      }}
                      isWithError
                      error={getErrorByName(schema, 'roles').error}
                      helperText={getErrorByName(schema, 'roles').message}
                    />
                  </div>
                </div>

                <div className='d-flex fj-start b-bottom'>
                  <div className='w-33 mt-2 pt-1'>
                    <span className='fw-simi-bold'>
                      {t(`${translationPath}policy`)}
                    </span>
                  </div>
                  <div className='w-75 mt-2 mb-1 pt-1'>
                    <AutocompleteComponent
                      idRef='policysRef'
                      inputPlaceholder={t(`${translationPath}select`)}
                      selectedValues={selected.policy}
                      wrapperClasses='w-100 mb-2'
                      data={data.systemPolicies || []}
                      displayLabel={(option) => option?.policyDataName || ''}
                      withoutSearchButton
                      multiple={false}
                      onChange={(event, newValue) => {
                        setSelected({ id: 'policy', value: newValue });
                      }}
                      onOpen={() => {
                        if (
                          data.systemPolicies &&
                          data.systemPolicies.length == 0
                        )
                          getSystemPolicies();
                      }}
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer) clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                          getSystemPolicies(value);
                        }, 1200);
                      }}
                      onKeyDown={() => {
                        setSelected({ id: 'policy', value: null });
                      }}
                    />
                  </div>
                </div>
              </>
            </DialogContent>
            <DialogActions>
              <div className='d-flex-center fj-end py-0 pt-3'>
                <ButtonBase
                  onClick={onClose}
                  className='btns theme-propx outlined'
                >
                  {t(`${translationPath}cancel`)}
                </ButtonBase>

                <ButtonBase
                  className='btns theme-propx solid mr-0'
                  onClick={saveHandler}
                >
                  {activeItem
                    ? t(`${translationPath}update`)
                    : t(`${translationPath}create`)}
                </ButtonBase>
              </div>
            </DialogActions>
          </div>
        }
      />
    </div>
  );
};
