import { config } from "../../config";
import { HttpServices } from "../../Helper";

import { showError } from "../../Helper";

const server_address = "https://ai.psi-crm.com/api";

export const AI_UnitMarketing = async (UnitID) => {
  try {
    const result = await HttpServices.get(
      `${server_address}/UnitMarketing/${UnitID}?environment=${config.AI_environment}`
    );
    return result;
  } catch (error) {
    showError('Services have Error')
    return error.response;
  }
};
