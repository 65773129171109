import React from "react";

function ChevronSelectorVerticalIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M9.844 3.036a1.062 1.062 0 0 0-.233.076c-.052.028-1.086 1.045-2.299 2.261-2.382 2.388-2.289 2.28-2.286 2.646.004.44.349.784.791.788.36.003.285.067 2.308-1.952L10 4.984l1.875 1.871c2.025 2.021 1.948 1.955 2.311 1.952a.807.807 0 0 0 .732-.489.938.938 0 0 0-.011-.656c-.098-.214-4.441-4.54-4.607-4.589-.191-.055-.309-.065-.456-.037m-4.166 9.167a.8.8 0 0 0-.596.479.938.938 0 0 0 .011.656c.05.109.623.7 2.278 2.35 2.373 2.365 2.283 2.286 2.629 2.286.346 0 .256.079 2.629-2.286 1.655-1.65 2.228-2.241 2.278-2.35a.852.852 0 0 0 .066-.338.786.786 0 0 0-.79-.807c-.36-.003-.285-.067-2.308 1.952L10 16.016l-1.875-1.871c-1.45-1.447-1.901-1.879-1.992-1.905-.19-.055-.308-.065-.455-.037"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ChevronSelectorVerticalIcon;
