import React, { useEffect, useRef, useState } from "react";
import { Paper, Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import { uploadFile, importFile } from "../../../Services";
import { CustomButton } from "../CustomButton";
import { DropzoneComponent } from "../DropzoneComponent";
import {
  useSelectedTheme,
  useOutsideClick,
  useTranslate,
} from "../../../Hooks";
import { CloseXIcon, GetHelpIcon } from "../../../assets/icons";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";

// Styles
import useStyles from "./styles";

const ImportContactModal = ({ onClose }) => {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { isDarkMode } = useSelector((state) => state.theme);
  const { translate } = useTranslate("NewContactsView");
  const { translate: sharedTranslate } = useTranslate("SharedV2");
  const { setAlertBoxContent } = useVerticalNav();

  const modalRef = useRef(null);
  const iconRef = useRef(null);
  const [activeCard, setActiveCard] = useState("individual");
  const [uploadedFileId, setUploadedFileId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleFilesAccepted = (files) => {
    setUploadedFiles(files);
  };

  useOutsideClick({
    ref: modalRef,
    handler: () => onClose,
    iconRef,
  });

  const handleCardClick = (cardType) => {
    setActiveCard(cardType);
  };

  const onSubmit = async () => {
    if (uploadedFileId) {
      const response = await importFile({
        fileId: uploadedFileId,
        importProcceseType: activeCard === "individual" ? 1 : 6,
      });

      onClose();
      if (response) {
        setAlertBoxContent({
          display: true,
          variant: "success",
          title: `Contact file sent successfully.`,
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      } else {
        setAlertBoxContent({
          display: true,
          variant: "error",
          title: "Contact file sending failed",
          description: "Failed to import contact file. Please try again.",
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      }
    }
  };

  return (
    <Box className={styles.modalContainer}>
      <Paper ref={modalRef} className={styles.modalPaper}>
        <Box className={styles.content}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <span className={styles.text}>
              {sharedTranslate("import-contacts")}
            </span>

            <CloseXIcon
              onClick={onClose}
              style={{ cursor: "pointer" }}
              width="20"
              height="20"
              fill={palette.foreground.quinary}
            />
          </Box>
        </Box>
        <Box className={styles.contactTypeContainer}>
          <Box className={styles.contactType}>
            <span>{sharedTranslate("contacts-type")}</span>

            <Box
              className={`${styles.card} ${
                activeCard === "individual" ? styles.activeCard : ""
              }`}
              onClick={() => handleCardClick("individual")}
            >
              <img
                src={`/images/contacts/individualType${
                  isDarkMode ? "Dark" : "Light"
                }Icon.svg`}
                alt="IndividualTypeIcon"
              />

              <span>{translate("individualText")}</span>
            </Box>

            <Box
              className={`${styles.card} ${
                activeCard === "corporate" ? styles.activeCard : ""
              }`}
              onClick={() => handleCardClick("corporate")}
            >
              <img
                src={`/images/contacts/corporateType${
                  isDarkMode ? "Dark" : "Light"
                }Icon.svg`}
                alt="CorporateTypeIcon"
              />

              <span> {translate("corporateText")}</span>
            </Box>
          </Box>

          <Box className={styles.uploadSection}>
            <Box className={styles.uploadInstructions}>
              <Box className={styles.header}>
                <span> {sharedTranslate("upload-file")}</span>
                <GetHelpIcon
                  width="16"
                  height="16"
                  fill={palette.foreground.quinary}
                  style={{ marginLeft: "2px" }}
                />
              </Box>
              <p>
                {sharedTranslate(
                  "heres-an-example-from-the-excel-file-that-you-can"
                )}{" "}
                <a
                  className={styles.downloadLink}
                  href={
                    activeCard === "individual"
                      ? "/files/IndividualContact.xlsx"
                      : activeCard === "corporate"
                      ? "/files/CompanyContact.xlsx"
                      : "#"
                  }
                  download={
                    activeCard === "individual"
                      ? "Individual Contact"
                      : activeCard === "corporate"
                      ? "Company Contact"
                      : undefined
                  }
                  style={{
                    pointerEvents: activeCard ? "auto" : "none",
                    color: activeCard
                      ? palette.button.tertiary_fg
                      : palette.text.disabled,
                  }}
                >
                  {sharedTranslate("download")}
                </a>
              </p>
              <p>
                {" "}
                {sharedTranslate("add-data-to-it-and-then-upload-it-again")}
              </p>
            </Box>

            <DropzoneComponent
              setUploadedFileId={setUploadedFileId}
              accept=".xls,.xlsx,.xlsm"
              title={sharedTranslate(
                "you-can-upload-an-excel-file-to-load-a-set-of-leads"
              )}
              onFilesAccepted={handleFilesAccepted}
            />
          </Box>
        </Box>
        <Box className={styles.actions}>
          <CustomButton
            boxShadow="xs"
            size="md"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </CustomButton>
          <CustomButton
            boxShadow="xs"
            size="md"
            onClick={onSubmit}
            variant="contained"
            color="primary"
            disabled={!activeCard || uploadedFiles?.length === 0}
          >
            Submit
          </CustomButton>
        </Box>
      </Paper>
    </Box>
  );
};

export default ImportContactModal;
