import React, { useReducer } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import xCloseIcon from '../../../../../assets/images/icons/x-close-dialog.svg';
import btnLoader from '../../../../../assets/images/GIF_images/btn-loader.gif';
import {
  DialogComponent,
  DatePickerComponent,
} from '../../../../../Components';
import { DialogActions, DialogContent, ButtonBase } from '@material-ui/core';
import { showError } from '../../../../../Helper';
import i18next from 'i18next';
export const ContractDetailsDialog = ({
  onClose,
  isLoading,
  onSubmit,
  isOpen,
  parentTranslationPath,
}) => {
  const translationPath = 'Utilities';
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });

  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };
  const [selected, setSelected] = useReducer(reducer, {
    leasingStartDate: null,
    leasingEndDate: null,
  });

  const calculateLeasingPeriod = () => {
    const startDate = moment(selected.leasingStartDate);
    const endDate = moment(selected.leasingEndDate);
    const duration = moment.duration(endDate.diff(startDate));
    const durationAsMonths = duration?.asMonths();
    const roundedDuration = durationAsMonths ? Math.ceil(durationAsMonths) : 0;
    return `${roundedDuration} Months`;
  };

  const saveHandler = () => {
    const startDate = moment(selected.leasingStartDate);
    const endDate = moment(selected.leasingEndDate);
    const duration = endDate.diff(startDate);
    if (duration < 0) {
      showError(t('duration_is_invalid'));
      return;
    }
    if (!selected.leasingStartDate || !selected.leasingEndDate) {
      showError(i18next.t('please-fix-all-errors', { ns: 'Shared' }));
      return;
    }

    if (!isLoading) onSubmit(selected);
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      maxWidth='sm'
      dialogTitle={
        <div className='users-manage-view add-task-dialog pb-3 w-100'>
          <div className='d-flex-v-center-h-between'>
            <div className='fw-simi-bold'>{t(`Contract-details`)}</div>
            <div className='xCloseIcon pointer' onClick={onClose}>
              <img src={xCloseIcon} />
            </div>
          </div>
        </div>
      }
      dialogContent={
        <div className='users-manage-view'>
          <DialogContent>
            <>
              <div className='d-flex fj-start b-bottom'>
                <div className='w-33 mt-2 pt-1'>
                  <span className='fw-simi-bold'>{t(`Start-date`)}</span>
                </div>
                <div className='w-50 mt-2 mb-3 pt-1'>
                  <DatePickerComponent
                    idRef='leasingStartDateRef'
                    labelClasses='Requierd-Color'
                    placeholder='DD/MM/YYYY'
                    value={selected.leasingStartDate || ''}
                    onDateChanged={(newValue) => {
                      setSelected({
                        id: 'leasingStartDate',
                        value: (newValue && moment(newValue).format()) || null,
                      });
                    }}
                  />
                </div>
              </div>
              <div className='d-flex fj-start b-bottom'>
                <div className='w-33 mt-2 pt-1'>
                  <span className='fw-simi-bold'>{t(`End-date`)}</span>
                </div>
                <div className='w-50 mt-2 mb-3 pt-1'>
                  <DatePickerComponent
                    idRef='leasingEndDateRef'
                    labelClasses='Requierd-Color'
                    placeholder='DD/MM/YYYY'
                    isDisabled={selected.leasingStartDate === null}
                    value={selected.leasingEndDate}
                    onDateChanged={(newValue) => {
                      setSelected({
                        id: 'leasingEndDate',
                        value: (newValue && moment(newValue).format()) || null,
                      });
                    }}
                  />
                  {selected.leasingStartDate && selected.leasingEndDate ? (
                    <p className='mt-2'>
                      <span>Duration period:</span>
                      <span className='fw-simi-bold mx-2'>
                        {calculateLeasingPeriod()}
                      </span>
                    </p>
                  ) : null}
                </div>
              </div>
            </>
          </DialogContent>
          <DialogActions>
            <div className='d-flex-center fj-end py-0 pt-3'>
              <ButtonBase
                onClick={onClose}
                className='btns theme-propx outlined'
              >
                {t(`cancel`)}
              </ButtonBase>
              <ButtonBase
                className='btns theme-propx solid mr-0'
                onClick={saveHandler}
              >
                {t(`Send`)}
                {isLoading ? (
                  <img
                    src={btnLoader}
                    className='ml-1'
                    width='12'
                    height='12'
                  />
                ) : (
                  false
                )}
              </ButtonBase>
            </div>
          </DialogActions>
        </div>
      }
    />
  );
};

export default ContractDetailsDialog;
