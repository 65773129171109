import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  AutocompleteComponent,
  PhonesComponent,
  Inputs,
  Spinner,
} from "../../../../Components";
import {
  GetLookupItemsByLookupTypeName,
  lookupItemsGetId,
} from "../../../../Services";
import { getErrorByName } from "../../../../Helper";
import { DuplicateDeveloperLandlineNumberRole } from "../../../../Rule";

export const AddNewDeveloper = ({
  parentTranslationPath,
  translationPath,
  dynamicComponentProps,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const itemRegex = new RegExp("^(?:[a-zA-Z\\s]+|[ء-ي\\s]+|\\*{8})$");

  const [allData, setAllData] = useState({
    language: [],
    country: [],
    city: [],
  });

  const onNewDeveloperChangeHandler = (
    key,
    values,
    selectedObj,
    selectedValues
  ) => {
    if (!selectedObj) {
      dynamicComponentProps.setNewDeveloperWithContactState({
        id: [key],
        value: values,
      });
    } else {
      dynamicComponentProps.setNewDeveloperWithContactState({
        id: [key],
        value: values,
      });
      dynamicComponentProps.setSelectedNewDeveloperWithContact({
        id: [selectedObj],
        value: selectedValues,
      });
    }
  };

  const getLookupItemsByName = async (lookUpName, key) => {
    dynamicComponentProps.setIsLoading((item) => ({ ...item, [key]: true }));
    const result = await GetLookupItemsByLookupTypeName({
      lookUpName,
      pageIndex: 1,
      pageSize: 300,
    });
    if (!(result && result.status && result.status !== 200)) {
      setAllData((item) => ({
        ...item,
        [key]: (result && result.result) || [],
      }));
    } else setAllData((item) => ({ ...item, [key]: [] }));
    dynamicComponentProps.setIsLoading((loading) => ({
      ...loading,
      [key]: false,
    }));
  };

  const lookupItemsGetIdAPI = async (key, lookupParentId, lookupTypeId) => {
    dynamicComponentProps.setIsLoading((item) => ({ ...item, [key]: true }));
    const result = await lookupItemsGetId({
      lookupTypeId: lookupTypeId,
      lookupParentId: lookupParentId,
    });
    if (!(result && result.status && result.status !== 200)) {
      setAllData((item) => ({ ...item, [key]: result || [] }));
    } else setAllData((item) => ({ ...item, [key]: [] }));
    dynamicComponentProps.setIsLoading((item) => ({ ...item, [key]: false }));
  };

  return (
    <>
      <div className="w-75 d-flex-column-center">
        <Spinner
          isActive={
            dynamicComponentProps?.isLoading?.checkDuplicationLandlineNumber
          }
          isAbsolute
        />
        <div className="w-100 mt-2 mb-2">
          <Inputs
            isAttachedInput
            idRef={`developerNameRef`}
            labelClasses="Requierd-Color"
            labelValue={"new-developer-name"}
            value={
              dynamicComponentProps?.selectedNewDeveloperWithContact
                .developerName || ""
            }
            inputPlaceholder={"enter-developer-name"}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            isWithError
            isSubmitted={dynamicComponentProps?.isSubmitted}
            helperText={
              (dynamicComponentProps?.helperText?.developerName &&
                t(dynamicComponentProps?.helperText?.developerName)) ||
              getErrorByName(dynamicComponentProps?.schema, "developerName")
                .message
            }
            error={
              dynamicComponentProps?.helperText?.developerName !== "" ||
              getErrorByName(dynamicComponentProps?.schema, "developerName")
                .error
            }
            onInputChanged={(event) => {
              if (!itemRegex.test(event.target.value))
                dynamicComponentProps.setHelperText((item) => ({
                  ...item,
                  developerName: t("please-enter-vaild-name"),
                }));
              else
                dynamicComponentProps.setHelperText((item) => ({
                  ...item,
                  developerName: "",
                }));

              const { value } = event.target;
              onNewDeveloperChangeHandler(
                "developerName",
                value,
                "developerName",
                value
              );
            }}
          />
        </div>
        <div className="w-100 mt-2 mb-2">
          <PhonesComponent
            labelClasses="Requierd-Color"
            labelValue={"landLine-number"}
            idRef={`landlineRef`}
            value={
              dynamicComponentProps?.selectedNewDeveloperWithContact
                ?.landlineNumber || ""
            }
            helperText={
              (dynamicComponentProps?.helperText?.landlineNumber !== "" &&
                t(dynamicComponentProps?.helperText?.landlineNumber)) ||
              getErrorByName(dynamicComponentProps?.schema, "landlineNumber")
                .message ||
              ""
            }
            error={
              dynamicComponentProps?.helperText?.landlineNumber !== "" ||
              getErrorByName(dynamicComponentProps?.schema, "landlineNumber")
                .error
            }
            inputPlaceholder={"enter-landlines-number"}
            onInputChanged={(value) => {
              let newValue = value;
              newValue = newValue.replaceAll(" ", "");
              dynamicComponentProps.setHelperText((item) => ({
                ...item,
                landlineNumber: "",
              }));

              if (
                (newValue !== "" && newValue.length < 7) ||
                (newValue !== "" && newValue.length > 14)
              ) {
                dynamicComponentProps.setHelperText((item) => ({
                  ...item,
                  landlineNumber: t("please-enter-vaild-landline-number"),
                }));
                onNewDeveloperChangeHandler(
                  "landlineNumber",
                  newValue,
                  "landlineNumber",
                  newValue
                );
              } else {
                dynamicComponentProps.setHelperText((item) => ({
                  ...item,
                  landlineNumber: "",
                }));
                onNewDeveloperChangeHandler(
                  "landlineNumber",
                  newValue.replaceAll(" ", ""),
                  "landlineNumber",
                  newValue.replaceAll(" ", "")
                );
              }
            }}
            onKeyUp={(e) => {
              let { value } = e.target;
              if (value) value = value.replaceAll(" ", "");
              if (searchTimer.current) clearTimeout(searchTimer.current);
              searchTimer.current = setTimeout(async () => {
                if (dynamicComponentProps?.helperText?.landlineNumber !== "")
                  return;
                dynamicComponentProps.setIsLoading((loading) => ({
                  ...loading,
                  checkDuplicationLandlineNumber: true,
                }));
                const isDuplicate = await DuplicateDeveloperLandlineNumberRole(
                  value
                );
                if (!isDuplicate)
                  dynamicComponentProps.setHelperText((item) => ({
                    ...item,
                    landlineNumber: t(
                      "please-enter-not-duplicate-phone-number"
                    ),
                  }));
                dynamicComponentProps.setIsLoading((loading) => ({
                  ...loading,
                  checkDuplicationLandlineNumber: false,
                }));
              }, 700);
            }}
            onKeyDown={() => {
              if (searchTimer) clearTimeout(searchTimer.current);
            }}
            isSubmitted={dynamicComponentProps?.isSubmitted}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>

        <div className="w-100 mt-2 mb-2">
          <AutocompleteComponent
            labelClasses="Requierd-Color"
            selectedValues={
              (dynamicComponentProps?.selectedNewDeveloperWithContact &&
                dynamicComponentProps?.selectedNewDeveloperWithContact
                  ?.language) ||
              null
            }
            idRef={`developerContactIdRef`}
            multiple={false}
            inputPlaceholder={t("select-developer-language")}
            data={allData && allData.language}
            inputClasses="inputs theme-form-builder"
            displayLabel={(option) => (option && option.lookupItemName) || ""}
            onChange={(e, newValue) => {
              onNewDeveloperChangeHandler(
                "languageId",
                (newValue && newValue.lookupItemId) || null,
                "language",
                newValue
              );
            }}
            isLoading={dynamicComponentProps?.isLoading.language}
            withLoader
            withoutSearchButton
            labelValue={"language"}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            isWithError
            isSubmitted={dynamicComponentProps?.isSubmitted}
            helperText={
              getErrorByName(dynamicComponentProps?.schema, "languageId")
                .message
            }
            error={
              getErrorByName(dynamicComponentProps?.schema, "languageId").error
            }
            onOpen={() => {
              if (allData && allData?.language.length === 0)
                getLookupItemsByName("Language", "language");
            }}
          />
        </div>
        <div className="w-100 mt-2 mb-2">
          <AutocompleteComponent
            labelClasses="Requierd-Color"
            selectedValues={
              (dynamicComponentProps?.selectedNewDeveloperWithContact &&
                dynamicComponentProps?.selectedNewDeveloperWithContact
                  .country) ||
              null
            }
            idRef={`developerCountryRef`}
            multiple={false}
            inputPlaceholder={t("select-developer-country")}
            data={allData.country}
            inputClasses="inputs theme-form-builder"
            displayLabel={(option) => (option && option.lookupItemName) || ""}
            onChange={async (e, newValue) => {
              if (newValue)
                await lookupItemsGetIdAPI("city", newValue?.lookupItemId, 17);
              onNewDeveloperChangeHandler(
                "countryId",
                newValue?.lookupItemId || null,
                "country",
                newValue
              );
              onNewDeveloperChangeHandler("cityId", null, "city", null);
            }}
            isLoading={dynamicComponentProps?.isLoading.country}
            withLoader
            withoutSearchButton
            labelValue={"country"}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            isWithError
            isSubmitted={dynamicComponentProps?.isSubmitted}
            helperText={
              getErrorByName(dynamicComponentProps?.schema, "countryId").message
            }
            error={
              getErrorByName(dynamicComponentProps?.schema, "countryId").error
            }
            onOpen={async () => {
              if (allData && allData?.country.length === 0)
                await getLookupItemsByName("Country", "country");
            }}
          />
        </div>
        <div className="w-100 mt-2 mb-2">
          <AutocompleteComponent
            labelClasses="Requierd-Color"
            selectedValues={
              (dynamicComponentProps?.selectedNewDeveloperWithContact &&
                dynamicComponentProps?.selectedNewDeveloperWithContact.city) ||
              null
            }
            idRef={`developerContactCityRef`}
            multiple={false}
            inputPlaceholder={t("select-developer-city")}
            data={allData.city}
            inputClasses="inputs theme-form-builder"
            displayLabel={(option) => (option && option.lookupItemName) || ""}
            onChange={(e, newValue) => {
              onNewDeveloperChangeHandler(
                "cityId",
                newValue?.lookupItemId || null,
                "city",
                newValue
              );
            }}
            isLoading={dynamicComponentProps?.isLoading.city}
            withLoader
            withoutSearchButton
            labelValue={"city"}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            isWithError
            isSubmitted={dynamicComponentProps?.isSubmitted}
            helperText={
              getErrorByName(dynamicComponentProps?.schema, "cityId").message
            }
            error={
              getErrorByName(dynamicComponentProps?.schema, "cityId").error
            }
          />
        </div>
      </div>
    </>
  );
};
