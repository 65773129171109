import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  merge_cards_skeleton_wrapper: {
    padding: theme.spacing(2),
    position: "relatvive",
  },
  merge_cards_skeleton_background: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: "12px",
    height: "auto",
    maxHeight: "620px",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  cardHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(1),
    position: "relative",
    "& .skeleton-wrapper": {
      position: "absolute",
      top: "-65px",
      left: "50%",
      transform: "translateX(-50%)",
      width: "70px",
      height: "70px",
    },
  },
  cardColumns: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  merge_skeleton_arrows_styles: {
    display: "flex",
    position: "fixed",
    bottom: "10px",
    right: "20px",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "end",
    gap: "24px",
  },
}));
