import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    customInputTime: {
      border: `1px solid ${theme.palette.foreground.senary}`,
      padding: "10px 0px 10px 14px",
      borderRadius: "8px",
      width: "100%",
    },
    customInputDate: {
      border: `1px solid ${theme.palette.foreground.senary}`,
      padding: "10px 0px 10px 14px",
      borderRadius: "8px",
      width: "100%",
      "& .MuiInputAdornment-positionEnd": {
        display: "none",
      },
      "& .MuiInputBase-input": {
        padding: "0 !important",
        lineHeight: "24px",
        height: "24px",
        "&::placeholder": {
          opacity: "1 !important",
          lineHeight: "24px",
          fontSize: "16px",
          fontWeight: 600,
          color: theme.palette.text.placeholder,
        },
      },
    },
    containerPaper: {
      position: "relative",
    },
    containerIcon: {
      position: "absolute",
      top: 13,
      right: 12,
    },
    pickerToolbar: {
      "& .MuiPickersToolbar-toolbar": {
        background: "transparent !important",
        justifyContent: "start",
        height: "auto",
      },
      "& .MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding": {
        padding: "0px",
      },

      "& .MuiPickersTimePickerToolbar-hourMinuteLabel": {
        "& button": {
          color: theme.palette.text.tertiary,
          border: `1px solid ${theme.palette.border.primary}`,
          padding: " 5px 7px",
          borderRadius: "8px",
          fontSize: "48px",
          fontWeight: "700",
          lineHeight: "60px",
        },
      },
      "& .MuiPickersToolbarText-toolbarTxt": {
        color: theme.palette.text.tertiary,
        padding: " 5px 7px",
        borderRadius: "8px",
        fontSize: "48px",
        fontWeight: "700",
        lineHeight: "60px",
      },
      "& .MuiPickersTimePickerToolbar-separator": {
        border: "none",
        padding: "20px 20px",
        margin: "0px",
      },
      "& .MuiPickersTimePickerToolbar-ampmSelection": {
        backgroundColor: theme.palette.background.hover,
        border: `1px solid ${theme.palette.border.secondary}`,
        borderRadius: "12px",
        padding: "6px",
        gap: "4px",
        position: "absolute",
        bottom: "0px",
        right: "20px",
        marginLeft: "0px",
      },
      "& .MuiPickersTimePickerToolbar-ampmLabel": {
        borderRadius: "8px",
        padding: "4px 6px",
        color: theme.palette.text.text_quarterary,
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
      },
      "& .MuiPickersToolbarText-toolbarBtnSelected": {
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.background.paper}`,
        borderRadius: "6px",
        color: theme.palette.text.secondary,
      },
      "& .MuiDialogActions-root": {
        display: "flex",
        justifyContent: "space-around",
        padding: "1rem 1.5rem !important",
        backgroundColor: theme.palette.background.paper,
      },
      "& .MuiButton-text MuiButton-textPrimary": {
        width: "100%",
      },
      "& .MuiDialogActions-root button:last-of-type": {
        backgroundColor: theme.palette.background.brand_solid,
        color: "#fff",
        borderRadius: "8px",
        color: theme.palette.text.white,
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
      },
      "& .MuiButton-textPrimary": {
        width: "100%",
      },
      "& .MuiDialogActions-root button:first-of-type": {
        border: `1px solid ${theme.palette.border.main}`,
        borderRadius: "8px",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  };
});
