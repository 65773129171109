export const PaymentPlansStyle = `

/*CSS GENERETED BY ANIMA PPLUGIN */

:root {
--bg-page-light : linear-gradient(180deg, #F4F4F4 0%, #EDEDED 100%);
--bg-page-dark : #272963;
--bg-primary-light : #ffffff;
--bg-primary-dark : transparent;
--bg-secondery-light : #ffffff;
--bg-secondery-dark : rgba(0, 0, 0, 0.40);
--text-primary-light : #2D3E50;
--text-primary-dark : #ffffff;
--table-border-clr-light : #E5E5EA;
--table-border-clr-dark : rgba(255, 255, 255, 0.08);

}


[data-mode="light"] {
  --bg-page: var(--bg-page-light);
  --bg-primary: var(--bg-primary-light);
  --bg-secondery: var(--bg-secondery-light);
  --table-border-clr: var(--table-border-clr-light);
  --text-primary: var(--text-primary-light);
}

[data-mode="dark"] {
  --bg-page: var(--bg-page-dark);
  --bg-primary: var(--bg-primary-dark);
  --bg-secondery: var(--bg-secondery-dark);
  --table-border-clr: var(--table-border-clr-dark);
  --text-primary: var(--text-primary-dark);
}

.PaymentPlanTable_Container {
  display: flex;
  flex-direction: column;
  width: 2480px;
  align-items: center;
  background: var(--bg-page);
  min-height: 100vh;
}

.title {
  color: var(--text-primary);
  font-size: 72px;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.PaymentPlanTable {
  width: 100%;
  padding: 120px 120px 174px 120px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

th {
  font-weight: 700;
}

tr {
  font-weight: 400;
}

tfoot , tbody {
  background: var(--bg-primary);
}

tfoot td {
  background: var(--bg-secondery);
  font-weight: 700;
}



td, th {
  border: 1px solid var(--table-border-clr);
  padding: 8px;
  color: var(--text-primary);
  font-size: 28.406px;
  line-height: 36.522px;
  text-align: center;
  vertical-align: middle;
  height: 81px;
  text-align: left;
  padding: 20px;
}
`;
