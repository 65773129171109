import React, { useCallback, useState, useEffect } from 'react';
import { DialogComponent, Tables } from '../../../../Components';
import { useTranslation } from 'react-i18next';
import { GetActiveUsersLog } from '../../../../Services';
import ActiveUserLogHeader from './ActiveUserHeaderData';
import { TableActions } from '../../../../Enums';
import { ActiveUserLogHistoryTable } from './ActiveUserLogHistoryView/ActiveUserLogHistoryTable';

export const ActiveUserLogTableView = ({ parentTranslationPath }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [filter, setFilter] = useState({ pageIndex: 1, pageSize: 25 });
  const [response, setResponse] = useState({
    result: [],
    totalCount: 0,
  });

  const { t } = useTranslation(parentTranslationPath);
  const getActiveUserLog = useCallback(async () => {
    setIsLoading(true);
    const res = await GetActiveUsersLog(filter);

    if (!(res && res.status && res.status !== 200)) {
      setResponse(res);
    }
    setIsLoading(false);
  }, [filter]);

  useEffect(() => {
    getActiveUserLog();
  }, [getActiveUserLog]);

  const tableActionClicked = (actionType, row) => {
    setActiveItem(row);
    setOpenDialog(true);
  };

  return (
    <>
      <Tables
        parentTranslationPath={parentTranslationPath}
        data={response?.result || []}
        headerData={ActiveUserLogHeader || []}
        isLoading={isLoading}
        itemsPerPage={filter.pageSize}
        activePage={filter.pageIndex - 1}
        onPageIndexChanged={(index) =>
          setFilter((filter) => ({ ...filter, pageIndex: index + 1 }))
        }
        onPageSizeChanged={(index) =>
          setFilter((filter) => ({ ...filter, pageSize: index }))
        }
        totalItems={response.totalCount}
        defaultActions={[{ enum: TableActions.viewDetailsApproval.key }]}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
      />
      <DialogComponent
        isOpen={openDialog}
        titleText='Approvals'
        onCloseClicked={() => {
          setOpenDialog(false);
          getActiveUserLog();
        }}
        dialogContent={
          <ActiveUserLogHistoryTable
            data={activeItem?.history}
            parentTranslationPath={parentTranslationPath}
          />
        }
      />
    </>
  );
};
