import {
  PropertiesManagementView,
  PropertiesProfileManagementView,
  NewPropertiesView,
  PropertyOverview,
} from "../../Views/Home";

import { PropertiesPermissionsCRM } from "../../Permissions/PropertiesPermissions";
import { PropertiesBulkUpdateView } from "../../Views/Home/PropertiesBulkUpdateView/PropertiesBulkUpdateView";
import DuplicatedPropertiesView from "../../Views/Home/PropertiesView/DuplicatedPropertiesView/DuplicatedPropertiesView";

export const NewPropertiesCrmRoutes = [
  {
    path: "/view",
    name: "PropertiesView:properties",
    component: NewPropertiesView,
    layout: "/home/Properties-CRM",
    default: true,
    isRoute: true,
    authorize: true,
    roles: Object.values(PropertiesPermissionsCRM),
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "PropertiesView:properties",
        isDisabled: false,
        route: "/home/Properties-CRM/view",
        groupName: "crm",
      },
    ],
  },
  {
    path: "/add",
    name: "homeLayout.propertiesManagementView.properties-management-view",
    component: PropertiesManagementView,
    layout: "/home/Properties-CRM",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "PropertiesView:properties",
        isDisabled: false,
        route: "/home/Properties-CRM/view",
        groupName: "crm",
      },
      {
        name: "homeLayout.propertiesManagementView.properties-management-view",
        isDisabled: true,
        route: "/home/propertiesadd",
      },
    ],
  },
  {
    path: "/edit",
    name: "homeLayout.propertiesManagementView.properties-management-view",
    component: PropertiesManagementView,
    layout: "/home/Properties-CRM",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "PropertiesView:properties",
        isDisabled: false,
        route: "/home/Properties-CRM/view",
        groupName: "crm",
      },
      {
        name: "homeLayout.propertiesManagementView.properties-management-view",
        isDisabled: true,
        route: "/home/Properties-CRM/edit",
      },
    ],
  },
  {
    path: "/property-profile-edit",
    name: "PropertiesProfileManagementView:property-profile-edit",
    component: PropertiesProfileManagementView,
    layout: "/home/Properties-CRM",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "PropertiesView:properties",
        isDisabled: false,
        route: "/home/Properties-CRM/view",
        groupName: "crm",
      },
      {
        name: "PropertiesProfileManagementView:property-profile-edit",
        isDisabled: true,
        route: "/home/Properties-CRM/property-profile-edit",
      },
    ],
  },
  {
    path: "/duplicated-properties",
    name: "PropertiesView:properties",
    component: DuplicatedPropertiesView,
    layout: "/home/Properties-CRM",
    default: false,
    isRoute: true,
    authorize: true,
    isDisabled: false,
    isExact: true,
    roles: [],
    breadcrumbs: [
      {
        name: "PropertiesView:properties",
        isDisabled: false,
        route: "/home/Properties-CRM/view",
        groupName: "crm",
      },
      {
        name: "PropertiesView:duplicated-properties",
        isDisabled: true,
        route: "/home/Properties-CRM/duplicated-properties",
      },
    ],
  },
  {
    path: "/property-bulk-update",
    name: "PropertiesProfileManagementView:property-bulk-update",
    component: PropertiesBulkUpdateView,
    layout: "/home/Properties-CRM",
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: "PropertiesView:properties",
        isDisabled: false,
        route: "/home/Properties-CRM/view",
        groupName: "crm",
      },
      {
        name: "PropertiesProfileManagementView:property-bulk-update",
        isDisabled: true,
        route: "/home/Properties-CRM/property-bulk-update",
      },
    ],
  },
  {
    path: '/property-profile-overview',
    name: 'PropertiesProfileManagementView:property-profile-edit',
    component: PropertyOverview,
    layout: '/home/Properties-CRM',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [],
  },
];
