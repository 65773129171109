import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import "./SmartProposalView.scss";
import { useDispatch, useSelector } from "react-redux";
import { PageHeading } from "../../../SharedComponents";
import { useTranslation } from "react-i18next";
import QontoStepper from "../../../SharedComponents/QontoStepper/QontoStepper";
import ProposalPreview from "./Utilities/ProposalPreview/ProposalPreview";
import {
  bottomBoxComponentUpdate,
  GlobalHistory,
  showError,
  showSuccess,
  sideMenuComponentUpdate,
  sideMenuIsOpenUpdate,
} from "../../../Helper";
import ProposalSteps from "./Enums/ProposalSteps";
import ProposalFormsContainer from "./Forms/ProposalFormsContainer";
import { ProposalFormsContext } from "./Context/ProposalFormsContext";
import ProposalFormButtons from "./Utilities/ProposalFormButtons/ProposalFormButtons";
import useInitialValues from "./Hooks/useInitialValues";
import { SmartProposalActions } from "../../../store/SmartProposal/Actions";
import PropertyNavigationBar from "./Utilities/PropertyNavigationBar/PropertyNavigationBar";
import { PropertyChangeSource } from "./Enums/PropertyChangeSource";
import generateProposal from "./Helper/generateProposal.js";
import useIsLoading from "./Hooks/useIsLoading.jsx";
import useRedirectOnInvalid from "./Hooks/useRedirectOnInvalid.jsx";


const SmartProposalView = () => {

  const parentTranslationPath = "SmartProposal";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);
  const dispatch = useDispatch();
  const { setIsLoading } = useIsLoading();
  useRedirectOnInvalid();

  const { proposalType, checkedProperties, receivers } = useSelector(
    (state) => state.SmartProposalReducer
  );
  
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") {
      return { ...state, [action.id]: action.value };
    }
    return { ...action.value };
  }, []);


  const [activePropertyIndex, setActivePropertyIndex] = useState({
    index: 0,
    lastChangeSource: null,
  });
  const PropertiesList = checkedProperties
    ? Object.values(checkedProperties)
    : [];
  const activeProperty = PropertiesList[activePropertyIndex.index];
  const activePropertyId = activeProperty?.propertyId;
  const isLastProperty =
    activePropertyIndex.index === PropertiesList.length - 1;
    
  const allowedProposalSteps = useMemo(
    () => {
      const isForMultiUnits = activeProperty?.units?.length > 1;
      const isFirstProperty = activePropertyIndex.index === 0;
      const unitsBasedSteps = isForMultiUnits
        ? ProposalSteps.filter(
          (step) =>
            step.key !== "FloorPlan" &&
            step.key !== "UnitsDetails"
        )
        : ProposalSteps.filter((step) => step.key !== "MultiUnitsDetails");

      const orderBasedSteps = isFirstProperty ?
       unitsBasedSteps 
       : unitsBasedSteps.filter((step) => step.key !== "CoverPage");

       return orderBasedSteps;
    },
    [activePropertyIndex]
  );

  //Stepper Loop
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const activeStep = allowedProposalSteps[activeStepIndex];
  const activeStepKey = activeStep?.key || "";
  const isLastStep = activeStepIndex === allowedProposalSteps.length - 1;

  const [resetForm, setResetForm] = useState(false);

  const { initialFormSelection } = useInitialValues();

  const [proposalFormSelection, setProposalFormsSelection] = useReducer(
    reducer,
    initialFormSelection
  );

  const isPageToggleChecked = proposalFormSelection[activeStepKey]?.showPage;

  const proposalFormChangeHandler = useCallback(
    (action) => setProposalFormsSelection(action),
    []
  );

  const proposalFormsContextValue = useMemo(
    () => ({
      ...proposalFormSelection,
      proposalFormChangeHandler,
    }),
    [proposalFormSelection]
  );

  const saveSubmittedForm = () => {
    const submittedForm = {
      ...proposalFormSelection,
    };

    const updatedPropertyItem = {
      ...(checkedProperties[activePropertyId] || {}),
      isSubmitted: true,
      submittedForm,
    };

    const updatedProposalState = {
      checkedProperties: {
        ...(checkedProperties || []),
        [activePropertyId]: updatedPropertyItem,
      },
      proposalType,
      receivers,
    };

    dispatch(SmartProposalActions.SmartProposalRequest(updatedProposalState));
  };

  const cancelHandler = () => {
    clearCacheHandler();
    GlobalHistory.goBack();
  };

  const nextHandler = () => {
    setActiveStepIndex((prevState) => prevState + 1);
  };

  const finishHandler = () => {
    saveSubmittedForm();
    setActivePropertyIndex((prevState) => ({
      index: prevState?.index,
      lastChangeSource: PropertyChangeSource.Finish.key,
    }));
  };

  const clearCacheHandler = () => {
    dispatch(SmartProposalActions.SmartProposalRequest({}));
  }
  

  const generateProposalHandler = async () => {
    setIsLoading(true);
    const response = await generateProposal({ proposalType, checkedProperties, receivers });
    
    if ( response === true ) {
      cancelHandler();
      showSuccess(t(`Generate-Proposal-Success`));
    } else showError(t(`Generate-Proposal-Fail`));
    setIsLoading(false);
  }
  
  const resetFormHandler = async () => {
    setResetForm(true);
    await setTimeout(() => {
      setResetForm(false);
    }, 500);

    setProposalFormsSelection({ id: "edit", value: initialFormSelection });

    setActivePropertyIndex((prevState) => ({
      index: prevState?.index || 0,
      lastChangeSource: PropertyChangeSource.Reset.key,
    }));

    setActiveStepIndex(0);
  };

  const refillFormHandler = async () => {

    setResetForm(true);
    await setTimeout(() => {
      setResetForm(false);
    }, 500);

    const cachedPropertyForm = {
      ...(checkedProperties[activePropertyId]?.submittedForm || {}),
    };

    const refillData = cachedPropertyForm || initialFormSelection;

    setProposalFormsSelection({ id: "edit", value: refillData });

    setActivePropertyIndex((prevState) => ({
      index: prevState?.index || 0,
      lastChangeSource: PropertyChangeSource.Reset.key,
    }));

    setActiveStepIndex(0);
  };

  const propertyNavigationHandler = (isFormSubmitted, index) => {
    setActiveStepIndex(-1);
    if (isFormSubmitted) {
      setActivePropertyIndex({
        index,
        lastChangeSource: PropertyChangeSource.NavigationFilledForm.key,
      });
    } else {
      setActivePropertyIndex({
        index,
        lastChangeSource: PropertyChangeSource.NavigationEmptyForm.key,
      });
    }
  };

  const submitHandler = async () => {
    saveSubmittedForm();
    setActivePropertyIndex((prevState) => ({
      index: (prevState?.index || 0) + 1,
      lastChangeSource: PropertyChangeSource.Submit.key,
    }));
  };

  const isPageHasToggle = () => activeStep?.hasPageToggle;

  const showPageHandler = (isChecked) => {
    setProposalFormsSelection({
    id: activeStepKey,
    value: {
      ...proposalFormSelection[activeStepKey],
      showPage: isChecked,
    }
  })};

//HP:
//hide whole image section when the image count is zero
//add placeholders to unit card image => add default images

//BE:: unit images reversed direction in interior from FE

console.log("proposalFormSelection", proposalFormSelection);

  const stepClickHandler = useCallback((stepIndex) => {
    setActiveStepIndex(stepIndex);
  }, []);

  useEffect(
    () => () => {
      sideMenuComponentUpdate(null);
      sideMenuIsOpenUpdate(false);
      bottomBoxComponentUpdate(null);
    }
  );

  useEffect(() => {
    if (
      activePropertyIndex.lastChangeSource ===
      PropertyChangeSource.Submit.key ||
      activePropertyIndex.lastChangeSource ===
      PropertyChangeSource.NavigationEmptyForm.key
    )
      resetFormHandler();
    else if (
      activePropertyIndex.lastChangeSource ===
      PropertyChangeSource.NavigationFilledForm.key
    )
      refillFormHandler();
    else if (
      activePropertyIndex.lastChangeSource ===
      PropertyChangeSource.Finish.key
    )
      generateProposalHandler();
  }, [activePropertyIndex]);

  return (
    <ProposalFormsContext.Provider value={proposalFormsContextValue}>
      <div className="SmartProposal px-4">
        <div className="SmartProposal__nav_box">
          <PageHeading
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            headerTitle={"Export-Proposal"}
            subTitle={"Export-Proposal-Sub-Title"}
            wrapperClasses={"pt-4 w-75"}
            headerTitleClasses={"fz-30  mb-3"}
            subTitleClasses={"m-0 fz-16"}
          />

          <div className="SmartProposal__nav">
            <p>{`(${activeProperty?.propertyName})`}</p>
            <span>{PropertiesList.length}</span>
          </div>
        </div>
        <div>
          <QontoStepper
            steps={allowedProposalSteps}
            activeStepIndex={activeStepIndex}
            onStepClickHandler={stepClickHandler}
          />
        </div>
        <div className="SmartProposal_layout">
          <div>
            <ProposalPreview activeStepKey={activeStepKey} />
          </div>
          <div className="SmartProposal_content">
            <div className="d-flex-default fj-between">
              <PageHeading
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                headerTitle={activeStep?.key}
                subTitle={`${activeStep?.key}-Sub-Title`}
                wrapperClasses={"w-75 px-4"}
                headerTitleClasses={"fz-16 mb-1"}
                subTitleClasses={"fz-14 m-0 ml-0"}
              />
              <ProposalFormButtons
                isLastStep={isLastStep}
                isLastProperty={isLastProperty}
                isPageToggleChecked={isPageToggleChecked}
                handler={{
                  finishHandler,
                  nextHandler,
                  submitHandler,
                  cancelHandler,
                  showPageHandler,
                  isPageHasToggle,
                }}
              />
            </div>
            <PropertyNavigationBar
              navigationHandler={propertyNavigationHandler}
              propertiesList={PropertiesList}
              activePropertyIndex={activePropertyIndex?.index || 0}
            />
            <div data-activestep={activeStepKey} className="SmartProposal_box">
              <ProposalFormsContainer
                activeStep={activeStep}
                activeProperty={activeProperty}
                resetForm={resetForm}
              />
            </div>
          </div>
        </div>
      </div>
    </ProposalFormsContext.Provider>
  );
};

export default SmartProposalView;
