import React from "react";
import clsx from "clsx";
import { Checkbox } from "@material-ui/core";

import useStyles from "./styles";

export const CustomCheckboxSize = {
  medium: "medium",
  small: "small",
};

const CustomCheckbox = ({
  sizeVariant = "medium",
  isAllIconSelected = false,
  customIconClass = "",
  ...props
}) => {
  const styles = useStyles({ isAllIconSelected });

  return (
    <Checkbox
      className={styles.root}
      disableRipple
      color="default"
      checkedIcon={
        <span
          className={clsx(styles.icon, styles.checkedIcon, styles[sizeVariant])}
        />
      }
      icon={
        <span
          className={clsx(styles.icon, customIconClass, styles[sizeVariant])}
        />
      }
      inputProps={{ "aria-label": "customCheckbox" }}
      {...props}
    />
  );
};

export default CustomCheckbox;
