import React, { memo, useMemo } from "react";
import "./ProposalPreview.scss";
import Mustache from "mustache";
import { classicTemplate } from "../../Templates/Classic/ClassicTemplate";
import { premiumTemplate } from "../../Templates/Premium/PremiumTemplate";
import { useProposalFormsContext } from "../../Context/ProposalFormsContext";
import { useSelector } from "react-redux";
import { ProposalTypes } from "../../Enums/ProposalTypes";

const ProposalPreview = memo(({ activeStepKey }) => {
  const { proposalType } = useSelector((state) => state.SmartProposalReducer);

  const initialProposalForms = {
    CoverPage: null,
    UnitsDetails: null,
    MultiUnitsDetails: null,
    PropertyDescription: null,
    UnitImages: null,
    LocationAndamenities: null,
    FloorPlan: null,
    PaymentPlan: null,
  };

  const proposalFormsSelection = useProposalFormsContext();

  const activeFormData = useMemo(
    () => proposalFormsSelection[activeStepKey],
    [activeStepKey, proposalFormsSelection]
  );

  const renderTemplate = () => {
    const proposalForms = {
      ...initialProposalForms,
      theme: proposalType?.isDark === true? "dark" : "light",
      [activeStepKey]: activeFormData,
    };

    const proposalTemplateTheme =
      proposalType?.type === ProposalTypes.Classic.key
        ? classicTemplate
        : proposalType?.type === ProposalTypes.Premium.key
        ? premiumTemplate
        : "";

    return Mustache.render(proposalTemplateTheme, proposalForms);
  };

  const renderedTemplate = useMemo(
    () => renderTemplate(),
    [activeStepKey, proposalType, proposalFormsSelection]
  );

  return (
    <div className="ProposalPreview">
      <iframe
        className="ProposalPreview_iframe"
        sandbox
        srcDoc={renderedTemplate}
      />
    </div>
  );
});

export default ProposalPreview;
