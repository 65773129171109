import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { ButtonBase } from "@material-ui/core";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Inputs, AutocompleteComponent } from "../../../Components";
import { DateRangePickerComponent } from "../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent";
import {
  AMLTransactionStatusEnum,
  AMLTransactionTypeEnum,
} from "../../../Enums";
import { GetAdvanceSearchContacts } from "../../../Services";
import { ContactsMapper } from "../../../Views/Home/ContactsView/ContactsUtilities/ContactsMapper";

export const AMLLogsFilters = ({
  parentTranslationPath,
  translationPath,
  setFilter,
  filter,
  defaultFilter,
  defaultFilterAMLLogs,
  setFilterAMLLogs,
  filterAMLLogs,
  exportHandler,
  dateRangeDefault ,
  dateFilter ,
  setDateFilter 

}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [contactData, setContactData] = useState([]);
  const [defaultSelected, setDefaultSelected] = useState({
    contact: null,
    transactionType: null,
    transactionID: null,
    unitReferenceNumber: null,
    amlStatus: null,
  });
  const [selected, setSelected] = useState({ ...defaultSelected });

  const onClearedAllFiltersClick = useCallback(() => {
    setDateFilter(dateRangeDefault);
    setFilter({ ...defaultFilter });
    setFilterAMLLogs({ ...defaultFilterAMLLogs });
    setSelected({ ...defaultSelected });
  });

  const AMLTransactionTypeEnumList = useMemo(
    () => [
      ...Object.values(AMLTransactionTypeEnum).filter(
        (s) => s.key !== 2 && s.key !== 4
      ),
    ],
    []
  );

  const AMLTransactionStatusList = useMemo(
    () => [
      ...Object.values(AMLTransactionStatusEnum).filter(
        (s) => s.key !== 1 && s.key !== 2
      ),
    ],
    []
  );

  const onClearDatePickerClicked = () => {
    setDateFilter(dateRangeDefault);
    setFilterAMLLogs({
      ...filterAMLLogs,
      fromDate: null,
      toDate: null,
    });
  };
  const getAllRelatedContacts = useCallback(async (searchValue) => {
    setIsLoading(true);
    var emailValidRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    let body = {
      criteria: {},
      filterBy: "createdOn",
    };

    if (searchValue && searchValue.match(emailValidRegex)) {
      body = {
        ...body,
        criteria: {
          Email: [{ searchType: 2, value: searchValue }],
        },
        filterBy: "createdOn",
      };
    } else if (searchValue && !isNaN(searchValue)) {
      body = {
        ...body,
        criteria: {
          Ids: [{ searchType: 2, value: searchValue }],
        },
        filterBy: "createdOn",
      };
    } else if (searchValue) {
      body = {
        ...body,
        criteria: {
          FullName: [
            {
              searchType: 2,
              value: searchValue,
            },
          ],
        },
      };
    }
    let list = [];
    const res = await GetAdvanceSearchContacts({ pageSize : 50 , pageIndex : 0 }, body);
    if (!(res && res.status && res.status !== 200)) {
      list = ((res && res.result) || []).map(
        (item) =>
          item.contactJson &&
          ContactsMapper(item, JSON.parse(item.contactJson).contact)
      );
      setContactData(list);
    } else {
      setContactData([]);
    }
    setIsLoading(false);
  }, []);

  return (
    <div className="header-section">
      <div className="filter-section">
        <div className="section" />
        <div className="section autocomplete-section">
          <div className="px-2 w-100 p-relative">
            <div className="d-inline-flex d-flex-v-center-h-start">
              <div className="px-2">
                <Inputs
                  idRef="transactionIDRef"
                  value={selected.transactionID || ""}
                  type={"number"}
                  onInputChanged={(e) => {
                    let { value } = e.target;
                    setSelected({ ...selected, transactionID: value });
                  }}
                  inputPlaceholder={t(`${translationPath}transactionId`)}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  onKeyUp={(e) => {
                    let { value } = e.target;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      setFilterAMLLogs((item) => ({
                        ...item,
                        transactionID:
                          (value && value !== "" && +value) || null,
                      }));
                    }, 700);
                  }}
                  onKeyDown={() => {
                    if (searchTimer) clearTimeout(searchTimer.current);
                  }}
                />
              </div>
              <div className="px-2">
                <Inputs
                  idRef="unitReferenceNumberRef"
                  value={selected.unitReferenceNumber || ""}
                  onInputChanged={(e) => {
                    let { value } = e.target;
                    setSelected({ ...selected, unitReferenceNumber: value });
                  }}
                  inputPlaceholder={t(
                    `${translationPath}unit-reference-number`
                  )}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  onKeyUp={(e) => {
                    let { value } = e.target;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      setFilterAMLLogs((item) => ({
                        ...item,
                        unitReferenceNumber:
                          (value && value !== "" && value) || null,
                      }));
                    }, 700);
                  }}
                  onKeyDown={() => {
                    if (searchTimer) clearTimeout(searchTimer.current);
                  }}
                />
              </div>
              <div className="px-2">
                <AutocompleteComponent
                  selectedValues={selected.transactionType}
                  idRef="transactionTypeRef"
                  multiple={false}
                  data={[...AMLTransactionTypeEnumList] || []}
                  displayLabel={(option) =>
                    (option && option.name && t(`${option.name}`)) || ""
                  }
                  withoutSearchButton
                  inputPlaceholder={t(`${translationPath}transaction-type`)}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setFilterAMLLogs((item) => ({
                      ...item,
                      transactionType: (newValue && newValue.key) || null,
                    }));
                    setSelected({ ...selected, transactionType: newValue });
                  }}
                />
              </div>
            </div>
            <div className="d-flex">
              <div className="px-2">
                <AutocompleteComponent
                  selectedValues={selected?.contact || null}
                  idRef="ClientNameRef"
                  multiple={false}
                  data={contactData || []}
                  displayLabel={(option) => (option && option.name) || ""}
                  renderOption={(option) => (
                    <div className="d-flex-column">
                      <div className="d-flex-v-center-h-between w-100 texts-truncate">
                        {option.name}
                      </div>
                      <span className="c-gray-secondary"> {option.id} </span>
                    </div>
                  )}
                  withoutSearchButton
                  inputPlaceholder={t(`${translationPath}client-name`)}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setFilterAMLLogs((item) => ({
                      ...item,
                      contactId: (newValue && newValue.id) || null,
                    }));
                    setSelected({ ...selected, contact: newValue });
                  }}
                  onOpen={() => {
                    if (contactData && contactData.length === 0)
                      getAllRelatedContacts();
                  }}
                  onInputKeyUp={(e) => {
                    const { value } = e.target;
                    if (searchTimer.current) clearTimeout(searchTimer.current);
                    searchTimer.current = setTimeout(() => {
                      getAllRelatedContacts(value);
                    }, 700);
                  }}
                  inputClasses="inputs theme-form-builder"
                  filterOptions={(options) => options}
                  filterSelectedOptions
                  isLoading={isLoading}
                  withLoader
                />
              </div>
              <div className="px-2">
                <AutocompleteComponent
                  selectedValues={selected.amlStatus}
                  idRef="transactionStatusId"
                  multiple={false}
                  data={[...AMLTransactionStatusList] || []}
                  displayLabel={(option) =>
                    (option && option.name && t(`${option.name}`)) || ""
                  }
                  withoutSearchButton
                  inputPlaceholder={t(`${translationPath}aml-status`)}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onChange={(event, newValue) => {
                    setFilterAMLLogs((item) => ({
                      ...item,
                      amlStatus: (newValue && newValue.key) || null,
                    }));
                    setSelected({ ...selected, amlStatus: newValue });
                  }}
                />
              </div>
              <div className="w-25 mx-2">
                <DateRangePickerComponent
                  ranges={[dateFilter]}
                  onClearClicked={() => onClearDatePickerClicked()}
                  onDateChanged={(selectedDate) => {
                        setDateFilter({
                          startDate:
                            selectedDate.selection &&
                            selectedDate.selection.startDate,

                          endDate: new Date(
                            moment(
                              selectedDate.selection &&
                                selectedDate.selection.endDate
                            ).endOf("day")
                          ),
                          key: "selection",
                        });
                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>
            </div>
          </div>
          <div className="px-2 w-100 p-relative">
            <div className="d-flex-column">
              <ButtonBase
                onClick={exportHandler}
                id="onClearedAllFiltersref"
                className="btns theme-solid  max-height mt-2"
              >
                <span className="mdi  mdi-share-variant m-1" />
                {t(`${translationPath}export-aml-logs`)}
              </ButtonBase>
              <ButtonBase
                onClick={onClearedAllFiltersClick}
                id="onClearedAllFiltersref"
                className="btns theme-solid bg-danger clear-all-btn max-height mt-2"
              >
                <span className="mdi mdi-filter-remove m-1" />
                {t(`${translationPath}clear-filters`)}
              </ButtonBase>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
