import { Box } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import MergeContactsCardSkeleton from "../../../../../../Components/V2/SkeletonLoader/MergeContactsCardSkeleton/MergeContactsCardSkeleton";
import MergeContactCard from "./MergeContactCard";
import { NewMergeContactsContext } from "../../../../../../Contexts/NewMergeContactsContext/NewMergeContactsContext";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { CustomButton } from "../../../../ResaleUnitsView/ResaleUnitsFilters/utilities";
import { useSelectedTheme } from "../../../../../../Hooks";

import useStyles from "./styles";
function MergeContactCardsContainer({
  contactsIds,
  allContactsInfo,
  selectedRadioValues,
  setSelectedRadioValues,
}) {
  const {
    translate,
    mergeRows,
    finalContactDetails,
    setFinalContactDetails,
    originalContact,
    setOriginalContact,
    activeContacts,
    setActiveContacts,
    currentIndex,
    setCurrentIndex,
    onRowClicked,
  } = useContext(NewMergeContactsContext);
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const [isLoading, setIsLoading] = useState(false);

  function onPreviousClicked() {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => Math.max(prev - 3, 0));
    }
  }

  function onNextClicked() {
    if (currentIndex <= allContactsInfo.length - 2) {
      setCurrentIndex((prev) => prev + 3);
    }
  }

  useEffect(() => {
    if (allContactsInfo.length > 0) {
      setActiveContacts(allContactsInfo.slice(currentIndex, currentIndex + 3));
    }
  }, [currentIndex]);

  useEffect(() => {
    if (allContactsInfo) {
      setIsLoading(true);
      setFinalContactDetails(allContactsInfo[0]);
      setOriginalContact(allContactsInfo[0]);
      setActiveContacts(allContactsInfo.slice(currentIndex, currentIndex + 3));
      setIsLoading(false);
    }
  }, [allContactsInfo]);

  return (
    <Box className={styles.new_merge_cards_wrapper}>
      <Box className={styles.new_merge_cards_inner_wrapper}>
        {(isLoading && activeContacts?.length <= 0) ||
        contactsIds?.length !== allContactsInfo?.length ? (
          <MergeContactsCardSkeleton />
        ) : (
          <>
            <Box className={styles.merge_contacts_cards_wrapper}>
              <MergeContactCard
                contact={finalContactDetails}
                rows={mergeRows}
                translate={translate}
                isFinalValuesContact={true}
                selectedRadioValues={selectedRadioValues}
              />
              {activeContacts?.map((item, index) => (
                <React.Fragment>
                  <MergeContactCard
                    contact={item}
                    rows={mergeRows}
                    translate={translate}
                    isOriginalContact={
                      item?.contactsId === originalContact?.contactsId
                    }
                    enableSwap={
                      !(item?.contactsId === originalContact?.contactsId)
                    }
                    onRowClicked={onRowClicked}
                    isFinalValuesContact={false}
                    selectedRadioValues={selectedRadioValues}
                  />
                </React.Fragment>
              ))}
            </Box>
          </>
        )}
      </Box>
      {!isLoading &&
        activeContacts?.length > 0 &&
        contactsIds?.length === allContactsInfo?.length && (
          <Box className={styles.new_contacts_cards_merge_pagination}>
            <CustomButton
              className={styles.new_merge_contacts_cards_arrows}
              onClick={onPreviousClicked}
            >
              <ChevronLeft
                width="24"
                height="24"
                fill={palette.foreground.secondary}
              />
            </CustomButton>
            <CustomButton
              className={styles.new_merge_contacts_cards_arrows}
              onClick={onNextClicked}
            >
              <ChevronRight
                width="24"
                height="24"
                fill={palette.foreground.secondary}
              />
            </CustomButton>
          </Box>
        )}
    </Box>
  );
}

export default MergeContactCardsContainer;
