import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  containerTableReminder: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: "12px",
    borderSpacing: "revert",
    borderCollapse: "unset",
  },
  containerTableHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: theme.palette.background.secondary,
    height: "44px",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  containerTitleHeader: {
    padding: "12px 24px",
    width: "90px",
  },
  containerRimenderTitleHeader: {
    width: "180px",
  },
  containerTableBody: {
    display: "flex",
    flexDirection: "column",
  },
  containerTableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: theme.palette.background.paper,
    height: "64px",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  containerActionButton: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
    justifyContent: "center",
    width: "92px",
  },
  containerIconActionButton: {
    padding: "10px",
  },
  forValue: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
    padding: "16px 24px",
    fontWeight: "400",
    fontSize: "14px",
    color: theme.palette.text.tertiary,
  },
  reminderValue: {
    width: "210px",
  },
  typeTitleReminderStyles: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    color: theme.palette.text.tertiary,
  },
}));
