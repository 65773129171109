const locationAndamenitiesDtoMapper = (property) => {
  
  const locationAndamenities = property.submittedForm?.LocationAndamenities || {};
  const data = property.submittedForm?.LocationAndamenities?.data || {};
  const { propertyLocation } = data;

  const mapLandmarks = (landmarks = []) => landmarks? landmarks.map(landmark => landmark?.name) : [];
  
  const nearBy = locationAndamenities.selectednNearestLandmarks ? 
  locationAndamenities.selectednNearestLandmarks.map(item => ({
    lookupId: item.categoryId,
    lookupName: item.categoryName,
    iconUrl: item.iconUrl,
    items: mapLandmarks(item.landmarks),
  })) : [];
  
  const facilitiesAndAmenities = locationAndamenities.selectedFacilitiesAndAmenities ? 
  locationAndamenities.selectedFacilitiesAndAmenities.map(item => ({
    lookupId: item.id,
    name: item.name,
    iconUrl: item.iconUrl,
  })) : [];
  
  return ({
    showLocation: locationAndamenities.showLocation,
    showNearBy: locationAndamenities.showNearBy,
    showFacilities: locationAndamenities.showFacilities,
    location: propertyLocation?.locationText,
    mapImageUrl: propertyLocation?.mapImageUrl,
    mapLink: propertyLocation?.mapLink,
    nearBy,
    facilitiesAndAmenities,
  })
}

export default locationAndamenitiesDtoMapper;

