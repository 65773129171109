import { useMemo } from 'react';

const usePickerExceedCount = ({itemsList = [], pickerItems, maxPickerItmes = 8, maxChecked }) => {
    const getCheckedExceedMax = () => {
        const checkedItems = pickerItems?.filter((item) => item.isChecked) || [];
        return checkedItems.length >= maxChecked;
      };
  
      const isCheckedExceedMax = useMemo(
        () => getCheckedExceedMax(),
        [pickerItems]
      );
  
      const isItemsNumExceedMax = itemsList.length > maxPickerItmes;
      const additionalItemsCount = itemsList.length - maxPickerItmes + 1;
      const getShowMoreStyles = (index) => ((index == maxPickerItmes - 1) && isItemsNumExceedMax)? "Drag_item_viewMore": "";
      
  return { isCheckedExceedMax, additionalItemsCount, getShowMoreStyles }
}

export default usePickerExceedCount
