import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';
import { CustomInput } from '../../../CustomInput';
import { CustomBadge } from '../../../CustomBadge';
import { useSelectedTheme } from '../../../../../Hooks';

function LandmarksContainer({
  styles,
  selectedLandmark,
  selectedLandmarkCategory,
}) {
  const {
    theme: { palette },
  } = useSelectedTheme();
  const [searchValue, setSearchValue] = useState('');
  const filteredLandmarks = useMemo(() => {
    if (!!searchValue) {
      return selectedLandmark?.landmarks.filter((landmark) =>
        landmark.name.toLowerCase().includes(searchValue.toLowerCase())
      );
    } else return selectedLandmark?.landmarks;
  }, [selectedLandmark?.landmarks, searchValue]);
  return (
    <Box className={clsx(styles.mainContainerItem, styles.landmarksContainer)}>
      <Typography className={styles.headTitle}>
        {selectedLandmarkCategory} near by
      </Typography>
      <Box></Box>
      <CustomInput
        placeholder={selectedLandmarkCategory}
        hasSearchIcon
        style={{
          maxWidth: '100%',
        }}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <Box className={styles.landmarksContent}>
        <Typography className={clsx(styles.resultStyle, styles.borderBottom)}>
          Results
          <CustomBadge
            label={selectedLandmark?.numberOfLandMarkPerCategory}
            Style={{
              margin: '0 8px',
            }}
            BorderColor={palette.utility.brand_200}
            Color={palette.utility.brand_700}
            BackgroundColor={palette.utility.brand_50}
            SizeVariant={'large'}
          />
        </Typography>
        {filteredLandmarks?.map((landmark, index) => (
          <Box className={clsx(styles.borderBottom)} key={`landmark-${index}`}>
            <Typography className={styles.landmarkName}>
              {landmark.name}
            </Typography>
            <Typography className={styles.landmarkCategory}>
              {selectedLandmarkCategory}
            </Typography>
            <Typography className={styles.landmarkAddress}>
              {landmark.address}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default LandmarksContainer;
