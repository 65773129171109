import React from "react";
import { SwitchRoute } from "../../../Components/Route/SwitchRoute";
import { NewPropertiesCrmRoutes } from "../../../routes/HomeRoutes/NewPropertiesCrmRoutes";
import { PropertiesLayoutProvider } from "./PropertiesLayoutContext";

export const NewPropertiesCrmLayout = () => {
  return (
    <PropertiesLayoutProvider>
      <SwitchRoute routes={NewPropertiesCrmRoutes} />
    </PropertiesLayoutProvider>
  );
};
