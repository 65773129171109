import React, { useState, useEffect, useCallback } from "react";
import { DialogComponent } from "../../../Components";
import { useTranslation } from "react-i18next";
import { GetParams } from "../../../Helper";
import { AI_UnitMarketing } from "../../../Services/AIServices/AIServices";

const AIMarketingContentGenerator = ({ onOpen, onClose, onSave }) => {
  const parentTranslationPath = "Shared";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);

  const [englishTitle, setEnglishTitle] = useState("");
  const [englishDescription, setEnglishDescription] = useState("");
  const [arabicTitle, setArabicTitle] = useState("");
  const [arabicDescription, setArabicDescription] = useState("");
  const [isGenerating, setIsGenerating] = useState(true);
  const [error, setError] = useState("");
  const [showRegenerate, setShowRegenerate] = useState(false);
  const [isGenerationComplete, setIsGenerationComplete] = useState(false);
  const [data, setData] = useState(false);

  const GetData = useCallback(async (unitId) => {
    const res = await AI_UnitMarketing(+GetParams("id"));
    if (!(res && res.status && res.status !== 200)) {
      setData(res);
    } else {
      setData(false);
      setError('Services have Error Or Content not found')
      setEnglishTitle("");
      setEnglishDescription("");
      setArabicTitle("");
      setArabicDescription("");
      setShowRegenerate(true);
      setIsGenerationComplete(false);
      setIsGenerating(false);
      setData(false);
    }
  });

  const fetchContent = async (language, field, result) => {
    try {
      if (!result) {
        return "Content not found";
      }

      const fieldMapping = {
        englishTitle: ["English Marketing Title", "EnglishTitle"],
        englishDescription: ["English Marketing Description", "EnglishMarketing Description"], // Handle both versions
        arabicTitle: ["Arabic Marketing Title", "ArabicTitle"],
        arabicDescription: ["Arabic Marketing Description", "ArabicMarketing Description"],
      };

      const fieldKeys = fieldMapping[field];

      for (let key of fieldKeys) {
        if (result[key]) {
          return result[key];
        }
      }

      console.warn(`Field not found: ${field}`);
      return "Content not found";
    } catch (error) {
      console.error("Error fetching content:", error);
      return "An error occurred while fetching content.";
    }
  };

  useEffect(() => {

    GetData();

  }, []);


  useEffect(() => {
    if (data !== false) {
      generateContent("englishTitle");
      generateContent("englishDescription");
    }
  }, [data]);

  const generateContent = async (field) => {
    setError("");

    try {
      const content = await fetchContent("english", field, data);
      if (content) {
        simulateTypingEffect(content, field, () => {
          if (field === "englishDescription") {
            generateContent("arabicTitle");
            generateContent("arabicDescription");
          }
        });
      }
    } catch (err) {
      setError(
        "AI content generation is currently unavailable. Please try again later."
      );
      setIsGenerating(false);
    }
  };

  const simulateTypingEffect = (text, field, onComplete) => {
    if (typeof text !== "string") {
      console.error("Invalid text provided to simulateTypingEffect.");
      return;
    }

    let index = 0;
    const setState =
      field === "englishTitle"
        ? setEnglishTitle
        : field === "englishDescription"
          ? setEnglishDescription
          : field === "arabicTitle"
            ? setArabicTitle
            : setArabicDescription;

    const typingInterval = setInterval(() => {
      try {
        if (index < text.length) {
          setState((prevText) => {
            const newText = prevText + text[index];

            if (
              field === "englishDescription" ||
              field === "arabicDescription"
            ) {
              const textarea = document.querySelector(
                `textarea[data-field="${field}"]`
              );
              if (textarea) {
                textarea.value = newText;
                textarea.scrollTop = textarea.scrollHeight;
              }
            }
            return newText;
          });

          index++;
        } else {
          clearInterval(typingInterval);

          if (field === "arabicDescription") {
            setIsGenerating(false);
            setIsGenerationComplete(true);
            setShowRegenerate(true);
          }

          if (onComplete) {
            onComplete();
          }
        }
      } catch (error) {
        console.error("Error occurred while typing:", error);
        clearInterval(typingInterval);
      }
    }, 0);
  };

  const handleSave = () => {
    onSave({
      englishTitle,
      englishDescription,
      arabicTitle,
      arabicDescription,
    });
    onClose();
  };

  const handleRegenerate = () => {
    setEnglishTitle("");
    setEnglishDescription("");
    setArabicTitle("");
    setArabicDescription("");
    setShowRegenerate(false);
    setIsGenerationComplete(false);
    setIsGenerating(true);
    setData(false);
    GetData();
  };

  const handleEdit = (e, field) => {
    if (isGenerating) {
      e.preventDefault();
      return;
    }
    if (field === "englishTitle") setEnglishTitle(e.target.value);
    if (field === "englishDescription") setEnglishDescription(e.target.value);
    if (field === "arabicTitle") setArabicTitle(e.target.value);
    if (field === "arabicDescription") setArabicDescription(e.target.value);
  };

  return (
    <>
      <DialogComponent
        isOpen={onOpen}
        titleText={
          t(`${translationPath}AiContentCreatorTitle`) ||
          "✨ Ai Content Creator"
        }
        saveText={t(`${translationPath}Save`) || "Save"}
        cancelText={t(`${translationPath}Cancel`) || "Cancel"}
        dialogContent={
          <div className="AIMarketingContentGenerator-wrapper">
            <div className="modal-content">
              {error && <div className="error">{error}</div>}

              <div>
                <h3>
                  {t(`${translationPath}EnglishTitle`) || "English Title"}
                </h3>
                <input
                  type="text"
                  value={englishTitle}
                  onChange={(e) => handleEdit(e, "englishTitle")}
                  disabled={isGenerating}
                />
              </div>

              <div>
                <h3>
                  {t(`${translationPath}EnglishDescription`) ||
                    "English Description"}
                </h3>
                <textarea
                  value={englishDescription}
                  onChange={(e) => handleEdit(e, "englishDescription")}
                  disabled={isGenerating}
                  data-field="englishDescription"
                />
              </div>

              <div>
                <h3>{t(`${translationPath}ArabicTitle`) || "Arabic Title"}</h3>
                <input
                  type="text"
                  value={arabicTitle}
                  onChange={(e) => handleEdit(e, "arabicTitle")}
                  disabled={isGenerating}
                  dir="rtl"
                />
              </div>

              <div>
                <h3>
                  {t(`${translationPath}ArabicDescription`) ||
                    "Arabic Description"}
                </h3>
                <textarea
                  value={arabicDescription}
                  onChange={(e) => handleEdit(e, "arabicDescription")}
                  disabled={isGenerating}
                  dir="rtl"
                  data-field="arabicDescription"
                />
              </div>

              <div className="modal-actions">
                {isGenerating ? (
                  <div className="generating-message">
                    {t(`${translationPath}GeneratingContent`) ||
                      "Generating content..."}
                  </div>
                ) : (
                  <>
                    {showRegenerate && (
                      <button
                        onClick={handleRegenerate}
                        disabled={!isGenerationComplete}
                      >
                        {t(`${translationPath}Regenerate`) || "Regenerate"}
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        }
        saveType="button"
        onSaveClicked={handleSave}
        saveIsDisabled={!isGenerationComplete}
        onCloseClicked={onClose}
        onCancelClicked={onClose}
      />
    </>
  );
};

export default AIMarketingContentGenerator;
