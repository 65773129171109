export const UnitsDetailsTemplate = `
<div class="UnitsDetails" >
  {{#UnitsDetails.data.unitsDetailsData}}
  <div class="first_part">
    <div class="frame">
      <div class="text-wrapper">Highlight Specifics</div>
      <span class="line"></span>
      <div class="div">
        <div class="frame-2">
          <div class="text-wrapper-2">Bedroom</div>
                    <img class="img" src="https://psi-crm.com/ProposalIcons/bed.svg" alt="">
          <div class="div-2">{{#numberOfBedrooms}}{{numberOfBedrooms}}{{/numberOfBedrooms}}{{^numberOfBedrooms}}-{{/numberOfBedrooms}}</div>
        </div>
        <span class="line"></span>
        <div class="frame-2">
          <div class="text-wrapper-2">Bath</div>
                    <img class="img" src="https://psi-crm.com/ProposalIcons/bathtub.svg" alt=""/>
          <div class="div-2">{{#numberOfBathrooms}}{{numberOfBathrooms}}{{/numberOfBathrooms}}{{^numberOfBathrooms}}-{{/numberOfBathrooms}}</div>
        </div>
        <span class="line"></span>
        <div class="frame-2">
          <div class="text-wrapper-2">Size</div>
                    <img class="img" src="https://psi-crm.com/ProposalIcons/resize.svg" alt=""/>
          <p class="div-2"><span class="span">{{#unitSize}}{{unitSize}}{{/unitSize}}{{^unitSize}}-{{/unitSize}}</span> <span class="text-wrapper-3">sq.ft</span></p>
        </div>
        <span class="line"></span>
        <div class="frame-2">
          <div class="text-wrapper-2">Floor</div>
                    <img class="artboard" src="https://psi-crm.com/ProposalIcons/floor.svg" alt=""/>
          <div class="div-2">{{#floorNumber}}{{floorNumber}}{{/floorNumber}}{{^floorNumber}}-{{/floorNumber}}</div>
        </div>
        <span class="line"></span>
        <div class="frame-2">
          <div class="text-wrapper-2">Furnished</div>
                    <img class="img" src="https://psi-crm.com/ProposalIcons/couch.svg" alt=""/>
          <div class="div-2">{{#furnishedStatus}}{{furnishedStatus}}{{/furnishedStatus}}{{^furnishedStatus}}-{{/furnishedStatus}}</div>
        </div>
      </div>
    </div>
    <div class="overlap-group">
      <div class="flexcontainer-wrapper">
        <div class="flexcontainer">
          <p class="text">
            <span class="text-wrapper-4">Your Dream Property Awaits<br /></span>
          </p>
          <p class="text">
            <span class="text-wrapper-5">Welcome to your exclusive unit proposal! At Property Shop Investment (PSI), we’ve tailored this selection to match your aspirations.<br /></span>
          </p>
          <p class="text">
            <span class="text-wrapper-5">Explore the possibilities and let us bring your vision to life!</span>
          </p>
        </div>
      </div>
      {{#sellingPrice}}
      <div class="frame-wrapper">
        <div class="div-wrapper">
          <div class="frame-3">
            <div class="text-wrapper-6">{{sellingPrice}}</div>
            <div class="text-wrapper-7">AED</div>
          </div>
        </div>
      </div>
      {{/sellingPrice}}
    </div>
  </div>
  <div class="second_part">
    {{#UnitsDetails.financialFees}}
    <div class="frame">
    {{#UnitsDetails.showFinancialFees}}
      {{#showTransferFees}}
      {{#UnitsDetails.data.unitsDetailsData}}
      <div class="div">
        <div class="frame-wrapper">
          <div class="div-wrapper"><div class="text-wrapper">Transfer Fees</div></div>
        </div>
        <div class="frame-2">
          <div class="frame-3">
             {{#transferFees}}
                <div class="financial-text">{{transferFees}}</div>
                <div class="financial-text-sub">AED</div>
                {{/transferFees}}
                {{^transferFees}}
                <div class="financial-text-sub">-</div>
             {{/transferFees}}
          </div>
        </div>
      </div>
      {{/UnitsDetails.data.unitsDetailsData}}
      {{/showTransferFees}}
      {{#showAgencyFees}}
      {{#UnitsDetails.data.unitsDetailsData}}
      <div class="div">
        <div class="frame-wrapper">
          <div class="div-wrapper"><div class="text-wrapper">Agency Fees</div></div>
        </div>
        <div class="frame-2">
          <div class="frame-3">
                {{#agencyFees}}
                <div class="financial-text">{{agencyFees}}</div>
                <div class="financial-text-sub">AED</div>
                <div class="financial-text-sub">+VAT</div>
                {{/agencyFees}}
                {{^agencyFees}}
                <div class="financial-text-sub">-</div>
                {{/agencyFees}}
          </div>
        </div>
      </div>
      {{/UnitsDetails.data.unitsDetailsData}}
      {{/showAgencyFees}}
      {{#showNOCFeeBuyer}}
      {{#UnitsDetails.data.unitsDetailsData}}
      <div class="div">
        <div class="frame-wrapper">
          <div class="div-wrapper"><div class="text-wrapper">NOC Fee Buyer</div></div>
        </div>
        <div class="frame-2">
          <div class="frame-3">
                {{#nocFeeBuyer}}
                <div class="financial-text">{{nocFeeBuyer}}</div>
                <div class="financial-text-sub">AED</div>
                {{/nocFeeBuyer}}
                {{^nocFeeBuyer}}
                <div class="financial-text-sub">-</div>
                {{/nocFeeBuyer}}
          </div>
        </div>
      </div>
      {{/UnitsDetails.data.unitsDetailsData}}
      {{/showNOCFeeBuyer}}
      {{#showServiceCharge}}
      {{#UnitsDetails.data.unitsDetailsData}}
      <div class="div">
        <div class="frame-wrapper">
          <div class="div-wrapper"><div class="text-wrapper">Service Charge</div></div>
        </div>
        <div class="frame-2">
          <div class="frame-3">
                {{#serviceCharge}}
                <div class="financial-text">{{serviceCharge}}</div>
                <div class="financial-text-sub">AED</div>
                {{/serviceCharge}}
                {{^serviceCharge}}
                <div class="financial-text-sub">-</div>
                {{/serviceCharge}}
          </div>
        </div>
      </div>
      {{/UnitsDetails.data.unitsDetailsData}}
      {{/showServiceCharge}}
      {{#showChillerCharges}}
      {{#UnitsDetails.data.unitsDetailsData}}
      <div class="div">
        <div class="frame-wrapper">
          <div class="div-wrapper"><div class="text-wrapper">Chiller Charges</div></div>
        </div>
        <div class="frame-2">
          <div class="frame-3">
                {{#chillerCharges}}
                <div class="financial-text">{{chillerCharges}}</div>
                <div class="financial-text-sub">AED</div>
                {{/chillerCharges}}
                {{^chillerCharges}}
                <div class="financial-text-sub">-</div>
                {{/chillerCharges}}
          </div>
        </div>
      </div>
      {{/UnitsDetails.data.unitsDetailsData}}
      {{/showChillerCharges}}
      {{#showLeased}}
      {{#UnitsDetails.data.unitsDetailsData}}
      <div class="div">
        <div class="frame-wrapper">
          <div class="div-wrapper"><div class="text-wrapper">Leased</div></div>
        </div>
        <div class="frame-2">
                {{#leased}}
                <div class="financial-text">{{leased}}</div>
                {{/leased}}
                {{^leased}}
                <div class="financial-text-sub">-</div>
                {{/leased}}
        </div>
      </div>
      {{/UnitsDetails.data.unitsDetailsData}}
      {{/showLeased}}
      {{#showCurrentRentAmount}}
      {{#UnitsDetails.data.unitsDetailsData}}
      <div class="div">
        <div class="frame-wrapper">
          <div class="div-wrapper"><div class="text-wrapper">Current Rent</div></div>
        </div>
        <div class="frame-2">
          <div class="frame-3">
                {{#currentRentAmount}}
                <div class="financial-text">{{currentRentAmount}}</div>
                <div class="financial-text-sub">AED</div>
                {{/currentRentAmount}}
                {{^currentRentAmount}}
                <div class="financial-text-sub">-</div>
                {{/currentRentAmount}}
          </div>
        </div>
      </div>
      {{/UnitsDetails.data.unitsDetailsData}}
      {{/showCurrentRentAmount}}
      {{#showRentalExpiryDate}}
      {{#UnitsDetails.data.unitsDetailsData}}
      <div class="div">
        <div class="frame-wrapper">
          <div class="div-wrapper"><div class="text-wrapper">Rental Expiry</div></div>
        </div>
        <div class="frame-2">
                {{#rentalExpiryDate}}
                <div class="financial-text">{{rentalExpiryDate}}</div>
                {{/rentalExpiryDate}}
                {{^rentalExpiryDate}}
                <div class="financial-text-sub">-</div>
                {{/rentalExpiryDate}}
        </div>
      </div>
      {{/UnitsDetails.data.unitsDetailsData}}
      {{/showRentalExpiryDate}}
     {{/UnitsDetails.showFinancialFees}}
    </div>
    {{/UnitsDetails.financialFees}}
    <div class="frame-4">
      <div class="text-wrapper-5">Highlight Specifics</div>
      <span class="line"></span>
      <div class="frame-5">
        <div class="frame-6">
          <div class="text-wrapper-6">View</div>
                    <img class="img" src="https://psi-crm.com/ProposalIcons/beach.svg" alt=""/>
          <div class="text-wrapper-7">{{#view}}{{view}}{{/view}}{{^view}}-{{/view}}</div>
        </div>
        <span class="line"></span>
        <div class="frame-6">
          <div class="text-wrapper-6">Maid Room</div>
                    <img class="img" src="https://psi-crm.com/ProposalIcons/maidroom.svg" alt=""/>
          <div class="text-wrapper-7">{{#numberOfMaidRooms}}{{numberOfMaidRooms}}{{/numberOfMaidRooms}}{{^numberOfMaidRooms}}-{{/numberOfMaidRooms}}</div>
        </div>
        <span class="line"></span>
        <div class="frame-6">
          <div class="text-wrapper-6">Balcony</div>
                    <img class="img" src="https://psi-crm.com/ProposalIcons/balcony.svg" alt=""/>
          <div class="text-wrapper-7">{{#balconyAvailability}}{{balconyAvailability}}{{/balconyAvailability}}{{^balconyAvailability}}-{{/balconyAvailability}}</div>
        </div>
        <span class="line"></span>
        <div class="frame-6">
          <div class="text-wrapper-6">Storage</div>
                    <img class="img" src="https://psi-crm.com/ProposalIcons/storage.svg" alt=""/>
          <div class="text-wrapper-7">{{#storageAvailability}}{{storageAvailability}}{{/storageAvailability}}{{^storageAvailability}}-{{/storageAvailability}}</div>
        </div>
        <span class="line"></span>
        <div class="frame-6">
          <div class="text-wrapper-6">Parking</div>
                    <img class="img" src="https://psi-crm.com/ProposalIcons/parking.svg" alt=""/>
          <div class="text-wrapper-7">{{#numberOfAvailableParking}}{{numberOfAvailableParking}}{{/numberOfAvailableParking}}{{^numberOfAvailableParking}}-{{/numberOfAvailableParking}}</div>
        </div>
      </div>
    </div>
  </div>
  {{/UnitsDetails.data.unitsDetailsData}}
</div>
`;
