import React from "react";

//  styles
import useStyles from './styles'

function Nearby({}) {
    const styles =useStyles()

    return(
        <>Nearby</>
    )
}

export default Nearby