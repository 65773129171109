import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";

import { GetAdvanceSearchContacts } from "../../../Services";
import { ContactsMapper } from "../../../Views/Home/ContactsView/ContactsUtilities";
import { useNewLayoutShared } from "../../../Contexts/NewLayoutSharedContext";

// Create the context
export const ContactLayoutContext = createContext();

// Create the provider component
export const ContactsLayoutProvider = ({ children }) => {
  const hasMounted = useRef(false); // Track initial render
  const { advancedSearchBody, pagination } = useNewLayoutShared();

  const [isLoading, setIsLoading] = useState(true);
  const [contactsData, setContactsData] = useState({
    result: [],
    totalCount: 0,
  });
  const [kycHistoryActions, setKycHistoryActions] = useState({
    activeItem: null,
    isShareOpen: false,
    isRiskRatingClicked: false,
    isViewOpen: false,
    riskRatingChanged: null,
  });

  const fetchContacts = useCallback(async () => {
    setIsLoading(true);
    hasMounted.current = true; // Mark as mounted

    try {
      const response = await GetAdvanceSearchContacts(
        {
          pageIndex: pagination.currentPage - 1,
          pageSize: pagination.itemsPerPage,
        },
        advancedSearchBody
      );

      if (!(response && response.status && response.status !== 200)) {
        setContactsData({
          result: ((response && response.result) || []).map(
            (item) =>
              item?.contactId && item?.contactJson &&
              ContactsMapper(item, JSON.parse(item.contactJson).contact)
          ),
          totalCount: (response && response.totalCount) || 0,
        });
      } else {
        setContactsData({
          result: [],
          totalCount: 0,
        });
      }
    } catch (error) {
      console.error("Failed to fetch contacts:", error);
    } finally {
      setIsLoading(false);
      hasMounted.current = false;
    }
  }, [pagination, advancedSearchBody]);

  useEffect(() => {
    if (!hasMounted.current) {
      fetchContacts();
    }
  }, [pagination, advancedSearchBody]);

  return (
    <ContactLayoutContext.Provider
      value={{
        contactsData,
        setContactsData,
        isLoading,
        hasMounted,
        kycHistoryActions,
        setKycHistoryActions,
      }}
    >
      {children}
    </ContactLayoutContext.Provider>
  );
};
