import React from "react";

//  styles
import useStyles from './styles'

function Task({}) {
    const styles =useStyles()

    return(
        <>Task</>
    )
}

export default Task