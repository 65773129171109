import { GetAllDevelopersContactsAPI, formByIdGet } from "../../../Services";

let oldvalue = "";
let timer = null;

export const DeveloperContactRule = async (
  item,
  value,
  setItems,
  setIsLoading
) => {
  if (!item.data.searchKey) return;
  if (timer !== null) clearTimeout(timer);

  timer = setTimeout(async () => {
    item.data.enum = [];
    let formId = null;
    setIsLoading(true);
    const response = await formByIdGet({
      formname: "Developers",
    });
    if (!(response && response.status && response.status !== 200))
      formId =
        response && response.length && response[0] && response[0].form_id;
    item.data.formId = formId;
    let list = [];

    const rs = await GetAllDevelopersContactsAPI({
      pageIndex: 1,
      pageSize: 100,
      search: value,
    });

    if (!rs || !rs.result) return;
    rs.result.map((element) => {
      const developerContact = element?.contact && JSON.parse(element.contact);
      list.push({
        id: element.contactsId,
        name: developerContact?.contact?.company_name,
        mobile:
          (developerContact?.contact?.landline_number &&
            developerContact?.contact?.landline_number?.phone) ||
          null,
        email_address:
          (developerContact?.contact?.general_email &&
            developerContact?.contact?.general_email?.email) ||
          null,
        contact_image: developerContact?.contact?.company_logoimage
          ? developerContact?.contact?.company_logoimage["Company Logo"]
            ? developerContact?.contact?.company_logoimage["Company Logo"] &&
              developerContact?.contact?.company_logoimage["Company Logo"]
                .length &&
              developerContact?.contact?.company_logoimage["Company Logo"][0]
            : developerContact?.contact?.company_logoimage["Company Images"] &&
              developerContact?.contact?.company_logoimage["Company Images"]
                .length &&
              developerContact?.contact?.company_logoimage["Company Images"][0]
          : null,
        developerId: element.developerId,
      });
    });
    setItems(list || []);
    item.data.enum = list;
    setIsLoading(false);
  }, 500);
};
