import React from "react";

function TagIcon({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M3.749 1.044c-.786.078-1.348.328-1.859.826-.399.39-.654.861-.796 1.47-.069.299-.074.55-.074 3.82 0 3.26.005 3.518.073 3.76.136.486.354.87.739 1.3.586.654 9.239 9.264 9.6 9.552.861.687 1.643.988 2.568.988s1.707-.301 2.568-.988c.432-.345 4.859-4.772 5.204-5.204.687-.861.988-1.643.988-2.568s-.3-1.706-.988-2.568c-.386-.484-9.57-9.658-9.897-9.887a3.028 3.028 0 0 0-.97-.454c-.216-.058-.629-.066-3.525-.074-1.804-.005-3.438.007-3.631.027m6.891 2.083c.227.136 9.434 9.355 9.699 9.713.253.341.334.532.389.909.037.259.034.364-.021.629-.125.609-.255.76-3.134 3.624-1.543 1.534-2.321 2.281-2.519 2.414-.672.453-1.433.454-2.108.001-.21-.141-1.534-1.437-4.999-4.894-2.75-2.744-4.758-4.778-4.82-4.883l-.107-.18-.011-3.079c-.012-3.345.003-3.694.17-3.947.154-.233.368-.354.687-.392.151-.018 1.696-.03 3.434-.027l3.16.005.18.107m-3.124 3.45c-1.05.402-1.349 1.7-.571 2.478a1.491 1.491 0 0 0 2.11 0c.726-.725.522-1.929-.405-2.398-.195-.099-.28-.116-.608-.125-.253-.007-.431.008-.526.045"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default TagIcon;
