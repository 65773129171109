import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useDebounce, useSelectedTheme } from "../../../Hooks";

// Icons
import { AlertCircle, SearchIcon } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

function CustomInput(props) {
  const {
    debounceTime,
    hasSearchIcon,
    onChange,
    handleClick,
    disabled,
    style,
    CustomClass,
    hasNoBorder,
    handleOnBlur,
    inputContainerOverrideStyles,
    errorMessage,
    hasError,
    submitted,
    colorDisabled,
    backgroundColorDisabled,
    preInputText,
    onKeyUp,
    onKeyDown,
    ...restProps
  } = props;

  const styles = useStyles({ backgroundColorDisabled, colorDisabled });

  const {
    theme: { palette },
  } = useSelectedTheme();

  const [inputValue, setInputValue] = useState(restProps.value || "");
  const debouncedValue = useDebounce(inputValue, debounceTime);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setInputValue(restProps.value || "");

    if (!restProps.value) {
      setIsFocused(false);
    }
  }, [restProps.value]);

  const isInitialRender = useRef(true); // Track if it's the initial render

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return; // Skip the effect on the initial render
    }

    if (debounceTime && onChange) {
      onChange({ target: { value: debouncedValue } });
    }
  }, [debouncedValue, debounceTime]);

  useEffect(() => {
    if (hasNoBorder && inputRef.current) {
      inputRef.current.focus();
    }
  }, [hasNoBorder]);

  const handleChange = (event) => {
    setInputValue(event.target.value);
    if (!debounceTime && onChange) {
      onChange(event);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (event) => {
    setIsFocused(false);

    if (handleOnBlur) {
      handleOnBlur(event);
    }
  };

  return (
    <div className={styles.inputWrapper}>
      <div
        style={style}
        className={clsx(
          styles.inputContainer,
          {
            [styles.disabled]: disabled,
            [styles.inputContainerError]: hasError && submitted,
            [styles.inputContainerFocused]: isFocused,
            [inputContainerOverrideStyles]: !!inputContainerOverrideStyles,
            [colorDisabled]: colorDisabled && disabled,
            [backgroundColorDisabled]: backgroundColorDisabled && disabled,
            [styles.inputContainerNoBorder]: hasNoBorder,
          },
          CustomClass
        )}
      >
        {hasSearchIcon && (
          <SearchIcon
            width="20"
            height="20"
            fill={palette.foreground.quarterary}
          />
        )}
        {preInputText && (
          <span className={styles.preInputText}>{preInputText}</span>
        )}
        <input
          autoComplete="off"
          type={restProps.type}
          placeholder={restProps.placeholder}
          value={inputValue}
          onChange={handleChange}
          onClick={handleClick}
          name={restProps.name}
          disabled={disabled}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
          min={restProps.min}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
        />
        {/* {hasError && submitted && (
          <AlertCircle
            width="16"
            height="16"
            fill={palette.foreground.errorSecondary}
          />
        )} */}
      </div>
      {hasError && errorMessage && submitted && (
        <span className={styles.errorMessage}>{errorMessage}</span>
      )}
    </div>
  );
}

CustomInput.defaultProps = {
  disabled: false,
  debounceTime: 0,
  hasSearchIcon: true,
  onChange: undefined,
  handleClick: undefined,
  style: {},
  CustomClass: "",
  hasNoBorder: false,
  handleOnBlur: undefined,
  inputContainerOverrideStyles: undefined,
  errorMessage: "",
  hasError: false,
  submitted: false,
};

export default CustomInput;
