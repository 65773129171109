export const PropertyDescriptionStyle = `

/*CSS GENERETED BY ANIMA PPLUGIN */

:root {
--bg-page-light : #F4F4F4;
--bg-page-dark : #272963;
--bg-card-light :rgba(255, 255, 255, 0.60);
--bg-card-dark : rgba(0, 0, 0, 0.40);
--bg-page-2-light :#eeeeee;
--bg-page-2-dark : #272963;
--bg-cover-light : linear-gradient(180deg, #EDEDED 0%, #fafafa 16.19%, rgba(244, 244, 244, 0.40) 67.95%);
--bg-cover-dark : linear-gradient(180deg, #272963 0%, #272963 16.19%, rgba(39, 41, 99, 0.40) 67.95%);
--border-clr-btn-light : #272963;
--border-clr-btn-dark : transparent;
--text-primary-light : #2D3E50;
--text-primary-dark : #FFFFFF;
--text-secondery-light : rgba(45, 62, 80, 0.60);
--text-secondery-dark : rgba(255, 255, 255, 0.60);
--icon-filter-light : grayscale(1) invert(1);
--icon-filter-dark : unset;
--arrow-icon-filter-light : grayscale(1) invert(1);
--arrow-icon-filter-dark : unset;
--map-filter-light :  contrast(1.1) saturate(0.05);
--map-filter-dark : grayscale(1) invert(1);
}


[data-mode="light"] {
  --bg-page: var(--bg-page-light);
  --bg-card: var(--bg-card-light);
  --bg-row: var(--bg-row-light);
  --bg-cover: var(--bg-cover-light);
  --bg-page-2: var(--bg-page-2-light);
  --border-clr-btn: var(--border-clr-btn-light);
  --text-primary: var(--text-primary-light);
  --text-secondery: var(--text-secondery-light);
  --icon-filter: var(--icon-filter-light);
  --arrow-icon-filter: var(--arrow-icon-filter-light);
  --map-filter: var(--map-filter-light);
}

[data-mode="dark"] {
  --bg-page: var(--bg-page-dark);
  --bg-card: var(--bg-card-dark);
  --bg-row: var(--bg-row-dark);
  --bg-cover: var(--bg-cover-dark);
  --bg-page-2: var(--bg-page-2-dark);
  --bg-cover: var(--bg-cover-dark);
  --border-clr-btn: var(--border-clr-btn-dark);
  --text-primary: var(--text-primary-dark);
  --text-secondery: var(--text-secondery-dark);
  --icon-filter: var(--icon-filter-dark);
  --arrow-icon-filter: var(--arrow-icon-filter-dark);
  --map-filter: var(--map-filter-dark);
}



.PropertyDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  {{#PropertyDescription.showGeneralFacts}}
  justify-content: space-between;
  gap: 100px;
  {{/PropertyDescription.showGeneralFacts}}
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  min-height: 100%;
  background: var(--bg-page);
}

.first_part {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 100px;
  padding: 120px;
  width: 2480px;
}


.experience-the {
  overflow-wrap: break-word;
}

.first_part .content {
  display: flex;
  flex-direction: column;
  width: 2240px;
  align-items: flex-start;
  gap: 120px;
  border-radius: 50px;
}

.first_part .frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 100px;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.first_part .text-wrapper {
  width: fit-content;
  margin-top: -1.00px;
color: var(--ffffff, #FFF);
font-family: Inter;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 40px;
}

.first_part .div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.first_part .text-wrapper-2 {
  width: fit-content;
  margin-top: -1.00px;
  font-family: "DM Sans-Bold", Helvetica;
  font-weight: 700;
  color: var(--text-primary);
  font-size: 72px;
  letter-spacing: -1.44px;
  line-height: 65px;
  white-space: nowrap;
}

.first_part .div-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.first_part .experience-the {
  align-self: stretch;
  margin-top: -1.00px;
  font-family: "DM Sans-9ptRegular", Helvetica;
  font-weight: 400;
  color: var(--text-primary);
  font-size: 34px;
  letter-spacing: 0;
  line-height: normal;
}

.first_part .row {
  display: flex;
  align-items: flex-start;
  gap: var(--3-spacing-spacing-4xl);
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.first_part .col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1;
  flex-grow: 1;
}

.first_part .frame-2 {
  display: inline-flex;
  align-items: center;
  gap: var(--3-spacing-spacing-xl);
  flex: 0 0 auto;
}

.first_part .arrow-narrow-right {
  width: 34px;
  height: 34px;
  filter: var(--arrow-icon-filter);
  margin-right: 16px;
}

.first_part .frame-3 {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  flex: 0 0 auto;
}

.first_part .text {
  width: fit-content;
  margin-top: -1.00px;
  font-family: "DM Sans-9ptRegular", Helvetica;
  font-weight: 400;
  color: var(--text-secondery);
  font-size: 36px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.first_part .text-2 {
  width: fit-content;
  margin-top: -1.00px;
  font-family: "DM Sans-9ptRegular", Helvetica;
  font-weight: 400;
  color: var(--text-primary);
  font-size: 36px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}


.project-page-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-page-header .div {
display: flex;
align-items: flex-start;
justify-content: space-between;
align-self: stretch;
width: 100%;
position: relative;
flex: 0 0 auto;
}

.project-page-header .frame-wrapper {
display: inline-flex;
flex-direction: column;
align-items: flex-start;
gap: 24px;
position: relative;
flex: 0 0 auto;
}

.project-page-header .frame-2 {
display: inline-flex;
flex-direction: column;
align-items: flex-start;
gap: 5px;
position: relative;
flex: 0 0 auto;
}

.project-page-header .text-wrapper {
position: relative;
width: fit-content;
margin-top: -1.00px;
font-family: "Inter-Medium", Helvetica;
font-weight: 500;
color: #ffffff;
font-size: 50px;
letter-spacing: 0;
line-height: normal;
}

.project-page-header .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "DM Sans-Bold", Helvetica;
  font-weight: 700;
  color: var(--text-primary);
  font-size: 72px;
  letter-spacing: -1.44px;
  line-height: 65px;
  white-space: nowrap;
}

.project-page-header .frame-3 {
display: flex;
padding: 32px 40px;
justify-content: center;
align-items: center;
gap:4px;
border-radius: 16px;
border: 2px solid #121456;
background: #F75414;
cursor: pointer;
}

.project-page-header .buttons-button {
display: flex;
align-items: center;
justify-content: center;
position: relative;
align-self: stretch;
width: 100%;
flex: 0 0 auto;
border-radius: 10.67px;
overflow: hidden;
box-shadow: 0px 2px 4px #1018280d;
}

.project-page-header .download {
position: relative;
width: 40px;
height: 40px;
}

.project-page-header .text-padding {
margin-top: 8px;
margin-left: 8px;
  color: #FFF;
font-family: Inter;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 40px;
}

.project-page-header .text {
position: relative;
width: fit-content;
margin-top: -2.00px;
color: var(--text-secondery);
font-family: Inter;
font-size: 36px;
font-style: normal;
font-weight: 600;
line-height: 40px;
}

.project-page-header .rectangle {
position: relative;
width: 2100.39px;
height: 24.75px;
background-color: #f75414;
border-radius: 50px 0px 0px 50px;
flex: 0 0 auto;
}






.second_part {
  padding: 120px;
    width: 2480px;
    background: var(--bg-cover), url("https://psi-crm.com/ProposalIcons/premium-cover.jpg");
    background-position-y: 280px;
    background-position-x: -200px;
    background-repeat: no-repeat;
    background-color: var(--bg-page-2);
    background-size: 125%;
}

.second_part .overlap-group {
  min-height: 1520px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 200px;
}

.second_part .pexels-photo-by {
  width: 2480px;
  height: 1416px;
  object-fit: cover;
}

.second_part .frame {
  display: flex;
  flex-direction: column;
  width: 2240px;
  align-items: flex-start;
  gap: 50px;
}

.second_part .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "DM Sans-Bold", Helvetica;
  font-weight: 700;
  color: var(--text-primary);
  font-size: 72px;
  letter-spacing: -1.44px;
  line-height: 65px;
  white-space: nowrap;
}

.second_part .row-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.second_part .row {
  display: flex;
  align-items: flex-start;
  gap: var(--3-spacing-spacing-4xl);
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.second_part .col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.second_part .div {
  display: inline-flex;
  align-items: center;
  gap: var(--3-spacing-spacing-xl);
  position: relative;
  flex: 0 0 auto;
}

.second_part .arrow-narrow-right {
  position: relative;
  width: 34px;
  height: 34px;
  filter: var(--arrow-icon-filter);
  margin-right: 16px;
}

.second_part .frame-2 {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.second_part .text {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "DM Sans-9ptRegular", Helvetica;
  font-weight: 400;
  color: var(--text-secondery);
  font-size: 36px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.second_part .text-2 {
  margin-top: -1.00px;
  font-family: "DM Sans-9ptRegular", Helvetica;
  color: var(--text-primary);
  font-size: 36px;
  position: relative;
  width: fit-content;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.second_part .frame-3 {
  display: flex;
  flex-direction: column;
  width: 2240px;
  align-items: flex-start;
  gap: 50px;
}

.second_part .col-2 {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.second_part .frame-4 {
  display: flex;
  flex-direction: column;
  height: 352px;
  align-items: center;
  gap: 32px;
  padding: 32px;
  position: relative;
  flex: 1;
  flex-grow: 1;
  background-color: var(--bg-card);
  border-radius: 12px;
  backdrop-filter: blur(8px) brightness(100%);
  -webkit-backdrop-filter: blur(8px) brightness(100%);
}

.second_part .img {
  position: relative;
  width: 120px;
  height: 120px;
  filter: var(--icon-filter);
}

.second_part .frame-5 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
}

.second_part .text-3 {
  margin-top: -1.00px;
  font-family: "Inter-Regular", Helvetica;
  color: var(--text-secondery);
  font-size: 36px;
  position: relative;
  width: fit-content;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.second_part .text-4 {
  font-family: "Inter-Regular", Helvetica;
  color: var(--text-primary);
  font-size: 46px;
  position: relative;
  width: fit-content;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}



`
