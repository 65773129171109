import React, { Children, useCallback, useEffect, useState } from 'react';
import { GetLeadStageHistory } from '../../../../Services';
import { getDownloadableLink, showError } from '../../../../Helper';
import {
  Avatar,
  ButtonBase,
  Chip,
  makeStyles,
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';
import { DefaultImagesEnum } from '../../../../Enums';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../../../SpinnerComponent/Spinner';
import { Check } from '@material-ui/icons';

const StyledStepper = withStyles({
  root: {
    padding: 0,
    width: '100%',
  },
})(Stepper);
const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    paddingLeft: 6,
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    backgroundColor: 'green',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: 'white',
      width: 14,
      height: 14,
    },
  },
});

function QontoStepIcon({ active }) {
  const classes = useQontoStepIconStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      <div className={classes.circle}>
        <Check fontSize='small' color='primary' />
      </div>
    </div>
  );
}

const LeadStageTracking = ({
  parentTranslationPath,
  translationPath,
  paramsId: leadId,
}) => {
  const { t } = useTranslation(parentTranslationPath, {
    keyPrefix: translationPath,
  });
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getLeadStageHistory = useCallback(async () => {
    try {
      const { result } = await GetLeadStageHistory(leadId);
      setData(result);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  }, [leadId]);

  useEffect(() => {
    getLeadStageHistory();
  }, [getLeadStageHistory]);
  return (
    <div className='d-flex-column'>
      <Spinner isActive={isLoading} isAbsolute />
      {Children.toArray(
        data.map((item, i) => (
          <div className='flex'>
            <div
              className='flex flex-col fa-center  '
              style={{ width: '6rem' }}
            >
              <Typography variant='body1' color='textSecondary'>
                {moment(item.createdOn).format('MMM D')}
              </Typography>
              <Typography variant='h6' color='textSecondary'>
                {moment(item.createdOn).format('YYYY')}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                {moment(item.createdOn).format('h:mm A')}
              </Typography>
            </div>
            <StyledStepper orientation='vertical'>
              <Step>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <Typography className='mx-2'>{item.leadStageName}</Typography>
                </StepLabel>
                <StepContent>
                  <div className=' d-flex fa-center'>
                    <Typography>
                      {i === data.length - 1
                        ? t('created_by')
                        : t('updated_by')}
                    </Typography>
                    <Avatar
                      src={
                        item.creatorProfileImag
                          ? getDownloadableLink(item.creatorProfileImag)
                          : DefaultImagesEnum.man.defaultImg
                      }
                      alt={item.creator}
                      sizes='small'
                      className='mx-2'
                    />
                    <Typography component={'strong'} className=' px-1'>
                      {item.creator}
                    </Typography>
                  </div>
                  <br />
                </StepContent>
              </Step>
              <StepConnector />
            </StyledStepper>
          </div>
        ))
      )}
    </div>
  );
};
export default LeadStageTracking;
