import React from "react";
import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import useStyles from "./styles";
function MergeContactsCardSkeleton({ cardsNumber, columnsNumber }) {
  const styles = useStyles();
  const cardsAmount = cardsNumber ?? [1, 2, 3, 4];
  const columnsAmount = columnsNumber ?? [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13,
  ];

  return (
    <Grid container spacing={2} className={styles.merge_cards_skeleton_wrapper}>
      {cardsAmount.map((_, index) => (
        <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
          <Box className={styles.merge_cards_skeleton_background}>
            <Box className={styles.cardHeader}>
              <div className="skeleton-wrapper">
                <Skeleton
                  animation="wave"
                  height={72}
                  width={72}
                  variant="circle"
                />
              </div>
              <Skeleton
                animation="wave"
                height={36}
                width={298}
                variant="text"
              />
            </Box>
            <Box className={styles.cardColumns}>
              {columnsAmount.map((_, colIndex) => (
                <Skeleton
                  key={colIndex}
                  animation="wave"
                  height={36}
                  width="100%"
                  variant="rect"
                />
              ))}
            </Box>
          </Box>
        </Grid>
      ))}
      <Box className={styles.merge_skeleton_arrows_styles}>
        <Skeleton animation="wave" height={44} width={44} variant="circle" />
        <Skeleton animation="wave" height={44} width={44} variant="circle" />
      </Box>
    </Grid>
  );
}

export default MergeContactsCardSkeleton;
