import { getPublicDownloadableLink } from "../../../../Helper";


const getUrlFromFileObj = (filesList) => {
  if(!Array.isArray(filesList)) return [];
  return filesList.map((item) => item?.id? getPublicDownloadableLink(item?.id) : null)
}


export  { 
  getUrlFromFileObj,
};