import { SmartProposalStatus } from './Status';

const initialState = localStorage.getItem('SmartProposal') ?
  JSON.parse(localStorage.getItem('SmartProposal')) :
  {};

export const SmartProposalReducer = (state = initialState, action) => {
  switch (action.type) {
    case SmartProposalStatus.ERROR:
    case SmartProposalStatus.REQUEST:
      return state;

    case SmartProposalStatus.SUCCESS:
      return action.payload;

    case SmartProposalStatus.RESET:
      return initialState;

    default:
      return state;
  }
};
