import {
  LeadsSalesPermissions,
  UnitsSalesPermissions,
  ActivitiesSalesPermissions,
  ResaleUnitsPermissions 
} from '../../Permissions/Sales';
import { ContactsPermissions, ReportsPermissions } from '../../Permissions/CRM';
import { PropertiesPermissionsCRM } from '../../Permissions/PropertiesPermissions';
import {
  UnitsLeasePermissions,
  LeadsLeasePermissions,
  ActivitiesLeasePermissions,
} from '../../Permissions/Lease';
import {
  LeadsCAllCenterPermissions,
  ActivitiesCallCenterPermissions,
  QACallCenterPermissions,
} from '../../Permissions/CallCenter';
import {
  UserLoginPermissions,
  BusinessGroupsPermissions,
  TeamPermissions,
  RolesPermissions,
  ImageGalleryPermissions,
  LocationsPermissions,
  AdminDashboardPermissions,
  BranchesPermissions,
  SaleAgentDashboardPermissions,
  SaleListingAgentDashboardPermissions,
  SaleListingTeamLeadDashboardPermissions,
  SaleTeamLeadDashboardPermissions,
  LeaseListingAgentDashboardPermissions,
  LeaseTeamLeadDashboardPermissions,
  LeaseListingTeamLeadDashboardPermissions,
  LeaseAgentDashboardPermissions,
  SystemNotificationsPermissions,
  AccountsDashboardPermissions,
  MarketingDashboardPermissions,
  AgentsRotationLogPermissions,
  CallCenterAgentDashboardPermissions,
  CallCenterTeamLeadDashboardPermissions,
  LeadsMortgagesPermissions,
  ActivitiesMortgagesPermissions,
  ClientsSegmentationPermissions,
  ExternalDashboardPermissions,
  ReportBuilderPermissions,
  BranchesDashboardPermissions,
  SalesDirectorDashboardPermissions,
  LeaseDirectorDashboardPermissions,
  ImportDetailsPermissions,
  ZeroMatchingSalePermissions,
  ZeroMatchingLeasePermissions,
  ListingShortagePermissions,
  PolicyPermissions,
  UsersLoginSystemLogPermissions,
  MergeContactLogPermissions,
  LostLeadsLogPermissions,
  ReportBuilderLogPermissions,
  AMLViewPermissions,
  UserAMLViewPermissions,
  LeadOwnerAdminAssignAgentPermissions,
  LeadOwnerAssignAgentPermissions,
  LeadOwnerLeadsPermissions,
  LeadOwnerUnitSalePermissions,
  LeadOwnerUnitLeasePermissions,
  LeadOwnerActivityPermissions,
  ExternalLinksPermissions,
  AdminExternalLinksPermissions,
  LeadOwnerDashboardPermissions,
  ActivatedLeadsAgentDashboardPermissions,
  ActivatedLeadsTeamLeadDashboardPermissions,
  PortalsDashboardPermissions,
  CEODashboardPermissions,
  CommunicationActivitiesLogPermissions,
  CampaignRequestsPermissions,
  CampaignConfigurationPermissions,
  CampaignsPermissions,
  KanbanBoardPermissions,
  PortalsConfigurationPermissions,
  RotationConfigurationPermissions,
  RotationSchemesBranchDashboardPermissions,
  RotationSchemesTeamLeadDashboardPermissions,
  CampaignCostDashboardPermissions,
  LeadOwnerTeamLeadDashboardPermissions,
  TransactionConfigurationPermissions,
  LandmarksPermissions,
  UnqualifiedLeadsCallCenterPermissions,
  LeadOwnerUnqualifiedLeadsPermissions,
  PrimaryUnitsPermissions,
  CoursesPermissions,
  PropertyRatingPermissions,
  AutoCorrespondencePermissions,
  ActiveUserLogPermissions,
  DeactiveUserLogPermission,
  ConvoloRotationPermissions,
  OwnerRotationPermissions,
  ApprovalsConfigurationPermissions,
  InquiryRotationPermissions,
  BranchZeromatchingDashboards,
  TeamZeromatchingDashboards,
  ConsentLogPermissions,
  HubspotContactsPermissions,
  AMLContactOpportunityPermissions,
  LeadOpportunitiesLogsPermissions,
  TemplateShareLogPermissions,
  BranchAMLPermissions,
  AmlDashboardPermissions,
  DevelopersPermissions,
  RiskRatingConfigurationsPermissions,
  UserTrainingInsightsPermissions,
  ProfileAndAchievementstPermissions,
  LearningHubPermissions,
  SalesTransactionsWithAMLPermissions,
  LeaseTransactionsWithAMLPermissions,
  PaymentPlansConfigurationsPermissions,
  LeadPoolConfigurationsPermissions
} from "../../Permissions";
import {
  SalesTransactionsPermissions,
  LeasingTransactionsPermissions,
  InvoicesPermissions,
} from '../../Permissions/Accounts';

import {
  AgentsPermissions,
  RotationSchemaPermissions,
} from '../../Permissions/AgentManagementPermissions';
import {
  LookupsPermissions,
  ActivityTypePermissions,
  TemplatesPermissions,
  FormBuilderPermissions,
} from '../../Permissions/SystemParametersPermissions';

import { ExceptionPermissionsHelper } from '../../Helper/ExceptionPermissions.Helper';

const contactExceptionPermission = ExceptionPermissionsHelper() || false;

export const MainMenu = [
  {
    groupId: 1,
    order: 1,
    description: "",

    icon: "mdi mdi-chart-scatter-plot-hexbin c-blue-lighter",
    iconActive: "mdi mdi-chart-scatter-plot-hexbin c-white",
    isDisabled: false,
    roles: [
      ...Object.values(AdminDashboardPermissions),
      ...Object.values(SaleAgentDashboardPermissions),
      ...Object.values(SaleListingAgentDashboardPermissions),
      ...Object.values(SaleListingTeamLeadDashboardPermissions),
      ...Object.values(SaleTeamLeadDashboardPermissions),
      ...Object.values(LeaseListingAgentDashboardPermissions),
      ...Object.values(LeaseListingTeamLeadDashboardPermissions),
      ...Object.values(LeaseTeamLeadDashboardPermissions),
      ...Object.values(LeaseAgentDashboardPermissions),
      ...Object.values(AccountsDashboardPermissions),
      ...Object.values(MarketingDashboardPermissions),
      ...Object.values(CallCenterAgentDashboardPermissions),
      ...Object.values(CallCenterTeamLeadDashboardPermissions),
      ...Object.values(ExternalDashboardPermissions),
      ...Object.values(BranchesDashboardPermissions),
      ...Object.values(SalesDirectorDashboardPermissions),
      ...Object.values(LeaseDirectorDashboardPermissions),
      ...Object.values(LeadOwnerDashboardPermissions),
      ...Object.values(ActivatedLeadsAgentDashboardPermissions),
      ...Object.values(ActivatedLeadsTeamLeadDashboardPermissions),
      ...Object.values(PortalsDashboardPermissions),
      ...Object.values(CEODashboardPermissions),
      ...Object.values(RotationSchemesBranchDashboardPermissions),
      ...Object.values(RotationSchemesTeamLeadDashboardPermissions),
      ...Object.values(CampaignCostDashboardPermissions),
      ...Object.values(LeadOwnerTeamLeadDashboardPermissions),
    ],
    name: "dashboard",
    newIcon: "icon-dashboard-route",
  },
  {
    groupId: 15,
    order: 2,
    description: "",
    icon: "mdi mdi-headphones c-blue-lighter",
    iconActive: "mdi mdi-headphones c-white",
    isDisabled: false,
    roles: contactExceptionPermission
      ? [...Object.values(PropertiesPermissionsCRM)]
      : [
          ...Object.values(ContactsPermissions),
          ...Object.values(PropertiesPermissionsCRM),
          ...Object.values(DevelopersPermissions),
        ],
    name: "crm",
    newIcon: "icon-crm-route",
  },
  {
    groupId: 6,
    order: 3,
    description: "",
    icon: "mdi mdi-chart-bar-stacked c-blue-lighter",
    iconActive: "mdi mdi-chart-bar-stacked c-white",
    isDisabled: false,
    roles: [
      ...Object.values(UnitsSalesPermissions),
      ...Object.values(LeadsSalesPermissions),
      ...Object.values(ActivitiesSalesPermissions),
      ...Object.values(PrimaryUnitsPermissions),
      ...Object.values(ResaleUnitsPermissions),
    ],
    name: "sales",
    newIcon: "icon-sales-route",
  },
  {
    groupId: 7,
    order: 4,
    description: "",
    icon: "mdi mdi-home-import-outline c-blue-lighter",
    iconActive: "mdi mdi-home-import-outline c-white",
    isDisabled: false,
    roles: [
      ...Object.values(UnitsLeasePermissions),
      ...Object.values(LeadsLeasePermissions),
      ...Object.values(ActivitiesLeasePermissions),
    ],
    name: "leasing",
    newIcon: "icon-leasing-route",
  },
  {
    groupId: 2,
    order: 5,
    description: "",
    icon: "mdi mdi-account-multiple-outline c-blue-lighter",
    iconActive: "mdi mdi-account-multiple-outline c-white",
    isDisabled: false,
    roles: [
      ...Object.values(LeadsCAllCenterPermissions),
      ...Object.values(ActivitiesCallCenterPermissions),
      ...Object.values(QACallCenterPermissions),
      ...Object.values(UnqualifiedLeadsCallCenterPermissions),
    ],
    name: "contact-center",
    newIcon: "icon-contact-center-route",
  },
  {
    groupId: 25,
    order: 6,
    description: "",
    icon: "mdi mdi-currency-usd  c-blue-lighter",
    iconActive: "mdi mdi-currency-usd  c-white",
    isDisabled: false,
    roles: [
      ...Object.values(ActivitiesMortgagesPermissions),
      ...Object.values(LeadsMortgagesPermissions),
    ],
    name: "mortgage",
    newIcon: "icon-mortgage-route",
  },
  {
    groupId: 20,
    order: 7,
    description: "",
    icon: "mdi  mdi-account-network c-blue-lighter",
    iconActive: "mdi  mdi-account-network c-white",
    isDisabled: false,
    roles: [
      ...Object.values(LeadOwnerAdminAssignAgentPermissions),
      ...Object.values(LeadOwnerAssignAgentPermissions),
      ...Object.values(LeadOwnerLeadsPermissions),
      ...Object.values(LeadOwnerUnitSalePermissions),
      ...Object.values(LeadOwnerUnitLeasePermissions),
      ...Object.values(LeadOwnerActivityPermissions),
      ...Object.values(LeadOwnerUnqualifiedLeadsPermissions),
    ],
    name: "leadOwner",
    newIcon: "icon-lead-owner-route",
  },

  {
    groupId: 27,
    order: 8,
    description: "",
    icon: "mdi mdi-help-circle c-blue-lighter",
    iconActive: "mdi mdi-help-circle c-white",
    isDisabled: false,
    roles: [
      ...Object.values(ZeroMatchingSalePermissions),
      ...Object.values(ZeroMatchingLeasePermissions),
      ...Object.values(ListingShortagePermissions),
      ...Object.values(InquiryRotationPermissions),
      ...Object.values(TeamZeromatchingDashboards),
      ...Object.values(BranchZeromatchingDashboards),
    ],
    name: "inquiries",
    newIcon: "icon-inquiries-route",
  },
  {
    groupId: 29,
    order: 9,
    description: "",
    icon: "mdi mdi-briefcase-download c-blue-lighter",
    iconActive: "mdi mdi-briefcase-upload c-white",
    isDisabled: false,
    roles: [
      ...Object.values(AMLViewPermissions),
      ...Object.values(UserAMLViewPermissions),
      ...Object.values(AMLContactOpportunityPermissions),
      ...Object.values(BranchAMLPermissions),
      ...Object.values(AmlDashboardPermissions),
    ],
    name: "AML",
    newIcon: "icon-aml-route",
  },
  {
    groupId: 18,
    order: 10,
    description: "",
    icon: "mdi mdi-account-tie c-blue-lighter",
    iconActive: "mdi mdi-account-tie c-white",
    isDisabled: false,
    roles: [
      ...Object.values(AgentsPermissions),
      ...Object.values(RotationSchemaPermissions),
      ...Object.values(ConvoloRotationPermissions),
      ...Object.values(OwnerRotationPermissions),
    ],
    name: "agent-management",
    newIcon: "icon-agent-management-route",
  },
  {
    groupId: 12,
    order: 11,
    description: "",
    icon: "mdi mdi-book-account-outline c-blue-lighter",
    iconActive: "mdi mdi-book-account-outline c-white",
    isDisabled: false,
    roles: [
      ...Object.values(SalesTransactionsPermissions),
      ...Object.values(LeasingTransactionsPermissions),
      ...Object.values(InvoicesPermissions),
      ...Object.values(SalesTransactionsWithAMLPermissions),
      ...Object.values(LeaseTransactionsWithAMLPermissions),
    ],
    name: "accounts",
    newIcon: "icon-accounts-route",
  },
  {
    groupId: 19,
    order: 12,
    description: "",
    icon: "mdi mdi-google-maps c-blue-lighter",
    iconActive: "mdi mdi-google-maps c-white",
    isDisabled: false,
    roles: [
      ...Object.values(LocationsPermissions),
      ...Object.values(LandmarksPermissions),
    ],
    name: "LocationView:locations",
    newIcon: "icon-locations-route",
  },
  {
    groupId: 17,
    order: 13,
    description: "",
    icon: "mdi mdi-tooltip-image-outline c-blue-lighter",
    iconActive: "mdi mdi-tooltip-image-outline c-white",
    isDisabled: false,
    roles: [...Object.values(ImageGalleryPermissions)],
    name: "gallery",
    newIcon: "icon-images-gallery-route",
  },
  {
    groupId: 16,
    order: 14,
    description: "",
    icon: "mdi mdi-view-list-outline c-blue-lighter",
    iconActive: "mdi mdi-view-list-outline c-white",
    isDisabled: false,
    roles: [
      ...Object.values(LookupsPermissions),
      ...Object.values(ActivityTypePermissions),
      ...Object.values(TemplatesPermissions),
      ...Object.values(FormBuilderPermissions),
    ],
    name: "system-parameters",
    newIcon: "icon-system-parameters-route",
  },
  {
    groupId: 26,
    order: 15,
    description: "",
    icon: "mdi mdi-account-search c-blue-lighter",
    iconActive: "mdi mdi-account-search c-white",
    isDisabled: false,
    roles: [
      ...Object.values(ClientsSegmentationPermissions),
      ...Object.values(CampaignRequestsPermissions),
      ...Object.values(CampaignConfigurationPermissions),
      ...Object.values(CampaignsPermissions),
      ...Object.values(KanbanBoardPermissions),
      ...Object.values(HubspotContactsPermissions),
    ],
    name: "marketing",
    newIcon: "icon-marketing-route",
  },
  {
    groupId: 23,
    order: 16,
    description: "",
    icon: "mdi mdi-clipboard-text c-blue-lighter",
    iconActive: "mdi mdi-clipboard-text c-white",
    isDisabled: false,
    roles: [
      ...Object.values(ReportsPermissions),
      ...Object.values(ReportBuilderPermissions),
    ],
    name: "reports",
    newIcon: "icon-reports-route",
  },
  {
    groupId: 24,
    order: 17,
    description: "",
    icon: "mdi  mdi-format-indent-increase c-blue-lighter",
    iconActive: "mdi  mdi-format-indent-increase c-white",
    isDisabled: false,
    roles: [
      ...Object.values(SystemNotificationsPermissions),
      ...Object.values(ImportDetailsPermissions),
      ...Object.values(AgentsRotationLogPermissions),
      ...Object.values(UsersLoginSystemLogPermissions),
      ...Object.values(MergeContactLogPermissions),
      ...Object.values(ReportBuilderLogPermissions),
      ...Object.values(LostLeadsLogPermissions),
      ...Object.values(CommunicationActivitiesLogPermissions),
      ...Object.values(ActiveUserLogPermissions),
      ...Object.values(DeactiveUserLogPermission),
      ...Object.values(ConsentLogPermissions),
      ...Object.values(LeadOpportunitiesLogsPermissions),
      ...Object.values(TemplateShareLogPermissions),
    ],
    name: "system-log",
    newIcon: "icon-system-logs-route",
  },
  {
    groupId: 30,
    order: 18,
    description: "",
    icon: "mdi mdi-link-variant c-blue-lighter",
    iconActive: "mdi mdi-link-variant c-white",
    isDisabled: false,
    roles: [
      ...Object.values(ExternalLinksPermissions),
      ...Object.values(AdminExternalLinksPermissions),
    ],
    name: "external-links",
    newIcon: "icon-external-links-route",
  },
  {
    groupId: 4,
    order: 19,
    description: "",
    icon: "mdi mdi-cog-outline c-blue-lighter",
    iconActive: "mdi mdi-cog-outline c-white",
    isDisabled: false,
    roles: [
      ...Object.values(UserLoginPermissions),
      ...Object.values(BusinessGroupsPermissions),
      ...Object.values(TeamPermissions),
      ...Object.values(RolesPermissions),
      ...Object.values(BranchesPermissions),
      ...Object.values(PolicyPermissions),
    ],
    name: "system-admin",
    newIcon: "icon-system-admin-route",
  },
  {
    groupId: 31,
    order: 20,
    description: "",
    icon: "mdi mdi-seal c-blue-lighter",
    iconActive: "mdi mdi-seal c-white",
    isDisabled: false,
    roles: [
      ...Object.values(PortalsConfigurationPermissions),
      ...Object.values(RotationConfigurationPermissions),
      ...Object.values(TransactionConfigurationPermissions),
      ...Object.values(PropertyRatingPermissions),
      ...Object.values(AutoCorrespondencePermissions),
      ...Object.values(ApprovalsConfigurationPermissions),
      ...Object.values(RiskRatingConfigurationsPermissions),
      ...Object.values(PaymentPlansConfigurationsPermissions),
      ...Object.values(LeadPoolConfigurationsPermissions),

      
    ],
    name: "system-configuration",
    newIcon: "icon-system-configuration-route",
  },
  {
    groupId: 32,
    order: 21,
    description: "",
    icon: "mdi mdi-lightbulb-on-outline c-blue-lighter",
    iconActive: "mdi mdi-lightbulb-on-outline c-white",
    isDisabled: false,
    roles: [
      ...Object.values(CoursesPermissions),
      ...Object.values(LearningHubPermissions),
      ...Object.values(UserTrainingInsightsPermissions),
      ...Object.values(ProfileAndAchievementstPermissions),
    ],
    name: "l-and-d",
    newIcon: "icon-learning-and-development-route",
  },
  {
    groupId: 33,
    order: 22,
    description: "",
    icon: "mdi mdi-database c-blue-lighter",
    iconActive: "mdi mdi-database c-white",
    isDisabled: false,
    roles: [],
    name: "shared-data",
    newIcon: "icon-system-configuration-route",
  },
];
