import React, { useState, useEffect, useRef } from "react";
import { TextField, Box, Chip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";

import { useSelectedTheme, useOutsideClick } from "../../../Hooks";
import {
  CloseXIcon,
  DotIcon,
  DropdownIcon,
  SearchLG,
  UserOneIcon,
} from "../../../assets/icons";
import PropTypes from "prop-types";
import { getDownloadableLink } from "../../../Helper";
import useStyles from "./styles";

//the avatar variant will be handled when we face one that require this variant, for now we cant handle it yet
const variantIcons = {
  default: null,
  icon: <UserOneIcon />,
  avatar: (avatarIdProp) =>
    avatarIdProp ? (
      <img
        alt="Avatar"
        src={getDownloadableLink(avatarIdProp) || ""}
        style={{
          borderRadius: "50%",
          objectFit: "cover",
        }}
      />
    ) : (
      <UserOneIcon />
    ),
  dot: <DotIcon />,
  search: <SearchLG />,
  tags: <SearchLG />,
};

const CustomChip = ({ index, label, getTagProps, hideDeleteMark, icon }) => {
  const tagProps = getTagProps({ index });
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <div {...tagProps} className={`${styles.customChipWrapper}`}>
      <div className={styles.customChipContent}>
        {icon && <Box className={styles.iconWrapper}>{icon}</Box>}
        <span className={styles.customChipLabel}>{label}</span>
      </div>
      {!hideDeleteMark && tagProps.onDelete && (
        <CloseXIcon
          width="12"
          hight="12"
          fill={palette.foreground.quinary}
          onClick={tagProps.onDelete}
          className={styles.closeXIcon}
        />
      )}
    </div>
  );
};

export default function CustomAutocomplete({
  variant = "default",
  leftIcon = null,
  onChange,
  options = [],
  showDropdownIcon = true,
  showCloseIcon = true,
  onCloseClicked = () => {},
  hasNoBorder = false,
  hideInputValue = false,
  open = false,
  listOfInputs = [],
  placeholder = "Enter value",
  style = {},
  onOpen = () => {},
  getOptionLabel,
  renderOption,
  onInputKeyUp,
  selectedValues,
  isDisabled = false,
  multiple = false,
  renderTags,
  tagValues,
  hideDeleteMark,
  chipsLabel,
  chipsDisabled,
  chipIcon,
  getChipIcon,
  avatarIdProp,
  ...params
}) {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();

  const dropdownRef = useRef(null);
  const iconRef = useRef(null);
  const inputRef = useRef(null);
  const selectedVariantIcon =
    variant === "avatar"
      ? variantIcons.avatar(avatarIdProp)
      : variantIcons[variant] || leftIcon;
  const selectedVariantIconStyles = {
    height: variant === "dot" ? "10" : variant === "avatar" ? "24px" : "20",
    width: variant === "dot" ? "10" : variant === "avatar" ? "24px" : "20",
    borderRadius: variant === "avatar" ? "50%" : "none",
    fill:
      variant === "dot"
        ? palette.foreground.success_secondary
        : palette.foreground.primary_fg,
  };

  const [isOpen, setIsOpen] = useState(open);

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false),
    iconRef,
  });
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  return (
    <Box ref={dropdownRef} style={{ width: "100%" }}>
      <Autocomplete
        id="Autocomplete"
        fullWidth
        disabled={isDisabled}
        multiple={multiple}
        getOptionLabel={getOptionLabel || ((option) => option?.name || "")}
        onOpen={() => {
          setIsOpen(true);
          if (onOpen) onOpen();
        }}
        className={clsx(
          styles.autoCompleteWrapper,
          hasNoBorder ? styles.noPadding : styles.withDefaultPadding,
          {
            [styles.iconVariant]: variant === "icon",
          }
        )}
        options={
          listOfInputs.length > 0
            ? listOfInputs.map((field, index) => ({
                name: `Custom Input ${index + 1}`,
                isDatePicker: true,
                component: field,
              }))
            : options.filter((opt) => opt && typeof opt === "object")
        }
        open={isOpen}
        closeIcon={
          showCloseIcon ? (
            <CloseXIcon
              width="16"
              height="16"
              fill={palette.foreground.quarterary}
              onClick={() => {
                if (onCloseClicked) onCloseClicked();
                setIsOpen(false);
              }}
            />
          ) : null
        }
        popupIcon={
          showDropdownIcon ? (
            <DropdownIcon
              width="20"
              height="20"
              fill={palette.foreground.quarterary}
            />
          ) : null
        }
        onClose={() => {
          if (open) setIsOpen(open);
          else setIsOpen(false);
        }}
        style={style}
        value={selectedValues}
        onChange={onChange}
        onKeyUp={onInputKeyUp}
        //we can add more configuration to the render tags later, this is the start of adding the rest of the variants
        renderTags={(tagValue, getTagProps) => (
          <div className={styles.chipsContainer}>
            {(tagValues || tagValue).map((option, index) => {
              const label = chipsLabel ? chipsLabel(option, index) : option;
              const isDisabled = chipsDisabled
                ? chipsDisabled(option, index)
                : false;

              return (
                <CustomChip
                  key={index}
                  option={option}
                  index={index}
                  label={label}
                  getTagProps={getTagProps}
                  disabled={isDisabled}
                  hideDeleteMark={hideDeleteMark}
                  icon={getChipIcon ? getChipIcon(option) : ""}
                />
              );
            })}
          </div>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            inputRef={inputRef}
            InputProps={{
              ...params.InputProps,
              classes: {
                input: styles.placeholder,
              },
              className: clsx(params.InputProps.className, {
                [styles.selected]: selectedValues !== null,
                [styles.multipleWrapper]: multiple,
                [styles.noBorder]: hasNoBorder,
              }),
              startAdornment: (
                <>
                  {selectedVariantIcon &&
                    React.cloneElement(
                      selectedVariantIcon,
                      selectedVariantIconStyles
                    )}
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
        renderOption={(option) => (
          <Box className={styles.renderOptionsWrapper}>
            {selectedVariantIcon
              ? React.cloneElement(
                  selectedVariantIcon,
                  selectedVariantIconStyles
                )
              : null}
            {renderOption ? (
              renderOption(option)
            ) : (
              <span>
                {option.isDatePicker ? option.component : option.name}
              </span>
            )}
          </Box>
        )}
        classes={
          listOfInputs.length > 0
            ? {
                paper: styles.paper,
                listbox: styles.listBox,
                option: styles.option,
              }
            : {}
        }
        {...params}
      />
    </Box>
  );
}

CustomAutocomplete.propTypes = {
  variant: PropTypes.oneOf([
    "default",
    "icon",
    "avatar",
    "dot",
    "search",
    "tags",
    "custom",
  ]),
  leftIcon: PropTypes.node,
  onChange: PropTypes.func,
  options: PropTypes.array,
  showDropdownIcon: PropTypes.bool,
  showCloseIcon: PropTypes.bool,
  onCloseClicked: PropTypes.func,
  hasNoBorder: PropTypes.bool,
  hideInputValue: PropTypes.bool,
  open: PropTypes.bool,
  listOfInputs: PropTypes.array,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  onOpen: PropTypes.func,
  getOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  onInputKeyUp: PropTypes.func,
  selectedValues: PropTypes.any,
  isDisabled: PropTypes.bool,
  multiple: PropTypes.bool,
  renderTags: PropTypes.func,
  tagValues: PropTypes.instanceOf(Array),
  hideDeleteMark: PropTypes.bool,
  chipsLabel: PropTypes.func,
  chipsDisabled: PropTypes.func,
  getChipIcon: PropTypes.func,
};

CustomAutocomplete.defaultProps = {
  variant: "default",
  leftIcon: null,
  onChange: () => {},
  options: [],
  showDropdownIcon: true,
  showCloseIcon: true,
  onCloseClicked: () => {},
  hasNoBorder: false,
  hideInputValue: false,
  open: false,
  listOfInputs: [],
  placeholder: "Enter value",
  style: {},
  onOpen: () => {},
  getOptionLabel: (option) => option?.name || "",
  renderOption: null,
  onInputKeyUp: () => {},
  selectedValues: null,
  isDisabled: false,
  multiple: false,
  renderTags: undefined,
  tagValues: undefined,
  hideDeleteMark: false,
  chipsLabel: undefined,
  chipsDisabled: () => false,
  getChipIcon: () => {},
};
