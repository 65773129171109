import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tables } from "../../../../../Components";
import { MultiUnitsHeaderData } from "../../Enums/MultiUnitsHeaderData";
import useCheckTableRows from "../../Hooks/useCheckTableRows";

const MultiUnitsDetailsForm = ({
  localFormChangeHandler,
  localFormSelection,
}) => {
  const parentTranslationPath = "SmartProposal";
  const translationPath = "MultiUnitsDetailsForm.";
  const { t } = useTranslation(parentTranslationPath);

  const isFilledCachedData = useRef(false);

  const proposalUnits = useMemo(
    () => localFormSelection?.data?.multiUnitsDetailsData || [],
    [localFormSelection?.data]
  );

  const cachedSelectedUnits = useMemo(
    () => localFormSelection?.selectedUnits || [],
    [localFormSelection?.selectedUnits]
  );
  const {
    selectedRows,
    getIsRowSelected,
    onSelectAllClicked,
    getIsSelectedAll,
    onSelectClicked,
    fillInitialSelected,
  } = useCheckTableRows({tableData: proposalUnits, rowKey: "unitId", maxSelection: 10});
  
  const fillCachedRowsHandler = () => {
    if (!isFilledCachedData.current) {
      fillInitialSelected(cachedSelectedUnits);
      isFilledCachedData.current = true;
    } 
  };

  useEffect(() => {
    fillCachedRowsHandler();
  }, [cachedSelectedUnits]);

  useEffect(() => {
     localFormChangeHandler("edit", {
      ...localFormSelection,
      selectedUnits: selectedRows,
      showTableView: selectedRows?.length > 4,
      showCardsView: selectedRows?.length <= 4,
     });
  }, [selectedRows]);

  return (
    <>
      <Tables
        data={proposalUnits}
        headerData={MultiUnitsHeaderData}
        defaultActions={[]}
        selectAllOptions={{
          selectedRows,
          getIsSelected: getIsRowSelected,
          disabledRows: [],
          withCheckAll: true,
          onSelectAllClicked,
          isSelectAll: getIsSelectedAll(),
          onSelectClicked,
        }}
        activePage={0}
        totalItems={proposalUnits?.length || 150}
        itemsPerPage={proposalUnits?.length || 150}
      />
    </>
  );
};

export default MultiUnitsDetailsForm;
