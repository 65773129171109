/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import i18next from "i18next";
import { ButtonBase, Tooltip } from "@material-ui/core";
import {
  CheckboxesComponent,
  LoadableImageComponant,
  ProgressComponet,
} from "../../../../../Components";
import {
  ActionsEnum,
  ContactTypeEnum,
  LoadableImageEnum,
} from "../../../../../Enums";
import { ReactComponent as Lock} from '../../../../../assets/images/defaults/lockRed.svg' ; 
import { ReactComponent as Unlocked} from '../../../../../assets/images/defaults/unlock-opened-padlock-outline.svg' ; 
import { getDownloadableLink } from "../../../../../Helper";
import { CopyToClipboardComponents } from "../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents";

export const PropertiesCardsComponent = ({
  data,
  activeCard,
  isExpanded,
  onCardClicked,
  onFooterActionsClicked,
  parentTranslationPath,
  translationPath,
  notExpandedMax,
  withCheckbox,
  checkedDetailedCards,
  onCardCheckboxClick,
  selectedDetailsPropertyItem,
  displyOpenFileButton,
}) => {

  const { t } = useTranslation([parentTranslationPath]);
  const getDefaultPropertyImage = useCallback(
    (propertyType) =>
      ContactTypeEnum[propertyType] && ContactTypeEnum[propertyType].defaultImg,
    []
  );
  const getIsSelectedCard = useCallback(
    (itemIndex) =>
      checkedDetailedCards.findIndex((item) => item === itemIndex) !== -1,
    [checkedDetailedCards]
  );

  const getPropertyRating = (item) => {
    if(item && item.propertyRating)
      {
         if(item.propertyRating >= 90 && item.propertyRating <=100 )
          return  { ratingType : 'Platinum' , totalStars : 5 , name : 'Platinum' } ;

          else if(item.propertyRating >= 80 &&  item.propertyRating < 90 )
            return  { ratingType : 'Gold' , totalStars : 4 ,name :  'gold' } ;
          else  if(item.propertyRating >= 70 && item.propertyRating < 80 )
            return  { ratingType : 'Silver ' , totalStars : 3  ,name : 'Silver'  } ;
          else if(item.propertyRating >= 60 &&  item.propertyRating < 70 )
            return  { ratingType : 'Bronze' , totalStars : 2 ,name : 'Bronze'   } ;
      }

      return    { ratingType : 'Bronze' , totalStars : 2 , name :  'Bronze'  } ; 
  };



  return (
    <div className="properties-cards-wrapper childs-wrapper">
      {data.result &&
        data.result.map((item, index) => (
          <div
            className={`properties-card-wrapper ${
              isExpanded ? " is-expanded" : ""
            }${
              (((activeCard && activeCard.id === item.id) ||
                (selectedDetailsPropertyItem &&
                  selectedDetailsPropertyItem.id === item.id)) &&
                " is-open") ||
              ""
            }`}
            key={`propertiesCardItemRef${index + 1}`}
            id={item.id}
          >
            {item && item.isBulkUpload && (
              <Tooltip
                title={
                  (item.isBulkUpload && t(`${translationPath}bulk_upload`)) ||
                  `${t("Shared:not-available")}`
                }
              >
                <div className={`mb-2 mt-4 ribbon blue properties`}>
                  {" "}
                  {t(`${translationPath}bulk_upload`)}
                </div>
              </Tooltip>
            )}
            {item && item.webPublished && (
              <Tooltip
                title={
                  (item.webPublished && t(`${translationPath}publish`)) ||
                  `${t("Shared:not-available")}`
                }
              >
                <div
                  className={`mb-1 mt-4 ribbon blue publish ${
                    !item.isBulkUpload && "publish-only"
                  }`}
                >
                  {" "}
                  {t(`${translationPath}publish`)}
                </div>
              </Tooltip>
            )}
            {withCheckbox && (
              <div className="card-checkbox-wrapper">
                <CheckboxesComponent
                  idRef={`propertiesCheckboxItemRef${index + 1}`}
                  singleChecked={getIsSelectedCard(item.id)}
                  onSelectedCheckboxClicked={(event) => {
                    event.preventDefault();
                    onCardCheckboxClick(index, item);
                  }}
                />
              </div>
            )}

            <a
              onClick={onCardClicked && onCardClicked(item, index)}
              className="cards-wrapper"
            >
              {item?.luxury_project == "Yes" && (
                <div className="lauxary-banner">
                  <p>{t(`${translationPath}LUXURY`)}</p>
                </div>
              )}
              <div className="cards-body-wrapper">
                <div
                  className={`card-body-section mt-3${
                    isExpanded ? " is-expanded" : ""
                  }`}
                >
                  <div
                    className={`properity-card-header ${
                      item.isBulkUpload && item.webPublished
                        ? "isBulkUpload"
                        : item.webPublished
                        ? "mt-2"
                        : "mt-3"
                    }`}
                  >
                    <LoadableImageComponant
                      classes="cover-image"
                      type={LoadableImageEnum.div.key}
                      alt={t(`${translationPath}property-image`)}
                      src={
                        (item.allpropertyImages &&
                          item.allpropertyImages[0] &&
                          getDownloadableLink(
                            item.allpropertyImages[0].fileId,
                            200,
                            true
                          )) ||
                        getDefaultPropertyImage(item.type)
                      }
                    />
                    <LoadableImageComponant
                      classes="cover-image"
                      type={LoadableImageEnum.div.key}
                      alt={t(`${translationPath}property-image`)}
                      src={
                        (item.allpropertyImages &&
                          item.allpropertyImages[1] &&
                          getDownloadableLink(
                            item.allpropertyImages[1].fileId,
                            200,
                            true
                          )) ||
                        getDefaultPropertyImage(item.type)
                      }
                    />
                    <LoadableImageComponant
                      classes="cover-image"
                      type={LoadableImageEnum.div.key}
                      alt={t(`${translationPath}property-image`)}
                      src={
                        (item.allpropertyImages &&
                          item.allpropertyImages[2] &&
                          getDownloadableLink(
                            item.allpropertyImages[2].fileId,
                            200,
                            true
                          )) ||
                        getDefaultPropertyImage(item.type)
                      }
                    />
                  </div>
                  <div className="d-flex flex-h-between w-100">
                    <div className="body-title-wrapper">
                      <span className="body-title">{item.property_name}</span>
                    </div>
                    <div className="id w-50">
                      <span className="details-text">
                        {/* <span className='details-icon mdi mdi-minus mdi-18px' /> */}
                        {t(`${translationPath}ID`)}:
                      </span>
                      <Tooltip title={t(`${translationPath}copy`)}>
                        <CopyToClipboardComponents
                          data={item && item.id}
                          childrenData={item && item.id}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="body-item-flex">
                    <div className="created-on px-2">
                      <span className="details-icon mdi mdi-calendar mdi-16px" />
                      <span>
                        <span className="details-text">
                          {t(`${translationPath}created`)}:
                        </span>
                        <span className="px-1">
                          {(item.createdOn &&
                            moment(item.createdOn)
                              .locale(i18next.language)
                              .format("DD/MM/YYYY")) ||
                            "N/A"}
                        </span>
                      </span>
                    </div>
                    <span>
                      <span
                        className={`card-tooltip card-badge ${
                          item.propertyStatus && item.propertyStatus.class
                        }`}
                      >
                        <span>
                          {(item.propertyStatus &&
                            t(item.propertyStatus.value)) ||
                            item.propertyStatus}
                        </span>
                      </span>
                    </span>
                  </div>
                  {item.details
                    .filter(
                      (filterItem, filterIndex) =>
                        (!isExpanded && filterIndex < notExpandedMax) ||
                        isExpanded
                    )
                    .map((subItem, subIndex) => (
                      <div
                        key={`propertiesCardItemRef${subIndex + 1}`}
                        className="details-item-wrapper"
                      >
                        {/* <span className={`details-icon ${subItem.iconClasses}`} /> */}
                        <span className="details-item">
                          <span className="details-text">
                            {/* <span className='details-icon mdi mdi-minus mdi-18px' /> */}
                            {t(`${translationPath}${subItem.title}`)}:
                          </span>
                          <span className="px-1">{subItem.value}</span>
                        </span>
                      </div>
                    ))}
                  {
                    <div className="details-item-wrapper">
                      <div className="d-flex d-flex-v-center-h-between">
                        <div className="d-flex d-flex-v-center">
                          <span className="details-text">
                            {t(`${translationPath}property-rating`)}:
                          </span>
                          {/* <span className="d-inline-flex d-flex-v-center">
                              <StarBackground/>
                            </span>   */}
                          <span className="px-1">
                            {item.propertyRating || "0"}
                          </span>
                          <div
                            className={`${getPropertyRating(item).ratingType}`}
                          >
                            <span className="px-1 fw-bold">
                              {t(
                                `${translationPath}${
                                  getPropertyRating(item).name
                                }`
                              )}
                            </span>
                          </div>
                        </div>
                        <span className="px-2">
                          <span
                            className={`${
                              item.property_lock && item.property_lock === "Yes"
                                ? "Locked"
                                : "Unlocked"
                            }`}
                          >
                            <span className="d-flex-v-center fw-bold px-1">
                              <span className="px-1"  
                               style={{
                                  color:
                                    item.property_lock &&
                                    item.property_lock === "Yes"
                                      ? "#5b0e01"
                                      : "#1f5125",
                                }}>
                              {t(
                                `${translationPath}${
                                  item.property_lock &&
                                  item.property_lock === "Yes"
                                    ? "locked"
                                    : "unlocked"
                                }`
                              )}
                              </span>
                              
                              <span
                                className="d-flex-v-center"
                                style={{
                                  width:
                                    item.property_lock &&
                                    item.property_lock === "Yes"
                                      ? "18px"
                                      : "16px",
                                  height:  item.property_lock &&
                                  item.property_lock === "Yes"
                                    ? "7px"
                                    : "8px",
                                }}
                              >
                                {item.property_lock &&
                                item.property_lock === "Yes" ? (
                                  <Lock />
                                ) : (
                                  <Unlocked />
                                )}
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="cards-progress-wrapper">
                <ProgressComponet
                  value={item.progress}
                  progressText={`${item.progress}%`}
                  themeClasses="theme-gradient"
                />
              </div>
              <div className="cards-footer-wrapper">
                {displyOpenFileButton && (
                  <ButtonBase
                    className={`btns theme-transparent mx-0 ${
                      displyOpenFileButton ? "w-50 " : "w-100"
                    }`}
                    onClick={onFooterActionsClicked(
                      ActionsEnum.folder.key,
                      item,
                      index
                    )}
                    id="ActionsClickedfolder"
                  >
                    <span className="mdi mdi-folder-outline" />
                    <span className="px-1">{t("open-file")}</span>
                  </ButtonBase>
                )}
              </div>
            </a>
          </div>
        ))}
    </div>
  );
};

PropertiesCardsComponent.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.instanceOf(Array),
    totalCount: PropTypes.number,
  }).isRequired,
  activeCard: PropTypes.instanceOf(Object),
  selectedDetailsPropertyItem: PropTypes.instanceOf(Object),
  isExpanded: PropTypes.bool.isRequired,
  withCheckbox: PropTypes.bool.isRequired,
  onCardClicked: PropTypes.func.isRequired,
  onFooterActionsClicked: PropTypes.func.isRequired,
  onCardCheckboxClick: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  notExpandedMax: PropTypes.number,
  checkedDetailedCards: PropTypes.arrayOf(PropTypes.number).isRequired,
  displyOpenFileButton: PropTypes.bool,
};
PropertiesCardsComponent.defaultProps = {
  notExpandedMax: 5,
  selectedDetailsPropertyItem: undefined,
  activeCard: undefined,
  displyOpenFileButton: false,
};
