import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  RadiosGroupComponent,
  SwitchComponent,
} from "../../../../../Components";
import DragDropPicker from "../../Utilities/DragDropPicker/DragDropPicker";
import { getPublicDownloadableLink } from "../../../../../Helper";
import { ContactDetailsType } from "../../Enums/ContactDetailsType";

const CoverPageForm = ({ localFormChangeHandler, localFormSelection }) => {
  const parentTranslationPath = "SmartProposal";
  const translationPath = "CoverPageForm.";
  const { t } = useTranslation(parentTranslationPath);

  const initializePropertyImages = () => {
    const selectedImageId = localFormSelection?.coverImage?.id;
    const publishedImages = [
      ...(localFormSelection?.data?.propertyPublishedImages || []),
    ];

    const targetIndex = 0;
    const selectedImageItem = publishedImages.find(
      (item) => item.fileId == selectedImageId
    );
    const selectedImageIndex = publishedImages.findIndex(
      (item) => item.fileId == selectedImageId
    );

    if (selectedImageIndex !== -1) {
      const checkedItem = {
        ...selectedImageItem,
        isChecked: true,
      };
      publishedImages.splice(selectedImageIndex, 1);
      publishedImages.splice(targetIndex, 0, checkedItem);
    }
    return publishedImages;
  };

  const propertyPublishedImages = useMemo(
    () => initializePropertyImages() || [],
    [localFormSelection]
  );

  const coverSelectHandler = useCallback((result) => {
    const defaultCoverImage = {
      url: "https://psi-crm.com/ProposalIcons/defaultCover.jpg",
      id: null,
    };

    const pickedImageId =
      result && Array.isArray(result) && result[0] && result[0]?.fileId;
    const coverImageURL = pickedImageId
      ? getPublicDownloadableLink(pickedImageId)
      : null;

    const coverImage = coverImageURL
      ? {
          url: coverImageURL,
          id: pickedImageId,
        }
      : defaultCoverImage;

    localFormChangeHandler("coverImage", coverImage);
  }, [localFormChangeHandler]);

  
  return (
    <>
      <div className="ProposalForm__field_box">
        <div className="ProposalForm__field_label">
          <p>Cover Page</p>
        </div>
        <div>
          <DragDropPicker
            onChange={coverSelectHandler}
            itemsList={propertyPublishedImages}
            maxChecked={1}
          />
        </div>
      </div>
      <span className="field_divider"></span>
      <div className="ProposalForm__field_box">
        <div className="ProposalForm__field_label">
          <p>{t(`${translationPath}Contacted-by`)}</p>
        </div>
        <div>
          <RadiosGroupComponent
            idRef="Contacted-by"
            data={Object.values(ContactDetailsType)}
            value={localFormSelection?.data?.contactDetailsType || null}
            labelInput="label"
            valueInput="key"
            themeClass="theme-propx"
            isDisabled
          />
        </div>
      </div>
      <span className="field_divider"></span>
      <div className="ProposalForm__field_box">
        <div className="ProposalForm__field_label">
          <p>{t(`${translationPath}logo`)}</p>
        </div>
        <div>
          <SwitchComponent
            idRef="showLogoRef"
            themeClass="thick-theme"
            isChecked={localFormSelection?.showLogo || false}
            labelValue={`${localFormSelection?.showLogo ? "show" : "hide"}`}
            parentTranslationPath={parentTranslationPath}
            translationPath={""}
            onChangeHandler={(_, isChecked) =>
              localFormChangeHandler("showLogo", isChecked)
            }
          />
        </div>
      </div>
    </>
  );
};

export default CoverPageForm;
