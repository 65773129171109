import React from "react";
import { CustomAutocomplete } from "../CustomAutocomplete";
import { useTranslate } from "../../../Hooks";

const DataFileAutocompleteComponent = ({
    onChange,
    isWithError,
    onKeyDown,
    onInputKeyUp,
    withoutSearchButton,
    renderFor,
    displayLabel,
    filterOptions,
    data,
    multiple,
    selectedValues,
    isDisabled,
    renderOption
}) => {
    const { translate } = useTranslate("HeaderView");
    return (
        <CustomAutocomplete
            placeholder={translate("Select")}
            multiple={multiple}
            options={data}
            isDisabled={isDisabled}
            onChange={onChange}
            value={selectedValues}
            onInputKeyUp={onInputKeyUp}
            getOptionLabel={displayLabel}
            renderOption={
                (renderFor === "contact" &&
                    ((option) =>
                        (option?.contact && (
                            <div className="d-flex-column">
                                <div className="d-flex-v-center-h-between w-100 texts-truncate">
                                    {(option?.contact?.first_name || option?.contact?.last_name) && (
                                        <span>
                                            {`${option?.contact?.first_name || ""} ${option?.contact?.last_name || ""
                                                }`}
                                        </span>
                                    )}
                                    {option?.contact?.company_name && (
                                        <span>{option?.contact?.company_name}</span>
                                    )}
                                </div>
                                <span className="c-gray-secondary">
                                    {(option?.contact?.contact_type_id === 1 &&
                                        ((option?.contact?.mobile && option?.contact?.mobile?.phone) ||
                                            "")) ||
                                        (option?.contact?.landline_number &&
                                            option?.contact?.landline_number?.phone) ||
                                        ""}
                                </span>
                            </div>
                        )) ||
                        (!option?.contact && option?.name && (
                            <div className="d-flex-column">
                                <div className="d-flex-v-center-h-between w-100 texts-truncate">
                                    <span>
                                        {(option?.first_name || option?.last_name) && (
                                            <span>
                                                {`${option?.first_name || ""} ${option?.last_name || ""}`}
                                            </span>
                                        )}
                                        {option?.company_name && <span>{option?.company_name}</span>}{" "}
                                    </span>
                                </div>
                                <span className="c-gray-secondary">
                                    {(option?.contact_type_id === 1 &&
                                        ((option?.mobile && option?.mobile?.phone) || "")) ||
                                        (option?.landline_number && option?.landline_number?.phone) ||
                                        ""}
                                </span>
                            </div>
                        )) ||
                        "")) ||
                (renderFor === "unit" &&
                    ((option) => (
                        <div className="d-flex-column">
                            <span>{`${option?.unitName} - ${option?.unitType}`}</span>
                            <span className="c-gray-secondary">{`${option?.unitRefNo}`}</span>
                        </div>
                    ))) ||
                (renderFor === "lead" &&
                    ((option) =>
                        (option?.lead && (
                            <div className="d-flex-column">
                                <span>
                                    {`${option?.leadId || ""} - ${option?.lead?.leadClass} - ${(option?.lead?.contact_name &&
                                            option?.lead?.contact_name?.name) ||
                                        (option?.lead?.company_name &&
                                            option?.lead?.company_name?.name) ||
                                        option?.lead?.company_name ||
                                        ""
                                        }`}
                                </span>
                            </div>
                        )) ||
                        "")) ||
                (renderFor === "property" &&
                    ((option) =>
                        (option?.property && (
                            <span>
                                {`${option?.property?.property_name || ""} - ${(option?.property?.city &&
                                        option?.property?.city?.lookupItemName) ||
                                    ""
                                    }`}
                            </span>
                        )) ||
                        "")) ||
                renderOption
            }
        />
    );
};
export default DataFileAutocompleteComponent;
