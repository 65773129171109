import React from "react";
import { Box, Typography, Divider } from "@material-ui/core";
import { useSelectedTheme, useTranslate } from "../../../../../../../../Hooks";
import { CustomCard } from "../../../../../../../../Components";

// styles
import useStyles from "./styles";

// icons
import {
  BathIcon,
  BedIcon,
  UnitSizeIcon,
} from "../../../../../../../../assets/icons";

function UnitsModelsCrad({ unitsModel }) {
  const styles = useStyles();
  const { translate } = useTranslate("NewPropertiesView");
  const {
    theme: { palette },
  } = useSelectedTheme();
  console.log("unitsModel :", unitsModel);

  return (
    <CustomCard
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.warpperUnitsModelsCrad}
    >
      <Box className={styles.headerCard}>
        <Typography className={styles.mainTitle}>
          {unitsModel?.propertyUnitModelName}
        </Typography>
        <Divider className={styles.divider} />
      </Box>
      <Box className={styles.bodyCard}>
        <Typography className={styles.propertyName}>
          {unitsModel?.propertyName}
        </Typography>
        <Box className={styles.detailsUnits}>
          <Box className={styles.containerUnitItem}>
            <UnitSizeIcon
              width="20"
              height="20"
              fill={palette.foreground.quinary}
            />
            <Typography className={styles.unitItemValues}>
              {`${unitsModel?.area} sq.ft` || "N/A"}
            </Typography>
          </Box>
          <Box className={styles.containerUnitItem}>
            <BedIcon width="20" height="20" fill={palette.foreground.quinary} />
            {(unitsModel?.bedroomNo && (
              <Typography className={styles.unitItemValues}>{`${
                unitsModel?.bedroomNo || ""
              }`}</Typography>
            )) ||
              "N/A"}
          </Box>
          <Box className={styles.containerUnitItem || ""}>
            <BathIcon
              width="20"
              height="20"
              fill={palette.foreground.quinary}
            />
            {(unitsModel?.bathroomNo && (
              <Typography
                className={styles.unitItemValues}
              >{`${unitsModel?.bathroomNo}`}</Typography>
            )) ||
              "N/A"}
          </Box>
        </Box>
        <Box className={styles.dataContainer}>
          <Typography className={styles.unitItemTitle}>
            {translate("Selling-price")}
          </Typography>
          <Typography className={styles.unitItemValues}>
            {`${unitsModel?.sellingPriceFrom} - ${unitsModel?.sellingPriceTo} AED`}
          </Typography>
        </Box>
        <Box className={styles.dataContainer}>
          <Typography className={styles.unitItemTitle}>
            {translate("Rent-per-year")}
          </Typography>
          <Typography className={styles.unitItemValues}>
            {`${unitsModel?.rentPerYearFrom} - ${unitsModel?.rentPerYearTo} AED`}
          </Typography>
        </Box>
      </Box>
    </CustomCard>
  );
}
export default UnitsModelsCrad;
