
export const CoverPageStyle = `
.cover-x {
            position: relative;
            width: 2480px;
            height: 1754px;
            background-color: #ffffff;
            background-size: cover;
            background-position: 50% 50%;
        }

        .cover-x .frame {
            display: flex;
            flex-direction: column;
            width: 2480px;
            align-items: flex-start;
            gap: 10px;
            padding: 0px 120px;
            position: absolute;
            top: 120px;
            left: 0;
        }

        .cover-x .div {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 64px;
            position: relative;
            align-self: stretch;
            width: 100%;
            flex: 0 0 auto;
        }

        .cover-x .frame-2 {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            position: relative;
            align-self: stretch;
            width: 100%;
            flex: 0 0 auto;
        }

        .cover-x .frame-3 {
            max-width: 45%;
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            position: relative;
            flex: 0 0 auto;
        }

        .cover-x .frame-4 {
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
            position: relative;
            flex: 0 0 auto;
        }

        .cover-x .text-wrapper {
            position: relative;
            width: fit-content;
            margin-top: -1.00px;
            font-family: "Inter-Medium", Helvetica;
            font-weight: 500;
            color: #000000;
            font-size: 48px;
            letter-spacing: 0;
            line-height: normal;
        }

        .cover-x .text-wrapper-2 {
            position: relative;
            width: fit-content;
            font-family: "Inter-Medium", Helvetica;
            font-weight: 500;
            color: #000000;
            font-size: 96px;
            letter-spacing: 0;
            line-height: normal;
        }

        .cover-x .p {
            position: relative;
            width: fit-content;
            font-family: "Inter-Medium", Helvetica;
            font-weight: 500;
            color: #00000080;
            font-size: 48px;
            letter-spacing: 0;
            line-height: normal;
        }

        .cover-x .frame-5 {
            display: flex;
            align-items: flex-start;
            justify-content: space-around;
            position: relative;
            flex: 1;
            flex-grow: 1;
        }

        .cover-x .frame-6 {
            display: inline-flex;
            align-items: center;
            gap: 16px;
            position: relative;
            flex: 0 0 auto;
        }

        .cover-x .frame-7 {
            display: inline-flex;
            align-items: center;
            gap: 20px;
            position: relative;
            flex: 0 0 auto;
        }

        .cover-x .text {
            position: relative;
            width: fit-content;
            margin-top: -1.00px;
            font-family: "Inter-Medium", Helvetica;
            font-weight: 500;
            color: #00000080;
            font-size: 37px;
            letter-spacing: 0;
            line-height: normal;
        }

        .cover-x .text-2 {
            margin-top: -1.00px;
            font-family: "Inter-Medium", Helvetica;
            font-weight: 500;
            color: #000000;
            font-size: 37px;
            line-height: normal;
            position: relative;
            width: fit-content;
            letter-spacing: 0;
        }

        .cover-x .frame-8 {
            display: inline-flex;
            align-items: center;
            gap: 16px;
            position: relative;
            flex: 0 0 auto;
        }

        .cover-x .frame-9 {
            display: inline-flex;
            align-items: center;
            gap: 24.68px;
            position: relative;
            flex: 0 0 auto;
        }

        .cover-x .img {
            position: relative;
            width: 146.53px;
            height: 146.53px;
            object-fit: cover;

        }

        .cover-x .div-wrapper {
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24.68px;
            position: relative;
            flex: 0 0 auto;
        }

        .cover-x .text-wrapper-3 {
            position: relative;
            max-width: 200px;
            word-break: break-word;
            margin-top: -0.77px;
            font-family: "Inter-Bold", Helvetica;
            font-weight: 700;
            color: #000000;
            font-size: 37.8px;
            letter-spacing: 0;
            line-height: normal;
        }

        .cover-x .rectangle {
            position: relative;
            width: 1980.39px;
            height: 16px;
            background-color: #272963;
            border-radius: 50px 0px 0px 50px;
        }

        .cover-x .frame-wrapper {
            position: absolute;
            width: 2480px;
            height: 925px;
            top: 525px;
            left: 0;
            overflow: hidden;
            background-image: url({{{CoverPage.coverImage.url}}});
            background-size: cover;
            background-position: 50% 50%;
        }

        .cover-x .frame-10 {
            display: flex;
            width: 2480px;
            align-items: center;
            justify-content: space-between;
            padding: 16px 120px;
            position: relative;
            top: 861px;
            background-color: #272963;
        }

        .cover-x .frame-11 {
            display: inline-flex;
            align-items: flex-start;
            gap: 8px;
            position: relative;
            flex: 0 0 auto;
        }

        .cover-x .text-wrapper-4 {
            position: relative;
            width: fit-content;
            margin-top: -1.00px;
            font-family: "Inter-SemiBold", Helvetica;
            font-weight: 600;
            color: #ffffff;
            font-size: 24px;
            letter-spacing: 0;
            line-height: normal;
        }

        .cover-x .frame-12 {
            display: inline-flex;
            align-items: center;
            gap: 48px;
            position: relative;
            flex: 0 0 auto;
        }

        .cover-x .frame-13 {
            display: inline-flex;
            align-items: center;
            gap: 8px;
            position: relative;
            flex: 0 0 auto;
        }

        .cover-x .img-2 {
            position: relative;
            width: 32px;
            height: 32px;
        }

        .cover-x .text-wrapper-5 {
            font-family: "Inter-SemiBold", Helvetica;
            font-weight: 600;
            color: #ffffff;
            font-size: 16px;
            line-height: normal;
            white-space: nowrap;
            position: relative;
            width: fit-content;
            letter-spacing: 0;
        }

        .cover-x .frame-14 {
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 8px;
            position: relative;
            flex: 0 0 auto;
        }

        .cover-x .frame-15 {
            display: inline-flex;
            align-items: center;
            gap: 8px;
            position: relative;
            flex: 0 0 auto;
        }

        .cover-x .frame-16 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0px;
            position: absolute;
            top: 1529px;
            min-width: 2240px;
            width: 100%;
            left: 0;
            padding-inline: 60px;
        }

        .cover-x .avatar-label-group {
            display: flex;
            align-items: center;
            gap: 16px;
            position: relative;
        }

        .cover-x .avatar {
            position: relative;
            width: 100px;
            height: 100px;
            border-radius: 13512.16px;
            background-image: url({{{CoverPage.data.contactDetails.avatarImage}}});
            background-position: center;
            background-repeat: no-repeat;
            background-size: 70%;
            {{^CoverPage.data.contactDetails.avatarImage}}}
               background-color: rgba(0, 0, 0, 0.10);
            {{/CoverPage.data.contactDetails.avatarImage}}}
        }

        .cover-x .contrast-border {
            width: 100px;
            height: 100px;
            border-radius: 13512.16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 40px;
            font-weight: 600;
            color: #344054;
        }

        .cover-x .text-and-supporting {
            display: inline-flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            flex: 0 0 auto;
            margin-right: -0.17px;
            gap: 16px;
        }

        .cover-x .text-3 {
            margin-top: -1.79px;
            font-family: "Inter", Helvetica;
            font-weight: 600;
            color: rgba(45, 62, 80, 1);
            font-size: 32px;
            line-height: 28px;
            position: relative;
            width: fit-content;
            letter-spacing: 0px;
            font-style: normal;
        }

        .cover-x .supporting-text {
            position: relative;
            width: fit-content;
            font-family: "Inter", Helvetica;
            font-weight: 400;
            color: #272963;
            font-size: 28px;
            letter-spacing: 0px;
            line-height: 24px;
            font-style: normal;
        }

        .cover-x .frame-17 {
            align-items: flex-start;
            gap: 20px;
            position: relative;
            flex: 0 0 auto;
            flex-wrap: wrap;
            display: flex;
            max-width: 1720px;
            justify-content: center
        }

        .cover-x .text-and-icon {
            display: inline-flex;
            align-items: center;
            gap: 16.67px;
            padding: 4.17px;
            position: relative;
            flex: 0 0 auto;
            background-color: #eaecf0cc;
            border-radius: 25px;
        }

        .cover-x .img-3 {
            position: relative;
            width: 41.67px;
            height: 41.67px;
        }

        .cover-x .text-wrapper-6 {
            font-family: "DM Sans-Regular", Helvetica;
            font-weight: 400;
            color: rgba(45, 62, 80, 1);
            font-size: 32px;
            text-align: center;
            line-height: normal;
            position: relative;
            width: fit-content;
            letter-spacing: 0;
        }

        .cover-x .text-4 {
            font-family: "Cormorant-Regular", Helvetica;
            font-weight: 400;
            color: #272963;
            font-size: 36px;
            text-align: center;
            line-height: normal;
            position: relative;
            width: fit-content;
            letter-spacing: 0;
        }

        .cover-x .img-wrapper {
            display: inline-flex;
            align-items: center;
            gap: 16.67px;
            padding: 4.17px;
            position: relative;
            flex: 0 0 auto;
            background-color: #eaecf0cc;
            border-radius: 41.67px;
        }

        .cover-x .logo {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            position: relative;
            flex: 0 0 auto;
        }

        .cover-x .logo-psi {
            position: relative;
            width: 100.46px;
            height: 90px;
        }

  
`