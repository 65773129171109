import React from "react";
import PropTypes from "prop-types";
import './MessageConfirmDialog.scss';
import { useTranslation } from "react-i18next";
import { DialogActions, DialogContent, ButtonBase } from "@material-ui/core";
import { DialogComponent } from "../../Components";

export const MessageConfirmDialog = ({
  isDialogOpen,
  wrapperClasses,
  theme,
  cancelButton,
  confirmButton,
  content,
  allowContentCopy,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <DialogComponent
      isOpen={isDialogOpen}
      maxWidth="sm"
      wrapperClasses={`message-confirm-dialog ${wrapperClasses}`}
      dialogTitle={<></>}
      dialogContent={
        <div className="dialog-body propx-view d-flex-column gap-32 p-3P5">
          <DialogContent>
            <div className="dialog-content gap-16">
              <div className={`dialog-icon ${theme}`}></div>
              <div>
                <div className="d-flex-v-center-h-between">
                  <div className="fw-simi-bold fz-18 mb-2">
                    {t(`${translationPath}${content.heading}`)}
                  </div>
                  <span
                    className="icons b-icon i-24 i-x-close-dialog pointer"
                    onClick={cancelButton.handler}
                  ></span>
                </div>
                <p
                  className={`content-body ${
                    allowContentCopy ? "allow-select-text" : ""
                  }`}
                >
                  {" "}
                  {content?.body && typeof content?.body !== "string"
                    ? content.body
                    : (content?.body && t(`${content.body}`)) || ""}
                </p>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="d-flex-center fj-end">
              <ButtonBase
                onClick={cancelButton.handler}
                className="btns theme-propx outlined m-0 mr-2"
              >
                {t(`${translationPath}${cancelButton.label}`)}
              </ButtonBase>

              {confirmButton.handler && (
                <ButtonBase
                  className={`btns theme-propx solid m-0 ml-1 ${theme}`}
                  onClick={confirmButton.handler}
                >
                  {t(`${translationPath}${confirmButton.label}`)}
                </ButtonBase>
              )}
            </div>
          </DialogActions>
        </div>
      }
    />
  );
};

MessageConfirmDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf(['alert', 'warning']),
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }),
  confirmButton: PropTypes.shape({
    label: PropTypes.string,
    handler: PropTypes.func.isRequired,
  }),
  cancelButton: PropTypes.shape({
    label: PropTypes.string,
    handler: PropTypes.func.isRequired,
  }),
  wrapperClasses: PropTypes.string,
  allowContentCopy: PropTypes.bool,
  parentTranslationPath: PropTypes.string,
  translationPath: PropTypes.string,
};
MessageConfirmDialog.defaultProps = {
  theme: 'alert',
  content: {
    body: '',
  },
  confirmButton: {
    label: "confirm",
  },
  cancelButton: {
    label: "cancel",
  },
  allowContentCopy : false,
  parentTranslationPath: '',
  translationPath: '',
};
