import React, { useCallback, useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AMLLogsHeaderData } from "./AMLLogsHeaderData";
import { Tables } from "../../../Components";
import { AMLTransactionStatusEnum, TableFilterTypesEnum } from "../../../Enums";

export const AMLogsTableView = ({
  parentTranslationPath,
  translationPath,
  setFilter,
  filter,
  isLoading,
  allAMLLogsData,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [activeItem, setActiveItem] = useState(null);

  const AMLTransactionStatusList = useMemo(
    () => [
      ...Object.values(AMLTransactionStatusEnum).filter(
        (s) => s.key !== 1 && s.key !== 2
      ),
    ],
    []
  );

  const tableActionClicked = useCallback(
    (actionEnum, item, focusedRow, event) => {
      event.stopPropagation();
      event.preventDefault();
      setActiveItem(item);
    },
    []
  );

  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };

  const repeated = (list, filed) =>
    list &&
    list.map((item, index) => (
      <span key={index}>
        {" "}
        {item[filed]} {list.length - 1 != index && <span> , </span>}
      </span>
    ));

  const getActionTableWithPermissions = () => {
    const list = [];
    return list;
  };


  return (
    <div className="d-flex-column mt-2">
      <div className="w-100 px-3">
        <Tables
          data={allAMLLogsData.result || []}
          headerData={AMLLogsHeaderData(t, AMLTransactionStatusList)}
          defaultActions={getActionTableWithPermissions()}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          actionsOptions={{
            onActionClicked: tableActionClicked,
          }}
          itemsPerPage={filter.pageSize}
          activePage={filter.pageIndex}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          totalItems={allAMLLogsData.totalCount}
        />
      </div>
    </div>
  );
};
