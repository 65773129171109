import React from "react";
import { Box } from "@material-ui/core";

// styles
import useStyles from "./styles";

function LocationComponent({  }) {
    const styles=useStyles()
    return(
        // <Box>LocationComponent</Box>
        <></>
    )
}
export default LocationComponent