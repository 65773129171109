import React from "react";


//  styles
import useStyles from './styles'

function FinanceCompany({}) {
    const styles =useStyles()

    return(
        <>FinanceCompany</>
    )
}

export default FinanceCompany