import coverDetailsDtoMapper from "./coverPageDto.js";
import proposalDetailsMapper from "./proposalDetailsDto.js";

const dtoMapper = (data) => {

  const { proposalType, checkedProperties, receivers } = data;
  const propertiesList = checkedProperties? Object.values(checkedProperties).filter(item=> item) : [];

  const { coverImage, coverPageDetails } = coverDetailsDtoMapper(propertiesList);
  const proposalDetails = proposalDetailsMapper(propertiesList);

  return {
    proposalType,
    coverImage,
    coverPageDetails,
    proposalDetails,
    receivers,
  };
};

export default dtoMapper;
