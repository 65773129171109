import React from "react";


//  styles
import useStyles from './styles'

function Marketing({}) {
    const styles =useStyles()

    return(
        <>Marketing</>
    )
}

export default Marketing