import React, { useCallback, useEffect, useState, useReducer } from "react";
import "./UnitMPIGaugeChartComponent";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GetMpiGaugeChartDataAPI } from "../../../../../../Services";
import { GaugeChart } from "./GaugeChart";

export const UnitMPIGaugeChartComponent = ({
  unitId,
  marketComparison,
  activeData,
  operationType,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, "Shared");
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [isLoading, setIsLoading] = useState(false);

  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };
  const [defaultState, setDefaultState] = useState({
    minPrice: null,
    maxPrice: null,
    medianPrice: null,
    psiPrice: null,
    noMPIDataFoundForTheUnit: null,
  });
  
  console.log('defaultState: ', defaultState);
  const [state, setState] = useReducer(reducer, {
    ...defaultState,
  });

  const GetUnitMpiGaugeChartInfo = useCallback(async () => {
    setIsLoading(true);
    const rs = await GetMpiGaugeChartDataAPI({
      unitId: unitId,
      operationType: operationType,
    });
    if (!(rs && rs.status && rs.status !== 200)) {
      if (rs)
        setState({
          id: "edit",
          value: { ...rs, noMPIDataFoundForTheUnit: null },
        });
    } else if (rs && rs.status && rs.status === 400) {
      setState({ id: "noMPIDataFoundForTheUnit", value: rs?.data });
    } else setState({ id: "edit", value: defaultState });

    setIsLoading(false);
  });

  useEffect(() => {
    if (unitId !== undefined && operationType !== undefined)
      GetUnitMpiGaugeChartInfo();
  }, [unitId, operationType]);

  return (
    <div className="w-100 mb-3">
      {state?.noMPIDataFoundForTheUnit ||
      state?.psiPrice === "" ||
      !state?.medianPrice ||
      !state?.maxPrice ? (
        <div className="px-2">
          <span className="px-2 fw-bold">{t(`${translationPath}mpi`)}</span>
          <span className="fw-bold c-primary">
            {t(`${translationPath}no-mpi-data-found-for-the-unit`)}
          </span>
        </div>
      ) : (
        <div className="d-flex GaugeChart ">
          <GaugeChart
            activeData={activeData}
            minPrice={state?.minPrice || 0}
            maxPrice={state?.maxPrice}
            medianPrice={state?.medianPrice || 0}
            marketComparison={marketComparison}
            psiPrice={state?.psiPrice || 0}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        </div>
      )}
    </div>
  );
};
