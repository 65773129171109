import React, { useMemo, useState } from 'react';
import {
  DropdownIconWrapper,
  StyledButton,
  StyledMenu,
  StyledMenuItem,
} from './style';

import './styles.scss';

const RadioItem = ({ value, selected, onClick }) => (
  <span
    className={`radio-items-filter ${selected ? 'selected-share-unit' : ''}`}
    onClick={onClick}
  >
    {value}
  </span>
);

export default function BedroomsFilter({
  setSelected,
  selected,
  bedroomsCriteriaChangeHandler,
  t,
}) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const values = useMemo(() => Array.from({ length: 7 }, (_, i) => i + 1), []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = (event) => {
    if (anchorEl && anchorEl.contains(event?.target)) {
      return;
    }
    setAnchorEl(null);
    setOpen(false);
  };

  const handleSelection = async (value) => {
    const bedroomKeys = { from: 'fromBedroom', to: 'toBedroom' };

    let updatedValue;
    if (value === 7) {
      updatedValue = { from: value, to: 10000 };
    } else if (value < selected?.bedrooms?.from || value === null) {
      updatedValue = { from: value, to: value };
    } else if (selected?.bedrooms?.from) {
      updatedValue = { from: selected.bedrooms.from, to: value };
    } else {
      updatedValue = { from: value, to: value };
    }

    bedroomsCriteriaChangeHandler(bedroomKeys, updatedValue);

    setSelected({
      id: 'bedrooms',
      value: updatedValue,
    });
  };
  return (
    <>
      <StyledButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='outlined'
        color='primary'
        onClick={handleClick}
      >
        <span className='share-unit-placeholder'>{t('bedrooms')}</span>
        <DropdownIconWrapper
          className={`mdi ${open ? 'mdi-chevron-up' : 'mdi-chevron-down'}`}
        />
      </StyledButton>

      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className='share-unit-filter-wrapper'>
          <legend className='share-unit-filter-title'>{t('bedrooms')}</legend>
          <div className='share-unit-value-row'>
            {/* <StyledMenuItem onClick={() => handleSelection(null)}>
              <RadioItem
                value={'Any'}
                selected={
                  selected.bedrooms?.from === null ||
                  selected.bedrooms?.to === null
                }
              />
            </StyledMenuItem> */}
            {values.map((i) => (
              <StyledMenuItem
                key={`bedroom-${i}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelection(i);
                }}
              >
                <RadioItem
                  value={i === 7 ? '+7' : i}
                  selected={
                    selected.bedrooms?.from <= i && selected.bedrooms?.to >= i
                  }
                />
              </StyledMenuItem>
            ))}
          </div>
        </div>
      </StyledMenu>
    </>
  );
}
