export const UnitImagesStyle = `

/*CSS GENERETED BY ANIMA PPLUGIN */

:root {
--bg-primary-light : linear-gradient(180deg, #F4F4F4 0%, #EDEDED 100%);
--bg-primary-dark : #272963;
--text-primary-light : #2D3E50;
--text-primary-dark : #ffffff;
}


[data-mode="light"] {
  --bg-primary: var(--bg-primary-light);
  --text-primary: var(--text-primary-light);
}

[data-mode="dark"] {
  --bg-primary: var(--bg-primary-dark);
  --text-primary: var(--text-primary-dark);
}

.unitImages_wrapper {
    display: flex;
    flex-direction: column;
    background: var(--bg-primary);
    min-height: 100vh;
}

.unitImages_wrapper.reverese {
  background-repeat: repeat-y;
}

[data-isMultiImage="true"] {
  display: flex !important;
  flex-direction: column;
  flex-grow: 1;
  gap: 50px;
}

.unitImages_section {
    display: flex;
    flex-direction: column;
    gap: 50px;
    background: url(https://psi-crm.com/ProposalIcons/images-pattren.png);
    background-repeat: repeat-y;
    padding: 120px;
    background-size: 50%;
    background-position-x: right;
}

.unitImages_section.reverse {
  background-position-x: left !important;
}

.title {
  color: var(--text-primary);
  font-size: 72px;
  font-weight: 700;
  line-height: normal;
  flex: 0 1;
}

.images-container {
  display: flex;
  height: 1350px;
  width: 100%;
  margin: auto;
  gap: 50px;
}

.images-sidebar {
  flex-grow: 1;
  min-height: 100%;
}

.images-content {
  display: none;
}

.images-content-section {
  flex-grow: 1;
}

.images-sidebar, .images-content-section-1, .images-content-section-2 {
  border-radius: 50px;
}
  
.select_all_container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: 1350px;
  width: 100%;
  margin: auto;
  gap: 50px;
}

.interior_section [data-isMultiImage="true"]{
{{^UnitImages.interiorImages.2.url}}
  flex-direction: row !important;
{{/UnitImages.interiorImages.2.url}}
}

`
