import { config } from "../../config";
import { HttpServices } from "../../Helper";

export const HubSpotIntegration = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/HubSpotIntegration/GetHubSpotContacts`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const ContactsBatchUpload = async (body) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/HubSpotIntegration/ContactsBatchUpload`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const GetAllHubSpotListsAPI = async () => {
  const result = await HttpServices.get(`${config.server_address}/CrmDfm/HubSpotIntegration/GetAllHubSpotLists`)
    .then((data) => data)
    .catch((error) => error.response);
  return result;
};

export const CreateHubSpotListAPI = async (listName) => {
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/HubSpotIntegration/CreateHubSpotList?listName=${listName}`
  )
    .then((data) => data)
    .catch((error) => error.response.data);
  return result;
};

export const ImportContactsAPI = async ({listId ,pageIndex , pageSize },body) => {
  const queryList = [];
  if (listId) queryList.push(`listId=${listId}`);
  if (pageIndex) queryList.push(`pageIndex=${pageIndex}`);
  if (pageSize) queryList.push(`pageSize=${pageSize}`);
  const result = await HttpServices.post(
    `${config.server_address}/CrmDfm/HubSpotIntegration/ImportContacts?${queryList.join('&')}`,
    body
  )
    .then((data) => data)
    .catch((error) => error.response.data);
  return result;
};
