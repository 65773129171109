import { useCallback, useState } from "react";

const useCheckTableRows = ({tableData, rowKey, maxSelection}) => {

  const [selectedRows, setSelectedRows] = useState([]);

  const getIsRowSelected = useCallback(
    (row) => selectedRows.some((item) => item[rowKey] === row[rowKey]),
    [selectedRows]
  );

  const isSelectEnabled = () => !(maxSelection && selectedRows?.length >= maxSelection);
  const isSelectAllEnabled = () => tableData?.length <= maxSelection;
  
  const onSelectClicked = useCallback(
    (row) => {
      if (getIsRowSelected(row))
        setSelectedRows((prevSelectedRows) =>
          prevSelectedRows.filter((item) => item[rowKey] !== row[rowKey])
        );
      else if(isSelectEnabled()) setSelectedRows((prevSelectedRows) => [...prevSelectedRows, row]);
    },
    [getIsRowSelected]
  );

  const getIsSelectedAll = useCallback(
    () =>
      tableData.length > 0 &&
      tableData.every((unit) => selectedRows.some((row) => row[rowKey] === unit[rowKey])),
    [tableData, selectedRows]
  );

  const fillInitialSelected = useCallback(
    (initialSelected) =>
      initialSelected.length > 0 ?
     setSelectedRows(() => [...(initialSelected || [])]) : null,
    [tableData, selectedRows]
  );

  const onSelectAllClicked = useCallback(() => {
      if (getIsSelectedAll()) setSelectedRows([]);
      else if(isSelectAllEnabled()) setSelectedRows([...tableData]);
    
  }, [getIsSelectedAll, tableData]);


    return {
        selectedRows,
        getIsRowSelected,
        onSelectAllClicked,
        getIsSelectedAll,
        onSelectClicked,
        fillInitialSelected
      };
}

export default useCheckTableRows;