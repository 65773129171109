import moment from "moment";

const getOrdinal = moment.localeData().ordinal;

const formatInstallmentSchedule = (item) => {
  if (!item) return "-";

  const {
    frequencyName,
    instalmentDate,
    isBasedOnBookingDate,
    amountPercentage,
    installmentNumber,
  } = item;

  if (frequencyName === "Date")
    return moment(instalmentDate).format("DD/MMM/YYYY");

  if (isBasedOnBookingDate)
    return `Downpayment + ${instalmentDate} Month${
      Number(instalmentDate) > 1 ? "s" : ""
    }`;

  if (amountPercentage && instalmentDate && frequencyName) {
    const percentageText = `${amountPercentage * 100}% Every`;
    const frequencyNameText = `${instalmentDate} ${frequencyName}${
      Number(instalmentDate) > 1 ? "s" : ""
    }`;
    const installmentNumberText = installmentNumber
      ? `for ${installmentNumber} Installment${
          installmentNumber > 1 ? "s" : ""
        }`
      : "";

    return `${percentageText} ${frequencyNameText} ${installmentNumberText}`.trim();
  }

  return "-";
};

const formatInstallmentsList = (installments) =>
  installments.map((installment, index) => ({
    paymentBreakdown: getOrdinal(index + 1),
    installmentSchedule: formatInstallmentSchedule(installment),
    installmentTypeName: installment.installmentTypeName,
    amountPercentage: installment.amountPercentage
      ? `${installment.amountPercentage * 100}%`
      : "0%",
  }));

const formatPaymentPlanData = (data) => {
  let formattedPlans = [];
  if (Array.isArray(data?.propertyPaymentPlans)) {
    const dataClone = [...data?.propertyPaymentPlans];
    formattedPlans = dataClone.map((plans) => ({
      propertyPaymentPlanId: plans.propertyPaymentPlanId,
      paymentPlanName: plans.paymentPlanName,
      propertyPlanInstallments: formatInstallmentsList(plans.propertyPlanInstallments),
    }));
  }

  return ({
    propertyPaymentPlans: formattedPlans
  });
};

export default formatPaymentPlanData;