import moment from 'moment';
import { ContactTypeEnum, PropertyStatusEnum } from '../../../../../Enums';

export const PropertiesMapper = (item, property) => {
    if (property) {
        return {
          ...property,
          updatedBy: item.updatedBy,
          isBulkUpload: item.isBulkUpload,
          id: item.propertyId,
          Ids: item.propertyId,
          createdBy: item.createdBy,
          property_lock:
            property && property?.property_lock ? property.property_lock : "No",
          propertyRating:
            (item.propertyRating &&
              parseFloat(item.propertyRating.toFixed(2))) ||
            0,
          webPublishedDate: item.webPublishedDate,
          webPublished: item.webPublished,
          property_name: property.property_name || "",
          name: property.property_name || "",
          updateDate:
            (moment(item && item.updateOn).isValid() && item.updateOn) || null,
          allpropertyImages: item.propertyImages || "N/A",
          type: ContactTypeEnum.corporate.value || "N/A",
          propertyTypeId:
            (property.property_type && property.property_type.lookupItemId) ||
            "N/A",
          propertyType: property.property_type
            ? property.property_type.lookupItemName
            : "N/A",
          progress:
            property.data_completed &&
            typeof property.data_completed === "string" &&
            property.data_completed.includes("%")
              ? +property.data_completed.substr(
                  0,
                  property.data_completed.length - 1
                )
              : +property.data_completed,

          progressWithPercentage:
            property.data_completed &&
            typeof property.data_completed !== "string"
              ? `${property.data_completed}%`
              : property.data_completed,

          propertyStatus:
            (property.property_plan &&
              PropertyStatusEnum[property?.property_plan?.lookupItemName]) ||
            property?.property_plan?.lookupItemName ||
            "N/A",

          property_owner:
            (property.property_owner && property.property_owner.name) || "N/A",
          // city: property.city && property.city.lookupItemName || 'N/A',
          // community: property.community && property.community.lookupItemName || 'N/A',
          property_usage:
            (property.property_usage &&
              property.property_usage.lookupItemName) ||
            "N/A",
          createdOn: (item && item.createdOn) || "N/A",
          map:
            (property.map &&
              property.map.latitude &&
              property.map.longitude &&
              `${property.map.latitude} - ${property.map.longitude}`) ||
            "",
          launch_date:
            (moment(property.launch_date).isValid() && property.launch_date) ||
            null,
          completion_date:
            (moment(property.completion_date).isValid() &&
              property.completion_date) ||
            null,
          construction_date:
            (moment(property.construction_date).isValid() &&
              property.construction_date) ||
            null,
          handover_date:
            (moment(property.handover_date).isValid() &&
              property.handover_date) ||
            null,
          referred_date:
            (moment(property.referred_date).isValid() &&
              property.referred_date) ||
            null,
          amenities:
            property.amenities && property.amenities.length > 0
              ? property.amenities.map((e) => `${e && e.lookupItemName}, `)
              : [],
          facilities:
            property.facilities && property.facilities.length > 0
              ? property.facilities.map((e) => `${e && e.lookupItemName}, `)
              : [],
          unit_types_in_property:
            property.unit_types_in_property &&
            property.unit_types_in_property.length > 0
              ? property.unit_types_in_property.map(
                  (e) => `${e && e.lookupItemName}, `
                )
              : [],

          details: [
            {
              iconClasses: "mdi mdi-account-circle",
              title: "property-owner",
              value: property.property_owner
                ? property.property_owner.name
                : "N/A",
            },
            {
              iconClasses: "mdi mdi-domain",
              title: "property_type",
              value: property.property_type
                ? property.property_type.lookupItemName
                : "N/A",
            },
            {
              iconClasses: "mdi mdi-point-of-sale",
              title: "city",
              value: property.city ? property.city.lookupItemName : "N/A",
            },
            {
              iconClasses: "mdi mdi-domain",
              title: "community",
              value: property.community
                ? property.community.lookupItemName
                : "N/A",
            },
            {
              iconClasses: "mdi mdi-domain",
              title: "property-usage",
              value: property.property_usage
                ? property.property_usage.lookupItemName
                : "N/A",
            },
            {
              iconClasses: "mdi mdi-domain",
              title: "district",
              value: property.district
                ? property.district.lookupItemName
                : "N/A",
            },
            {
              iconClasses: "mdi mdi-domain",
              title: "number-of-floors",
              value: property.number_of_floors
                ? property.number_of_floors
                : "N/A",
            },
            {
              iconClasses: "mdi mdi-domain",
              title: "builtup-area",
              value: property.builtup_area_sqft
                ? property.builtup_area_sqft
                : "N/A",
            },
            {
              iconClasses: "mdi mdi-domain",
              title: "completion-date",
              value: property.completion_date
                ? moment(property.completion_date).format("DD/MM/YYYY")
                : "N/A",
            },
            {
              iconClasses: "mdi mdi-domain",
              title: "master-developer",
              value: property.master_developer
                ? property.master_developer.name
                : "N/A",
            },
          ],
        };
    }
};
