import React from "react";
import  ButtonBase  from "@material-ui/core/ButtonBase";
import { useTranslation } from "react-i18next";
import { SwitchComponent } from "../../../../../Components";
import useIsLoading from "../../Hooks/useIsLoading";

const ProposalFormButtons = ({isLastStep, isLastProperty, isPageToggleChecked, handler}) => {
  const parentTranslationPath = "SmartProposal";
  const translationPath = "";
  const { t } = useTranslation(parentTranslationPath);

  const { isLoading } = useIsLoading();
  
  return (
    <div className="d-flex-default h-fit fj-end py-0 pb-3 w-25">
      { handler.isPageHasToggle()?
        <SwitchComponent
          idRef="showMapRef"
          themeClass="thick-theme"
          isChecked={isPageToggleChecked}
          labelValue={"show-page"}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onChangeHandler={(_, isChecked) => handler.showPageHandler(isChecked)}
        /> : 
        null
      }
      <ButtonBase
        onClick={handler.cancelHandler}
        className="btns theme-propx outlined"
      >
        {t(`${translationPath}cancel`)}
      </ButtonBase>
      {isLastStep && !isLastProperty && (
        <ButtonBase
          className="btns theme-propx solid mr-0"
          onClick={handler.submitHandler}
          disabled={isLoading}
        >
          {t(`${translationPath}submit`)}
        </ButtonBase>
      )}
      {!isLastStep && (
        <ButtonBase
          className="btns theme-propx solid mr-0"
          onClick={handler.nextHandler}
          disabled={isLoading}
        >
          {t(`${translationPath}next`)}
        </ButtonBase>
      )}
      {isLastStep && isLastProperty && (
        <ButtonBase
          className="btns theme-propx solid mr-0"
          onClick={handler.finishHandler}
          disabled={isLoading}
        >
          {t(`${translationPath}finish`)}
        </ButtonBase>
      )}
    </div>
  );
};

export default ProposalFormButtons;
