import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import { CustomCard } from "../../../../../../../../Components";
import { useTranslate } from "../../../../../../../../Hooks";

// styles
import useStyles from "./styles";

function PropertyDetailsComponent({ propretyResponseJson }) {
  const styles = useStyles();
  const { translate } = useTranslate("NewPropertiesView");
  return (
    <CustomCard
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.wrapperPropretyDetails}
    >
      <Box className={styles.containerHeaderDetails}>
        <Typography className={styles.ItemTitleHeader}>{translate("Property-Details")}</Typography>
        <Divider className={styles.divider} />
      </Box>
      <Box className={styles.containerInfoDetails}>
        <Box className={styles.containerDataItem}>
          <Typography className={styles.titleItem}>
            {translate("Service-Charge")}
          </Typography>
          <Typography className={styles.valueItem}>
            {propretyResponseJson?.property?.service_charge}
          </Typography>
        </Box>
        <Box className={styles.containerDataItem}>
          <Typography className={styles.titleItem}>
            {translate("Number-of-Floors")}
          </Typography>
          <Typography className={styles.valueItem}>
            {propretyResponseJson?.property?.number_of_floors}
          </Typography>
        </Box>
        <Box className={styles.containerDataItem}>
          <Typography className={styles.titleItem}>
            {translate("Property-Usage")}
          </Typography>
          <Typography className={styles.valueItem}>
            {propretyResponseJson?.property?.property_usage?.lookupItemName}
          </Typography>
        </Box>
        <Box className={styles.containerDataItem}>
          <Typography className={styles.titleItemExpiryDays}>
            {translate("Reservation-Expiry-Days")}
          </Typography>
          <Typography className={styles.valueItemExpiryDays}>
            {propretyResponseJson?.property?.reservation_expiry_days}
          </Typography>
        </Box>
        <Box className={styles.containerDataItem}>
          <Typography className={styles.titleItem}>
            {translate("Secondary-Developer")}
          </Typography>
          <Typography className={styles.valueItem}>
            {propretyResponseJson?.property?.secondary_developer?.name}
          </Typography>
        </Box>
        <Box className={styles.containerDataItem}>
          <Typography className={styles.titleItem}>
            {translate("Assigned-Real-Estate-Agent")}
          </Typography>
          <Typography className={styles.valueItem}>
            {propretyResponseJson?.property?.assigned_real_estate_agent?.name}
          </Typography>
        </Box>
        <Box className={styles.containerDataItem}>
          <Typography className={styles.titleItem}>
            {translate("Master-Developer")}
          </Typography>
          <Typography className={styles.valueItem}>
            {propretyResponseJson?.property?.master_developer?.name}
          </Typography>
        </Box>
        <Box className={styles.containerDataItem}>
          <Typography className={styles.titleItem}>
            {translate("Ownership")}
          </Typography>
          <Typography className={styles.valueItem}>
            {propretyResponseJson?.property?.ownership?.lookupItemName}
          </Typography>
        </Box>
      </Box>
    </CustomCard>
  );
}
export default PropertyDetailsComponent;
