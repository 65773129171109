import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DialogComponent, Inputs, Spinner } from "../../../../Components";
import { showError, GlobalTranslate, showSuccess } from "../../../../Helper";
import { CreateHubSpotListAPI } from "../../../../Services";

export const AddHubSpotListComponent = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState({
    saveListName: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [helperText, setHelperText] = useState({
    listName: "",
  });
  const [listNameInfo, setListNameInfo] = useState({
    listName: null,
  });

  const saveHandler = useCallback(async () => {
    setIsLoading((item) => ({ ...item, saveListName: true }));
    const res = await CreateHubSpotListAPI(listNameInfo?.listName);
    setIsLoading((item) => ({ ...item, saveListName: false }));
    if (res && !res.Message) {
      showSuccess(t(`${translationPath}add-new-list-in-hubSpot-succssfuly`));
      onSave({ ...res });
      onClose() ; 
    } else if (res && res.Message) {
      const messageOne =
        (res.Message &&
          res.Message &&
          res.Message.includes("List_Name_Exist") &&
          "list-name-exist") ||
        null;
      showError(
        messageOne
          ? t(`${translationPath}${messageOne}`)
          : t(`${translationPath}add-new-list-in-hubSpot-faild`)
      );
      setIsLoading((item) => ({ ...item, saveListName: false }));
    }
  }, [listNameInfo]);

  useEffect(() => {
    if (isOpen) {
      setListNameInfo({
        listName: null,
      });
    }
  }, [isOpen]);

  return (
    <>
      <DialogComponent
        saveText={GlobalTranslate.t("Shared:save")}
        disableBackdropClick
        titleText={t(`${translationPath}add-new-list-in-hubSpot`)}
        saveClasses={"btns theme-solid bg-primary"}
        saveType="button"
        maxWidth="sm"
        dialogContent={
          <div className="d-flex-column-center">
            <Spinner isActive={isLoading.saveListName} isAbsolute />
            <div className="w-100 mr-1-reversed mt-3">
              <Inputs
                idRef="list-name"
                labelValue="list-name"
                value={listNameInfo.listName || ""}
                onInputChanged={(event) => {
                  const newValue = event.target.value;
                  setListNameInfo((item) => ({
                    ...item,
                    listName: newValue,
                  }));
                }}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                isSubmitted={isSubmitted}
                error={listNameInfo.listName === ""}
                helperText={
                  (listNameInfo.listName === "" && "list-name-required") || ""
                }
              />
            </div>
          </div>
        }
        isOpen={isOpen}
        saveIsDisabled={
          isLoading.saveListName ||
          listNameInfo.listName === "" ||
          listNameInfo.listName === null
        }
        onSaveClicked={() => saveHandler()}
        onCancelClicked={onClose}
        translationPath={translationPath.confirm}
        onCloseClicked={onClose}
      />
    </>
  );
};
