import React from 'react';
// import moment from 'moment';
import { UserAccountTypeEnum } from '../../../../../Enums';

export const ContactsTableHeaderData = [
  {
    id: 1,
    label: "Full Name",
    input: "FullName",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    fieldType: "text",
    component: (item) => <span>{item && item.name} </span> || <span />,
  },
  {
    id: 2,
    label: "Contact Type",
    input: "contactTypeStr",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    data: { enum: ["Individual", "Corporate"] },
    fieldType: "select",
    isSortable: true,
    component: (item) => <span>{item && item.userType} </span> || <span />,
  },
  {
    id: 3,
    label: "Contact Class",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    lookup: 5,
    input: "contact_class",
    fieldType: "select",
    displayPath: "contact_class",
    isSortable: true,
    component: (item) =>
      (
        <span>
          {item &&
            item.accountType &&
            UserAccountTypeEnum[item.accountType].tableImg && (
              <img
                src={UserAccountTypeEnum[item.accountType].tableImg}
                alt="account-type"
              />
            )}
        </span>
      ) || <span />,
  },

  {
    id: 4,
    label: "Created By",
    input: "createdBy",
    isHiddenFilter: false,
    fieldType: "searchField",
    fieldKey: "createdBy",
    isDefaultFilterColumn: true,
    isSortable: true,
    data: {
      type: "string",
      title: "Created By",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "createdBy",
      displayPath: "createdBy",
    },
  },
  {
    id: 5,
    label: "Creation Date",
    input: "createdOn",
    isHiddenFilter: false,
    fieldKey: "createdOn",
    fieldType: "alt-date",
    isDefaultFilterColumn: true,
    isDate: true,
    fieldType: "alt-date",
    isSortable: true,
  },
  {
    id: 6,
    label: "Updated By",
    input: "updatedBy",
    isHiddenFilter: false,
    fieldType: "searchField",
    fieldKey: "updatedBy",
    isDefaultFilterColumn: true,
    isSortable: true,
    data: {
      type: "string",
      title: "Updated By",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "updatedBy",
      displayPath: "updatedBy",
    },
  },
  {
    id: 7,
    label: "UpdateOn Date",
    input: "updateOn",
    isHiddenFilter: false,
    fieldType: "alt-date",
    fieldKey: "updateOn",
    isDefaultFilterColumn: true,
    isDate: true,
    isSortable: true,
    fieldType: "alt-date",
  },
  {
    id: 8,
    label: "Progress",
    input: "data_completed",
    fieldKey: "data_completed",
    textInputType: "number",
    textInputMax: 100,
    textInputMin: 0,
    isHiddenFilter: true,
    fieldType: "number",
    isDefaultFilterColumn: true,
    isNumber: true,
    isSortable: true,
    component: (item) =>
      <span> {item && item.progressWithPercentage}</span> || <span />,
  },
  {
    id: 9,
    label: "Email",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    input: "Email",
    fieldType: "text",
    isSortable: true,
    data: {
      type: "string",
      title: "Email Address",
      description: "Main Information",
      CommunicationType: "Email",
      duplicateValidation: "true",
      iconField: "",
      regExp: "^(?:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+|\\*{8})$",
      errorMsg: "Fill Email in a correct way",
      isSearchable: true,
      searchableKey: "email_address",
      displayPath: "email_address.email",
      DtoName: "",
      HelperInfo: "",
      sensitive: true,
      caption: "",
    },
    component: (item) =>
      (item &&
        item.details &&
        item.details.map((el) => el.title === "email" && el.value)) || <span />,
  },
  {
    id: 10,
    label: "Country",
    input: "country",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    lookup: 16,
    fieldType: "select",
    isSortable: true,
    component: (item) =>
      (item &&
        item.details &&
        item.details.map((el) => el.title === "location" && el.value)) || (
        <span />
      ),
  },
  {
    id: 11,
    label: "isBulkUpload",
    input: "isBulkUpload",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    data: { enum: ["Yes", "No"] },
    fieldType: "select",
    component: (item) => <div>{item?.isBulkUpload ? "true" : "false"}</div>,
  },
  {
    id: 12,
    label: "Source File Name",
    input: "sourceFileName",
    fieldKey: "sourceFileName",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    fieldType: "text",
    isSortable: true,
    component: (item) => (
      <div>{item?.sourceFileName ? item.sourceFileName : "N/A"}</div>
    ),
  },
  {
    id: 13,
    label: "Is HubSpot",
    input: "isHubSpot",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    data: { enum: ["Yes", "No"] },
    fieldType: "select",
  },
  {
    id: 14,
    label: "Is Marketing Contact",
    input: "isMarketingContact",
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    isSortable: true,
    data: { enum: ["Yes", "No"] },
    fieldType: "select",
  },
  {
    id: 15,
    label: "Has Transactions",
    input: "hasTransactions",
    isSortable: true,
    fieldType: "text",
    displayPath: "hasTransactions",
    component: (item) => <div>{item?.hasTransactions}</div>,
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
  },
  {
    id: 16,
    label: "Transaction Count",
    input: "transactionCount",
    fieldKey: "transactionCount",
    textInputType: "number",
    isHiddenFilter: false,
    fieldType: "number",
    isDefaultFilterColumn: true,
    isNumber: true,
    isSortable: true,
    component: (item) =>
      <span> {item && item.transactionCount}</span> || <span />,
  },
];