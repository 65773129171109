import React, { useState } from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import { CustomButton, CustomCard } from "../../../../../../../../Components";
import { useSelectedTheme, useTranslate } from "../../../../../../../../Hooks";

// styles
import useStyles from "./styles";

// icons
import {
  ArrowNarrowRight,
  DropdownIcon,
} from "../../../../../../../../assets/icons";

function FeaturesOverviewComponent({ title, data = [] }) {
  const styles = useStyles();
  const { translate } = useTranslate("NewPropertiesView");

  const {
    theme: { palette },
  } = useSelectedTheme();

  const [isExpand, setIsExpand] = useState(false);
  const visibleItems = 6;
  const isExpandable = data.length > visibleItems;
  const displayedData = isExpand ? data : data.slice(0, visibleItems);
  const lastItem = data[visibleItems] || null;

  return (
    <CustomCard
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.containerFeaturOverView}
    >
      <Box className={styles.containerHeader}>
        <Typography className={styles.mainTitle}>{translate(title)}</Typography>
        <Divider />
      </Box>
      <Box className={styles.containerBody}>
        {displayedData?.length > 0 ? (
          displayedData
            ?.reduce((rows, item, index) => {
              if (index % 2 === 0) rows.push([]);
              rows[rows.length - 1].push(item);
              return rows;
            }, [])
            ?.map((row, rowIndex) => (
              <Box key={rowIndex} className={styles.containerRow}>
                {row.map((item, index) => (
                  <Box key={index} className={styles.containerItems}>
                    <ArrowNarrowRight
                      width="20"
                      height="20"
                      fill={palette.foreground.primary_fg}
                    />
                    <Typography className={styles.titleItem}>{item?.lookupItemName}</Typography>
                  </Box>
                ))}
              </Box>
            ))
        ) : (
          <Typography className={styles.titleItem}>{translate("No-data-available")}</Typography>
        )}

        {isExpandable && (
          <Box className={styles.containerRow}>
            <Box className={styles.containerItems}>
              {!isExpand && lastItem && (
                <>
                  <ArrowNarrowRight
                    width="20"
                    height="20"
                    fill={palette.foreground.primary_fg}
                  />
                  <Typography className={styles.titleItem}>{lastItem?.lookupItemName}</Typography>
                </>
              )}
            </Box>

            <Box className={styles.containerLastItems}>
              <CustomButton
                boxShadow="none"
                size="md"
                variant="text"
                color="tertiary"
                onClick={() => setIsExpand((prev) => !prev)}
                endIcon={
                  <DropdownIcon
                    width="20"
                    height="20"
                    fill={palette.button.tertiary_fg}
                    style={{
                      transform: isExpand ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease",
                    }}
                  />
                }
              >
                {isExpand ? translate("show-less") : translate("show-more")}
              </CustomButton>
            </Box>
          </Box>
        )}
      </Box>
    </CustomCard>
  );
}

export default FeaturesOverviewComponent;
