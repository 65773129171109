import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Inputs, CheckboxesComponent } from '../../../../../Components';
import { red } from '@material-ui/core/colors';


export const ActivitiesTypesFieldsSection = ({
  activitiesTypesDetails,
  formFields,
  formFieldIds,
  setFormFieldIds,
  translationPath,
  parentTranslationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [searchedItemId, setSearchedItemId] = useState('');
  const searchTimer = useRef(null);
  const searchedItemRef = useRef(null);

  const cardCheckboxClicked = useCallback(
    (element) => {
      setFormFieldIds((items) => {
        const index = items.findIndex((item) => item.fieldKey === element.fieldKey);
        if (index === -1) items.push(element);
        else {
          items.splice(index, 1);
          setSearchedItemId('');
        }
        if (element.fieldTitle === 'relatedTo') {
          items.push(
            formFields.find((item) => item.fieldTitle === 'activityTypeRelates')
          );
        }
        return [...items];
      });
    },
    [setFormFieldIds, activitiesTypesDetails]
  );

  const executeScroll = () =>
    searchedItemRef &&
    searchedItemRef.current &&
    searchedItemRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
  const searchHandler = (value) => {
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      setSearchedItemId(value);
      executeScroll();
    }, 500);
  };
  const getIsChecked = useCallback(
    (item) => formFieldIds.findIndex((el) => el.fieldKey === item.fieldKey) !== -1,
    [formFieldIds]
  );
  return (
    <div>
      <div className='bulk-header-section'>{t(`${translationPath}activities-types-fields`)}</div>
      <div className='bulk-sub-header-section'>
        {t(`${translationPath}choose-one-of-the-following-fields`)}
      </div>
      <div className='mt-2'>
        <Inputs
          idRef='activityTypeSearchRef'
          inputPlaceholder={t(`${translationPath}search-fields`)}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          onKeyUp={(e) => searchHandler(e.target.value)}
          startAdornment={<span className='mdi mdi-magnify mdi-24px c-gray' />}
        />
      </div>
      <div className='bulked-units-section mt-3'>
        {formFields &&
          formFields.filter(item => !item.hidden && item.fieldKey !== "ActivityTypeName").map(
            (item, index) =>
              <div
                ref={
                  searchedItemId.length > 0 &&
                    item.fieldTitle.toLowerCase().includes(searchedItemId.toLowerCase()) ?
                    searchedItemRef :
                    null
                }
                className={`unit-fields-item mb-3 ${getIsChecked(item) ? 'is-cheked' : ''} ${searchedItemId.length > 0 &&
                  item.fieldTitle.toLowerCase().includes(searchedItemId.toLowerCase()) ?
                  'is-search' :
                  ''
                  } `}
                key={`unitsItemRef${index + 1}`}
              >
                <div
                  className='unit-fields-card-checkbox-wrapper'
                  key={`activityTypeCheckItemRef${index + 2}`}
                >
                  <CheckboxesComponent
                    singleChecked={getIsChecked(item)}
                    idRef={`fieldsCheckboxItemRef${index + 3}`}
                    onSelectedCheckboxClicked={(e) => {
                      cardCheckboxClicked(item);

                    }}
                  />
                </div>
                <div className='item-ref-no pl-2 pr-2 mt-1'>
                  {t(`${translationPath}${item.fieldTitle}`)}
                  {item.isRequired && <span style={{ color: 'red', margin: 2 }}> * </span>}
                </div>
              </div>

          )}
      </div>
    </div>
  );
};
ActivitiesTypesFieldsSection.propTypes = {
  setFormFieldIds: PropTypes.func.isRequired,
  translationPath: PropTypes.string.isRequired,
  formFields: PropTypes.instanceOf(Array).isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  formFieldIds: PropTypes.instanceOf(Array).isRequired,
};
