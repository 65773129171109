import React from "react";
import "./PickerImageCard.scss";
import { getDownloadableLink } from "../../../../../../Helper";

const PickerImageCard = ({ itemData, fileKeys }) => {
  const PickerCardStyles = {
    backgroundImage:
      itemData && itemData[fileKeys.id]
        ? `url(${getDownloadableLink(itemData[fileKeys.id])})`
        : "",
    backgroundColor: itemData ? "#d3d3d37d" : "transparent",
    backgroundBlendMode: "overlay",
  };

  return (
    <div
      className={`PickerCard ${
        itemData.isChecked ? "PickerCard__checked" : ""
      }`}
      style={PickerCardStyles}
    ></div>
  );
};

export default PickerImageCard;
