import { makeStyles } from "@material-ui/core/styles";
import { useIsAr } from "../../../Hooks";

export default makeStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    flex: {
      display: "flex",
      gap: "4px",
      width: "214px",
      height: "83px",
      padding: "16px",
      alignItems: "center",
      background: theme.palette.background.paper,
      boxShadow:
        "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    },
    actionsContainer: (props) => ({
      position: "fixed",
      right: isAr ? "unset" : props?.isDesktop ? "41px" : "25px",
      zIndex: 50,
    }),
    button: {
      minWidth: "fit-content",
      padding: "10px",
      borderRadius: "6px",
    },
  };
});
