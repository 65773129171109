import { useDispatch, useSelector } from 'react-redux';
import { SmartProposalActions } from '../../../../store/SmartProposal/Actions';

const useIsLoading = () => {
    
  const dispatch = useDispatch();
  const proposalState = useSelector(
    (state) => state.SmartProposalReducer
  );

    
  const isLoadingHandler = (isLoading) => {
    const updatedProposalState = {
      ...proposalState,
      isLoading,
    };

    dispatch(SmartProposalActions.SmartProposalRequest(updatedProposalState));
  }
  

  return { 
     isLoading: proposalState.isLoading,
     setIsLoading: isLoadingHandler,
    }
}

export default useIsLoading
