import React, { useCallback, useEffect, useState } from "react";
import { useTitle } from "../../../Hooks";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { showError, showSuccess, showWarn  , getDownloadableLink} from "../../../Helper";
import { Spinner } from "../../../Components";
import {
  GetTransactionsAMLLogsAPI,
  ExportTransactionsAMLLogsAPI,
} from "../../../Services";
import { AMLLogsFilters, AMLogsTableView } from "./index";

const parentTranslationPath = "AMLLogs";
const translationPath = "";

export const AMLLogsView = () => {
  useTitle("AMLLogs");
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState({
    allTransactionsAMLLogs: false,
    exportTransactionsAMLLogs: false,
  });
  const [tableFilterData, setTableFilterData] = useState([]);
  const [allAMLLogs, setAllAMLLogs] = useState({
    result: [],
    totalCount: 0,
  });
  const [defaultFilter, setDefaultFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
  });
  const [defaultFilterAMLLogs, setDefaultFilterAMLLogs] = useState({
    transactionID: null,
    contactId: null,
    unitReferenceNumber: null,
    transactionType: null,
    amlStatus: null,
    fromDate: null,
    toDate: null,
  });
  const [filter, setFilter] = useState({ ...defaultFilter });
  const [filterAMLLogs, setFilterAMLLogs] = useState({
    ...defaultFilterAMLLogs,
  });
   const dateRangeDefault = {
      startDate: null,
      endDate: null,
      key: "selection",
    };
  
    const [dateFilter, setDateFilter] = useState(dateRangeDefault);

  const GetTransactionsAMLLogs = async (date) => {
    setIsLoading((item) => ({ ...item, allTransactionsAMLLogs: true }));
    let  fromDate  = null ;
    let  toDate  = null ; 

    if (dateFilter && dateFilter.startDate && dateFilter.endDate)
       {
           fromDate = moment(dateFilter.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss");
           toDate = moment(dateFilter.endDate).format(
          "YYYY-MM-DDTHH:mm:ss")
        }

       
    const res = await GetTransactionsAMLLogsAPI({
      pageIndex: filter.pageIndex + 1,
      pageSize: filter.pageSize,
      body: 
        { ...filterAMLLogs , fromDate: fromDate, toDate: toDate  }
    });
    let mapped = [];

    if (!(res && res.status && res.status !== 200)) {
      if (res && res.result) {
        res.result.map((item) => {
          const amlContactDetailsList =
            (item && item.contactDetails && JSON.parse(item.contactDetails)) ||
            null;
          mapped.push({ amlContactDetails: amlContactDetailsList, ...item });
        });
      }
      setAllAMLLogs((item) => ({
        ...item,
        result: mapped,
        totalCount: res.totalCount,
      }));
    } else {
      setAllAMLLogs((item) => ({
        ...item,
        result: [],
        totalCount: 0,
      }));
    }
    setIsLoading((item) => ({ ...item, allTransactionsAMLLogs: false }));
  };

  const exportHandler = useCallback(async () => {
    if (allAMLLogs && allAMLLogs.result && allAMLLogs.result.length > 0) {
      setIsLoading((item) => ({ ...item, exportTransactionsAMLLogs: true }));
      let fromDate = null;
      let toDate = null;
      if (dateFilter && dateFilter.startDate && dateFilter.endDate) {
        fromDate = moment(dateFilter.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss");
        toDate = moment(dateFilter.endDate).format("YYYY-MM-DDTHH:mm:ss");
      }
      const res = await ExportTransactionsAMLLogsAPI({
        pageIndex: filter.pageIndex + 1,
        pageSize: filter.pageSize,
        body: { ...filterAMLLogs, fromDate: fromDate, toDate: toDate },
      });
      if (!(res && res.status && res.status !== 200)) {
        if (res && res.uuid) {
          try {
            const link = document.createElement("a");
            link.setAttribute(
              "download",
              res.fileName || "AMLTransactionsLogs"
            );
            link.href = getDownloadableLink(res.uuid || res.documentId);
            document.body.appendChild(link);
            link.click();
            link.remove();
          } catch (error) {}
        }
      } else
        showError(t(`${translationPath}export-transactions-aml-logs-faild`));
      setIsLoading((item) => ({ ...item, exportTransactionsAMLLogs: false }));
    } else showWarn(t(`${translationPath}we-need-data-to-export-aml-logs`));
  }, [filter, filterAMLLogs, allAMLLogs]);

  const reloadData = () => {
    GetTransactionsAMLLogs();
  };

  useEffect(() => {
    reloadData();
  }, [filter, filterAMLLogs , dateFilter]);

   


  return (
    <div className="Active-user-log  view-wrapper">
      <Spinner isActive={isLoading?.allTransactionsAMLLogs} isAbsolute />
      <div className="w-100 px-2">
        <div className="header-section-ActiveUser"></div>
        <div className="w-100 px2">
          <AMLLogsFilters
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            setFilter={setFilter}
            filter={filter}
            defaultFilter={defaultFilter}
            defaultFilterAMLLogs={defaultFilterAMLLogs}
            setFilterAMLLogs={setFilterAMLLogs}
            filterAMLLogs={filterAMLLogs}
            exportHandler={exportHandler}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            dateRangeDefault={dateRangeDefault}

          />
        </div>
        <div className="w-100 px2">
          <AMLogsTableView
            allAMLLogsData={allAMLLogs}
            filter={filter}
            setFilter={setFilter}
            isLoading={isLoading}
            tableFilterData={tableFilterData}
            setTableFilterData={setTableFilterData}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
          />
        </div>
      </div>
    </div>
  );
};
