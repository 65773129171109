import React from "react";

function Bell01({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M11.54 1.023c-1.065.103-1.662.259-2.52.658-1.894.881-3.306 2.605-3.817 4.659A8.298 8.298 0 0 0 5 7.973c0 .941-.14 2.297-.321 3.112-.282 1.271-.642 2.113-1.503 3.515-.6.977-.747 1.244-.837 1.518-.241.74.178 1.527.955 1.792.193.066.708.071 8.466.081 5.387.008 8.376-.002 8.594-.029.579-.07.963-.311 1.197-.751.15-.284.214-.608.169-.852-.072-.38-.171-.571-1.037-1.997-.751-1.236-1.092-2.057-1.362-3.275-.182-.823-.26-1.546-.321-2.967-.051-1.202-.133-1.683-.427-2.5-.854-2.375-2.984-4.14-5.453-4.518-.393-.061-1.3-.106-1.58-.079m1.012 2.019a5.012 5.012 0 0 1 4.303 3.778c.085.337.145.941.145 1.45 0 .889.151 2.274.342 3.139.329 1.49.744 2.524 1.554 3.871l.421.7-3.659.01c-2.012.006-5.304.006-7.316 0l-3.659-.01.421-.7c.816-1.357 1.26-2.475 1.574-3.96.178-.842.321-2.186.321-3.032.001-.527.061-1.128.146-1.468a5.055 5.055 0 0 1 3.695-3.679 4.976 4.976 0 0 1 1.712-.099M9.04 20.061c-.556.173-.836.823-.585 1.361.204.439 1.135 1.049 2.035 1.334a4.841 4.841 0 0 0 3.02 0c.899-.285 1.831-.896 2.035-1.334a.986.986 0 0 0-.602-1.363c-.408-.122-.62-.065-1.09.293-.611.466-1.084.626-1.853.626-.766 0-1.243-.16-1.846-.62-.466-.356-.712-.421-1.114-.297"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default Bell01;
