export const PropertyChangeSource = {
    Submit: {
        id: 1,
        key: "Submit",
    },
    Reset: {
        id: 2,
        key: "Reset",
    },
    Finish: {
        id: 3,
        key: "Finish",
    },
    NavigationEmptyForm: {
        id: 4,
        key: "NavigationEmptyForm",
    },
    NavigationFilledForm: {
        id: 5,
        key: "NavigationFilledForm",
    },
}