const unitDtoMapper = (property) => {

  const singleUnit = property.submittedForm?.UnitsDetails || {};
  const multiUnits = property.submittedForm?.MultiUnitsDetails || {};

  const isSingleUnit = property.units?.length === 1;

  const multiUnitIds = Array.isArray(multiUnits.selectedUnits)
    ? multiUnits.selectedUnits.map((item) => item.unitId)
    : [];

  const unitIds = isSingleUnit ? [property.units[0]] : multiUnitIds;
  const showFinancialFees =  isSingleUnit ? singleUnit?.showFinancialFees : false;

  const {
    showTransferFees,
    showAgencyFees,
    showNOCFeeBuyer,
    showServiceCharge,
    showChillerCharges,
    showLeased,
    showCurrentRentAmount,
    showRentalExpiryDate,
  } = singleUnit.financialFees || {};

  const mappedUnit = {
    propertyId: property?.propertyId,
    unitImage: singleUnit?.unitImage?.url,
    unitIds,
    showFinancialFees,
    showTransferFees,
    showAgencyFees,
    showNOCFeeBuyer,
    showServiceCharge,
    showChillerCharges,
    showLeased,
    showCurrentRentAmount,
    showRentalExpiryDate,
    singleUnitDetails: {},
    multiUnitsDetails: [],
  };

  return mappedUnit;
};

export default unitDtoMapper;
