import React from "react";

function Mail01({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M4.92 3.041c-.768.051-1.248.164-1.74.41A3.755 3.755 0 0 0 1.121 6.2l-.083.477c-.053.299-.05 9.928.003 10.503.068.748.184 1.207.423 1.678a3.802 3.802 0 0 0 2.696 2.018c.578.112 1.31.124 7.84.124 6.53 0 7.262-.012 7.84-.124a3.802 3.802 0 0 0 2.696-2.018c.239-.471.355-.93.423-1.678.053-.575.056-10.204.003-10.503l-.083-.477a3.785 3.785 0 0 0-2.559-2.954c-.685-.225-.42-.218-7.98-.227-3.828-.005-7.167.005-7.42.022m14.572 2.05c.509.123.908.4 1.171.811.109.17.279.611.247.641-.094.085-8.249 5.765-8.415 5.861-.201.117-.272.136-.495.136-.223 0-.294-.019-.495-.136-.166-.096-8.321-5.776-8.415-5.861-.033-.03.139-.471.249-.641.362-.557.831-.803 1.641-.863.22-.016 3.514-.026 7.32-.022 6.285.007 6.945.014 7.192.074m-9.436 8.76c.824.552 1.208.688 1.944.688s1.12-.136 1.944-.688a588.54 588.54 0 0 0 3.769-2.625l3.292-2.306-.016 4.15c-.017 4.498-.013 4.423-.237 4.882-.126.259-.562.686-.834.816-.455.219-.211.212-7.918.212-7.696 0-7.459.006-7.918-.209-.27-.128-.705-.554-.834-.819-.224-.459-.22-.384-.237-4.882l-.016-4.15 3.292 2.306a588.54 588.54 0 0 0 3.769 2.625"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default Mail01;
