import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteComponent } from "../../Components";
import { DisplayCaptionInAdvanceSearch } from '../../Helper'
import {
  ContactRule,
  UserRule,
  UnitRule,
  PropertyRule,
  ActivityTypeRule,
  CampaignsRule,
  DeveloperContactRule,
} from "./Rules";

export const SearchItemComponent = ({
  translationPath,
  parentTranslationPath,
  item,
  index,
  filtersWithValues,
  setFiltersWithValues,
  changeOperator,
}) => {
  const { t } = useTranslation("Shared");

  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);

  return (
    <>
      <AutocompleteComponent
        key={`filter${index + 1}-${item.key + index}-${item.input}`}
        selectedValues={
          filtersWithValues[index] && filtersWithValues[index].displayObj
        }
        idRef={`filter${index + 1}-${item.input}`}
        multiple={false}
        inputPlaceholder={t("search")}
        data={
          item && item.data && item.data.enum && item.data.enum.length
            ? item.data.enum
            : items
        }
        inputClasses="inputs theme-form-builder"
        displayLabel={(option) => (option && option.name) || ""}
        onChange={(e, v) => {
          const updatelist = [...filtersWithValues];
          updatelist[index] = {
            ...updatelist[index],
            displayValue: (v && v.name) || v,
            displayObj: v,
          };

          setFiltersWithValues(updatelist);
          changeOperator((v && v.name) || v);
        }}
        withLoader
        isLoading={isLoading}
        onInputKeyUp={(e) => {
          const { value } = e.target;
          if (searchTimer.current) clearTimeout(searchTimer.current);
          searchTimer.current = setTimeout(() => {
            if (item && item.data && item.data.searchKey === "developers")
              DeveloperContactRule(item, value, setItems, setIsLoading);
            else if (item && item.data && item.data.searchKey === "contact")
              ContactRule(item, value, setItems, setIsLoading);
            else if (item && item.data && item.data.searchKey === "User")
              UserRule(item, value, setItems, setIsLoading);
            else if (item && item.data && item.data.searchKey === "unit")
              UnitRule(item, value, setItems, setIsLoading);
            else if (item && item.data && item.data.searchKey === "property")
              PropertyRule(item, value, setItems, setIsLoading);
            else if (item && item.data && item.data.searchKey === "activtyType")
              ActivityTypeRule(item, value, setItems, setIsLoading);
            else if (
              item &&
              item.data &&
              item.data.searchKey === "campaign_name"
            )
              CampaignsRule(item, value, setItems, setIsLoading);
          }, 1000);
        }}
        withoutSearchButton
        labelValue={DisplayCaptionInAdvanceSearch(item) || item.label}
        filterOptions={(options) => options}
        filterSelectedOptions
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />
    </>
  );
};
