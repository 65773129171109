import React from 'react';
import { styled, withStyles } from '@material-ui/core/styles';
import { ButtonBase, Menu, MenuItem } from '@material-ui/core';

export const StyledButton = styled(ButtonBase)({
  borderRadius: '10px',
  border: '1px solid #F0F0F0',
  background: '#FFF',
  display: 'flex',
  minHeight: '40px',
  padding: '8.5px 7px',
  alignItems: 'center',
  gap: '10px',
  textTransform: 'capitalize',
  '& .share-unit-placeholder': {
    color: '#A6A6A6',
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: 'normal',
  },
});

export const DropdownIconWrapper = styled('span')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#8E8E93',
  '&::before': {
    fontSize: '22px', // This forces MDI icon to follow the size
  },
});

export const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '12px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles(() => ({
  root: {
    padding: '0px',
  },
}))(MenuItem);
