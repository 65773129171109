import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  propertyCard: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: "12px",
    color: theme.palette.text.primary,
    display: "flex",
    flexDirection: "column",
    width: "339px",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    direction: "ltr",
  },
  imageWrapper: {
    width: "100%",
    height: "240px",
    "& .splide__arrow": {
      display: "none",
      width: "36px",
      height: "36px",
      backgroundColor: "rgba(255, 255, 255, 0.90)",
      backdropFilter: "blur(4px)",
    },
    "& .splide__pagination": {
      bottom: "20px",
      borderRadius: "24px",
      border: "1px solid rgba(255, 255, 255, 0.40)",
      background: "rgba(255, 255, 255, 0.50)",
      backdropFilter: "blur(4px)",
      width: "fit-content",
      height: "16px",
      padding: "0 8px",
      alignItems: "center",
      gap: "4px",
      justifySelf: "center",
      display: "none",
    },
    "& .splide__pagination__page": {
      margin: 0,
      backgroundColor: "#FFF",
      opacity: 1,
    },
    "& .is-active": {
      backgroundColor: "#101828",
    },
  },
  imageWrapperHover: {
    "& .splide__arrow": { display: "flex" },
    "& .splide__pagination": { display: "flex" },
  },
  unitImage: {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "12px 12px 0 0",
  },
  emptyImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.disabled,
    height: "100%",
    borderRadius: "12px 12px 0 0",
  },
  details: {
    padding: "20px 16px",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  unitName: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "28px",
    height: "60px",
    alignContent: "center",
  },
  countryText: {
    fontSize: "14px",
    fontWeight: 500,
    height: "20px",
    lineHeight: "20px",
    color: theme.palette.text.primary,
  },
  divider: {
    margin: "16px 0 8px 0",
    backgroundColor: theme.palette.background.secondary,
  },
  footer: {
    display: "flex",
    gap: "2px",
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.secondary,
    flexDirection: "column",
    alignItems: "center",
    "& label": {
      fontWeight: 500,
    },
  },
  dateInfo: {
    display: "flex",
    gap: "8px",
    "& span": {
      color: theme.palette.text.tertiary,
    },
  },
  flexRow: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
  },
  idSection: {
    alignItems: "center",
    color: theme.palette.button.tertiary_fg,
    display: "flex",
    gap: "8px",
    justifyContent: "flex-start",
    "& label": {
      fontSize: "16px",
      fontWeight: 600,
    },
  },
  propertyType: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    color: theme.palette.text.brand_secondary,
  },
  status: {
    zIndex: 2,
    position: "absolute",
    top: "20px",
    right: "16px",
  },
  checkbox: {
    zIndex: 2,
    position: "absolute",
    top: "20px",
    left: "16px",
  },
  font500: {
    fontSize: "14px",
    fontWeight: 500,
  },
  font400: {
    fontSize: "14px",
    fontWeight: 400,
  },
  secondary: {
    color: theme.palette.text.secondary,
  },
  primary: {
    color: theme.palette.text.primary,
  },
  tertiary: {
    color: theme.palette.text.tertiary,
  },
  containerItemAvatar: {
    border: `2px solid ${theme.palette.background.primary_alt}`,
    borderRadius: "50%",
  },
  avatarStyle: {
    width: "24px",
    height: "24px",
    fontSize: "12px",
    fontWeight: 600,
    color: theme.palette.text.text_quarterary,
    background: theme.palette.background.secondary_hover,
  },
  owner: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  customMenuList: {
    width: "240px",
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: "8px !important",
    padding: "4px 6px",
    boxShadow:
      "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important",
  },
  menuIcon: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  option: {
    display: "flex",
    padding: "9px 10px",
    gap: "8px",
    margin: "1px 0",
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.secondary,
    "&:hover": {
      backgroundColor: theme.palette.button.bg_hover,
    },
  },
  menuWrapper: {
    padding: "20px 16px",
    position: "absolute",
    top: "240px",
    right: 0,
  },
  flexRow2: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
  },
  active: {
    border: `1px solid ${theme.palette.border.brand}`,
  },
  checkboxBackground: {
    background: "rgba(255, 255, 255, 0.5)",
  },
}));
