import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { ProposalTypes } from "../../Enums/ProposalTypes";
import {
  AutocompleteComponent,
  SwitchComponent,
} from "../../../../../Components";
import ThemeCards from "./ThemeCards";
import ReceiverTypes from "../../Enums/ReceiverTypes";
import { filterContactsHasEmails } from "../../Helper/emailAdressHelpers";
import useGetStartProposalData from "./useGetStartProposalData";

const ThemeDialogForm = ({ selected, setSelected }) => {
  const parentTranslationPath = "SmartProposal";
  const translationPath = "ProposalThemeDialog.";
  const { t } = useTranslation(parentTranslationPath);
  const setTimoutId = useRef(null);

  const { getLeads, getSystemUsers, data } = useGetStartProposalData();

  const inputSearchHandler = (value, endpointFunc) => {
    if (setTimoutId.current) clearTimeout(setTimoutId.current);
    setTimoutId.current = setTimeout(() =>  endpointFunc(value), 700);
  }
  
  const leadOptionDisplay = (option) => option?.lead?.contact_name?.name ||
    option?.lead?.company_name || ""
  
  
  return (
    <div className='flex-column gap-20px"'>
      <ThemeCards selected={selected} setSelected={setSelected} />
      <div className="dialog_field mx-4 pt-2 pb-1 mt-1 d-flex fj-start">
        <div className="w-20">
          <span className="fw-simi-bold">{t(`${translationPath}mode`)}</span>
        </div>
        <div className="w-80">
          <SwitchComponent
            idRef="MyleadsRef"
            themeClass="thick-theme"
            isChecked={selected.isDark || false}
            labelValue={selected.isDark ? "dark-mode" : "light-mode"}
            isDisabled={!ProposalTypes[selected.type.name]?.isDarkAvailable}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onChangeHandler={(event, isChecked) => {
              event.stopPropagation();
              setSelected((prevState) => ({
                ...prevState,
                isDark: isChecked,
              }));
            }}
          />
        </div>
      </div>
      <div className="dialog_field mx-4 pt-2 pb-1 mt-1 d-flex fj-start">
        <div className="w-20">
          <span className="fw-simi-bold">
            {t(`${translationPath}Send-to-user`)}
          </span>
        </div>
        <div className="w-80">
          <AutocompleteComponent
            idRef="System-usersRef"
            inputPlaceholder={t(`select`)}
            selectedValues={selected?.receiverUsers || []}
            wrapperClasses="mr-2 my-2"
            data={data.systemUsers || []}
            chipsLabel={(option) => option.fullName || ""}
            withoutSearchButton
            multiple
            displayLabel={(option) => option.fullName || ""}
            renderOption={(option) =>
              ((option.userName || option.fullName) &&
                `${option.fullName} (${option.userName})`) ||
              ""
            }
            onChange={(_, newValue) => {
              const receiverUsers = filterContactsHasEmails(
                newValue,
                ReceiverTypes.User.value
              );

              setSelected((prevState) => ({
                ...prevState,
                receiverUsers,
              }));
            }}
            onOpen={() => {
              if (data.systemUsers && data.systemUsers.length == 0)
                getSystemUsers();
            }}
            onInputKeyUp={(e) => {
                const { value } = e.target;
                inputSearchHandler(value, getSystemUsers);
            }}
            filterOptions={(options) => options}
          />
        </div>
      </div>
      <div className="dialog_field mx-4 pt-2 pb-1 mt-1 d-flex fj-start">
        <div className="w-20">
          <span className="fw-simi-bold">
            {t(`${translationPath}Send-to-lead`)}
          </span>
        </div>
        <div className="w-80">
          <AutocompleteComponent
            idRef="leadsRef"
            inputPlaceholder={t(`select`)}
            selectedValues={selected.receiverLeads || []}
            wrapperClasses="mr-2 my-2"
            data={data.leads || []}
            chipsLabel={leadOptionDisplay}
            withoutSearchButton
            multiple
            displayLabel={leadOptionDisplay}
            renderOption={leadOptionDisplay}
            onChange={(_, newValue) => {
              const receiverLeads = filterContactsHasEmails(
                newValue,
                ReceiverTypes.Lead.value
              );
              setSelected((prevState) => ({
                ...prevState,
                receiverLeads,
              }));
            }}
            onOpen={() => {
              if (data?.leads?.length == 0) getLeads();
            }}
            onInputKeyUp={(e) => {
                const { value } = e.target;
                inputSearchHandler(value, getLeads);
            }}
            filterOptions={(options) => options}
          />
        </div>
      </div>
    </div>
  );
};

export default ThemeDialogForm;
