import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import StepButton from "@material-ui/core/StepButton";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";

const useQontoStepIconStyles = makeStyles({
  root: {
    height: 24,
    width: 24,
    borderRadius: "50%",
    cursor: "pointer",
  },
  active: {
    color: "#7F6944",
    boxShadow: "0px 0px 0px 4px #8C734B3D",
    border: "8px solid currentColor",
  },
  circle: {
    color: "#F9FAFB",
    backgroundColor: "transparent",
    border: "8px solid currentColor",
    boxShadow: "0 0 0 1.5px #EAECF0",
    backgroundColor: "#D0D5DD",
  },
  completed: {
    color: "#FFF",
    backgroundColor: "#7F6944",
    padding: "3px",
  },
});

function QontoStepIcon({ active, completed }) {
  const classes = useQontoStepIconStyles();

  return (
    <div className={classes.root}>
      {completed ? (
        <Check className={`${classes.root} ${classes.completed}`} />
      ) : active ? (
        <div className={`${classes.root} ${classes.active}`} />
      ) : (
        <div className={`${classes.root} ${classes.circle}`} />
      )}
    </div>
  );
}

const QontoStepper = memo(({ steps, activeStepIndex, onStepClickHandler, nonLinear }) => {
  
  const StyledStepLabel = withStyles({
    label: {
      color: "#344054",
      textAlign: "center",
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "20px",
    },
  })(StepLabel);

  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 12,
      left: "calc(-50% + 12px)",
      right: "calc(50% + 12px)",
    },
    active: {
      "& $line": {
        borderColor: "#7F6944",
      },
    },
    completed: {
      "& $line": {
        borderColor: "#7F6944",
      },
    },
    line: {
      borderColor: "#eaeaf0",
      borderTopWidth: 2,
    },
  })(StepConnector);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    stepperPaper: {
      backgroundColor: "transparent !important",
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStepIndex}
        connector={<QontoConnector />}
        classes={{ root: classes.stepperPaper }}
        nonLinear={nonLinear}
        alternativeLabel
      >
        {steps.map((item, index) => (
          <Step
            key={item.label}
            active={index === activeStepIndex}
            completed={index < activeStepIndex}
          >
            <StepButton onClick={() => onStepClickHandler(index)}>
              <StepLabel StepIconComponent={QontoStepIcon}>
                <StyledStepLabel>{item.label}</StyledStepLabel>
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </div>
  );
})

export default QontoStepper;

QontoStepper.propTypes = {
  steps: PropTypes.array,
  nonLinear: PropTypes.bool,
  onStepClickHandler: PropTypes.func,
};

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};
