import React, {
  useEffect,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { SwitchComponent } from "../../../../../Components";
import MultiSelectField from "../../Utilities/MultiSelectField/MultiSelectField";
import useLocationSelectFields from "../../Hooks/useLocationSelectFields";

const LocationAndAmenitiesForm = ({
  localFormChangeHandler,
  localFormSelection,
}) => {
  const parentTranslationPath = "SmartProposal";
  const translationPath = "LocationAndAmenitiesForm.";
  const { t } = useTranslation(parentTranslationPath);


  const facilitiesAndAmenitiesData = useMemo(
    () => localFormSelection?.data?.facilitiesAndAmenities || [],
    [localFormSelection?.data?.facilitiesAndAmenities]
  );

  const nearestLandmarksData = useMemo(
    () => localFormSelection?.data?.nearestLandmarks || [],
    [localFormSelection?.data?.nearestLandmarks]
  );
  const {
    selectedItems,
    setSelectedItems,
    facilitiesChangeHandler,
    landmarksChangeHandler,
 } = useLocationSelectFields(localFormSelection, localFormChangeHandler)

  useEffect(() => {
    landmarksChangeHandler();
  }, [selectedItems.nearestLandmarks]);

  useEffect(() => {
    facilitiesChangeHandler();
  }, [selectedItems.facilitiesAndAmenities]);

  return (
    <>
      <div className="ProposalForm__field_box">
        <div className="ProposalForm__field_label">
          <p>{t(`${translationPath}Include-Map`)}</p>
        </div>
        <div>
          <SwitchComponent
            idRef="showMapRef"
            themeClass="thick-theme"
            isChecked={localFormSelection?.showLocation || false}
            labelValue={`${
              localFormSelection?.showLocation ? "show-map" : "hide-map"
            }`}
            parentTranslationPath={parentTranslationPath}
            translationPath={""}
            onChangeHandler={(_, isChecked) =>
              localFormChangeHandler("edit", {
                ...(localFormSelection || {}),
                showLocation: isChecked,
                showLocationAndamenitiesPage:
                  isChecked ||
                  localFormSelection.showNearBy ||
                  localFormSelection.showFacilities,
              })
            }
          />
        </div>
      </div>
      <span className="field_divider"></span>
      <div className="ProposalForm__field_box">
        <div className="ProposalForm__field_label">
          <p>{t(`${translationPath}Include-Neighborhood/Nearby-Section`)}</p>
          <p>{`You can select up to ${6}`}</p>
        </div>
        <div>
          <SwitchComponent
            idRef="showNearByRef"
            themeClass="thick-theme"
            isChecked={localFormSelection?.showNearBy || false}
            labelValue={`${localFormSelection?.showNearBy ? "show" : "hide"}`}
            parentTranslationPath={parentTranslationPath}
            translationPath={""}
            onChangeHandler={(_, isChecked) =>
              localFormChangeHandler("edit", {
                ...(localFormSelection || {}),
                showNearBy: isChecked,
                showLocationAndamenitiesPage:
                  isChecked ||
                  localFormSelection.showLocation ||
                  localFormSelection.showFacilities,
              })
            }
          />
          <MultiSelectField
            options={nearestLandmarksData}
            labelKey={"categoryName"}
            idKey={"categoryId"}
            selectedItems={selectedItems.nearestLandmarks}
            selectHandler={(newSelectedItems) =>
              setSelectedItems((prevState) => ({
                ...prevState,
                nearestLandmarks: newSelectedItems,
              }))}
            maxSelection={6}
          />
        </div>
      </div>
      <span className="field_divider"></span>
      <div className="ProposalForm__field_box">
        <div className="ProposalForm__field_label">
          <p>{t(`${translationPath}Include Amenities-and-Facilities`)}</p>
          <p>{`You can select up to ${25}`}</p>
        </div>
        <div>
          <SwitchComponent
            idRef="showFacilitiesAndAmentiesRef"
            themeClass="thick-theme"
            isChecked={localFormSelection?.showFacilities || false}
            labelValue={`${
              localFormSelection?.showFacilities ? "show" : "hide"
            }`}
            parentTranslationPath={parentTranslationPath}
            translationPath={""}
            onChangeHandler={(_, isChecked) =>
              localFormChangeHandler("edit", {
                ...(localFormSelection || {}),
                showFacilities: isChecked,
                showLocationAndamenitiesPage:
                  isChecked ||
                  localFormSelection.showLocation ||
                  localFormSelection.showNearBy,
              })
            }
          />

          <MultiSelectField
            options={facilitiesAndAmenitiesData}
            labelKey={"name"}
            idKey={"id"}
            selectedItems={selectedItems.facilitiesAndAmenities}
            selectHandler={    (newSelectedItems) =>
              setSelectedItems((prevState) => ({
                ...prevState,
                facilitiesAndAmenities: newSelectedItems,
              }))}
            maxSelection={25}
          />
        </div>
      </div>
    </>
  );
};

export default LocationAndAmenitiesForm;
