import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Paper, Box, Typography } from '@material-ui/core';
import { useSelectedTheme, useTranslate, useIsAr } from '../../../Hooks';
import {
  CustomBadge,
  CustomButton,
  CustomCopyToClipboard,
  CustomIconButton,
  ImageCarousel,
  PropertyRating,
  PropertyPlanBadges,
} from '..';
import { PropertySideMenuTabs } from './UI';
import { returnPropsByPermissions, truncateString } from '../../../Helper';
import { ContactsPermissions } from '../../../Permissions';

// Icons
import {
  CloseXIcon,
  ListIcon,
  ActivityIcon,
  ArrowUpRight,
  HeartIcon,
} from '../../../assets/icons';

// Styles
import useStyles from './styles';
import { GetPropertyNearByLandMarks } from '../../../Services';
import { useVerticalNav } from '../../../Contexts/VerticalNavContext';
import _ from 'lodash';
import { LandmarksContainer } from './UI/Landmarks';

function PropertyDetailsSideMenu({ open, onClose, activeItem }) {
  const { setAlertBoxContent } = useVerticalNav();
  const [isExpanded, setIsExpanded] = useState(false);
  const styles = useStyles({ isExpanded });
  const { translate } = useTranslate('NewContactsView');
  const theme = useSelector((store) => store.theme);
  const [landmarks, setLandmarks] = useState({});
  const [radius, setRadius] = useState(5);
  const [selectedLandmarkCategory, setSelectedLandmarkCategory] =
    useState(null);
  const { isAr } = useIsAr(theme.themeDirection);
  const {
    theme: { palette },
  } = useSelectedTheme();

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const getPropertyNearByLandMarks = useCallback(async () => {
    if (!activeItem?.id) {
      return;
    }
    try {
      const data = await GetPropertyNearByLandMarks(activeItem?.id, radius);
      const formattedData = _.groupBy(data, 'categoryName');
      setLandmarks(formattedData);
    } catch (error) {
      setAlertBoxContent('something went wrong');
    }
  }, [activeItem?.id, radius]);
  const setCategory = (category) => {
    setSelectedLandmarkCategory(category);
  };
  useEffect(() => {
    getPropertyNearByLandMarks();
  }, [getPropertyNearByLandMarks]);
  useEffect(() => {
    setRadius(5);
    setIsExpanded(false)
    setLandmarks({});
  }, [activeItem?.id]);
  const PropertyDetailsSideMenuContent = (
    <Paper
      className={clsx(styles.contactSideMenu, { [styles.hidden]: !open })}
      style={{
        transform: open ? 'translateX(0)' : 'translateX(100%)',
        transition: 'transform 0.5s ease-in-out',
      }}
    >
      <Box className={styles.mainContainer}>
        {isExpanded && (
          <LandmarksContainer
            styles={styles}
            selectedLandmark={landmarks[selectedLandmarkCategory]?.[0]}
            selectedLandmarkCategory={selectedLandmarkCategory}
          />
        )}
        <Box className={styles.mainContainerItem}>
          <Box>
            <Box className={styles.headingContainer}>
              <Typography className={styles.menuHeading} variant='h2'>
                {translate('Property')}
              </Typography>

              <CustomIconButton
                variant='text'
                size='xs'
                boxShadow='none'
                color='tertiaryColor'
                hideHoverBg
                onClick={onClose}
              >
                <CloseXIcon
                  width='20'
                  height='20'
                  fill={palette.button.tertiaryColor_fg}
                />
              </CustomIconButton>
            </Box>
            <Box style={{ position: 'relative' }}>
              <ImageCarousel
                imagesArray={activeItem?.allpropertyImages}
                height={245}
              />

              <Box className={styles.favoriteIcon}>
                <HeartIcon width='24' height='24' fill='white' />
              </Box>

              <Box className={styles.rating}>
                <CustomBadge
                  Style={{
                    backdropFilter: 'blur(4px)',
                    padding: '4px 8px',
                    fontSize: '16px',
                  }}
                  label={<PropertyRating rating={activeItem?.propertyRating} />}
                  BackgroundColor={'rgba(255, 255, 255, 0.50)'}
                  BorderColor={'rgba(255, 255, 255, 0.40)'}
                  Color={'#101828'}
                />
              </Box>
            </Box>
          </Box>
          <Box className={styles.detailsContainer}>
            <Box className={styles.detailsContent}>
              <Box className={styles.contactDetails}>
                <Box className={styles.nameContainer}>
                  <Typography
                    className={styles.prefix}
                    style={{ order: isAr ? 1 : 'unset' }}
                    variant='body1'
                  >
                    {truncateString(activeItem?.name, 50)}
                  </Typography>

                  <CustomIconButton
                    variant='text'
                    size='xs'
                    boxShadow='none'
                    color='secondary'
                    onClick={() => {}}
                  >
                    <ArrowUpRight
                      width='20'
                      height='20'
                      fill={palette.button.secondaryGray_fg}
                    />
                  </CustomIconButton>
                </Box>

                <Box className={styles.contactInfoContainer}>
                  {activeItem?.isBulkUpload && (
                    <span style={{ textDecoration: 'underline' }}>
                      {translate('BULK')}
                    </span>
                  )}

                  <Box className={styles.idSection}>
                    <CustomCopyToClipboard
                      data={activeItem?.id} // copy
                      childrenData={'#' + activeItem?.id} // render
                      copyDoneShowTime={1000}
                    />

                    {activeItem?.propertyType && (
                      <Box className={styles.propertyType}>
                        {activeItem?.propertyType}
                      </Box>
                    )}

                    <CustomBadge
                      label={activeItem?.data_completed + '%' || '0%'}
                      SizeVariant={'small'}
                      BackgroundColor={
                        palette.utility[
                          activeItem?.data_completed >= 50
                            ? 'brand_50'
                            : 'error_50'
                        ]
                      }
                      BorderColor={
                        palette.utility[
                          activeItem?.data_completed >= 50
                            ? 'brand_200'
                            : 'error_200'
                        ]
                      }
                      Color={
                        palette.utility[
                          activeItem?.data_completed >= 50
                            ? 'brand_700'
                            : 'error_700'
                        ]
                      }
                    />
                  </Box>

                  <Box>
                    {activeItem?.property_plan?.lookupItemName ||
                      (activeItem?.property_plan && (
                        <PropertyPlanBadges
                          PropertyPlanKey={
                            activeItem?.property_plan?.lookupItemName ||
                            activeItem?.property_plan
                          }
                        />
                      ))}
                  </Box>
                </Box>

                <Box className={styles.actionsContainer}>
                  <CustomButton
                    boxShadow='xs'
                    size='md'
                    variant='outlined'
                    color='secondaryColor'
                    startIcon={
                      <ListIcon
                        width='20'
                        height='20'
                        fill={palette.button.secondaryColor_fg}
                      />
                    }
                    // onClick={() => setIsAddTaskDialogOpen(true)}
                  >
                    {translate('ADD_TASK')}
                  </CustomButton>

                  {returnPropsByPermissions(
                    ContactsPermissions.AddNewActivity.permissionsId
                  ) && (
                    <CustomButton
                      boxShadow='xs'
                      size='md'
                      variant='contained'
                      color='primary'
                      startIcon={
                        <ActivityIcon
                          width='20'
                          height='20'
                          fill={palette.button.primary_fg}
                        />
                      }
                      // onClick={() => setAddActivity(true)}
                    >
                      Send proposal
                    </CustomButton>
                  )}
                </Box>
              </Box>
              <PropertySideMenuTabs
                key={activeItem?.id}
                tabValue={tabValue}
                handleTabChange={handleTabChange}
                activeItem={activeItem}
                expandMenu={() => setIsExpanded(true)}
                landmarksKeys={Object.keys(landmarks).map((key) => ({
                  key,
                  icon: landmarks[key]?.[0]?.iconUrl,
                }))}
                setCategory={setCategory}
                radius={radius}
                setRadius={setRadius}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );

  return ReactDOM.createPortal(
    <>{PropertyDetailsSideMenuContent}</>,
    document.getElementById('portal-root')
  );
}

export default PropertyDetailsSideMenu;
