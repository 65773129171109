import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Box, Divider, Typography } from "@material-ui/core";
import { CustomCard } from "../../../../../../../../Components";
import { useSelectedTheme, useTranslate } from "../../../../../../../../Hooks";
import {
  CalenderCheckIcon,
  HeartHandIcon,
  RocketIcon,
  ToolsIcon,
} from "../../../../../../../../assets/icons";
import { TimelineConnector } from "@material-ui/lab";

// styles
import useStyles from "./styles";

function PropertyTimelineComponent({ propretyResponseJson }) {
  const styles = useStyles();
  const { translate } = useTranslate("NewPropertiesView");
  const {
    theme: { palette },
  } = useSelectedTheme();
  const [isCuurentDate, setIsCurrentDate] = useState(null);

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const launchDateCheck = [propretyResponseJson?.property?.launch_date];
    const matchingDate = launchDateCheck.findIndex((dateString) => {
      if (!dateString) return false;

      const date = new Date(dateString);
      return (
        date.getDate() === currentDay &&
        date.getMonth() === currentMonth &&
        date.getFullYear() === currentYear
      );
    });

    if (matchingDate !== -1) {
      setIsCurrentDate(2);
    } else {
      setIsCurrentDate(null);
    }
  }, [propretyResponseJson]);

  return (
    <CustomCard
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.wrapperPropretyTimeLine}
    >
      <Box className={styles.continerHeaderCard}>
        <Typography className={styles.ItemTitleHeader}>
          {translate("Property-timeline")}
        </Typography>
        <Divider className={styles.divider} />
      </Box>
      <Box className={styles.containerBodyCard}>
        {[
          {
            key: "construction_date",
            icon: (
              <ToolsIcon
                width="24"
                height="24"
                fill={palette.foreground.secondary}
              />
            ),
            label: translate("Construction-date"),
          },
          {
            key: "handover_date",
            icon: (
              <HeartHandIcon
                width="24"
                height="24"
                fill={palette.foreground.secondary}
              />
            ),
            label: translate("Handover-date"),
          },
          {
            key: "launch_date",
            icon: (
              <RocketIcon
                width="24"
                height="24"
                fill={
                  isCuurentDate === 2
                    ? palette.background.brand_solid
                    : palette.foreground.secondary
                }
              />
            ),
            label: translate("Launch-date"),
          },
          {
            key: "completion_date",
            icon: (
              <CalenderCheckIcon
                width="24"
                height="24"
                fill={palette.foreground.secondary}
              />
            ),
            label: translate("Completion-date"),
          },
        ].map(({ key, icon, label }, index) => (
          <Box
            key={key}
            className={clsx(styles.containerDate, {
              [styles.currentDateStyle]: index === isCuurentDate,
            })}
          >
            <Box className={styles.containerIconAndVerticalLine}>
              <Box
                className={clsx(styles.containerIcon, {
                  [styles.currentDateIcon]: index === isCuurentDate,
                })}
              >
                {icon}
              </Box>
              {index !== 3 && (
                <TimelineConnector
                  className={clsx(styles.timelineConnector, {
                    [styles.currentTimeLine]: index === isCuurentDate,
                  })}
                />
              )}
            </Box>
            <Box className={styles.containerData}>
              <Typography
                className={clsx(styles.titleItem, {
                  [styles.currentTitle]: index === isCuurentDate,
                })}
              >
                {translate(label)}
              </Typography>
              <Typography
                className={clsx(styles.valueItem, {
                  [styles.currentValue]: index === isCuurentDate,
                })}
              >
                {formatDate(propretyResponseJson?.property?.[key])}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </CustomCard>
  );
}
export default PropertyTimelineComponent;
