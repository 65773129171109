import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  CustomBadge,
  CustomBreadCrumb,
  PageTitle,
  CustomButton,
  CustomTabs,
} from "../../../../Components";
import {
  useQuery,
  useSelectedTheme,
  useTitle,
  useTranslate,
} from "../../../../Hooks";
import { useVerticalNav } from "../../../../Contexts/VerticalNavContext";
import { ClockIcon, EditIcon } from "../../../../assets/icons";
import { PropertiesAdvanceSearchTest } from "../../../../Services";
import {
  FinanceCompany, // 1
  Gallery, // 2
  Marketing, // 3
  MPI, // 4
  PropertyInfo, // 5
  Specifications, // 6
  UnitModels, // 7
  DocumentTab, // 8
  PaymentPlan, // 9
  Units, // 10
  Task, // 11
  Nearby, // 12
  AgentCourseQualifications, // 13
} from "./UI";

// styles
import useStyles from "./styles";

function PropertyOverview() {
  const styles = useStyles();

  const { translate } = useTranslate("NewPropertiesView");

  const {
    theme: { palette },
  } = useSelectedTheme();

  const history = useHistory();

  const { setActionableItems } = useVerticalNav();

  const query = useQuery();
  const propretyId = query.get("id");
  const formTypeId = query.get("formType");
  useTitle(translate("property-over-View"));
  const [tabValue, setTabValue] = useState(0);
  const [isHistoryOpen, setIsOpenHistoryOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [propretyResponse, setPropretyResponse] = useState([]);
  const [activePropertyData, setActivePropertyData] = useState();

  const propretyOverviewTabs = [
    {
      title: activePropertyData?.property?.property_name || "",
      subTitle: translate("View-your-Properties-details"),
      label: translate("Property-Info"),
      component: (
        <PropertyInfo
          setTabValue={setTabValue}
          propretyId={propretyId}
          propretyResponseJson={activePropertyData}
          propretyResponse={propretyResponse}
        />
      ),
      actionButton: {
        label: translate("Edit"),
        handler: () => {
          history.push(
            `/home/Properties-CRM/edit?formType=${formTypeId}&id=${propretyId}`
          );
        },
        startIcon: (
          <EditIcon width="20" height="20" fill={palette.button.primary_fg} />
        ),
      },
    },
    {
      title: activePropertyData?.property?.property_name || "",
      subTitle: translate("View-your-Properties-details"),
      label: translate("Unit-Models"),
      component: <UnitModels propretyId={propretyId} />,
      actionButton: null,
    },
    {
      title: activePropertyData?.property?.property_name || "",
      subTitle: translate("View-your-Properties-details"),
      label: translate("Gallery"),
      component: <Gallery propretyId={propretyId} />,
      actionButton: null,
    },
    {
      title: activePropertyData?.property?.property_name || "",
      subTitle: translate("View-your-Properties-details"),
      label: translate("Finance-Company"),
      component: <FinanceCompany propretyId={propretyId} />,
      actionButton: null,
    },
    {
      title: activePropertyData?.property?.property_name || "",
      subTitle: translate("View-your-Properties-details"),
      label: translate("Marketing"),
      component: <Marketing propretyId={propretyId} />,
      actionButton: null,
    },
    {
      title: activePropertyData?.property?.property_name || "",
      subTitle: translate("View-your-Properties-details"),
      label: translate("Specifications"),
      component: <Specifications propretyId={propretyId} />,
      actionButton: null,
    },
    {
      title: activePropertyData?.property?.property_name || "",
      subTitle: translate("View-your-Properties-details"),
      label: translate("MPI"),
      component: <MPI propretyId={propretyId} />,
      actionButton: null,
    },
    {
      title: activePropertyData?.property?.property_name || "",
      subTitle: translate("View-your-Properties-details"),
      label: translate("Documents"),
      component: <DocumentTab propretyId={propretyId} />,
      actionButton: null,
    },
    {
      title: activePropertyData?.property?.property_name || "",
      subTitle: translate("View-your-Properties-details"),
      label: translate("Payment-Plan"),
      component: <PaymentPlan propretyId={propretyId} />,
      actionButton: null,
    },
    {
      title: activePropertyData?.property?.property_name || "",
      subTitle: translate("View-your-Properties-details"),
      label: translate("Units"),
      component: <Units propretyId={propretyId} />,
      actionButton: null,
    },
    {
      title: activePropertyData?.property?.property_name || "",
      subTitle: translate("View-your-Properties-details"),
      label: translate("Task"),
      component: <Task propretyId={propretyId} />,
      actionButton: null,
    },
    {
      title: activePropertyData?.property?.property_name || "",
      subTitle: translate("View-your-Properties-details"),
      label: translate("Nearby"),
      component: <Nearby propretyId={propretyId} />,
      actionButton: null,
    },
    {
      title: activePropertyData?.property?.property_name || "",
      subTitle: translate("View-your-Properties-details"),
      label: translate("Agent-Course-Qualifications"),
      component: <AgentCourseQualifications propretyId={propretyId} />,
      actionButton: null,
    },
  ];

  const userHasPolicyToDisplayData = useCallback(async (propertyId) => {
    setIsLoading(true);
    const isForLog = true;

    const body = {
      criteria: {
        Ids: [{ searchType: 1, value: propertyId }],
      },
      filterBy: "createdOn",
      orderBy: 2,
    };

    const res = await PropertiesAdvanceSearchTest(
      { pageIndex: 0, pageSize: 2, isForLog },
      body
    );
    if (!(res && res.status && res.status !== 200)) {
      if (res && res.totalCount > 0) {
        if (
          res &&
          res.result &&
          res.result.length &&
          res.result[0].propertyJson
        ) {
          const propertyJson = JSON.parse(
            res && res.result && res.result.length && res.result[0].propertyJson
          );
          setPropretyResponse(res?.result);
          setActivePropertyData(propertyJson);
        }
      } else {
        setActivePropertyData(null);
        setPropretyResponse([]);
      }
    } else {
      setActivePropertyData(null);
    }

    setIsLoading(false);
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);

    setActionableItems({
      selectedIds: [],
      action: null,
    });
  };

  useEffect(() => {
    userHasPolicyToDisplayData(propretyId);
  }, []);

  return (
    <Box className={styles.propertyOverview}>
      <Box className={styles.breadcrumbTitleWrapper}>
        <CustomBreadCrumb
          breadcrumbs={[
            { label: translate("crm"), nonClickable: true },
            {
              label: translate("Properties"),
              link: "/home/Properties-CRM/view",
            },
          ]}
          containerClasses={styles.breadCrumbContainer}
        >
          <CustomBadge
            Style={{
              padding: "4px 8px",
              borderRadius: "6px",
              lineHeight: "20px",
            }}
            label={`${activePropertyData?.property?.property_name || ""}`}
            BackgroundColor={palette.breadcrumbs.brand_bg_hover}
            BorderColor={palette.breadcrumbs.brand_bg_hover}
            Color={palette.breadcrumbs.brand_fg_hover}
          />
        </CustomBreadCrumb>
        <PageTitle
          title={propretyOverviewTabs[tabValue].title}
          subTitle={propretyOverviewTabs[tabValue].subTitle}
          hideDivider
          showBackIcon
        >
          {!!propretyOverviewTabs[tabValue].actionButton && (
            <CustomButton
              boxShadow="xs"
              size="lg"
              variant="outlined"
              color="primary"
              onClick={propretyOverviewTabs[tabValue].actionButton?.handler}
              startIcon={
                propretyOverviewTabs[tabValue].actionButton?.startIcon ?? null
              }
            >
              {propretyOverviewTabs[tabValue].actionButton?.label}
            </CustomButton>
          )}
        </PageTitle>
      </Box>
      <Box className={styles.historyPercentage}>
        <CustomButton
          boxShadow="none"
          variant="text"
          size="md"
          color="tertiary"
          onClick={() => {
            setIsOpenHistoryOpen(true);
          }}
          startIcon={
            <ClockIcon
              width="20"
              height="20"
              fill={palette.button.tertiary_fg}
            />
          }
        >
          {translate("history")}
        </CustomButton>
        {activePropertyData?.property?.data_completed !== null && (
          <CustomBadge
            label={activePropertyData?.property?.data_completed + "%" || "0%"}
            SizeVariant={"small"}
            BackgroundColor={
              palette.utility[
                activePropertyData?.property?.data_completed >= 50
                  ? "brand_50"
                  : "error_50"
              ]
            }
            BorderColor={
              palette.utility[
                activePropertyData?.property?.data_completed >= 50
                  ? "brand_200"
                  : "error_200"
              ]
            }
            Color={
              palette.utility[
                activePropertyData?.property?.data_completed >= 50
                  ? "brand_700"
                  : "error_700"
              ]
            }
          />
        )}
      </Box>
      <Box className={styles.tabsSide}>
        <CustomTabs
          tabValue={tabValue}
          onTabChange={handleTabChange}
          tabs={propretyOverviewTabs}
          variant="overview"
        />
      </Box>
    </Box>
  );
}
export default PropertyOverview;
