import React, { useCallback, useEffect, useReducer, useState } from "react";
import { GetLeadPoolLogDetails } from "../../../Services";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import { Box, Collapse, IconButton, Typography } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { PaginationComponent, Spinner } from "../../../Components";
import { bottomBoxComponentUpdate } from "../../../Helper";

import "./styles.scss";

const translationPath = "LeadsPoolConfiguration";
function LeadsPoolConfigurationLogView() {
  const { t: translate } = useTranslation(translationPath);
  const headerData = [
    { id: 1, title: translate("date-and-time-viewed"), align: "left" },
    { id: 2, title: translate("user"), align: "center" },
    { id: 3, title: translate("type"), align: "right" },
  ];
  const changeHeaderData = [
    { id: 1, title: translate("field-name"), align: "left" },
    { id: 2, title: translate("changed-from"), align: "center" },
    { id: 3, title: translate("changed-to"), align: "right" },
  ];
  const [expandedRow, setExpandedRow] = useState(null);
  const [data, setData] = useState({
    result: [],
    totalCount: 0,
  });
  const [filter, setFilter] = useState({
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem("Pagination")) || 25,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [differences, setDifferences] = useState({});

  async function getLeadsPoolConfigurationLog() {
    setIsLoading(true);
    const res = await GetLeadPoolLogDetails(
      filter.pageIndex + 1,
      filter.pageSize
    );
    if (!(res && res.status && res.status !== 200)) {
      setData(res ?? []);
    } else {
      setData({});
    }
    setIsLoading(false);
  }
  const onExpandClicked = (item) => {
    if (item.actionType.toLowerCase() !== "read") {
      const newExpandedRow =
        item.leadPoolConfigurationLogId === expandedRow
          ? null
          : item.leadPoolConfigurationLogId;
      setExpandedRow(newExpandedRow);
      if (newExpandedRow) {
        const newDifferences = processLeadsPoolConfigLog(item);
        setDifferences((prev) => ({
          ...prev,
          [item.leadPoolConfigurationLogId]: newDifferences,
        }));
      }
    }
  };

  function processLeadsPoolConfigLog(item) {
    const differences = [];
    const obj1 = item?.responseBefore ?? {};
    const obj2 = item?.responseAfter ?? {};

    const getSuffix = (n) => {
      if (n === 1) return "st";
      if (n === 2) return "nd";
      if (n === 3) return "rd";
      return "th";
    };

    Object.keys({ ...obj1, ...obj2 }).forEach((key) => {
      if (
        JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key]) &&
        key !== "LeadPoolReferrals"
      ) {
        differences.push({
          field: translate(key),
          oldValue: obj1[key] ?? null,
          newValue: obj2[key] ?? null,
        });
      }
    });

    const referralsBefore = obj1.LeadPoolReferrals ?? [];
    const referralsAfter = obj2.LeadPoolReferrals ?? [];

    const maxLength = Math.max(referralsBefore.length, referralsAfter.length);
    for (let i = 0; i < maxLength; i++) {
      const before = referralsBefore[i] || {};
      const after = referralsAfter[i] || {};
      const indexLabel = `${i + 1}${getSuffix(i + 1)}`;

      Object.keys({ ...before, ...after }).forEach((key) => {
        if (
          before[key] !== after[key] &&
          key.toLowerCase() !== "referredbyuserid"
        ) {
          differences.push({
            field: `${indexLabel} LeadPoolReferrals ${key}`, // Store raw key
            oldValue: before[key] ?? null,
            newValue: after[key] ?? null,
          });
        }
      });
    }

    return differences;
  }

  const onPageIndexChanged = (pageIndex) => {
    setFilter({ ...filter, pageIndex: pageIndex });
  };

  const onPageSizeChanged = (pageSize) => {
    setFilter({ ...filter, pageSize: pageSize });
  };

  const getTranslationKey = (key) => {
    const formattedKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();

    return formattedKey;
  };

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex || 0}
        pageSize={filter.pageSize || 25}
        totalCount={data?.totalCount || 0}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  useEffect(() => {
    getLeadsPoolConfigurationLog();
  }, [filter]);

  return (
    <>
      <Spinner isAbsolute isActive={isLoading} />
      <div className="leads-pool-configuration-page-wrapper">
        <TableContainer>
          <Table className="leads-pool-configuration-table-wrapper">
            <TableHead>
              <TableRow>
                {headerData?.map((item) => (
                  <TableCell
                    align={item.align}
                    key={item.id}
                    className="header-table-cell"
                  >
                    {translate(item?.title) || ""}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.result?.map((item, index) => (
                <React.Fragment key={item.leadPoolConfigurationLogId}>
                  <TableRow
                    className={`leads-pool-configuratio-row ${
                      index % 2 === 0 ? "odd-row" : "even-row"
                    }`}
                    onClick={() => onExpandClicked(item)}
                  >
                    <TableCell className="table-body-cell" align="left">
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        disabled={item?.actionType?.toLowerCase() === "read"}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (item?.actionType?.toLowerCase() !== "read")
                            onExpandClicked(item);
                        }}
                      >
                        {expandedRow === item?.leadPoolConfigurationLogId ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                      {moment(item.actionDate).format("DD/MM/YYYY - hh:mm A") ||
                        "N/A"}
                    </TableCell>
                    <TableCell className="table-body-cell" align="center">
                      {item.actionBy || "N/A"}
                    </TableCell>
                    <TableCell className="table-body-cell" align="right">
                      {translate(item.actionType) || "N/A"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={expandedRow === item?.leadPoolConfigurationLogId}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box margin={1}>
                          <Table size="small" aria-label="purchases">
                            <TableHead>
                              <TableRow>
                                {changeHeaderData?.map((header) => (
                                  <TableCell
                                    align={header.align}
                                    key={header.id}
                                    className="header-table-cell"
                                  >
                                    {translate(header?.title) || ""}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {differences[
                                item.leadPoolConfigurationLogId
                              ]?.map((diff, index) => (
                                <TableRow
                                  key={index}
                                  className={`leads-pool-configuratio-row ${
                                    index % 2 === 0 ? "odd-row" : "even-row"
                                  }`}
                                >
                                  <TableCell align="left">
                                    {translate(getTranslationKey(diff.field), {
                                      defaultValue: diff.field,
                                    })}
                                  </TableCell>

                                  <TableCell align="center">
                                    {`${
                                      diff.field.toLowerCase().includes("time")
                                        ? diff?.oldValue || "N/A"
                                        : String(
                                            diff?.oldValue
                                          ).toLowerCase() === "true"
                                        ? "On"
                                        : String(
                                            diff?.oldValue
                                          ).toLowerCase() === "false"
                                        ? "Off"
                                        : translate(diff?.oldValue) || "N/A"
                                    }` || "N/A"}
                                  </TableCell>
                                  <TableCell align="right">
                                    {`${
                                      diff.field.toLowerCase().includes("time")
                                        ? diff?.newValue || "N/A"
                                        : String(
                                            diff?.newValue
                                          ).toLowerCase() === "true"
                                        ? "On"
                                        : String(
                                            diff?.newValue
                                          ).toLowerCase() === "false"
                                        ? "Off"
                                        : translate(diff?.newValue) || "N/A"
                                    }` || "N/A"}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}

export default LeadsPoolConfigurationLogView;
