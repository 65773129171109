import React, { useState } from 'react';
import { LoadableImageComponant } from '../../../../Components';
import { ContactTypeEnum } from '../../../../Enums';
import { formatCommasForPriceUnit } from '../../../../Helper/formatCommas.Helper';
import BedroomsIcon from '../../../../Contexts/UnitValidationContext/Icons/BedroomIcon';
import BathtubIcon from '../../../../Contexts/UnitValidationContext/Icons/BathtubIcon';
import UnitSizeIcon from '../../../../Contexts/UnitValidationContext/Icons/UnitSizeIcon';
import MaidIcon from './MaidIcon.svg';
import './styles.scss';
import { Button } from '@material-ui/core';
import SharedUnitsDialogV2 from './SharedUnitsDialogV2';

function SharetUnitsCardsComponentV2({ data, parentTranslationPath, t }) {
  const [isSharedDialogOpen, setIsSharedDialogOpen] = useState(false);
  const [selectedSharedUnit, setSelectedSharedUnit] = useState(null);

  return (
    <>
      {data?.result?.map((item) => (
        <div
          key={item.id}
          className='share-unit-card-wrapper'
        >
          <div className='share-unit-content'>
            <div className='share-unit-image'>
              <div className='share-unit-badges-container'>
                <span className='share-unit-badge'>{item?.unit_type}</span>
                <span className='share-unit-badge share-unit-secondary'>
                  {item?.sourceSystemName}
                </span>
              </div>

              <LoadableImageComponant
                classes='cover-image'
                alt='unit image'
                src={item?.imageUrl || ContactTypeEnum.corporate.defaultImg}
                width='100%'
                height='169px'
                style={{ borderRadius: '10px' }}
              />
            </div>
            <div className='share-unit-section1'>
              <span className='share-unit-price-wrapper'>
                <span className='share-unit-price'>
                  {formatCommasForPriceUnit(
                    item?.unitOperationSource === 1
                      ? item?.selling_price
                      : item?.rentPerYear
                  )}
                </span>
                <span className='share-unit-currency'>AED</span>
              </span>
              <span className='share-unit-name'>
                {item?.unit_type || ''} {item?.property_name || ' '}
              </span>
              <span className='share-unit-city'> </span>
            </div>
            <div className='share-unit-section2'>
              <div className='share-unit-icons'>
                <BedroomsIcon
                  width='16'
                  height='16'
                />
                <span className='icon-text'>{item?.bedroom || 0}</span>
              </div>
              <div className='share-unit-icons'>
                <BathtubIcon
                  width='16'
                  height='16'
                />
                <span className='icon-text'>{item?.bathroom || 0}</span>
              </div>
              <div className='share-unit-icons'>
                <UnitSizeIcon
                  width='16'
                  height='16'
                />
                <span className='icon-text'>
                  {item?.builtup_area_sqft || 0}
                  <sup className='sup-text'>sqft</sup>
                </span>
              </div>
              {/* <div className='share-unit-icons'>
                <img
                  src={MaidIcon}
                  alt='Maid Icon'
                />
                <span className='icon-text'>{item?.bathroom || 0}</span>
              </div> */}
            </div>
            <div className='share-unit-section3'>
              <span className='share-unit-agent'>Listing Agent:</span>
              <span className='share-unit-agent-name'>
                {item?.unitOperationSource === 1
                  ? item?.listing_agent
                  : item?.rent_listing_agent}
              </span>
            </div>
          </div>
          <Button
            className='Side_popup_btn'
            onClick={(e) => {
              e.stopPropagation();
              setSelectedSharedUnit(item);
              setIsSharedDialogOpen(true);
            }}
          >
            <span className='mdi mdi-folder-open'></span>
          </Button>
        </div>
      ))}
      {isSharedDialogOpen && (
        <SharedUnitsDialogV2
          activeItem={selectedSharedUnit}
          isOpen={isSharedDialogOpen}
          parentTranslationPath={parentTranslationPath}
          t={t}
          onClose={() => {
            setIsSharedDialogOpen(false);
            setSelectedSharedUnit(null);
          }}
        />
      )}
    </>
  );
}

export default SharetUnitsCardsComponentV2;
