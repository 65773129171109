import React, { memo } from "react";
import { Chip, Box } from "@material-ui/core";

const ChipsViewBox = memo(
  ({ selectedItems, selectHandler, labelKey, idKey, isDisabled }) => {
    const deleteHandler = (itemToDelete) => () => {
      selectHandler(
        selectedItems.filter((item) => item[idKey] !== itemToDelete[idKey])
      );
    };

    return (
      <Box mt={1} display="flex" flexWrap="wrap">
        {selectedItems.map((item) => (
          <Chip
            key={item[idKey]}
            label={item[labelKey]}
            onDelete={deleteHandler(item)}
            style={{ margin: "4px" }}
          />
        ))}
      </Box>
    );
  }
);

export default ChipsViewBox;
