
import React, { useCallback, useEffect, useState , useRef } from 'react';
import { useTranslation } from 'react-i18next'; 
import { AutocompleteComponent } from '../../Components'
import { GetLookupItemsByLookupTypeName, lookupItemsGet } from '../../Services' ; 
import {  UnitsOperationTypeEnum  } from '../../Enums' ; 
import { DisplayCaptionInAdvanceSearch } from '../../Helper'


export const SelectItemComponent = ({
    translationPath,
    parentTranslationPath,
    item , 
    index,
    filtersWithValues , 
    setFiltersWithValues , 
    changeOperator , 
    unitType , 
}) => {
    const { t } = useTranslation('Shared');

    const searchTimer = useRef(null);
    const [isLoading ,setIsLoading ] = useState(false) ;
    const [items, setItems] = useState([]);

    const filterOptions = (item) => 
    { 
      if(item.key === 'operation_type' && unitType !== undefined) 
      {
         return unitType  === 'sale' ? (item.data.enum.filter(x=> x.lookupItemId !== UnitsOperationTypeEnum.rent.key)) 
         : (item.data.enum.filter(x=> x.lookupItemId !== UnitsOperationTypeEnum.sale.key))  ; 
          
      }
      else return (item && item.data && item.data.enum ) || []  ; 
        
    };
    const filterOptions2 = (items) => 
    { 
      if(item.key === 'operation_type' && unitType !== undefined) 
      {
         return unitType  === 'sale' ? (items.filter(x=> x.lookupItemId !== UnitsOperationTypeEnum.rent.key)) 
         : (items.filter(x=> x.lookupItemId !== UnitsOperationTypeEnum.sale.key))  ; 
          
      }
      else return (items); 
        
    };
    const lookupItems = useCallback(async (lookup, search) => {
         setIsLoading(true);
        const result = await lookupItemsGet({
          lookupTypeId: lookup,
          pageIndex: 1,
          pageSize: 100,
          searchedItem: search,
        });
        if (!(result && result.status && result.status !== 200)) {
          setTimeout(() => {
            setItems((result && result.result) || []);
          }, 500);
        } else
          setItems([]);

         setIsLoading(false);
      }, []);

      const getLookupItemsByName = async (lookUpName) => {
        const result = await GetLookupItemsByLookupTypeName({
          lookUpName,
          pageSize: 100,
          pageIndex: 1,
        });
        if (!(result && result.status && result.status !== 200)) {
            setItems((result && result.result) || []);
        } else setItems([]);
      };

    useEffect(() => {
      const lookupItemId = item?.data?.lookup || item?.lookup;
      const lookupItemName = item?.data?.lookupName || item?.lookupName;

      if(lookupItemName) getLookupItemsByName(lookupItemName);
      else if(lookupItemId) lookupItems(lookupItemId);
    }, []);



  
    return (
        <>
        <AutocompleteComponent
                value={filtersWithValues[index] && filtersWithValues[index].displayObj}
                key={`filter${index + 1}-${item.key + index}-${item.input}`}
                selectedValues={filtersWithValues[index] && filtersWithValues[index].displayObj }
                idRef={`filter${index + 1}-${item.input}`}
                multiple={false}
                data={(item && item.data && item.data.enum && item.data.enum.length ) ? filterOptions(item): filterOptions2(items) }
                inputClasses='inputs theme-form-builder'
                displayLabel={
                  ((option) => (option  && option.lookupItemName) || (option && option.display) || (option && option.value ) || (option && option.name ) || option  || '')
                }
                onChange={(e, v) => {
                  const updatelist = [...filtersWithValues];
                  updatelist[index] = {
                    ...updatelist[index],
                    displayValue :  (v && v.lookupItemName ) || (v && v.value ) || (v && v.name) || (v && v.id) || v , 
                    displayObj: v,
                  };

                  setFiltersWithValues(updatelist) ; 
                  if (v && v.name) changeOperator(v.key);
                  else
                    changeOperator(
                      (v && v.lookupItemName) ||
                        (v && v.value) ||
                        (v && v?.id) ||
                        v
                    );

                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    if((item && item.data && item.data.lookup) || (item.lookup))
                    lookupItems((item && item.data && item.data.lookup) || (item.lookup), value);
                  }, 700);
                }}
                isLoading={isLoading}
                withLoader
                withoutSearchButton
                labelValue={DisplayCaptionInAdvanceSearch(item) || item.label}
                filterOptions={(options) =>options}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
                
              />
          
        </>
    );
};
