import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    wapperReminderTypeOptions: {
      width: "100%",
      borderRadius: "8px",
      boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
      height: "40px",
      "& .MuiButtonBase-root": {
        backgroundColor: theme.palette.background.paper,
        padding: "10px 12px",
        borderColor: theme.palette.border.primary,
        width: "100%",
      },"&.selectedOption": {
        backgroundColor: "#000000",
        color: "#FFFFFF",
      }
    },
    isActiveBg:{
      backgroundColor: `${theme.palette.background.primary_hover} !important`,
    },activeTitle:{
      color : `${theme.palette.text.secondary_hover} !important`
    },
    containerReminderOptionDeatails: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "8px",
    },
    iconWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    text: {
      fontSize: "14px",
      fontWeight: "600",
      lineHeight: "20px",
      color: theme.palette.text.secondary,
    }
  };
});
