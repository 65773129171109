import React, { useEffect, useReducer, useState } from 'react';
import {
  DialogComponent,
  Inputs,
  PhonesComponent,
  Spinner,
  UploaderComponent,
} from '../../../../../../../../../../Components';
import { useTranslation } from 'react-i18next';
import LookupAutocomplete from '../../../../../../../../../../Components/Controls/LookupAutocomplete/LookupAutocomplete';
import { ContactClassIdEnum } from '../../../../../../../../../../Enums';
import { StaticLookupsIds } from '../../../../../../../../../../assets/json/StaticLookupsIds';
import { CreateContactCorporate } from '../../../../../../../../../../Services';
import { showError } from '../../../../../../../../../../Helper';
import { useContactValidationContext } from '../../../../../../../../../../Contexts/ContactValidationContext/ContactValidationContext';
import { useSuccessDialog } from '../../../../../../../../../../SharedComponents';

const reducer = (state, { field, value }) => {
  return { ...state, [field]: value };
};
const AddCorporateContactDialog = ({
  open,
  onClose,
  parentTranslationPath,
}) => {
  const {
    data: { companyName, landline },
  } = useContactValidationContext();
  const { showDialog } = useSuccessDialog();
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    classifications: [],
    country: {},
    city: {},
    landline: [],
    language: {},
    logoGUID: null,
    companyName: '',
    website: '',
  });
  const { t } = useTranslation(parentTranslationPath);
  const saveHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const body = {
        classifications: state.classifications.map((e) => e.lookupItemId),
        countryId: state.country.lookupItemId,
        cityId: state.city.lookupItemId,
        landlineNumber: state.landline,
        languageId: state.language.lookupItemId,
        logoGUID: state.logoGUID,
        companyName: state.companyName,
        website: state.website,
      };
      const result = await CreateContactCorporate(body);
      showDialog(t('GlobalSuccessDialog:contact-successfully'), {
        name: result.companyName,
        id: result.contactId,
      });
      onClose();
    } catch (error) {
      showError('create_failed');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    dispatch({ field: 'companyName', value: companyName });
    dispatch({ field: 'landline', value: [landline] });
  }, [companyName, landline]);
  return (
    <DialogComponent
      isOpen={open}
      onCancelClicked={onClose}
      onCloseClicked={onClose}
      titleText={t('add_corporate_contact')}
      onSaveClicked={saveHandler}
      parentTranslationPath={parentTranslationPath}
      dialogContent={
        <div className='d-flex flex-wrap gap-12 fj-around pb-2'>
          <Spinner isActive={isLoading} isAbsolute />
          <Inputs
            labelValue={'company_name'}
            wrapperClasses={'w-25'}
            value={state.companyName}
            readOnly
            parentTranslationPath={parentTranslationPath}
          />
          <LookupAutocomplete
            lookupTypeId={StaticLookupsIds.Country}
            selectedValues={state.country}
            onChange={(_e, newValues) =>
              dispatch({ field: 'country', value: newValues })
            }
            label={'Country'}
            parentTranslationPath={parentTranslationPath}
          />
          <LookupAutocomplete
            lookupTypeId={StaticLookupsIds.Cities}
            selectedValues={state.city}
            onChange={(_e, newValues) =>
              dispatch({ field: 'city', value: newValues })
            }
            lookupParentId={state.country?.lookupItemId}
            label={'City'}
            parentTranslationPath={parentTranslationPath}
          />
          <PhonesComponent
            value={state.landline[0]}
            isDisabled
            wrapperClasses={'w-25'}
            labelValue={'landline'}
          />
          <LookupAutocomplete
            lookupTypeId={StaticLookupsIds.Languages}
            selectedValues={state.language}
            onChange={(_e, newValues) =>
              dispatch({ field: 'language', value: newValues })
            }
            label={'language'}
            wrapperClasses={'w-25'}
            parentTranslationPath={parentTranslationPath}
          />
          <LookupAutocomplete
            lookupTypeId={ContactClassIdEnum.lookupTypeId}
            multiple
            selectedValues={state.classifications}
            onChange={(_e, newValues) =>
              dispatch({ field: 'classifications', value: newValues })
            }
            label={'contact_class'}
            parentTranslationPath={parentTranslationPath}
          />
          <Inputs
            labelValue={'website'}
            wrapperClasses={'w-75'}
            type={'url'}
            value={state.website}
            onInputChanged={(e) =>
              dispatch({ field: 'website', value: e.target.value })
            }
            parentTranslationPath={parentTranslationPath}
          />
          <UploaderComponent
            accept={'image/*'}
            wrapperClasses={'uploader-wrapper w-75'}
            labelValue={'logo'}
            uploadedChanged={(e) =>
              dispatch({ field: 'logoGUID', value: e?.[0]?.uuid })
            }
            parentTranslationPath={parentTranslationPath}
          />
        </div>
      }
    />
  );
};
export default AddCorporateContactDialog;
