import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  new_merge_cards_wrapper: {
    padding: "50px 0px 0px 0px",
  },

  new_merge_cards_inner_wrapper: {},

  contact_image_styles: {
    width: "68px",
    height: "68px",
    maxWidth: "68px",
    maxHeight: "68px",
    borderRadius: "100px",
    backgroundColor: theme.palette.background.secondary,
  },

  merge_contacts_cards_wrapper: {
    display: "flex",
    gap: "32px",
    margin: "16px 0px",
    height: "100%",
    width: "100%",
    // overflowX: "auto",
    // overflowY: "hidden",
    // whiteSpace: "nowrap",
    maxWidth: "1776px",
    "& > *": {
      border: `1px solid ${theme.palette.border.secondary}`,
      borderRadius: "12px",
    },
    "& > *:nth-child(1)": {
      border: `1px solid ${theme.palette.foreground.brandPrimary}`,
      borderRadius: "12px",
    },
  },

  contact_merge_cards_rows_container: {
    cursor: "pointer",
    color: theme.palette.text.tertiary,
    border: `1px solid ${theme.palette.border.secondary}`,
    padding: "16px 0px 0px 0px",
    borderRadius: "0px 0px 12px 12px",
    "& > *:nth-child(odd)": {
      backgroundColor: theme.palette.background.secondary,
    },
    "& > *:nth-child(even)": {
      backgroundColor: theme.palette.background.primary,
    },

    "& > *:nth-last-child(1)": {
      borderRadius: "0% 0% 80% 80%",
    },
  },

  contact_merge_cards_inner_wrapper: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: "12px",
    backgroundColor: theme.palette.background.primary,
  },

  contact_merge_cards_upper_section: {
    position: "relative",
    display: "flex",
    width: "100%",
    padding: "48px 0px 16px 0px",
    alignContent: "center",
    justifyContent: "center",
    gap: "8px",
    color: theme.palette.text.tertiary,
  },

  contact_merge_cards_rows: {
    height: "36px",
    maxHeight: "fit-content",
    width: "100%",
    padding: "8px 16px",
    display: "flex",
    alignContent: "center",
    justifyContent: "space-between",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    whiteSpace: "nowrap",
  },

  contact_merge_cards_full_wrapper: {
    position: "relative",
    width: "420px",
    maxWidth: "420px",
    [theme.breakpoints.down("1500")]: {
      width: "309px",
    },
  },

  merge_contacts_cards_field_name: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
  },

  merge_contacts_cards_radio_field: {
    fontSize: "16px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    gap: "8px",

    color: theme.palette.text.tertiary,
    "& .MuiRadio-root": {
      color: theme.palette.background.brand_solid,
    },
    "& .MuiTypography-root": {
      color: theme.palette.text.tertiary,
      opacity: "0.5",
    },
  },

  merge_contacts_card_final_contact_title: {
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "32px",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("1500")]: {
      fontFamily: "Inter",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "32px",
    },
  },
  merge_contacts_card_original_contact_title: {
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "32px",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("1500")]: {
      fontFamily: "Inter",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "32px",
    },
  },
  merge_contacts_card_archive_contact_title: {
    fontSize: "24px",
    fontWeight: "600",
    lineHeight: "32px",
    color: theme.palette.text.error_primary,
    [theme.breakpoints.down("1500")]: {
      fontFamily: "Inter",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "32px",
    },
  },

  current_merge_contact_badge: {
    position: "absolute",
    top: "16px",
    right: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "2px 10px",
    width: "71px",
    maxWidth: "71px",
    height: "24px",
    maxHeight: "24px",
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: "100px",
  },
  swap_merge_contact_badge: {
    position: "absolute",
    top: "-40px",
    right: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 12px",
    width: "44px",
    maxWidth: "44px",
    height: "36px",
    maxHeight: "36px",
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: "8px",
    cursor: "pointer",
  },
  new_contacts_cards_merge_pagination: {
    display: "flex",
    position: "fixed",
    bottom: "10px",
    right: "20px",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "end",
    gap: "24px",
  },

  new_merge_contacts_cards_arrows: {
    backgroundColor: theme.palette.background.gray_50,
    border: "0px",
    padding: "8px",
    height: "44px",
    width: "44px",
    borderRadius: "100px",
  },
  merge_contacts_cards_field_value: {
    minHeight: "fit-content",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    whiteSpace: "normal",
    wordBreak: "break-word",
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    flexWrap: "wrap",
    color: theme.palette.text.primary,
  },
  avatarWrapper: {
    alignItems: "center",
    backgroundColor: "#FFF",
    borderRadius: "50%",
    boxShadow:
      "0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06)",
    display: "flex",
    height: "80px",
    justifyContent: "center",
    marginLeft: "33.33%",
    position: "relative",
    width: "80px",
  },
  defaultAvatar: {
    background: theme.palette.background.secondary_hover,
    border: `1px solid ${theme.palette.border.avatarContrast}`,
    height: "68px",
    width: "68px",

    "& img": {
      height: "36px",
      width: "36px",
    },
  },
  avatar: {
    background: theme.palette.background.secondary_hover,
    border: `1px solid ${theme.palette.border.avatarContrast}`,
    height: "68px",
    width: "68px",
  },

  contact_avatar_styles: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "-40px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "72px",
    height: "72px",
    border: `2px solid ${theme.palette.border.secondary}`,
    borderRadius: "50px",
    margin: "0 auto",
  },
}));
