import React, { useCallback } from "react";
import {
  CustomBadge,
  CustomCard,
  CustomCopyToClipboard,
  CustomIconButton,
} from "../../../../../../../../Components";
import { Box, Typography } from "@material-ui/core";
import { useSelectedTheme, useTranslate } from "../../../../../../../../Hooks";
import {
  ArrowUpRight,
  BathIcon,
  BedIcon,
  EmptyUnitGalleryIcon,
  UnitSizeIcon,
} from "../../../../../../../../assets/icons";
import { getDownloadableLink } from "../../../../../../../../Helper";
import { formatCommasForPriceUnit } from "../../../../../../../../Helper/formatCommas.Helper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// styles
import useStyles from "./styles";

function UnitPropertyCard({ dataUnit }) {
  const styles = useStyles();
  const { translate } = useTranslate("NewPropertiesView");
  const history = useHistory();
  const {
    theme: { palette },
  } = useSelectedTheme();

  const handleOpenUnit = useCallback(() => {
    const unitOperationTypeList = ["Sale", "Rent", "SaleAndRent"];
    const unitOperationType =
      dataUnit &&
      dataUnit.operation_type &&
      dataUnit.operation_type.lookupItemName;

    if (unitOperationType === unitOperationTypeList[0]) {
      history.push(
        `/home/units-sales/unit-profile-edit?formType=${dataUnit.unitTypeId}&id=${dataUnit.id}`
      );
    } else if (unitOperationType === unitOperationTypeList[1]) {
      history.push(
        `/home/units-lease/unit-profile-edit?formType=${dataUnit.unitTypeId}&id=${dataUnit.id}`
      );
    } else if (unitOperationType === unitOperationTypeList[2]) {
      history.push(
        `/home/units-sales/unit-profile-edit?formType=${dataUnit.unitTypeId}&id=${dataUnit.id}`
      );
    } else {
      history.push(
        `/home/units/unit-profile-edit?formType=${dataUnit.unitTypeId}&id=${dataUnit.id}`
      );
    }
  }, [dataUnit]);

  return (
    <CustomCard
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.wapperunitCardItem}
    >
      <Box className={styles.containerImage}>
        {dataUnit?.allunitImages?.fileId ? (
          <img
            className={styles.imageWrapper}
            src={getDownloadableLink(dataUnit?.allunitImages?.fileId)}
            alt={dataUnit?.allunitImages?.fileName}
          />
        ) : (
          <EmptyUnitGalleryIcon
            width="52"
            height="52"
            fill={palette.foreground.quinary}
          />
        )}
      </Box>
      <Box className={styles.containerBodyCard}>
        <Box className={styles.containerTopSection}>
          <Box className={styles.containerUnitPrice}>
            <Box className={styles.containerPriceTitle}>
              <Typography className={styles.containerPrice}>
                {formatCommasForPriceUnit(dataUnit?.selling_price_agency_fee) ||
                  "N/A"}
              </Typography>
              <Typography className={styles.containerType}>
                {translate("AED")}
              </Typography>
            </Box>
            <CustomIconButton
              variant="text"
              size="xs"
              boxShadow="none"
              color="secondary"
              onClick={() => {
                handleOpenUnit();
              }}
            >
              <ArrowUpRight
                width="20"
                height="20"
                fill={palette.button.secondaryGray_fg}
              />
            </CustomIconButton>
          </Box>
          <Typography className={styles.titleUnit}>{dataUnit?.name}</Typography>
          <Box className={styles.containerUnitTypeAndCopy}>
            <CustomCopyToClipboard
              data={dataUnit?.refNo}
              childrenData={"#" + dataUnit?.refNo}
              copyDoneShowTime={1000}
            />
            <Typography className={styles.unitTypeTitle}>
              {dataUnit?.unitType}
            </Typography>
          </Box>
          <Typography className={styles.containerAddressInfo}>
            {`${dataUnit?.community?.lookupItemName || "N/A"}` +
              " , " +
              `${dataUnit?.sub_community?.lookupItemName || "N/A"}`}
          </Typography>
        </Box>
        <Box className={styles.containerBottomSection}>
          <Box className={styles.containerInfoSection}>
            <CustomBadge
              label={`${dataUnit?.progressWithPercentage}`}
              SizeVariant={"small"}
              BackgroundColor={palette.utility["brand_50"]}
              BorderColor={palette.utility["brand_200"]}
              Color={palette.utility["brand_700"]}
            />
            <CustomBadge
              label={dataUnit?.unitStatus?.name}
              SizeVariant={"small"}
              BackgroundColor={palette.utility["gray_blue_50"]}
              BorderColor={palette.utility["gray_blue_200"]}
              Color={palette.utility["gray_blue_700"]}
            />
            <Typography className={styles.forSaleLable}>
              {translate("For Sale")}
            </Typography>
          </Box>
          <Box className={styles.detailsUnits}>
            <Box className={styles.containerUnitItem}>
              <UnitSizeIcon
                width="20"
                height="20"
                fill={palette.foreground.quinary}
              />
              <Typography className={styles.UnitItemDetails}>
                {`${dataUnit?.flatContent?.[2].value} sq.ft` || "N/A"}
              </Typography>
            </Box>
            <Box className={styles.containerUnitItem}>
              <BedIcon
                width="20"
                height="20"
                fill={palette.foreground.quinary}
              />
              {(dataUnit?.bedrooms && (
                <Typography className={styles.UnitItemDetails}>{`${
                  dataUnit?.bedrooms || ""
                }`}</Typography>
              )) ||
                "N/A"}
            </Box>
            <Box className={styles.containerUnitItem || ""}>
              <BathIcon
                width="20"
                height="20"
                fill={palette.foreground.quinary}
              />
              {(dataUnit?.bathrooms && (
                <Typography
                  className={styles.UnitItemDetails}
                >{`${dataUnit?.bathrooms}`}</Typography>
              )) ||
                "N/A"}
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomCard>
  );
}
export default UnitPropertyCard;
