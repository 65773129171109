import { makeStyles } from "@material-ui/core/styles";
export default makeStyles((theme) => ({
  wrapperAddNewActivityForm: {
    display: "flex",
    flexDirection: "row",
  },
  containerInfoActivitey: {
    display: "flex",
    flexDirection: "column",
    width: "336px",
    minHeight: "724px",
    background: theme.palette.background.hover,
    padding: "20px 24px",
    gap: "16px",
  },
  containerfiledActivity: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "100%",
    padding: "20px 24px",
  },
  containerInfoItem: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  containerContentDialog: {
    padding: `20px 0px 0px 0px !important`,
  },
  containerInfoTitle: {
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "20px",
    color: theme.palette.text.primary,
  },
  containerInfoValue: {
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    color: theme.palette.text.secondary,
  },
  containerCreatedBy: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    alignItems: "center",
  },
  containerInputFiled: {
    width: "100%",
  },
  titleInputFiled: {
    fontSize: "14px",
    fontWeight: "600",
    lineHeight: "20px",
    color: theme.palette.text.secondary,
    minWidth: "160px",
    maxWidth: "280px",
  },
  containerInputFiled: {
    display: "flex",
    flexDirection: "row",
    gap: "32px",
  },
  containerActivityRelatedToList: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  containerImage: {
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    overflow: "hidden",
  },
  ImageWrapper: {
    width: "100%",
    height: "100%",
  },
  containerTitiles: {
    display: "flex",
    flexDirection: "column",
  },
  subTitleInputFiled: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "20px",
    color: theme.palette.text.tertiary,
    minWidth: "160px",
    maxWidth: "280px",
  },
  containerMoreOption: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.background.hover,
    padding: "8px",
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  containerMoreOptionDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  containerTitles: {
    display: "flex",
    flexDirection: "column",
  },
  containerSwitchAndTitle: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
  },
  containerActivityDateTime: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    width: "100%",
  },
  containerSetReminderInputFiled: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    width: "100%",
  },
  containerTimeActivity: {
    width: "100%",
  },
  containerDateActivity: {
    width: "100%",
  },
  containerAddReminders: {
    display: "flex",
    alignItems: "flex-start",
  },
  containerTableReminder: {
    border: `1px solid ${theme.palette.border.secondary}`,
    borderRadius: "8px",
  },
  containerTableHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: theme.palette.background.secondary,
    height: "44px",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
  },
  containerTitleHeader: {
    padding: "12px 24px",
    width: "90px",
  },
  containerActionButton: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
  },
  containerIconActionButton: {
    padding: "10px",
  },
  containerTableBody: {
    display: "flex",
    flexDirection: "column",
  },
  containerTableRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    background: theme.palette.background.paper,
    height: "64px",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.border.secondary}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
  containerDuratinActivity: {
    width: "100%",
  },
  forValue: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "120px",
    display: "block",
    padding: "16px 22px"
  },
  containerRimenderTitleHeader: {
    width: "180px",
  },
  reminderValue: {
    width: "210px",
  },
  timeReminderForPersonStyles :{
    fontSize:'16px',
    fontWeight:'500',
    lineHeight:'24px',
    color:theme.palette.text.primary
  },
  typeTitleReminderStyles :{
    fontSize:'14px',
    fontWeight:'400',
    lineHeight:'20px',
    color:theme.palette.text.tertiary
  },MoreOptionTitle:{
    fontWeight:'500',
    fontSize:'18px',
    color:`${theme.palette.text.primary}`,
    lineHeight:'28px'
  },statusTitle:{
    fontWeight:'400',
    fontSize:'16px',
    color:`${theme.palette.text.tertiary}`,
    lineHeight:'24px'
  }
}));
