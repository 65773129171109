import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { ReskinningInputs } from "../ReskinningInputs";
import { useSelectedTheme } from "../../../Hooks";

// Styles
import useStyles from "./styles";

// Icons
import { ChevronDown, CloseXIcon } from "../../../assets/icons";

function ReskinningAutocomplete(props) {
  const {
    onChange,
    onOpen,
    data,
    displayLabel,
    defaultValue,
    isLoading,
    selectedValues,
    inputValue,
    onInputChange,
    disabledOptions,
    chipsLabel,
    chipsDisabled,
    translationPath,
    parentTranslationPath,
    labelValue,
    inputPlaceholder,
    idRef,
    autocompleteClasses,
    variant,
    multiple,
    isDisabled,
    searchClicked,
    isRequired,
    labelClasses,
    inputWrapperClasses,
    helperText,
    error,
    inputLabel,
    renderOption,
    withLoader,
    withoutSearchButton,
    buttonOptions,
    disableClearable,
    renderTags,
    isWithError,
    isSubmitted,
    paddingReverse,
    inputStartAdornment,
    beforeIconClasses,
    afterIconClasses,
    overInputIcon,
    themeClass,
    inputThemeClass,
    getOptionSelected,
    onInputKeyUp,
    onTextKeyDown,
    onTextKeyUp,
    tagValues,
    filterOptions,
    groupBy,
    inputEndAdornment,
    autoComplete,
    textValue,
    hideDeleteMark,
    filterSelectedOptions,
    helperInfo,
  } = props;

  const {
    theme: { palette },
  } = useSelectedTheme();

  const isShowClearIndicator  = multiple
    ? Array.isArray(selectedValues) && selectedValues.length > 0
    : selectedValues && (typeof selectedValues !== "object" || Object.keys(selectedValues).length > 0)

  const styles = useStyles({ isShowClearIndicator });

  return (
    <div>
      {!withoutSearchButton && (
        <Button
          className="btns-icon theme-transparent mx-2"
          onClick={searchClicked}
        >
          <span className="mdi mdi-magnify" />
        </Button>
      )}
      <Autocomplete
        multiple={multiple}
        disableClearable={disableClearable}
        autoComplete={autoComplete}
        id={idRef || labelValue || inputPlaceholder || inputLabel || ""}
        className={`autocomplete ${autocompleteClasses}`}
        options={data}
        classes={{
          root: styles.root,
          paper: styles.popover, // Apply the custom popover styling
          option: styles.option, // Apply the custom option styling
          listbox: styles.listbox, // Styles for the <ul> element
          popupIndicator: styles.popupIndicator, // Override popup icon styles
          clearIndicator: styles.clearIndicator, // Hides the default clear button
          endAdornment: styles.endAdornment, // Styles for the endAdornment
        }}
        groupBy={groupBy}
        onKeyUp={onInputKeyUp}
        getOptionLabel={displayLabel}
        renderOption={renderOption}
        defaultValue={defaultValue}
        getOptionSelected={getOptionSelected}
        filterSelectedOptions={filterSelectedOptions}
        loading={isLoading}
        onOpen={onOpen}
        value={selectedValues}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onChange={onChange}
        getOptionDisabled={disabledOptions}
        disabled={isDisabled}
        filterOptions={filterOptions}
        popupIcon={
          <ChevronDown
            width="24"
            height="24"
            fill={palette.foreground.quarterary}
          />
        }
        limitTags={2}
        renderTags={
          renderTags ||
          ((tagValue, getTagProps) =>
            (tagValues || tagValue).map(
              (option, index) =>
                (!hideDeleteMark && (
                  <Chip
                    label={chipsLabel && chipsLabel(option, index)}
                    {...getTagProps({ index })}
                    disabled={chipsDisabled(option, index)}
                    onDelete={hideDeleteMark}
                    className={styles.chip}
                  />
                )) || (
                  <Chip
                    label={chipsLabel && chipsLabel(option, index)}
                    {...getTagProps({ index })}
                    disabled={chipsDisabled(option, index)}
                    deleteIcon={
                      <CloseXIcon
                        className={styles.deleteTagIcon}
                        fill={palette.foreground.quinary}
                      />
                    }
                    className={styles.chip}
                  />
                )
            ))
        }
        renderInput={(params) => (
          <ReskinningInputs
            idRef={idRef || inputLabel || inputPlaceholder || labelValue || ""}
            autoComplete={autoComplete}
            label={inputLabel}
            labelValue={labelValue}
            labelClasses={labelClasses}
            autoCompleteParams={params}
            value={textValue}
            inputPlaceholder={inputPlaceholder}
            variant={variant}
            isWithError={isWithError}
            isSubmitted={isSubmitted}
            paddingReverse={paddingReverse}
            isRequired={isRequired}
            wrapperClasses={inputWrapperClasses}
            fieldClasses={styles.input}
            startAdornment={inputStartAdornment}
            helperInfo={helperInfo}
            endAdornment={inputEndAdornment}
            beforeIconClasses={beforeIconClasses}
            afterIconClasses={afterIconClasses}
            overInputIcon={overInputIcon}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            buttonOptions={buttonOptions}
            themeClass={themeClass || inputThemeClass}
            onKeyDown={onTextKeyDown}
            onKeyUp={onTextKeyUp}
            isLoading={isLoading}
            withLoader={withLoader}
            error={error}
            helperText={helperText}
          />
        )}
      />
    </div>
  );
}

ReskinningAutocomplete.propTypes = {
  autoComplete: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  data: PropTypes.instanceOf(Array).isRequired,
  displayLabel: PropTypes.func.isRequired,
  tagValues: PropTypes.instanceOf(Array),
  disableClearable: PropTypes.bool,
  chipsLabel: PropTypes.func,
  renderTags: PropTypes.func,
  getOptionSelected: PropTypes.func,
  groupBy: PropTypes.func,
  renderOption: PropTypes.func,
  searchClicked: PropTypes.func,
  onInputKeyUp: PropTypes.func,
  onTextKeyDown: PropTypes.func,
  onTextKeyUp: PropTypes.func,
  textValue: PropTypes.string,
  selectedValues: PropTypes.oneOfType([
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(Object),
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
  ]),
  defaultValue: PropTypes.instanceOf(Array),
  isLoading: PropTypes.bool,
  multiple: PropTypes.bool,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  filterOptions: PropTypes.func,
  disabledOptions: PropTypes.func,
  chipsDisabled: PropTypes.func,
  parentTranslationPath: PropTypes.string,
  translationPath: PropTypes.string,
  labelValue: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  idRef: PropTypes.string,
  autocompleteClasses: PropTypes.string,
  variant: PropTypes.string,
  inputWrapperClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  helperText: PropTypes.string,
  inputLabel: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  error: PropTypes.bool,
  withLoader: PropTypes.bool,
  withoutSearchButton: PropTypes.bool,
  isWithError: PropTypes.bool,
  isSubmitted: PropTypes.bool,
  paddingReverse: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputStartAdornment: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.node,
  ]),
  inputEndAdornment: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.func,
    PropTypes.node,
  ]),
  beforeIconClasses: PropTypes.string,
  afterIconClasses: PropTypes.string,
  overInputIcon: PropTypes.string,
  themeClass: PropTypes.string,
  inputThemeClass: PropTypes.string,
  hideDeleteMark: PropTypes.bool,
  buttonOptions: PropTypes.shape({
    className: PropTypes.string,
    iconClasses: PropTypes.string,
    onActionClicked: PropTypes.func,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
  }),
  filterSelectedOptions: PropTypes.bool,
  helperInfo: PropTypes.string,
};

ReskinningAutocomplete.defaultProps = {
  autoComplete: "new-password",
  defaultValue: undefined,
  selectedValues: undefined,
  tagValues: undefined,
  isLoading: false,
  inputValue: undefined,
  getOptionSelected: undefined,
  onInputChange: undefined,
  groupBy: undefined,
  renderOption: undefined,
  renderTags: undefined,
  searchClicked: undefined,
  disabledOptions: undefined,
  chipsDisabled: () => false,
  parentTranslationPath: "",
  translationPath: "",
  labelValue: null,
  inputPlaceholder: null,
  idRef: "autocompleteRef",
  autocompleteClasses: "",
  variant: "outlined",
  inputWrapperClasses: undefined,
  labelClasses: undefined,
  withoutSearchButton: false,
  onInputKeyUp: undefined,
  chipsLabel: undefined,
  helperText: null,
  inputLabel: null,
  multiple: true,
  isDisabled: false,
  isRequired: false,
  error: false,
  withLoader: true,
  buttonOptions: null,
  disableClearable: false,
  isWithError: false,
  isSubmitted: false,
  hideDeleteMark: true,
  paddingReverse: undefined,
  inputStartAdornment: undefined,
  inputEndAdornment: undefined,
  beforeIconClasses: undefined,
  afterIconClasses: undefined,
  overInputIcon: undefined,
  themeClass: undefined,
  inputThemeClass: undefined,
  filterOptions: undefined,
  filterSelectedOptions: false,
  helperInfo: undefined,
};

export default ReskinningAutocomplete;
