export const PaymentPlansStyle = `
.PaymentPlanTable_Container {
  display: flex;
  flex-direction: column;
  width: 2480px;
  justify-content: center;
  align-items: center;
}

.title {
  color: var(--2-d-3-e-50, #2D3E50);
  font-size: 72px;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.PaymentPlanTable {
  width: 100%;
  padding: 120px 120px 174px 120px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

th {
  font-weight: 700;
}

tr {
  font-weight: 400;
}

tfoot {
  background: #F0F0F0;
}

tfoot td {
  font-weight: 700;
}

td, th {
  border: 1px solid #E5E5EA;
  padding: 8px;
  color: var(--2-d-3-e-50, #2D3E50);
  font-size: 28.406px;
  line-height: 36.522px;
  text-align: center;
  vertical-align: middle;
  height: 81px;
  text-align: left;
  padding: 20px;
}
`;
