import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxesComponent, SwitchComponent } from "../../../../../Components";
import DragDropPicker from "../../Utilities/DragDropPicker/DragDropPicker";
import { getPublicDownloadableLink } from "../../../../../Helper";
import { UnitFinancialFees } from "../../Enums/UnitFinancialFees";

const UnitsDetailsForm = ({ localFormChangeHandler, localFormSelection }) => {
  const parentTranslationPath = "SmartProposal";
  const translationPath = "UnitsDetailsForm.";
  const { t } = useTranslation(parentTranslationPath);
  
  const initializeUnitImages = () => {
    const selectedImageId = localFormSelection?.unitImage?.id;
    const publishedImages = [
      ...(localFormSelection?.data?.unitPublishedImages || []),
    ];

    const targetIndex = 0;
    const selectedImageItem = publishedImages.find(
      (item) => item.fileId == selectedImageId
    );
    const selectedImageIndex = publishedImages.findIndex(
      (item) => item.fileId == selectedImageId
    );

    if (selectedImageIndex !== -1) {
      const checkedItem = {
        ...selectedImageItem,
        isChecked: true,
      };
      publishedImages.splice(selectedImageIndex, 1);
      publishedImages.splice(targetIndex, 0, checkedItem);
    }
    return publishedImages;
  };

  const unitPublishedImages = useMemo(
    () => initializeUnitImages() || [],
    [localFormSelection]
  );

  const unitImageSelectHandler = useCallback((result) => {
    const defaultUnitImage = {
      url: "https://psi-crm.com/ProposalIcons/defaultCover.jpg",
      id: null,
    };

    const pickedImageId =
      result && Array.isArray(result) && result[0] && result[0]?.fileId;
    const unitImageURL = pickedImageId
      ? getPublicDownloadableLink(pickedImageId)
      : null;

    const unitImage = unitImageURL
      ? {
          url: unitImageURL,
          id: pickedImageId,
        }
      : defaultUnitImage;

    localFormChangeHandler("unitImage", unitImage);
  }, [localFormChangeHandler]);




  const financialFeesChangeHandler = (checkedItem) => {
    const checkedItemKey = checkedItem?.key;
    const checkedItemValue = localFormSelection?.financialFees[checkedItemKey] || false;

    const localFinancialFees = {
      ...(localFormSelection?.financialFees),
      [checkedItemKey]: !checkedItemValue,
    }

    localFormChangeHandler("financialFees", localFinancialFees);
  };

  const returnCheckedItemValue = (checkedItem) => {
    const checkedItemKey = checkedItem?.key;
    const checkedItemValue = localFormSelection?.financialFees[checkedItemKey] || false;
    return checkedItemValue;
  }
  

  return (
    <>
      <div className="ProposalForm__field_box">
        <div className="ProposalForm__field_label">
          <p>Unit image</p>
        </div>
        <div>
          <DragDropPicker
            onChange={unitImageSelectHandler}
            itemsList={unitPublishedImages}
            maxChecked={1}
          />
        </div>
      </div>
      <div className="ProposalForm__field_box">
        <div className="ProposalForm__field_label">
          <p>Financial fees</p>
          {/* <p>{t(`${translationPath}-`)}</p> */}
        </div>
        <div>
          <SwitchComponent
            idRef="showFinancialFeesRef"
            wrapperClasses="mb-3"
            themeClass="thick-theme"
            isChecked={localFormSelection?.showFinancialFees || false}
            labelValue={`${localFormSelection?.showFinancialFees ? "show" : "hide"}`}
            parentTranslationPath={parentTranslationPath}
            translationPath={""}
            onChangeHandler={(_, isChecked) =>
              localFormChangeHandler("showFinancialFees", isChecked)
            }
          />
          <CheckboxesComponent
            idRef="isPublishUnitSaleRef"
            themeClass="theme-propx"
            data={UnitFinancialFees}
            isRow
            onSelectedCheckboxChanged={financialFeesChangeHandler}
            parentTranslationPath={parentTranslationPath}
            translationPathForData={""}
            labelInput="label"
            checked={returnCheckedItemValue}
          />
        </div>
      </div>
    </>
  );
};

export default UnitsDetailsForm;
