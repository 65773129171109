import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Joi from "joi";
import {
  DialogComponent,
  Inputs,
  Spinner,
  AutocompleteComponent,
} from "../../../../Components";
import { MessageConfirmDialog } from "../../../../SharedComponents";
import { showError, GlobalTranslate, showSuccess } from "../../../../Helper";
import { GetAllHubSpotListsAPI, ImportContactsAPI } from "../../../../Services";
import { AddHubSpotListComponent } from "./AddHubSpotListComponent";

export const HubspotComponentView = ({
  parentTranslationPath,
  translationPath,
  isOpen,
  onClose,
  onSave,
  state,
  createHubSpotJson,
  totalCount,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [isLoading, setIsLoading] = useState({
    importContacts: false,
    allHubSpotList: false,
  });

  const [isMissingInfoMSGOpen, setIsMissingInfoMSGOpen] = useState(false);
  const [allHubSpotList, setAllHubSpotList] = useState({
    lists: [],
    totalCount: 0,
  });

  const [isOpenAddHubSpotList, setIsOpenAddHubSpotList] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [helperText, setHelperText] = useState({
    listName: "",
  });

  const [selected, setSelected] = useState({
    listName: null,
  });
  const [importContactsState, setImportContactsState] = useState({
    listId: null,
    pageIndex: 1,
    pageSize: totalCount,
  });

  const schema = Joi.object({
    listId: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}hubspot-list-is-required`),
        "number.empty": t(`${translationPath}hubspot-list-is-required`),
        "number.required": t(`${translationPath}hubspot-list-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(importContactsState);

  const saveHandler = useCallback(async () => {
    setIsLoading((item) => ({ ...item, importContacts: true }));
    const res = await ImportContactsAPI(
      { ...importContactsState },
      {
        spName: "GetSegmentationContacts",
        reportJson: { ...createHubSpotJson },
        systemReportCategoryTypeId: null,
        reportId: null,
        systemReportName: null,
      }
    );
    setIsLoading((item) => ({ ...item, importContacts: false }));
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}the-background-pull-process-that-is-running`));
      setIsMissingInfoMSGOpen(true);
    } else showError(t(`${translationPath}pull-contacts-to-hubSpot-faild`));
    setIsLoading((item) => ({ ...item, importContacts: false }));
  }, [createHubSpotJson, importContactsState]);

  useEffect(() => {
    if (!isOpen) {
      setImportContactsState({
        listId: null,
        pageSize: totalCount,
        pageIndex: 1,
      });
    }
  }, [isOpen, totalCount]);

  const GetAllHubSpotList = useCallback(async () => {
    setIsLoading((item) => ({ ...item, allHubSpotList: true }));
    const res = await GetAllHubSpotListsAPI();
    if (!(res && res.status && res.status !== 200)) {
      setAllHubSpotList({
        lists: (res && res.lists) || [],
        totalCount: (res && res.lists && res.lists.length) || 0,
      });
    } else
      setAllHubSpotList({
        lists: [],
        totalCount: 0,
      });

    setIsLoading((item) => ({ ...item, allHubSpotList: false }));
  }, []);

  useEffect(() => {
    GetAllHubSpotList();
  }, [GetAllHubSpotList]);

  return (
    <>
      <DialogComponent
        saveText={GlobalTranslate.t("Shared:save")}
        disableBackdropClick
        titleText={t(`${translationPath}pull-contacts-to-existing-list`)}
        saveClasses={"btns theme-solid bg-primary"}
        saveType="button"
        maxWidth="sm"
        dialogContent={
          <div className="d-flex-column-center">
            <Spinner
              isActive={isLoading.importContacts}
              isAbsolute
            />
            <div className="w-100 mr-1-reversed mt-3">
              <AutocompleteComponent
                idRef={"allHubSpotList"}
                className="px-2"
                selectedValues={selected && selected.listName}
                labelValue={t(`${translationPath}hub-spot-list`)}
                data={allHubSpotList.lists || []}
                multiple={false}
                displayLabel={(option) => (option && option.name) || ""}
                chipsLabel={(option) => (option && option.name) || ""}
                withoutSearchButton
                buttonOptions={{
                  className: "btns-icon theme-solid bg-blue-lighter",
                  iconClasses: "mdi mdi-plus",
                  isRequired: false,
                  onActionClicked: () => {
                    setIsOpenAddHubSpotList(true);
                  },
                }}
                onChange={(event, newValue) => {
                  setSelected((item) => ({ ...item, listName: newValue }));
                  setImportContactsState((item) => ({
                    ...item,
                    listId: (newValue && newValue.listId) || null,
                  }));
                }}
                isLoading={isLoading.allHubSpotList}
                withLoader
              />
              {isOpenAddHubSpotList && (
                <AddHubSpotListComponent
                  isOpen={isOpenAddHubSpotList}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  onClose={() => setIsOpenAddHubSpotList(false)}
                  onSave={(res) => {
                    if (res) {
                      setSelected((item) => ({ ...item, listName: res }));
                      setImportContactsState((item) => ({
                        ...item,
                        listId: res && res.listId,
                      }));
                      setTimeout(() => {
                        GetAllHubSpotList();
                    }, 2000);

                    }
                  }}
                />
              )}
            </div>
          </div>
        }
        isOpen={isOpen}
        saveIsDisabled={
          isLoading.importContacts ||
          isLoading.allHubSpotList ||
          importContactsState.listId === null ||
          schema.error?.details?.length > 0
        }
        onSaveClicked={() => saveHandler()}
        onCancelClicked={onClose}
        translationPath={translationPath.confirm}
        onCloseClicked={onClose}
      />
      {isMissingInfoMSGOpen && (
        <MessageConfirmDialog
          isDialogOpen={isMissingInfoMSGOpen}
          theme="warning"
          content={{
            heading: "pull-contacts-to-hubspot",
            body: t(
              `${translationPath}in-background-pull-process-that-is-running-please-check-your-process-to-complete-and-wait-new-notification-soon`
            ),
          }}
          confirmButton={{
            label: t(`${translationPath}confirm`),
            handler: () => {
              onClose();
              setIsMissingInfoMSGOpen(false);
            },
          }}
          cancelButton={{
            label: t(`${translationPath}discard`),
            handler: () => {
              onClose();
              setIsMissingInfoMSGOpen(false);
            },
          }}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      )}
    </>
  );
};
