import { makeStyles } from "@material-ui/core/styles";
import { lineHeight } from "suneditor/src/plugins";

export default makeStyles((theme) => (
    {
        wrapperAddNewActivityModal :{
            display:'flex',
            flexDirection:'column',
            gap:'16px'
        },containerFiledItem:{
            display:'flex',
            flexDirection:'row',
            gap:'32px'  
        },titleItem:{
            fontSize:'14px',
            fontWeight:600,
            lineHeight:'20px',
            color :theme.palette.text.secondary,
            maxWidth:'260px',
            minWidth:'160px'
        }
    }
))