import { useCallback, useEffect, useReducer } from "react";
import {
  GetMultiProposalUnitsDetails,
  GetNearestLandmarks,
  GetProjectDescription,
  GetPropertyFacilitiesAndAmenities,
  GetPropertyLocation,
  GetPropertyPaymentPlans,
  GetPropertyPublishedImages,
  GetProposalContactDetails,
  GetProposalUnitDetails,
  GetUnitFloorPlanImages,
  GetUnitPublishedImages,
} from "../../../../Services/SmartProposal";
import useIsLoading from "./useIsLoading";
import useRedirectOnInvalid from "./useRedirectOnInvalid";

export const useGetUnitsData = (activeStepkey, activeProperty, resetForm = false) => {

  const propertyId = activeProperty?.propertyId;
  const units =  activeProperty?.units || [];
  const { isInvalidProposalInfo } = useRedirectOnInvalid();

  const initialDataState = {
    CoverPage: null,
    UnitsDetails: null,
    MultiUnitsDetails: null,
    PropertyDescription: null,
    UnitImages: null,
    LocationAndamenities: null,
    FloorPlan: null,
    PaymentPlan: null,
  }

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const { setIsLoading } = useIsLoading();

  const StepsFetchFunctions = {
    CoverPage: getCoverPageData,
    UnitsDetails: getUnitsDetailsData,
    MultiUnitsDetails: getMultiUnitsDetailsData,
    PropertyDescription: getPropertyDescriptionData,
    UnitImages: getUnitImagesData,
    LocationAndamenities: getLocationAndamenitiesData,
    FloorPlan: getFloorPlanData,
    PaymentPlan: getPaymentPlanData,
  };

  const fetchFunction = StepsFetchFunctions[activeStepkey];

  const [data, setData] = useReducer(reducer, initialDataState);

  console.log("useGetUnitsData RERENDER", propertyId, data);

  const getActiveStepData = async () => {
    setIsLoading(true);
    const stepData = await fetchFunction({ propertyId, unitId: units[0], unitIdList: units });
    setData({ id: activeStepkey, value: stepData });
    setIsLoading(false);
  };

  const resetData = () => {
    setData({ id: "edit", value: initialDataState })
  };

  useEffect(() => {
    if (!data[activeStepkey] && fetchFunction && !isInvalidProposalInfo) getActiveStepData();
  }, [activeStepkey]);

  useEffect(() => {
    if (resetForm) resetData();
  }, [resetForm]);

  return data;
};


//Util Functions
const checkIsSingleUnit = (unitIdList) => unitIdList?.length === 1;





// ============== Fetch Step Data Functions ===========

async function getCoverPageData({ propertyId }) {
  const propertyPublishedImages = await getPropertyPublishedImages(propertyId);
  const proposalContactDetails = await getProposalContactDetails(propertyId);
  return {
    propertyPublishedImages: propertyPublishedImages || [],
    proposalContactDetails,
  };
}

async function getUnitsDetailsData({ unitId }) {
  const unitsDetailsData = await getProposalUnitDetails(unitId);
  const unitPublishedImages = await getUnitPublishedImages(unitId);

  return {
    unitsDetailsData,
    unitPublishedImages: unitPublishedImages || [],
  };
}

async function getMultiUnitsDetailsData({ unitIdList }) {
  const body = unitIdList || [];
  const multiUnitsDetailsData = await getMultiProposalUnitsDetails(body);
  return {
    multiUnitsDetailsData: multiUnitsDetailsData || [],
  };
}

async function getPropertyDescriptionData({ propertyId }) {
  const projectDescriptionData = await getProjectDescription(propertyId);
  return {
    projectDescriptionData
  };
}

async function getUnitImagesData({ unitId, unitIdList, propertyId }) {
  const propertyPublishedImages = await getPropertyPublishedImages(propertyId);
  const unitPublishedImages = await getUnitPublishedImages(unitId);
  const isSingleUnit = checkIsSingleUnit(unitIdList);
  return {
    unitPublishedImages: unitPublishedImages || [],
    propertyPublishedImages: propertyPublishedImages || [],
    isSingleUnit,
  };
}

async function getLocationAndamenitiesData({ propertyId, unitIdList }) {

  let unitsIds = [];
  if(checkIsSingleUnit(unitIdList)) unitsIds = [ unitIdList[0] ];

  const body = {
    propertyId,
    unitsIds,
    pageIndex: 1,
    pageSize: 150,
  }
  const facilitiesAndAmenities = await getPropertyFacilitiesAndAmenities(body);
  const nearestLandmarks = await getNearestLandmarks(propertyId);
  const propertyLocation = await getPropertyLocation(propertyId);
  return {
    nearestLandmarks: nearestLandmarks || [],
    facilitiesAndAmenities: facilitiesAndAmenities?.result || [],
    propertyLocation,
  };
}

async function getFloorPlanData({ unitId }) {
  const floorPlanImages = await getUnitFloorPlanImages(unitId);
  return {
    floorPlanImages: floorPlanImages || [],
  };
}

async function getPaymentPlanData({ propertyId }) {
  const propertyPaymentPlans = await getPropertyPaymentPlans(propertyId);
  return {
    propertyPaymentPlans: propertyPaymentPlans?.result || [],
  };
}





// ============== Services =============

async function fetchData(endpoint, params) {
  try {
    const res = await endpoint(params);
    if (res && res.status && res.status !== 200) {
      return null;
    }
    return res;
  } catch (error) {
    return null;
  }
}

async function getUnitPublishedImages(unitId) {
  return fetchData(GetUnitPublishedImages, unitId);
}

async function getUnitFloorPlanImages(unitId) {
  return fetchData(GetUnitFloorPlanImages, unitId);
}

async function getProposalUnitDetails(unitId) {
  return fetchData(GetProposalUnitDetails, unitId);
}

async function getProjectDescription(propertyId) {
  return fetchData(GetProjectDescription, propertyId);
}

async function getNearestLandmarks(propertyId) {
  return fetchData(GetNearestLandmarks, propertyId);
}

async function getPropertyPaymentPlans(propertyId) {
  return fetchData(GetPropertyPaymentPlans, propertyId);
}

async function getProposalContactDetails(propertyId) {
  return fetchData(GetProposalContactDetails, propertyId);
}

async function getPropertyPublishedImages(propertyId) {
  return fetchData(GetPropertyPublishedImages, propertyId);
}

async function getMultiProposalUnitsDetails(body) {
  return fetchData(GetMultiProposalUnitsDetails, body);
}

async function getPropertyFacilitiesAndAmenities(params) {
  return fetchData(GetPropertyFacilitiesAndAmenities, params);
}

async function getPropertyLocation(propertyId) {
  return fetchData(GetPropertyLocation, propertyId);
}
