import React, { useRef, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { DialogTitle, InputAdornment, Paper } from "@material-ui/core";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { locale } from "core-js";
import {
  useLocalStorage,
  useSelectedTheme,
} from "../../../Hooks";
import { ChevronSelectorVerticalIcon, CloseXIcon } from "../../../assets/icons";

// Styles
import useStyle from "./styles";

// Icons
import { IconButton } from "@material-ui/core";

const CustomDatePickerComponent = ({
  value,
  isTimePicker,
  isDisabled,
  format,
  onDateChanged,
  placeholder,
  idRef,
  withIcon,
  startAdornment,
  endAdornment,
  title = "Select Time",
  autoOk,
  minDate,
  error,
  maxDate,
  isSubmitted,
  customStyle = "",
  onError,
  helperText,
  translate
}) => {
  const styles = useStyle();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const datePickerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isBlurOrChanged, setIsBlurOrChanged] = useState(false);
  const [language] = useLocalStorage("localization", {
    currentLanguage: "en",
    isRtl: false,
  });
  const onDateChangedHandler = (newValue) => {
    setIsBlurOrChanged(true);
    if (onDateChanged) onDateChanged(newValue);
  };

  const PaperComponent = ({ onClose, ...props }) => (
    <Paper {...props} className={styles.containerPaper}>
      <DialogTitle>{title}</DialogTitle>
      <IconButton className={styles.containerIcon} onClick={onClose}>
        <CloseXIcon width="20" height="20" fill={palette.foreground.quinary} />
      </IconButton>
      {props.children}
    </Paper>
  );

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={locale || language.currentLanguage}
    >
      {isTimePicker && (
        <KeyboardTimePicker
          value={value}
          okLabel={autoOk}
          cancelLabel="Cancel"
          disabled={isDisabled}
          placeholder={placeholder || undefined}
          id={idRef || ""}
          className={clsx(styles.customInputTime, customStyle)}
          onChange={onDateChangedHandler}
          helperText={
            (helperText &&
              (isSubmitted || isBlurOrChanged) &&
              translate(`${helperText}`)) ||
            undefined
          }
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => {
            setIsBlurOrChanged(true);
            setIsOpen(false);
          }}
          onBlur={() => setIsBlurOrChanged(true)}
          error={error}
          keyboardIcon={
            <ChevronSelectorVerticalIcon
              width="20"
              height="20"
              fill={palette.foreground.quarterary}
            />
          }
          onClick={() => setIsOpen(true)}
          InputProps={{
            startAdornment: withIcon && startAdornment && (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
          }}
          DialogProps={{
            PaperComponent: (dialogProps) => (
              <PaperComponent
                {...dialogProps}
                onClose={() => setIsOpen(false)}
              />
            ),
            className: styles.pickerToolbar,
          }}
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
      {!isTimePicker && (
        <KeyboardDatePicker
          format={format}
          value={value}
          okLabel={autoOk}
          id={idRef}
          cancelLabel="Cancel"
          disabled={isDisabled}
          error={error}
          helperText={
            (helperText &&
              (isSubmitted || isBlurOrChanged) &&
              translate(`${helperText}`)) ||
            undefined
          }
          onError={onError}
          placeholder={placeholder || undefined}
          className={clsx(styles.customInputDate, customStyle)}
          onChange={onDateChangedHandler}
          ref={datePickerRef}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onBlur={() => setIsBlurOrChanged(true)}
          onClose={() => {
            setIsBlurOrChanged(true);
            setIsOpen(false);
          }}
          InputProps={{
            startAdornment: withIcon && startAdornment && (
              <InputAdornment
                position="start"
                onClick={() => {
                  setIsOpen(true);
                }}
                style={{ cursor: "pointer" }}
              >
                {startAdornment}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
          }}
          onKeyPress={(event) =>
            (event.key === "Enter" && setIsOpen(true)) || undefined
          }
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
    </MuiPickersUtilsProvider>
  );
};

export default CustomDatePickerComponent;

CustomDatePickerComponent.defaultProps = {
  value: undefined,
  helperText: undefined,
  maxDate: undefined,
  minDate: undefined,
  format: "DD/MM/YYYY",
  isTimePicker: false,
  endAdornment: undefined,
  endAdornmentIcon: undefined,
  isSubmitted: undefined,
  placeholder: undefined,
  autoOk: undefined,
};
