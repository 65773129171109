import React, { useState } from "react";
import { CustomDialog } from "../../../../CustomDialog";
import {useTranslate } from "../../../../../../Hooks";
import UnitsAddForRentView from "./UnitsAddForRentView";
import UnitsAddForSaleView from "./UnitsAddForSaleView";
import { CustomButton } from "../../../../CustomButton";

//styles
import useStyles from "./styles";


const UnitSucsessValidateDialog = ({ open, onClose }) => {
  const styles = useStyles();
  const { translate } = useTranslate("HeaderView");
  const [view, setview] = useState(1);

  const [onSaveCall, setOnSaveCall] = useState(false);
  const [onSaveAndCompleteCall, setOnSaveAndCompleteCall] = useState(false);

  const AddContactOnSaveHandler =()=>{
    setOnSaveCall(true)
  }
  const saveAndCompleteDetailsHandler =()=>{
    setOnSaveAndCompleteCall(true)
  }

  return (
    <CustomDialog
      open={open}
      width="1024px"
      title={translate("Add-new-unit")}
      subtitle={translate(
        "Fill-in-the-details-below-to-add-a-new-unit-to-your-list-Ensure-all-required-fields-are-completed-before-saving"
      )}
      confirmText={translate("Add-unit")}
      contentDialogClass={styles.containerContentDialog}
      cancelText={translate("cancel")}
      onClose={() => onClose()}
      onConfirm={() => {
        AddContactOnSaveHandler();
      }}
      isDisabled={onSaveCall}
      content={
        view === 2 ? (
          <>
            <UnitsAddForRentView
              setview={(viewval) => setview(viewval)}
              onClose={() => onClose()}
              onSaveCall={onSaveCall}
              onSaveAndCompleteCall={onSaveAndCompleteCall}
            />
          </>
        ) : (
          <>
            <UnitsAddForSaleView
              setview={(viewval) => setview(viewval)}
              onClose={() => onClose()}
              onSaveCall={onSaveCall}

              onSaveAndCompleteCall={onSaveAndCompleteCall}
            />
          </>
        )
      }
      moreAction={
        <CustomButton
          boxShadow="none"
          size="lg"
          variant="text"
          color="tertiaryGray"
          onClick={() => {
            saveAndCompleteDetailsHandler(true);
          }}
          style={{ marginInlineEnd: "auto" }}
        >
          {translate("Save-And-Complete-Details")}
        </CustomButton>
      }
    />
  );
};

export default UnitSucsessValidateDialog;
