import React from "react";
import { DialogComponent } from "../DialogComponent/DialogComponent";
import { useTranslation } from "react-i18next";

export const PropertyLockManagementDialog = ({
  translationPath,
  isOpenDialog,
  onClose,
  onSave,
  filedName,
  isFromQuick,
}) => {

  const { t } = useTranslation("Shared");

  return (
    <div>
      <DialogComponent
        titleText="confirm-message"
        saveText="confirm"
        saveType="button"
        maxWidth="sm"
        dialogContent={
          <div className="d-flex-column-center">
            <span className="mdi mdi-close-octagon c-danger mdi-48px" />

            <span>
              {filedName === "property_lock"
                ? `${t(
                    `you-cannot-create-a-unit-under-this-property-because-it-is-locked`
                  )}`
                : `${t(
                    `the-property-you-are-selecting-is-currently-blocked-please-contact-the-system-administrator-for-assistance`
                  )}`}
            </span>
          </div>
        }
        saveClasses="btns theme-solid bg-danger w-100 mx-2 mb-2"
        isOpen={isOpenDialog}
        onSaveClicked={() => {
          if (onSave) onSave();

          onClose();
        }}
        onCloseClicked={() => {
          onClose(false);
        }}
        onCancelClicked={() => {
          onClose(false);
        }}
        parentTranslationPath={"Shared"}
        translationPath={translationPath}
        hideSaveBtn={
          isFromQuick || filedName !== "property_lock" ? true : false
        }
      />
    </div>
  );
};
