import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Stepper,
  StepLabel,
  Step,
  StepConnector,
  Typography,
} from "@material-ui/core";
import { useIsAr, useSelectedTheme } from "../../../Hooks";
import { withStyles } from "@material-ui/core/styles";

// Styles
import useStyles from "./styles";

// Icons
import { Tick } from "../../../assets/icons";

function ReskinningStepper(props) {
  const { activeStep, steps, progressValueInput, labelInput } = props;

  const styles = useStyles();

  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<CustomConnector />}
      style={{ background: "transparent", padding: 0, marginBottom: "32px" }}
    >
      {steps &&
        Array.isArray(steps) &&
        steps.map((item, index) => (
          <Step key={`${item[labelInput]}${index + 1}`}>
            <StepLabel
              StepIconComponent={(props) => {
                const { active, completed } = props;

                return (
                  <div
                    className={clsx(styles.root, {
                      [styles.active]: active,
                      [styles.completed]: completed,
                    })}
                  >
                    {completed && (
                      <Tick
                        width="12"
                        height="10.5"
                        fill={palette.foreground.white}
                      />
                    )}
                  </div>
                );
              }}
              classes={{
                label: styles.stepLabel,
                active: styles.activeLabel,
                completed: styles.completedLabel,
              }}
            >
              {item[labelInput]}

              {progressValueInput && (
                <Typography className={clsx(styles.progress)}>{`${item[progressValueInput]}%`}</Typography>
              )}
            </StepLabel>
          </Step>
        ))}
    </Stepper>
  );
}

ReskinningStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  labelInput: PropTypes.string.isRequired,
  steps: PropTypes.instanceOf(Array).isRequired,
  progressValueInput: PropTypes.string,
};

ReskinningStepper.defaultProps = {
  progressValueInput: undefined,
};

export default ReskinningStepper;

const CustomConnector = withStyles((theme) => {
  const { isAr } = useIsAr(theme.direction);

  return {
    alternativeLabel: {
      top: 10,
      ...(isAr
        ? {
            right: "calc(-50%)",
            left: "calc(50%)",
          }
        : {
            left: "calc(-50%)",
            right: "calc(50%)",
          }),
    },
    active: {
      "& $line": {
        backgroundColor: theme.palette.foreground.brandPrimary, // active color
      },
    },
    completed: {
      "& $line": {
        backgroundColor: theme.palette.foreground.brandPrimary, // active color
      },
    },
    line: {
      height: 2,
      border: 0,
      backgroundColor: theme.palette.background.quarterary, // default color
      borderRadius: 0,
    },
  };
})(StepConnector);
