import { getUrlFromFileObj } from "../filesHelpers";

const imagesDescDtoMapper = (property) => {
  const maxCheckedImages = 4;

  const unitImages = property.submittedForm?.UnitImages || {};


  return {
    showArchitectureImages: unitImages.showArchitectureImages || false,
    showInteriorImages: unitImages.showInteriorImages || false,
    isSelectAllArchitecture:
      unitImages.architectureImages?.length === maxCheckedImages,
    isSelectAllInterior:
      unitImages.interiorImages?.length === maxCheckedImages,
    propertyImages: getUrlFromFileObj(unitImages.architectureImages),
    unitImages: getUrlFromFileObj(unitImages.interiorImages),
  };
};

export default imagesDescDtoMapper;
