import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Box, Table, TableHead, TableRow, TableBody } from "@material-ui/core";

import useStyles from "./styles";

const TableSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.tableFlexContainerLoader}>
      <Box>
        <Box className="loaderFlex">
          <Table className={classes.tableViewContainer}>
            <TableHead>
              <TableRow>
                <Box className={classes.skeletonHeader}>
                  <Skeleton
                    animation="wave"
                    height="20px"
                    variant="rectangular"
                    width="20px"
                  />
                </Box>
                {Array.from({ length: 7 }).map((_, cellIndex) => (
                  <th key={"property-head-cell-skeleton" + cellIndex}>
                    <Box className={classes.displayFlex}>
                      <Skeleton
                        animation="wave"
                        height="1vw"
                        className={classes.skeletonCell}
                        variant="rectangular"
                        width="8vw"
                      />
                    </Box>
                  </th>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: 10 }).map((_, rowIndex) => (
                <TableRow key={"TableBody TableRow" + rowIndex}>
                  <Box className={classes.skeletonHeader}>
                    <Skeleton
                      animation="wave"
                      height="20px"
                      variant="rectangular"
                      width="20px"
                    />
                  </Box>
                  {Array.from({ length: 7 }).map((_, cellIndex) => (
                    <td key={"body-cell-skeleton" + cellIndex}>
                      <Box className={classes.displayFlex}>
                        <Skeleton
                          animation="wave"
                          height="1vw"
                          className={classes.skeletonCell}
                          variant="rectangular"
                          width="8vw"
                        />
                      </Box>
                    </td>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
};

export default TableSkeleton;
