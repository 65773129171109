import formatPaymentPlanData from "./paymentPlan.js"
import formatCoverPage from "./coverPage.js";
import formatLocationAndamenities from "./locationAndamenities.js";
import formatProjectDescriptionData from "./propertyDescription.js";
import formatUnitsDetails from "./unitsDetails.js";

const filledDataMapper = (data) => {

    const { propertyData, formsSelection, activeStepKey } = data;
    
    const stepData = propertyData[activeStepKey];
    const isFormFilledBefore = formsSelection[activeStepKey]?.data;

    if (!stepData || isFormFilledBefore) return formsSelection;
    

    const mappedApiData = {
        CoverPage: formatCoverPage(stepData?.proposalContactDetails, stepData?.propertyPublishedImages),
        PropertyDescription: formatProjectDescriptionData(stepData?.projectDescriptionData),
        LocationAndamenities: formatLocationAndamenities(stepData),
        PaymentPlan:  activeStepKey == "PaymentPlan" ? formatPaymentPlanData(stepData) : {},
        UnitsDetails: formatUnitsDetails(stepData),
        MultiUnitsDetails: stepData,
        UnitImages: stepData,
        FloorPlan: stepData,
    };


    const filledForm = {
        ...formsSelection,
        [activeStepKey]: {
            ...(formsSelection[activeStepKey]),
            data: mappedApiData[activeStepKey]
        }
    }

    return filledForm
}

export default filledDataMapper;

