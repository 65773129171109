import moment from 'moment';
import {  UserAccountTypeEnum } from '../../../../../Enums';

export const DevelopersMapper = (item, developer) => {
  const getAccountType = (type) => {
    if (type === 'Normal') return UserAccountTypeEnum.normal.value;
    if (type === 'VIP') return UserAccountTypeEnum.platinum.value;
    if (type === 'VVIP') return UserAccountTypeEnum.gold.value;
    return null;
  };
  if (developer) {
    return {
      ...developer,
      contactId: item.contactId,
      name:
          (developer && developer.developer_name && developer.developer_name.name ) || '' 
          || 'N/A',
      id: item.developerContactId,
      createdBy: item.createdBy, 
      updatedBy : item.updatedBy ,
      imagePath: developer &&
      (developer && developer.developer_logo &&
        developer.developer_logo['Company Logo'] &&
        developer.developer_logo['Company Logo'][0] &&
        developer.developer_logo['Company Logo'][0].uuid) ||
      '',
     updateDate: item && item.updateOn,
     hasAgreement : item.hasAgreement , 
     agreementDocument : item.agreementDocument , 
     developerClass : developer && developer.developer_class && developer.developer_class?.lookupItemName , 
      accountType:
      developer && developer.developer_class && developer.developer_class?.lookupItemName && getAccountType(developer.developer_class.lookupItemName) ,
      progress:
      developer.data_completed && typeof developer && developer.data_completed === 'string' && developer.data_completed.includes('%') ?
      developer.data_completed.substr(0, developer.data_completed.length - 1) :
      developer && developer.data_completed,
      progressWithPercentage:
      developer.data_completed && typeof developer && developer.data_completed && developer.data_completed !== 'string' ?
          `${developer && developer.data_completed && developer.data_completed}%` :
          developer && developer.data_completed,
      createdOn: ( item.createdOn && moment(item.createdOn).isValid() && item.createdOn) || null,
      updateOn: ( item.updateOn && moment(item.updateOn).isValid() && item.updateOn) || null,
      landline_number: developer.landline_number,
      details: [
        {
          title: 'name',
          value:
              (developer && developer.developer_name && developer.developer_name.name ) || 'N/A' ,
        },
        {
          title: 'location',
          value:
            ((developer && developer.country &&
              developer.country?.lookupItemName &&
              `${developer.country.lookupItemName}`) ||
              '') +
            ((developer && developer.city && developer.city?.lookupItemName && `, ${developer.city?.lookupItemName}`) ||
              '') +
            ((developer && developer.street && developer.street?.value && `, ${developer.street?.value} `) || '') ||
            'N/A',
        },
        {
          title: 'email',
          value:
          (developer && developer.email_address && developer.email_address?.email) || 'N/A',
        },
        {
          title:  'landline-number'  ,
          value:
          developer && developer?.landline_number && developer.landline_number?.phone ,
        },
        {
          title:  'website' ,
          value:
              (developer && developer?.website ) || 'N/A',
        },
       
    
        {
          ContactAllDetails: [
            {
              title: 'developer_class',
              value: (developer && developer.developer_class && developer.developer_class?.lookupItemName) || '',
            },
            {
              title: 'job_title',
              value: (developer && developer.job_title && developer.title?.lookupItemName) || 'N/A',
            },
            {
              title: 'createdOn',
              value: (item && item?.createdOn) || '',
            },
            {
              title: 'building_name',
              value: (developer && developer?.building_name && developer?.building_name?.value) || 'N/A',
            },
            {
              title: 'building_number',
              value: (developer && developer.building_number && developer.building_number?.value) || '',
            },
            {
              title: 'near_by',
              value: (developer && developer.near_by && developer.near_by?.value) || 'N/A',
            },
            {
              title: 'postalzip_code',
              value: (developer && developer.postalzip_code && developer.postalzip_code?.value) || '',
            },
            {
              title: 'po_box',
              value: (developer && developer.po_box && developer.po_box?.value) || '',
            },
            {
              title: 'address_line1',
              value: (developer && developer.address_line1 && developer.address_line1?.value) || '',
            },
            {
              title: 'country',
              value: (developer && developer.country && developer.country?.lookupItemName) || '',
            },
            {
              title: 'city',
              value: (developer && developer.city && developer.city?.lookupItemName) || '',
            },
           
            {
              title: 'district',
              value: (developer && developer.district && developer.district?.lookupItemName) || '',
            },
            {
              title: 'community',
              value: (developer && developer.community && developer.community?.lookupItemName) || '',
            },
           
            {
              title: 'data_completed',
              value:( developer && developer?.data_completed ) || '',
            },
            {
              title: 'other_contact_mobile_no',
              value: (developer && developer?.other_contact_mobile_no && developer.other_contact_mobile_no?.phone) || '',
            },
           
            {
              title: 'work_phone',
              value:
               developer && developer.landline_number &&  developer.landline_number.phone ,
            },
          ],
        },
      ],
      allDetails: {
        'General Information':[
          {
            title: 'developer_name',
            value: (developer && developer?.developer_name?.name) || 'N/A',
          },
          {
            title:  'landline_number'  ,
            value:
            developer && developer?.landline_number && developer.landline_number?.phone ,
          },
         ,
          {
            title: 'email_address',
            value: (developer && developer.email_address && developer.email_address?.email) || 'N/A',
          },
          {
            title: 'developer_class',
            value: (developer && developer.developer_class && developer.developer_class?.lookupItemName) || 'N/A',
          },
          {
            title: 'website',
            value: (developer && developer.website) || 'N/A',
          },
          {
            title: 'country',
            value: (developer && developer.country && developer.country?.lookupItemName) || 'N/A',
          },
          {
            title: 'city',
            value: (developer && developer.city && developer.city?.lookupItemName) || 'N/A',
          },
        ],
        'Residence Address': [
          {
            title: 'country',
            value: (developer && developer.country && developer.country?.lookupItemName) || 'N/A',
          },
          {
            title: 'city',
            value: (developer && developer.city && developer.city?.lookupItemName) || 'N/A',
          },
          {
            title: 'district',
            value: (developer && developer.district && developer.district?.lookupItemName) || 'N/A',
          },
          {
            title: 'community',
            value: (developer && developer.community && developer.community?.lookupItemName) || 'N/A',
          },
          {
            title: 'street',
            value: (developer && developer.street && developer.street?.value) || 'N/A',
          },
          {
            title: 'building_name',
            value: (developer && developer.building_name && developer.building_name?.value) || 'N/A',
          },
          {
            title: 'building_no',
            value: (developer && developer.building_no && developer.building_no?.value) || 'N/A',
          },
          {
            title: 'floor_no',
            value: (developer && developer.floor_no && developer.floor_no?.value) || 'N/A',
          },
          {
            title: 'postalzip_code',
            value: (developer && developer.postalzip_code && developer.postalzip_code?.value) || 'N/A',
          },
          {
            title: 'po_box',
            value: (developer && developer.po_box && developer.po_box?.value) || 'N/A',
          },
          {
            title: 'map',
            value: (developer && developer.map && `${developer.map?.latitude}, ${developer.map?.longitude}`) || 'N/A',
          },
        ],
        Address: [
          {
            title: 'country',
            value: (developer && developer.country && developer.country?.lookupItemName) || 'N/A',
          },
          {
            title: 'city',
            value: (developer && developer.city && developer.city?.lookupItemName) || 'N/A',
          },
          {
            title: 'district',
            value: (developer && developer.district && developer.district?.lookupItemName) || 'N/A',
          },
          {
            title: 'community',
            value: (developer && developer.community && developer.community?.lookupItemName) || 'N/A',
          },
          {
            title: 'street',
            value: (developer && developer.street && developer.street?.value) || 'N/A',
          },
          {
            title: 'building_name',
            value: (developer && developer.building_name && developer.building_name?.value) || 'N/A',
          },
          {
            title: 'map',
            value:
              (developer && developer.map &&
                `Longitude: ${developer && developer.map?.longitude}, Latitude: ${developer.map?.latitude}`) ||
              'N/A',
          },
          {
            title: 'postalzip_code',
            value: (developer && developer.postalzip_code && developer.postalzip_code?.value) || 'N/A',
          },
          {
            title: 'po_box',
            value: (developer && developer.po_box && developer.po_box?.value) || 'N/A',
          },
          {
            title: 'address_line_1',
            value: (developer && developer.address_line_1 && developer.address_line_1?.value) || 'N/A',
          },
          {
            title: 'near_by',
            value: (developer && developer.near_by && developer.near_by?.value) || 'N/A',
          },
        ],
        'Work Address': [
          {
            title: 'workcountry',
            value: (developer && developer?.country && developer.country?.lookupItemName) || 'N/A',
          },
          {
            title: 'workcity',
            value: (developer && developer.city && developer.city?.lookupItemName) || 'N/A',
          },
          {
            title: 'workdistrict',
            value: (developer && developer.district && developer.district?.lookupItemName) || 'N/A',
          },
          {
            title: 'workcommunity',
            value: (developer && developer?.community && developer.community?.lookupItemName) || 'N/A',
          },
          {
            title: 'workstreet',
            value: (developer && developer.street && developer.workstreet?.value) || 'N/A',
          },
          {
            title: 'workbuilding_name',
            value: (developer && developer.building_name && developer.building_name?.value) || 'N/A',
          },

          {
            title: 'workbuilding_no',
            value: (developer && developer.building_number && developer.building_number?.value) || 'N/A',
          },
          {
            title: 'workmap',
            value:
              (developer && developer.map &&
                developer.map.value &&
                developer.map.value.latitude &&
                `Latitude: ${developer.map.value.latitude}, Longitude: ${developer.map.value.longitude}`) ||
              'N/A',
          },
          {
            title: 'address_line2',
            value: (developer && developer.address_line2 && developer.address_line2?.value) || 'N/A',
          },
        ],
      },
    };
  }
};
