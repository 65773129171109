import { config } from "../../config/config";
import { HttpServices } from "../../Helper";

export const CanSendToLeadPool = async (leadId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/LeadPoolProcessor/CanSendToLeadPool/${leadId}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const SendLeadsToLeadPool = async (body, isAssetsLeads) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/LeadPoolProcessor/SendLeadsToLeadPool?isAssetsLeads=${isAssetsLeads}`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const RetrieveLeadsFromPool = async ({
  pageIndex,
  pageSize,
  ...body
}) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/LeadPoolProcessor/RetrieveLeadsFromPool/${pageIndex}/${pageSize}`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const CheckAgentLeadsInPoolWithoutRecentActivity = async (userId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/LeadPoolProcessor/CheckAgentLeadsInPoolWithoutRecentActivity/${userId}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetAllLeadsPoolSummary = async (referredToId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/LeadPoolProcessor/GetAllLeadsPoolSummary?referredToId=${referredToId}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetAllLeadsPoolDetails = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/LeadPoolProcessor/GetAllLeadsPoolDetails`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetRemainingLeadAssignmentCapacity = async (referredToId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/LeadPoolProcessor/GetRemainingLeadAssignmentCapacity?referredToId=${referredToId}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetLeadPoolConfiguration = async (leadPoolConfigrationId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/LeadPoolProcessor/GetLeadPoolConfiguration/${leadPoolConfigrationId}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const UpdateLeadPoolConfiguration = async (body) => {
  try {
    const result = await HttpServices.post(
      `${config.server_address}/CrmDfm/LeadPoolProcessor/UpdateLeadPoolConfiguration`,
      body
    );
    return result;
  } catch (error) {
    return error.response;
  }
};

export const GetLeadPoolLogDetails = async (pageIndex, pageSize) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/LeadPoolProcessor/GetLeadPoolLogDetails/${pageIndex}/${pageSize}`
    );
    return result;
  } catch (error) {
    return error.response;
  }
};
export const GetReassignLeadPoolWithDetails = async (jobId) => {
  try {
    const result = await HttpServices.get(
      `${config.server_address}/CrmDfm/Leads/GetReassignLeadPoolWithDetails/${jobId}`
    );
    return result;
  } catch (error) {
    throw error.response;
  }
};
