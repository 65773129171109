import React, { useState, memo, useEffect, useMemo } from "react";
import "./DragDropPicker.scss";
import PickerImageCard from "./PickerImageCard/PickerImageCard";
import { PropTypes } from "prop-types";
import { CheckboxesComponent } from "../../../../../Components";
import useDragDrop from "../../Hooks/useDragDrop";
import usePickerExceedCount from "../../Hooks/usePickerExceedCount";
import PickerDialog from "./PickerDialog/PickerDialog";

const DragDropPicker = memo(
  ({ itemsList, onChange, fileKeys, DragItem, maxPickerItmes, maxChecked }) => {

    const [pickerItems, setPickerItems] = useState([]);
    const [isShowMoreDialogOpen, setIsShowMoreDialogOpen] = useState(false);
    const checkIsComponentExist = () =>
      typeof DragItem === "function" || typeof DragItem?.type === "function";

    const { handleDragStart, handleDragEnd, handleDragOver, handleDrop } =
      useDragDrop({ onChange, pickerItems, setPickerItems });

    const { isCheckedExceedMax, additionalItemsCount, getShowMoreStyles } = 
    usePickerExceedCount({itemsList, pickerItems, maxPickerItmes, maxChecked });

    const initializePickerItems = () => {
      if (Array.isArray(itemsList) && pickerItems.length == 0)
        setPickerItems(itemsList);
    };

    useEffect(() => initializePickerItems(), [itemsList]);


    const handleCheckboxChange = (e, item, index) => {
      const isChecked = e.target.checked;

      if (isChecked && isCheckedExceedMax) return;

      let updatedItems = [...pickerItems];
      updatedItems.splice(index, 1);

      const lastCheckedIndex = updatedItems.reduce(
        (lastIndex, currentItem, idx) =>
          currentItem.isChecked ? idx : lastIndex,
        -1
      );
      const targetIndex = maxChecked == 1? 0 : lastCheckedIndex + 1;
      
      updatedItems.splice(targetIndex, 0, { ...item, isChecked });

      setPickerItems(updatedItems);
      onChange(updatedItems.filter((item) => item.isChecked));
    };

    const viewMoreClickHandler = (index) => {
      const isViewMoreItem = index == maxPickerItmes - 1;
      if(isViewMoreItem) setIsShowMoreDialogOpen(true);
    }
    
    return (
      <>
      <div className="PickerItems_container">
        {pickerItems?.length > 0? pickerItems.map((item, index) => (
          <div
            key={item[fileKeys.id]}
            className={`PickerItems_Drag_item ${getShowMoreStyles(index)}`}
            style={{
              display: index >= maxPickerItmes ? "none" : "block",
              "--additional-items-count": `"+${additionalItemsCount}"`,
            }}
            draggable={item.isChecked || false}
            onDragStart={(e) => handleDragStart(e, item)}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, item)}
            onClick={()=> viewMoreClickHandler(index)}
          >
            <CheckboxesComponent
              idRef="checkboxes_component"
              themeClass="theme-propx"
              singleChecked={item.isChecked || false}
              onSelectedCheckboxClicked={(event) =>
                handleCheckboxChange(event, item, index)
              }
            />
            {checkIsComponentExist() ? (
              <DragItem
                key={`dynamicComponentRef-${item[fileKeys.id]}`}
                itemData={item}
                fileKeys={fileKeys}
              />
            ) : null}
          </div>
        )) :  <span>No images available .</span>}
        </div>
        {isShowMoreDialogOpen ?
          <PickerDialog
            isDialogOpen={isShowMoreDialogOpen}
            submitHandler={(dialogPickerItems) => {
              onChange(dialogPickerItems.filter((item) => item.isChecked));
              setPickerItems(dialogPickerItems);
              setIsShowMoreDialogOpen(false);
            }}
            closeHandler={() => setIsShowMoreDialogOpen(false)}
            pickerItems={pickerItems}
            fileKeys={fileKeys}
            maxChecked={maxChecked}
            DragItem={DragItem}
          />
          : null}
      </>
    );
  }
);

export default DragDropPicker;

DragDropPicker.propTypes = {
  itemsList: PropTypes.array,
  onChange: PropTypes.func,
  fileKeys: PropTypes.object,
  DragItem: PropTypes.element,
  maxPickerItmes: PropTypes.number,
  maxChecked: PropTypes.number,
};

DragDropPicker.defaultProps = {
  itemsList: [],
  onChange: () => {},
  fileKeys: {
    id: "fileId",
    name: "fileName",
  },
  DragItem: PickerImageCard,
  maxPickerItmes: 8,
  maxChecked: 4,
};
