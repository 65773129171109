import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import {
  AutocompleteComponent,
  DialogComponent,
  Inputs,
  Spinner,
} from '../../Components';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { ButtonBase, DialogActions } from '@material-ui/core';
import { PropertiesMapper } from '../../Views/Home';
import { useDebouncedAction } from '../../Hooks/DebouncedAction';
import { GlobalHistory, showError, showSuccess } from '../../Helper';
import { PropertyLockManagementDialog } from '../../Components/Utilities/PropertyLockManagementDialog';
import * as Yup from 'yup';
import {
  CheckForDuplicateUnit,
  PropertiesAdvanceSearchTest,
  ClaimUnit,
  GetAllPropertyUnitTypeAPI,
  CheckPropertyLockAPI 
} from '../../Services';
import DuplicateUnitCard from './DuplicateUnitCard';
import CheckCircleGreen from './Icons/CheckCircleGreen';
import { CloseXIcon } from '../../assets/icons';

const translationPath = '';
const parentTranslationPath = 'UnitValidationContext';

const UnitValidationContext = createContext({
  selected: {
    propertyName: null,
    unitType: null,
    buildingNumber: null,
    phaseNumber: null,
    unitNumber: null,
  },
  isValidUnit: false,
  toggleContactValidationDialog: (callback = null, closeCallback = null) => {},
  resetStateHandler: () => {},
  onCloseClicked: () => {},
  onClaimUnitClicked: () => {},
  onConfirmClaimUnitClicked: () => {},
});

export const useUnitValidationContext = () => useContext(UnitValidationContext);

export const UnitValidationProvider = ({ children }) => {
  const { t } = useTranslation(parentTranslationPath);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const userId = JSON.parse(localStorage.getItem('session')).userId;
  const [loadings, setLoadings] = useReducer(reducer, {
    properties: false,
    getDuplicates: false,
    checkIsLocked : false 
  });

  const [isOpenPropertyLockMangaement, setIsOpenPropertyLockMangaement] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isClaimedSuccessfully, setIsClaimedSuccessfully] = useState(false);
  const [selectedDuplicatedUnit, setSelectedDuplicatedUnit] = useState({});
  const [preventCleanup, setPreventCleanup] = useState(false);

  const [potentialDuplicateExist, setPotentialDuplicateExist] = useState(false);
  const [duplicatedUnits, setDuplicatedUnits] = useState([]);
  const [isValidUnit, setIsValidUnit] = useState(false);
  const callbackRef = useRef();
  const closeCallbackRef = useRef();
  const [state, setState] = useReducer(reducer, {
    propertyName: null,
    unitType: null,
    buildingNumber: null,
    phaseNumber: null,
    unitNumber: null,
  });
  const [selected, setSelected] = useReducer(reducer, {
    propertyName: null,
    unitType: null,
    buildingNumber: null,
    phaseNumber: null,
    unitNumber: null,
  });

  const [data, setData] = useReducer(reducer, {
    propertyNames: null,
    unitTypes: null,
  });
  const [isUnitValidationDialogOpen, setIsUnitValidationDialogOpen] =
    useState(false);

  const unitValidationSchema = Yup.object().shape({
    propertyName: Yup.string().required(t('property-name-is-required')),
    unitType: Yup.string().required(t('unit-type-is-required')),
    buildingNumber: Yup.string().nullable(),
    phaseNumber: Yup.string().nullable(),
    unitNumber: Yup.string()
      .matches(/^(?=.*[a-zA-Z0-9]).*$/, t('input_cannot_be_special_characters'))
      .required(t('unit-number-is-required')),
  });

  

  const getAllProperties = useCallback(async (search = '') => {
    setLoadings({ id: 'properties', value: true });
    const criteria = {};
    if (search !== '') {
      const isPropertyId = search ? !isNaN(search) : false;
      if (isPropertyId) criteria.Ids = [{ searchType: 1, value: search }];
      else criteria.property_name = [{ searchType: 2, value: search }];
    }
    const res = await PropertiesAdvanceSearchTest(
      { pageIndex: 0, pageSize: 200 },
      {
        criteria: criteria,
        filterBy: 'createdOn',
        orderBy: 2,
      }
    );
    if (!(res && res.status && res.status !== 200))
      setData({
        id: 'propertyNames',
        value:
          ((res && res.result) || []).map(
            (item) =>
              item.propertyJson &&
              PropertiesMapper(item, JSON.parse(item.propertyJson).property)
          ) || [],
      });
    else setData({ id: 'propertyNames', value: [] });
    setLoadings({ id: 'properties', value: false });
  }, []);

  const getUnitTypes = async (propertyId) => {
    let unitTypes;
    const res = await GetAllPropertyUnitTypeAPI(propertyId);
    if (!(res && res.status && res.status !== 200)) {
      unitTypes = res[0]?.propertyUnitType;
      setData({ id: 'unitTypes', value: unitTypes || [] });
    } else setData({ id: 'unitTypes', value: [] });
  };

  const onCardClicked = (card) => {
    setSelectedDuplicatedUnit((prevSelected) =>
      prevSelected?.unitId === card.unitId ? {} : card
    );
  };

  const onCloseClicked = () => {
    if (!callbackRef.current && !closeCallbackRef.current) {
      GlobalHistory.goBack();
    } else {
      callbackRef.current = null;

      if (closeCallbackRef.current) {
        closeCallbackRef.current();
      }
    }
    closeCallbackRef.current = null;
    resetStateHandler();
    setIsUnitValidationDialogOpen(false);
  };

  const onSaveClicked = async () => {
    setIsSubmitted(true);
    setLoadings({ id: 'getDuplicates', value: true });
    try {
      await unitValidationSchema.validate(state, { abortEarly: false });
      const res = await CheckForDuplicateUnit(state);
      if (res.length > 0) {
        setDuplicatedUnits(res);
        setPotentialDuplicateExist(true);
        setLoadings({ id: 'getDuplicates', value: false });
        return;
      }
      toggleUnitValidationDialog();
      setIsValidUnit(true);
      if (callbackRef.current) {
        callbackRef.current();
      }
    } catch (error) {
      showError('Something went wrong');
    } finally {
      setLoadings({ id: 'getDuplicates', value: false });
    }
  };

  const onPotentialDuplicateUnitsDialogCloseClicked = () => {
    setPotentialDuplicateExist(false);
    resetStateHandler();
  };

  const CheckPropertyLock = useCallback(async (propertyId) => {
    if(propertyId){
      setLoadings({ id: 'checkIsLocked', value: true });
      const checkPropertyLock = await CheckPropertyLockAPI(propertyId);
      setLoadings({ id: 'checkIsLocked', value: false });
      return  checkPropertyLock ; 
    }
  }, []);

  const onClaimUnitClicked = async () => {
    const unitId = selectedDuplicatedUnit?.unitId || null;
    const listingAgentId = userId;

    const res = await ClaimUnit(unitId, listingAgentId);
    if (!(res && res.status && res.status !== 200)) {
      if (res) {
        setPreventCleanup(true);
        showSuccess(t('unit-claimed-Successfully'));
        setPotentialDuplicateExist(false);
        setIsClaimedSuccessfully(false);
        resetStateHandler();
        setTimeout(() => {
          GlobalHistory.goBack();
        }, 500);
      } else {
        showError(t("couldn't-claim-unit"));
      }
    } else {
      showError(t('an-error-occurred-while-claiming-unit'));
    }
  };

  const toggleUnitValidationDialog = (callback, closeCallback) => {
    if (callback) {
      callbackRef.current = callback;
    }
    if (closeCallback) {
      closeCallbackRef.current = closeCallback;
    }
    setIsUnitValidationDialogOpen((prev) => !prev);
  };

  const onOpenFilClicked = () => {
    setIsUnitValidationDialogOpen(false);
    setPotentialDuplicateExist(false);
    GlobalHistory.push('/home/matched-unit-overview');
  };

  const debouncedGetAllProperties = useDebouncedAction(getAllProperties, 700);

  const resetStateHandler = () => {
    setState({
      id: 'edit',
      value: {
        propertyName: null,
        unitType: null,
        buildingNumber: null,
        phaseNumber: null,
        unitNumber: null,
      },
    });
    setSelected({
      id: 'edit',
      value: {
        propertyName: null,
        unitType: null,
        buildingNumber: null,
        phaseNumber: null,
        unitNumber: null,
      },
    });
    setDuplicatedUnits([]);
    setSelectedDuplicatedUnit([]);
    setPotentialDuplicateExist(false);
    setIsSubmitted(false);
    setIsClaimedSuccessfully(false);
    setIsValidUnit(false);
  };

  useEffect(() => {
    getAllProperties();
  }, []);
  const onConfirmClaimUnitClicked = () => {
    setIsClaimedSuccessfully(true);
  };

  return (
    <UnitValidationContext.Provider
      value={{
        isUnitValidationDialogOpen,
        setIsUnitValidationDialogOpen,
        toggleUnitValidationDialog,
        resetStateHandler,
        isValidUnit,
        selected,
        selectedDuplicatedUnit,
        onCloseClicked,
        onClaimUnitClicked,
        onConfirmClaimUnitClicked,
        preventCleanup,
      }}
    >
      <Spinner isAbsolute isActive={loadings.getDuplicates} />
      {children}
      {isUnitValidationDialogOpen && (
        <DialogComponent
          isOpen={isUnitValidationDialogOpen}
          titleText={t("add-new-unit")}
          maxWidth="sm"
          onCloseClicked={() => {
            onCloseClicked();
          }}
          dialogContent={
            <>
              <div className="unitValidationContextDialogWrapper">
                <AutocompleteComponent
                  idRef="propertiesRef"
                  labelValue={t("property-name")}
                  data={data?.propertyNames || []}
                  selectedValues={selected?.propertyName || null}
                  labelClasses="Requierd-Color"
                  multiple={false}
                  withoutSearchButton={true}
                  isSubmitted={isSubmitted}
                  displayLabel={(option) => option?.property_name || ""}
                  renderOption={(option) =>
                    (option && (
                      <div className="d-flex-v-center-h-between w-100 texts-truncate mb-1">
                        {option?.property_name && (
                          <div className="d-flex-v-center-h-between w-100">
                            {option?.property_name}
                          </div>
                        )}
                        {option?.id && (
                          <span className="">
                            <span className="c-gray-secondary">{`${
                              option?.property_plan
                                ? `${option.property_plan?.lookupItemName} - ${option.id}`
                                : ""
                            }`}</span>
                          </span>
                        )}
                      </div>
                    )) ||
                    "N/A"
                  }
                  onChange={async (_, newValue) => {
                    if (
                      newValue &&
                      newValue?.id &&
                      (await CheckPropertyLock(newValue?.id))
                    ) {
                      setSelected({
                        id: "edit",
                        value: {
                          ...selected,
                          propertyName: null,
                          unitType: [],
                        },
                      });
                      setState({
                        id: "edit",
                        value: {
                          ...state,
                          propertyName: null,
                          unitType: [],
                        },
                      });
                      setData({ id: "unitTypes", value: [] });
                      setIsOpenPropertyLockMangaement(true);
                      return;
                    }
                    setSelected({ id: "propertyName", value: newValue });
                    setState({
                      id: "propertyName",
                      value: newValue?.property_name,
                    });
                    setState({ id: "unitType", value: [] });
                    setSelected({ id: "unitType", value: [] });
                    setData({ id: "unitTypes", value: [] });
                    if (newValue?.id) {
                      getUnitTypes(newValue.id);
                    }
                    if (!newValue) debouncedGetAllProperties();
                  }}
                  onInputKeyUp={(e) => {
                    const value = e?.target?.value;
                    debouncedGetAllProperties(value);
                  }}
                  isLoading={loadings.properties}
                  withLoader
                  onInputChange={(e) => {}}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  filterOptions={(options) => options}
                />
                <AutocompleteComponent
                  idRef="unitTypeRef"
                  labelValue={t(`${translationPath}unit-type`)}
                  data={data.unitTypes || []}
                  selectedValues={selected.unitType || null}
                  labelClasses="Requierd-Color"
                  multiple={false}
                  withoutSearchButton={true}
                  isSubmitted={isSubmitted}
                  displayLabel={(option) => option?.lookupItemName || ""}
                  renderOption={(option) => option?.lookupItemName || ""}
                  onChange={(_, newValue) => {
                    const value = String(newValue.lookupItemName);
                    setSelected({ id: "unitType", value: newValue });
                    setState({
                      id: "unitType",
                      value: value,
                    });
                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                  isDisabled={!selected.propertyName}
                />

                <Inputs
                  idRef={"BuildingNumberRef"}
                  labelValue={"building-number"}
                  type={"text"}
                  value={selected.buildingNumber || ""}
                  isSubmitted={isSubmitted}
                  onInputChanged={(e) => {
                    const value = e.target.value ?? null;
                    setSelected({ id: "buildingNumber", value: value });
                    setState({ id: "buildingNumber", value: value });
                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
                <Inputs
                  idRef={"phaseNumberRef"}
                  labelValue={"phase-number"}
                  type={"text"}
                  value={selected.phaseNumber || ""}
                  isSubmitted={isSubmitted}
                  onInputChanged={(e) => {
                    const value = e.target.value ?? null;
                    setSelected({ id: "phaseNumber", value: value });
                    setState({ id: "phaseNumber", value: value });
                  }}
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
                <Inputs
                  idRef={"unitNumberRef"}
                  labelValue={"unit-number"}
                  type={"text"}
                  value={selected.unitNumber || ""}
                  isSubmitted={isSubmitted}
                  onInputChanged={(e) => {
                    const value = e.target.value ?? null;
                    setSelected({ id: "unitNumber", value: value?.trim() });
                    setState({ id: "unitNumber", value: value?.trim() });
                  }}
                  labelClasses="Requierd-Color"
                  translationPath={translationPath}
                  parentTranslationPath={parentTranslationPath}
                />
              </div>

              <DialogActions className="actionButtonsUnitValidationDialogWrapper">
                <ButtonBase
                  className="MuiButtonBase-root btns bg-cancel"
                  onClick={() => {
                    onCloseClicked();
                  }}
                >
                  {t("cancel")}
                </ButtonBase>
                <ButtonBase
                  className="MuiButtonBase-root btns theme-solid "
                  onClick={() => {
                    onSaveClicked();
                  }}
                >
                  {t("Submit")}
                </ButtonBase>
              </DialogActions>
            </>
          }
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}

      <DialogComponent
        titleText={"potential-duplicate-units"}
        isOpen={potentialDuplicateExist}
        maxWidth="md"
        onCloseClicked={() => {
          onPotentialDuplicateUnitsDialogCloseClicked();
        }}
        dialogContent={
          <>
            <p className="select-potential-duplicate-units-text">
              {duplicatedUnits?.length === 0
                ? t("there-is-no-duplicate-units")
                : t("you-can't-select-the-units")}
            </p>
            <div className="potential-duplicated-units-cards">
              {duplicatedUnits?.map((item) => (
                <DuplicateUnitCard
                  key={item.unitId}
                  unitData={item}
                  onCardClicked={onCardClicked}
                  onOpenFilClicked={onOpenFilClicked}
                  setSelectedDuplicatedUnit={setSelectedDuplicatedUnit}
                  isSelected={selectedDuplicatedUnit?.unitId === item.unitId}
                  onCloseClicked={onCloseClicked}
                />
              ))}
            </div>

            <DialogActions className="actionButtonsUnitValidationDialogWrapper">
              <ButtonBase
                className="MuiButtonBase-root btns bg-cancel"
                onClick={() => {
                  onPotentialDuplicateUnitsDialogCloseClicked();
                }}
              >
                {t("cancel")}
              </ButtonBase>
              <ButtonBase
                className="MuiButtonBase-root btns theme-solid "
                disabled={
                  Object.keys(selectedDuplicatedUnit).length === 0 ||
                  selectedDuplicatedUnit?.hasListingAgent
                }
                onClick={() => {
                  onConfirmClaimUnitClicked();
                }}
              >
                {t("claim-unit")}
              </ButtonBase>
            </DialogActions>
          </>
        }
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />

      {isClaimedSuccessfully && (
        <DialogComponent
          isOpen={isClaimedSuccessfully}
          maxWidth="xs"
          headingIcon
          dialogContent={
            <>
              <div className="claim-dialog-wrapper">
                <div className="claim-background-pattern"></div>
                <div className="claim-dialog-header">
                  <div
                    className="Claim-heading-icon"
                    onClick={() => {
                      setIsClaimedSuccessfully(false);
                    }}
                  >
                    <CloseXIcon width="24" height="24" fill={"#98A2B3"} />
                  </div>
                  <div className="claim-dialog-content">
                    <div className="claim-icon-wrapper">
                      <CheckCircleGreen
                        width="24"
                        height="24"
                        fill={"#079455"}
                      />
                    </div>
                    <div className="claim-title-Wrapper">
                      <span className="claim-title">
                        Update Unit Successfully{" "}
                      </span>
                      <span className="claim-sub-title">
                        {t("this-unit-is-assigned-to-you-right-now")}
                      </span>
                    </div>
                  </div>
                </div>
                <DialogActions className="confirmClaimDialog">
                  <ButtonBase
                    className="MuiButtonBase-root btns bg-cancel"
                    onClick={() => {
                      setIsClaimedSuccessfully(false);
                    }}
                  >
                    {t("cancel")}
                  </ButtonBase>
                  <ButtonBase
                    className="MuiButtonBase-root btns theme-solid claim-confirm"
                    onClick={() => {
                      onClaimUnitClicked();
                    }}
                  >
                    {t("confirm")}
                  </ButtonBase>
                </DialogActions>
              </div>
            </>
          }
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
      {isOpenPropertyLockMangaement && (
        <PropertyLockManagementDialog
          isOpenDialog={isOpenPropertyLockMangaement}
          onClose={() => setIsOpenPropertyLockMangaement(false)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onCancelClicked={() => {
            setIsOpenPropertyLockMangaement(false);
          }}
          onSave={() => {}}
          isFromQuick
        />
      )}
    </UnitValidationContext.Provider>
  );
};
