import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    propertyOverview: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    breadcrumbTitleWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    breadCrumbContainer: {
      paddingLeft: "36px",
    },
    historyTab: {
      paddingLeft: "36px",
    },
    historyPercentage: {
      display: "flex",
      flexDirection: "row",
      gap: "12px",
      alignItems: "center",
      paddingLeft: "36px",
    },
    tabsSide: {
      padding: "32px 0px 24px 0px",
      minWidth: 0,
    },
  };
});
