import React, { useCallback, useEffect, useReducer, useState } from "react";
import { CustomAutocomplete } from "../../../../CustomAutocomplete";
import { CustomInput } from "../../../../CustomInput";
import { Box, Divider, Typography } from "@material-ui/core";
import { useSelectedTheme, useTranslate } from "../../../../../../Hooks";
import { useVerticalNav } from "../../../../../../Contexts/VerticalNavContext";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CustomRadioButtonGroupItem } from "../../../../CustomRadioButtonGroupItem";
import {
  GetContacts,
  lookupItemsGetId,
  OrganizationUserSearch,
  unitPost,
} from "../../../../../../Services";
import { AgentRoleEnum } from "../../../../../../Enums";
import { useDebouncedAction } from "../../../../../../Hooks/DebouncedAction";
import { StaticLookupsIds } from "../../../../../../assets/json/StaticLookupsIds";
import {
  BuildingIcon,
  ReleaseIcon,
  RentIcon,
  SaleIcon,
} from "../../../../../../assets/icons";
import { CustomCopyToClipboard } from "../../../../CustomCopyToClipboard";
import { CustomRadioButton } from "../../../../CustomRadioButton";
import { CustomNumberSelector } from "../../../../CustomNumberSelector";

//styles
import useStyles from "./styles";

const UnitsAddForSaleView = ({ onClose, setview , onSaveCall ,onSaveAndCompleteCall}) => {
  const styles = useStyles();
  const { translate } = useTranslate("HeaderView");
  const { setAlertBoxContent, addNewUnit } = useVerticalNav();
  const history = useHistory();
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const {
    theme: { palette },
  } = useSelectedTheme();

  const nmbrSelectArray = ["Any", 1, 2, 3, 4, 5, 6, 7, 8];
  const [operationType, setOperationType] = useState(1);
  const [isOnSubmit, setIsOnSubmit] = useState(false);
  const [ViewType, setViewType] = useState(1);
  const [selectedValue, setSelectedValue] = useReducer(reducer, {
    oprationType: 1,
    saleType: 0,
    occupancyStatus: null,
    bathroom: null,
    bedroom: null,
    size: null,
    unitView: null,
    fittingandfixtures: null,
    facilitiesandAmenities: null,
    listingAgent: null,
    leadOwner: null,
  });
  const [data, setData] = useReducer(reducer, {
    saleType: [],
    unitView: [],
    fittingandfixtures: [],
    facilitiesandAmenities: [],
    listingAgent: [],
    leadOwner: [],
  });
  const [saleTypeOptionData, setSaleTypeOptionData] = useReducer(reducer, {
    Sale: [],
    Resale: [],
  });

  const [saveDto, setSaveDto] = useState({
    unit_ref_no: null,
    selling_price_agency_fee: {
      salePrice: 0,
      agencyFee: 0,
      persantageFee: 0,
    },
    unit_type_id: 1,
    operation_type: {
      lookupItemId: 430,
      lookupItemName: "Sale",
      lookupItemCode: null,
      description: null,
      order: 1,
      parentLookupItemId: null,
      parentLookupItemName: null,
      parentLookupTypeId: 0,
      parentLookupTypeName: null,
      isEditable: false,
    },
    new_unit: "Yes",
    unit_type: null,
    furnished: "No",
    property_name: null,
    owner: null,
    lead_owner: null,
    floor_number: null,
    mandates: null,
    mandate_type: null,
    unit_number: null,
    mortgaged: null,
    can_view: null,
    unique_selling_proposition_usp: null,
    municipality_number: null,
    ejari_approval_refno: null,
    inventory_list_from: null,
    website_unit_link: null,
    fully_unit_description: null,
    unit_model: null,
    full_floor: null,
    bedrooms: null,
    bathrooms: null,
    maid_rooms: null,
    store_room: null,
    study_room: null,
    kids_breakfast_room: null,
    kitchen_details: null,
    number_of_balcony: null,
    number_of_terraces: null,
    floor_height: null,
    plot_area_sqft: null,
    builtup_area_sqft: null,
    gfa_sqft: null,
    balcony_area_size_sqft: null,
    terrace_size_sqft: null,
    total_area_size_sqft: null,
    price_per_sqm: null,
    amenities: null,
    fitting_and_fixtures: null,
    primary_view: null,
    secondary_view: null,
    pets_allowed: null,
    rating: null,
    listing_agent: null,
    listing_date: null,
    listing_expiry_period: null,
    listing_expiry_date: null,
    last_available_for_rent: null,
    market_value_rent: null,
    rent_security_deposit: null,
    rent_general_deposit: null,
    chiller_charge_type: null,
    chiller_charge_for_rent_unit: null,
    chiller_capacity_for_rent_unit: null,
    chiller_deposit: null,
    expenses: null,
    expected_rent: null,
    rent_targeted_price: null,
    rent_price_fees: null,
    rent_roi: null,
    unit_images: null,
    unit_documents_files: null,
    data_completed: 16,
    rent_listing_agent: null,
    lease_lead_owner: null,
    sale_type: null,
  });

  const handleSaleTypeSelectChange = (oprationTypeId) => {
    const selectedSaleTypeObj = data.saleType.find(
      (item) => item.lookupItemId === oprationTypeId
    );
    setSaveDto((item) => ({
      ...item,
      sale_type: selectedSaleTypeObj,
    }));
  };

  const getSaleTypes = async () => {
    const res = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.UnitSaleType,
    });
    if (!(res && res.status && res.status !== 200)) {
      const sale = res?.filter(
        (item) =>
          item?.lookupItemName === "New Sale" && item?.lookupItemId === 432
      );
      const resale = res?.filter(
        (item) =>
          item?.lookupItemName === "Resale" && item?.lookupItemId === 433
      );
      setSaleTypeOptionData({
        id: "Sale",
        value: sale,
      });
      setSaleTypeOptionData({
        id: "Resale",
        value: resale,
      });
      setData({
        id: "saleType",
        value: res,
      });
    } else {
      setSaleTypeOptionData({
        id: "Sale",
        value: [],
      });
      setSaleTypeOptionData({
        id: "Resale",
        value: [],
      });
      setData({
        id: "saleType",
        value: [],
      });
    }
  };

  const UnitViewAPI = useCallback(async () => {
    const results = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.UintView,
    });
    setData({
      id: "unitView",
      value: results,
    });
  }, []);

  const FittingandfixturesAPI = useCallback(async () => {
    const results = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.FittingAndFixtures,
    });
    setData({
      id: "fittingandfixtures",
      value: results,
    });
  }, []);

  const FacilitiesandAmenitiesAPI = useCallback(async () => {
    const results = await lookupItemsGetId({
      lookupTypeId: StaticLookupsIds.FacilitiesAndAmenities,
    });
    setData({
      id: "facilitiesandAmenities",
      value: results,
    });
  }, []);

  const ListingAgentAPI = useCallback(async (value) => {
    const res = await OrganizationUserSearch({
      pageSize: 100,
      pageIndex: 1,
      name: value || "",
      userName: null,
      phoneNumber: null,
      email: null,
      userStatusId: 2,
      userTypeId:
        operationType === 1
          ? AgentRoleEnum.SaleListingAgent.value
          : AgentRoleEnum.LeaseListingAgent.value,
    });
    if (res && res.totalCount === 0) {
      setData({
        id: "listingAgent",
        value: [],
      });
    } else {
      setData({
        id: "listingAgent",
        value: res?.result,
      });
    }
  }, []);

  const LeadOwnerAPI = useCallback(async (value) => {
    const res = await GetContacts({
      pageIndex: 0,
      pageSize: 100,
      search: value,
      isAdvance: false,
    });
    setData({
      id: "leadOwner",
      value: res?.result,
    });
  }, []);

  const debouncedGetLeadOwner = useDebouncedAction(LeadOwnerAPI, 700);
  const debouncedGetListingAgent = useDebouncedAction(ListingAgentAPI, 700);

  const addUnitHandler = async (isContinue) => {
    setIsOnSubmit(true);
    try {
      const res = await unitPost({ unitJson: { unit: saveDto } });

      if (res && res.unitId) {
        setAlertBoxContent({
          display: true,
          variant: "success",
          title: translate("Unit-Add-Successfully"),
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
        if (isContinue)
          history.push(
            `/home/units-sales/unit-profile-edit?formType=1&id=${res.unitId}`
          );
        onClose();
      } else {
        setAlertBoxContent({
          display: true,
          variant: "error",
          title: translate("Failed-to-add-unit"),
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      }
    } catch (error) {
      console.log("something wrong !", error);
      setAlertBoxContent({
        display: true,
        variant: "error",
        title: "Failed-to-add-unit",
        onClose: () => {
          setAlertBoxContent(null);
        },
      });
    } finally {
      setIsOnSubmit(false);
    }
  };

  useEffect(() => {
    getSaleTypes();
    UnitViewAPI();
    FittingandfixturesAPI();
    FacilitiesandAmenitiesAPI();
    ListingAgentAPI();
  }, []);

  useEffect(() => {
    setSaveDto((item) => ({
      ...item,
      unit_number: addNewUnit?.unit?.unitNumber,
      unit_type: addNewUnit?.unit?.unitType,
      property_name: addNewUnit?.unit?.propertyName,
    }));
  }, [addNewUnit]);

  useEffect(() => {
    setview(ViewType);
  }, [ViewType]);

  useEffect(() => {
    if (onSaveCall) {
      addUnitHandler();
    } else if (onSaveAndCompleteCall) {
      addUnitHandler(onSaveAndCompleteCall);
    } else{
      return
    }
  }, [onSaveCall, onSaveAndCompleteCall]);
  return (
    <Box className={styles.wrapperUnitSucsessValidateDialog}>
      <Box className={styles.containerPropretyInfo}>
        <Box className={styles.containerProprtyInfoItem}>
          <Typography className={styles.TitleInfo}>
            {translate("Property-name")}
          </Typography>
          <Typography className={styles.valueInfo}>
            {addNewUnit?.unit?.propertyName?.name || ""}
          </Typography>
        </Box>
        <Box className={styles.containerProprtyInfoItem}>
          <Typography className={styles.TitleInfo}>
            {translate("Unit-type")}
          </Typography>
          <Typography className={styles.valueInfo}>
            {addNewUnit?.unit?.unitType?.lookupItemName || ""}
          </Typography>
        </Box>
        <Box className={styles.containerProprtyInfoItem}>
          <Typography className={styles.TitleInfo}>
            {translate("Unit-number")}
          </Typography>
          <CustomCopyToClipboard
            fontSize={"14px"}
            data={addNewUnit?.unit?.unitNumber}
            childrenData={`#${addNewUnit?.unit?.unitNumber}`}
          />
        </Box>
        {addNewUnit?.unit?.buildingNumber && (
          <Box className={styles.containerProprtyInfoItem}>
            <Typography className={styles.TitleInfo}>
              {translate("Building-number")}
            </Typography>
            <Typography className={styles.valueInfo}>
              {addNewUnit?.unit?.buildingNumber || ""}
            </Typography>
          </Box>
        )}
        {addNewUnit?.unit?.phaseNumber && (
          <Box className={styles.containerProprtyInfoItem}>
            <Typography className={styles.TitleInfo}>
              {translate("Phase-number")}
            </Typography>
            <Typography className={styles.valueInfo}>
              {" "}
              {addNewUnit?.unit?.phaseNumber || ""}
            </Typography>
          </Box>
        )}
      </Box>

      <Box className={styles.containerFillFiled}>
        <Box className={styles.containerFiledItem}>
          <Typography className={styles.titleInputFiled}>
            {translate("Operation-type")}
          </Typography>
          <Box className={styles.containerInputFileds}>
            <CustomRadioButtonGroupItem
              checked={selectedValue.oprationType === 1}
              onChange={(event) => {
                setSelectedValue({
                  id: "oprationType",
                  value: Number(event?.target?.value),
                });
                // setViewType(Number(event?.target?.value));
                setSaveDto((item) => ({
                  ...item,
                  operation_type: {
                    lookupItemId: 430,
                    lookupItemName: "Sale",
                    lookupItemCode: null,
                    description: null,
                    order: 1,
                    parentLookupItemId: null,
                    parentLookupItemName: null,
                    parentLookupTypeId: 0,
                    parentLookupTypeName: null,
                    isEditable: false,
                  },
                }));
              }}
              label="Sale"
              icon={<SaleIcon width="20" height="20" />}
              value={1}
            />
            <CustomRadioButtonGroupItem
              checked={selectedValue.oprationType === 2}
              onChange={(event) => {
                setSelectedValue({
                  id: "oprationType",
                  value: Number(event?.target?.value),
                });
                setViewType(Number(event?.target?.value));
                setSaveDto((item) => ({
                  ...item,
                  operation_type: {
                    lookupItemId: 431,
                    lookupItemName: "Rent",
                    lookupItemCode: null,
                    description: null,
                    order: 2,
                    parentLookupItemId: null,
                    parentLookupItemName: null,
                    parentLookupTypeId: 0,
                    parentLookupTypeName: null,
                    isEditable: false,
                  },
                }));
              }}
              label="Rent"
              icon={<RentIcon width="20" height="20" />}
              value={2}
            />
          </Box>
        </Box>
        <Divider />

        <Box className={styles.containerFiledItem}>
          <Typography className={styles.titleInputFiled}>
            {translate("Sale-type")}*
          </Typography>
          <Box className={styles.containerInputFileds}>
            <CustomRadioButtonGroupItem
              checked={
                selectedValue.saleType ===
                Number(saleTypeOptionData?.Sale[0]?.lookupItemId)
              }
              onChange={(event) => {
                const oprationTypeId = Number(event?.target?.value);
                setSelectedValue({
                  id: "saleType",
                  value: Number(oprationTypeId),
                });
                handleSaleTypeSelectChange(oprationTypeId);
              }}
              label={translate("Primary-sale")}
              icon={<BuildingIcon width="20" height="20" />}
              value={Number(saleTypeOptionData?.Sale[0]?.lookupItemId)}
            />
            <CustomRadioButtonGroupItem
              checked={
                selectedValue?.saleType ===
                Number(saleTypeOptionData?.Resale[0]?.lookupItemId)
              }
              onChange={(event) => {
                const oprationTypeId = Number(event?.target?.value);
                setSelectedValue({
                  id: "saleType",
                  value: Number(oprationTypeId),
                });
                handleSaleTypeSelectChange(oprationTypeId);
              }}
              label={translate("Resale")}
              icon={<ReleaseIcon width="20" height="20" />}
              value={Number(saleTypeOptionData?.Resale[0]?.lookupItemId)}
            />
          </Box>
        </Box>
        <Divider />

        <Box className={styles.containerFiledItem}>
          <Typography className={styles.titleInputFiled}>
            {translate("Occupancy-status")}
          </Typography>
          <Box className={styles.containerInputFileds}>
            <CustomRadioButton
              checked={selectedValue?.occupancyStatus === 1}
              onChange={(event) => {
                const newValue = Number(event.target.value);
                setSelectedValue({
                  id: "occupancyStatus",
                  value: newValue,
                });
                setSaveDto((item) => ({
                  ...item,
                  new_unit: (newValue ? "Yes" : "No") || null,
                }));
              }}
              label={translate("New-Never-occupied")}
              value={1}
            />
            <CustomRadioButton
              checked={selectedValue?.occupancyStatus === 0}
              onChange={(event) => {
                const newValue = Number(event.target.value);
                setSelectedValue({
                  id: "occupancyStatus",
                  value: newValue,
                });
                setSaveDto((item) => ({
                  ...item,
                  new_unit: (newValue ? "Yes" : "No") || null,
                }));
              }}
              label={translate("Previously-occupied")}
              value={0}
            />
          </Box>
        </Box>
        <Divider />

        <Box className={styles.containerFiledItem}>
          <Typography className={styles.titleInputFiled}>
            {translate("Bedroom")}
          </Typography>
          <Box className={styles.containerInputFileds}>
            <CustomNumberSelector
              valuesArray={nmbrSelectArray}
              onChange={(value) => {
                setSelectedValue({
                  id: "bedroom",
                  value: value,
                });
                setSaveDto((item) => ({ ...item, bedrooms: value }));
              }}
            />
          </Box>
        </Box>
        <Divider />

        <Box className={styles.containerFiledItem}>
          <Typography className={styles.titleInputFiled}>
            {translate("Bathroom")}
          </Typography>
          <Box className={styles.containerInputFileds}>
            <CustomNumberSelector
              valuesArray={nmbrSelectArray}
              onChange={(value) => {
                setSelectedValue({
                  id: "bathroom",
                  value: value,
                });
                setSaveDto((item) => ({ ...item, bathrooms: value }));
              }}
            />
          </Box>
        </Box>
        <Divider />

        <Box className={styles.containerFiledItem}>
          <Typography className={styles.titleInputFiled}>
            {translate("Size")}
          </Typography>
          <CustomInput
            placeholder={translate("1,000.00")}
            hasSearchIcon={false}
            style={{ maxWidth: "100%" }}
            errorMessage={translate("Invalid_Url")}
            onChange={(e) => {
              const newValue = e.target.value;
              setSaveDto((item) => ({
                ...item,
                builtup_area_sqft: newValue,
              }));
              setSelectedValue({
                id: "size",
                value: newValue,
              });
            }}
            preInputText={"Sqft"}
          />
        </Box>
        <Divider />

        <Box className={styles.containerFiledItem}>
          <Typography className={styles.titleInputFiled}>
            {translate("Unit-views")}
          </Typography>
          <CustomAutocomplete
            placeholder={translate("Select")}
            options={data?.unitView || []}
            onChange={(e, value) => {
              setSelectedValue({
                id: "unitView",
                value: value,
              });
              setSaveDto((item) => ({ ...item, primary_view: value }));
            }}
            value={selectedValue?.unitView || null}
            getOptionLabel={(option) => option?.lookupItemName || ""}
            getOptionValue={(option) => option?.lookupItemId || ""}
            renderOption={(option) => option?.lookupItemName || ""}
          />
        </Box>
        <Divider />

        <Box className={styles.containerFiledItem}>
          <Typography className={styles.titleInputFiled}>
            {translate("Fitting-and-Fixtures")}
          </Typography>
          <CustomAutocomplete
            placeholder={translate("Select")}
            options={data?.fittingandfixtures || []}
            onChange={(e, value) => {
              setSelectedValue({
                id: "fittingandfixtures",
                value: value,
              });
              setSaveDto((item) => ({
                ...item,
                fitting_and_fixtures: value,
              }));
            }}
            value={selectedValue?.fittingandfixtures || null}
            getOptionLabel={(option) => option?.lookupItemName || ""}
            getOptionValue={(option) => option?.lookupItemId || ""}
            renderOption={(option) => option?.lookupItemName || ""}
          />
        </Box>
        <Divider />

        <Box className={styles.containerFiledItem}>
          <Typography className={styles.titleInputFiled}>
            {translate("FacilitiesAndAmenities")}
          </Typography>
          <CustomAutocomplete
            placeholder={translate("Select")}
            options={data?.facilitiesandAmenities || []}
            onChange={(e, value) => {
              setSelectedValue({
                id: "facilitiesandAmenities",
                value: value,
              });
              setSaveDto((item) => ({ ...item, amenities: value }));
            }}
            value={selectedValue?.facilitiesandAmenities || null}
            getOptionLabel={(option) => option?.lookupItemName || ""}
            getOptionValue={(option) => option?.lookupItemId || ""}
            renderOption={(option) => option?.lookupItemName || ""}
          />
        </Box>
        <Divider />

        <Box className={styles.containerFiledItem}>
          <Typography className={styles.titleInputFiled}>
            {translate("Selling-price")}
          </Typography>
          <CustomInput
            placeholder={translate("0.0")}
            hasSearchIcon={false}
            value={saveDto.selling_price_agency_fee.salePrice}
            style={{ maxWidth: "100%" }}
            type="number"
            min={0}
            onChange={(e) => {
              if (!e.target.value) {
                const editSellingPriceAgencyFee = {
                  ...saveDto.selling_price_agency_fee,
                  salePrice: 0,
                  persantageFee: 0,
                  agencyFee: 0,
                };
                setSaveDto((item) => ({
                  ...item,
                  selling_price_agency_fee: editSellingPriceAgencyFee,
                }));
                return;
              }
              const salePrice = e.target.value;
              const agencyFeeValue = saveDto.selling_price_agency_fee.agencyFee;
              const editSellingPriceAgencyFee = {
                ...saveDto.selling_price_agency_fee,
                salePrice,
                persantageFee:
                  agencyFeeValue && salePrice
                    ? (agencyFeeValue / salePrice) * 100
                    : 0,
              };
              setSaveDto((item) => ({
                ...item,
                selling_price_agency_fee: editSellingPriceAgencyFee,
              }));
            }}
            preInputText={"AED"}
          />
        </Box>
        <Divider />

        <Box className={styles.containerFiledItem}>
          <Typography className={styles.titleInputFiled}>
            {translate("Agency-fee")}
          </Typography>
          <Box className={styles.containerAgencyfeeFileds}>
            <CustomInput
              placeholder={translate("0.0")}
              hasSearchIcon={false}
              style={{ maxWidth: "100%" }}
              value={saveDto.selling_price_agency_fee.agencyFee}
              onKeyUp={(e) => {
                const salePriceValue =
                  saveDto.selling_price_agency_fee.salePrice;

                const value =
                  e && e.target && e.target.value ? e.target.value : 0;
                const fixed = value && value.replace(/,/g, "");
                let agencyFee = fixed ? parseFloat(fixed) : 0;

                if (agencyFee > salePriceValue) agencyFee = salePriceValue;

                const editSellingPriceAgencyFee = {
                  ...saveDto.selling_price_agency_fee,
                  agencyFee: salePriceValue ? agencyFee : 0,
                  persantageFee:
                    salePriceValue && agencyFee
                      ? (agencyFee / salePriceValue) * 100
                      : 0,
                };
                setSaveDto((item) => ({
                  ...item,
                  selling_price_agency_fee: editSellingPriceAgencyFee,
                }));
              }}
              preInputText={"AED"}
            />
            <CustomInput
              placeholder={translate("0.0")}
              hasSearchIcon={false}
              style={{ maxWidth: "100%" }}
              value={saveDto.selling_price_agency_fee.persantageFee}
              onKeyUp={(e) => {
                const salePriceValue =
                  saveDto.selling_price_agency_fee.salePrice;
                const value =
                  e && e.target && e.target.value ? e.target.value : 0;
                const fixed = value && value.replace(/,/g, "");

                let persantageFee =
                  salePriceValue && fixed ? parseFloat(fixed) : 0;
                if (persantageFee > 100) persantageFee = 100;

                const agencyFeeValue =
                  salePriceValue && persantageFee
                    ? (persantageFee / 100) * salePriceValue
                    : 0;
                const editSellingPriceAgencyFee = {
                  ...saveDto.selling_price_agency_fee,
                  agencyFee: agencyFeeValue,
                  persantageFee,
                };
                setSaveDto((item) => ({
                  ...item,
                  selling_price_agency_fee: editSellingPriceAgencyFee,
                }));
              }}
              preInputText={"%"}
            />
          </Box>
        </Box>
        <Divider />

        <Box className={styles.containerFiledItem}>
          <Typography className={styles.titleInputFiled}>
            {translate("Listing-agent")}
          </Typography>
          <CustomAutocomplete
            variant="icon"
            placeholder={translate("Select")}
            options={data?.listingAgent || []}
            onChange={(e, value) => {
              setSelectedValue({
                id: "listingAgent",
                value: value,
              });
              setSaveDto((item) => ({
                ...item,
                listing_agent: {
                  email: value.email || "",
                  id: value.id,
                  name: value.fullName,
                  phone: value.phoneNumber || "",
                  userName: value.userName,
                },
              }));
            }}
            onInputKeyUp={(e) => {
              const value = e?.target?.value || null;
              debouncedGetListingAgent(value);
            }}
            value={selectedValue?.listingAgent || null}
            getOptionLabel={(option) => option?.fullName || ""}
            renderOption={(option) => option?.fullName || ""}
          />
        </Box>
        <Divider />

        <Box className={styles.containerFiledItem}>
          <Typography className={styles.titleInputFiled}>
            {translate("Lead-owner")}
          </Typography>
          <CustomAutocomplete
            variant="icon"
            options={data?.leadOwner || []}
            onInputKeyUp={(e) => {
              const value = e?.target?.value || null;
              debouncedGetLeadOwner(value);
            }}
            value={selectedValue?.leadOwner || null}
            getOptionLabel={(option) =>
              (option.contact &&
                (option.contact.first_name || option.contact.last_name) &&
                `${option.contact.first_name} ${option.contact.last_name}`) ||
              option.contact.company_name ||
              ""
            }
            renderOption={(option) =>
              (option.contact &&
                (option.contact.first_name || option.contact.last_name) &&
                `${option.contact.first_name} ${option.contact.last_name}`) ||
              option.contact.company_name ||
              ""
            }
            onChange={(e, value) => {
              setSelectedValue({
                id: "leadOwner",
                value: value,
              });
              setSaveDto((item) => ({
                ...item,
                lead_owner: {
                  id: value.contactsId,
                  name:
                    (value.contact &&
                      (value.contact.first_name || value.contact.last_name) &&
                      `${value.contact.first_name} ${value.contact.last_name}`) ||
                    value.contact.company_name ||
                    "",
                  type: value.contact.contact_type_id,
                },
              }));
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default UnitsAddForSaleView;
