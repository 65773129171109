import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteComponent } from "../../../../Components";
import { GetCorporateContacts } from "../../../../Services";
import { ClassificationsContactEnum } from "../../../../Enums";

export const ContactComponent = ({
  parentTranslationPath,
  translationPath,
  selected,
  state,
  setState,
  setSelected,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const searchTimer = useRef(null);
  const [isLoading, setIsLoading] = useState({
    allContacts: false,
  });
  const [allContacts, setAllContacts] = useState([]);
  const onContactChangeHandler = (contact) => {
    const newState = {
      ...state,
      developerName: (contact?.id && +contact.id) || null,
      landLineNumber: contact?.landLineNumber || null,
      languageId:
        (contact?.language && contact?.language?.lookupItemId) || null,
      cityId: (contact?.city && contact?.city?.lookupItemId) || null,
      countryId: (contact?.country && contact?.country?.lookupItemId) || null,
    };
    const newSelected = {
      ...selected,
      developerName: contact || null,
      landLineNumber: contact?.landLineNumber || null,
      language: contact?.language || null,
      city: contact?.city || null,
      country: contact?.country || null,
    };
    setState({ id: "edit", value: newState });
    setSelected({ id: "edit", value: newSelected });
  };

  const getAllCorporateContacts = async (search) => {
    let list = [];
    setIsLoading((item) => ({ ...item, allContacts: true }));
    const rs = await GetCorporateContacts({
      pageIndex: 0,
      pageSize: 100,
      search: search,
      classificationId:
        ClassificationsContactEnum.find(
          (f) => f.name === "Real Estate - Developer"
        )?.Id || null,
    });
    if (!rs || !rs.result || !rs.result.length) setAllContacts([]);
    else {
      rs.result.map((element) => {
        if (+element?.contact?.contact_type_id === 2) {
          list.push({
            id: element.contactsId,
            name: element.contact.company_name,
            country: element.contact?.country,
            city: element.contact?.city,
            landLineNumber:
              (element.contact.landline_number &&
                element.contact.landline_number.phone) ||
              null,
            email_address:
              (element.contact.general_email &&
                element.contact.general_email.email) ||
              null,
            language: element.contact?.language || null,
          });
        }
      });
      setAllContacts([...list]);
    }
    setIsLoading((item) => ({ ...item, allContacts: false }));
  };

  useEffect(() => {
    getAllCorporateContacts();
  }, []);

  return (
    <>
      <div className="w-100 d-flex-column-center">
        <AutocompleteComponent
          selectedValues={(selected && selected?.developerName) || null}
          idRef={`developerContactNameRef`}
          multiple={false}
          inputPlaceholder={t("select-contact")}
          data={allContacts}
          inputClasses="inputs theme-form-builder"
          displayLabel={(option) => (option && option.name) || ""}
          renderOption={(option) => (
            <div className="d-flex-column">
              <div className="d-flex-v-center-h-between w-100 texts-truncate">
                {option.name}
              </div>
              <span className="c-gray-secondary">{option.id}</span>
            </div>
          )}
          onChange={(e, newValue) => {
            onContactChangeHandler(newValue);
          }}
          isLoading={isLoading.allContacts}
          withLoader
          withoutSearchButton
          labelValue={t("corporate-contact")}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          onInputKeyUp={(e) => {
            const { value } = e.target;
            if (searchTimer.current) clearTimeout(searchTimer.current);
            searchTimer.current = setTimeout(() => {
              getAllCorporateContacts(value);
            }, 800);
          }}
          filterOptions={(options) => options}
          getOptionSelected={(option) => option?.id === state?.developerName}
          filterSelectedOptions
        />
      </div>
    </>
  );
};
