import React, { useState, useEffect, useContext } from "react";
import { Box, Menu } from "@material-ui/core";
import { useSelector } from "react-redux";
import clsx from "clsx";

import {
  UpdateUserSearchCriteria,
  DeleteListOfSearchCriteria,
} from "../../../Services";
import { CustomButton, CustomIconButton, CustomInput } from "..";
import { useIsAr, useSelectedTheme } from "../../../Hooks";
import { useNewLayoutShared } from "../../../Contexts/NewLayoutSharedContext";
import {
  FilterLinesICon,
  DropdownIcon,
  DeleteIcon,
} from "../../../assets/icons";

import useStyles from "./styles";

export default function SavedFiltersAutoComplete() {
  const { savedFiltersCriteria, setSavedFiltersCriteria } =
    useNewLayoutShared();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isManageToggle, setIsManageToggle] = useState(true);
  const [isModified, setIsModified] = useState(false);
  const theme = useSelector((store) => store.theme);
  const { isAr } = useIsAr(theme.themeDirection);
  const open = Boolean(anchorEl);
  const name = "Select saved filters";
  const [selectedValue, setSelectedValue] = useState(name);
  const [selectedId, setSelectedId] = useState(undefined);

  const initializeViews = () => [...(savedFiltersCriteria?.data || [])];

  const [originalViews, setOriginalViews] = useState(initializeViews);
  const [views, setViews] = useState([...originalViews]);
  const [deletedIds, setDeletedIds] = useState([]); // Track deleted IDs

  useEffect(() => {
    const uniqueViews = initializeViews();
    setOriginalViews([...uniqueViews]);
    setViews([...uniqueViews]);
  }, [savedFiltersCriteria?.data]);

  useEffect(() => {
    if (savedFiltersCriteria?.selectedId === 0) {
      setSelectedValue(name);
      setSelectedId(0);
    }
  }, [savedFiltersCriteria?.selectedId]);

  useEffect(() => {
    setSavedFiltersCriteria((prevData) => ({
      ...prevData,
      selectedValue,
      selectedId,
    }));
  }, [selectedValue, selectedId]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInputChange = (id, newName) => {
    setViews((prevViews) =>
      prevViews.map((view) =>
        view.userSearchCriteriaId === id
          ? { ...view, fieldsGroupName: newName }
          : view
      )
    );
    setIsModified(true);
  };

  const handleDelete = (id) => {
    setViews((prevViews) =>
      prevViews.filter((view) => view.userSearchCriteriaId !== id)
    );
    setDeletedIds((prevDeletedIds) => [...prevDeletedIds, id]); // Track deleted IDs
    setIsModified(true);
  };

  const handleCancel = () => {
    setViews([...originalViews]);
    setDeletedIds([]); // Clear deleted IDs on cancel
    setIsManageToggle(true);
    setIsModified(false);
  };

  const handleUpdate = async () => {
    try {
      const updatedViews = views.filter((view) => {
        const originalView = originalViews.find(
          (ov) => ov.userSearchCriteriaId === view.userSearchCriteriaId
        );
        return (
          originalView && originalView.fieldsGroupName !== view.fieldsGroupName
        );
      });

      const payload = updatedViews.map((view) => ({
        userFilterGroupId: view.userSearchCriteriaId,
        fieldsGroupName: view.fieldsGroupName,
        fieldsGroupData: JSON.stringify(view.fieldsDetails),
        searchFiledsType: 1,
      }));

      if (payload.length > 0) {
        await UpdateUserSearchCriteria(payload);
      }

      if (deletedIds.length > 0) {
        await DeleteListOfSearchCriteria(deletedIds);
        setDeletedIds([]); // Clear deleted IDs after update
      }

      setOriginalViews([...views]);
      setSavedFiltersCriteria((prevData) => ({
        ...prevData,
        data: [...views],
      }));

      setIsManageToggle(true);
      setIsModified(false);
    } catch (error) {
      console.error("Error updating user column groups:", error);
    }
  };

  const anchorOrigin = isAr
    ? { vertical: "bottom", horizontal: "left" }
    : { vertical: "bottom", horizontal: "right" };

  const transformOrigin = isAr
    ? { vertical: "top", horizontal: "left" }
    : { vertical: "top", horizontal: "right" };

  const style = {
    width: "auto",
    borderColor:
      selectedValue !== name ? palette.border.brand : palette.border.primary,
    boxShadow:
      selectedValue !== name
        ? "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(140, 115, 75, 0.24)"
        : "none",
  };

  return (
    <>
      <CustomIconButton
        style={style}
        variant="outlined"
        size="md"
        boxShadow="none"
        hideHoverBg={true}
        color="secondary"
        isDropDown={true}
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Box
          className={styles.iconContainer}
          style={
            selectedValue !== name
              ? {
                  color: palette.text.secondary,
                  fontWeight: 500,
                }
              : { color: palette.text.placeholder, fontWeight: 400 }
          }
        >
          <FilterLinesICon
            width="20"
            height="20"
            fill={palette.button.secondary_fg}
          />
          {selectedValue}
        </Box>

        <DropdownIcon
          width="20"
          height="20"
          fill={palette.button.secondary_fg}
        />
      </CustomIconButton>

      <Menu
        id="custom-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        getContentAnchorEl={null}
        PaperProps={{
          className: styles.customMenu,
        }}
        MenuListProps={{
          className: styles.customMenuList,
        }}
      >
        <Box className={styles.dropdownMenu}>
          <Box
            className={clsx(styles.item, {
              [styles.isManageToggle]: isManageToggle,
            })}
            key={0}
            onClick={() => {
              if (isManageToggle) {
                setSelectedValue(name);
                setSelectedId(0);
                setAnchorEl(null);
              }
            }}
          >
            {isManageToggle && (
              <span
                className={styles.text}
                id={0}
                onClick={() => {
                  setSelectedValue(name);
                  setSelectedId(0);
                  setAnchorEl(null);
                }}
              >
                {savedFiltersCriteria?.data?.length === 0 ? "No options" : name}
              </span>
            )}
          </Box>

          {views?.map((item) => (
            <Box
              className={clsx(styles.item, {
                [styles.isManageToggle]: isManageToggle,
              })}
              key={item?.userSearchCriteriaId}
              onClick={() => {
                if (isManageToggle) {
                  setSelectedValue(item?.searchCriteriaName || "");
                  setSelectedId(item?.userSearchCriteriaId);
                  setAnchorEl(null);
                }
              }}
            >
              {isManageToggle ? (
                <span
                  className={styles.text}
                  id={item?.userSearchCriteriaId}
                  onClick={() => {
                    setSelectedValue(item?.searchCriteriaName || "");
                    setSelectedId(item?.userSearchCriteriaId);
                    setAnchorEl(null);
                  }}
                >
                  {item?.searchCriteriaName}
                </span>
              ) : (
                item?.searchCriteriaName !== name && (
                  <>
                    <CustomInput
                      CustomClass={styles.inputField}
                      hasSearchIcon={false}
                      type="text"
                      value={item?.searchCriteriaName}
                      onChange={(e) => {
                        handleInputChange(
                          item?.userSearchCriteriaId,
                          e.target.value
                        );

                        const currentId = views.find(
                          (item) => item?.searchCriteriaName === selectedValue
                        )?.userSearchCriteriaId;

                        if (currentId === item?.userSearchCriteriaId) {
                          setSelectedValue(e.target.value);
                          setSelectedId(item?.userSearchCriteriaId);
                        }
                      }}
                      handleClick={(e) => {
                        e.stopPropagation();
                      }}
                    />

                    {selectedValue !== item?.searchCriteriaName && (
                      <DeleteIcon
                        width="20"
                        height="20"
                        style={{ cursor: "pointer" }}
                        fill={palette.button.secondary_fg}
                        onClick={() => handleDelete(item?.userSearchCriteriaId)}
                      />
                    )}
                  </>
                )
              )}
            </Box>
          ))}
        </Box>

        {isManageToggle ? (
          <Box className={styles.action}>
            <CustomButton
              style={{ width: "100%" }}
              onClick={() => {
                setIsManageToggle((prev) => !prev);
              }}
              boxShadow="xs"
              size="md"
              variant="contained"
            >
              Manage saved filters
            </CustomButton>
          </Box>
        ) : (
          <Box className={styles.actions}>
            <CustomButton
              boxShadow="xs"
              style={{ width: "100%" }}
              size="md"
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
            >
              Cancel
            </CustomButton>
            <CustomButton
              boxShadow="non"
              size="md"
              style={{ width: "100%" }}
              variant="contained"
              color="primary"
              onClick={handleUpdate}
              disabled={!isModified}
            >
              Update
            </CustomButton>
          </Box>
        )}
      </Menu>
    </>
  );
}
