import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import { CustomInput } from "../../../../CustomInput";
import { useTranslate } from "../../../../../../Hooks";

//styles
import useStyles from "./styles";

const PriceAndPercentageSection = ({
  type,
  onChange,
  currency,
  value,
  labelValue,
  ORAdornment,
  allItemsValues,
  area,
  onValueChanged,
  itemList,
  values,
  sqrSalePrice,
  setData,
  hideRentPerSqFt,
  depositSum,
  hideDeposit,
  itemIsSensitive,
  canChange,
}) => {
  const styles = useStyles();
  const { translate } = useTranslate("HeaderView");

  const [builtupAreaSqft, setBuiltupAreaSqfte] = useState(
    allItemsValues && allItemsValues.builtup_area_sqft
      ? allItemsValues.builtup_area_sqft &&
          allItemsValues.builtup_area_sqft !== "" &&
          +allItemsValues.builtup_area_sqft
      : area || 0
  );
  const sqrSalePrice2 =
    sqrSalePrice !== undefined && sqrSalePrice !== "" && sqrSalePrice !== null
      ? sqrSalePrice
      : 0;
  const [salePrice, setSalePrice] = useState(
    value && value.salePrice ? value.salePrice : 0
  );
  const [agencyFee, setAgencyFee] = useState(
    value && value.agencyFee ? value.agencyFee : 0
  );
  const [persantageFee, setPersantageFee] = useState(
    value && value.persantageFee ? value.persantageFee : 0
  );
  const [rentPerSq, setRentPerSq] = useState(
    value && value.rentPerSq ? value.rentPerSq : 0
  );
  const [rentPerYear, setRentPerYear] = useState(
    value && value.rentPerYear ? value.rentPerYear : 0
  );
  const [rentPerMonth, setRentPerMonth] = useState(
    value && value.rentPerMonth ? value.rentPerMonth : 0
  );
  const [rentPerYearDisply, setRentPerYearDisply] = useState(0);
  const [isFirst, setFirst] = useState(false);

  const [commission, setcommission] = useState(
    value && value.commission ? value.commission : 0
  );
  const [commissionPer, setCommissionPer] = useState(
    value && value.commissionPer ? value.commissionPer : 0.0
  );
  const [deposit, setDeposit] = useState(
    value && value.deposit ? value.deposit : 0
  );
  const [depositPer, setDepositPer] = useState(
    value && value.depositPer ? value.depositPer : 0
  );


  useEffect(() => {
    if (values && !itemIsSensitive && type === "Sale Type") {
      const sellerHasPaid =
        values && values.seller_has_paid ? values.seller_has_paid : 0;
      const sub = salePrice - sellerHasPaid;
      setData("amount_due_to_developer", sub);
      setData("amount_due-to-developer", sub);
    }
    if (
      builtupAreaSqft &&
      builtupAreaSqft !== "********" &&
      salePrice &&
      !itemIsSensitive
    ) {
      const pricesqm = salePrice / builtupAreaSqft;
      if (setData) {
        setData("pricesqm", pricesqm || "0");
      } else if (onValueChanged) {
        onValueChanged(pricesqm, 0, "pricesqm");
        if (!pricesqm) onValueChanged(0, undefined, "pricesqm");
        else {
          onValueChanged(pricesqm, 0, "pricesqm");
        }
      }
    }
  }, [salePrice, type]);

  useEffect(() => {
    if (type === "Rent Type") {
      setDeposit(depositSum);
      const rentPriceFees =
        (allItemsValues && allItemsValues.rent_price_fees) ||
        (values && values.rent_price_fees);
      if (rentPriceFees)
        onChange({
          rentPerSq: rentPriceFees.rentPerSq || rentPerSq || 0,
          rentPerYear: rentPriceFees.rentPerYear || rentPerYear || 0,
          rentPerMonth: rentPriceFees.rentPerMonth || rentPerMonth || 0,
          commission: rentPriceFees.commission || commission || 0,
          commissionPer: rentPriceFees.commissionPer || commissionPer || 0,
          deposit: depositSum,
          depositPer,
        });
      else if (!rentPriceFees)
        onChange({
          rentPerSq: 0,
          rentPerYear: 0,
          rentPerMonth: 0,
          commission: 0,
          commissionPer: 0,
          deposit: depositSum,
          depositPer: 0,
        });
    }
  }, [depositSum, type]);

  return (
    <>
      {type === "Rent Type" && (
        <Box className={styles.containerPriceAndPercentageSection}>
          <Divider />
          <Box className={styles.containerFiledItem}>
            <Typography className={styles.titleInputFiled}>
              {translate("Rent-per-year")}
            </Typography>
            <CustomInput
              placeholder={translate("0.0")}
              hasSearchIcon={false}
              value={!itemIsSensitive ? rentPerYear : "********"}
              style={{ maxWidth: "100%" }}
              type="number"
              min={0}
              onChange={(e) => {
                const rentPerYearValue = e.target.value;
                if (!rentPerYearValue) {
                  setRentPerYear(0);
                  setRentPerSq(0);
                  setcommission(0);
                  setCommissionPer(0);
                  //setDeposit(0);
                  // setDepositPer(0);
                  setRentPerMonth(0);
                  onChange({
                    rentPerSq: 0,
                    rentPerYear: 0,
                    rentPerMonth: 0,
                    commission: 0,
                    commissionPer: 0,
                    deposit: deposit,
                    depositPer,
                  });
                  return;
                }
                setRentPerYear(rentPerYearValue);
                const rentPerSqValue = builtupAreaSqft
                  ? rentPerYearValue / builtupAreaSqft
                  : 0;
                setRentPerSq(rentPerSqValue);
                const rentPerMonthValue = rentPerYearValue / 12;
                setRentPerMonth(rentPerYearValue ? rentPerMonthValue : 0);
                let depositPerValue = 0;
                let commissionPerValue = 0;
                if (commission > rentPerYearValue) {
                  setcommission(rentPerYearValue);
                  setCommissionPer(100);
                } else {
                  commissionPerValue =
                    commission && rentPerYearValue
                      ? (commission / rentPerYearValue) * 100
                      : 0;
                  if (commissionPerValue > 100) commissionPerValue = 100;
                  setCommissionPer(commissionPerValue || 0);
                }

                // if (deposit > rentPerYearValue) {
                //   setDeposit(rentPerYearValue);
                //  // setDepositPer(100);
                // } else {
                //   depositPerValue = deposit && rentPerYearValue ? (deposit / rentPerYearValue) * 100 : 0;
                //   if (depositPerValue > 100)
                //     depositPerValue = 100;
                //  // setDepositPer(depositPerValue || 0);
                // }
                onChange({
                  rentPerSq: rentPerSqValue,
                  rentPerYear: rentPerYearValue,
                  rentPerMonth: rentPerMonthValue,
                  commission:
                    commission > rentPerYearValue
                      ? rentPerYearValue
                      : commission,
                  commissionPer: commissionPerValue || 0,
                  deposit,
                  depositPer,
                });
              }}
              preInputText={"AED"}
            />
          </Box>
          <Divider />

          <Box className={styles.containerFiledItem}>
            <Typography className={styles.titleInputFiled}>
              {translate("Rent-per-month")}
            </Typography>
            <CustomInput
              placeholder={translate("0.0")}
              hasSearchIcon={false}
              value={!itemIsSensitive ? rentPerMonth : "********"}
              style={{ maxWidth: "100%" }}
              type="number"
              min={0}
              onKeyUp={(e) => {
                const monthValue =
                  e && e.target && e.target.value ? e.target.value : 0;
                const fixed = monthValue && monthValue.replace(/,/g, "");
                const editMonth = parseFloat(fixed);
                if (editMonth === 0) {
                  setRentPerYear(0);
                  setRentPerMonth(0);
                  setcommission(0);
                  setCommissionPer(0);
                  //setDeposit(0);
                  //setDepositPer(0);
                  onChange({
                    rentPerSq,
                    rentPerYear: 0,
                    rentPerMonth: 0,
                    commission: 0,
                    commissionPer: 0,
                    deposit,
                    depositPer,
                  });
                  return;
                }
                const rentPerYearValue = editMonth * 12;
                setRentPerSq(
                  builtupAreaSqft ? rentPerYearValue / builtupAreaSqft : 0
                );
                setRentPerYear(rentPerYearValue);
                setRentPerMonth(editMonth);
                let commissionPerValue = 0;
                if (commission > rentPerYearValue) {
                  setcommission(rentPerYearValue);
                  setCommissionPer(100);
                } else {
                  commissionPerValue =
                    commission && rentPerYearValue
                      ? (commission / rentPerYearValue) * 100
                      : 0;
                  if (commissionPerValue > 100) commissionPerValue = 100;
                  setCommissionPer(commissionPerValue || 0);
                }
                // let depositPerValue = 0;
                // if (deposit > rentPerYearValue) {
                //   setDeposit(rentPerYearValue);
                //   setDepositPer(100);
                // } else {
                //   depositPerValue = deposit && rentPerYearValue ? ((deposit / rentPerYearValue) * 100) : 0;
                //   if (depositPerValue > 100)
                //     depositPerValue = 100;
                //   setDepositPer(depositPerValue || 0);
                // }

                onChange({
                  rentPerSq: builtupAreaSqft
                    ? rentPerYearValue / builtupAreaSqft
                    : 0,
                  rentPerYear: rentPerYearValue,
                  rentPerMonth: editMonth,
                  commission:
                    commission > rentPerYearValue
                      ? rentPerYearValue
                      : commission,
                  commissionPer: commissionPerValue || 0,
                  deposit,
                  depositPer,
                });
              }}
              preInputText={"AED"}
            />
          </Box>
          <Divider />

          <Box className={styles.containerFiledItem}>
            <Typography className={styles.titleInputFiled}>
              {translate("Commission")}
            </Typography>
            <Box className={styles.containerAgencyfeeFileds}>
              <CustomInput
                placeholder={translate("0.0")}
                hasSearchIcon={false}
                style={{ maxWidth: "100%" }}
                value={!itemIsSensitive ? commission : "********"}
                onKeyUp={(e) => {
                  if (rentPerYear === 0) {
                    setcommission(0);
                    setCommissionPer(0);
                    onChange({
                      rentPerSq,
                      rentPerYear,
                      rentPerMonth,
                      commission: 0,
                      commissionPer: 0,
                      deposit,
                      depositPer,
                    });
                    return;
                  }

                  const commissionNumber =
                    e && e.target && e.target.value ? e.target.value : 0;
                  const fixed =
                    commissionNumber && commissionNumber.replace(/,/g, "");
                  const editValue = parseFloat(fixed);
                  if (editValue > rentPerYear) {
                    setcommission(rentPerYear);
                    setCommissionPer(100);
                    onChange({
                      rentPerSq,
                      rentPerYear,
                      rentPerMonth,
                      commission: rentPerYear,
                      commissionPer: 100,
                      deposit,
                      depositPer,
                    });
                  } else {
                    setcommission(rentPerYear ? editValue : 0);
                    const perNumber = rentPerYear
                      ? (editValue / rentPerYear) * 100
                      : 0;
                    setCommissionPer(perNumber || 0);
                    onChange({
                      rentPerSq,
                      rentPerYear,
                      rentPerMonth,
                      commission: editValue,
                      commissionPer: perNumber || 0,
                      deposit,
                      depositPer,
                    });
                  }
                }}
                preInputText={"AED"}
              />
              <CustomInput
                placeholder={translate("0.0")}
                hasSearchIcon={false}
                style={{ maxWidth: "100%" }}
                value={!itemIsSensitive ? commissionPer : "********"}
                onKeyUp={(e) => {
                  if (rentPerYear === 0) {
                    setcommission(0);
                    setCommissionPer(0);
                    onChange({
                      rentPerSq,
                      rentPerYear,
                      rentPerMonth,
                      commission: 0,
                      commissionPer: 0,
                      deposit,
                      depositPer,
                    });
                    return;
                  }

                  const commissionPerNumber =
                    e && e.target && e.target.value ? e.target.value : 0;
                  const fixed =
                    commissionPerNumber &&
                    commissionPerNumber.replace(/,/g, "");
                  const editValue = parseFloat(fixed);
                  if (editValue > 100) {
                    setcommission(rentPerYear);
                    setCommissionPer(100);
                    onChange({
                      rentPerSq,
                      rentPerYear,
                      rentPerMonth,
                      commission: rentPerYear,
                      commissionPer: 100,
                      deposit,
                      depositPer,
                    });
                  } else {
                    const commissionValue = (editValue / 100) * rentPerYear;
                    setcommission(rentPerYear ? commissionValue : 0);
                    setCommissionPer(editValue);
                    onChange({
                      rentPerSq,
                      rentPerYear,
                      rentPerMonth,
                      commission: commissionValue,
                      commissionPer: editValue || 0,
                      deposit,
                      depositPer,
                    });
                  }
                }}
                preInputText={"%"}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default PriceAndPercentageSection;
