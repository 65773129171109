import React, { useEffect, useRef, useState } from "react";
import { Paper, Box } from "@material-ui/core";
import { useSelector } from "react-redux";

import { importFile } from "../../../Services";
import { CustomButton } from "../CustomButton";
import { DropzoneComponent } from "../DropzoneComponent";
import {
  useSelectedTheme,
  useOutsideClick,
  useTranslate,
} from "../../../Hooks";
import { CloseXIcon, GetHelpIcon } from "../../../assets/icons";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";

// Styles
import useStyles from "./styles";

const ImportPropertyModal = ({ onClose }) => {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { isDarkMode } = useSelector((state) => state.theme);
  const { translate: sharedTranslate } = useTranslate("SharedV2");
  const { setAlertBoxContent } = useVerticalNav();

  const modalRef = useRef(null);
  const iconRef = useRef(null);
  const [uploadedFileId, setUploadedFileId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleFilesAccepted = (files) => {
    setUploadedFiles(files);
  };

  useOutsideClick({
    ref: modalRef,
    handler: () => onClose,
    iconRef,
  });

  const onSubmit = async () => {
    if (uploadedFileId) {
      const response = await importFile({
        fileId: uploadedFileId,
        importProcceseType: 4,
      });

      onClose();
      if (response) {
        setAlertBoxContent({
          display: true,
          variant: "success",
          title: `Property file sent successfully.`,
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      } else {
        setAlertBoxContent({
          display: true,
          variant: "error",
          title: "Property file sending failed",
          description: "Failed to import Property file. Please try again.",
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      }
    }
  };

  return (
    <Box className={styles.modalContainer}>
      <Paper ref={modalRef} className={styles.modalPaper}>
        <Box className={styles.content}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <span className={styles.text}>
              {sharedTranslate("import-Property")}
            </span>

            <CloseXIcon
              onClick={onClose}
              style={{ cursor: "pointer" }}
              width="20"
              height="20"
              fill={palette.foreground.quinary}
            />
          </Box>
        </Box>
        <Box className={styles.typeContainer}>
          <Box className={styles.uploadSection}>
            <Box className={styles.uploadInstructions}>
              <Box className={styles.header}>
                <span> {sharedTranslate("upload-file")}</span>
                <GetHelpIcon
                  width="16"
                  height="16"
                  fill={palette.foreground.quinary}
                  style={{ marginLeft: "2px" }}
                />
              </Box>
              <p>
                {sharedTranslate(
                  "heres-an-example-from-the-excel-file-that-you-can"
                )}{" "}
                <a
                  className={styles.downloadLink}
                  href="/files/PropertyTemplate.xlsx"
                  download={"Property Template"}
                  style={{
                    pointerEvents: "auto",
                    color: palette.button.tertiary_fg,
                  }}
                >
                  {sharedTranslate("download")}
                </a>
              </p>
              <p>
                {" "}
                {sharedTranslate("add-data-to-it-and-then-upload-it-again")}
              </p>
            </Box>

            <DropzoneComponent
              setUploadedFileId={setUploadedFileId}
              accept=".xls,.xlsx,.xlsm"
              title={"xls, xlsx or xlsm"}
              onFilesAccepted={handleFilesAccepted}
            />
          </Box>
        </Box>
        <Box className={styles.actions}>
          <CustomButton
            boxShadow="xs"
            size="md"
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            Cancel
          </CustomButton>
          <CustomButton
            boxShadow="xs"
            size="md"
            onClick={onSubmit}
            variant="contained"
            color="primary"
            disabled={uploadedFiles?.length === 0}
          >
            Submit
          </CustomButton>
        </Box>
      </Paper>
    </Box>
  );
};

export default ImportPropertyModal;
