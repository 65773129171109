
import {
  LeadsManagementView,
  LeadsMergeView,
  LeadsProfileManagementView,
} from '../../Views/Home';

// import { LeadsProfileManagementView } from '../../Views/Home/LeadOwnerLeadsView/LeadsProfileManagementView';
import { LeadsLeadOwner } from '../../Views/Home/LeadsLeadOwnerView/LeadsLeadOwner';



export const LeadOwnerLeadsRoutes = [
  {
    path: '/add',
    name: 'homeLayout.leadsManagementView.leads-management-view',
    component: LeadsManagementView,
    layout: '/home/lead-owner-lead',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:lead-owner-leads',
        isDisabled: false,
        route: '/home/lead-owner-lead/view',
        groupName: 'lead-owner',
      },
      {
        name: 'homeLayout.leadsManagementView.leads-management-view',
        isDisabled: true,
        route: '/home/lead-owner-lead/add',
      },
    ],
  },
  {
    path: '/edit',
    name: 'homeLayout.leadsManagementView.leads-management-view',
    component: LeadsManagementView,
    layout: '/home/lead-owner-lead',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:leads',
        isDisabled: false,
        route: '/home/lead-owner-lead/view',
        groupName: 'lead-owner',
      },
      {
        name: 'homeLayout.leadsManagementView.leads-management-view',
        isDisabled: true,
        route: '/home/lead-owner-lead/edit',
      },
    ],
  },
  {
    path: '/lead-profile-edit',
    name: 'LeadsProfileManagementView:lead-profile-edit',
    component: LeadsProfileManagementView,
    layout: '/home/lead-owner-lead',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:leads',
        isDisabled: false,
        route: '/home/lead-owner-lead/view',
        groupName: 'lead-owner',
      },
      {
        name: 'LeadsProfileManagementView:lead-profile-edit',
        isDisabled: true,
        route: '/home/lead-owner-lead/lead-profile-edit',
      },
    ],
  },
  {
    path: '/view',
    name: 'LeadsView:leads',
    component: LeadsLeadOwner,
    layout: '/home/lead-owner-lead',
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:leads',
        isDisabled: false,
        route: '/home/lead-owner-lead/view',
        groupName: 'lead-owner',
      },
    ],
  },
  {
    path: '/merge',
    name: 'homeLayout.leadsManagementView.leads-management-view',
    component: LeadsMergeView,
    layout: '/home/lead-owner-lead',
    default: false,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [
      {
        name: 'LeadsView:leads',
        isDisabled: false,
        route: '/home/lead-owner-lead/view',
        groupName: 'lead-owner',
      },
      {
        name: 'homeLayout.leadsManagementView.merge-leads',
        isDisabled: true,
        route: '/home/lead-owner-lead/merge',
      },
    ],
  },
];
