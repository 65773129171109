import React, { memo, useCallback, useEffect } from "react";
import "./ProposalFormsContainer.scss";
import "./TableStylesOverride.scss";
import { useProposalFormsContext } from "../Context/ProposalFormsContext";
import { useGetUnitsData } from "../Hooks/useGetUnitsData";
import filledDataMapper from "../Helper/filledDataMapper/index.js";

const ProposalFormsContainer = memo(({ activeStep, activeProperty, resetForm }) => {

  const Component = activeStep?.component;
  const checkIsComponentExist = () => typeof Component === "function" || typeof Component?.type === "function";


  const activeStepKey = activeStep?.key;
  const propertyData = useGetUnitsData(activeStepKey, activeProperty, resetForm);
  const formsSelection = useProposalFormsContext();
  const proposalFormChangeHandler = formsSelection.proposalFormChangeHandler;

  const filledForm = filledDataMapper({ propertyData, formsSelection, activeStepKey });

  const canFillApiData = () => {
    const isDataExist = propertyData[activeStepKey];
    const isNotFilledBefore = !(formsSelection[activeStepKey]?.data);
    return isDataExist && isNotFilledBefore;
  }

  useEffect(() => {
    if (canFillApiData()) proposalFormChangeHandler({ id: "edit", value: filledForm });
  }, [filledForm]);


  const localFormChangeHandler = useCallback((key, value) => {    
    if (key == "edit") proposalFormChangeHandler({
      id: key,
      value: {
        ...formsSelection,
        [activeStepKey]: value,
      },
    });
    else proposalFormChangeHandler({
      id: activeStepKey,
      value: {
        ...(formsSelection[activeStepKey] || {}),
        [key]: value,
      },
    });
  }, [activeStepKey, formsSelection]);


  return (
    <div className="ProposalFormContainer">
      {checkIsComponentExist() ? (
        <Component key={`dynamicComponentRef-${activeStepKey}`}
          localFormChangeHandler={localFormChangeHandler}
          localFormSelection={formsSelection[activeStepKey]}
        />
      ) : null}
    </div>
  );
});

export default ProposalFormsContainer;
