import React, { useEffect, useState } from 'react';
import { DialogComponent, Spinner } from '../../../../Components';
import { CopyToClipboardComponents } from '../../../../ReusableComponents/UtilityComponents';
import { ButtonBase } from '@material-ui/core';
import { ChevronDown, ChevronUp } from '../../../../assets/icons';
import { GetReassignLeadPoolWithDetails } from '../../../../Services';
import { showError } from '../../../../Helper';
import { useTranslation } from 'react-i18next';

export default function LeadAssignmentStatusDialog({
  isOpen,
  onClose,
  jobId,
  parentTranslationPath,
}) {
  const { t } = useTranslation(parentTranslationPath);
  const [leadPoolData, setLeadPoolData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const failedLeadIds =
    leadPoolData?.reassignJobRecords
      ?.filter((item) => item.isFailed)
      ?.map((item) => item.leadId) || [];
  const [showMore, setShowMore] = useState(false);
  const displayedIds = showMore ? failedLeadIds : failedLeadIds.slice(0, 9);

  const successCount =
    leadPoolData?.totalCount - leadPoolData?.failedCount ?? 0;

  const getReassignLeadPoolWithDetails = async () => {
    setIsLoading(true);
    try {
      const res = await GetReassignLeadPoolWithDetails(jobId);
      setLeadPoolData(res);
    } catch {
      showError('Error fetching lead pool details');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getReassignLeadPoolWithDetails();
  }, []);

  return (
    <>
      <Spinner isActive={isLoading} />
      <DialogComponent
        titleText={'lead-assignment-status'}
        isOpen={isOpen}
        hideSaveBtn
        maxWidth='md'
        onCancelClicked={onClose}
        onCloseClicked={onClose}
        contentClasses='Lead-assignment-status-wrapper'
        parentTranslationPath={parentTranslationPath}
        dialogContent={
          <>
            <span className='Lead-assignment-status-title'>
              {t('leadsAssigned', {
                successCount,
                totalCount: leadPoolData?.totalCount,
                agentName: leadPoolData?.agentName ?? '',
                userName: leadPoolData?.userName ?? '',
              })}
            </span>
            <div className='Lead-assignment-status-content'>
              <span className='Lead-assignment-status-sub-title'>
                {t('leadsAlreadyAssigned', {
                  failedCount: leadPoolData?.failedCount ?? 0,
                })}
              </span>
              <div className='Lead-assignment-status-ids'>
                {displayedIds.map((id, index) => (
                  <CopyToClipboardComponents
                    key={index}
                    data={id}
                    childrenData={id}
                    CustomizationClassName={`copyToClipBoard blueText`}
                  />
                ))}
              </div>
              {failedLeadIds?.length > 9 && (
                <ButtonBase
                  className='Lead-assignment-status-showMore'
                  onClick={() => setShowMore((prev) => !prev)}
                >
                  {showMore ? (
                    <>
                      <span>{t('show-less')}</span>
                      <ChevronUp
                        width='20'
                        height='20'
                        fill='#3B72D9'
                      />
                    </>
                  ) : (
                    <>
                      <span>{t('view-more')}</span>
                      <ChevronDown
                        width='20'
                        height='20'
                        fill='#3B72D9'
                      />
                    </>
                  )}
                </ButtonBase>
              )}
            </div>
          </>
        }
      />
    </>
  );
}
