import React, { useEffect, useState, useCallback, useReducer } from "react";
import { useTranslation } from "react-i18next";
import { ButtonBase } from "@material-ui/core";
import Joi from "joi";
import { Spinner, DialogComponent } from "../../../../Components";
import { useSuccessDialog } from "../../../../SharedComponents";
import { showError, showSuccess } from "../../../../Helper";
import { ContactClassificationsEnum } from "../../../../Enums";
import {
  DeveloperTypes,
  AddAlreadyExisting,
  AddNewDeveloper,
  ContactComponent,
} from "../Dialogs";
import {
  CreateDeveloperWithContactAPI,
  QuickAddDeveloperAPI,
} from "../../../../Services";

const parentTranslationPath = "Developers";
const translationPath = "";

export const AddDeveloperComponent = ({ isOpen, onClose, onSave }) => {
  const { t } = useTranslation(parentTranslationPath);
  const { showDialog } = useSuccessDialog();

  const [isLoading, setIsLoading] = useState({
    allContacts: false,
    saveDeveloper: false,
    language: false,
    country: false,
    city: false,
    checkDuplicationLandlineNumber: false,
  });
  const [defualts, setDefualts] = useState({
    defualtState: {
      developerName: null, // contact Id
      landLineNumber: null,
      languageId: null,
      cityId: null,
      countryId: null,
    },
    defualtSelect: {
      developerName: null,
      landLineNumber: null,
      language: null,
      city: null,
      country: null,
    },
    defualtNewDeveloperState: {
      email: null,
      developerName: null,
      landlineNumber: null,
      languageId: null,
      countryId: null,
      cityId: null,
      multiPropertyOwner: true,
      promoBy_SMS_Email: true,
      contactClassification: ContactClassificationsEnum.Developer.key,
    },
    defualtNewDeveloperSelect: {
      developerName: null,
      landLineNumber: null,
      language: null,
      city: null,
      country: null,
    },
    defualtHelperText: {
      landlineNumber: "",
      developerName: "",
      language: "",
      country: "",
      city: "",
    },
  });

  const [helperText, setHelperText] = useState({
    ...defualts.defualtHelperText,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectDeveloperType, setSelectDeveloperType] = useState("");
  const reducer = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };
  const reducer1 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };
  const reducer2 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };
  const reducer3 = (select, action) => {
    if (action.id !== "edit") return { ...select, [action.id]: action.value };
    if (action.id === "edit") {
      return {
        ...action.value,
      };
    }
  };
  const [state, setState] = useReducer(reducer, { ...defualts.defualtState });

  const [selected, setSelected] = useReducer(reducer1, {
    developerName: null,
    landLineNumber: null,
    language: null,
    city: null,
    country: null,
  });
  const [newDeveloperWithContactState, setNewDeveloperWithContactState] =
    useReducer(reducer2, { ...defualts.defualtNewDeveloperState });

  const [selectedNewDeveloperWithContact, setSelectedNewDeveloperWithContact] =
    useReducer(reducer3, { ...defualts.defualtNewDeveloperSelect });

  const schema = Joi.object({
    developerName: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}developer-name-is-required`),
        "number.empty": t(`${translationPath}developer-name-is-required`),
        "number.required": t(`${translationPath}developer-name-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const schemaNewDeveloper = Joi.object({
    developerName: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}developer-name-is-required`),
        "string.empty": t(`${translationPath}developer-name-is-required`),
        "string.required": t(`${translationPath}developer-name-is-required`),
      }),
    landlineNumber: Joi.string()
      .required()
      .messages({
        "string.base": t(`${translationPath}landlineNumber-is-required`),
        "string.empty": t(`${translationPath}landlineNumber-is-required`),
        "string.required": t(`${translationPath}landlineNumber-is-required`),
      }),
    languageId: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}language-name-is-required`),
        "number.empty": t(`${translationPath}language-name-is-required`),
        "number.required": t(`${translationPath}language-name-is-required`),
      }),
    countryId: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}country-is-required`),
        "number.empty": t(`${translationPath}country-is-required`),
        "number.required": t(`${translationPath}country-is-required`),
      }),
    cityId: Joi.number()
      .required()
      .messages({
        "number.base": t(`${translationPath}city-is-required`),
        "number.empty": t(`${translationPath}city-name-is-required`),
        "number.required": t(`${translationPath}city-name-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(newDeveloperWithContactState);

  const saveHandler = useCallback(async () => {
    if (selectDeveloperType === "new" && schemaNewDeveloper.error) {
      showError(t(`${translationPath}fill-all-required-fields`));
      return;
    } else if (selectDeveloperType === "existing" && schema.error) {
      showError(t(`${translationPath}fill-all-required-fields`));
      return;
    }
    if (Object.values(helperText).some((s) => s !== "")) {
      showError(t(`${translationPath}fill-fields-with-correct-values`));
      return;
    }
    setIsLoading((item) => ({ ...item, saveDeveloper: true }));
    const result =
      selectDeveloperType === "new"
        ? await CreateDeveloperWithContactAPI({
            ...newDeveloperWithContactState,
          })
        : await QuickAddDeveloperAPI({
            ...state,
          });

    setIsLoading((item) => ({ ...item, saveDeveloper: false }));

    if (
      !(result && result.status && result.status == 200) &&
      !result?.Message
    ) {
      showSuccess(t(`${translationPath}add-developer-succssfuly`));
      showDialog(
        t("GlobalSuccessDialog:add-developer-successfully"),
        {
          name: result?.developerName,
          id: result?.developerId,
          type: "developer",
        },
        true
      );
      onSave(result);
    } else if (result && result.Message) {
      const messageOne =
        (result.Message &&
          result.Message &&
          result.Message.includes(
            "Same Contact Info Is Duplicated With Contact"
          ) &&
          "same-contact-info-is-duplicated-with-contact") ||
        null;
      const messageTwo =
        (result.Message &&
          result.Message &&
          result.Message.includes(
            " ___CONTACT_ALREADY_ASSOCIAITED_WITH_OTHER_DEVELOPER___"
          ) &&
          "CONTACT_ALREADY_ASSOCIAITED_WITH_OTHER_DEVELOPER") ||
        null;

      showError(
        (messageOne && t(`${translationPath}${messageOne}`)) ||
          (messageTwo && t(`${translationPath}${messageTwo}`)) ||
          t(`${translationPath}add-developer-faild`)
      );
    } else showError(t(`${translationPath}add-developer-faild`));
  }, [
    state,
    selectDeveloperType,
    newDeveloperWithContactState,
    schemaNewDeveloper,
    schema,
  ]);

  const clearData = async () => {
    setHelperText({ ...defualts.defualtHelperText });
    setState({ ...defualts.defualtState });
    setSelected({ ...defualts.defualtSelect });
    setSelectedNewDeveloperWithContact({
      ...defualts.defualtNewDeveloperSelect,
    });
    setNewDeveloperWithContactState({ ...defualts.defualtNewDeveloperState });
  };

  useEffect(() => {
    if (!isOpen) {
      clearData();
    }
  }, [isOpen]);

  return (
    <>
      <DialogComponent
        saveText={t(`${translationPath}save`)}
        disableBackdropClick
        maxWidth="sm"
        titleText={t(`${translationPath}add-developer`)}
        saveClasses={"btns theme-solid bg-primary"}
        saveType="button"
        dialogContent={
          <div className="w-100  d-flex-column-center">
            <Spinner isActive={isLoading.saveDeveloper} isAbsolute />
            <div className="w-100 d-flex-column">
              <div className="developer-dialog-wrapper">
                <div className="dialog-content-wrapper">
                  {selectDeveloperType === "existing" &&
                    selected?.developerName && (
                      <div className="w-35">
                        <AddAlreadyExisting
                          translationPath={translationPath}
                          parentTranslationPath={parentTranslationPath}
                          selected={selected}
                        />
                      </div>
                    )}
                  <div
                    className={
                      (selectDeveloperType === "existing" && "w-65 px-2") ||
                      "w-100"
                    }
                  >
                    <DeveloperTypes
                      parentTranslationPath={parentTranslationPath}
                      translationPath={translationPath}
                      selectDeveloperType={selectDeveloperType}
                      setSelectDeveloperType={setSelectDeveloperType}
                      clearData={clearData}
                    />
                    {selectDeveloperType === "existing" && (
                      <div className="px-3 d-flex-column-center mb-2 mt-4">
                        <ContactComponent
                          translationPath={translationPath}
                          parentTranslationPath={parentTranslationPath}
                          selected={selected}
                          state={state}
                          setSelected={setSelected}
                          setState={setState}
                        />
                      </div>
                    )}
                    {selectDeveloperType === "new" && (
                      <div className="d-flex-column-center w-100 mb-2 mt-2">
                        <AddNewDeveloper
                          translationPath={translationPath}
                          parentTranslationPath={parentTranslationPath}
                          dynamicComponentProps={{
                            selectedNewDeveloperWithContact,
                            newDeveloperWithContactState,
                            setSelectedNewDeveloperWithContact,
                            setNewDeveloperWithContactState,
                            setIsLoading,
                            schema: schemaNewDeveloper,
                            isSubmitted,
                            setIsLoading,
                            isLoading,
                            helperText,
                            setHelperText,
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        isOpen={isOpen}
        saveIsDisabled={
          isLoading.saveDeveloper ||
          (selectDeveloperType === "new" &&
            (schemaNewDeveloper.error ||
              Object.values(helperText).some((h) => h !== ""))) ||
          (selectDeveloperType === "existing" && schema.error) ||
          isLoading.checkDuplicationLandlineNumber ||
          !selectDeveloperType
        }
        onSaveClicked={() => saveHandler()}
        onCancelClicked={onClose}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        onCloseClicked={onClose}
      />
    </>
  );
};
