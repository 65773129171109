import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { AutocompleteComponent  } from '../../../../../../Components';
import { PropertyLockManagementDialog } from "../../../../../../Components/Utilities/PropertyLockManagementDialog";

import {
  getProperties,
  CheckPropertyLockAPI,
} from "../../../../../../Services";

export const PropertyName = ({
  parentTranslationPath,
  translationPath,
  setvalue,
  helperText,
  error,
  isSubmitted,
  labelClasses,
  value,
  isValidUnit
}) => {
  const { t } = useTranslation(parentTranslationPath || '');
  const [isOpenPropertyLockMangaement, setIsOpenPropertyLockMangaement] =
    useState(false);
  const [res, setres] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const searchTimer = useRef(null);
  const PropertiesAPI = useCallback(async (value) => {
    setisLoading(true);
    const results = await getProperties({
      pageIndex: 0,
      pageSize: 100,
      search: value,
    });
    setres(results.result);
    setisLoading(false);
  }, []);
  useEffect(() => {
    PropertiesAPI();
  }, [PropertiesAPI]);
  useEffect(
    () => () => {
      if (searchTimer.current) clearTimeout(searchTimer.current);
    },
    []
  );
  const searchHandler = (e) => {
    const { value } = e.target;
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      PropertiesAPI(value);
    }, 700);
  };

  const CheckPropertyLock = useCallback(async (propertyId) => {
      if(propertyId){
        setisLoading(true);
        const checkPropertyLock = await CheckPropertyLockAPI();
        setisLoading(false );
        return checkPropertyLock ; 
      }
      return false ; 
    }, []);

  return (
    <div>
      <AutocompleteComponent
        idRef="PropertyNameRef"
        labelValue="PropertyName"
        labelClasses={labelClasses}
        selectedValues={value}
        multiple={false}
        data={res || []}
        displayLabel={(option) =>
          `${option?.property?.property_name ?? option.property_name}  ${
            option?.property?.property_plan
              ? `(${option?.property?.property_plan?.lookupItemName})`
              : ""
          }`
        }
        withoutSearchButton
        inputPlaceholder={t(`${translationPath}selectProperty`)}
        isSubmitted={isSubmitted}
        helperText={helperText}
        error={error}
        isWithError
        onInputKeyUp={(e) => searchHandler(e)}
        isLoading={isLoading}
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onChange={async (event, newValue) => {
          if (newValue) {
            const isLock = await CheckPropertyLock(+newValue.propertyId);
            if (isLock) {
              setIsOpenPropertyLockMangaement(true);
              return;
            }
          }
          setvalue(
            newValue && {
              id: +newValue.propertyId,
              name: newValue.property.property_name,
            }
          );
        }}
        isDisabled={isValidUnit}
      />
      {isOpenPropertyLockMangaement && (
        <PropertyLockManagementDialog
          isOpenDialog={isOpenPropertyLockMangaement}
          onClose={() => setIsOpenPropertyLockMangaement(false)}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onCancelClicked={() => {
            setIsOpenPropertyLockMangaement(false);
          }}
          onSave={() => {
            setIsOpenPropertyLockMangaement(false);
          }}
        />
      )}
    </div>
  );
};
PropertyName.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  setvalue: PropTypes.func.isRequired,
  helperText: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  isSubmitted: PropTypes.string.isRequired,
};
