import React from "react";
import clsx from "clsx";
import moment from "moment";
import { Box, Typography, Divider } from "@material-ui/core";
import {
  CustomButton,
  CustomCard,
  PropertyPlanBadges,
  PropertyRating,
} from "../../../../../../../../Components";
import { getDownloadableLink } from "../../../../../../../../Helper";
import { useSelectedTheme, useTranslate } from "../../../../../../../../Hooks";
import LocationIcon from "../../../../../../DuplicatedUnitOverview/Sections/MiddleSectionDuplicated/Icons/LocationIcon";
import {
  BedIcon,
  Calendar,
  Download02,
  IconImageOffLine,
  TagIcon,
  UnitSizeIcon,
} from "../../../../../../../../assets/icons";

// styles
import useStyles from "./styles";

function ProprtyInfoComponent({
  propretyResponseJson,
  propretyResponse,
  fullPropertyMarketing,
}) {
  const styles = useStyles();
  const { translate } = useTranslate("NewPropertiesView");
  const {
    theme: { palette },
  } = useSelectedTheme();

  const downloadDocument = (item) => {
    if (!item) {
      return;
    }
    try {
      const link = document.createElement("a");
      link.setAttribute("download", item.documentName);
      link.href = getDownloadableLink(item.documentId);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  return (
    <CustomCard
      boxShadow="xs"
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.wrapperProprtyInfoCard}
    >
      <Box className={clsx(styles.containerLeftImage ,{[styles.containerEmptyImage]:!propretyResponse[0]?.propertyImages[0]?.fileId})}>
        {propretyResponse[0]?.propertyImages[0]?.fileId ? (
          <img
            src={getDownloadableLink(
              propretyResponse[0]?.propertyImages[0]?.fileId
            )}
            alt="avatar"
            className={styles.previewImage}
          />
        ) : (
          <IconImageOffLine
            width="74"
            height="74"
            fill={palette.foreground.quinary}
          />
        )}
      </Box>
      <Box className={styles.containerInfoProp}>
        <Box className={styles.propertyInfoDetails}>
          <Typography className={styles.mainTitleItem}>
            {translate("Property-info")}
          </Typography>
          <Divider className={styles.dividerPropInfo} />
          <Box className={styles.containerPropInfoDetails}>
            <Box className={styles.containerLeftDetails}>
              <Box className={styles.containerInfoItem}>
                <Typography className={styles.titleItem}>
                  {translate("Property-Type")}
                </Typography>
                <Typography className={styles.titleValue}>
                  {
                    propretyResponseJson?.property?.property_type
                      ?.lookupItemName
                  }
                </Typography>
              </Box>
              <Box className={styles.containerInfoItem}>
                <Typography className={styles.titleItem}>
                  {translate("Location")}
                </Typography>
                <Box className={styles.containerLocationValue}>
                  <LocationIcon
                    width="24"
                    height="24"
                    fill={palette.foreground.primary_fg}
                  />
                  <Typography className={styles.titleValue}>
                    {propretyResponseJson?.property?.community?.lookupItemName +
                      "," +
                      propretyResponseJson?.property?.city?.lookupItemName +
                      "," +
                      propretyResponseJson?.property?.country?.lookupItemName}
                  </Typography>
                </Box>
              </Box>
              <Box className={styles.containerInfoItem}>
                <Typography className={styles.titleItem}>
                  {translate("Property-plan")}
                </Typography>
                {propretyResponseJson?.property?.property_plan
                  ?.lookupItemName && (
                  <PropertyPlanBadges
                    PropertyPlanKey={
                      propretyResponseJson?.property?.property_plan
                        ?.lookupItemName
                    }
                  />
                )}
              </Box>
              <Box className={styles.containerInfoItem}>
                <Typography className={styles.titleItem}>
                  {translate("Brochure-PDF")}
                </Typography>
                <CustomButton
                  size="sm"
                  variant="text"
                  color="tertiary"
                  style={{ display: "flex", gap: "12px" }}
                  startIcon={
                    <Download02
                      width="20"
                      height="20"
                      fill={palette.utility.brand_700}
                    />
                  }
                  onClick={() => {
                    downloadDocument();
                  }}
                >
                  {translate("Download")}
                </CustomButton>
              </Box>
            </Box>
            <Box className={styles.containerRightDetails}>
              {propretyResponseJson?.property?.master_developer?.contact_image
                ?.uuid && (
                <Box className={styles.containerImageDeveloper}>
                  <img
                    src={getDownloadableLink(
                      propretyResponseJson?.property?.master_developer
                        ?.contact_image?.uuid
                    )}
                    alt="avatar"
                    className={styles.previewImageDeveloper}
                  />
                </Box>
              )}
              <Box className={styles.containerContactInfo}>
                <Typography className={styles.nameDevStyle}>
                  {propretyResponseJson?.property?.master_developer?.name}
                </Typography>
                <Typography className={styles.contactDevStyle}>
                  {propretyResponseJson?.property?.master_developer?.mobile}
                </Typography>
                <Typography
                  className={clsx(
                    styles.contactDevStyle,
                    styles.containerContactDev
                  )}
                >
                  {
                    propretyResponseJson?.property?.master_developer
                      ?.email_address
                  }
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography className={styles.statusProp}>
            {translate("Available")}
          </Typography>
          <Divider className={styles.divider} />
        </Box>
        <Box className={styles.containerAreaInformation}>
          <Box className={styles.areaInfoItem}>
            <TagIcon
              width={40}
              height={40}
              fill={palette.foreground.primary_fg}
            />
            <Typography className={styles.titleItem}>
              {translate("Price-range")}
            </Typography>

            {fullPropertyMarketing[0]?.priceFrom &&
              fullPropertyMarketing[0]?.priceMax && (
                <Typography className={styles.titleValue}>
                  {`${fullPropertyMarketing[0]?.priceFrom}  -  ${fullPropertyMarketing[0]?.priceMax} AED`}
                </Typography>
              )}
          </Box>
          <Box className={styles.areaInfoItem}>
            <Calendar
              width={40}
              height={40}
              fill={palette.foreground.primary_fg}
            />
            <Typography className={styles.titleItem}>
              {translate("Handover-date")}
            </Typography>

            {propretyResponseJson?.property?.handover_date && (
              <Typography className={styles.titleValue}>
                {moment(propretyResponseJson?.property?.handover_date).format(
                  "MMM YYYY"
                )}
              </Typography>
            )}
          </Box>
          <Box>
            <UnitSizeIcon
              width="40"
              height="40"
              fill={palette.foreground.primary_fg}
            />
            <Typography className={styles.titleItem}>
              {translate("Area-range")}
            </Typography>

            {fullPropertyMarketing[0]?.sizeMin &&
              fullPropertyMarketing[0]?.sizeMax && (
                <Typography className={styles.titleValue}>
                  {`${fullPropertyMarketing[0]?.sizeMin}  -  ${fullPropertyMarketing[0]?.sizeMax} Sqft`}
                </Typography>
              )}
          </Box>
          <Box>
            <BedIcon
              width={40}
              height={40}
              fill={palette.foreground.primary_fg}
            />
            <Typography className={styles.titleItem}>
              {translate("Bedrooms")}
            </Typography>

            <Typography className={styles.titleValue}>
              {fullPropertyMarketing[0]?.availableBedrooms}
            </Typography>
          </Box>
        </Box>

        <PropertyRating
          rating={propretyResponse[0]?.propertyRating}
          width="36"
          height="36"
          percentageStyle={styles.percentage}
          textStyle={styles.text}
        />

        <Box>
          <Typography className={styles.statusProp}>
            {translate("More-images")}
          </Typography>
          <Divider className={styles.divider} />
          {propretyResponse[0]?.propertyImages.length > 1 && (
            <Box className={styles.containerImages}>
              {propretyResponse[0]?.propertyImages
                ?.slice(1, 5)
                ?.map((image, index) => (
                  <>
                    <Box
                      className={clsx(
                        styles.containerImageItem,
                        index === 3 &&
                          propretyResponse[0]?.propertyImages.length > 5 &&
                          styles.containerImageLastItem
                      )}
                    >
                      <img
                        src={getDownloadableLink(image.fileId)}
                        alt="avatar"
                        className={styles.previewImage}
                      />
                      {index === 3 &&
                        propretyResponse[0]?.propertyImages.length > 5 && (
                          <Box className={styles.overlay}>
                            +{propretyResponse[0]?.propertyImages.length - 4}
                          </Box>
                        )}
                    </Box>
                  </>
                ))}
            </Box>
          )}
        </Box>
      </Box>
    </CustomCard>
  );
}
export default ProprtyInfoComponent;
