import React, { useState , useEffect} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { getBgProgressColor , returnPropsByPermissions  } from '../../Helper';
import { HistoryTabsComponent } from '../HistoryComponent/HistoryTabsComponent';
import { PopoverComponent } from '../PopoverComponent/PopoverComponent';
import {
  LeadsSalesPermissions,
  LeadsLeasePermissions,
  LeadsCAllCenterPermissions,
  LeadsMortgagesPermissions,
  LeadOwnerLeadsPermissions,
} from "../../Permissions"; 

export const CompletedDataComponent = ({
  completedData,
  activeItem,
  formType,
  typeId,
  operationType,
  isSaleLead,
  isPropertyManagementView,
  leadsPoolSource,
  isPrimarySale,
  showSaleType,
  updatePrimarySale,
}) => {
  const { t } = useTranslation('Shared');
  const pathName = window.location.pathname
    .split("/home/")[1]
    .split("/view")[0]
    .split("/")[0];
  const [showSaleTypeWithPermissions , setShowSaleTypeWithPermissions] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const showLeadsPoolSource =
    window.location.pathname.includes('/lead-profile-edit');
  const clickHandler = (e) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(e.currentTarget);
    }
  };
  useEffect(() => {
    if (pathName === "lead-sales") {
      setShowSaleTypeWithPermissions(
        returnPropsByPermissions(
          LeadsSalesPermissions.UpdateSaleType.permissionsId
        )
      );
    } else if (pathName === "lead-lease") {
      setShowSaleTypeWithPermissions(
        returnPropsByPermissions(
          LeadsLeasePermissions.UpdateSaleType.permissionsId
        )
      );
    } else if (pathName === "leads") {
      setShowSaleTypeWithPermissions(
        returnPropsByPermissions(
          LeadsCAllCenterPermissions.UpdateSaleType.permissionsId
        )
      );
    } else if (pathName === "mortgage-leads") {
      setShowSaleTypeWithPermissions(
        returnPropsByPermissions(
          LeadsMortgagesPermissions.UpdateSaleType.permissionsId
        )
      );
    } else if (pathName === "lead-owner-lead") {
      setShowSaleTypeWithPermissions(
        returnPropsByPermissions(
          LeadOwnerLeadsPermissions.UpdateSaleType.permissionsId
        )
      );
    }
  }, [pathName]);
  
  return (
    <>
      <div className='completed-data-component-wrapper'>
        <span className='completed-history-wrapper'>
          {showSaleType  &&  (
            <ButtonBase
              className='btns theme-propx outlined px-3'
              onClick={clickHandler}
              disabled={!isSaleLead || !showSaleTypeWithPermissions}
            >
              <strong>{t('sale_type')}:</strong>
              <span>{t(isPrimarySale ? 'primary' : 'resale')}</span>
            </ButtonBase>
          )}
          {showLeadsPoolSource && (
            <ButtonBase
              onClick={() => {}}
              className='btns theme-propx outlined px-3'
            >
              <span>
                {t('leads-pool-source')}: {t(leadsPoolSource?.toLowerCase())}
              </span>
            </ButtonBase>
          )}

          <ButtonBase
            onClick={() => {
              setIsOpen(true);
            }}
            className='btns c-black-light history-button'
          >
            <span className='mdi mdi-clock-time-four-outline' />
            {t('history')}
          </ButtonBase>
        </span>
        <div
          className={`completed-data-content ${
            getBgProgressColor(completedData || 0).className
          }`}
        >
          <span>{`${(completedData && `${completedData}%`) || 'N/A'}`}</span>
        </div>
      </div>
      <HistoryTabsComponent
        activeItem={activeItem}
        isOpen={isOpen}
        isOpenChanged={() => setIsOpen(false)}
        formType={formType}
        typeId={typeId}
        operationType={operationType}
        isPropertyManagementView={isPropertyManagementView}
      />
      <PopoverComponent
        attachedWith={anchorEl}
        handleClose={() => setAnchorEl(null)}
        component={
          <ButtonBase
            className='px-3 py-2 capitalize'
            onClick={updatePrimarySale}
          >
            <h6>
              {t('change_to', {
                value: t(!isPrimarySale ? 'primary' : 'resale'),
              })}
            </h6>
          </ButtonBase>
        }
      />
    </>
  );
};

CompletedDataComponent.propTypes = {
  completedData: PropTypes.number.isRequired,
  activeItem: PropTypes.objectOf(PropTypes.any),
  formType: PropTypes.string,
  typeId: PropTypes.string,
  operationType: PropTypes.string,
  isPropertyManagementView: PropTypes.bool,
};

CompletedDataComponent.defaultProps = {
  activeItem: undefined,
  formType: undefined,
  typeId: undefined,
  operationType: undefined,
  isPropertyManagementView: false,
};
