import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    wapperPropretySpecification: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "24px",
    },
    containerHeader: {
      display: "flex",
      flexDirection: "row",
      gap: "16",
      justifyContent: "space-between",
    },
    divider: {
      marginTop: "20px",
    },
    containerBodyCard: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
    },
    rowItem: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    containerTopSection: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
      alignItems: "center",
    },
    containerBottomSection: {
      display: "flex",
      flexDirection: "row",
      gap: "4px",
      alignItems: "center",
    },
    titleStyle: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
      color: theme.palette.text.brand_secondary,
    },
    valueStyle: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
      color: theme.palette.text.secondary,
    },
    mainTitle: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "28px",
      color: theme.palette.text.primary,
    },
  };
});
