import { SmartProposalStatus } from './Status';

const SmartProposalRequest = (payload) => {
  return ({ type: SmartProposalStatus.REQUEST, payload })
  
};
const SmartProposalSuccess = (payload) => ({ type: SmartProposalStatus.SUCCESS, payload });
const SmartProposalError = (payload) => ({ type: SmartProposalStatus.ERROR, payload });
const SmartProposalReset = (payload) => ({ type: SmartProposalStatus.RESET, payload });

export const SmartProposalActions = {
  SmartProposalRequest,
  SmartProposalSuccess,
  SmartProposalError,
  SmartProposalReset,
};
