import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as AlreadyExistingIcon } from "../../../../assets/images/defaults/ConstructionMachineCraneLiftSvgrepo.svg";
import { ReactComponent as NewDeveloperIcon } from "../../../../assets/images/defaults/newDeveloper.svg";

export const DeveloperTypes = ({
  parentTranslationPath,
  translationPath,
  selectDeveloperType,
  setSelectDeveloperType,
  clearData,
}) => {
  const { t } = useTranslation(parentTranslationPath);

  return (
    <>
      <div className="w-100 d-flex-center">
        <div
          className={`developer-type  ${
            selectDeveloperType === "new" ? "active" : ""
          }`}
        >
          <div
            className="d-flex-column-center"
            onClick={() => {
              setSelectDeveloperType("new");
              clearData();
            }}
          >
            <span>
              {" "}
              <NewDeveloperIcon />{" "}
            </span>
            <span>{t(`${translationPath}new-developer`)}</span>
          </div>
        </div>
        <div
          className={`developer-type  ${
            selectDeveloperType === "existing" ? "active" : ""
          }`}
          onClick={() => {
            setSelectDeveloperType("existing");
            clearData();
          }}
        >
          <span>
            {" "}
            <AlreadyExistingIcon />{" "}
          </span>
          <span>{t(`${translationPath}already-existing`)}</span>
        </div>
      </div>
    </>
  );
};
