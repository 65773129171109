import React from "react";

function RocketIcon({ fill, ...restProps }) {
  return (
    <svg
      height={"24"}
      width={"24"}
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M21.22 1.026c-.552.034-1.224.106-1.648.177-2.432.409-4.826 1.541-6.752 3.194-.525.451-1.367 1.34-1.819 1.92-.376.483-.422.528-.517.507A18.278 18.278 0 0 0 8.7 6.558c-.984-.085-1.954.021-2.618.287-.918.367-1.617 1.104-2.196 2.315-.506 1.056-.94 2.558-.87 3.009.039.254.239.541.481.69l.197.121 2.444.011 2.445.011 1.208 1.209 1.207 1.209.011 2.443.011 2.443.121.197c.149.242.436.442.69.481.269.042.909-.097 1.787-.386 1.85-.611 2.998-1.448 3.481-2.538.44-.994.495-2.414.159-4.139-.053-.274-.084-.51-.067-.525.016-.014.263-.213.548-.441a15.53 15.53 0 0 0 2.595-2.67c1.665-2.245 2.633-5.132 2.643-7.885.003-.691.002-.702-.105-.884-.268-.455-.597-.553-1.652-.49m-.26 2.025-.061.51c-.333 2.707-1.481 5.035-3.424 6.946-1.049 1.032-2.028 1.731-3.495 2.496-.646.337-1.634.797-1.713.797-.026 0-.504-.457-1.062-1.015-.904-.904-1.011-1.025-.984-1.11.05-.165.544-1.196.791-1.655 1.243-2.301 2.404-3.681 4.091-4.863a11.844 11.844 0 0 1 4.877-1.993c.264-.044.561-.087.66-.095l.25-.022c.038-.004.07-.002.07.004M9.09 8.603c.193.026.35.064.35.083 0 .02-.095.211-.211.425a30.78 30.78 0 0 0-.823 1.699l-.082.19H6.822c-.901 0-1.502-.015-1.502-.037 0-.083.256-.725.424-1.063.45-.906.807-1.192 1.696-1.356.17-.031 1.281.008 1.65.059m6.316 6.367c.081.617.072 1.582-.02 1.941-.139.541-.45.893-1.123 1.269-.247.138-.953.433-1.153.482l-.111.027.011-1.509.01-1.51.42-.182c.231-.101.744-.348 1.14-.551.396-.202.731-.37.746-.373.014-.002.05.181.08.406m-10.117.074c-.116.02-.352.09-.525.157-1.105.426-1.926 1.473-2.544 3.243-.413 1.185-.782 2.892-.702 3.249.087.382.405.699.787.78.393.084 2.015-.262 3.251-.693 2.341-.817 3.447-2.003 3.448-3.7 0-.562-.09-.953-.332-1.44-.136-.273-.24-.411-.531-.7-.495-.493-.955-.751-1.574-.882-.292-.062-.945-.069-1.278-.014m1.091 2.051c.221.103.374.242.51.461.077.123.09.2.09.524 0 .341-.011.399-.108.564-.178.302-.539.602-1.024.85-.411.21-1.558.625-1.903.688l-.156.029.029-.156c.065-.352.438-1.387.661-1.837.323-.652.7-1.066 1.072-1.178.23-.07.619-.044.829.055"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default RocketIcon;
