export const FloorPlanStyle = `

/*CSS GENERETED BY ANIMA PPLUGIN */

:root {
--bg-primary-light : linear-gradient(180deg, #F4F4F4 0%, #EDEDED 100%);
--bg-primary-dark : #272963;
--text-primary-light : #2D3E50;
--text-primary-dark : #ffffff;
}


[data-mode="light"] {
  --bg-primary: var(--bg-primary-light);
  --text-primary: var(--text-primary-light);
}

[data-mode="dark"] {
  --bg-primary: var(--bg-primary-dark);
  --text-primary: var(--text-primary-dark);
}

.FloorPlan_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--bg-primary);
  min-height: 100vh;
}

.title {
  color: var(--text-primary);
  font-size: 72px;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.FloorPlan {
  padding: 120px 120px 174px 120px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.floorPlan_image{
    width: 2100px;
    height: 1315px;
    background-color: gray;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
`;
