import React, { createContext, useContext, useMemo } from 'react';
import { selectedEnvironment } from '../config/config';

const LookupContext = createContext({
  disableAddLookup: false,
  editLookup: false,
});

export const useDisabledLookupContext = () => useContext(LookupContext);

const DisabledLookupContextProvider = ({ children }) => {
  const disabled = useMemo(
    () => ['dubai', 'assets', 'dubaiCRMConfig'].includes(selectedEnvironment),
    []
  );
  const editLookup = useMemo(
    () => ['development'].includes(selectedEnvironment),
    []
  );
  return (
    <LookupContext.Provider value={{ disableAddLookup: disabled, editLookup }}>
      {children}
    </LookupContext.Provider>
  );
};

export default DisabledLookupContextProvider;
