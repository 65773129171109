import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    containerFeaturOverView: {
      width: "100%",
      padding: "24px",
      gap: "16px",
      display: "flex",
      flexDirection: "column",
    },
    mainTitle: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "28px",
      color: theme.palette.text.primary,
    },
    containerHeader: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
    containerRow: {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
      alignItems: "center",
      width: "100%",
    },
    containerBody: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    containerItems: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      gap: "16px",
    },
    containerLastItems: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      justifyContent: "flex-end",
    },
    containerShowMoreButn: {
      display: "flex",
      alignItems: "flex-end",
    },
    titleItem: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
      color: theme.palette.text.secondary,
    },
  };
});
