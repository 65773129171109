import React, { createContext, useState, useContext, useCallback } from "react";

// Create the context
const VerticalNavContext = createContext();

// Provider Component
export const VerticalNavProvider = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [hoverExpanded, setHoverExpanded] = useState(false);
  const [isMobileSideNavOpen, setIsMobileSideNavOpen] = useState(false);
  const [mainContainerPadding, setMainContainerPadding] = useState("32px");

  const onTogglePinned = () => {
    setIsExpanded(!isExpanded);
    setHoverExpanded(false); // Ensure hover is disabled when toggling pinned
  };

  const onToggleMobileSideNav = () => {
    setIsMobileSideNavOpen(!isMobileSideNavOpen);
  };

  const memoizedSetMainContainerPadding = useCallback((padding) => {
    setMainContainerPadding(padding);
  }, []);

  const [tablePopoverContent, setTablePopoverContent] = useState(null);

  const [alertBoxContent, setAlertBoxContent] = useState(null);

  const [stickyBottomBox, setStickyBottomBox] = useState(null);

  const [mainLayoutRef, setMainLayoutRef] = useState(null);

  const [addNewContact, setAddNewContact] = useState({
    addContactModalOpened: false,
    contact: {},
    AddNewContactDialogOpened :false,
    shouldOpenAddPage: undefined
  });

  const [actionableItems, setActionableItems] = useState({
    selectedIds: [],
    action: null,
    // contacts => bulk_update, merge, pull
    // leads => unqualified, reassignOwner, reassignSeeker, clone, closeLead
  });

  const [duplicatedContact, setDuplicatedContact] = useState({
    isDuplicate: false,
    matchingContacts: [],
  });
  const [addNewUnit, setAddNewUnit] = useState({
    addUnitModalOpened: false,
    unit: {}
  });
  const [duplicatedUnit, setDuplicatedUnit] = useState({
    isDuplicate: false,
    matchingUnits: [],
    selectedDuplicatedUnit: {},
    isOpenDuplicatedUnitsDialog: false,
    isOpenSucsessValidateDialog: false,
  });

  const [addNewProprety, setAddNewProprety] = useState({
    addPropretyModalOpened: false,
    Proprety: {},
  });

  const [duplicatedProprety, setDuplicatedProprety] = useState({
    isExactMatch: false,
    isFuzzyMatch: false,
    matchingProperties: [],
    fuzzyMatchingProperties: [],
    PropretyMatchingPropertiesDialogOpened: false,
    PropretyFuzzyMatchingPropertiesDialogOpened: false,
    PropretySucsessValidateDialog: false,
  });


  // Context value that will be supplied to any consuming component
  const contextValue = {
    tablePopoverContent,
    setTablePopoverContent,
    isExpanded,
    setIsExpanded,
    hoverExpanded,
    setHoverExpanded,
    onTogglePinned,
    sideNavWidth: {
      expanded: "312px",
      collapsed: "80px",
    },
    navNotCollapsed: isExpanded || hoverExpanded,
    isMobileSideNavOpen,
    onToggleMobileSideNav,
    mainContainerPadding,
    setMainContainerPadding: memoizedSetMainContainerPadding,
    alertBoxContent,
    setAlertBoxContent,
    // PS: Sticky bottom box for render the pagination component
    stickyBottomBox,
    setStickyBottomBox,
    // PS: Main layout ref for scrolling
    mainLayoutRef,
    setMainLayoutRef,
    addNewContact,
    setAddNewContact,
    duplicatedContact,
    setDuplicatedContact,
    addNewUnit,
    setAddNewUnit,
    duplicatedUnit,
    setDuplicatedUnit,
    addNewProprety,
    setAddNewProprety,
    duplicatedProprety,
    setDuplicatedProprety,
    actionableItems,
    setActionableItems,
  };

  return (
    <VerticalNavContext.Provider value={contextValue}>
      {children}
    </VerticalNavContext.Provider>
  );
};

// Custom hook to use the context
export const useVerticalNav = () => useContext(VerticalNavContext);
