import React from 'react';
import { DialogComponent } from '../../../../Components';
import { showError, showSuccess } from '../../../../Helper';
import { DeleteExternalLink } from '../../../../Services';
import { useTranslation } from 'react-i18next';

export const DeleteDialog = ({
  onCancelClicked,
  onCloseClicked,
  reloadData,
  isDeleteDialogOpen,
  setIsDeleteDialogOpen,
  translationPath,
  parentTranslationPath,
  activeItem,
  setActiveItem
}) => {
  const { t } = useTranslation(parentTranslationPath);

  const deleteHandler = async () => {
    const externalLinkId  = activeItem?.externalLinkId ;
    const res = await DeleteExternalLink(externalLinkId);
    if (!(res && res.status && res.status !== 200)) {
      reloadData();
      showSuccess(t(`${translationPath}external-link-deleted-successfully`));
      setActiveItem(null)
    } else showError(t(`${translationPath}external-link-delete-failed`));
    setIsDeleteDialogOpen(false);
  };

  return (
    <DialogComponent
      titleText='confirm-message'
      saveText='confirm'
      saveType='button'
      maxWidth='sm'
      dialogContent={
        <div className='d-flex-column-center'>
          <span className='mdi mdi-close-octagon c-danger mdi-48px' />
          <span>{`${t(`${translationPath}external-link-delete-description`)} "${activeItem?.externalLinkName}"?`}</span>
        </div>
      }
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={isDeleteDialogOpen}
      onCancelClicked={onCancelClicked}
      onCloseClicked={onCloseClicked}
      onSaveClicked={deleteHandler}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
