import { formatDisplayDate } from "../formatDate";

const formatContactDetails = (proposalContactDetails) => {
    const isAgentDetails = !!proposalContactDetails?.agentContactDetails;
    const agentDetails = proposalContactDetails?.agentContactDetails;
    const companyDetails = proposalContactDetails?.companyContactDetails;

  return {
      name: isAgentDetails
          ? `${agentDetails?.firstName} ${agentDetails?.lastName}`
          : companyDetails?.companyName,
      phone: isAgentDetails
          ? agentDetails?.mobile
          : companyDetails?.companyMobileNumber,
      whatsApp: isAgentDetails
          ? agentDetails?.whatsApp
          : companyDetails?.companyWhatsAppNumber,
      email: isAgentDetails
          ? agentDetails?.email
          : companyDetails?.companyEmail,
      jobTitle: isAgentDetails
          ? agentDetails?.jobTitle
          : null,
      avatarImage: isAgentDetails
          ? agentDetails?.profileImage
          : companyDetails?.psiLogo,
      initials: isAgentDetails
          ? `${agentDetails?.firstName?.[0]?.toUpperCase()}${agentDetails?.lastName?.[0]?.toUpperCase()}`
          : companyDetails?.companyName?.[0]?.toUpperCase(),
  };
};

const formatCoverPage = (proposalContactDetails, propertyPublishedImages) => {
  return {
      ...(proposalContactDetails || {}),
      propertyPublishedImages: propertyPublishedImages || null,
      contactDetailsType: proposalContactDetails?.contactDetailsType || null,
      proposalDate:  formatDisplayDate(proposalContactDetails?.proposalDate, "D MMM YYYY"),
      completionDate: formatDisplayDate(proposalContactDetails?.completionDate, "MMM YYYY"),
      contactDetails: formatContactDetails(proposalContactDetails),
  };
};

export default formatCoverPage;