import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    PaymentPlanSharedComponent: {
      width: "100%",
      padding: "24px",
      gap: "16px",
      display: "flex",
      flexDirection: "column",
    },
    mainTitle: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "28px",
      color: theme.palette.text.primary,
    },
    containerHeader: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
    rowLayout: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      backgroundColor: theme.palette.background.secondary_hover,
      borderRadius: "12px",
      padding: "5px 20px",
    },
    containerBody: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    containerLeftSide: {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
    },
    titleItem: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "24px",
      color: theme.palette.text.secondary,
    },
  };
});
