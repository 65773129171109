import React from "react";
import { useTranslation } from "react-i18next";
import { SwitchComponent } from "../../../../../Components";

const PropertyDescriptionForm = ({ localFormChangeHandler, localFormSelection }) => {
  const parentTranslationPath = "SmartProposal";
  const translationPath = "PropertyDescriptionForm.";
  const { t } = useTranslation(parentTranslationPath);


  return (
    <>
      <div className="ProposalForm__field_box">
        <div className="ProposalForm__field_label">
          <p>Property description section</p>
        </div>
        <div>
          <SwitchComponent
            idRef="descriptionSectionRef"
            themeClass="thick-theme"
            isChecked={localFormSelection?.showGeneralFacts || false}
            labelValue={`${localFormSelection?.showGeneralFacts ? "show" : "hide"}`}
            parentTranslationPath={parentTranslationPath}
            translationPath={""}
            onChangeHandler={(_, isChecked) =>
              localFormChangeHandler("edit", {
                ...(localFormSelection || {}),
                showGeneralFacts: isChecked,
                showPage: isChecked || localFormSelection.showProjectFacts,
              })
            }
          />
        </div>
      </div>
      <div className="ProposalForm__field_box">
        <div className="ProposalForm__field_label">
          <p>Project facts section</p>
        </div>
        <div>
          <SwitchComponent
            idRef="ProjectFactsRef"
            themeClass="thick-theme"
            isChecked={localFormSelection?.showProjectFacts || false}
            labelValue={`${localFormSelection?.showProjectFacts ? "show" : "hide"}`}
            parentTranslationPath={parentTranslationPath}
            translationPath={""}
            onChangeHandler={(_, isChecked) =>
              localFormChangeHandler("edit", {
                ...(localFormSelection || {}),
                showProjectFacts: isChecked,
                showPage: localFormSelection.showGeneralFacts || isChecked,
              })}
          />
        </div>
      </div>
    </>
  );
};

export default PropertyDescriptionForm;
