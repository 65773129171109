import { CoverPageTemplate } from "./CoverPage/template";
import { UnitsDetailsTemplate } from "./UnitsDetails/template";
import { CoverPageStyle } from "./CoverPage/styles";
import { UnitsDetailsStyle } from "./UnitsDetails/styles";
import { PropertyDescriptionStyle } from "./PropertyDescription/styles";
import { PropertyDescriptionTemplate } from "./PropertyDescription/template";
import { UnitImagesTemplate } from "./UnitImages/template";
import { UnitImagesStyle } from "./UnitImages/styles";
import { LocationAndamenitiesStyle } from "./Location&Amenities/styles";
import { LocationAndamenitiesTemplate } from "./Location&Amenities/template";
import { MultiUnitsCardsTemplate } from "./MultiUnitsCards/template";
import { MultiUnitsCardsStyle } from "./MultiUnitsCards/styles";
import { MultiUnitsTableStyle } from "./MultiUnitsTable/styles";
import { MultiUnitsTableTemplate } from "./MultiUnitsTable/template";
import { PaymentPlansTemplate } from "./PaymentPlans/template";
import { PaymentPlansStyle } from "./PaymentPlans/styles";
import { FloorPlanTemplate } from "./FloorPlan/template";
import { FloorPlanStyle } from "./FloorPlan/styles";

export const premiumTemplate = `
<!DOCTYPE html>
<html>
    <head>
        <meta charset="utf-8" />
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Cormorant:wght@300..700&family=DM+Sans:wght@100..1000&display=swap" rel="stylesheet">        <style>
            
       /* ============= Global CSS ============= */

            * {
                -webkit-font-smoothing: antialiased;
                box-sizing: border-box;
            }
            html, body, p {
                margin: 0;
                height: 100%;
                font-family: "Inter", serif;
            }
            body::-webkit-scrollbar {
               width: 0px;
            }
            button:focus-visible {
                outline: 2px solid #4a90e2 !important;
                outline: -webkit-focus-ring-color auto 5px !important;
            }
            a {
                text-decoration: none;
            }
              
              :root {
                --x-3b-7-2d-9: rgba(59, 114, 217, 1);
                --x-000000: rgba(0, 0, 0, 1);
                --ffffff: rgba(255, 255, 255, 1);
                --x-215-3b-1: rgba(33, 83, 177, 1);
                --c-7c-7cc: rgba(199, 199, 204, 1);
                --x-8e-8e-93: rgba(142, 142, 147, 1);
                --db-50-4a: rgba(219, 80, 74, 1);
                --f-0f-0f-0: rgba(240, 240, 240, 1);
                --e-0e-0e-0: rgba(224, 224, 224, 1);
                --a-6a-6a-6: rgba(166, 166, 166, 1);
                --e-5e-5ea: rgba(229, 229, 234, 1);
                --x-2d-3e-50: rgba(45, 62, 80, 1);
                --header-2-font-family: "DM Sans", Helvetica;
                --header-2-font-weight: 400;
                --header-2-font-size: 48px;
                --header-2-letter-spacing: 0px;
                --header-2-line-height: normal;
                --header-2-font-style: normal;
                --text-lg-semibold-font-family: "Inter", Helvetica;
                --text-lg-semibold-font-weight: 600;
                --text-lg-semibold-font-size: 18px;
                --text-lg-semibold-letter-spacing: 0px;
                --text-lg-semibold-line-height: 28px;
                --text-lg-semibold-font-style: normal;
                --text-md-regular-font-family: "Inter", Helvetica;
                --text-md-regular-font-weight: 400;
                --text-md-regular-font-size: 16px;
                --text-md-regular-letter-spacing: 0px;
                --text-md-regular-line-height: 24px;
                --text-md-regular-font-style: normal;
                --collection-1-primary-color: rgba(39, 41, 99, 1);
                --collection-1-gray: rgba(241, 242, 242, 1);
                --collection-1-white: rgba(255, 255, 255, 1);
                --typescale-label-small-size: 11px;
                --typescale-label-small-line-height: 16px;
                --typescale-label-small-tracking: 0.5px;
                --m3-schemes-on-error: rgba(255, 255, 255, 1);
                --m3-schemes-on-surface: rgba(29, 27, 32, 1);
                --1-color-modes-component-colors-components-avatars-avatar-contrast-border: rgba(
                  0,
                  0,
                  0,
                  0.08
                );
                --3-spacing-spacing-md: var(--primitives-spacing-2-8px);
                --3-spacing-spacing-xl: var(--primitives-spacing-4-16px);
                --3-spacing-spacing-2xl: var(--primitives-spacing-5-20px);
                --3-spacing-spacing-6xl: var(--primitives-spacing-12-48px);
                --primitives-spacing-2-8px: 8px;
                --primitives-spacing-4-16px: 16px;
                --primitives-spacing-5-20px: 20px;
                --primitives-spacing-12-48px: 48px;
              }
              

            /* ============= Template Specific CSS ============= */
            {{#CoverPage}} ${CoverPageStyle} {{/CoverPage}}
            {{#UnitsDetails}} ${UnitsDetailsStyle} {{/UnitsDetails}}
            {{#PropertyDescription}} ${PropertyDescriptionStyle} {{/PropertyDescription}}
            {{#UnitImages}} ${UnitImagesStyle} {{/UnitImages}}
            {{#FloorPlan}} ${FloorPlanStyle} {{/FloorPlan}}
            {{#LocationAndamenities}} ${LocationAndamenitiesStyle} {{/LocationAndamenities}}
            {{#PaymentPlan}} ${PaymentPlansStyle} {{/PaymentPlan}}
            {{#MultiUnitsDetails.showTableView}} ${MultiUnitsTableStyle} {{/MultiUnitsDetails.showTableView}}
            {{#MultiUnitsDetails.showCardsView}} ${MultiUnitsCardsStyle} {{/MultiUnitsDetails.showCardsView}}
        </style>
    </head>
    <body data-mode="{{theme}}">
        {{#CoverPage}} ${CoverPageTemplate} {{/CoverPage}}
        {{#UnitsDetails}} ${UnitsDetailsTemplate} {{/UnitsDetails}}
        {{#PropertyDescription}} ${PropertyDescriptionTemplate} {{/PropertyDescription}}
        {{#UnitImages}} ${UnitImagesTemplate} {{/UnitImages}}
        {{#FloorPlan}} ${FloorPlanTemplate} {{/FloorPlan}}
        {{#LocationAndamenities}} ${LocationAndamenitiesTemplate} {{/LocationAndamenities}}
        {{#PaymentPlan}} ${PaymentPlansTemplate} {{/PaymentPlan}}
        {{#MultiUnitsDetails.showTableView}} ${MultiUnitsTableTemplate} {{/MultiUnitsDetails.showTableView}}
        {{#MultiUnitsDetails.showCardsView}} ${MultiUnitsCardsTemplate} {{/MultiUnitsDetails.showCardsView}}
    </body>
</html>
`;
