export const PaymentPlansTemplate = `
<div class="PaymentPlanTable_Container">
  {{#PaymentPlan.showPage}}
  {{#PaymentPlan.checkedPaymentPlans}}
    <div class="PaymentPlanTable">
      <p class="title">{{paymentPlanName}}</p>
      <table>
        <tr>
          <th>Payment Breakdown</th>
          <th>Installment Schedule</th>
          <th>Milestone</th>
          <th>Percentage</th>
        </tr>
        {{#propertyPlanInstallments}}
          <tr>
            <td>{{paymentBreakdown}}</td>
            <td>{{installmentSchedule}}</td>
            <td>{{installmentTypeName}}</td>
            <td>{{amountPercentage}}</td>
          </tr>
        {{/propertyPlanInstallments}}
        <tfoot>
        <tr>
          <td colspan="3">Total</td>
          <td>100%</td>
        </tr>
        </tfoot>
      </table>
    </div>
  {{/PaymentPlan.checkedPaymentPlans}}
  {{/PaymentPlan.showPage}}
</div>
`;
