import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    modeWrapper: {
      marginTop: "24px",
      marginBottom: "24px",
      display: "flex",
      justifyContent: "flex-end",
    },
    gridContainer: {
      display: "flex",
      flex: "1 1 100%",
      flexWrap: "wrap",
      gap: "18px",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
      },
    },
    fieldWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
    label: {
      fontSize: "14px",
      lineHeight: "20px",
      color: theme.palette.text.secondary,
      fontWeight: 600,
      minWidth: "160px",
    },
    divider: {
      backgroundColor: theme.palette.border.secondary,
    },
    fieldItem: {
      display: "flex",
      gap: "32px",
    },
    inputField: {
      borderRadius: "8px",
      background: theme.palette.background.paper,
      width: "100%",
  
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        border: `1px solid ${theme.palette.border.primary}`,
      },
  
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        border: `1px solid ${theme.palette.border.primary}`,
      },
  
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        boxShadow:
          "0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(140, 115, 75, 0.24)", // selected value
        border: `1px solid ${theme.palette.border.brand}`,
      },
    },
  };
});
