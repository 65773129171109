import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Breadcrumb } from "../../Components";
import {
  BottomBoxView,
  Header,
  MainMenuView,
  NewReleaseNotification,
  OpenCloseView,
  GlobalSuccessDialog,
} from "../../Views/Home";
import { SwitchRoute } from "../../Components/Route/SwitchRoute";
import { HomeRoutes } from "../../routes/HomeRoutes/HomeRoutes";
import {
  setLoading,
  setSideMenuIsOpenCallback,
  sideMenuIsOpenUpdate,
} from "../../Helper/Middleware.Helper";
import { config } from "../../config";
import { SuccessDialogProvider } from "../../SharedComponents";
import ContextProvider from "../../Contexts/ContextProvider";


const OldHomeLayout = () => {
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);
  setSideMenuIsOpenCallback(setSideMenuIsOpen);
  const [, setShowLoading] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(70);
  const [NewReleaseNotificationState, setNewReleaseNotificationState] =
    useState(false);

  const changeShowLoading = (flag) => {
    setShowLoading(flag);
  };

  setLoading(changeShowLoading);
  if (config.DisableRightClicking)
    document.addEventListener("contextmenu", (event) => event.preventDefault());

  // useEffect(() => {
  //   try {
  //     const storedSession = localStorage.getItem("session");
  //     if (storedSession) {
  //       const versionFrom = JSON.parse(storedSession).versionNo;
  //       if (versionFrom && versionFrom !== config.version)
  //         setNewReleaseNotificationState(true);
  //     } else console.warn("'session' not found in localStorage");
  //   } catch (error) {
  //     console.error("Error  parsing 'session' from localStorage:", error);
  //   }
  // }, []);






  return (
    <SuccessDialogProvider>
      <ContextProvider>
        <GlobalSuccessDialog />
        <Header headerHeightChanged={(hh) => setHeaderHeight(hh)} />
        <div
          className="container"
          style={{ minHeight: `calc(100vh - ${headerHeight}px)` }}
        >
          <MainMenuView isHover={isHover} setIsHover={setIsHover} />
          <div
            className={clsx("content-wrapper", {
              "is-open": isHover,
              "is-open-side-menu": sideMenuIsOpen,
            })}
          >
            <div className="open-close-main-layout">
              <Breadcrumb />
              <SwitchRoute routes={HomeRoutes} />
            </div>
            <BottomBoxView />
          </div>
          <OpenCloseView
            isOpen={sideMenuIsOpen}
            isOpenClicked={() => sideMenuIsOpenUpdate()}
          />
        </div>
        {/* <NewReleaseNotification
            isOpen={NewReleaseNotificationState}
            setNewReleaseNotificationState={setNewReleaseNotificationState}
            isClicked={() => {}}
          /> */}
      </ContextProvider>
    </SuccessDialogProvider>
  );
};

export { OldHomeLayout };
