import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    wapperunitCardItem: {
      width: "305.5px",
      display: "flex",
      flexDirection: "column",
    },
    containerImage: {
      width: "305.5px",
      height: "240px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    containerBodyCard: {
      width: "305.5px",
      height: "240px",
    },
    imageWrapper: {
      height: "100%",
      width: "100%",
    },
    containerBodyCard: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      padding: "20px 16px",
    },
    containerBottomSection: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    containerTopSection: {
      display: "flex",
      flexDirection: "column",
    },
    containerUnitPrice: {
      display: "flex",
      justifyContent: "space-between",
    },
    containerPriceTitle: {
      display: "flex",
      gap: "8px",
      flexDirection: "row",
      alignItems: "center",
    },
    containerPrice: {
      fontSize: "20px",
      fontWeight: 600,
      lineHeight: "30px",
      color: theme.palette.text.brand_tertiary,
    },
    containerType: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      color: theme.palette.utility.brand_300,
    },
    titleUnit: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "28px",
      color: theme.palette.text.primary,
    },
    containerUnitTypeAndCopy: {
      display: "flex",
      flexDirection: "row",
      gap: "10px",
    },
    unitTypeTitle: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "20px",
      color: theme.palette.text.brand_secondary,
    },
    containerAddressInfo: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      color: theme.palette.text.primary,
    },
    forSaleLable: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      color: theme.palette.text.primary,
    },
    containerInfoSection: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
    },
    detailsUnits: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
    },
    containerUnitItem: {
      display: "flex",
      flexDirection: "row",
      gap: "4px",
    },
    UnitItemDetails: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      color: theme.palette.text.tertiary,
    },
  };
});
