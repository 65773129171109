import React from "react";
import clsx from "clsx";
import { Box, Table } from "@material-ui/core";

import { useSelectedTheme, useTranslate } from "../../../Hooks";

import { DeleteIcon } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

const AddAdditionalTable = ({
  initValue,
  others,
  setNewValue,
  columnName = "email",
}) => {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { translate } = useTranslate("HeaderView");

  const handleDelete = (index) => {
    setNewValue((prev) => ({
      ...prev,
      others: (prev?.others || [])?.filter((_, i) => i !== index),
    }));
  };

  return (
    <Table className={styles.containerTableReminder}>
      <thead>
        <tr className={styles.containerTableHeader}>
          <th
            className={clsx(
              styles.containerTitleHeader,
              styles.typeTitleReminderStyles
            )}
            style={{ width: "82%" }}
          >
            {translate(columnName)}
          </th>
          <th
            className={clsx(
              styles.containerTitleHeader,
              styles.typeTitleReminderStyles
            )}
            style={{ width: "18%" }}
          >
            {translate("Action")}
          </th>
        </tr>
      </thead>

      <tbody className={styles.containerTableBody}>
        {initValue && (
          <tr className={styles.containerTableRow}>
            <td className={clsx(styles.forValue)}>{initValue}</td>
          </tr>
        )}
        {others?.map((item, index) => (
          <tr key={index} className={styles.containerTableRow}>
            <td className={clsx(styles.forValue)}>{item}</td>

            {/* Delete action */}
            <td className={styles.containerActionButton}>
              <Box className={styles.containerIconActionButton}>
                <DeleteIcon
                  width="20"
                  height="20"
                  fill={palette.button.tertiaryGray_fg}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDelete(index)}
                />
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default AddAdditionalTable;
