import { formatDisplayDate } from "../formatDate.js";
import { getPublicDownloadableLink } from "../../../../../Helper/Middleware.Helper.jsx";

const formatProjectDescriptionData = (data) => ({
    projectDescriptionData: {
        ...(data || {}),
        brochureFile: data?.brochureFile? getPublicDownloadableLink(data?.brochureFile) : null,
        projectDetails: {
        ...(data?.projectDetails || {}),
         handover: formatDisplayDate(data?.projectDetails?.handover, "D MMM YYYY"),
         completionDate: formatDisplayDate(data?.projectDetails?.completionDate, "D MMM YYYY"),
        }
    }
});

export default formatProjectDescriptionData;