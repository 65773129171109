import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";

import {
  GetAdvanceSearchContacts,
  PropertiesAdvanceSearchTest,
  GetAllUserSearchCriteria,
} from "../Services";
import { PropertiesMapper } from "../Views/Home/PropertiesView/PropertiesUtilities/PropertiesMapper";
import { ContactsMapper } from "../Views/Home/ContactsView/ContactsUtilities";
import { SearchCriteriaEnum } from "../Enums";

// Create the context
const NewLayoutSharedContext = createContext();

// Provider Component
export const NewLayoutSharedProvider = ({ children }) => {
  const [advancedSearchBody, setAdvancedSearchBody] = useState({
    criteria: {},
    filterBy: "createdOn",
    orderBy: 2,
    map: null,
    LeadClasses: [],
    contactClasses: [],
  });

  const [isAddTaskDialogOpen, setIsAddTaskDialogOpen] = useState(false);
  const [addActivity, setAddActivity] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 25,
  });

  const isContactPage = window.location.pathname.includes("Contacts-CRM");
  const [savedFiltersCriteria, setSavedFiltersCriteria] = useState({
    isOpen: false,
    selectedValue: undefined,
    selectedId: undefined,
    data: [],
    filterDataToAdd: {},
    type: isContactPage ? "contact" : "property",
  });
  const [isCriteriaLoading, setIsCriteriaLoading] = useState([]);
  const [filterModalData, setFilterModalData] = useState({
    isOpen: false,
    selectedValue: undefined,
    selectedId: undefined,
    data: [],
    selectedColumns: [],
  });

  const getSearchCriteria = useCallback(async () => {
    setIsCriteriaLoading(true);

    try {
      const res = await GetAllUserSearchCriteria(
        !isContactPage
          ? SearchCriteriaEnum.Property.typeId
          : SearchCriteriaEnum.Contact.typeId
      );

      if (!(res && res.status && res.status !== 200)) {
        setSavedFiltersCriteria((prevData) => ({
          ...prevData,
          data: res,
        }));
      } else {
        setSavedFiltersCriteria((prevData) => ({
          ...prevData,
          data: [],
        }));
      }
    } catch (error) {
      console.error("Failed to fetch search criteria:", error);
    } finally {
      setIsCriteriaLoading(false);
    }
  }, []);

  useEffect(() => {
    getSearchCriteria();
  }, []);

  return (
    <NewLayoutSharedContext.Provider
      value={{
        advancedSearchBody,
        setAdvancedSearchBody,
        pagination,
        setPagination,
        savedFiltersCriteria,
        setSavedFiltersCriteria,
        isCriteriaLoading,
        filterModalData,
        setFilterModalData,
        isAddTaskDialogOpen,
        setIsAddTaskDialogOpen,
        addActivity,
        setAddActivity,
      }}
    >
      {children}
    </NewLayoutSharedContext.Provider>
  );
};

// Custom hook to use the context
export const useNewLayoutShared = () => useContext(NewLayoutSharedContext);
