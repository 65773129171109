import React, { useEffect, useState } from "react";
import { Box, Button, ButtonGroup } from "@material-ui/core";
import { Mail01, Bell01, MessageChatSquare } from "../../../../../assets/icons";
import { useSelectedTheme } from "../../../../../Hooks";
import clsx from "clsx";

// styles
import useStyles from "./styles";

const ReminderTypeOptionsComponent = ({
  data,
  value,
  valueInput,
  onSelectChanged,
}) => {
  const [selected, setSelected] = useState(value || null);
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const getIconMap = (value, itemKey) => {
    const iconMap = {
      email: (
        <Mail01
          width="20"
          height="20"
          fill={getIconColor(itemKey)}
          className={styles.iconItem}
        />
      ),
      sms: (
        <MessageChatSquare
          className={styles.iconItem}
          width="20"
          height="20"
          fill={getIconColor(itemKey)}
        />
      ),
      notification: (
        <Bell01
          className={styles.iconItem}
          width="20"
          height="20"
          fill={getIconColor(itemKey)}
        />
      ),
    };
    return iconMap[value] || null;
  };

  const getIconColor = (itemKey) => {
    return selected === itemKey
      ? palette.text.secondary_hover
      : palette.text.secondary;
  };

  useEffect(() => {
      setSelected(value)
  }, [value]);

  return (
    <ButtonGroup className={styles.wapperReminderTypeOptions}>
      {data &&
        data.length > 0 &&
        data.map((item, index) => (
          <Button
            key={item.key}
            className={clsx(styles.containerReminderOptionsItem, {
              [styles.isActiveBg]: selected === item.key,
            })}
            onClick={() => {
              setSelected(item.key);
              onSelectChanged(item.key);
            }}
          >
            <Box className={styles.containerReminderOptionDeatails}>
              <Box className={styles.iconWrapper}>
                {getIconMap(item?.value?.toLowerCase(), item?.key)}
              </Box>
              <Box
                className={clsx(styles.text, {
                  [styles.activeTitle]: selected === item.key,
                })}
              >
                {item.value}
              </Box>
            </Box>
          </Button>
        ))}
    </ButtonGroup>
  );
};
export default ReminderTypeOptionsComponent;
