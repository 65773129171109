import { GenerateProposal } from "../../../../Services/SmartProposal";
import dtoMapper from "./dtoMapper";

const generateProposal = async (params) => {
  const body = dtoMapper(params);
  try {
    const response = await GenerateProposal(body);
    if (response && response.status && response.status !== 200) {
      return false;
    }    
    return true;
  } catch (error) {
    return false;
  }
};

export default generateProposal;
