const paymentPlansDtoMapper = (property) => {
  const paymentPlans = property.submittedForm?.PaymentPlan?.checkedPaymentPlans || [];

  const mappedPaymentPlans = paymentPlans.map((item) => ({
    paymentPlanName: item?.paymentPlanName,
    paymentPlanData: item?.propertyPlanInstallments || [],
  }));
  
  return mappedPaymentPlans;
};

export default paymentPlansDtoMapper;
