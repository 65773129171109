import React, { useState, useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonBase } from '@material-ui/core';
import { useTitle } from '../../../../../Hooks';
import {
  bottomBoxComponentUpdate,
  GetParams,
  GlobalHistory,
  showError,
  showSuccess,
} from '../../../../../Helper';
import {
  LandmarkMainInformation,
  LandmarkAttachments,
} from '../LandmarkUtilities';
import { LocationsPermissions } from '../../../../../Permissions';
import { PermissionsComponent, Spinner } from '../../../../../Components';
import {
  GetLandmarkById,
  UpdateLandmark,
  CreateLandmark,
} from '../../../../../Services';
import { StaticLookupsIds } from '../../../../../assets/json/StaticLookupsIds';
import SchoolDetailsForm from '../LandmarkUtilities/SchoolDetailsForm/SchoolDetailsForm';
import { SchoolDetailsDialogView } from './SchoolDetailsDialogView/SchoolDetailsDialogView';

const parentTranslationPath = 'LocationView';
const translationPath = '';
export const LandmarkManagementView = () => {
  const { t } = useTranslation(parentTranslationPath);
  const isEditView = window.location.pathname.includes('edit');

  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [schoolDialogIsOpen, setSchoolDialogIsOpen] = useState(false);
  const [selected, setSelected] = useReducer(reducer, {
    landmarkEnglishName: '',
    landmarkArabicName: '',
    arDescription: '',
    enDescription: '',
    category: null,
    latitude: 25.178495,
    longitude: 55.545002,
    addressLine1English: '',
    addressLine1Arabic: '',
    country: {
      lookupItemId: StaticLookupsIds.UAECities,
      lookupItemName: 'United Arab Emirates',
    },
    city: null,
    district: null,
    community: null,
    subCommunity: null,
    website: '',
    youtubeLink: '',
    usefulLink: '',
    virtualTourLink: '',
    isActive: true,
    files: [],
    schoolNationality: null,
    studentPercentage: '',
    studentCount: 0,
    studentRate: '',
    facebookLink: '',
    instagramLink: '',
    isFeatured: false,
    logImage: [],
  });

  const getMappedPayload = () => {

    if (selected?.category === null) {
      throw t('field_is_required', { field: t('category'), ns: 'Agents' });
    }

    const {
      landmarkEnglishName,
      landmarkArabicName,
      arDescription,
      enDescription,
      latitude,
      longitude,
      addressLine1English,
      addressLine1Arabic,
      website,
      youtubeLink,
      usefulLink,
      virtualTourLink,
      isActive,
      studentPercentage,
      studentCount = 0,
      studentRate,
      facebookLink,
      instagramLink,
      isFeatured,
      files,
      logImage,
      category,
      country,
      city,
      district,
      community,
      subCommunity,
      schoolNationality,
    } = selected || {};

    const body = {
      landmarkEnglishName,
      landmarkArabicName,
      arDescription,
      enDescription,
      latitude,
      longitude,
      addressLine1English,
      addressLine1Arabic,
      website,
      youtubeLink,
      usefulLink,
      virtualTourLink,
      isActive,
      facebookLink,
      instagramLink,
      isFeatured,
      categoryId: category?.lookupItemId,
      countryId: country?.lookupItemId,
      cityId: city?.lookupItemId,
      districtId: district?.lookupItemId,
      communityId: community?.lookupItemId,
      subCommunityId: subCommunity?.lookupItemId,
      landmarkSchoolDetails: [
        {
          schoolNationalityId: schoolNationality?.lookupItemId,
          studentPercentage,
          studentCount,
          studentRate,
        },
      ],
      landmarkImages: (files || []).map((item) => ({
        isLogo: false,
        fileId: item.uuid,
        urlName: item.fileName,
      })).concat(
        (logImage || []).map((item) => ({
          isLogo: true,
          fileId: item.uuid,
          urlName: item.fileName,
        }))
      ),
    };

    return body;
  };


  const getMappedSelected = (data) => {
    const {
      landmarkEnglishName,
      landmarkArabicName,
      arDescription,
      enDescription,
      latitude,
      longitude,
      addressLine1English,
      addressLine1Arabic,
      website,
      youtubeLink,
      usefulLink,
      virtualTourLink,
      isActive,
      facebookLink,
      instagramLink,
      isFeatured,
    } = data;

    const mappedSelected = {
      landmarkEnglishName,
      landmarkArabicName,
      arDescription,
      enDescription,
      latitude,
      longitude,
      addressLine1English,
      addressLine1Arabic,
      website,
      youtubeLink,
      usefulLink,
      virtualTourLink,
      facebookLink,
      instagramLink,
      isFeatured,
      isActive,
      category: {
        lookupItemId: data.categoryId,
        lookupItemName: data.categoryName,
      },
      country: {
        lookupItemId: data.countryId,
        lookupItemName: data.countryName,
      },
      city: {
        lookupItemId: data.cityId,
        lookupItemName: data.cityName,
      },
      district: {
        lookupItemId: data.districtId,
        lookupItemName: data.districtName,
      },
      community: {
        lookupItemId: data.communityId,
        lookupItemName: data.communityName,
      },
      subCommunity: {
        lookupItemId: data.subCommunityId,
        lookupItemName: data.subCommunityName,
      },
      schoolNationality: {
        lookupItemId:
          data.landmarkSchoolDetails?.length &&
          data.landmarkSchoolDetails[0].schoolNationalityId,
        lookupItemName:
          data.landmarkSchoolDetails?.length &&
          data.landmarkSchoolDetails[0].schoolNationalityName,
      },
      studentPercentage:
        data.landmarkSchoolDetails?.length &&
        data.landmarkSchoolDetails[0].studentPercentage,
      studentCount:
        data.landmarkSchoolDetails?.length &&
        data.landmarkSchoolDetails[0].studentCount,
      studentRate:
        data.landmarkSchoolDetails?.length &&
        data.landmarkSchoolDetails[0].studentRate,
      files: data.landmarkImages
        ? data.landmarkImages
          .filter((f) => !f.isLogo)
          .map((item) => ({
            uuid: item.fileId,
            fileName: item.urlName,
          }))
        : [],
      logImage: data.landmarkImages
        ? data.landmarkImages
          .filter((f) => f.isLogo)
          .map((item) => ({
            isLogo: true,
            uuid: item.fileId,
            fileName: item.urlName,
          }))
        : [],
    };

    setSelected({ id: 'edit', value: mappedSelected });
  };

  const getLandmarkById = async () => {
    setIsLoading(true);
    const landmarkId = +GetParams('id');
    const res = await GetLandmarkById(landmarkId);
    if (!(res && res.status && res.status !== 200)) {
      getMappedSelected(res);
    }
    setIsLoading(false);
  };

  const createLandmark = async () => {
    try {
      setIsLoading(true);
      const body = getMappedPayload();
      const res = await CreateLandmark(body);
      if (!(res && res.status && res.status !== 200)) {
        showSuccess(t`${translationPath}Create-successfully`);
        GlobalHistory.push('/home/Landmarks/view');
      } else showError(t`${translationPath}Create-failed`);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateLandmark = async () => {
    try {
      setIsLoading(true);
      const landmarkId = +GetParams('id');
      const body = getMappedPayload();
      const res = await UpdateLandmark({ body, landmarkId });
      if (!(res && res.status && res.status !== 200)) {
        showSuccess(t`${translationPath}Update-successfully`);
        GlobalHistory.push('/home/Landmarks/view');
      } else showError(t`${translationPath}Update-failed`);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveHandler = () => {
    if (!(selected.landmarkArabicName && selected.landmarkEnglishName)) return;
    if (isEditView) updateLandmark();
    else createLandmark();
  };

  useEffect(() => {
    if (isEditView) getLandmarkById();
  }, []);

  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='d-flex-v-center-h-end flex-wrap '>
        <ButtonBase
          className='btns theme-transparent mb-2'
          onClick={() => {
            GlobalHistory.push('/home/Landmarks/view');
          }}
        >
          <span>{t('Shared:cancel')}</span>
        </ButtonBase>
        <PermissionsComponent
          permissionsList={Object.values(LocationsPermissions)}
          permissionsId={LocationsPermissions.EditLocationDetails.permissionsId}
        >
          <ButtonBase
            className='btns theme-solid mb-2'
            onClick={() => saveHandler()}
          >
            <span>{t('Shared:save')}</span>
          </ButtonBase>
        </PermissionsComponent>
      </div>
    );
  });
  return (
    <div className='Location-Sharing-wraper'>
      <Spinner isActive={isLoading} />
      <LandmarkMainInformation
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
        selected={selected}
        setSelected={setSelected}
        isEditView={isEditView}
      />
      <div>
        <LandmarkAttachments
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
          selected={selected}
          setSelected={setSelected}
          isEditView={isEditView}
        />
        {selected.category?.lookupItemName === 'School' && (
          <div className='lamdmarks-form1 lamdmarks-form2'>
            <div className='Active-btt-menu mt-2'>
              <ButtonBase
                className={`menu-button`}
                onClick={() => {
                  setSchoolDialogIsOpen(true);
                }}
              >
                {t(`${translationPath}show-school-details`)}
              </ButtonBase>
            </div>
          </div>
        )}

        {schoolDialogIsOpen && (
          <SchoolDetailsDialogView
            open={schoolDialogIsOpen}
            close={() => {
              setSchoolDialogIsOpen(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
