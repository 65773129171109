import React, { useCallback, useEffect, useState } from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import {
  CustomBadge,
  CustomButton,
  CustomCard,
} from "../../../../../../../../Components";
import {
  useQuery,
  useSelectedTheme,
  useTranslate,
} from "../../../../../../../../Hooks";
import { GetPropertySpecificationByPropertyId } from "../../../../../../../../Services";

// styles
import useStyles from "./styles";

// icons
import { UnitSizeIcon } from "../../../../../../../../assets/icons";

function PropertySpecificationComponent({ setTabValue }) {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { translate } = useTranslate("NewPropertiesView");
  const query = useQuery();
  const [mapArrayFiled, setMapArrayFiled] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const defaultState = {
    propertyId: query.get("id"),
    numberOfApartment: 0,
    minApartmentArea: 0,
    maxApartmentArea: 0,
    numberOfApartmentStudio: 0,
    numberOfApartmentPenthouse: 0,
    numberOfApartmentOneBedroom: 0,
    numberOfApartmentTowBedroom: 0,
    numberOfApartmentThreeBedroom: 0,
    numberOfApartmentFourBedroom: 0,
    numberOfApartmentFiveBedroom: 0,
    numberOfApartmentBedroomMoreThanFive: 0,
    numberOfVilla: 0,
    minVillaArea: 0,
    maxVillaArea: 0,
    numberOfVillaStudio: 0,
    numberOfVillaPenthouse: 0,
    numberOfVillaOneBedroom: 0,
    numberOfVillaTowBedroom: 0,
    numberOfVillaThreeBedroom: 0,
    numberOfVillaFourBedroom: 0,
    numberOfVillaFiveBedroom: 0,
    numberOfVillaBedroomMoreThanFive: 0,
    retailUnitGrossArea: 0,
    minRetailUnitArea: 0,
    maxRetailUnitArea: 0,
    officeGrossArea: 0,
    minOfficeArea: 0,
    maxOfficeArea: 0,
    laborCampGrossArea: 0,
    minLaborCampArea: 0,
    maxLaborCampArea: 0,
    wareHouseGrossArea: 0,
    minWareHouseArea: 0,
    maxWareHouseArea: 0,
    numberOfRetail: 0,
    numberOfOffices: 0,
    numberOfPentHouse: 0,
    minPentHouseArea: 0,
    maxPentHouseArea: 0,
    minStudioArea: 0,
    maxStudioArea: 0,
    numberOfStudio: 0,
    minTownHouseArea: 0,
    maxTownHouseArea: 0,
    numberOfTownHouse: 0,
    numberOfStudioOneBedroom: 0,
    numberOfStudioTowBedroom: 0,
    numberOfStudioThreeBedroom: 0,
    numberOfStudioFourBedroom: 0,
    numberOfStudioFiveBedroom: 0,
    numberOfStudioBedroomMoreThanFive: 0,
    numberOfTownHouseOneBedroom: 0,
    numberOfTownHouseTowBedroom: 0,
    numberOfTownHouseThreeBedroom: 0,
    numberOfTownHouseFourBedroom: 0,
    numberOfTownHouseFiveBedroom: 0,
    numberOfTownHouseBedroomMoreThanFive: 0,
    numberOfPentHouseOneBedroom: 0,
    numberOfPentHouseTowBedroom: 0,
    numberOfPentHouseThreeBedroom: 0,
    numberOfPentHouseFourBedroom: 0,
    numberOfPentHouseFiveBedroom: 0,
    numberOfPentHouseBedroomMoreThanFive: 0,
  };
  const [propertypeScificationData, setPropertypeScificationData] =
    useState(defaultState);

  const GetSpecificationById = useCallback(async () => {
    setIsLoading(true);
    const result = await GetPropertySpecificationByPropertyId(query.get("id"));
    if (!(result && result.status && result.status !== 200)) {
      setPropertypeScificationData({ ...result, propertyId: query.get("id") });
    } else {
      setPropertypeScificationData(defaultState);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    GetSpecificationById();
  }, [GetSpecificationById]);

  const mapFiled = () => {
    setMapArrayFiled([
      {
        title: translate("Apartment"),
        minValue: propertypeScificationData?.minApartmentArea || 0,
        maxValue: propertypeScificationData?.maxApartmentArea || 0,
        number: propertypeScificationData?.numberOfApartment || 0,
        area: 0,
      },
      {
        title: translate("Villa"),
        minValue: propertypeScificationData?.minVillaArea || 0,
        maxValue: propertypeScificationData?.maxVillaArea || 0,
        number: propertypeScificationData?.numberOfApartment || 0,
        area: 0,
      },
      {
        title: translate("Retail-Unit"),
        minValue: propertypeScificationData?.minRetailUnitArea || 0,
        maxValue: propertypeScificationData?.maxRetailUnitArea || 0,
        number: propertypeScificationData?.numberOfRetail || 0,
        area: propertypeScificationData?.retailUnitGrossArea || 0,
      },
      {
        title: translate("Office"),
        minValue: propertypeScificationData?.minOfficeArea || 0,
        maxValue: propertypeScificationData?.maxOfficeArea || 0,
        number: propertypeScificationData?.numberOfOffices || 0,
        area: propertypeScificationData?.officeGrossArea || 0,
      },
      {
        title: translate("Labor-Camp"),
        minValue: propertypeScificationData?.minLaborCampArea || 0,
        maxValue: propertypeScificationData?.maxLaborCampArea || 0,
        number: 0,
        area: propertypeScificationData?.laborCampGrossArea || 0,
      },
      {
        title: translate("Warehouse"),
        minValue: propertypeScificationData?.minWareHouseArea || 0,
        maxValue: propertypeScificationData?.maxWareHouseArea || 0,
        number: propertypeScificationData?.wareHouseGrossArea || 0,
        area: 0,
      },
      {
        title: translate("Studio"),
        minValue: propertypeScificationData?.minStudioArea || 0,
        maxValue: propertypeScificationData?.maxStudioArea || 0,
        number: propertypeScificationData?.numberOfStudio || 0,
        area: 0,
      },
      {
        title: translate("Townhouse"),
        minValue: propertypeScificationData?.minTownHouseArea || 0,
        maxValue: propertypeScificationData?.maxTownHouseArea || 0,
        number: propertypeScificationData?.numberOfTownHouse || 0,
        area: 0,
      },
      {
        title: translate("Penthouse"),
        minValue: propertypeScificationData?.minPentHouseArea || 0,
        maxValue: propertypeScificationData?.maxPentHouseArea || 0,
        number: propertypeScificationData?.numberOfPentHouse || 0,
        area: 0,
      },
    ]);
  };
  useEffect(() => {
    mapFiled();
  }, [propertypeScificationData]);

  return (
    <CustomCard
      borderRadius="xl"
      boxShadow="xs"
      borderColor="secondary"
      classes={styles.wapperPropretySpecification}
    >
      <Box>
        <Box className={styles.containerHeader}>
          <Typography className={styles.mainTitle}>
            {translate("Property-specification")}
          </Typography>
          <CustomButton
            boxShadow="none"
            size="md"
            onClick={() => {
              setTabValue(5);
            }}
            variant="text"
            color="tertiaryGray"
          >
            {translate("View-all")}
          </CustomButton>
        </Box>
        <Divider className={styles.divider} />
      </Box>
      <Box className={styles.containerBodyCard}>
        {mapArrayFiled &&
          mapArrayFiled?.map((item, index) => (
            <Box className={styles.rowItem} key={index}>
              <Box className={styles.containerTopSection}>
                <Typography className={styles.titleStyle}>
                  {item?.title}
                </Typography>
                <CustomBadge
                  label={`No .${item?.number}`}
                  SizeVariant={"small"}
                  BackgroundColor={palette.utility["brand_50"]}
                  BorderColor={palette.utility["brand_200"]}
                  Color={palette.utility["brand_700"]}
                />
              </Box>
              <Box className={styles.containerBottomSection}>
                <UnitSizeIcon
                  width="20"
                  height="20"
                  fill={palette.utility.utility_gray_700}
                />
                <Typography className={styles.valueStyle}>
                  {`${item?.area} ft ${item?.minValue} - ${item?.maxValue} sq.ft`}
                </Typography>
              </Box>
            </Box>
          ))}
      </Box>
    </CustomCard>
  );
}
export default PropertySpecificationComponent;
