import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    wrapperPropretyTimeLine: {
      width: "100%",
      padding: "24px",
      display: "flex",
      flexDirection: "column",
      gap: "24px",
    },
    divider: {
      marginTop: "24px",
    },
    containerDate: {
      display: "flex",
      flexDirection: "row",
      gap: "12px",
    },
    containerData: {
      display: "flex",
      flexDirection: "column",
      padding: "4px 0px 24px 0px",
    },
    titleItem: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "20px",
      color: theme.palette.text.gray_700,
    },
    valueItem: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      color: theme.palette.text.tertiary,
    },
    currentValue: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      color: theme.palette.text.primary,
    },
    currentTitle: {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "20px",
      color: theme.palette.text.brand_tertiary,
    },
    containerIcon: {
      border: `1px solid ${theme.palette.border.secondary}`,
      borderRadius: "12px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "12px",
    },
    currentDateIcon: {
      border: `1px solid ${theme.palette.utility.brand_300}`,
      borderRadius: "12px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "12px",
    },
    timelineConnector: {
      backgroundColor: theme.palette.background.gray_200,
      height: "12px",
      width: "2px",
      margin: "auto",
      borderRadius: "6px",
    },
    currentTimeLine: {
      backgroundColor: theme.palette.background.brand_solid,
      height: "12px",
      width: "2px",
      margin: "auto",
      borderRadius: "6px",
    },
    containerIconAndVerticalLine: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      paddingBottom: "4px",
    },
    ItemTitleHeader: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "28px",
      color: theme.palette.text.primary,
    },
  };
});
