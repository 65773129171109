export const MultiUnitsTableStyle = `

/*CSS GENERETED BY ANIMA PPLUGIN */

:root {
--bg-page-light : linear-gradient(180deg, #F4F4F4 0%, #EDEDED 100%);
--bg-page-dark : #272963;
--bg-table-light : #ffffff;
--bg-table-dark : #161B26;
--bg-row-light : rgba(240, 240, 240, 1);
--bg-row-dark : #161B26;
--border-clr-btn-light : #272963;
--border-clr-btn-dark : transparent;
--text-primary-light : #2D3E50;
--text-primary-dark : #F0F0F0;
--text-secondery-light : #2D3E50;
--text-secondery-dark : #A6A6A6;

}


[data-mode="light"] {
  --bg-page: var(--bg-page-light);
  --bg-table: var(--bg-table-light);
  --bg-row: var(--bg-row-light);
  --border-clr-btn: var(--border-clr-btn-light);
  --text-primary: var(--text-primary-light);
  --text-secondery: var(--text-secondery-light);
}

[data-mode="dark"] {
  --bg-page: var(--bg-page-dark);
  --bg-table: var(--bg-table-dark);
  --bg-row: var(--bg-row-dark);
  --border-clr-btn: var(--border-clr-btn-dark);
  --text-primary: var(--text-primary-dark);
  --text-secondery: var(--text-secondery-dark);
}




.MultiUnitsPage {
display: flex;
padding: 120px 120px 300px 120px;
flex-direction: column;
align-items: center;
flex-shrink: 0;
gap: 50px;
background: var(--bg-page);
min-height: 100vh;
}

.MultiUnitsPage .title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}


.title-text-wrapper {
  position: relative;
  flex: 1;
  margin-top: -1.00px;
  font-family: "Inter", serif;
  font-weight: 700;
  color: var(--text-primary);
  font-size: 72px;
  letter-spacing: 0;
  line-height: normal;
}

.MultiUnitsTable {
  display: flex;
  align-items: flex-start;
  padding: 12px 24px 44px;
  position: relative;
  background: var(--bg-table);
  border-radius: 12px;
  border: 3px solid #E5E5EA;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.MultiUnitsTable .section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.MultiUnitsTable .row {
  align-items: flex-start;
  gap: var(--3-spacing-spacing-4xl);
  display: flex;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.MultiUnitsTable .col {
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  flex: 1;
  flex-grow: 1;
  display: flex;
  position: relative;
}

.MultiUnitsTable .div {
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  background-color: var(--1-color-modes-colors-background-bg-secondary-subtle);
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: var(--e-5e-5ea);
  display: flex;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.MultiUnitsTable .frame {
  display: flex;
  width: 250px;
  gap: var(--3-spacing-spacing-xl);
  align-items: center;
  position: relative;
}

.MultiUnitsTable .text {
  width: fit-content;
  font-family: "Inter-Bold", Helvetica;
  font-weight: 700;
  font-size: 28.4px;
  text-align: center;
  line-height: 36.5px;
  white-space: nowrap;
  position: relative;
  margin-top: -1.00px;
  color: var(--text-primary);
  letter-spacing: 0;
}

.MultiUnitsTable .text-wrapper {
  display: flex;
  width: 250px;
  align-items: center;
  gap: var(--3-spacing-spacing-xl);
  position: relative;
}

.MultiUnitsTable .text-2 {
  flex: 1;
  font-family: "Inter-Bold", Helvetica;
  font-weight: 700;
  font-size: 28.4px;
  line-height: 36.5px;
  position: relative;
  margin-top: -1.00px;
  color: var(--text-primary);
  letter-spacing: 0;
}

.MultiUnitsTable .buttons-button-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--bg-row);
}

.MultiUnitsTable .buttons-button {
  display: flex;
  width: 76px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: var(--3-spacing-spacing-xl) 22px var(--3-spacing-spacing-xl) 22px;
  position: relative;
  flex: 0 0 auto;
  border-radius: var(--2-radius-radius-md);
  overflow: hidden;
  border: 2px solid;
  border-color: var(--border-clr-btn);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.MultiUnitsTable .img {
  position: relative;
  width: 32px;
  height: 32px;
}

.MultiUnitsTable .row-2 {
  align-items: center;
  padding: 12px 30px;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: var(--bg-row);
  border-radius: 12px;
  display: flex;
  position: relative;
}

.MultiUnitsTable .text-3 {
  width: fit-content;
  font-family: "Inter-SemiBold", Helvetica;
  font-weight: 600;
  font-size: 28px;
  line-height: normal;
  position: relative;
  margin-top: -1.00px;
  color: var(--text-secondery);
  letter-spacing: 0;
}

.MultiUnitsTable .text-4 {
  flex: 1;
  font-family: "Inter-SemiBold", Helvetica;
  font-weight: 600;
  font-size: 28px;
  line-height: normal;
  position: relative;
  margin-top: -1.00px;
  color: var(--text-secondery);
  letter-spacing: 0;
}

.MultiUnitsTable .div-wrapper {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  flex: 0 0 auto;
  align-items: center;
  position: relative;
}

.MultiUnitsTable .buttons-button-2 {
  display: flex;
  width: 76px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: var(--3-spacing-spacing-xl) 22px var(--3-spacing-spacing-xl) 22px;
  position: relative;
  flex: 0 0 auto;
  background-color: #272963;
  border-radius: 18px;
  overflow: hidden;
  border: 1px solid;
    border-color: var(--border-clr-btn);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}


`
