export const PropertyDescriptionTemplate = `
<div class="property-description">
{{#PropertyDescription.showPage}}
  <div class="main">
    <div class="content">
      <div class="frame">
        <div class="div">
          <div class="frame-wrapper">
            <div class="frame-2">
              {{#PropertyDescription.data.projectDescriptionData.projectDetails.completionDate}}
                <div class="text-wrapper">Completion - {{PropertyDescription.data.projectDescriptionData.projectDetails.completionDate}}</div>
              {{/PropertyDescription.data.projectDescriptionData.projectDetails.completionDate}}
              <div class="text-wrapper-2">Property Description</div>
            </div>
          </div>
          {{#PropertyDescription.data.projectDescriptionData.brochureFile}}
            <div class="frame-3">
              <a href="{{{PropertyDescription.data.projectDescriptionData.brochureFile}}}" target="_blank" class="buttons-button">
                <img class="download" src="https://psi-crm.com/ProposalIcons/download-03.svg" alt=""/>
                <div class="text-padding">
                  <div class="text">Download Brochure</div>
                </div>
              </a>
            </div>
          {{/PropertyDescription.data.projectDescriptionData.brochureFile}}
        </div>
      </div>
      <div class="rectangle"></div>
      <div class="frame-4">
        {{#PropertyDescription.showGeneralFacts}}
          <div class="frame-5">
              <div class="text-wrapper-3">Project general facts</div>
              <div class="frame-6">
                <p class="p">{{PropertyDescription.data.projectDescriptionData.projectDetails.propertyOverView}}</p>
              </div>
          </div>
          <div class="frame-5">
            <div class="text-wrapper-5">Project details</div>
            <div class="frame-6">
              <div class="row">
                <div class="col">
                  <div class="frame-7">
                    <img class="arrow-narrow-right" src="https://psi-crm.com/ProposalIcons/arrow-narrow-right.svg" />
                    <div class="frame-8">
                      <div class="text-2">Construction status:</div>
                      <div class="text-3">
                        {{#PropertyDescription.data.projectDescriptionData.projectDetails.constructionStatus}}
                          {{PropertyDescription.data.projectDescriptionData.projectDetails.constructionStatus}}
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.constructionStatus}}
                        {{^PropertyDescription.data.projectDescriptionData.projectDetails.constructionStatus}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.constructionStatus}}
                      </div>
                    </div>
                  </div>
                  <div class="frame-7">
                    <img class="arrow-narrow-right" src="https://psi-crm.com/ProposalIcons/arrow-narrow-right.svg" />
                    <div class="frame-8">
                      <div class="text-2">Completion date:</div>
                      <div class="text-3">
                        {{#PropertyDescription.data.projectDescriptionData.projectDetails.completionDate}}
                          {{PropertyDescription.data.projectDescriptionData.projectDetails.completionDate}}
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.completionDate}}
                        {{^PropertyDescription.data.projectDescriptionData.projectDetails.completionDate}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.completionDate}}
                      </div>
                    </div>
                  </div>
                  <div class="frame-7">
                    <img class="arrow-narrow-right" src="https://psi-crm.com/ProposalIcons/arrow-narrow-right.svg" />
                    <div class="frame-8">
                      <div class="text-2">Ownership type:</div>
                      <div class="text-3">
                        {{#PropertyDescription.data.projectDescriptionData.projectDetails.ownershipType}}
                          {{PropertyDescription.data.projectDescriptionData.projectDetails.ownershipType}}
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.ownershipType}}
                        {{^PropertyDescription.data.projectDescriptionData.projectDetails.ownershipType}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.ownershipType}}
                      </div>
                    </div>
                  </div>
                  <div class="frame-7">
                    <img class="arrow-narrow-right" src="https://psi-crm.com/ProposalIcons/arrow-narrow-right.svg" />
                    <div class="frame-8">
                      <div class="text-2">Master developer:</div>
                      <div class="text-3">
                        {{#PropertyDescription.data.projectDescriptionData.projectDetails.masterDeveloper}}
                          {{PropertyDescription.data.projectDescriptionData.projectDetails.masterDeveloper}}
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.masterDeveloper}}
                        {{^PropertyDescription.data.projectDescriptionData.projectDetails.masterDeveloper}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.masterDeveloper}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="frame-7">
                    <img class="arrow-narrow-right" src="https://psi-crm.com/ProposalIcons/arrow-narrow-right.svg" />
                    <div class="frame-8">
                      <div class="text-2">Developed by:</div>
                      <div class="text-3">
                        {{#PropertyDescription.data.projectDescriptionData.projectDetails.developedBy}}
                          {{PropertyDescription.data.projectDescriptionData.projectDetails.developedBy}}
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.developedBy}}
                        {{^PropertyDescription.data.projectDescriptionData.projectDetails.developedBy}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.developedBy}}
                      </div>
                    </div>
                  </div>
                  <div class="frame-7">
                    <img class="arrow-narrow-right" src="https://psi-crm.com/ProposalIcons/arrow-narrow-right.svg" />
                    <div class="frame-8">
                      <div class="text-2">Usage:</div>
                      <div class="text-3">
                        {{#PropertyDescription.data.projectDescriptionData.projectDetails.usage}}
                          {{PropertyDescription.data.projectDescriptionData.projectDetails.usage}}
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.usage}}
                        {{^PropertyDescription.data.projectDescriptionData.projectDetails.usage}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.usage}}
                      </div>
                    </div>
                  </div>
                  <div class="frame-7">
                    <img class="arrow-narrow-right" src="https://psi-crm.com/ProposalIcons/arrow-narrow-right.svg" />
                    <div class="frame-8">
                      <div class="text-2">Handover:</div>
                      <div class="text-3">
                        {{#PropertyDescription.data.projectDescriptionData.projectDetails.handover}}
                          {{PropertyDescription.data.projectDescriptionData.projectDetails.handover}}
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.handover}}
                        {{^PropertyDescription.data.projectDescriptionData.projectDetails.handover}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.handover}}
                      </div>
                    </div>
                  </div>
                  <div class="frame-7">
                    <img class="arrow-narrow-right" src="https://psi-crm.com/ProposalIcons/arrow-narrow-right.svg" />
                    <div class="frame-8">
                      <div class="text-2">Service charge:</div>
                      <div class="text-3">
                        {{#PropertyDescription.data.projectDescriptionData.projectDetails.serviceCharge}}
                          {{PropertyDescription.data.projectDescriptionData.projectDetails.serviceCharge}}
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.serviceCharge}}
                        {{^PropertyDescription.data.projectDescriptionData.projectDetails.serviceCharge}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectDetails.serviceCharge}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {{/PropertyDescription.showGeneralFacts}}
        {{#PropertyDescription.showProjectFacts}}
          <div class="frame-5">
            <div class="text-wrapper-3">Project facts</div>
            <div class="frame-6">
              <div class="row">
                <div class="col">
                  <div class="frame-7">
                    <img class="arrow-narrow-right" src="https://psi-crm.com/ProposalIcons/arrow-narrow-right.svg" />
                    <div class="frame-8">
                      <div class="text-4">Total no of floors:</div>
                      <div class="text-5">
                        {{#PropertyDescription.data.projectDescriptionData.projectFacts.totalNumberOfFloors}}
                          {{PropertyDescription.data.projectDescriptionData.projectFacts.totalNumberOfFloors}}
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.totalNumberOfFloors}}
                        {{^PropertyDescription.data.projectDescriptionData.projectFacts.totalNumberOfFloors}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.totalNumberOfFloors}}
                      </div>
                    </div>
                  </div>
                  <div class="frame-7">
                    <img class="arrow-narrow-right" src="https://psi-crm.com/ProposalIcons/arrow-narrow-right.svg" />
                    <div class="frame-8">
                      <div class="text-4">No of units:</div>
                      <div class="text-5">
                        {{#PropertyDescription.data.projectDescriptionData.projectFacts.totalNumberOfUnits}}
                          {{PropertyDescription.data.projectDescriptionData.projectFacts.totalNumberOfUnits}}
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.totalNumberOfUnits}}
                        {{^PropertyDescription.data.projectDescriptionData.projectFacts.totalNumberOfUnits}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.totalNumberOfUnits}}
                      </div>
                    </div>
                  </div>
                  <div class="frame-7">
                    <img class="arrow-narrow-right" src="https://psi-crm.com/ProposalIcons/arrow-narrow-right.svg" />
                    <div class="frame-8">
                      <div class="text-4">No of residential:</div>
                      <div class="text-5">
                        {{#PropertyDescription.data.projectDescriptionData.projectFacts.numberOfResidentialUnits}}
                          {{PropertyDescription.data.projectDescriptionData.projectFacts.numberOfResidentialUnits}}
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.numberOfResidentialUnits}}
                        {{^PropertyDescription.data.projectDescriptionData.projectFacts.numberOfResidentialUnits}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.numberOfResidentialUnits}}
                      </div>
                    </div>
                  </div>
                  <div class="frame-7">
                    <img class="arrow-narrow-right" src="https://psi-crm.com/ProposalIcons/arrow-narrow-right.svg" />
                    <div class="frame-8">
                      <div class="text-4">No of commercial:</div>
                      <div class="text-5">
                        {{#PropertyDescription.data.projectDescriptionData.projectFacts.numberOfCommercialUnits}}
                          {{PropertyDescription.data.projectDescriptionData.projectFacts.numberOfCommercialUnits}}
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.numberOfCommercialUnits}}
                        {{^PropertyDescription.data.projectDescriptionData.projectFacts.numberOfCommercialUnits}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.numberOfCommercialUnits}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="frame-5">
            <div class="text-wrapper-3">Project allocation</div>
            <div class="frame-6">
              <div class="row">
                <div class="col-2">
                  <div class="frame-9">
                    <img class="img" src="https://psi-crm.com/ProposalIcons/building-01.svg" />
                    <div class="frame-10">
                      <div class="text-6">Apartment</div>
                      <div class="text-7">
                        {{#PropertyDescription.data.projectDescriptionData.projectFacts.numberOfApartmentUnits}}
                          {{PropertyDescription.data.projectDescriptionData.projectFacts.numberOfApartmentUnits}}
                          units
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.numberOfApartmentUnits}}
                        {{^PropertyDescription.data.projectDescriptionData.projectFacts.numberOfApartmentUnits}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.numberOfApartmentUnits}}
                      </div>
                    </div>
                  </div>
                  <div class="frame-9">
                    <img class="img" src="https://psi-crm.com/ProposalIcons/house-modern-svgrepo-com 1.svg" />
                    <div class="frame-11">
                      <div class="text-6">Villa</div>
                      <div class="text-7">
                         {{#PropertyDescription.data.projectDescriptionData.projectFacts.numberOfApartmentUnits}}
                          {{PropertyDescription.data.projectDescriptionData.projectFacts.numberOfApartmentUnits}}
                          units
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.numberOfApartmentUnits}}
                        {{^PropertyDescription.data.projectDescriptionData.projectFacts.numberOfApartmentUnits}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.numberOfApartmentUnits}}
                      </div>
                    </div>
                  </div>
                  <div class="frame-9">
                    <img class="img" src="https://psi-crm.com/ProposalIcons/briefcase-01.svg" />
                    <div class="frame-11">
                      <div class="text-6">Office</div>
                      <div class="text-7">
                        {{#PropertyDescription.data.projectDescriptionData.projectFacts.numberOfOfficeUnits}}
                          {{PropertyDescription.data.projectDescriptionData.projectFacts.numberOfOfficeUnits}}
                          units
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.numberOfOfficeUnits}}
                        {{^PropertyDescription.data.projectDescriptionData.projectFacts.numberOfOfficeUnits}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.numberOfOfficeUnits}}
                      </div>
                    </div>
                  </div>
                  <div class="frame-9">
                    <img class="img" src="https://psi-crm.com/ProposalIcons/building-07 (1).svg" />
                    <div class="frame-11">
                      <div class="text-6">Townhouse</div>
                      <div class="text-7">
                        {{#PropertyDescription.data.projectDescriptionData.projectFacts.numberOfTownhouseUnits}}
                          {{PropertyDescription.data.projectDescriptionData.projectFacts.numberOfTownhouseUnits}}
                          units
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.numberOfTownhouseUnits}}
                        {{^PropertyDescription.data.projectDescriptionData.projectFacts.numberOfTownhouseUnits}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.numberOfTownhouseUnits}}
                      </div>
                    </div>
                  </div>
                  <div class="frame-9">
                    <img class="img" src="https://psi-crm.com/ProposalIcons/building-08.svg" />
                    <div class="frame-11">
                      <div class="text-6">Penthouse</div>
                      <div class="text-7">
                        {{#PropertyDescription.data.projectDescriptionData.projectFacts.numberOfPenthouseUnits}}
                          {{PropertyDescription.data.projectDescriptionData.projectFacts.numberOfPenthouseUnits}}
                          units
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.numberOfPenthouseUnits}}
                        {{^PropertyDescription.data.projectDescriptionData.projectFacts.numberOfPenthouseUnits}}
                          -
                        {{/PropertyDescription.data.projectDescriptionData.projectFacts.numberOfPenthouseUnits}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {{/PropertyDescription.showProjectFacts}}
      </div>
    </div>
  </div>
  {{/PropertyDescription.showPage}}
</div>
`;
