import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Tabs from "../../Utilities/Tabs/Tabs";
import { CheckboxesComponent } from "../../../../../Components";
import InstallmentsTable from "../../Utilities/InstallmentsTable/InstallmentsTable";

const PaymentPlanForm = ({ localFormChangeHandler, localFormSelection }) => {
  const parentTranslationPath = "SmartProposal";
  const translationPath = "PaymentPlanForm.";
  const { t } = useTranslation(parentTranslationPath);


  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const paymentPlans = localFormSelection?.data?.propertyPaymentPlans || [];

  const generateTabs = () => {
    return Array.isArray(paymentPlans)
      ? paymentPlans.map( item => {
          return {
            id: item.propertyPaymentPlanId,
            label: item.paymentPlanName,
          };
        })
      : [];
  };
  const checkedItemIndex = useMemo(
    () =>
      localFormSelection?.checkedPaymentPlans?.findIndex(
        (item) =>
          item.propertyPaymentPlanId ===
          paymentPlans[activeTabIndex]?.propertyPaymentPlanId
      ),
    [localFormSelection?.checkedPaymentPlans, paymentPlans, activeTabIndex]
  );

  const paymentPlanTabs = useMemo(() => generateTabs(), [paymentPlans]);
  const activeTabLabel = paymentPlanTabs[activeTabIndex]?.label;

  const showPaymentPlanHandler = () => {
    const activePlanData = paymentPlans[activeTabIndex];

    let checkedPaymentPlans = [
      ...(localFormSelection?.checkedPaymentPlans || []),
    ];
    const isAlreadyAdded = checkedItemIndex !== -1;

    if (!isAlreadyAdded) checkedPaymentPlans.push(activePlanData);
    else if (isAlreadyAdded) checkedPaymentPlans.splice(checkedItemIndex, 1);

    localFormChangeHandler("checkedPaymentPlans", checkedPaymentPlans);
  };

  return (
    <>
      <Tabs
        tabsData={paymentPlanTabs}
        tabsChangeHandler={(activeTabIndex) =>
          setActiveTabIndex(activeTabIndex)
        }
      />
      {paymentPlanTabs?.length > 0 ? (
        <div>
          <CheckboxesComponent
            idRef="Show-pyment-planRef"
            label={`Show "${activeTabLabel || ""}" payment plan`}
            themeClass="theme-propx"
            singleChecked={checkedItemIndex !== -1 || false}
            onSelectedCheckboxChanged={showPaymentPlanHandler}
          />
          <InstallmentsTable
            data={paymentPlans[activeTabIndex]?.propertyPlanInstallments || []}
          />
        </div>
      ) : null}
    </>
  );
};

export default PaymentPlanForm;
