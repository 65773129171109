import React, { useEffect, useState } from "react";
import "../../DragDropPicker/DragDropPicker.scss";
import {
  CheckboxesComponent,
  DialogComponent,
} from "../../../../../../Components";
import useDragDrop from "../../../Hooks/useDragDrop";
import { ButtonBase, DialogActions, DialogContent } from "@material-ui/core";
import { PageHeading } from "../../../../../../SharedComponents";
import { useTranslation } from "react-i18next";
import xCloseIcon from "../../../../../../assets/images/icons/x-close-dialog.svg";
import usePickerExceedCount from "../../../Hooks/usePickerExceedCount";

const PickerDialog = ({
  isDialogOpen,
  pickerItems,
  fileKeys,
  maxChecked,
  DragItem,
  closeHandler,
  submitHandler,
}) => {
  const parentTranslationPath = "SmartProposal";
  const translationPath = "ProposalThemeDialog.";
  const { t } = useTranslation(parentTranslationPath);

  const checkIsComponentExist = () =>
    typeof DragItem === "function" || typeof DragItem?.type === "function";
  const [dialogPickerItems, setDialogPickerItems] = useState([]);

  const { handleDragStart, handleDragEnd, handleDragOver, handleDrop } =
    useDragDrop({ 
      pickerItems: dialogPickerItems,
      setPickerItems: setDialogPickerItems,
     });

  const { isCheckedExceedMax } = usePickerExceedCount({
    pickerItems: dialogPickerItems,
    maxChecked,
  });

  const initializePickerItems = () => {
    if (Array.isArray(pickerItems) && dialogPickerItems.length == 0)
      setDialogPickerItems(pickerItems);
  };

  useEffect(() => initializePickerItems(), [pickerItems]);

  const handleCheckboxChange = (e, item, index) => {
    const isChecked = e.target.checked;

    if (isChecked && isCheckedExceedMax) return;

    let updatedItems = [...dialogPickerItems];
    updatedItems.splice(index, 1);

    const lastCheckedIndex = updatedItems.reduce(
      (lastIndex, currentItem, idx) =>
        currentItem.isChecked ? idx : lastIndex,
      -1
    );
    const targetIndex = maxChecked == 1 ? 0 : lastCheckedIndex + 1;

    updatedItems.splice(targetIndex, 0, { ...item, isChecked });

    setDialogPickerItems(updatedItems);
  };

  return (
    <>
      {isDialogOpen && (
        <DialogComponent
          isOpen={isDialogOpen}
          maxWidth="sm"
          dialogTitle={
            <div className="w-100">
              <div className="d-flex-v-center-h-between fa-start">
                <PageHeading
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                  headerTitle={"Choose-images"}
                  subTitle={"Choose-images-sub-title"}
                  wrapperClasses={"m-0"}
                  headerTitleClasses={"m-0 fz-16"}
                  subTitleClasses={"m-0 fz-12"}
                />
                <div className="xCloseIcon pointer" onClick={closeHandler}>
                  <img src={xCloseIcon} />
                </div>
              </div>
            </div>
          }
          dialogContent={
            <div className="">
              <DialogContent className="flex-column gap-20px p-0">
                <div className="PickerItems_container" style={{ margin: "auto" }}>
                  {dialogPickerItems?.length > 0 ? (
                    dialogPickerItems.map((item, index) => (
                      <div
                        key={item[fileKeys.id]}
                        className={`PickerItems_Drag_item`}
                        draggable={item.isChecked || false}
                        onDragStart={(e) => handleDragStart(e, item)}
                        onDragEnd={handleDragEnd}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, item)}
                      >
                        <CheckboxesComponent
                          idRef="checkboxes_component"
                          themeClass="theme-propx"
                          singleChecked={item.isChecked || false}
                          onSelectedCheckboxClicked={(event) =>
                            handleCheckboxChange(event, item, index)
                          }
                        />
                        {checkIsComponentExist() ? (
                          <DragItem
                            key={`dynamicComponentRef-${item[fileKeys.id]}`}
                            itemData={item}
                            fileKeys={fileKeys}
                          />
                        ) : null}
                      </div>
                    ))
                  ) : (
                    <span>No images available .</span>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <div className="d-flex-center fj-end mt-3">
                  <ButtonBase
                    onClick={closeHandler}
                    className="btns theme-propx outlined"
                  >
                    {t(`${translationPath}cancel`)}
                  </ButtonBase>

                  <ButtonBase
                    className="btns theme-propx solid mr-0"
                    onClick={()=> submitHandler(dialogPickerItems)}
                  >
                    {t(`${translationPath}submit`)}
                  </ButtonBase>
                </div>
              </DialogActions>
            </div>
          }
        />
      )}
    </>
  );
};

export default PickerDialog;
