import React, {
  useState, useEffect, useCallback, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  ButtonBase,
  Radio,
  FormControlLabel,
} from '@material-ui/core';

import { LeadsClassTypesEnum, AgentRoleEnum } from '../../../../Enums';
import { AutocompleteComponent, CheckboxesComponent } from '../../../../Components';
import {
  BulkLeadsAutoRotationQualifiedByQa, checkIfAgentIsBlocked, OrganizationUserSearch, ReassignLeads
} from '../../../../Services';
import { showError, showSuccess } from '../../../../Helper';
import { GetAdvanceSearchRotationScheme } from '../../../../Services/RotaionSchemaService/RotationSchemaService';
import { MessageConfirmDialog } from '../../../../SharedComponents';

export const LeadsReassignDialog = ({
  isOpen,
  onSave,
  translationPath,
  parentTranslationPath,
  onClose,
  leadType,
  isLoadingReassign,
  activeItem,
  selectedLeadIds,
  Ralode,
  isQualifiedByQa
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const pathName = window.location.pathname.split('/home/')[1];
  const [isAgentBlockedDialogOpen , setIsAgentBlockedDialogOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const searchTimer = useRef(null);
  const [state, setState] = useState({
    referredToId: null,
    SendtoAllSchemas: false,
    isCopyToActivites: false,
    rotationSchemeId: null
  });
  const [agentList, setAgentList] = useState({
    result: [],
    totalCount: 0,
  });

  const getSaleOrLeaseAgents = useCallback(async (value) => {
    setIsLoading(true);
    let userTypeId = null;
    if (leadType === 'Buyer' || leadType === LeadsClassTypesEnum.buyer.key) userTypeId = AgentRoleEnum.SaleAgent.value;
    else if (leadType === 'Tenant' || leadType === LeadsClassTypesEnum.tenant.key) userTypeId = AgentRoleEnum.LeaseAgent.value;
    else if (leadType === 'Landlord' || leadType === LeadsClassTypesEnum.landlord.key) userTypeId = AgentRoleEnum.LeaseListingAgent.value;
    else if (leadType === 'Seller' || leadType === LeadsClassTypesEnum.seller.key) userTypeId = AgentRoleEnum.SaleListingAgent.value;
    const res = await OrganizationUserSearch({
      pageSize: 20, pageIndex: 0, name: value, userTypeId
    });
    if (!(res && res.status && res.status !== 200)) {
      setAgentList({
        result: res.result,
        totalCount: res && res.totalCount,
      });
    } else {
      setAgentList({
        result: [],
        totalCount: 0,
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getSaleOrLeaseAgents();
  }, []);

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [selectedValue, setSelectedValue] = React.useState('false');
  const [selectedone, setselectedone] = useState([]);
  const [selectedtow, setselectedtow] = useState([]);
  const [selectedSchemesIdes, setSelectedSchemesIdes] = useState([]);
  const [allSchemas, setAllSchemas] = useState([]);
  const [isSendToAllChecked, setIsSendToAllChecked] = useState(false);
  const onSubmit = useCallback(async (state) => {
    const originalArray = activeItem && activeItem.leadId && [activeItem && activeItem.leadId, ...selectedLeadIds] || selectedLeadIds || [];
    const uniqueArray = [...new Set(originalArray)];
    let res = null;
    setIsLoading(true);
    if (selectedValue === 'Reassign') {
      res = await ReassignLeads({
        leadIds: uniqueArray ||
          selectedLeadIds,
        referredToId: state.referredToId,
        isCopyTo: state.isCopyToActivites
      }, (isQualifiedByQa));
    } else if (selectedValue === 'Send to Rotation')
      res = await BulkLeadsAutoRotationQualifiedByQa({ leadsIds: uniqueArray, recommendedSchemesIds: state.rotationSchemeId });
    else if (selectedValue === 'false') {

      // res = await UpdateLeadQualification(
      //   {
      //  //   leadId: activeItem && activeItem.leadId || null,
      //  //   leadQualificationLookup: { lookupsId: 62204, lookupItemName: 'Qualified by QA', }
      //   }
      // );
    }
    if (!(res && res.status && res.status !== 200)) {
      // if (pathName === 'Unqualified-Lead' && selectedValue !== 'false') {
      //   res = await UpdateLeadQualification(
      //     {
      //       leadId: activeItem && activeItem.leadId || null,
      //       leadQualificationLookup: {
      //         lookupsId: 62204,
      //         lookupItemName: 'Qualified by QA',
      //       }
      //     }
      //   );
      // }

      showSuccess(t(`${translationPath}send-successed`));
      Ralode();
    } else showError(t(`${translationPath}send-failed`));
    setIsLoading(false);
    onClose();
  }, [onClose, selectedLeadIds, selectedValue]);

  const getAllSchemas = useCallback(async (search) => {
    setIsLoading(true);
    const isForOwnerLeads = (leadType === 'Landlord' || leadType === LeadsClassTypesEnum.landlord.key) ||
      (leadType === 'Seller' || leadType === LeadsClassTypesEnum.seller.key);

    const res = await GetAdvanceSearchRotationScheme({
      pageSize: 25, pageIndex: 1, search, isForOwnerLeads
    });
    if (!(res && res.status && res.status !== 200)) setAllSchemas(res.result);
    else setAllSchemas([]);

    setIsLoading(false);
  }, []);
  useEffect(() => {
    getAllSchemas();
  }, [getAllSchemas]);

  useEffect(() => {
    if (selectedSchemesIdes.length > 0 || isSendToAllChecked) setIsSaveDisabled(false);

    else setIsSaveDisabled(true);
  }, [selectedSchemesIdes, isSendToAllChecked]);

  const handleChange = (event) => {
    if (event.target.value === 'Send to Rotation') {
      setselectedone([]);
      setState((items) => ({ ...items, isCopyToActivites: false }));
    } else if (event.target.value === 'Reassign') {
      setselectedtow([]);
      setState((items) => ({ ...items, SendtoAllSchemas: false }));
    }
    setSelectedValue(event.target.value);
  };
  const checkAgentIsBlocked = async (value) => {
    try {
      const res = await checkIfAgentIsBlocked(value?.id);
      return res
    } catch {
      showError(t("an-error-blocked-agent"));
    }
  };
  

  return (
    <div>
      <Dialog
        maxWidth='sm'
        open={isOpen}
        onClose={() => {
          onClose();
        }}
        disableBackdropClick
      >
        <DialogTitle id='alert-dialog-slide-title'>
          {t(`${translationPath}reassign-leads`)}
        </DialogTitle>
        <DialogContent>
          <div className='dialog-content-wrapper'>
            <div className='dialog-content-item w-100'>
              <FormControlLabel
                value='Reassign'
                control={(
                  <Radio
                    checked={selectedValue === 'Reassign'}
                    onChange={handleChange}
                    value='Reassign'
                    label='Reassign'
                    name='radio-button-demo'
                    inputProps={{ 'aria-label': 'Reassign' }}
                  />
                )}
                label='Reassign'
              />
              <AutocompleteComponent
                idRef='referredToIdRef'
                multiple={false}
                labelValue={t(`${translationPath}referredTo`)}
                isLoading={isLoading}
                isDisabled={selectedValue !== 'Reassign'}
                withoutSearchButton
                selectedValues={selectedone}
                getOptionSelected={(option) => option.id === selectedone.id}
                data={(agentList && agentList.result) || []}
                chipsLabel={(option) => (option && option.fullName) || (option.name) || ''}
                displayLabel={(option) => (option && option.fullName) || (option.name) || ''}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                inputPlaceholder={t(`${translationPath}referredTo`)}
                onChange={async (event, newValue) => {
                  if (await checkAgentIsBlocked(newValue)) {
                    setIsAgentBlockedDialogOpen(true);
                    setselectedone(null);
                    setState((item) => ({ ...item, referredToId:null }));
                    return;
                  }
                  setselectedone(newValue);
                  setState((item) => ({ ...item, referredToId: (newValue && newValue.id) }));
                }}
                onInputKeyUp={(e) => {
                  const { value } = e.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getSaleOrLeaseAgents(value);
                  }, 500);
                }}
              />

            </div>
            <div className='dialog-content-item'>
              <CheckboxesComponent
                idRef='rwithCopyAllActivitesRef'
                parentTranslationPath={parentTranslationPath}
                isDisabled={selectedValue !== 'Reassign'}
                translationPath={translationPath}
                label='withCopyAllActivites'
                singleChecked={state.isCopyToActivites}
                onSelectedCheckboxClicked={() => {
                  setState((items) => ({ ...items, isCopyToActivites: (!state.isCopyToActivites) }));
                }}
              />
            </div>
            <div className='mt-4' />

            <FormControlLabel
              value='Send to Rotation'
              control={(
                <Radio
                  checked={selectedValue === 'Send to Rotation'}
                  onChange={handleChange}
                  value='Send to Rotation'
                  label='Send to Rotation'
                  name='radio-button-demo'
                  inputProps={{ 'aria-label': 'Send to Rotation' }}
                />
              )}
              label='Send to Rotation'
            />
            <div className='d-flex-column-center'>

              <AutocompleteComponent
                idRef='recommendedSchemesRef'
                multiple={false}
                labelValue={t(`${translationPath}recommendedSchemes`)}
                isLoading={isLoading}
                isDisabled={selectedValue !== 'Send to Rotation' || state.SendtoAllSchemas === true}
                withoutSearchButton
                selectedValues={selectedtow}
                getOptionSelected={(option) => option.rotationSchemeId === selectedtow.rotationSchemeId}
                data={(allSchemas && allSchemas) || []}
                chipsLabel={(option) => (option && option.label) || ''}
                displayLabel={(option) => (option && option.label) || ''}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                inputPlaceholder={t(`${translationPath}SchemaName`)}
                onChange={(event, newValue) => {
                  setselectedtow(newValue);
                  if (state.SendtoAllSchemas === true)
                    setState((item) => ({ ...item, rotationSchemeId: null }));
                  else
                    setState((item) => ({ ...item, rotationSchemeId: newValue && newValue && [(newValue && newValue.rotationSchemeId)] || null }));
                }}
                onInputKeyUp={(event) => {
                  const { value } = event.target;
                  if (searchTimer.current) clearTimeout(searchTimer.current);
                  searchTimer.current = setTimeout(() => {
                    getAllSchemas(value);
                  }, 700);
                }}
              />
            </div>
            <CheckboxesComponent
              idRef='rwithCopyAllActivitesRef'
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              isDisabled={selectedValue !== 'Send to Rotation'}
              label='send-to-all-schemas'
              singleChecked={state.SendtoAllSchemas}
              onSelectedCheckboxClicked={() => {
                setselectedtow([]);
                setState((item) => ({ ...item, rotationSchemeId: null }));
                setState((items) => ({ ...items, SendtoAllSchemas: (!state.SendtoAllSchemas) }));
              }}
            />
            {isAgentBlockedDialogOpen && (
              <MessageConfirmDialog
                isDialogOpen={isAgentBlockedDialogOpen}
                theme='warning'
                cancelButton={{
                  label: 'Discard',
                  handler: () => {
                    setIsAgentBlockedDialogOpen(false);
                  },
                }}
                content={{
                  heading: "agent-blocked",
                  body: "the-selected-agent-is-blocked",
                }}
                parentTranslationPath="Shared"
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <div className='form-builder-wrapper'>
            <div className=' d-flex form-builder-footer-wrapper is-dialog w-100 MuiGrid-align-items-xs-center MuiGrid-justify-xs-space-between'>
              <div className='MuiGrid-root-right'>
                <ButtonBase
                  className='MuiButtonBase-root MuiButton-root MuiButton-text btns'
                  onClick={() => {
                    onClose();
                  }}
                >
                  <span className='MuiButton-label'>
                    <span className='mx-2'>
                      {t(`${translationPath}cancel`)}
                    </span>
                  </span>
                  <span className='MuiTouchRipple-root' />
                </ButtonBase>
              </div>
              <div className='MuiGrid-root-right'>
                <ButtonBase
                  className='MuiButtonBase-root MuiButton-root MuiButton-text btns theme-solid'
                  disabled={
                    state.referredToId === null &&
                    state.SendtoAllSchemas === false &&
                    (state.rotationSchemeId === null)
                  }
                  onClick={() => {
                    onSubmit(state);
                  }}
                >
                  <span className='MuiButton-label'>
                    <span className='mx-2'>
                      {t(`${translationPath}save`)}
                    </span>
                  </span>
                  <span className='MuiTouchRipple-root' />
                </ButtonBase>
              </div>
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};
