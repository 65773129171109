import { formatDisplayDate } from "../formatDate";

const formatUnitsDetails = (data) => ({
  ...(data || {}),
  unitsDetailsData: {
    ...(data?.unitsDetailsData || {}),
    rentalExpiryDate: formatDisplayDate(data?.unitsDetailsData?.rentalExpiryDate, "D MMM YYYY"),
  }
});

export default formatUnitsDetails;
