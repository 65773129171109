import React, { useState, useCallback, useEffect } from "react";
import { GetAllActivityTypes, lookupItemsGetId } from "../../../../Services";
import { AutocompleteComponent, SelectComponet } from "../../../../Components";

export const CreateLeadFormStatic = ({
  formData,
  parentTranslationPath,
  translationPath,
  LeadFormData,
  setLeadFormData,
}) => {
  const [formValues, setFormValues] = useState(formData);
  const [selected, setSelected] = useState({
    activityTypeId: null,
    sourceId: null,
    leadActivityTypeForm: null,
    leadsType: null,
    mediaDetails: [],
  });

  const [loadings, setLoadings] = useState({ activityTypes: false });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [data, setData] = useState([]);
  const [DataMediaDetails, setDatamediaDetails] = useState([]);
  //console.log('DataMediaDetails: ', DataMediaDetails);
  const getAllActivityTypes = useCallback(async () => {
    setLoadings((prevState) => ({ ...prevState, activityTypes: true }));
    try {
      const res = await GetAllActivityTypes();
      if (res) {
        setData(res);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Failed to fetch activity types", error);
      setData([]);
    }
    setLoadings((prevState) => ({ ...prevState, activityTypes: false }));
  }, []);

  useEffect(() => {
    getAllActivityTypes();
  }, [getAllActivityTypes]);

  const handleFieldChange = (fieldName, value) => {
    const updatedFormValues = { ...formValues, [fieldName]: value };
    if (
      [
        "LeadsType",
        "SourceId",
        "psi_opportunity",
        "MediaDetailsWhitelist",
      ].includes(fieldName)
    ) {
      const condition = {
        FormId: 127,
        searchKey: fieldName,
        value: value,
        Operator: 1,
        Operand: "AND",
      };
      setLeadFormData((prevData) => ({
        ...prevData,
        conditions: [...(prevData.conditions || []), condition],
      }));
    } else if (
      [
        "activityTypeId-activity",
        "LeadActivityTypeForm-activity",
        "LeadActivityTypeForm",
      ].includes(fieldName)
    ) {
      setLeadFormData((prevData) => ({
        ...prevData,
        activity: {
          ...prevData.activity,
          [(fieldName === "LeadActivityTypeForm" && "activityTypeId") ||
          fieldName]: {
            type: "Fixed",
            table: null,
            conditionColumn: null,
            conditionValue: null,
            object: null,
            value: value,
          },
        },
      }));
    }

    setFormValues(updatedFormValues);
  };

  const getAllMediaDetails = useCallback(async (mediaTypeId) => {
    const res = await lookupItemsGetId({
      lookupTypeId: 1240,
      lookupParentId: mediaTypeId,
    });
    if (!(res && res.status && res.status !== 200))
      setDatamediaDetails((item) => ({ ...item, mediaDetails: res }));
    else setDatamediaDetails((item) => ({ ...item, mediaDetails: [] }));
  }, []);

  useEffect(() => {
    getAllMediaDetails();
  }, [getAllMediaDetails]);

  const renderField = (formName) => {
    if (formValues.formName === formName) {
      if (formName === "psi_opportunity") {
        return (
          <div className="dialog-content-item">
            <label>Psi Opportunity</label>
            <div>
              <label>
                <input
                  type="radio"
                  name="psi_opportunity"
                  value="yes"
                  checked={formValues.psi_opportunity === "yes"}
                  onChange={(e) =>
                    handleFieldChange("psi_opportunity", e.target.value)
                  }
                />
                Yes
              </label>
              <label style={{ marginLeft: "10px" }}>
                <input
                  type="radio"
                  name="psi_opportunity"
                  value="no"
                  checked={formValues.psi_opportunity === "no"}
                  onChange={(e) =>
                    handleFieldChange("psi_opportunity", e.target.value)
                  }
                />
                No
              </label>
            </div>
          </div>
        );
      } else if (formName === "SourceId") {
        return (
          <div className="dialog-content-item">
            <SelectComponet
              idRef="sourceIdRef"
              data={[
                { id: "CRM", text: "CRM" },
                { id: "MyLead", text: "MyLead" },
              ]}
              emptyItem={{
                value: null,
                text: "Select Source",
                isDisabled: false,
              }}
              value={selected.sourceId}
              onSelectChanged={(value) => {
                handleFieldChange("SourceId", value || null);
                setSelected((prevState) => ({
                  ...prevState,
                  sourceId: value,
                }));
              }}
              wrapperClasses="mb-3"
              valueInput="id"
              textInput="text"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          </div>
        );
      } else if (formName === "LeadsType") {
        return (
          <div className="dialog-content-item">
            <SelectComponet
              idRef="leadsTypeRef"
              data={[
                { id: "Owner", text: "Owner" },
                { id: "Seeker", text: "Seeker" },
              ]}
              emptyItem={{
                value: null,
                text: "Select Leads Type",
                isDisabled: false,
              }}
              value={selected.leadsType}
              onSelectChanged={(value) => {
                handleFieldChange("LeadsType", value?.id || null);
                setSelected((prevState) => ({
                  ...prevState,
                  leadsType: value,
                }));
              }}
              wrapperClasses="mb-3"
              valueInput="id"
              textInput="text"
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
            />
          </div>
        );
      } else if (formName === "activityTypeId-activity") {
        return (
          <div className="dialog-content-item">
            <AutocompleteComponent
              idRef="activityTypeIdRef"
              labelValue="activity-type"
              selectedValues={selected.activityTypeId}
              multiple={false}
              labelClasses="Requierd-Color"
              data={data}
              displayLabel={(option) => option.activityTypeName || ""}
              getOptionSelected={(option) =>
                option.activityTypeId === formValues.activityTypeId
              }
              groupBy={(option) => option.categoryName || ""}
              withoutSearchButton
              isLoading={loadings.activityTypes}
              isWithError
              isSubmitted={isSubmitted}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChange={(event, newValue) => {
                handleFieldChange("activityTypeId", newValue || null);
                setSelected((prevState) => ({
                  ...prevState,
                  activityTypeId: newValue,
                }));
              }}
            />
          </div>
        );
      } else if (formName === "LeadActivityTypeForm-activity") {
        return (
          <div className="dialog-content-item">
            <AutocompleteComponent
              idRef="leadActivityTypeFormRef"
              labelValue="lead-activity-type"
              selectedValues={selected.leadActivityTypeForm}
              multiple={false}
              labelClasses="Requierd-Color"
              data={data}
              displayLabel={(option) => option.activityTypeName || ""}
              getOptionSelected={(option) =>
                option.activityTypeId === formValues.activityTypeId
              }
              groupBy={(option) => option.categoryName || ""}
              withoutSearchButton
              isLoading={loadings.activityTypes}
              isWithError
              isSubmitted={isSubmitted}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onChange={(event, newValue) => {
                handleFieldChange("LeadActivityTypeForm", newValue || null);
                setSelected((prevState) => ({
                  ...prevState,
                  leadActivityTypeForm: newValue,
                }));
              }}
            />
          </div>
        );
      } else if (formName === "MediaDetailsWhitelist") {
        return (
          <div className="dialog-content-item">
            <AutocompleteComponent
              idRef="mediaDetailseRef"
              labelValue="Shared:"
              selectedValues={selected?.mediaDetails || []}
              multiple
              withoutSearchButton
              isWithError
              isSubmitted={isSubmitted}
              parentTranslationPath={parentTranslationPath}
              chipsLabel={(option) => option?.lookupItemName || ""}
              translationPath={translationPath}
              data={(DataMediaDetails && DataMediaDetails.mediaDetails) || []}
              displayLabel={(option) => option?.lookupItemName || ""}
              groupBy={(option) => option?.lookupItemName || ""}
              getOptionSelected={(option) =>
                selected?.mediaDetails?.some(
                  (item) => item.lookupItemId === option.lookupItemId
                )
              }
              onChange={(event, newValue) => {
                handleFieldChange("MediaDetailsWhitelist", newValue || null);
                let newSelected = newValue || [];
                const mappedStateMediaDetails = newSelected?.map((item) => ({
                  lookupItemId: item.lookupItemId,
                  lookupItemName: item.lookupItemName,
                }));
                setSelected((prevState) => ({
                  ...prevState,
                  mediaDetails: mappedStateMediaDetails,
                }));
              }}
            />
          </div>
        );
      } else {
        return null;
      }
    }
    return null;
  };

  return (
    <form>
      {renderField("activityTypeId-activity")}
      {renderField("SourceId")}
      {renderField("psi_opportunity")}
      {renderField("LeadsType")}
      {renderField("LeadActivityTypeForm-activity")}
      {renderField("MediaDetailsWhitelist")}
    </form>
  );
};
