import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from "@material-ui/core";

// Styles
import useStyles from './styles';
import { useSelectedTheme } from '../../../Hooks';

// Icons
import { GridView, TableView } from '../../../assets/icons';

// Constants for view modes
export const VIEW_MODES = {
  TABLE: "tableView",
  GRID: "cards",
};

function ViewModeSwitcher({ viewMode, onChangeViewMode, isLoading }) {
  const styles = useStyles();

  const {
    theme: { palette },
  } = useSelectedTheme();

  return (
    <ButtonGroup disabled={isLoading} className={styles.viewModeBtnGroup} size="small" disableRipple>
      <Button
        className={viewMode === VIEW_MODES.TABLE ? styles.isActiveBg : ""}
        onClick={() => onChangeViewMode(VIEW_MODES.TABLE)}
      >
        <TableView width="20" height="20" fill={palette.button.secondary_fg} />
      </Button>
      <Button
        className={viewMode === VIEW_MODES.GRID ? styles.isActiveBg : ""}
        onClick={() => onChangeViewMode(VIEW_MODES.GRID)}
      >
        <GridView width="20" height="20" fill={palette.button.secondary_fg} />
      </Button>
    </ButtonGroup>
  );
}

ViewModeSwitcher.propTypes = {
  viewMode: PropTypes.oneOf([VIEW_MODES.TABLE, VIEW_MODES.GRID]),
  onChangeViewMode: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

ViewModeSwitcher.defaultProps = {
  viewMode: VIEW_MODES.GRID,
  isLoading: false,
};

export default ViewModeSwitcher;
