import React from "react";
import { SwitchRoute } from "../../../Components/Route/SwitchRoute";
import { NewContactsCrmRoutes } from "../../../routes/HomeRoutes/NewContactCrmRoutes";
import { ContactsLayoutProvider } from "./ContactLayoutContext";

export const NewContactsCrmLayout = () => {
  return (
    <ContactsLayoutProvider>
      <SwitchRoute routes={NewContactsCrmRoutes} />
    </ContactsLayoutProvider>
  );
};
