import React from "react";
import { CopyToClipboardComponents } from "../../../../ReusableComponents/UtilityComponents";

export const MultiUnitsHeaderData = [
    {
        id: 1,
        label: 'Unit ref NO.',
        input: 'unitRefNo',
        component: (item) => item.unitRefNo ?
            <CopyToClipboardComponents
              data={item.unitRefNo}
              childrenData={item.unitRefNo}/>
             : '',
    },
    {
        id: 2,
        label: 'Unit type',
        input: 'unitType',
    },
    {
        id: 3,
        label: 'Height category',
        input: 'heightCategory',
    },
    {
        id: 4,
        label: 'Unit Model',
        input: 'unitModel',
    },
    {
        id: 5,
        label: 'Bedroom',
        input: 'bedroom',
    },
    {
        id: 6,
        label: 'Maid Room',
        input: 'maidRoom',
    },
    {
        id: 7,
        label: 'size (Sq.Ft)',
        input: 'totalAreaSize',
    },
    {
        id: 8,
        label: 'Price',
        input: 'price',
        component: (item) => item.price ?
        <span>{item.price} AED</span>
         : '',
    },
]