import React, { memo, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./ProposalThemeDialog.scss";
import xCloseIcon from "../../../../../assets/images/icons/x-close-dialog.svg";
import { DialogActions, DialogContent, ButtonBase } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DialogComponent } from "../../../../../Components";
import { mapEmailAddress } from "../../Helper/emailAdressHelpers";
import { PageHeading } from "../../../../../SharedComponents";
import { ProposalTypes } from "../../Enums/ProposalTypes";
import { useDispatch, useSelector } from "react-redux";
import { GlobalHistory, showWarn } from "../../../../../Helper";
import { SmartProposalActions } from "../../../../../store/SmartProposal/Actions";
import ThemeDialogForm from "./ThemeDialogForm";

const ProposalThemeDialog = memo(
  ({ singleUnit, isDialogOpen, setIsDialogOpen, checkboxSelectHandler }) => {
    const parentTranslationPath = "SmartProposal";
    const translationPath = "ProposalThemeDialog.";
    const { t } = useTranslation(parentTranslationPath);
    const dispatch = useDispatch();
    const receivers = useRef([]);

    const [selected, setSelected] = useState({
      type: ProposalTypes.Classic,
      isDark: false,
      receiverUsers: [],
      receiverLeads: [],
    });

    const { checkedUnits } = useSelector(
      (state) => state.SmartProposalReducer);
    const proposalUnits = useMemo(() => singleUnit? [singleUnit] : (checkedUnits || []), [checkedUnits, singleUnit]);

    const mapCheckedProperties = () => {
      const localCheckedProperties = {};
      proposalUnits.forEach((item) => {
        if (localCheckedProperties[item.property_name?.id]) {
          localCheckedProperties[item.property_name?.id].units.push(item.id);
        } else
          localCheckedProperties[item.property_name?.id] = {
            propertyId: item.property_name?.id,
            propertyName: item.property_name?.name,
            units: [item.id],
          };
      });
      return localCheckedProperties;
    };

    const checkedProperties = useMemo(
      () => (proposalUnits ? mapCheckedProperties() : []),
      [proposalUnits]
    );

    const isValidInfo = () => {
      const isUnitsSelected = proposalUnits && proposalUnits.length > 0;
      const isTypeSelected = selected.type;
      const hasEmailAddress = receivers.current?.length > 0;

      return isTypeSelected && isUnitsSelected && hasEmailAddress;
    }

    
    const submitHandler = () => {
      const { receiverUsers, receiverLeads } = selected;
      receivers.current = mapEmailAddress({ receiverUsers, receiverLeads });

      if (!isValidInfo()) {
        showWarn(
          t(`${translationPath}please-provide-the-required-information`)
        );
        return;
      }

      const smartProposalState = {
        checkedProperties,
        proposalType: {
          type: selected?.type?.key,
          isDark: selected.isDark,
        },
        receivers: receivers.current,
      };      

      dispatch(SmartProposalActions.SmartProposalRequest(smartProposalState));

      closeDialogHandler();
      GlobalHistory.push(`/home/smart-proposal`);
    };

    const activeCheckboxesHandler = ({ isActive }) =>
      checkboxSelectHandler(isActive);

    const closeDialogHandler = () => {
      activeCheckboxesHandler({ isActive: false });
      setIsDialogOpen(false);
    };

    return (
      <div onClick={(event) => event.stopPropagation()}>
        {isDialogOpen && (
          <DialogComponent
            isOpen={isDialogOpen}
            maxWidth="sm"
            dialogTitle={
              <div className="w-100">
                <div className="d-flex-v-center-h-between fa-start">
                  <PageHeading
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    headerTitle={"Choose-a-template"}
                    subTitle={"Choose-a-template-sub-title"}
                    wrapperClasses={"m-0"}
                    headerTitleClasses={"m-0 fz-16"}
                    subTitleClasses={"m-0 fz-12"}
                  />
                  <div
                    className="xCloseIcon pointer"
                    onClick={closeDialogHandler}
                  >
                    <img src={xCloseIcon} />
                  </div>
                </div>
              </div>
            }
            dialogContent={
              <div className="Proposal_selection__Dialog">
                <DialogContent className="flex-column gap-20px">
                  <ThemeDialogForm
                    selected={selected}
                    setSelected={setSelected}
                  />
                </DialogContent>
                <DialogActions>
                  <div className="d-flex-center fj-end">
                    <ButtonBase
                      onClick={closeDialogHandler}
                      className="btns theme-propx outlined"
                    >
                      {t(`${translationPath}cancel`)}
                    </ButtonBase>

                    <ButtonBase
                      className="btns theme-propx solid mr-0"
                      onClick={submitHandler}
                    >
                      {t(`${translationPath}submit`)}
                    </ButtonBase>
                  </div>
                </DialogActions>
              </div>
            }
          />
        )}
      </div>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.checkedUnits?.length === nextProps.checkedUnits?.length &&
      prevProps.isDialogOpen === nextProps.isDialogOpen
    );
  }
);

export default ProposalThemeDialog;

ProposalThemeDialog.propTypes = {
  checkedUnits: PropTypes.array,
  checkboxSelectHandler: PropTypes.func,
};
ProposalThemeDialog.defaultProps = {
  checkedUnits: [],
  checkboxSelectHandler: () => {},
};
