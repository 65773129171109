const projectDescDtoMapper = (property) => {
  const description = property.submittedForm?.PropertyDescription || {};
  const data = property.submittedForm?.PropertyDescription?.data?.projectDescriptionData || {};

  const getProjectDetails = () => {
    const details = data.projectDetails || {};
    return {
      constructionStatus: details.constructionStatus,
      completionDate: details.completionDate,
      developedBy: details.developedBy,
      usage: details.usage,
      ownershipType: details.ownershipType,
      handover: details.handover,
      masterDeveloper: details.masterDeveloper,
      serviceCharge: details.serviceCharge,
    };
  };

  return {
    showGeneralFacts: description.showGeneralFacts,
    showProjectFacts: description.showProjectFacts,
    projectGeneralFacts: data.projectDetails?.propertyOverView,
    brochureFile: data.brochureFile,
    projectFacts: data.projectFacts,
    projectDetails: getProjectDetails(),
  };
};

export default projectDescDtoMapper;
