import { useCallback, useRef } from "react";

const useDragDrop = ({ onChange = ()=>{}, pickerItems = [], setPickerItems = ()=>{} }) => {
  const draggingItem = useRef(null);

  const handleDragStart = useCallback((e, item) => {
    draggingItem.current = item;
    e.dataTransfer.setData("text/plain", "");
  },[]);

  const handleDragEnd = useCallback(() => {
    draggingItem.current = null;
  },[]);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
  },[]);

  const handleDrop = useCallback((e, targetItem) => {
    if (!draggingItem.current) return;

    const currentIndex = pickerItems.indexOf(draggingItem.current);
    const targetIndex = pickerItems.indexOf(targetItem);

    const lastCheckedIndex = pickerItems.reduce(
      (lastIndex, currentItem, idx) =>
        currentItem.isChecked ? idx : lastIndex,
      -1
    );

    if (targetIndex > lastCheckedIndex) return;

    if (currentIndex !== -1 && targetIndex !== -1) {
      const updatedItems = [...pickerItems];
      updatedItems.splice(currentIndex, 1);
      updatedItems.splice(targetIndex, 0, draggingItem.current);
      setPickerItems(updatedItems);
      onChange(updatedItems.filter((item) => item.isChecked));
    }
  },[pickerItems]);

  return { handleDragStart, handleDragEnd, handleDragOver, handleDrop };
};

export default useDragDrop;
