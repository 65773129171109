export const CoverPageTemplate = `
  <div class="cover-x">
    <div class="header">
      {{#CoverPage.data.branchDetails.licenceId}}
      <div class="text-block">
        <div class="text-wrapper-3">ID</div>
        <div class="text-wrapper-4">{{CoverPage.data.branchDetails.licenceId}}</div>
      </div>
      {{/CoverPage.data.branchDetails.licenceId}}
      <div class="emaar-logo">
        <img class="page" src={{{CoverPage.data.developerLogo}}} />
        <div class="div-wrapper">
          <div class="text-wrapper-5">{{CoverPage.data.developerName}}</div>
        </div>
      </div>
      {{#CoverPage.data.proposalDate}}
      <div class="text-block-2">
        <div class="text-wrapper-3">Date</div>
        <div class="text-wrapper-4">{{CoverPage.data.proposalDate}}</div>
      </div>
      {{/CoverPage.data.proposalDate}}
    </div>
    <div class="body">
      <div class="frame">
        <div class="div">
          <div class="logo"></div>
          <div class="text-wrapper">www.psinv.net</div>
        </div>
        <div class="text-wrapper-2">
          {{#CoverPage.data.completionDate}}Completion - {{CoverPage.data.completionDate}}{{/CoverPage.data.completionDate}}
        </div>
        <div class="parkside-hills">{{CoverPage.data.propertyName}}</div>
        <p class="text-wrapper-2">{{CoverPage.data.propertyLocation}}</p>
      </div>
    </div>
    <div class="footer">
      <div class="frame-2">
        <div class="avatar-label-group">
          <div class="avatar">
            <div class="contrast-border">
              {{^CoverPage.data.contactDetails.avatarImage}}
                {{CoverPage.data.contactDetails.initials}}
              {{/CoverPage.data.contactDetails.avatarImage}}
            </div>
          </div>
          <div class="text-and-supporting">
            <div class="text-2">{{CoverPage.data.contactDetails.name}}</div>
            <div class="supporting-text">{{CoverPage.data.contactDetails.jobTitle}}</div>
          </div>
        </div>
        <div class="frame-3">
          {{#CoverPage.data.contactDetails.phone}}
          <div class="frame-4">
            <div class="frame-5">
              <div class="text-and-icon">
                <img class="img" src="https://psi-crm.com/ProposalIcons/phone-call-01.svg" />
              </div>
              <div class="text-2">Phone</div>
            </div>
            <div class="text-3">{{CoverPage.data.contactDetails.phone}}</div>
          </div>
          {{/CoverPage.data.contactDetails.phone}}
          {{#CoverPage.data.contactDetails.whatsApp}}
          <div class="frame-4">
            <div class="frame-5">
              <div class="img-wrapper">
                <img class="img" src="https://psi-crm.com/ProposalIcons/whatsapp.svg" />
              </div>
              <div class="text-2">Whatsapp</div>
            </div>
            <div class="text-3">{{CoverPage.data.contactDetails.whatsApp}}</div>
          </div>
          {{/CoverPage.data.contactDetails.whatsApp}}
          {{#CoverPage.data.contactDetails.email}}
          <div class="frame-4">
            <div class="frame-5">
              <div class="img-wrapper">
                <img class="img" src="https://psi-crm.com/ProposalIcons/mail.svg" />
              </div>
              <div class="text-2">Email</div>
            </div>
            <div class="text-3">{{CoverPage.data.contactDetails.email}}</div>
          </div>
          {{/CoverPage.data.contactDetails.email}}
        </div>
      </div>
      <div class="frame-6">
        <div class="frame-7">
          <p class="text-wrapper-6">{{CoverPage.data.proposalDate}} Property Shop Investment LLC @All right reserved.</p>
        </div>
        <div class="frame-8">
          {{#CoverPage.data.branchDetails.branchPhoneNumber}}
          <div class="frame-4">
            <img class="img-2" src="https://psi-crm.com/ProposalIcons/phone.svg" />
            <div class="frame-4">
              <div class="text-wrapper-6">{{CoverPage.data.branchDetails.branchPhoneNumber}}</div>
            </div>
          </div>
          {{/CoverPage.data.branchDetails.branchPhoneNumber}}
          {{#CoverPage.data.branchDetails.branchEmail}}
          <div class="frame-4">
            <img class="img-2" src="https://psi-crm.com/ProposalIcons/mail-01.svg" />
            <div class="frame-9">
              <div class="text-wrapper-6">{{CoverPage.data.branchDetails.branchEmail}}</div>
            </div>
          </div>
          {{/CoverPage.data.branchDetails.branchEmail}}
          {{#CoverPage.data.branchDetails.branch}}
          <div class="frame-4">
            <img class="img-2" src="https://psi-crm.com/ProposalIcons/marker-pin-01.svg" />
            <div class="frame-10">
              <div class="text-wrapper-6">{{CoverPage.data.branchDetails.branch}}</div>
            </div>
          </div>
          {{/CoverPage.data.branchDetails.branch}}
        </div>
      </div>
    </div>
  </div>
`;
