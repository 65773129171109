import React from "react";
import { DialogComponent, Tables } from "../../../../../../../../Components";
import { RiskRatingConfigEnum } from "../../../../../../../../Enums";
import "./RiskReasons.scss";

export default function RiskReasonsDialog({
  isOpen,
  activeItem,
  close,
  parentTranslationPath,
}) {
  const mapRiskLabels = {
    CustomerType: "customerType",
    ResidenceStatus: "residenceAddress",
    "CustomerSourceOfFund/Wealth": "customerSourceOfFunds",
    "NatureOfTransaction(PaymentMethod)": "natureOfTransactions",
    "JurisdictionRisk(Nationality)": "jurisdictionRisk",
    "UAE Nationality": "uaeNationality",
    "UAE Residence Address": "uaeNationality",
  };

  const groupedData = [];

  const hasHighRiskPEP = activeItem?.riskRatingDetailsDto?.some(
    (item) => item.fieldName === "PEP/Associated PEP" && item.riskRating === 1
  );

  const findAvailableRow = (key) => {
    for (let row of groupedData) {
      if (!(key in row)) return row;
    }
    return null;
  };

  activeItem?.riskRatingDetailsDto &&
    activeItem.riskRatingDetailsDto.forEach((item) => {
      if (item.riskRating !== 3) {
        const key =
          item.fieldName === "UAE Nationality"
            ? mapRiskLabels[item.fieldName]
            : item.fieldName === "UAE Residence Address"
            ? mapRiskLabels[item.fieldName]
            : mapRiskLabels[item.riskRatingFormLabelName];
        if (key) {
          if (
            hasHighRiskPEP &&
            item.riskRatingFormLabelName === "CustomerType" &&
            item.fieldName !== "PEP/Associated PEP" &&
            item.riskRating !== 1
          ) {
            return;
          }

          let row = findAvailableRow(key);
          if (!row) {
            row = {};
            groupedData.push(row);
          }
          row[key] =
            item.fieldName === "UAE Nationality"
              ? " Not UAE Nationality"
              : item.fieldName;
          row[`${key}Rating`] = item.riskRating;
        }
      }
    });
  const getRatingStyleClass = (ratingName) =>
    Object.values(RiskRatingConfigEnum).find(
      (item) => item.value === ratingName
    )?.class || "";

  return (
    <>
      <DialogComponent
        titleText={"risk-reasons"}
        isOpen={isOpen}
        onCancelClicked={close}
        onCloseClicked={close}
        hideSaveBtn
        parentTranslationPath={parentTranslationPath}
        dialogContent={
          <Tables
            data={groupedData}
            parentTranslationPath={parentTranslationPath}
            defaultActions={[]}
            headerData={[
              {
                id: 1,
                label: "customerType",
                input: "customerType",
                component: (item) => (
                  <div className="d-flex fa-center gap-12">
                    <span
                      className={`RateDot ${getRatingStyleClass(
                        item.customerTypeRating
                      )}`}
                    ></span>
                    <span className="fw-simi-bold">
                      {item.customerType || "-"}
                    </span>
                  </div>
                ),
              },
              {
                id: 2,
                label: "residenceAddress",
                input: "residenceAddress",
                component: (item) => (
                  <div className="d-flex fa-center gap-12">
                    <span
                      className={`RateDot ${getRatingStyleClass(
                        item.residenceAddressRating
                      )}`}
                    ></span>
                    <span className="fw-simi-bold">
                      {item.residenceAddress || "-"}
                    </span>
                  </div>
                ),
              },
              {
                id: 3,
                label: "customerSourceOfFunds",
                input: "customerSourceOfFunds",
                component: (item) => (
                  <div className="d-flex fa-center gap-12">
                    <span
                      className={`RateDot ${getRatingStyleClass(
                        item.customerSourceOfFundsRating
                      )}`}
                    ></span>
                    <span className="fw-simi-bold">
                      {item.customerSourceOfFunds || "-"}
                    </span>
                  </div>
                ),
              },
              {
                id: 4,
                label: "natureOfTransactions",
                input: "natureOfTransactions",
                component: (item) => (
                  <div className="d-flex fa-center gap-12">
                    <span
                      className={`RateDot ${getRatingStyleClass(
                        item.natureOfTransactionsRating
                      )}`}
                    ></span>
                    <span className="fw-simi-bold">
                      {item.natureOfTransactions || "-"}
                    </span>
                  </div>
                ),
              },
              {
                id: 5,
                label: "jurisdictionRisk",
                input: "jurisdictionRisk",
                component: (item) => (
                  <div className="d-flex fa-center gap-12">
                    <span
                      className={`RateDot ${getRatingStyleClass(
                        item.jurisdictionRiskRating
                      )}`}
                    ></span>
                    <span className="fw-simi-bold">
                      {item.jurisdictionRisk || "-"}
                    </span>
                  </div>
                ),
              },
              {
                id: 6,
                label: "uaeNationality",
                input: "uaeNationality",
                component: (item) => (
                  <div className="d-flex fa-center gap-12">
                    <span
                      className={`RateDot ${getRatingStyleClass(
                        item.uaeNationalityRating
                      )}`}
                    ></span>
                    <span className="fw-simi-bold">
                      {item.uaeNationality || "-"}
                    </span>
                  </div>
                ),
              },
            ]}
          />
        }
      />
    </>
  );
}
