import { SystemConfigrationView } from "../../Views/Home/Administration/SystemConfigrationView/SystemConfigrationView";
import LeadsPoolConfigurationView from "../../Views/Home/LeadsPoolConfigurationView/LeadsPoolConfigurationView";

export const LeadsPoolConfigurationRoutes = [
  {
    path: "/view",
    name: "general-configration",
    component: LeadsPoolConfigurationView,
    layout: "/home/leads-pool-configuration",
    default: true,
    isRoute: true,
    authorize: true,
    roles: [],
    isDisabled: false,
    isExact: true,
    breadcrumbs: [],
  },
];
