import clsx from "clsx";
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Avatar,
  IconButton,
  MenuItem,
  Menu,
} from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import { useNewLayoutShared } from "../../../Contexts/NewLayoutSharedContext";
import { useSelectedTheme, useTitle, useTranslate } from "../../../Hooks";
import { truncateString } from "../../../Helper";
import {
  CustomBadge,
  CustomCopyToClipboard,
  PropertyPlanBadges,
  PropertyRating,
  ImageCarousel,
  CustomCheckbox,
  CustomIconButton,
} from "../..";

// Icons
import { ArrowUpRight, MoreVertIcon, ListIcon } from "../../../assets/icons";

// Styles
import useStyles from "./styles";

const PropertyCard = ({ item, activeItem, setActiveItem }) => {
  const styles = useStyles();

  const {
    theme: { palette },
  } = useSelectedTheme();

  const history = useHistory();

  const { setIsAddTaskDialogOpen } = useNewLayoutShared();
  const { actionableItems, setActionableItems } = useVerticalNav();

  const [anchorEl, setAnchorEl] = useState(null);
  const [checked, setChecked] = useState(
    actionableItems?.selectedIds?.includes(item?.id)
  );

  useEffect(() => {
    if (!actionableItems?.selectedIds?.includes(item?.id)) {
      setChecked(false);
    }
  }, [actionableItems?.selectedIds?.length]);

  // handleChange function to add or remove the item based on whether the checkbox is checked or not
  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setActionableItems((prevState) => ({
        ...prevState,
        selectedIds: [...new Set([...prevState.selectedIds, item.id])],
      }));
    } else {
      // Remove the id from the actionableItems array if unchecked
      setActionableItems((prevState) => ({
        ...prevState,
        selectedIds: prevState.selectedIds.filter((id) => id !== item.id),
      }));
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const rect = event.currentTarget.getBoundingClientRect();
    setAnchorEl({
      top: rect.top + 25,
      left: rect.left + 15,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const options = [
    {
      label: "Add task",
      icon: (
        <ListIcon width="16" height="16" fill={palette.foreground.quarterary} />
      ),
      handleOnclick: () => setIsAddTaskDialogOpen(true),
    },
  ];

  const handleCardClick = (event) => {
    event.stopPropagation();

    history.push(
      `/home/Properties-CRM/property-profile-overview?formType=${item.propertyTypeId}&id=${item.id}`
    );
  };

  return (
    <Box
      key={"Property-card" + item?.id}
      className={clsx(styles.propertyCard, {
        [styles.active]: item?.id === activeItem?.id,
      })}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        setActiveItem(item);
      }}
    >
      {actionableItems?.action && (
        <Box
          style={{
            width: "28px",
            cursor:
              actionableItems?.selectedIds?.length >= 10 &&
              !actionableItems?.selectedIds?.includes(item?.id) // Disable if length > 10 and not already selected
                ? "not-allowed"
                : "pointer",
          }}
          className={styles.checkbox}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <CustomCheckbox
            customIconClass={styles.checkboxBackground}
            checked={checked}
            onChange={handleChange}
            disabled={
              actionableItems?.selectedIds?.length >= 10 &&
              !actionableItems?.selectedIds?.includes(item?.id) // Disable if length > 10 and not already selected
            }
          />
        </Box>
      )}

      {item?.propertyStatus && item?.propertyStatus !== "N/A" && (
        <Box className={styles.status}>
          <PropertyPlanBadges
            PropertyPlanKey={
              item?.propertyStatus?.value || item?.propertyStatus
            }
          />
        </Box>
      )}

      <ImageCarousel imagesArray={item?.allpropertyImages} height={240} />

      <Box className={styles.details}>
        <Box className={styles.header}>
          <Box
            className={styles.flexRow}
            style={{ alignItems: "center", maxWidth: "87%" }}
          >
            <Box className={styles.unitName} onClick={handleCardClick}>
              {truncateString(item?.name, 50)}
            </Box>

            <CustomIconButton
              variant="text"
              size="xs"
              boxShadow="none"
              color="secondary"
              onClick={handleCardClick}
            >
              <ArrowUpRight
                width="20"
                height="20"
                fill={palette.button.secondaryGray_fg}
              />
            </CustomIconButton>

            <Box
              className={styles.menuWrapper}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <IconButton
                aria-controls="more-button"
                aria-haspopup="true"
                aria-label="more-button"
                id="more-button"
                disableRipple
                onClick={handleClick}
                className={styles.menuIcon}
              >
                <MoreVertIcon
                  width="20"
                  height="20"
                  fill={
                    anchorEl
                      ? palette.foreground.quarterary
                      : palette.foreground.quinary
                  }
                />
              </IconButton>
              <Menu
                id="more-button"
                PaperProps={{ elevation: 0 }}
                MenuListProps={{ className: styles.customMenuList }}
                anchorReference="anchorPosition"
                anchorPosition={
                  anchorEl
                    ? { top: anchorEl.top, left: anchorEl.left }
                    : undefined
                }
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                {options?.map(({ label, icon, handleOnclick }) => (
                  <MenuItem
                    disableRipple
                    key={label}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOnclick();
                      handleClose();
                    }}
                    className={styles.option}
                  >
                    {icon}
                    <span>{label}</span>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
          <Box className={styles.idSection}>
            <CustomCopyToClipboard
              data={item?.id} // copy
              childrenData={"#" + item?.id} // render
              copyDoneShowTime={1000}
            />

            <Box className={styles.propertyType}>{item?.propertyType}</Box>

            <CustomBadge
              label={item?.progress + "%" || "0%"}
              SizeVariant={"small"}
              BackgroundColor={
                palette.utility[item?.progress >= 50 ? "brand_50" : "error_50"]
              }
              BorderColor={
                palette.utility[
                  item?.progress >= 50 ? "brand_200" : "error_200"
                ]
              }
              Color={
                palette.utility[
                  item?.progress >= 50 ? "brand_700" : "error_700"
                ]
              }
            />
          </Box>
          <label className={styles.countryText}>
            {truncateString(
              `${item?.community?.lookupItemName || ""}${
                item?.sub_community?.lookupItemName
                  ? `, ${item?.sub_community?.lookupItemName}`
                  : ""
              }`,
              40
            )}
          </label>
          <PropertyRating rating={item?.propertyRating} />
        </Box>

        <Divider className={styles.divider} />

        <Box className={styles.footer}>
          <Box className={styles.flexRow}>
            <label className={clsx(styles.font400, styles.secondary)}>
              Owner:
            </label>
            <Box className={styles.owner}>
              <Box className={styles.containerItemAvatar}>
                {item?.property_owner && item?.property_owner !== "N/A" && (
                  <Avatar className={styles.avatarStyle}>
                    {item?.property_owner?.slice(0, 2).toUpperCase()}
                  </Avatar>
                )}
              </Box>

              <span style={{ fontWeight: 600 }} className={styles.primary}>
                {item?.property_owner}
              </span>
            </Box>
          </Box>
          <Box className={styles.flexRow2}>
            <label className={clsx(styles.font500, styles.secondary)}>
              Creation On:
            </label>
            <Box className={styles.dateInfo}>
              <label className={clsx(styles.font500, styles.primary)}>
                {moment(item?.createdOn).format("DD MMM YYYY")}
              </label>
              <span className={clsx(styles.font400, styles.tertiary)}>
                {truncateString(item?.createdBy, 10)}
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

PropertyCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default PropertyCard;
