import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    wrapperProprtyInfoCard: {
      display: "flex",
      flexDirection: "row",
      gap: "32px",
      padding: "24px",
    },
    containerLeftImage: {
      width: "432px",
      height: "662px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    containerEmptyImage: {
      width: "432px",
      height: "662px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.background.secondary_hover,
      borderRadius: "12px",
    },
    previewImage: {
      width: "100%",
      height: "100%",
      borderRadius: "12px",
    },
    previewImageDeveloper: {
      width: "100%",
      height: "100%",
    },
    containerInfoProp: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      gap: "32px",
    },
    divider: {
      marginTop: "8px",
    },
    dividerPropInfo: {
      marginBottom: "24px",
      marginTop: "8px",
    },
    containerPropInfoDetails: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    containerLeftDetails: {
      display: "flex",
      flexDirection: "column",
      gap: "12px",
    },
    containerInfoItem: {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
      alignItems: "start",
    },
    containerLocationValue: {
      display: "flex",
      flexDirection: "row",
      gap: "5px",
    },
    mainTitleItem: {
      fontSize: "18px",
      fontWeight: 600,
      color: theme.palette.text.secondary,
      lineHeight: "28px",
    },
    titleItem: {
      fontSize: "18px",
      fontWeight: 500,
      color: theme.palette.text.secondary,
      lineHeight: "24px",
    },
    titleValue: {
      fontSize: "16px",
      fontWeight: 600,
      color: theme.palette.text.secondary,
      lineHeight: "24px",
    },
    containerRightDetails: {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
    },
    containerAreaInformation: {
      display: "flex",
      flexDirection: "row",
      gap: "32px",
    },
    statusProp: {
      fontSize: "18px",
      fontWeight: 600,
      color: theme.palette.text.primary,
      lineHeight: "28px",
    },
    containerImageDeveloper: {
      width: "70px",
      height: "70px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    nameDevStyle: {
      fontSize: "16px",
      fontWeight: 600,
      color: theme.palette.text.secondary,
      lineHeight: "24px",
    },
    contactDevStyle: {
      fontSize: "16px",
      fontWeight: 500,
      color: theme.palette.text.secondary,
      lineHeight: "24px",
    },
    containerImages: {
      marginTop: "24px",
      display: "flex",
      flexDirection: "row",
      gap: "12px",
    },
    containerImageItem: {
      height: "120px",
      display: "flex",
      width: "186.5px",
    },
    percentage: {
      fontSize: "18px",
      fontWeight: 700,
      color: theme.palette.text.brand_tertiary,
    },
    text: {
      fontSize: "30px",
      fontWeight: 600,
      lineHeight: "38px",
      display: "flex",
      alignItems: "center",
    },
    containerImageLastItem: {
      position: "relative",
    },
    overlay: {
      position: "absolute",
      backgroundColor: `rgba(0, 0, 0, 0.50)`,
      backdropFilter: "blur(4px)",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: theme.palette.text.white,
      fontSize: "20px",
      fontWeight: 400,
      width: "186.5px",
      height: "120px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "12px",
    },
    containerContactDev: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  };
});
