
export const CoverPageStyle = `

/*CSS GENERETED BY ANIMA PPLUGIN */

:root {
--bg-gradient-light : linear-gradient(180deg, rgba(244, 244, 244, 0.6) 0%, rgb(237, 237, 237) 100%);
--bg-gradient-dark : linear-gradient(180deg, rgba(39, 41, 99, 0.60) 0%, #272963 100%);
--bg-logo-light : url("https://psi-crm.com/ProposalIcons/logo%20psi.svg");
--bg-logo-dark : url("https://psi-crm.com/ProposalIcons/logo%20psi%20dark.svg");
--text-clr-primary-light : #272963;
--text-clr-primary-dark : #ffffff;
--text-clr-secondary-light :rgb(34, 48, 62);
--text-clr-secondary-dark : #F7F3E2;
--bg-primary-light : #2D3E50;
--bg-primary-dark : #222469;
}


[data-mode="light"] {
  --bg-gradient: var(--bg-gradient-light);
  --bg-primary: var(--bg-primary-light);
  --bg-logo: var(--bg-logo-light);
  --text-clr-primary: var(--text-clr-primary-light);
  --text-clr-secondary: var(--text-clr-secondary-light);
}

[data-mode="dark"] {
  --bg-gradient: var(--bg-gradient-dark);
  --bg-primary: var(--bg-primary-dark);
  --bg-logo: var(--bg-logo-dark);
  --text-clr-primary: var(--text-clr-primary-dark);
  --text-clr-secondary: var(--text-clr-secondary-dark);
}

  .cover-x {
    width: 2480px;
    height: 1754px;
    background: var(--bg-gradient), url({{{CoverPage.coverImage.url}}});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 120px;
    padding-bottom: 0;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .cover-x .frame {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--3-spacing-spacing-xl);
    padding: 10px 0px;
  }
  
  .cover-x .div {
    {{#CoverPage.showLogo}}
    display: inline-flex;
    {{/CoverPage.showLogo}}
    {{^CoverPage.showLogo}}
    visibility: hidden;
    {{/CoverPage.showLogo}}
    flex-direction: column;
    align-items: center;
    gap: 16px;
    flex: 0 0 auto;
  }
  
  .cover-x .logo {
    width: 177.97px;
    height: 159.44px;
    background: var(--bg-logo);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  
  .cover-x .text-wrapper {
    width: fit-content;
    font-family: "DM Sans-Bold", Helvetica;
    font-weight: 700;
    color: var(--text-clr-primary);
    font-size: 36px;
    text-align: center;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .cover-x .text-wrapper-2 {
    width: fit-content;
    font-family: var(--header-2-font-family);
    font-weight: var(--header-2-font-weight);
    color: var(--text-clr-primary);
    font-size: var(--header-2-font-size);
    text-align: center;
    letter-spacing: var(--header-2-letter-spacing);
    line-height: var(--header-2-line-height);
    font-style: var(--header-2-font-style);
  }
  
  .cover-x .parkside-hills {
    width: fit-content;
    font-family: "Cormorant Garamond-Bold", Helvetica;
    font-weight: 700;
    color: var(--text-clr-primary);
    font-size: 210px;
    text-align: center;
    letter-spacing: -6.30px;
    line-height: 160px;
    text-transform: uppercase;
  }
  
  .cover-x .text-block {
    display: flex;
    flex-direction: column;
    width: 239px;
    align-items: center;

  }
  
  .cover-x .text-wrapper-3 {
    align-self: stretch;
    margin-top: -1.00px;
    font-family: "DM Sans-Regular", Helvetica;
    font-weight: 400;
    color: var(--text-clr-secondary);
    font-size: 42px;
    text-align: center;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .cover-x .text-wrapper-4 {
    align-self: stretch;
    font-family: "Cormorant", serif;
    font-weight: 500;
    color: var(--text-clr-secondary);
    font-size: 46px;
    text-align: center;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .cover-x .text-block-2 {
    display: flex;
    flex-direction: column;
    width: 239px;
    align-items: center;
  }
  
  .cover-x .emaar-logo {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
  
  .cover-x .page {
    width: 302px;
    height: 59.74px;
  }
  
  .cover-x .div-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24.68px;
    flex: 0 0 auto;
  }
  
  .cover-x .text-wrapper-5 {
    width: fit-content;
    margin-top: -0.77px;
    font-family: "DM Sans-Bold", Helvetica;
    font-weight: 700;
    color: var(--text-clr-primary);
    font-size: 46px;
    text-align: center;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .cover-x .frame-2 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-inline: 120px;
    margin-bottom: 40px;
  }

  .cover-x .avatar-label-group {
    display: flex;
    align-items: center;
    gap: 16px;
  }

    .cover-x .avatar {
      width: 100px;
      height: 100px;
      border-radius: 13512.16px;
      background-image: url({{{CoverPage.data.contactDetails.avatarImage}}});
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
      /*background-color: rgb(243 243 243);*/
    }

    .cover-x .contrast-border {
        width: 100px;
        height: 100px;
        border-radius: 13512.16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 40px;
        font-weight: 600;
        color: #344054;
    }

    .cover-x .text-and-supporting {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 0 0 auto;
        margin-right: -0.17px;
        gap: 16px;
    }
  

  .cover-x .text {
    width: fit-content;
    margin-top: -1.79px;
    font-family: var(--text-lg-semibold-font-family);
    font-weight: var(--text-lg-semibold-font-weight);
    color: var(--text-clr-secondary);
    font-size: var(--text-lg-semibold-font-size);
    letter-spacing: var(--text-lg-semibold-letter-spacing);
    line-height: var(--text-lg-semibold-line-height);
    font-style: var(--text-lg-semibold-font-style);
  }
  
  
  .cover-x .frame-3 {
    display: inline-flex;
    align-items: flex-start;
    gap: var(--3-spacing-spacing-2xl);
    flex: 0 0 auto;
  }
  
  .cover-x .frame-4 {
    display: inline-flex;
    align-items: center;
    gap: var(--3-spacing-spacing-md);
    flex: 0 0 auto;
  }
  
  .cover-x .frame-5 {
    display: inline-flex;
    align-items: center;
    gap: 20px;
    flex: 0 0 auto;
  }
  
  .cover-x .text-and-icon {
    display: inline-flex;
    align-items: center;
    gap: 16.67px;
    padding: 4.17px;
    flex: 0 0 auto;
    background-color: #eaecf0cc;
    border-radius: 25px;
  }
  
  .cover-x .img {
    width: 41.67px;
    height: 41.67px;
  }
  
  .cover-x .text-2 {
    width: fit-content;
    font-family: "DM Sans-Regular", Helvetica;
    font-weight: 400;
    color: var(--text-clr-secondary);
    font-size: 42px;
    text-align: center;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .cover-x .text-3 {
    width: fit-content;
    font-family: "Cormorant", serif;
    font-weight: 400;
    color: var(--text-clr-primary);
    font-size: 46px;
    text-align: center;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .cover-x .img-wrapper {
    display: inline-flex;
    align-items: center;
    gap: 16.67px;
    padding: 4.17px;
    flex: 0 0 auto;
    background-color: #eaecf0cc;
    border-radius: 41.67px;
  }
  
  .cover-x .frame-6 {
    display: flex;
    width: 2480px;
    align-items: center;
    justify-content: space-between;
    padding: 16px 120px;
    top: 1690px;
    left: 0;
    background-color: var(--bg-primary);
  }
  
  .cover-x .frame-7 {
    display: inline-flex;
    align-items: flex-start;
    gap: 8px;
    flex: 0 0 auto;
  }
  
  .cover-x .text-wrapper-6 {
    width: fit-content;
    margin-top: -1.00px;
    font-family: "Inter-SemiBold", Helvetica;
    font-weight: 600;
    color: #ffffff;
    font-size: 24px;
    letter-spacing: 0;
    line-height: normal;
  }
  
  .cover-x .frame-8 {
    display: inline-flex;
    align-items: center;
    gap: var(--3-spacing-spacing-6xl);
    flex: 0 0 auto;
  }
  
  .cover-x .img-2 {
    width: 32px;
    height: 32px;
  }
  
  .cover-x .text-wrapper-7 {
    width: fit-content;
    font-family: "Inter-SemiBold", Helvetica;
    font-weight: 600;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0;
    line-height: normal;
    white-space: nowrap;
  }
  
  .cover-x .frame-9 {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    flex: 0 0 auto;
  }
  
  .cover-x .frame-10 {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    flex: 0 0 auto;
  }


  
`