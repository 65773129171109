import React from "react";

//  styles
import useStyles from './styles'

function AgentCourseQualifications({}) {
    const styles =useStyles()

    return(
        <>AgentCourseQualifications</>
    )
}

export default AgentCourseQualifications