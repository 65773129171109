export const FloorPlanTemplate = `
<div class="FloorPlan_Container">
  {{#FloorPlan.showPage}}
  {{#FloorPlan.floorPlanImages}}
    <div class="FloorPlan">
      <p class="title">Floor Plan</p>
      <div class="floorPlan_image" style="background-image: url({{{url}}});"></div>
    </div>
  {{/FloorPlan.floorPlanImages}}
  {{/FloorPlan.showPage}}
</div>
`;
