import { showWarn } from "../../../../Helper";
import ReceiverTypes from "../Enums/ReceiverTypes";


const mapEmailAddress = ({ receiverUsers, receiverLeads }) => {
  let emailsList = [];
  receiverUsers.forEach((item) => emailsList.push({
    name: item?.fullName,
    email: item?.email, 
  }));
  receiverLeads.forEach((item) => emailsList.push({
    name: item?.lead?.contact_name?.name,
    email: item?.contactEmailAddress,
  }));
  return emailsList;
};

const checkIsEmailExist = (contact, type) => {
  let isExist = false;
  if (type == ReceiverTypes.Lead.value) {
      isExist = !!contact?.contactEmailAddress;
  } else if (type == ReceiverTypes.User.value) {
      isExist = !!contact?.email;
  }
  if (!isExist) showWarn("This person doesn't have an email address .");
  return isExist;
}

const filterContactsHasEmails = (contacts, type) => {
  return contacts.filter(item => checkIsEmailExist(item, type))
}

export {
    mapEmailAddress,
    checkIsEmailExist,
    filterContactsHasEmails,
}