import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    containerPropretyInfo: {
      display: "flex",
      flexDirection: "row",
      gap: "32px",
    },
    containerPropretyDetailTimeLine: {
      display: "flex",
      flexDirection: "row",
      gap: "24px",
      width: "100%",
    },
    containerSecCol: {
      display: "flex",
      flexDirection: "column",
      flex: "0.253",
    },
    containerFirstCol: {
      display: "flex",
      flexDirection: "column",
      gap: "32px",
      flex: "0.747",
    },
  };
});
