
//NOTE: ICON URL FORMAT : 
//https://psi-crm.com/ProposalIcons/{{lookupId}}.svg

export const LocationAndamenitiesTemplate = `
<div class="locationAndAmenities">
{{#LocationAndamenities.showPage}}
  <div class="locationAndAmenities_section_No1">
    <div class="cover-x">
      <div class="content">
        <div class="frame">
          {{#LocationAndamenities.showLocation}}
          <div class="div">
            <div class="frame-2">
              <div class="text-wrapper">Location</div>
              {{#LocationAndamenities.data.propertyLocation.locationText}}
                <div class="frame-3">
                  <img class="marker-pin" src="https://psi-crm.com/ProposalIcons/marker-pin-01-gray.svg" />
                  <div class="supporting-text">{{LocationAndamenities.data.propertyLocation.locationText}}</div>
                </div>
              {{/LocationAndamenities.data.propertyLocation.locationText}}
            </div>
            {{#LocationAndamenities.data.propertyLocation.mapImageUrl}}
              <img class="artboard" src={{{LocationAndamenities.data.propertyLocation.mapImageUrl}}} />
            {{/LocationAndamenities.data.propertyLocation.mapImageUrl}}
            {{#LocationAndamenities.data.propertyLocation.mapLink}}
             <div class="frame-4">
              <img class="link-external" src="https://psi-crm.com/ProposalIcons/link-external-01.svg" />
              <a href="{{LocationAndamenities.data.propertyLocation.mapLink}}" target="_blank" rel="noopener noreferrer" class="text-wrapper-2">
                Google Map link
              </a>
             </div>
            {{/LocationAndamenities.data.propertyLocation.mapLink}}
          </div>
          {{/LocationAndamenities.showLocation}}
          {{#LocationAndamenities.showNearBy}}
          <div class="frame-5">
            <div class="text-wrapper-3">Neighborhood/Nearby</div>
            <div class="row">
            {{#LocationAndamenities.selectednNearestLandmarks}}
              <div class="item">
                <div class="frame-6">
                  <div class="frame-6_1">
                    <img class="img" src="https://psi-crm.com/ProposalIcons/{{categoryId}}.svg" />
                  </div>
                  <div class="div-wrapper">
                    <div class="text">{{categoryName}}</div>
                  </div>
                </div>
                <span class="vector-1"></span>
                <div class="frame-7">
                {{#landmarks}}
                  <div class="frame-8">
                    <div class="text-2">{{name}}</div>
                  </div>
                {{/landmarks}}
                </div>
              </div>
              <span class="vector-2"></span>
            {{/LocationAndamenities.selectednNearestLandmarks}}
            </div>
          </div>
          {{/LocationAndamenities.showNearBy}}
        </div>
      </div>
    </div>
  </div>
  {{#LocationAndamenities.showFacilities}}
  <div class="locationAndAmenities_section_No2">
    <div class="cover-x">
      <div class="content">
        <div class="frame">
          <div class="div">
            <div class="div-wrapper">
              <div class="text-wrapper">Amenities and Facilities</div>
            </div>
            <div class="row-wrapper">
              <div class="row">
                <div class="col">
                  {{#LocationAndamenities.selectedFacilitiesAndAmenities}}
                  <div class="frame-4">
                    <img class="artboard img" src="https://psi-crm.com/ProposalIcons/{{id}}.svg" />
                    <div class="frame-3">
                      <div class="text">{{name}}</div>
                    </div>
                  </div>
                  {{/LocationAndamenities.selectedFacilitiesAndAmenities}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {{/LocationAndamenities.showFacilities}}
  {{/LocationAndamenities.showPage}}
</div>
`;
