
import React, { useState  , useEffect  } from 'react';
import { useTranslation } from 'react-i18next'; 
import { InputAdornment } from '@material-ui/core';
import { Inputs , PhonesComponent  } from '../../Components' ;
import { DisplayCaptionInAdvanceSearch } from "../../Helper";


export const TextItemComponent = ({
    translationPath,
    parentTranslationPath,
    item , 
    index,
    filtersWithValues , 
    setFiltersWithValues , 
    changeOperator , 
}) => {


    const { t } = useTranslation('Shared');
    const [helperText, setHelperText] = useState('');
    const [numberFields, setNumberFields] = useState(null);
    const [filterSpecialKeyList, setFilterSpecialKeyList ] = useState(['currency' , 'size' ,   'decimal']);


    useEffect(() => 
    {
     if(item && item.data && item.data.specialKey !== '' && filterSpecialKeyList && filterSpecialKeyList.some((x)=> x === item.data.specialKey))
       setNumberFields(item.data.specialKey) ; 
     
     else 
      setNumberFields(null) ; 

    }, [item]);



    return (
      <>
        {!numberFields &&
          (item.fieldType === "text" ||
            item.fieldType === "textField" ||
            item.fieldType === "textarea" ||
            (item &&
              item.data &&
              item.data.uiType &&
              item.data.uiType === "text")) && (
            <Inputs
              idRef={`form${index + 1}-${item.searchKey + index}$-${item.key}`}
              labelClasses=""
              key={`form${index + 1}-${item.searchKey + index}-${item.key}`}
              labelValue={DisplayCaptionInAdvanceSearch(item) || item.label}
              value={
                (filtersWithValues[index] &&
                  filtersWithValues[index].displayObj) ||
                ""
              }
              helperText={helperText}
              error={helperText !== ""}
              onInputChanged={(e) => {
                setHelperText("");
                if (
                  item &&
                  item.data &&
                  item.data.regExp &&
                  item.data.regExp !== ""
                ) {
                  const itemRegex = new RegExp(item.data.regExp);
                  if (!itemRegex.test(e.target.value))
                    setHelperText(item.data.errorMsg);
                  else setHelperText("");
                }
                const updatelist = [...filtersWithValues];
                updatelist[index] = {
                  ...updatelist[index],
                  displayValue: e.target.value,
                  displayObj: e.target.value,
                };
                setFiltersWithValues(updatelist);
                changeOperator(e.target.value);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          )}
        {((item &&
          item.data &&
          item.data.CommunicationType &&
          item.data.CommunicationType === "Phone") ||
          item.fieldType === "phone") && (
          <div className="form-item">
            <PhonesComponent
              idRef={item.id}
              labelValue={item.label}
              helperText={helperText !== ""}
              error={helperText !== ""}
              key={`form${index + 1}-${item.key + index}`}
              value={
                filtersWithValues[index] && filtersWithValues[index].displayObj
                  ? filtersWithValues[index].displayObj || ""
                  : item.data.defaultCountryCode
              }
              onInputChanged={(value) => {
                if (value.length > 14) return;
                if (value.length < 7)
                  setHelperText("please insert correct phone number");
                else setHelperText("");
                const updatelist = [...filtersWithValues];
                updatelist[index] = {
                  ...updatelist[index],
                  displayValue: value,
                  displayObj: value,
                };
                setFiltersWithValues([...updatelist]);
                changeOperator(value);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
        )}

        {item.fieldType === "communication" &&
          item &&
          item.data &&
          item.data.CommunicationType === "Email" && (
            <div className="form-item">
              <Inputs
                idRef={item.id}
                labelValue={item.label}
                value={
                  filtersWithValues[index] &&
                  filtersWithValues[index].displayObj
                    ? filtersWithValues[index].displayObj || ""
                    : ""
                }
                key={`form${index + 1}-${item.key + index}`}
                isWithError
                helperText={helperText !== ""}
                error={helperText !== ""}
                onInputChanged={(e) => {
                  if (
                    item &&
                    item.data &&
                    item.data.regExp &&
                    item.data.regExp !== ""
                  ) {
                    const itemRegex = new RegExp(item.data.regExp);
                    if (!itemRegex.test(e.target.value))
                      setHelperText(item.data.errorMsg);
                    else setHelperText("");
                  }
                  const updatelist = [...filtersWithValues];
                  updatelist[index] = {
                    ...updatelist[index],
                    displayValue: e.target.value,
                    displayObj: e.target.value,
                  };
                  setFiltersWithValues(updatelist);
                  changeOperator(e.target.value);
                }}
                translationPath={translationPath}
                parentTranslationPath={parentTranslationPath}
              />
            </div>
          )}

        {item.fieldType === "number" && (
          <div className="form-item">
            <Inputs
              type={"number"}
              min={item.textInputMin || 0}
              max={item.textInputMax}
              idRef={`form${index + 1}-${item.searchKey + index}$-${item.id}`}
              labelClasses=""
              key={`form${index + 1}-${item.searchKey + index}-${item.id}`}
              labelValue={item.label}
              value={
                (filtersWithValues[index] &&
                  filtersWithValues[index].displayObj) ||
                ""
              }
              helperText={helperText}
              error={helperText !== ""}
              onInputChanged={(e) => {
                setHelperText("");
                if (
                  item &&
                  item.data &&
                  item.data.regExp &&
                  item.data.regExp !== ""
                ) {
                  const itemRegex = new RegExp(item.data.regExp);
                  if (!itemRegex.test(e.target.value))
                    setHelperText(item.data.errorMsg);
                  else setHelperText("");
                }
                const updatelist = [...filtersWithValues];
                updatelist[index] = {
                  ...updatelist[index],
                  displayValue: e.target.value,
                  displayObj: e.target.value,
                };
                setFiltersWithValues(updatelist);
                changeOperator(e.target.value);
              }}
              translationPath={translationPath}
              parentTranslationPath={parentTranslationPath}
            />
          </div>
        )}
        {numberFields && (
          <Inputs
            isAttachedInput
            idRef={item.id}
            labelValue={item.label}
            value={
              filtersWithValues[index] && filtersWithValues[index].displayObj
                ? filtersWithValues[index].displayObj || ""
                : ""
            }
            key={`form${index + 1}-${item.key + index}`}
            isWithError
            helperText={helperText !== ""}
            error={helperText !== ""}
            type={"number"}
            endAdornment={
              item &&
              item.data &&
              item.data.specialKey === "currency" && (
                <InputAdornment position="start" className="px-2">
                  AED
                </InputAdornment>
              )
            }
            withNumberFormat
            onInputChanged={(e) => {
              setHelperText("");
              if (
                item &&
                item.data &&
                item.data.regExp &&
                item.data.regExp !== ""
              ) {
                const itemRegex = new RegExp(item.data.regExp);
                if (!itemRegex.test(e.target.value))
                  setHelperText(item.data.errorMsg);
                else setHelperText("");
              }
              const numberValue = Number.isNaN(e.target.value)
                ? null
                : e.target.value;
              const updatelist = [...filtersWithValues];
              updatelist[index] = {
                ...updatelist[index],
                displayValue: numberValue,
                displayObj: numberValue,
              };
              setFiltersWithValues(updatelist);
              changeOperator(numberValue);
            }}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
          />
        )}
      </>
    );
};
