export const PropertyDescriptionStyle = `

.property-description .main {
  display: flex;
  width: 2480px;
  height: 3508px;
  padding: 210px 120px 180px 120px;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(39, 41, 99, 1);
}

.property-description .main .content {
  width: 2180px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 80px;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
  border-radius: 50px;
}

.property-description .main .frame {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 48px;
  align-self: stretch;
  width: 100%;
  position: relative;
  flex: 0 0 auto;
}

.property-description .main .div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  width: 100%;
  position: relative;
  flex: 0 0 auto;
}

.property-description .main .frame-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  flex: 0 0 auto;
}

.property-description .main .frame-2 {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  position: relative;
  flex: 0 0 auto;
}

.property-description .main .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 50px;
  letter-spacing: 0;
  line-height: normal;
}

.property-description .main .text-wrapper-2 {
  position: relative;
  width: fit-content;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #ffffff;
  font-size: 102px;
  letter-spacing: 0;
  line-height: normal;
}

.property-description .main .frame-3 {
  display: flex;
  padding: 32px 40px;
  justify-content: center;
  align-items: center;
  gap:4px;
  border-radius: 16px;
  border: 2px solid #121456;
  background: #F75414;
  cursor: pointer;
}

.property-description .main .buttons-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  border-radius: 10.67px;
  overflow: hidden;
  box-shadow: 0px 2px 4px #1018280d;
}

.property-description .main .download {
  position: relative;
  width: 40px;
  height: 40px;
}

.property-description .main .text-padding {
  margin-top: 8px;
  margin-left: 8px;
}

.property-description .main .text {
  position: relative;
  width: fit-content;
  margin-top: -2.00px;
  font-family: "Inter-SemiBold", Helvetica;
  font-weight: 600;
  color: #ffffff;
  font-size: 36px;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
}

.property-description .main .rectangle {
  position: relative;
  width: 2100.39px;
  height: 24.75px;
  background-color: #f75414;
  border-radius: 50px 0px 0px 50px;
  flex: 0 0 auto;
}

.property-description .main .frame-4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 100px;
  align-self: stretch;
  width: 100%;
  position: relative;
  flex: 0 0 auto;
}

.property-description .main .frame-5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  align-self: stretch;
  width: 100%;
  position: relative;
  flex: 0 0 auto;
}

.property-description .main .text-wrapper-3 {
  font-family: "Inter-Bold", Helvetica;
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-weight: 700;
  color: #ffffff;
  font-size: 72px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.property-description .main .frame-6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  align-self: stretch;
  width: 100%;
  position: relative;
  flex: 0 0 auto;
}

.property-description .main .p {
  position: relative;
  align-self: stretch;
  margin-top: -1.00px;
  overflow-wrap: break-word;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 36px;
  letter-spacing: 0;
  line-height: normal;
}

.property-description .main .text-wrapper-4 {
  position: relative;
  align-self: stretch;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 36px;
  letter-spacing: 0;
  line-height: normal;
}

.property-description .main .text-wrapper-5 {
  font-family: "DM Sans-Bold", Helvetica;
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-weight: 700;
  color: #ffffff;
  font-size: 72px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.property-description .main .row {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.property-description .main .col {
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.property-description .main .frame-7 {
  display: inline-flex;
  align-items: center;
  gap: 32px;
  position: relative;
  flex: 0 0 auto;
}

.property-description .main .arrow-narrow-right {
  position: relative;
  width: 34px;
  height: 34px;
}

.property-description .main .frame-8 {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.property-description .main .text-2 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "DM Sans-9ptRegular", Helvetica;
  font-weight: 400;
  color: #ffffff99;
  font-size: 37px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.property-description .main .text-3 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "DM Sans-9ptRegular", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 37px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.property-description .main .text-4 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: #ffffff99;
  font-size: 37px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.property-description .main .text-5 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 37px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.property-description .main .col-2 {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.property-description .main .frame-9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 48px;
  position: relative;
  flex: 1;
  flex-grow: 1;
  background-color: #00000066;
  border-radius: 32px;
  backdrop-filter: blur(40px) brightness(100%);
  -webkit-backdrop-filter: blur(40px) brightness(100%);
}

.property-description .main .img {
  position: relative;
  width: 120px;
  height: 120px;
}

.property-description .main .frame-10 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.property-description .main .text-6 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: #ffffff99;
  font-size: 40px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.property-description .main .text-7 {
  position: relative;
  width: fit-content;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 40px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.property-description .main .frame-11 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}
`
