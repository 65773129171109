export const LocationAndamenitiesStyle = `

/*CSS GENERETED BY ANIMA PPLUGIN */

:root {
--bg-page-light : linear-gradient(180deg, #F4F4F4 0%, #EDEDED 100%);
--bg-page-dark : #272963;
--bg-card-light :rgba(255, 255, 255, 0.60);
--bg-card-dark : rgba(0, 0, 0, 0.40);
--bg-row-light : rgba(240, 240, 240, 1);
--bg-row-dark : #161B26;
--border-clr-btn-light : #272963;
--border-clr-btn-dark : transparent;
--text-primary-light : #2D3E50;
--text-primary-dark : #FFFFFF;
--text-secondery-light : #2D3E50;
--text-secondery-dark : #F7F3E2;
--icon-filter-light : unset;
--icon-filter-dark : grayscale(1) invert(1);
--map-filter-light :  contrast(1.1) saturate(0.05);
--map-filter-dark : grayscale(1) invert(1);
}


[data-mode="light"] {
  --bg-page: var(--bg-page-light);
  --bg-card: var(--bg-card-light);
  --bg-row: var(--bg-row-light);
  --border-clr-btn: var(--border-clr-btn-light);
  --text-primary: var(--text-primary-light);
  --text-secondery: var(--text-secondery-light);
  --icon-filter: var(--icon-filter-light);
  --map-filter: var(--map-filter-light);
}

[data-mode="dark"] {
  --bg-page: var(--bg-page-dark);
  --bg-card: var(--bg-card-dark);
  --bg-row: var(--bg-row-dark);
  --border-clr-btn: var(--border-clr-btn-dark);
  --text-primary: var(--text-primary-dark);
  --text-secondery: var(--text-secondery-dark);
  --icon-filter: var(--icon-filter-dark);
  --map-filter: var(--map-filter-dark);
}


.LocationAndamenities {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--bg-page);
  padding: 120px;
  gap: 120px;
}

/* first-part */

.first_part .artboard {
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
  object-fit: cover;
  border-radius: 50px;
  filter: var(--map-filter);
}

.first_part .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 120px;
  position: relative;
  border-radius: 50px;
}

.first_part .frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 120px;
  position: relative;
  align-self: stretch;
  width: 100%;
}

.first_part .div {
  display: flex;
  flex-direction: column;
  align-items:
  align-items: flex-start;
  gap: 50px;
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
}

.first_part .frame-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}
   .NearBy-card {
     width: 310px;
display: flex;
padding: 20px;
flex-direction: column;
align-items: center;
gap: 20px;
align-self: stretch;
  border-radius: 12px;
background: var(--bg-card);
backdrop-filter: blur(4px);
}

.NearBy-card img {
  width: 100px;
  height: 100px;
}

.divider {
  width: 100%;
  height: 1px;
  background: #E5E5EA;
}

.title{
  color: var(--text-primary);
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

 .near-by-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
 }

.near-by-list .list-text {
  color: var(--text-primary);
  font-family: Inter;
  font-size: 32px;
  font-weight: 400;
  line-height: normal;
}

.first_part .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "DM Sans-Bold", Helvetica;
  font-weight: 700;
  color: var(--text-primary);
  font-size: 72px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.first_part .frame-3 {
  display: inline-flex;
  align-items: flex-start;
  gap: 5px;
  position: relative;
  flex: 0 0 auto;
}

.first_part .marker-pin {
  position: relative;
  width: 48px;
  height: 48px;
  filter: var(--icon-filter);
}

.first_part .supporting-text {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Cormorant-Regular", Helvetica;
  font-weight: 400;
  color: var(--text-secondery);
  font-size: 40px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
}

.first_part .google-maps-mockup {
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
  background-color: var(--1-color-modes-colors-background-bg-primary);
}

.map-link {
  display: inline-flex;
  align-items: center;
  gap: 25px;
  padding: 0px 50px;
  position: relative;
  flex: 0 0 auto;
}

.map-link .link-external {
  position: relative;
  width: 54px;
  height: 54px;
}

.map-link .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter-Bold", Helvetica;
  font-weight: 700;
  color: rgba(59, 114, 217, 1);
  font-size: 42px;
  letter-spacing: 0;
  line-height: 62px;
  white-space: nowrap;
}

.first_part .map {
  position: absolute;
  width: 2240px;
  height: 703px;
  top: 0;
  left: 0;
  object-fit: cover;
}

.first_part .frame-4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.first_part .row {
  display: flex;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.first_part .item {
  display: flex;
  flex-direction: column;
  min-width: 252px;
  align-items: center;
  justify-content: center;
  gap: var(--3-spacing-spacing-4xl);
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.first_part .frame-5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--3-spacing-spacing-2xl);
  padding: var(--3-spacing-spacing-2xl);
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ffffff99;
  border-radius: 12px;
  backdrop-filter: blur(8px) brightness(100%);
  -webkit-backdrop-filter: blur(8px) brightness(100%);
}

.first_part .img {
  position: relative;
  width: 100px;
  height: 100px;
  filter: var(--icon-filter);
}

.first_part .div-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
}

.first_part .text {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "DM Sans-9ptRegular", Helvetica;
  font-weight: 400;
  color: var(--text-primary);
  font-size: 40px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.first_part .vector {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 2px;
}

.first_part .frame-6 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.first_part .frame-7 {
  display: flex;
  align-items: center;
  gap: 32px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.first_part .text-2 {
  flex: 1;
  position: relative;
  margin-top: -1.00px;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 32px;
  letter-spacing: 0;
  line-height: normal;
}

.first_part .text-3 {
  width: fit-content;
  position: relative;
  margin-top: -1.00px;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 32px;
  letter-spacing: 0;
  line-height: normal;
}

.first_part .frame-8 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: #ffffff99;
  border-radius: 12px;
  backdrop-filter: blur(8px) brightness(100%);
  -webkit-backdrop-filter: blur(8px) brightness(100%);
}

.first_part .frame-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 252px;
  align-items: center;
  justify-content: center;
  gap: 32px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}






/* second-part */

.second-part .content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 120px;
  border-radius: 50px;
}

.second-part .frame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 120px;
  position: relative;
  align-self: stretch;
  width: 100%;
}

.second-part .div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
}

.second-part .div-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.second-part .text-wrapper {
  position: relative;
  flex: 1;
  margin-top: -1.00px;
  font-family: "DM Sans-Bold", Helvetica;
  font-weight: 700;
  color: var(--text-primary);
  font-size: 72px;
  letter-spacing: 0;
  line-height: normal;
}

.second-part .row-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
}

.second-part .row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--3-spacing-spacing-4xl);
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
}

.second-part .col {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 32px 32px;
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
}

.second-part .frame-2 {
  display: flex;
  flex-direction: column;
  width: 422px;
  align-items: center;
  justify-content: center;
  gap: 27.08px;
  padding: 24px;
  position: relative;
  border-radius: 9999px;
}

.second-part .img {
  position: relative;
  width: 100px;
  height: 100px;
  filter: var(--icon-filter);
}

.second-part .frame-3 {
  color: var(--text-primary);
  text-align: center;
  font-size: 37px;
  font-weight: 400;
  line-height: normal;
}

.second-part .text {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "DM Sans-9ptRegular", Helvetica;
  font-weight: 400;
  color: var(--text-primary);
  font-size: 37px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.second-part .frame-4 {
  display: flex;
  flex-direction: column;
  width: 422px;
  align-items: center;
  justify-content: center;
  gap: 27.08px;
  padding: var(--3-spacing-spacing-3xl);
  position: relative;
  border-radius: var(--2-radius-radius-full);
}

.second-part .element {
  position: relative;
  width: 108.33px;
  height: 100px;
}

.second-part .frame-5 {
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.second-part .frame-6 {
  margin-left: -42.00px;
  margin-right: -42.00px;
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}

.second-part .frame-7 {
  margin-left: -36.00px;
  margin-right: -36.00px;
  display: inline-flex;
  align-items: center;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
}


`;
