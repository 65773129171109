import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogComponent,
  Spinner,
  AutocompleteComponent,
} from '../../../../../../Components';
import { showSuccess, showError } from '../../../../../../Helper';
import { UnitsOperationTypeEnum } from '../../../../../../Enums';
import { getUnits, UnitsAdvanceSearchTest } from '../../../../../../Services';
import { useSelector } from 'react-redux';
import { useDebouncedAction } from '../../../../../../Hooks/DebouncedAction';

export const AssignToUnitDialog = ({
  parentTranslationPath,
  translationPath,
  onClose,
  onSave,
  activeItem,
  isOpen,
  inquiryTypeId,
  allUnitsRelatedInquiry,
}) => {
  const { t } = useTranslation('inquires');
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [isLoadingUnits, setIsLoadingUnits] = useState(false);

  const [assignToUnit, setAssignToUnit] = useState(null);

  const [detailsUnitsList, setDetailsUnitsList] = useState({
    result: [],
    totalCount: 0,
  });

  const UnitMapper = (item) => {
    const unitJson = JSON.parse(item.unitJson).unit;
    if (!unitJson) {
      return;
    }
    const returnObj = {
      id: item.unitId,
      name: `${
        (unitJson.unit_type && unitJson?.unit_type.lookupItemName) || ''
      } ${unitJson.property_name ? unitJson.property_name.name : ''}`,
      refNo: unitJson.unit_ref_no,
    };
    return returnObj;
  };

  const getAllAvailableUnitsData = useCallback(
    async (search) => {
      setIsLoadingUnits(true);

      const OperationType =
        inquiryTypeId === 3 || inquiryTypeId === 1 ? 'Sale' : 'Lease';

      let criteria = {
        status: [{searchType: 1, value: "available"}]
      };

      if (search) criteria.UnitReferenceNo = [{ searchType: 2, value: search }];

      let body = {
        criteria,
        filterBy: 'createdOn',
        orderBy: 2,
        OperationType,
      };

      const res = await UnitsAdvanceSearchTest(
        { pageIndex: 0, pageSize: 10 },
        body
      );
      if (!(res && res.status && res.status !== 200)) {
        if (allUnitsRelatedInquiry && allUnitsRelatedInquiry.length > 0) {
          const newArray = res?.result?.filter(
            (item1) =>
              !allUnitsRelatedInquiry.some((item2) => item2.id === item1.unitId)
          );
          setDetailsUnitsList({
            result: newArray?.map((item) => UnitMapper(item)).filter(Boolean),
            totalCount: newArray?.length || 0,
          });
        } else {
          setDetailsUnitsList({
            result: res.result?.map((item) => UnitMapper(item)).filter(Boolean),
            totalCount: res?.totalCount || 0,
          });
        }
      } else {
        setDetailsUnitsList({
          result: [],
          totalCount: 0,
        });
      }
      setIsLoadingUnits(false);
    },
    [inquiryTypeId, loginResponse]
  );

  const getAllAvailableUnitsDataDebounced = useDebouncedAction(getAllAvailableUnitsData);
  useEffect(() => {
    if (loginResponse) getAllAvailableUnitsData();
  }, [getAllAvailableUnitsData]);

  return (
    <DialogComponent
      titleText='assign-inquiry-to-unit'
      saveText={t(`${translationPath}save`)}
      SmothMove
      saveType='button'
      maxWidth='sm'
      dialogContent={
        <div className='d-flex-column'>
          <Spinner isActive={isLoadingUnits} isAbsolute />
          <div className='w-100'>
            <AutocompleteComponent
              idRef='assignInquiryToUnitRef'
              data={detailsUnitsList.result ?? []}
              multiple={false}
              displayLabel={(option) => (option?.name) || ''}
              chipsLabel={(option) => (option?.name) || ''}
              renderOption={(option) => (
                <div className='d-flex-column'>
                  <div className='d-flex-v-center-h-between w-100 texts-truncate'>
                    {option.name}
                  </div>
                  <span className='c-gray-secondary'> {option.refNo} </span>
                </div>
              )}
              inputPlaceholder={'search-by-ref-no'}
              withoutSearchButton
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputKeyUp={(e) => {
                getAllAvailableUnitsDataDebounced(e.target.value);
              }}
              onChange={(e, newValue) => {
                setAssignToUnit(newValue);
              }}
              isLoading={isLoadingUnits}
              withLoader
              filterOptions={(options) => options}
            />
          </div>
        </div>
      }
      saveClasses='btns theme-solid bg-danger w-100 mx-2 mb-2'
      isOpen={isOpen}
      onSaveClicked={() => onSave(assignToUnit)}
      onCloseClicked={() => onClose()}
      onCancelClicked={() => onClose()}
      parentTranslationPath={parentTranslationPath}
      translationPath={translationPath}
    />
  );
};
