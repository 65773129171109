export const UnitsDetailsStyle = `

/*=================== First section css=================*/

.Unit_details{
  background: linear-gradient(180deg, #F4F4F4 0%, #EDEDED 100%);
}
  
.Unit_details_section_No1 .cover-x {
  width: 2480px;
  height: 1754px;
  background: linear-gradient(180deg, #F4F4F4 0%, #EDEDED 100%);
}

.Unit_details_section_No1 .cover-x .content {
  display: flex;
  flex-direction: column;
  width: 2240px;
  align-items: flex-start;
  gap: 90px;
  position: relative;
  top: 121px;
  left: 120px;
}

.Unit_details_section_No1 .cover-x .row {
  display: flex;
  align-items: flex-start;
  gap: 49px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.Unit_details_section_No1 .cover-x .frame {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  flex: 0 0 auto;
}

.Unit_details_section_No1 .cover-x .div-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  position: relative;
  flex: 0 0 auto;
}

.Unit_details_section_No1 .cover-x .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter", Helvetica;
  font-weight: 700;
  color: var(--x-2d-3e-50);
  font-size: 72px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
}

.Unit_details_section_No1 .cover-x .div {
  display: flex;
  align-items: flex-start;
  gap: 90px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.Unit_details_section_No1 .cover-x .col {
  display: flex;
  flex-direction: column;
  height: 1394px;
  align-items: flex-start;
  gap: 70px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.Unit_details_section_No1 .cover-x .group {
  position: relative;
  width: 1075px;
  height: 1394px;
  background-image: url({{{UnitsDetails.unitImage.url}}});
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 50px;
}

.Unit_details_section_No1 .cover-x .frame-wrapper {
  display: flex;
  flex-direction: column;
  width: 698px;
  height: 165px;
  align-items: center;
  justify-content: space-around;
  padding: 32px;
  position: absolute;
  top: 48px;
  left: 188px;
  background-color: #ffffff99;
  border-radius: 12px;
  backdrop-filter: blur(8px) brightness(100%);
  -webkit-backdrop-filter: blur(8px) brightness(100%);
}

.Unit_details_section_No1 .cover-x .frame-2 {
  display: flex;
  align-items: flex-end;
  gap: 30px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  margin-top: -8.00px;
  margin-bottom: -8.00px;
  border: unset !important;
}

.Unit_details_section_No1 .cover-x .frame-3 {
  display: inline-flex;
  align-items: flex-end;
  gap: 30px;
  position: relative;
  flex: 0 0 auto;
  margin-right: -16.00px;
}

.Unit_details_section_No1 .cover-x .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter", Helvetica;
  font-weight: 700;
  color: var(--collection-1-primary-color);
  font-size: 97px;
  letter-spacing: 0;
  line-height: normal;
}

.Unit_details_section_No1 .cover-x .text-wrapper-3 {
  position: relative;
  width: fit-content;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: var(--collection-1-primary-color);
  font-size: 65px;
  letter-spacing: 0;
  line-height: normal;
}

.Unit_details_section_No1 .cover-x .flexcontainer-wrapper {
  display: flex;
  width: 1011px;
  height: 264px;
  align-items: center;
  justify-content: flex-start;
  padding: 32px;
  position: absolute;
  top: 1082px;
  left: 32px;
  background-color: #ffffff99;
  border-radius: 12px;
  backdrop-filter: blur(8px) brightness(100%);
  -webkit-backdrop-filter: blur(8px) brightness(100%);
}

.Unit_details_section_No1 .cover-x .flexcontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 90%;
}

.Unit_details_section_No1 .cover-x .text {
  position: relative;
  align-self: stretch;
  font-family: "Inter-Bold", Helvetica;
  font-weight: 400;
  color: #000000;
  font-size: 30px;
  letter-spacing: 0;
  line-height: normal;
}

.Unit_details_section_No1 .cover-x .span {
  font-weight: 700;
}

.Unit_details_section_No1 .cover-x .text-wrapper-4 {
  font-family: "Inter", Helvetica;
  font-weight: 500;
}

.Unit_details_section_No1 .cover-x .col-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--3-spacing-spacing-8xl);
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
}

.Unit_details_section_No1 .cover-x .text-wrapper-5 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
  color: #00000080;
  font-size: 60px;
  letter-spacing: 0;
  line-height: normal;
}

.Unit_details_section_No1 .cover-x .text-wrapper-6 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter-Bold", Helvetica;
  font-weight: 700;
  color: #000000;
  font-size: 72px;
  letter-spacing: 0;
  line-height: normal;
}

.Unit_details_section_No1 .cover-x .frame-4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
  position: relative;
  flex: 1;
  align-self: stretch;
  width: 100%;
  flex-grow: 1;
}

.Unit_details_section_No1 .cover-x .info_box_wrapper,
.Unit_details_section_No2 .info_box_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

.Unit_details_section_No1 .info_box,
.Unit_details_section_No2 .info_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  padding: var(--3-spacing-spacing-7xl);
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
  background-color: var(--collection-1-primary-color);
  border-radius: 50px;
  height: 464px;
  width: 464px;
}

.Unit_details_section_No1 .info_box .frame-7,
.Unit_details_section_No2 .info_box .frame-7 {
  display: flex;
  align-items: flex-end;
  gap: 30px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  flex-wrap: wrap;
}

.Unit_details_section_No1 .cover-x .img {
  position: relative;
  width: 120px;
  height: 120px;
}

.Unit_details_section_No1 .info_box .text-wrapper-7, 
.Unit_details_section_No2 .info_box .text-wrapper-7 {
  position: relative;
  width: fit-content;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: var(--collection-1-white);
  font-size: 65px;
  letter-spacing: 0;
  line-height: normal;
}

.Unit_details_section_No1 .info_box .text-wrapper-8,
.Unit_details_section_No2 .info_box .text-wrapper-8 {
  position: relative;
  width: fit-content;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 300;
  color: var(--collection-1-white);
  font-size: 60px;
  letter-spacing: 0;
  line-height: normal;
}

.Unit_details_section_No1 .cover-x .frame-8 {
  display: inline-flex;
  align-items: flex-end;
  gap: 10px;
  position: relative;
  flex: 0 0 auto;
}

.Unit_details_section_No1 .cover-x .text-wrapper-9 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: var(--collection-1-white);
  font-size: 65px;
  letter-spacing: 0;
  line-height: normal;
}

.Unit_details_section_No1 .cover-x .artboard {
  position: relative;
  width: 100px;
  height: 100px;
}


/*=================== Second section css=================*/

.Unit_details_section_No2 .main {
  display: flex;
  align-items: center;
  padding: 120px;
  position: relative;
}

.Unit_details_section_No2 .main .row {
  display: flex;
  align-items: center;
  gap: 90px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.Unit_details_section_No2 .main .frame {
  flex-direction: column;
  width: 1075px;
  align-items: flex-start;
  gap: 80.5px;
  display: flex;
  position: relative;
  align-self: stretch;
}

.Unit_details_section_No2 .main .div {
  height: 116px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
}

.Unit_details_section_No2 .main .frame-wrapper {
  flex-direction: column;
  width: 500px;
  align-items: center;
  justify-content: center;
  padding: var(--3-spacing-spacing-xl) 32px var(--3-spacing-spacing-xl) 32px;
  background-color: #272963;
  border-color: #272963;
  border-radius: 12px 0px 0px 12px;
  backdrop-filter: blur(8px) brightness(100%);
  -webkit-backdrop-filter: blur(8px) brightness(100%);
  display: flex;
  position: relative;
  align-self: stretch;
}

.Unit_details_section_No2 .main .div-wrapper {
  display: inline-flex;
  align-items: flex-end;
  gap: 30px;
  position: relative;
  flex: 0 0 auto;
}

.Unit_details_section_No2 .main .text-wrapper, .financial-text {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: var(--ffffff);
  font-size: 52px;
  letter-spacing: -2.08px;
  line-height: normal;
}

.Unit_details_section_No2 .main .frame-2 {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--3-spacing-spacing-xl) 32px var(--3-spacing-spacing-xl) 32px;
  flex: 1;
  flex-grow: 1;
  background-color: #272963;
  border-color: #272963;
  border-radius: 0px 12px 12px 0px;
  border-top-width: 2px;
  border-top-style: solid;
  border-right-width: 2px;
  border-right-style: solid;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  backdrop-filter: blur(8px) brightness(100%);
  -webkit-backdrop-filter: blur(8px) brightness(100%);
  display: flex;
  position: relative;
  align-self: stretch;
}

.Unit_details_section_No2 .main .frame-3 {
  display: inline-flex;
  align-items: center;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
}

.Unit_details_section_No2 .main .text-wrapper-2, .financial-text-2 {
  color: var(--ffffff);
  font-size: 60px;
  letter-spacing: 0;
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter-SemiBold", Helvetica;
  font-weight: 600;
  line-height: normal;
}

.Unit_details_section_No2 .main .text-wrapper-3, .financial-text-3 {
  color: var(--ffffff);
  letter-spacing: 0;
  position: relative;
  width: fit-content;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  font-size: 52px;
  line-height: normal;
}

.Unit_details_section_No2 .main .frame-4 {
  display: flex;
  flex-direction: column;
  width: 500px;
  align-items: center;
  justify-content: center;
  padding: var(--3-spacing-spacing-xl) 32px var(--3-spacing-spacing-xl) 32px;
  position: relative;
  align-self: stretch;
  background-color: #27296329;
  border-radius: 12px 0px 0px 12px;
  border: 2px solid;
  border-color: #272963;
  backdrop-filter: blur(8px) brightness(100%);
  -webkit-backdrop-filter: blur(8px) brightness(100%);
}

.Unit_details_section_No2 .main .text-wrapper-4, .financial-text-4 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: var(--collection-1-primary-color);
  font-size: 52px;
  letter-spacing: -2.08px;
  line-height: normal;
}

.Unit_details_section_No2 .main .frame-5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--3-spacing-spacing-xl) 32px var(--3-spacing-spacing-xl) 32px;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
  background-color: #2729631a;
  border-radius: 0px 12px 12px 0px;
  border-top-width: 2px;
  border-top-style: solid;
  border-right-width: 2px;
  border-right-style: solid;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-color: #272963;
  backdrop-filter: blur(8px) brightness(100%);
  -webkit-backdrop-filter: blur(8px) brightness(100%);
}

.Unit_details_section_No2 .main .frame-66 {
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-self: stretch;
}

.Unit_details_section_No2 .main .text-wrapper-5, .financial-text-5 {
  color: var(--collection-1-primary-color);
  font-size: 60px;
  letter-spacing: -2.08px;
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter-SemiBold", Helvetica;
  font-weight: 600;
  line-height: normal;
}

.Unit_details_section_No2 .main .col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  flex: 1;
  flex-grow: 1;
}

.Unit_details_section_No2 .main .frame-8 {
  height: 464px;
  align-items: flex-start;
  gap: 50px;
  width: 100%;
  display: flex;
  position: relative;
  align-self: stretch;
}

.Unit_details_section_No2 .main .frame-9 {
  display: flex;
  flex-direction: column;
  max-width: 512px;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  padding: 35px 55px;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
  background-color: var(--collection-1-primary-color);
  border-radius: 50px;
}

.Unit_details_section_No2 .main .frame-10 {
  align-items: flex-end;
  gap: 30px;
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  position: relative;
  align-self: stretch;
}

.Unit_details_section_No2 .main .couch {
  position: relative;
  width: 120px;
  height: 120px;
}

.Unit_details_section_No2 .main .frame-11 {
  display: inline-flex;
  align-items: flex-end;
  gap: 10px;
  position: relative;
  flex: 0 1 auto;
}

.Unit_details_section_No2 .main .text-wrapper-6, .financial-text-6 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: var(--collection-1-white);
  font-size: 65px;
  letter-spacing: 0;
  line-height: normal;
}

.Unit_details_section_No2 .main .frame-12 {
  display: flex;
  flex-direction: column;
  max-width: 512px;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  padding: 35px 55px;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
  background-color: var(--collection-1-primary-color);
  border-radius: 50px;
}

.Unit_details_section_No2 .main .img {
  position: relative;
  width: 137px;
  height: 137px;
}

.Unit_details_section_No2 .main .element {
  position: relative;
  width: 137px;
  height: 139.22px;
}

.financial-text, .financial-text-2, .financial-text-3,
.financial-text-4, .financial-text-5, .financial-text-6 {
  color: unset !important;
}

.frame-4, .frame-5, .frame-2, .frame-wrapper {
  background-color: unset !important;
}

.frame-2, .frame-wrapper {
  border: 2px solid;
  border-color: #272963;
  border-right : 0px solid;
}

.frame-4, .frame-5 {
  border: 2px solid;
  border-color: #272963;
  border-left : 0px solid;
}

.Unit_details_section_No2 .main .div:nth-child(odd) {
  background-color: #272963 !important;
  color: white !important;
  border-radius: 16px;
}

.Unit_details_section_No2 .main .div:nth-child(even) {
  background-color: #2729631a !important;
  color: #272963 !important;
  border-radius: 16px;
}

.price-wrapper{
  display: flex;
  min-width: 698px;
  width: fit-content;
  position: relative;
  top: 48px;
  margin: auto;height: 165px;
  padding: 32px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.60);
  backdrop-filter: blur(4px);
}

.price-wrapper frame-2 {
  border: none;
}

`

