import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  GlobalTranslate,
  showSuccess,
  showError,
  GlobalHistory,
} from "../../../Helper";
import { config } from "../../../config";
import {
  SignInForm,
  UserVerificationForm,
  ResetPasswordRequestForm,
  OTPVerificationForm,
  ChangePasswordForm,
  LoginFormsEnum
} from "./LoginSteps";

const translationPath = "";
function LoginView({ loginResponse }) {
  const parentTranslationPath = "LoginView";
  const { t } = useTranslation(parentTranslationPath);

  const [loginDto, setLoginDto] = useState({
    identity: "",
    password: "",
    rememberMe: false,
    organizationId: config.organizationsId,
    applicationId: config.applicationId,
  });
  const [activeLoginForm, setActiveLoginForm] = useState(1);
  const [userVerificationInfo, setUserVerificationInfo] = useState({});


  // eslint-disable-next-line prefer-destructuring
  useEffect(() => {
    if (loginResponse) {
      if (loginResponse.token) {
        localStorage.setItem("session", JSON.stringify(loginResponse));
        if (loginResponse.isComplex === true) {
          GlobalHistory.push("/home");
        } else if (loginResponse.isComplex === false) {
          setActiveLoginForm(LoginFormsEnum.ChangePassword.key);
        }
      } else if (loginResponse?.Message) {
        showError(t(`${loginResponse.Message}`));
      } else {
        showError(GlobalTranslate.t("Shared:login-failed-msg"));
      }
    }
    return () => { };
  }, [loginResponse]);


  return (
    <div className="login-view">
      <div class="blurrd-bg">
        <div className="login-view-wrapper login-content-wrapper">
          <div className="login-form-wrapper d-flex-center">
            <div className="login-content">
              <div className="d-flex-column-center  px-4">
                {activeLoginForm === LoginFormsEnum.SignIn.key ? (
                  <SignInForm
                    loginDto={loginDto}
                    setLoginDto={setLoginDto}
                    setActiveLoginForm={setActiveLoginForm}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                ) : null}

                {activeLoginForm === LoginFormsEnum.ResetPasswordRequest.key ? (
                  <ResetPasswordRequestForm
                    setActiveLoginForm={setActiveLoginForm}
                    setUserVerificationInfo={setUserVerificationInfo}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                ) : null}
                {activeLoginForm === LoginFormsEnum.UserVerification.key ? (
                  <UserVerificationForm
                    setActiveLoginForm={setActiveLoginForm}
                    userVerificationInfo={userVerificationInfo}
                    setUserVerificationInfo={setUserVerificationInfo}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                ) : null}
                {activeLoginForm === LoginFormsEnum.OTPVerification.key ? (
                  <OTPVerificationForm
                    setActiveLoginForm={setActiveLoginForm}
                    userVerificationInfo={userVerificationInfo}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                ) : null}
                {activeLoginForm === LoginFormsEnum.ChangePassword.key ? (
                  <ChangePasswordForm
                    setActiveLoginForm={setActiveLoginForm}
                    userVerificationInfo={userVerificationInfo}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                  />
                ) : null}
              </div>
            </div>
            <div className="login-footer">
              <p>Xeleration © 2024</p>
            </div>
          </div>
          <div className="slide-show-wrapper"></div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  const {
    login: { loginResponse },
  } = state;
  return {
    loginResponse,
  };
};
LoginView.propTypes = {
  loginResponse: PropTypes.shape(undefined),
};
LoginView.defaultProps = {
  loginResponse: null,
};
const store = connect(mapStateToProps)(LoginView);

export { store as LoginView };
