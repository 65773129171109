import React from "react";

function ChevronUp({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M11.64 8.068c-.155.053-.604.485-3.293 3.167-1.777 1.773-3.159 3.182-3.22 3.285-.091.152-.107.226-.107.48 0 .257.016.327.113.492.13.222.369.409.603.473.202.054.53.024.709-.066.074-.037 1.355-1.285 2.845-2.773L12 10.421l2.71 2.705c1.49 1.488 2.771 2.736 2.845 2.773.455.229 1.046.045 1.312-.407.097-.165.113-.235.113-.492 0-.254-.016-.328-.107-.48-.061-.103-1.442-1.512-3.22-3.285-2.836-2.828-3.131-3.11-3.31-3.17a1.053 1.053 0 0 0-.703.003"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default ChevronUp;
