export const UnitImagesStyle = `
.unitImages_wrapper {
  padding: 125px;
  display: flex;
  flex-direction: column;
  gap: 120px;
}

[data-isMultiImage="true"] {
  display: flex !important;
  flex-direction: column;
  flex-grow: 1;
  gap: 50px;
}

.unitImages_section {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.title {
  color: #000;
  font-size: 72px;
  font-weight: 700;
  line-height: normal;
}

.images-container {
  display: flex;
  height: 1350px;
  width: 100%;
  margin: auto;
  gap: 50px;
}

.images-sidebar {
  flex-grow: 1;
  min-height: 100%;
}

.images-content {
  display: none;
}

.images-content-section {
  flex-grow: 1;
}

.images-sidebar, .images-content-section-1, .images-content-section-2 {
  border-radius: 50px;
}
  
.select_all_container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: 1350px;
  width: 100%;
  margin: auto;
  gap: 50px;
}

.interior_section [data-isMultiImage="true"]{
{{^UnitImages.interiorImages.2.url}}
  flex-direction: row !important;
{{/UnitImages.interiorImages.2.url}}
}
`
