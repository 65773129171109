import React from "react";

function MessageChatSquare({ fill, ...restProps }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fill ?? ""}
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M4.92 1.041c-.769.051-1.248.164-1.74.41A3.776 3.776 0 0 0 1.124 4.16C1.019 4.698 1 5.417 1 8.785c0 3.855.001 3.88.237 4.564.23.67.684 1.301 1.281 1.781.533.428.962.616 1.929.845l.107.025.016 1.29c.014 1.219.02 1.304.105 1.538.211.577.76.944 1.413.944.234 0 .317-.022.582-.151.286-.139.387-.227 1.314-1.16l1.005-1.009.027.447c.058.971.316 1.592.9 2.164.271.265.626.494.961.62.509.192.673.205 2.783.232 1.862.023 2.01.03 2.14.101.077.042.719.479 1.428.972.736.512 1.367.922 1.474.959a1.01 1.01 0 0 0 1.081-.328c.183-.239.213-.39.215-1.064.002-.697-.009-.672.302-.675.262-.003.698-.127 1.04-.297.785-.389 1.305-1.023 1.551-1.893.087-.306.088-.348.088-3.17.001-2.648-.005-2.884-.073-3.18-.1-.428-.221-.719-.429-1.025-.505-.745-1.262-1.184-2.174-1.261L20 10.028V7.644c0-2.769-.03-3.25-.245-3.964-.331-1.099-1.29-2.058-2.435-2.434-.67-.22-.57-.217-6.48-.227-3.003-.005-5.667.005-5.92.022m11.572 2.05c.15.036.357.107.46.157.242.118.682.558.8.8.209.43.217.535.237 3.342l.019 2.61h-2.874c-3.496 0-3.87.027-4.463.321a2.892 2.892 0 0 0-1.575 1.99c-.043.187-.069.575-.084 1.273l-.021 1.004-1.214 1.236-1.213 1.236-.002-.628c-.001-.345-.022-.723-.046-.84-.169-.807-.85-1.419-1.796-1.611-.494-.101-.81-.264-1.121-.577a1.927 1.927 0 0 1-.559-1.064c-.035-.209-.043-1.208-.03-3.92.015-3.173.026-3.675.082-3.912.084-.355.206-.594.428-.841.363-.405.761-.576 1.46-.628.22-.016 2.839-.026 5.82-.021 4.872.007 5.448.014 5.692.073m3.882 8.984c.211.07.488.356.554.571.041.136.052.757.052 2.854v2.68l-.118.214c-.239.431-.504.521-1.553.524-.365.001-.553.019-.658.063a1 1 0 0 0-.64.856l-.022.238-.552-.376c-.647-.439-.845-.545-1.243-.665-.281-.084-.385-.088-2.354-.111l-2.06-.023-.23-.113c-.331-.163-.462-.366-.511-.797-.05-.433-.05-4.632 0-5.067.064-.557.311-.82.827-.882.151-.018 2.092-.029 4.314-.025 3.161.006 4.074.019 4.194.059"
        fill-rule="evenodd"
        fill={fill ?? ""}
      />
    </svg>
  );
}

export default MessageChatSquare;
