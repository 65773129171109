import React, {
 useCallback, useEffect, useReducer, useState, useRef
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Joi from 'joi';
import { ButtonBase } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DatePickerComponent, Inputs, Spinner } from '../../../../../../../../Components';
import {
  GetLeaseTransactionDetails,
  UpdateLeaseUnitTransaction
} from '../../../../../../../../Services';
import {AgentTypesEnum} from '../../../../../../../../Enums';
import {bottomBoxComponentUpdate,
  showSuccess,
  showError,
  getErrorByName,
  GlobalHistory,
  GetParams
} from '../../../../../../../../Helper';

export const TransactionsDetails = ({
  unitTransactionId,
  parentTranslationPath,
  translationPath,
}) => {
  const { t } = useTranslation(parentTranslationPath, 'Shared');
  const isEditForm = useRef(+GetParams('actionType') === 1);
  const reducer = useCallback((state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useReducer(reducer, {
    cityName: null,
    propertyName: null,
    unitReferenceNo: null,
    leadReferenceNo: null,
    claimDate: null,
    exteranalAgencyName: null,
    splitComm: null,
    rentPerYear: null,
    totalAgencyFee: null,
    startDate: null,
    breakLeaseDate: null,
    netAmountTenant: 0,
    netAmountLandLord: 0,
    externalAgencyFeeTenant: 0,
    externalAgencyFeeLandLord: 0,
    agencyFeeTenant: 0,
    agencyFee: 0,
    agentCommissions: [],
    transactionEntryDate : null,
    invoiceNotes: null,
    unitType : null , 
    completionDate : null  ,
    transactedBy : null , 
    approvedBy : null , 
    ownerName : null , 
    transactionCreatedDate : null , 
    masterDeveloper : null ,
  });

  const schema = Joi.object({
    startDate: Joi.date()
      .required()
      .messages({
        'date.base': t(`${translationPath}Contract Start Date Is Required`),
      }) || Joi.any() || Joi.any(),
      breakLeaseDate: Joi.date()
      .required()
      .messages({
        'date.base': t(`${translationPath}Contract End Date Is Required`),
      }) || Joi.any() || Joi.any(),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const getLeasingTransactionDetails = useCallback(async () => {
    setIsLoading(true);
    // const res = await GetLeasingTransactionDetails(unitTransactionId);
    const res = await GetLeaseTransactionDetails(unitTransactionId);
    if (!((res && res.data && res.data.ErrorId) || !res)) setState({ id: 'edit', value: res });
    setIsLoading(false);
  }, [unitTransactionId]);

  const updateLeaseUnitTransaction = useCallback(async () => {

    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }
    setIsLoading(true);
    const mappedAgentCommissions = state.agentCommissions && state.agentCommissions.map(item => ({
      ...item,
      agentType: AgentTypesEnum[item.agentType] && AgentTypesEnum[item.agentType].key,
    }))
    const body = {
      rentPerYear: state.rentPerYear,
      contractStartDate: state.startDate,
      contractEndDate: state.breakLeaseDate,
      claimDate: state.claimDate,
      externalAgencyFeeTenant: state.externalAgencyFeeTenant,
      agencyFeeTenant: state.agencyFeeTenant,
      externalAgencyFeeLandLord: state.externalAgencyFeeLandLord,
      agencyFee: state.agencyFee,
      agentCommissions: mappedAgentCommissions,
      transactionEntryDate: state.transactionEntryDate,
      unitType: state.unitType,
      unitSaleType: state.unitSaleType,
      completionDate: state.completionDate,
      transactedBy: state.transactedBy,
      approvedBy: state.approvedBy,
      discount: state.discount,
      ownerName: null,
      transactionCreatedDate: null,
      masterDeveloper: null,
    };
    const res = await UpdateLeaseUnitTransaction(unitTransactionId, body);
    if (!(res && res.status && res.status !== 200)) {
      reloadDetailsData();
      showSuccess(`Transaction Updated Successfully`);
    } else {
      showError('Transaction Update Failed');
    }
    setIsLoading(false);
  }, [unitTransactionId, state]);

  const reloadDetailsData = () => {
    getLeasingTransactionDetails();
  }

  const saveHandler = () => {
    updateLeaseUnitTransaction();
  }

  const cancelHandler = () => {
    GlobalHistory.push(`/home/leasing-transactions/view`);
  }
  useEffect(() => {
    if (unitTransactionId) getLeasingTransactionDetails();
  }, [getLeasingTransactionDetails, unitTransactionId]);
  useEffect(() => {
    bottomBoxComponentUpdate(
      <div className='d-flex-v-center-h-end flex-wrap'>
        <ButtonBase className='btns theme-transparent mb-2' onClick={cancelHandler}>
          <span>{t('Shared:cancel')}</span>
        </ButtonBase>
        <ButtonBase className='btns theme-solid mb-2' disabled={!isEditForm.current} onClick={saveHandler}>
          <span>{t('Shared:save')}</span>
        </ButtonBase>
      </div>
    );
  });

  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  return (
    <div className="transactions-details-wrapper childs-wrapper">
      <Spinner isActive={isLoading} isAbsolute />
      <div className="form-item">
        <Inputs
          idRef="cityNameRef"
          labelValue="state"
          value={state.cityName || ""}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="propertyNameRef"
          labelValue="property-name"
          value={state.propertyName || ""}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="unitNumberRef"
          labelValue="unit-no"
          value={state.unitNumber || ""}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="unitReferenceNoRef"
          labelValue="unit-reference-no"
          value={state.unitReferenceNo || ""}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="leadReferenceNoRef"
          labelValue="lead-ref-no"
          value={state.leadReferenceNo || ""}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="unitTypeRef"
          labelValue="unitType"
          value={state.unitType || ""}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="exteranalAgencyNameRef"
          labelValue="external-agency-name"
          value={state.exteranalAgencyName || ""}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="splitCommRef"
          labelValue="split-comm-description"
          value={state.splitComm || ""}
          endAdornment={<span className="px-1">%</span>}
          type="number"
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <DatePickerComponent
          idRef="claimDateRef"
          labelValue="claim-date"
          placeholder="DD/MM/YYYY"
          value={state.claimDate}
          isDisabled={!isEditForm.current}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            setState({
              id: "claimDate",
              value: (newValue && moment(newValue).format()) || null,
            });
          }}
        />
      </div>
      <div className="form-item">
        <DatePickerComponent
          idRef="TransactionCompletionDateRef"
          labelValue="transaction-completion-date"
          placeholder="DD/MM/YYYY"
          value={state.completionDate || ""}
          isDisabled={!isEditForm.current}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            setState({
              id: "completionDate",
              value: (newValue && moment(newValue).format()) || null,
            });
          }}
        />
      </div>
      <div className="form-item">
        <DatePickerComponent
          idRef="transactionCreatedDateRef"
          labelValue="transaction-created-date"
          placeholder="DD/MM/YYYY"
          value={state.transactionCreatedDate}
          isDisabled={!isEditForm.current}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            setState({
              id: "transactionCreatedDate",
              value: (newValue && moment(newValue).format()) || null,
            });
          }}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="masterDeveloperRef"
          labelValue="master-developer"
          value={state.masterDeveloper || ""}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="ownerNameRef"
          labelValue="unit-landlord"
          value={state.ownerName || ""}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="TransactedByRef"
          labelValue="transacted-by"
          value={state.transactedBy || ""}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="approvedByRef"
          labelValue="approved-by"
          value={state.approvedBy || ""}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="rentPerYearRef"
          labelValue="rent-year-description"
          value={state.rentPerYear || 0}
          isDisabled={!isEditForm.current}
          onInputChanged={(event) => {
            const newValue = event.target.value;
            if (newValue) setState({ id: "rentPerYear", value: +newValue });
            else setState({ id: "rentPerYear", value: 0 });
          }}
          type="number"
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="totalAgencyFeeRef"
          labelValue="total-agency-fee"
          value={state.totalAgencyFee || 0}
          isDisabled={!isEditForm.current}
          onInputChanged={(event) => {
            const newValue = event.target.value;
            if (newValue) setState({ id: "totalAgencyFee", value: +newValue });
            else setState({ id: "totalAgencyFee", value: 0 });
          }}
          type="number"
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <DatePickerComponent
          idRef="startDateRef"
          labelValue="contract-start-date"
          placeholder="DD/MM/YYYY"
          value={state.startDate}
          maxDate={state.breakLeaseDate}
          helperText={getErrorByName(schema, "startDate").message}
          error={getErrorByName(schema, "startDate").error}
          isDisabled={!isEditForm.current}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            setState({
              id: "startDate",
              value: (newValue && moment(newValue).format()) || null,
            });
          }}
        />
      </div>
      <div className="form-item">
        <DatePickerComponent
          idRef="breakLeaseDateRef"
          labelValue="contract-end-date"
          placeholder="DD/MM/YYYY"
          value={state.breakLeaseDate}
          minDate={state.startDate}
          helperText={getErrorByName(schema, "breakLeaseDate").message}
          error={getErrorByName(schema, "breakLeaseDate").error}
          isDisabled={!isEditForm.current}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            setState({
              id: "breakLeaseDate",
              value: (newValue && moment(newValue).format()) || null,
            });
          }}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="agencyFeeTenantRef"
          labelValue="Agency-Fee-(Tenant)"
          value={state.agencyFeeTenant || 0}
          isDisabled={!isEditForm.current}
          onInputChanged={(e) => {
            const value = e && e.target && e.target.value ? e.target.value : 0;
            if (Number.isNaN(value)) value = 0;
            const fixed = value && value.replace(/,/g, "");
            let parsedValue = fixed ? parseFloat(fixed) : 0;

            const effectedByValue = state.rentPerYear;
            if (parsedValue > effectedByValue) parsedValue = effectedByValue;

            const netAmountTenant = parsedValue - state.externalAgencyFeeTenant;

            setState({
              id: "edit",
              value: {
                ...state,
                agencyFeeTenant: parsedValue,
                netAmountTenant,
              },
            });
          }}
          type="number"
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="agencyFeeRef"
          labelValue="Agency-fee-(Landlord)"
          value={state.agencyFee || 0}
          isDisabled={!isEditForm.current}
          onInputChanged={(e) => {
            const value = e && e.target && e.target.value ? e.target.value : 0;
            if (Number.isNaN(value)) value = 0;
            const fixed = value && value.replace(/,/g, "");
            let parsedValue = fixed ? parseFloat(fixed) : 0;

            const effectedByValue = state.rentPerYear;
            if (parsedValue > effectedByValue) parsedValue = effectedByValue;

            const netAmountLandLord =
              parsedValue - state.externalAgencyFeeLandLord;

            setState({
              id: "edit",
              value: {
                ...state,
                agencyFee: parsedValue,
                netAmountLandLord,
              },
            });
          }}
          type="number"
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="externalAgencyFeeTenantRef"
          labelValue="External-Agency-Fee-(Tenant)"
          value={state.externalAgencyFeeTenant || 0}
          isDisabled={!isEditForm.current}
          onInputChanged={(e) => {
            const value = e && e.target && e.target.value ? e.target.value : 0;
            if (Number.isNaN(value)) value = 0;
            const fixed = value && value.replace(/,/g, "");
            let parsedValue = fixed ? parseFloat(fixed) : 0;

            const effectedByValue = state.rentPerYear;
            if (parsedValue > effectedByValue) parsedValue = effectedByValue;

            const netAmountTenant = state.agencyFeeTenant - parsedValue;

            setState({
              id: "edit",
              value: {
                ...state,
                externalAgencyFeeTenant: parsedValue,
                netAmountTenant,
              },
            });
          }}
          type="number"
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="externalAgencyFeeLandLordRef"
          labelValue="External-Agency-Fee-(Landlord)"
          value={state.externalAgencyFeeLandLord || 0}
          isDisabled={!isEditForm.current}
          onInputChanged={(e) => {
            const value = e && e.target && e.target.value ? e.target.value : 0;
            if (Number.isNaN(value)) value = 0;
            const fixed = value && value.replace(/,/g, "");
            let parsedValue = fixed ? parseFloat(fixed) : 0;

            if (parsedValue > state.rentPerYear)
              parsedValue = state.rentPerYear;

            const netAmountLandLord = state.agencyFee - parsedValue;

            setState({
              id: "edit",
              value: {
                ...state,
                externalAgencyFeeLandLord: state.rentPerYear ? parsedValue : 0,
                netAmountLandLord,
              },
            });
          }}
          type="number"
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="netAmountTenantRef"
          labelValue="Net-Amount-(Tenant)"
          value={state.netAmountTenant || 0}
          isDisabled
          type="number"
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="netAmountLandLordRef"
          labelValue="Net-Amount-(Landlord)"
          value={state.netAmountLandLord || 0}
          isDisabled
          type="number"
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
      {state.agentCommissions &&
        state.agentCommissions.map((item, index) => (
          <div className="form-item">
            <Inputs
              withNumberFormat
              idRef={`agent-commission-${index})`}
              labelValue={`${item.agentType} Agent Commission (${item.agentName})`}
              value={state.agentCommissions[index].commission || 0}
              endAdornment={<span className="px-2">%</span>}
              type="number"
              min={0}
              max={100}
              isDisabled={!isEditForm.current}
              isWithError
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              onInputChanged={(event) => {
                const { value } = event.target;
                const stateAgents = [...state.agentCommissions];
                const changedAgent = stateAgents[index];
                stateAgents[index] = {
                  ...changedAgent,
                  commission: value,
                };
                setState({ id: "agentCommissions", value: stateAgents });
              }}
            />
          </div>
        ))}
      <div className="form-item">
        <DatePickerComponent
          idRef="transactionEntryDate"
          labelValue="transaction-date"
          placeholder="DD/MM/YYYY"
          value={state.transactionEntryDate}
          isDisabled={!isEditForm.current}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onDateChanged={(newValue) => {
            setState({
              id: "transactionEntryDate",
              value: (newValue && moment(newValue).format()) || null,
            });
          }}
        />
      </div>
      <div className="form-item">
        <Inputs
          idRef="invoiceNotes"
          labelValue="notes"
          value={state.invoiceNotes || ""}
          isDisabled
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
        />
      </div>
    </div>
  );
};

TransactionsDetails.propTypes = {
  unitTransactionId: PropTypes.number,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
};
TransactionsDetails.defaultProps = {
  unitTransactionId: null,
};
