import React, { useRef, useState } from 'react';
import { DropdownIconWrapper, StyledButton, StyledMenu } from './style';
import { InputAdornment, Slider } from '@material-ui/core';
import { Inputs } from '../../../../Components';

function PriceFilterShareUnit({
  selected,
  setSelected,
  PriceAndSizeChangeHandler,
  t,
}) {
  const searchTimer = useRef(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (event) => {
    if (anchorEl && anchorEl.contains(event?.target)) {
      return;
    }
    setAnchorEl(null);
    setOpen(false);
  };

  const handleSliderChange = (event, newValue) => {
    const [minValue, maxValue] = newValue;
    setSelected({ id: 'price', value: { from: minValue, to: maxValue } });
    if (searchTimer.current) {
      clearTimeout(searchTimer.current);
    }
    searchTimer.current = setTimeout(() => {
      PriceAndSizeChangeHandler('SellingPrice', {
        from: minValue,
        to: maxValue,
      });
    }, 1000);
  };

  const handleInputChange = (value, type) => {
    if (type === 'from') {
      setSelected({
        id: 'price',
        value: { from: value },
      });
      if (searchTimer.current) {
        clearTimeout(searchTimer.current);
      }
      searchTimer.current = setTimeout(() => {
        PriceAndSizeChangeHandler('SellingPrice', {
          from: value,
          to: selected?.price?.to,
        });
      }, 1000);
    } else {
      setSelected({
        id: 'price',
        value: { from: selected?.price?.from, to: value },
      });
      if (searchTimer.current) {
        clearTimeout(searchTimer.current);
      }
      searchTimer.current = setTimeout(() => {
        PriceAndSizeChangeHandler('SellingPrice', {
          from: selected?.price?.from,
          to: value,
        });
      }, 1000);
    }
  };
  return (
    <>
      <StyledButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='outlined'
        color='primary'
        onClick={handleClick}
      >
        <span className='share-unit-placeholder'>{t('price')}</span>
        <DropdownIconWrapper
          className={`mdi ${open ? 'mdi-chevron-up' : 'mdi-chevron-down'}`}
        />
      </StyledButton>
      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className='share-unit-filter-wrapper'>
          <legend className='share-unit-filter-title'>
            {t('price-range')}
          </legend>

          <div className='slider-container share-unit-slider'>
            <Slider
              value={[
                Number(selected?.price?.from) || 0,
                Number(selected?.price?.to) || 0,
              ]}
              onChange={handleSliderChange}
              min={10000}
              max={60000000}
              valueLabelDisplay='auto'
            />
          </div>
          <div className='inputs-container'>
            <div className='w-50'>
              <Inputs
                idRef='priceFromRef'
                type='number'
                labelValue={'Min'}
                value={selected?.price?.from || 10000}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  handleInputChange(value, 'from');
                }}
                startAdornment={
                  <InputAdornment position='end'>AED</InputAdornment>
                }
                min={0}
                className='price-input'
              />
            </div>
            <span className='range-separator'>-</span>
            <div className='w-50'>
              <Inputs
                idRef='priceToRef'
                type='number'
                labelValue={'Max'}
                value={selected?.price?.to || 60000000}
                onInputChanged={(event) => {
                  const { value } = event.target;
                  handleInputChange(value, 'to');
                }}
                startAdornment={
                  <InputAdornment position='end'>AED</InputAdornment>
                }
                min={0}
              />
            </div>
          </div>
        </div>
      </StyledMenu>
    </>
  );
}

export default PriceFilterShareUnit;
