import React, { useCallback, useState, useEffect } from "react";
import clsx from "clsx";
import { Box, Paper, Typography } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { CloseXIcon, CheckIcon, DeleteIcon } from "../../../assets/icons";
import { useVerticalNav } from "../../../Contexts/VerticalNavContext";
import {
  CustomAutocomplete,
  CustomButton,
  DropzoneComponent,
  CustomInput,
  CustomRadioButton,
  CustomCard,
} from "../..";
import {
  useSelectedTheme,
  useTranslate,
  useIsArabicLayout,
} from "../../../Hooks";
import {
  GetLookupItemsByLookupTypeName,
  ApproveOrRejectContactConsent,
} from "../../../Services";
import { GetFileTypeIcon } from "../../../Views/Home/NewContactsView/ContactOverview/UI";

// Styles
import useStyles from "./styles";

// Validation Schema
const validationSchema = Yup.object().shape({
  reason: Yup.object().required("Reason is required"),
  evidence: Yup.string(),
  evidenceText: Yup.string(),
  remarks: Yup.string(),
});

const AddDNCRAndConsentModal = ({
  contactId,
  variant = "Consent",
  onClose,
  setRefetchDNCRAndConsent,
}) => {
  const styles = useStyles();
  const { isArabicLayout } = useIsArabicLayout();
  const { setAlertBoxContent } = useVerticalNav();
  const { translate } = useTranslate("NewContactsView");

  const {
    theme: { palette },
  } = useSelectedTheme();

  const [reasons, setReasons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isDNCR = variant === "DNCR";

  // Fetch Reasons
  const fetchReasons = useCallback(async () => {
    try {
      const res = await GetLookupItemsByLookupTypeName({
        lookUpName: isDNCR ? "DNCRReasons" : "ConsentReasons",
        pageIndex: 0,
        pageSize: 99999,
      });
      if (!(res && res.status && res.status !== 200)) {
        setReasons(res.result);
      } else {
        setReasons([]);
      }
    } catch (error) {
      console.error("Failed to fetch reasons:", error);
    }
  }, [isDNCR]);

  useEffect(() => {
    fetchReasons();
  }, [fetchReasons]);

  const [activeCard, setActiveCard] = useState(1);
  const handleCardClick = (cardType) => {
    setActiveCard(Number(cardType));
  };

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filesAccepted, setFilesAccepted] = useState([]);
  const [uploadedFileId, setUploadedFileId] = useState(null);
  const [displayError, setDisplayError] = useState(false);

  const handleFilesAccepted = (files) => {
    setFilesAccepted(files);
  };

  const onSubmitClicked = async (values) => {
    if (!values.evidence && !values.link) {
      setDisplayError(true);
      return 0;
    }

    setDisplayError(false);

    setIsLoading(true);

    if (values?.link || values?.document || values?.evidence) {
      const body = {
        contactId,
        isApproved: activeCard === 1 ? true : false,
        isDNCR,
        changeReasonId: values?.reason?.lookupItemId,
        remarks: values?.remarks || null,
        evidenceDtos: [
          {
            fileId: uploadedFileId,
            fileName: uploadedFiles?.[0]?.fileName,
            evidenceLink: values?.link,
            writtenEvidence: values?.evidence || null,
          },
        ],
      };

      const res = await ApproveOrRejectContactConsent(body);

      if (!(res && res.status && res.status !== 200)) {
        onClose();
        setRefetchDNCRAndConsent((prev) => prev + 1);

        setAlertBoxContent({
          display: true,
          variant: "success",
          title: translate("SUBMISSION_SUCCESS_MESSAGE"),
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      } else {
        setAlertBoxContent({
          display: true,
          variant: "error",
          title: translate("SUBMISSION_ERROR_MESSAGE"),
          onClose: () => {
            setAlertBoxContent(null);
          },
        });
      }
    }
    setIsLoading(false);
  };

  const formatUploadedDocumentToMap = () => {
    if (uploadedFileId && filesAccepted?.length > 0) {
      const file = filesAccepted[0];

      setUploadedFiles([
        {
          fileId: uploadedFileId,
          fileSize: file.size,
          fileName: file.path,
          fileType: file.type,
        },
      ]);
    }
  };

  const handleDeleteUploadedFile = (index) => {
    setUploadedFiles((prevUploadedFiles) =>
      prevUploadedFiles.filter((_, i) => i !== index)
    );
  };

  useEffect(() => {
    formatUploadedDocumentToMap();
  }, [uploadedFileId]);

  const formatBytes = (bytes, decimals = 1) => {
    if (!+bytes) {
      return "0 Bytes";
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  return (
    <Box className={styles.overlay}>
      <Paper className={styles.modal}>
        <Formik
          initialValues={{
            reason: "",
            evidence: "",
            evidenceText: "",
            remarks: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const reason = reasons?.find(
              (item) => values?.reason?.lookupItemName === item?.lookupItemName
            );

            await onSubmitClicked({
              link: values.evidence,
              evidence: values.evidenceText,
              remarks: values.remarks,
              reason,
              document: {
                uuid: uploadedFileId,
                fileName: uploadedFiles?.[0]?.fileName,
                url: null,
              },
            });
          }}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Box className={styles.scrollDiv}>
                {/* Modal Header */}
                <Box className={styles.modalHeader}>
                  <Typography variant="h6" className={styles.modalTitle}>
                    {translate(
                      isDNCR
                        ? "ADD_DNCR_RECORD_TITLE"
                        : "ADD_CONSENT_RECORD_TITLE"
                    )}
                  </Typography>
                  <span>{translate("MODAL_DESCRIPTION")}</span>
                  <CloseXIcon
                    onClick={onClose}
                    className={styles.closeIcon}
                    style={{ [isArabicLayout ? "left" : "right"]: 0 }}
                    width="20"
                    height="20"
                    fill={palette.foreground.quinary}
                  />
                </Box>

                {/* Status Selection */}
                <Box
                  className={styles.fieldContainer}
                  style={{ padding: "20px 0 16px" }}
                >
                  <label>
                    {translate(
                      isDNCR ? "BLOCK_STATUS_LABEL" : "CONSENT_STATUS_LABEL"
                    )}
                  </label>
                  <Box className={styles.actionButtonsContainer}>
                    {/* Unblock/Approve Card */}
                    <Box
                      className={clsx(styles.statusCard, {
                        [styles.activeStatusCard]: activeCard === 1,
                      })}
                      onClick={() => handleCardClick(1)}
                    >
                      <Box className={styles.statusCardContent}>
                        <CheckIcon
                          width="20"
                          height="20"
                          fill={palette.text.success_primary}
                        />
                        <span>
                          {translate(
                            isDNCR ? "UNBLOCK_LABEL" : "APPROVE_LABEL"
                          )}
                        </span>
                      </Box>
                      <CustomRadioButton
                        checked={activeCard === 1}
                        value="option1"
                      />
                    </Box>

                    {/* Block/Reject Card */}
                    <Box
                      className={clsx(styles.statusCard, {
                        [styles.activeStatusCard]: activeCard === 2,
                      })}
                      onClick={() => handleCardClick(2)}
                    >
                      <CloseXIcon
                        width="20"
                        height="20"
                        fill={palette.text.error_primary}
                      />
                      <span>
                        {translate(isDNCR ? "BLOCK_LABEL" : "REJECT_LABEL")}
                      </span>
                      <CustomRadioButton
                        checked={activeCard === 2}
                        value="option2"
                      />
                    </Box>
                  </Box>
                </Box>

                {/* Reason Field */}
                <Box className={styles.fieldContainer}>
                  <label>{translate("REASON_LABEL")}</label>
                  <Box className={styles.errorMessageContainer}>
                    <CustomAutocomplete
                      variant="default"
                      options={reasons || []}
                      renderOption={(option) => option?.lookupItemName || ""}
                      getOptionLabel={(option) => option?.lookupItemName || ""}
                      placeholder={translate("REASON_LABEL")}
                      value={values.reason}
                      onChange={(e, selectedOption) => {
                        setFieldValue("reason", selectedOption);
                      }}
                    />
                    {touched.reason && errors.reason && (
                      <Box className={styles.errorMessage}>{errors.reason}</Box>
                    )}
                  </Box>
                </Box>

                {/* Evidence Field */}
                <Box className={styles.fieldContainer}>
                  <label>{translate("EVIDENCE_LABEL")}*</label>
                  <Box className={styles.inputGroup}>
                    <CustomInput
                      inputContainerOverrideStyles={styles.inputFullWidth}
                      placeholder={translate("EVIDENCE_INPUT_PLACEHOLDER")}
                      type="text"
                      value={values.evidence}
                      onChange={(e) =>
                        setFieldValue("evidence", e.target.value)
                      }
                    />

                    <DropzoneComponent
                      setUploadedFileId={setUploadedFileId}
                      title={translate("DROPZONE_TITLE")}
                      onFilesAccepted={handleFilesAccepted}
                    />

                    {uploadedFiles && uploadedFiles?.length > 0 && (
                      <Box className={styles.containerUploadedFilesCards}>
                        {uploadedFiles
                          ?.filter((file) => file?.fileType)
                          ?.map((uploadFile, index) => (
                            <CustomCard
                              boxShadow="xs"
                              borderRadius="xl"
                              borderColor="secondary"
                              classes={styles.CardUploadedFilesInfo}
                              key={index}
                            >
                              {GetFileTypeIcon(uploadFile?.fileType)}
                              <Box className={styles.ContainerFileUploadedInfo}>
                                <Box
                                  className={styles.containerTitleAndDeleteIcon}
                                >
                                  <Typography className={styles.TitleFileName}>
                                    {uploadFile?.fileName}
                                  </Typography>
                                  <DeleteIcon
                                    width="20"
                                    height="20"
                                    className={styles.DeleteIcon}
                                    style={{ cursor: "pointer" }}
                                    fill={palette.button.secondary_fg}
                                    onClick={() =>
                                      handleDeleteUploadedFile(index)
                                    }
                                  />
                                </Box>
                                <Typography className={styles.FileSizeTitle}>
                                  {formatBytes(uploadFile?.fileSize)}
                                </Typography>
                              </Box>
                            </CustomCard>
                          ))}
                      </Box>
                    )}

                    <Box className={styles.errorMessageContainer}>
                      <textarea
                        className={styles.textAreaInput}
                        placeholder={translate("WRITTEN_EVIDENCE_PLACEHOLDER")}
                        value={values.evidenceText}
                        onChange={(e) =>
                          setFieldValue("evidenceText", e.target.value)
                        }
                      />

                      {displayError && (
                        <Box className={styles.errorMessage}>
                          {translate("PROVIDE_LINK_TEXT")}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>

                {/* Remarks Field */}
                <Box
                  className={styles.fieldContainer}
                  style={{ padding: "16px 0 20px" }}
                >
                  <label>{translate("REMARKS_LABEL")}</label>
                  <textarea
                    className={styles.textAreaInput}
                    placeholder={translate("REMARKS_INPUT_PLACEHOLDER")}
                    value={values.remarks}
                    onChange={(e) => setFieldValue("remarks", e.target.value)}
                  />
                </Box>
              </Box>
              {/* Footer Actions */}
              <Box className={styles.footerActions}>
                <CustomButton
                  variant="outlined"
                  color="secondary"
                  onClick={onClose}
                >
                  {translate("CANCEL_BUTTON_LABEL")}
                </CustomButton>
                <CustomButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                >
                  {translate(
                    isDNCR
                      ? "SUBMIT_DNCR_BUTTON_LABEL"
                      : "SUBMIT_CONSENT_BUTTON_LABEL"
                  )}
                </CustomButton>
              </Box>
            </Form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
};

export default AddDNCRAndConsentModal;
