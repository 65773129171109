import React from "react";

const transactionLinksActionClicked = (item, key) => async (event) => {
  event.stopPropagation();
  if (key === "transactionID" && item.transactionID)
    window.open(
      `/home/AMLView/AMLView?transactionID=${item.transactionID}`,
      "_blank"
    );
  else if (
    key === "unit" &&
    item?.operationType === "Sale" &&
    item.unitReferenceNumber &&
    item.unitId
  )
    window.open(
      `/home/units-sales/unit-profile-edit?formType=${1}&id=${
        item.unitId || item.unitReferenceNumber
      }&operationType=${430}`,
      "_blank"
    );
  else if (key === "unit" && item.operationType === "Rent")
    window.open(
      `/home/units-lease/unit-profile-edit?formType=${1}&id=${
        item.unitId || item.unitReferenceNumber
      }&operationType=${431}`,
      "_blank"
    );
  else if (key === "contact")
    window.open(
      `/home/units-lease/unit-profile-edit?formType=${1}&id=${
        item.unitId || item.unitReferenceNumber
      }&operationType=${431}`,
      "_blank"
    );
  else return null;
};

const repeated = (list, filed) =>
  list &&
  list.map((item, index) => (
    <span
      key={index}
      onClick={() => {
        window.open(
          `/home/Contacts-CRM/contact-profile-edit?formType=${item.ContactsTypeId}&id=${item.ContactId}`,
          "_blank"
        );
      }}
    >
      {" "}
      {item[filed]}
      {list.length - 1 != index && <span> , </span>}
    </span>
  ));

export const AMLLogsHeaderData = (t, AMLamlTransactionStatusList) => [
  {
    id: 1,
    isSortable: false,
    isHiddenFilter: true,
    label: "transactionId",
    input: "transactionID",
    component: (item) =>
      item.transactionID ? (
        <a
          className="c-primary newTabActivites"
          onClick={transactionLinksActionClicked(item, "transactionID")}
        >
          {item.transactionID || ""}
        </a>
      ) : (
        <span> {item.transactionID || ""} </span>
      ),
  },

  {
    id: 2,
    isSortable: false,
    label: "transaction-create-date",
    input: "transactionCreateDate",
    isHiddenFilter: true,
    isDate: true,
  },
  {
    id: 2,
    label: "ref-no",
    input: "unitReferenceNumber",
    isHiddenFilter: true,
    component: (item) =>
      item.unitReferenceNumber ? (
        <a
          className="c-primary newTabActivites"
          onClick={transactionLinksActionClicked(item, "unit")}
        >
          {item.unitReferenceNumber || ""}
        </a>
      ) : (
        <span> {item.unitReferenceNumber || ""} </span>
      ),
  },
  {
    id: 3,
    label: "aml-Transaction-status",
    input: "amlamlTransactionStatus",
    isHiddenFilter: true,
    withSelectFilter: true,
    optionFilterList: [...AMLamlTransactionStatusList],
    component: (item) =>
      (
        <span>
          {item.amlTransactionStatus === "Pass" ||
          item.amlTransactionStatus === "Approved" ? (
            <span className="pass-lapel">
              {t(`${item.amlTransactionStatus}`)}
            </span>
          ) : item.amlTransactionStatus === "Fail" ||
            item.amlTransactionStatus === "Rejected" ? (
            <div className="Fail-lapel">
              {t(`${item.amlTransactionStatus}`)}
            </div>
          ) : item.amlTransactionStatus === "Pending" ||
            item.amlTransactionStatus === "WithdrawTransaction" ||
            item.amlTransactionStatus === "ReassignedToRequester" ||
            item.amlTransactionStatus === "Auto Withdraw" ||
            item.amlTransactionStatus === "Auto With draw" ||
            item.amlTransactionStatus === "Withdraw Transaction" || 
            item.amlTransactionStatus === "With draw Transaction" || 
            item.amlTransactionStatus === "Reassigned To Requester" ||
            item.amlTransactionStatus === "NeedRevision" ||
            item.amlTransactionStatus === "Need Revision" ||
            item.amlTransactionStatus === "Resubmitted" ||
            item.amlTransactionStatus === "AutoWithdraw" ? (
            <div className="Pending-lapel">
              {t(`${item.amlTransactionStatus}`)}
            </div>
          ) : (
            ""
          )}
        </span>
      ) || "",
  },
  {
    
    id: 4,
    isHiddenFilter: true,
    label: "requester-name",
    input: "requesterName",
  },
  {
    id: 5,
    label: "requester-branch",
    input: "requesterBranch",
    isHiddenFilter: true,
  },
  {
    id: 6,
    label: "transaction-type",
    input: "transactionType",
    isHiddenFilter: true,
  },
  {
    id: 4,
    label: "client-name",
    isHiddenFilter: true,
    component: (item) =>
      (
        <span>
          {item &&
            item.amlContactDetails &&
            repeated(item.amlContactDetails, "ClientName")}
        </span>
      ) || "N/A",
  },
  {
    id: 7,
    label: "aml-sale-amount",
    isHiddenFilter: true,
    input: "saleAmount",
  },
  {
    id: 8,
    label: "aml-lease-amount",
    isHiddenFilter: true,
    input: "leaseAmount",
  },
  {
    id: 9,
    label: "aml-reason",
    isHiddenFilter: true,
    input: "reason",
  },
  {
    id: 10,
    label: "aml-remark",
    isHiddenFilter: true,
    input: "remark",
  },
  {
    id: 13,
    isSortable: false,
    label: "aml-status-update-date",
    isHiddenFilter: true,
    input: "amlStatusUpdateDate",
    isHiddenFilter: true,
    isDate: true,
  },
];
