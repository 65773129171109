import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    warpperUnitsModelsCrad: {
      width: "415.33px",
      padding: "24px",
      gap: "16px",
      display: "flex",
      flexDirection: "column",
    },
    headerCard: {
      display: "flex",
      flexDirection: "column",
    },
    divider: {
      marginTop: "20px",
    },
    mainTitle: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "28px",
      color: theme.palette.text.primary,
    },
    detailsUnits: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
    },
    containerUnitItem: {
      display: "flex",
      flexDirection: "row",
      gap: "4px",
    },
    dataContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "16px",
    },
    bodyCard: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },propertyName:{
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
        color: theme.palette.text.brand_secondary,
    },unitItemValues:{
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
        color: theme.palette.text.secondary,
    },unitItemTitle:{
        fontSize: "16px",
        fontWeight: 599,
        lineHeight: "24px",
        color: theme.palette.text.secondary,
    }
  };
});
