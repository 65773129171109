import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => {
  return {
    wapperUnitsComponent: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
    },
    containerHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    containerLeftHeader: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
      alignItems: "center",
    },
    containerCards: {
      display: "flex",
      flexDirection: "row",
      gap: "24px",
      flexWrap: "wrap",
    },
    containerMainTitle: {
      fontSize: "30px",
      fontWeight: 600,
      lineHeight: "38px",
      color: theme.palette.text.primary,
    },
  };
});
