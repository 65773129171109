import React, { memo, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Tabs as MuiTabs } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";

const StyledTabs = withStyles((theme) => ({
  root: {
    gap: theme.spacing(1.5),
    minHeight: theme.spacing(4.75),
    borderBottom: "1px solid #EAECF0",
  },
  indicator: {
    backgroundColor: "#7F6944",
  },
  flexContainer: {
    "& .Mui-selected": {
      color: "#7F6944",
    },
  },
  scrollButtons: {
    "&.Mui-disabled": {
      display: "none",
    },
    "&:not(.Mui-disabled)": {
      display: "inline-flex",
    }
  }
}))((props) => <MuiTabs {...props} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: "fit-content",
    minHeight: theme.spacing(4.75),
    height: theme.spacing(2),
    opacity: 1,
    color: "#667085",
    fontWeight: 600,
    paddingBlock: theme.spacing(0),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    "&:hover": {
      color: "#635235",
    },
    "&:focus": {
      color: "#635235",
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const Tabs = memo(({ tabsData, tabsChangeHandler }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabChange = (event, index) => {
    event.stopPropagation();
    setActiveTabIndex(index);
    tabsChangeHandler(index);
  };

  return (
    (Array.isArray(tabsData) && tabsData.length > 0) ?
      <StyledTabs value={activeTabIndex} onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="on">
        {tabsData.map(tab => <StyledTab key={tab.id} label={tab.label} />)}
      </StyledTabs> : <span>No payment plans available .</span>
  )
});

Tabs.propTypes = {
  tabsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  tabsChangeHandler: PropTypes.func.isRequired,
};

Tabs.defaultProps = {
  tabsData: [],
  tabsChangeHandler: () => { },
};

export default Tabs;
