import React, { useEffect, useRef, useReducer } from 'react';
import { ApplicationUserSearch } from '../../../../../../../../Services/userServices';
import { GetAllBranches } from '../../../../../../../../Services';
import { useTranslation } from 'react-i18next';
import {
  AutocompleteComponent,
  SelectComponet,
  Inputs,
} from '../../../../../../../../Components';
import { floatHandler } from '../../../../../../../../Helper';
import { CampaignFinancialSourceEnum } from '../../../../../../../../Enums';
import { InputAdornment, ButtonBase } from '@material-ui/core';

export function FinanceStep({
  parentTranslationPath,
  translationPath,
  selected,
  setSelected,
  isReadOnly,
}) {
  const searchTimer = useRef(null);
  const { t } = useTranslation(parentTranslationPath);
  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  };
  const defaultFinancialSourceItem = {
    type: null,
    branch: null,
    user: null,
    percent: null,
  };

  const [isLoading, setIsLoading] = useReducer(reducer, {
    users: false,
    branches: false,
  });
  const [data, setData] = useReducer(reducer, {
    users: [],
    branches: [],
  });

  const financialSourceDecrementHandler = (index) => {
    const localFinancialSources = [...(selected.financialSources || [])];
    localFinancialSources.splice(index, 1);

    if (localFinancialSources.length == 0) {
      setSelected({
        id: 'financialSources',
        value: [{ ...defaultFinancialSourceItem }],
      });
    } else {
      setSelected({
        id: 'financialSources',
        value: localFinancialSources,
      });
    }
  };

  const financialSourceIncrementHandler = (index) => {
    const localFinancialSource = [...(selected.financialSources || [])];
    localFinancialSource.push({ ...defaultFinancialSourceItem });
    setSelected({
      id: 'financialSources',
      value: localFinancialSource,
    });
  };

  const financialSourceChangeHandler = (index, property, newValue) => {
    const localFinancialSources = [...(selected.financialSources || [])];
    const localItem = localFinancialSources[index] || null;
    let newLocalItem = {
      ...(localItem || {}),
    };

    if (property == 'type')
      newLocalItem = {
        ...(localItem || {}),
        [property]: newValue,
        branch: null,
        user: null,
      };
    else
      newLocalItem = {
        ...(localItem || {}),
        [property]: newValue,
      };
    localFinancialSources[index] = newLocalItem;

    setSelected({ id: 'financialSources', value: localFinancialSources });
  };

  const getAllUsers = async (searchValue) => {
    setIsLoading({ id: 'users', value: true });
    const res = await ApplicationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0) {
        setData({ id: 'users', value: localValue });
      } else {
        setData({ id: 'users', value: [] });
      }
      setIsLoading({ id: 'users', value: false });
    }
  };

  const getAllBranches = async (searchValue) => {
    setIsLoading({ id: 'branches', value: true });
    const res = await GetAllBranches({
      pageIndex: 1,
      pageSize: 10,
      branchName: searchValue || '',
    });
    if (!(res && res.status && res.status !== 200))
      setData({ id: 'branches', value: res.result || [] });
    else setData({ id: 'branches', value: [] });

    setIsLoading({ id: 'branches', value: false });
  };

  useEffect(() => {
    getAllUsers();
    getAllBranches();
  }, []);

  return (
    <div className='camp-req-finance-f1  p-4'>
      <div className='d-flex'>
        <div className='w-50 mr-2'>
          <Inputs
            idRef='LengthOfCampaignRef'
            labelValue='Length-of-campaign'
            value={selected.campaignLength}
            endAdornment={
              <InputAdornment
                position='end'
                className='px-2'
              >
                Days
              </InputAdornment>
            }
            type='number'
            min={0}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
              let value = +floatHandler(event.target.value, 3);
              if (value < 0) value = 0;
              setSelected({ id: 'campaignLength', value: value });
            }}
            isDisabled={isReadOnly}
          />
        </div>
        <div className='w-50 mr-2'>
          <Inputs
            idRef='campaignBudgetRef'
            labelValue='Campaign-Budget'
            value={selected.campaignBudget}
            endAdornment={
              <InputAdornment
                position='end'
                className='px-2'
              >
                AED
              </InputAdornment>
            }
            type='number'
            min={0}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onInputChanged={(event) => {
              let value = event.target.value;
              setSelected({ id: 'campaignBudget', value: +value });
            }}
            isDisabled={isReadOnly}
          />
        </div>
      </div>

      <fieldset className='input-fieldset mr-2'>
        <legend className='input-fieldset-legend'>
          {t(`${translationPath}Financial-Source`)}
        </legend>
        {selected.financialSources &&
          selected.financialSources.map((item, index) => (
            <div className={item.type ? 'withType' : 'withoutType'}>
              <div className='mr-2'>
                <SelectComponet
                  data={Object.values(CampaignFinancialSourceEnum)}
                  labelValue='Source-Type'
                  emptyItem={{
                    value: null,
                    text: 'select-type',
                    isDisabled: false,
                  }}
                  value={item.type}
                  valueInput='name'
                  textInput='name'
                  onSelectChanged={(newValue) =>
                    financialSourceChangeHandler(index, 'type', newValue)
                  }
                  themeClass='theme-default'
                  idRef='FinancialSourceTypeRef'
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              </div>
              {(item.type &&
                (item.type === 'Company' ? (
                  <div className='mr-2'>
                    <AutocompleteComponent
                      idRef='sourceBranchRef'
                      labelValue={t(`${translationPath}branch`)}
                      selectedValues={item.branch}
                      isDisabled={isLoading.branches || isReadOnly}
                      data={data.branches || []}
                      displayLabel={(option) => option.branchName || ''}
                      multiple={false}
                      wrapperClasses='source-style'
                      withoutSearchButton
                      onChange={(event, newValue) =>
                        financialSourceChangeHandler(index, 'branch', newValue)
                      }
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer.current)
                          clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                          getAllBranches(value);
                        }, 1200);
                      }}
                      onKeyDown={() => {
                        financialSourceChangeHandler(index, 'branch', null);
                      }}
                      isLoading={isLoading.branches}
                    />
                  </div>
                ) : (
                  <div className='mr-2'>
                    <AutocompleteComponent
                      idRef='sourceAgentRef'
                      labelValue={t(`${translationPath}agent`)}
                      selectedValues={item.user}
                      isDisabled={isLoading.users || isReadOnly}
                      data={data.users || []}
                      displayLabel={(option) => option.fullName || ''}
                      multiple={false}
                      wrapperClasses='source-style'
                      withoutSearchButton
                      onChange={(event, newValue) =>
                        financialSourceChangeHandler(index, 'user', newValue)
                      }
                      onInputKeyUp={(e) => {
                        const { value } = e.target;
                        if (searchTimer.current)
                          clearTimeout(searchTimer.current);
                        searchTimer.current = setTimeout(() => {
                          getAllUsers(value);
                        }, 1200);
                      }}
                      onKeyDown={() => {
                        financialSourceChangeHandler(index, 'user', null);
                      }}
                      isLoading={isLoading.users}
                    />
                  </div>
                ))) ||
                null}
              <Inputs
                idRef='financialSourcePercentRef'
                labelValue='percentage'
                value={item.percent || 0}
                endAdornment={
                  <InputAdornment
                    position='end'
                    className='px-2'
                  >
                    %
                  </InputAdornment>
                }
                type='number'
                min={0}
                max={100}
                parentTranslationPath={parentTranslationPath}
                translationPath={translationPath}
                onInputChanged={(event) => {
                  let value =
                    isNaN(event.target.value) || event.target.value === ''
                      ? 0
                      : Number(event.target.value);
                  if (value > 100) value = 100;
                  else if (value < 0) value = 0;
                  else value = value ? parseFloat(value.toFixed(2)) : 0;
                  financialSourceChangeHandler(index, 'percent', value);
                }}
                isDisabled={isReadOnly}
              />
              <div className='d-flex-center ml-2'>
                <ButtonBase
                  className='btns-icon theme-solid bg-secondary-light mt-1 mr-1'
                  onClick={() => financialSourceDecrementHandler(index)}
                  disabled={isReadOnly}
                >
                  <span className='mdi mdi-minus c-black-light' />
                </ButtonBase>
                {index === 0 && (
                  <ButtonBase
                    className='btns-icon theme-solid bg-secondary-light mt-1 mr-1'
                    onClick={() => financialSourceIncrementHandler(index)}
                    disabled={isReadOnly}
                  >
                    <span className='mdi mdi-plus c-black-light' />
                  </ButtonBase>
                )}
              </div>
            </div>
          ))}
      </fieldset>
      <div className='w-50 mr-2'>
        <Inputs
          idRef='dailyBudgetRef'
          labelValue='daily-budget'
          value={selected.dailyBudget}
          endAdornment={
            <InputAdornment
              position='end'
              className='px-2'
            >
              AED
            </InputAdornment>
          }
          type='number'
          min={0}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onInputChanged={(event) => {
            let value = event.target.value;
            setSelected({ id: 'dailyBudget', value: +value });
          }}
          isDisabled={isReadOnly}
        />
      </div>
    </div>
  );
}
