import React, { useCallback, useState } from 'react';
import { Tables } from '../../../../../../../Components';
import { TableActions, TableFilterTypesEnum } from '../../../../../../../Enums';
import { useTranslation } from 'react-i18next';
import { DeleteSourceFile } from '../../../../../../../Services';
import { showError, showSuccess } from '../../../../../../../Helper';
import { EditSourceFileDialog } from './EditSourceFileDialog';
export const HistoryContactDetailsTable = ({
  contactId,
  contactDetailsData,
  parentTranslationPath,
  onReload,
  initialFilter,
  searchFilter,
  setSearchFilter,
  translationPath,
  initialSearchFilter,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [activeItem, setActiveItem] = useState(null);
  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [openDialogEditSourceFile, setOpenDialogEditSourceFile] =
    useState(false);
  const [actionsList, setActionsList] = useState(
    getActionTableWithPermissions()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const contactSourceTypeOptions = [
    {
      contactSourceType: 1,
      contactSourceTypeName: 'Contact',
    },
    {
      contactSourceType: 2,
      contactSourceTypeName: 'Lead',
    },
  ];
  const headerData = [
    {
      label: t(`${translationPath}contact-file-name`),
      input: 'sourceFileName',
      isDate: false,
      filterType: TableFilterTypesEnum.textInput.key,
      searchableKey: 'sourceFileName',
      component: (item) =>
        item.sourceFileName ? <span>{item.sourceFileName}</span> : 'N/A',
    },
    {
      label: t(`${translationPath}contact-file-year`),
      input: 'sourceFileYear',
      isDate: false,
      searchableKey: 'sourceFileYear',
      filterType: TableFilterTypesEnum.textInput.key,
      component: (item) =>
        item.sourceFileYear ? <span>{item.sourceFileYear}</span> : 'N/A',
    },
    {
      label: t(`${translationPath}contact-file-date`),
      input: 'sourceFileDate',
      isDate: true,
      component: (item) =>
        item.sourceFileDate ? <span>{item.sourceFileDate}</span> : 'N/A',
    },
    {
      label: t(`${translationPath}contact-by`),
      input: 'sourceBy',
      isDate: false,
      searchableKey: 'sourceBy',
      filterType: TableFilterTypesEnum.textInput.key,
      component: (item) =>
        item.sourceBy ? <span>{item.sourceBy}</span> : 'N/A',
    },
    {
      label: t(`${translationPath}community-contact-file`),
      input: 'communitySourceFile',
      isDate: false,
      searchableKey: 'communitySourceFile',
      filterType: TableFilterTypesEnum.textInput.key,
      component: (item) =>
        item.communitySourceFile ? (
          <span>{item.communitySourceFile}</span>
        ) : (
          'N/A'
        ),
    },
    {
      label: t(`${translationPath}property-contact-file`),
      input: 'propertySourceFile',
      isDate: false,
      searchableKey: 'propertySourceFile',
      filterType: TableFilterTypesEnum.textInput.key,
      component: (item) =>
        item.propertySourceFile ? (
          <span>{item.propertySourceFile}</span>
        ) : (
          'N/A'
        ),
    },
    {
      label: t(`${translationPath}contact-type`),
      input: 'contactSourceType',
      isDate: false,
      searchableKey: 'contactSourceType',
      filterType: TableFilterTypesEnum.selectOption.key,
      optionFilterList: contactSourceTypeOptions.map((item) => ({
        key: item.contactSourceType,
        value: item.contactSourceTypeName,
      })),
      component: (item) =>
        item.contactSourceTypeName ? (
          <span>{item.contactSourceTypeName}</span>
        ) : (
          'N/A'
        ),
    },
    {
      label: 'Created-by',
      input: 'createdby',
      isDate: false,
      searchableKey: 'createdby',
      filterType: TableFilterTypesEnum.textInput.key,
      component: (item) =>
        item.createdby ? <span>{item.createdby}</span> : 'N/A',
    },
    {
      label: 'Created-On',
      input: 'createdOn',
      isDate: true,
      searchableKey: 'fromDate',
      filterType: TableFilterTypesEnum.datePicker.key,
      component: (item) =>
        item.createdOn ? <span>{item.createdOn}</span> : 'N/A',
    },
  ];
  const tableActionClicked = useCallback((actionEnum, item) => {
    if (actionEnum === TableActions.editText.key) {
      setActiveItem(item);
      setOpenDialogEditSourceFile(true);
    }
    if (actionEnum === TableActions.delete.key) {
      setActiveItem(item);
      deleteSourceFile(item?.sourceFileId);
    }
  }, []);
  function getActionTableWithPermissions() {
    const list = [];
    list.push({
      enum: TableActions.editText.key,
      isDisabled: false,
      externalComponent: null,
    });
    list.push({
      enum: TableActions.delete.key,
      isDisabled: false,
      externalComponent: null,
    });
    return list;
  }
  const focusedRowChanged = (index, item) => {
    if (index === -1) {
      return;
    }
    if (
      item.contactSourceType ===
      contactSourceTypeOptions.pop().contactSourceType
    ) {
      setActionsList([]);
    }
  };
  const deleteSourceFile = useCallback(async (sourceFileId) => {
    setIsLoading(true);
    const res = await DeleteSourceFile(sourceFileId);
    if (res === true) {
      showSuccess(t(`delete-contact-file-successfully`));
      onReload();
    } else {
      showError(t(`failed-to-delete-contact-file`));
    }
  });

  const resetFilter = () => {
    setSearchFilter(initialSearchFilter);
    setIsClearFiltersClicked(true);
  };

  return (
    <div className='mt-4'>
      <div className='title-section'>{t('contact-file-table')}</div>
      <Tables
        headerData={headerData}
        data={contactDetailsData?.result || []}
        itemsPerPage={searchFilter.pageSize}
        activePage={0}
        filterData={headerData.map((item) => ({
          key: item.key,
          id: item.id,
          filterType: item.filterType,
          searchableKey: item.searchableKey ?? item.input,
          isHiddenFilter: item.isHiddenFilter,
          optionFilterList: item.optionFilterList,
          filterBy: item.filterBy,
        }))}
        isWithFilter={true}
        isWithOrderBy={true}
        onFilterValuesChanged={(e) => {
          if (e && !!Object.values(e).length) {
            const { value, searchableKey } = Object.values(e)[0];
            setSearchFilter((filter) => ({
              ...filter,
              [searchableKey]: value,
              ...(searchableKey === 'fromDate' && { toDate: value }),
            }));
          } else {
            setSearchFilter((filter) => {
              const updatedFilter = Object.entries(filter).filter(
                ([_key, value]) => !!value
              );
              return Object.fromEntries(updatedFilter);
            });
          }
        }}
        defaultActions={actionsList}
        actionsOptions={{
          onActionClicked: tableActionClicked,
        }}
        focusedRowChanged={focusedRowChanged}
        isClearFiltersClicked={isClearFiltersClicked}
        setIsClearFiltersClicked={setIsClearFiltersClicked}
        totalItems={contactDetailsData?.totalCount || 9999}
        translationPath={translationPath}
        parentTranslationPath={parentTranslationPath}
      />
      {openDialogEditSourceFile && (
        <EditSourceFileDialog
          activeItem={activeItem}
          onOpen={openDialogEditSourceFile}
          onClose={() => setOpenDialogEditSourceFile(false)}
          onSave={() => {
            onReload();
          }}
          translationPath={translationPath}
          parentTranslationPath={parentTranslationPath}
        />
      )}
    </div>
  );
};
