import React from "react";
import { Tables } from "../../../../../Components";

const InstallmentsTable = ({ data }) => {

  return (
    <>
      <Tables
        data={data}
        headerData={[
          {
            label: "Payment Breakdown",
            input: "paymentBreakdown",
          },
          {
            label: "Installment Schedule",
            input: "installmentSchedule",
          },
          {
            label: "Milestone",
            input: "installmentTypeName",
          },
          {
            label: "Percentage",
            input: "amountPercentage",
          },
        ]}
        defaultActions={[]}
      />
    </>
  );
};

export default InstallmentsTable;
