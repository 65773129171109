import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import { CustomCard } from "../../../../../../../../Components";
import { useTranslate } from "../../../../../../../../Hooks";

// styles
import useStyles from "./styles";

function PaymentPlanSharedComponent({ paymentItem }) {
  const styles = useStyles();
  const { translate } = useTranslate("NewPropertiesView");

  return (
    <CustomCard
      borderRadius="xl"
      borderColor="secondary"
      classes={styles.PaymentPlanSharedComponent}
    >
      <Box className={styles.containerHeader}>
        <Typography className={styles.mainTitle}>
          {paymentItem?.paymentPlanName}
        </Typography>
        <Divider />
      </Box>
      <Box className={styles.containerBody}>
        <Box className={styles.rowLayout}>
          <Box className={styles.containerLeftSide}>
            <Typography className={styles.titleItem}>{paymentItem?.downPayment || "0%"}</Typography>
            <Typography className={styles.titleItem}>{translate("Payment")}</Typography>
          </Box>
          <Typography className={styles.titleItem}>{translate("Down-payment")}</Typography>
        </Box>
        <Box className={styles.rowLayout}>
          <Box className={styles.containerLeftSide}>
            <Typography className={styles.titleItem}>{paymentItem?.monthlyInstallment || "0%"}</Typography>
            <Typography className={styles.titleItem}>{translate("Payment")}</Typography>
          </Box>
          <Typography className={styles.titleItem}>{translate("Installment")}</Typography>
        </Box>
        <Box className={styles.rowLayout}>
          <Box className={styles.containerLeftSide}>
            <Typography className={styles.titleItem}>{paymentItem?.handoverPayment || "0%"}</Typography>
            <Typography className={styles.titleItem}>{translate("Payment")}</Typography>
          </Box>
          <Typography className={styles.titleItem}>{translate("Handover")}</Typography>
        </Box>
        <Box className={styles.rowLayout}>
          <Box className={styles.containerLeftSide}>
            <Typography className={styles.titleItem}>{paymentItem?.postHandover || "0%"}</Typography>
            <Typography className={styles.titleItem}>{translate("Payment")}</Typography>
          </Box>
          <Typography className={styles.titleItem}>{translate("Post-Handover")}</Typography>
        </Box>
      </Box>
    </CustomCard>
  );
}
export default PaymentPlanSharedComponent;
