import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Box } from "@material-ui/core";

import useStyles from "./styles";

const PropertyCardSkeleton = ({ index }) => {
  const styles = useStyles();

  return (
    <Box
      key={`Property-CardSkeletonLoader-${index}`}
      className={styles.PropertyCardWrapper}
    >
      <Box className={styles.cardHeader}>
        <Skeleton animation="wave" height="240px" variant="rect" width="100%" />
      </Box>
      <Box className={styles.wrapper}>
        <Box className={styles.detailsWrapper}>
          <Box className={styles.nameContainer}>
            <Skeleton
              animation="wave"
              width="160px"
              height="50px"
              variant="text"
            />
            <Skeleton
              animation="wave"
              height="20px"
              variant="rect"
              width="20px"
            />
          </Box>
          <Box className={styles.idSection}>
            <Skeleton
              animation="wave"
              width="60px"
              height="30px"
              variant="text"
            />

            <Skeleton
              animation="wave"
              height="20px"
              variant="rect"
              width="20px"
            />

            <Skeleton
              animation="wave"
              width="40px"
              height="30px"
              variant="text"
            />

            <Skeleton
              className={styles.rounded}
              animation="wave"
              width="30px"
              variant="circle"
              height="20px"
            />
          </Box>
        </Box>

        <Box className={styles.flexRow}>
          <Skeleton
            animation="wave"
            width="120px"
            height="20px"
            variant="text"
          />
        </Box>

        <Box className={styles.flexRow}>
          <Skeleton
            className={styles.rounded}
            animation="wave"
            width="42px"
            variant="circle"
            height="20px"
          />

          <Skeleton
            animation="wave"
            height="20px"
            variant="rect"
            width="20px"
          />

          <Skeleton
            animation="wave"
            width="20px"
            height="20px"
            variant="text"
          />
        </Box>

        <Box className={styles.divider} />

        <Box className={styles.dateContainer}>
          <Skeleton
            animation="wave"
            width="58px"
            height="20px"
            variant="text"
          />

          <Box className={styles.dateText}>
            <Skeleton
              className={styles.avatar}
              animation="wave"
              width="24px"
              variant="circle"
              height="24px"
            />

            <Skeleton
              animation="wave"
              width="84px"
              height="20px"
              variant="text"
            />
          </Box>
        </Box>

        <Box className={styles.dateContainer}>
          <Skeleton
            animation="wave"
            width="84px"
            height="20px"
            variant="text"
          />

          <Box className={styles.dateText}>
            <Skeleton
              animation="wave"
              width="84px"
              height="20px"
              variant="text"
            />

            <Skeleton
              animation="wave"
              width="84px"
              height="20px"
              variant="text"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PropertyCardSkeleton;
