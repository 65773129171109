import {
  ContactsPermissions,
  ListingShortagePermissions,
  ZeroMatchingLeasePermissions,
  ZeroMatchingSalePermissions,
  UnitsSalesPermissions,
  UnitsLeasePermissions,
  DevelopersPermissions,
  PropertiesPermissionsCRM,
  LeadsCAllCenterPermissions,
  LeadsMortgagesPermissions,
  LeadsLeasePermissions,
  LeadsSalesPermissions,
  LeadOwnerLeadsPermissions,
} from "../Permissions";
import { returnPropsByPermissions } from "./ReturnPropsByPermissions.Helper";

export const ExceptionPermissionsHelper = () => {
  let userPermissions = localStorage.getItem("session");
  if (userPermissions) {
    userPermissions = JSON.parse(localStorage.getItem("session")).permissions;
    let view = false;
    let edit = false;
    var array = Object.values(ContactsPermissions);
    var res = userPermissions.filter((item1) =>
      array.some((item2) => item2.permissionsId === item1.permissionsId)
    );
    if (res && res.length === 2) {
      res.forEach((element) => {
        if (
          element.permissionsId ===
          ContactsPermissions.ViewContactInformation.permissionsId
        )
          view = true;
        else if (
          element.permissionsId ===
          ContactsPermissions.EditContactInformation.permissionsId
        )
          edit = true;
      });
    }
    if (res && view && edit) {
      return true;
    }
    return false;
  }
};

export const getIsFieldWithPermission = (field) => {
  if (field === "property_lock")
    return returnPropsByPermissions(
      PropertiesPermissionsCRM.UpdatePropertyLock.permissionsId
    );
  if (
    field === "assign_to_listing_shortage" ||
    field === "send_to_rotation_listing_shortage"
  )
    return returnPropsByPermissions(
      ListingShortagePermissions.AssignInquiry.permissionsId
    );
  else if (
    field === "assign_to_zero_matching_sale" ||
    field === "send_to_rotation_zero_matching_sale"
  )
    return returnPropsByPermissions(
      ZeroMatchingSalePermissions.AssignInquiry.permissionsId
    );
  else if (
    field === "assign_to_zero_matching_lease" ||
    field === "send_to_rotation_zero_matching_lease"
  )
    return returnPropsByPermissions(
      ZeroMatchingLeasePermissions.AssignInquiry.permissionsId
    );
  else return false;
};

export const canEditFieldWithPermissions = (fieldId, itemType) => {
  if (fieldId === "high_profile_management")
    return returnPropsByPermissions(
      ContactsPermissions.EditHighProfileManagement.permissionsId
    );
  else if (fieldId === "angry_bird")
    return returnPropsByPermissions(
      ContactsPermissions.EditAngryBirdField.permissionsId
    );
  else if (fieldId === "property_lock")
    return returnPropsByPermissions(
      PropertiesPermissionsCRM.UpdatePropertyLock.permissionsId
    );
  else if (
    itemType &&
    itemType.type === "unit" &&
    fieldId === "listing_agent"
  ) {
    if (itemType.name && itemType.name === "saleUnit")
      return returnPropsByPermissions(
        UnitsSalesPermissions.EditListingAgent.permissionsId
      );
    else if (itemType.name && itemType.name === "leaseUnit")
      return returnPropsByPermissions(
        UnitsLeasePermissions.EditListingAgent.permissionsId
      );
  } else if (
    itemType &&
    itemType.type === "unit" &&
    fieldId === "rent_listing_agent"
  ) {
    if (itemType.name && itemType.name === "saleUnit")
      return returnPropsByPermissions(
        UnitsSalesPermissions.EditLeaseListingAgent.permissionsId
      );
    else if (itemType.name && itemType.name === "leaseUnit")
      return returnPropsByPermissions(
        UnitsLeasePermissions.EditLeaseListingAgent.permissionsId
      );
  } else if (
    fieldId === "contact_consent_main" ||
    fieldId === "contact_consent-main"
  )
    return returnPropsByPermissions(
      ContactsPermissions.ChangeMainConsentStatus.permissionsId
    );
  else if (
    fieldId === "contact_consent_assets" ||
    fieldId === "contact_consent-assets"
  )
    return returnPropsByPermissions(
      ContactsPermissions.ChangeAssetsConsentStatus.permissionsId
    );
  else if (fieldId === "contact_dncr")
    return returnPropsByPermissions(
      ContactsPermissions.ChangeDNCRStatus.permissionsId
    );


  return true;
};

export const tabsPermissionsHelper = (tabsList) => {
  let userPermissions = localStorage.getItem("session");
  if (userPermissions) {
    userPermissions = JSON.parse(localStorage.getItem("session")).permissions;
    const tabsListWithPermissions = tabsList.filter((item1) =>
      userPermissions.some(
        (item2) => item2.permissionsId === item1.permissionsId
      )
    );
    return tabsListWithPermissions;
  }
};
export const getIsFieldWithPermissionOnTab = (formName, fieldItem) => {
  if (formName === "Developers") {
    if (
      fieldItem &&
      fieldItem.data &&
      fieldItem.data.description === "General Information"
    )
      return returnPropsByPermissions(
        DevelopersPermissions.EditDeveloperGeneralInformation.permissionsId
      );
    else if (
      fieldItem &&
      fieldItem.data &&
      fieldItem.data.description === "Key Contact"
    )
      return returnPropsByPermissions(
        DevelopersPermissions.EditKeyContact.permissionsId
      );
    else if (
      fieldItem &&
      fieldItem.data &&
      fieldItem.data.description === "Location Details"
    ) {
      if (
        fieldItem &&
        fieldItem.field &&
        (fieldItem.field.id === "district" ||
          fieldItem.field.id === "community" ||
          fieldItem.field.id === "sub_community" ||
          fieldItem.field.id === "map")
      )
        return returnPropsByPermissions(
          DevelopersPermissions.EditPrimaryLocation.permissionsId
        );

      return true;
    }
  } else return true;
};
