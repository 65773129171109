import React, { useContext } from "react";
import { Box, FormControlLabel, Radio, Avatar } from "@material-ui/core";
import { CustomCopyToClipboard } from "../../../../../../Components";
import { NewMergeContactsContext } from "../../../../../../Contexts/NewMergeContactsContext/NewMergeContactsContext";
import { SwapHoriz } from "@material-ui/icons";
import { useSelectedTheme } from "../../../../../../Hooks";
import { getDownloadableLink } from "../../../../../../Helper";
import { useSelector } from "react-redux";
import useStyles from "./styles";
function MergeContactCard({
  contact,
  onRowClicked,
  enableSwap,
  isFinalValuesContact = false,
  isOriginalContact = false,
}) {
  const styles = useStyles();
  const {
    translate,
    mergeRows,
    getIsSelected,
    getRowValue,
    changeOriginalCotnact,
  } = useContext(NewMergeContactsContext);

  const {
    theme: { palette },
  } = useSelectedTheme();
  const { isDarkMode } = useSelector((state) => state.theme);

  return (
    <Box className={styles.contact_merge_cards_full_wrapper}>
      <Box
        className={styles.contact_avatar_styles}
        style={isDarkMode ? { backgroundColor: "unset" } : {}}
      >
        {getDownloadableLink(
          contact?.contact?.contact_image?.["Image Upload"]?.[0].uuid
        ) && (
          <Avatar
            className={
              contact?.contact?.contact_image?.["Image Upload"]?.[0]
                ? styles.avatar
                : styles.defaultAvatar
            }
            src={
              contact?.contact?.contact_image?.["Image Upload"]?.[0]
                ? getDownloadableLink(
                    contact?.contact?.contact_image?.["Image Upload"]?.[0].uuid
                  )
                : contact?.contact?.contact_type_id === 2
                ? "/images/contacts/CorporateAvatar.svg"
                : contact?.contact?.gender?.lookupItemName === "Male"
                ? "/images/contacts/MaleAvatar.svg"
                : "/images/contacts/FemaleAvatar.svg"
            }
          />
        )}
      </Box>

      <Box className={styles.contact_merge_cards_inner_wrapper}>
        <Box className={styles.contact_merge_cards_upper_section}>
          {isFinalValuesContact ? (
            <span className={styles.merge_contacts_card_final_contact_title}>
              {translate("final_contact")}
            </span>
          ) : isOriginalContact ? (
            <span className={styles.merge_contacts_card_original_contact_title}>
              {translate("original_contact")}
            </span>
          ) : (
            <span className={styles.merge_contacts_card_archive_contact_title}>
              {translate("archive_contact")}
            </span>
          )}

          {isOriginalContact && (
            <span className={styles.current_merge_contact_badge}>
              {translate("current")}
            </span>
          )}
          {enableSwap && (
            <span className={styles.swap_merge_contact_badge}>
              <SwapHoriz
                width="20"
                height="20"
                fill={palette.foreground.secondary}
                onClick={() => {
                  changeOriginalCotnact(contact);
                }}
              />
            </span>
          )}
          <CustomCopyToClipboard
            data={contact?.contactsId}
            childrenData={`#${contact?.contactsId}`}
          />
        </Box>

        <Box className={styles.contact_merge_cards_rows_container}>
          {mergeRows?.map((row, index) => (
            <React.Fragment
              key={`${(contact?.contactsId, row?.fieldId, index)}`}
            >
              <div
                className={styles.contact_merge_cards_rows}
                onClick={() => {
                  if (onRowClicked && !isFinalValuesContact)
                    onRowClicked(row, contact);
                }}
              >
                {isFinalValuesContact ? (
                  <span className={styles.merge_contacts_cards_field_name}>
                    {translate(row?.fieldName)}
                  </span>
                ) : (
                  <FormControlLabel
                    value={null}
                    label={translate(row?.fieldName)}
                    className={styles.merge_contacts_cards_radio_field}
                    control={
                      <Radio
                        checked={getIsSelected(row, contact)}
                        color="primary"
                      />
                    }
                  />
                )}
                <span className={styles.merge_contacts_cards_field_value}>
                  {getRowValue(contact, row)}
                </span>
              </div>
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default MergeContactCard;
