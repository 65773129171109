import { HistoryComponent } from '../Sections/HistoryComponent';
import {
  UnitsSalesPermissions,
  UnitsLeasePermissions,
  LeadOwnerUnitSalePermissions,
  LeadOwnerUnitLeasePermissions,
} from '../../../Permissions';
import {
  ContactHistoryComponent,
  UnitHistoryComponent,
  LeadHistoryComponent,
  PropertyHistoryComponent,
  RotationSchemaHistoryComponent,
  ManageAgentsHistoryComponent,
} from '../Sections';
import { TransactionHistoryComponent } from '../Sections/TransactionHistoryComponent/TransactionHistoryComponent';
import { MergingHistoryComponent } from '../Sections/HistoryComponent/MergingHistoryComponent';
import LeadStageTracking from '../Sections/LeadStageTracking/LeadStageTracking';

export const HistoryComponentTabs = {
  history: [
    { label: 'history', component: HistoryComponent },
    { label: 'transaction-history', component: TransactionHistoryComponent },
  ],
  contactHistory: [
    { label: 'history', component: ContactHistoryComponent },
    { label: 'merging-history', component: MergingHistoryComponent },
  ],
  saleUnitHistory: [
    {
      label: 'history',
      component: UnitHistoryComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId: UnitsSalesPermissions.ViewUnitHistory.permissionsId,
    },
    {
      label: 'transaction-history',
      component: TransactionHistoryComponent,
      permissionsList: Object.values(UnitsSalesPermissions),
      permissionsId:
        UnitsSalesPermissions.ViewTransactionDetailsForUnit.permissionsId,
    },
  ],

  leaseUnitHistory: [
    {
      label: 'history',
      component: UnitHistoryComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId: UnitsLeasePermissions.ViewUnitHistory.permissionsId,
    },
    {
      label: 'transaction-history',
      component: TransactionHistoryComponent,
      permissionsList: Object.values(UnitsLeasePermissions),
      permissionsId:
        UnitsLeasePermissions.ViewTransactionsForUnit.permissionsId,
    },
  ],
  unitPropertyManagmentHistory: [
    { label: 'history', component: UnitHistoryComponent },
    { label: 'transaction-history', component: TransactionHistoryComponent },
  ],
  saleLeadsHistory: [
    { label: 'history', component: LeadHistoryComponent },
    { label: 'stage_tracking', component: LeadStageTracking },
  ],
  leaseLeadHistory: [
    { label: 'history', component: LeadHistoryComponent },
    { label: 'stage_tracking', component: LeadStageTracking },
  ],

  callCenterLeadHistory: [
    { label: 'history', component: LeadHistoryComponent },
    { label: 'stage_tracking', component: LeadStageTracking },
  ],

  propertyManagmentLeadHistory: [
    { label: 'history', component: LeadHistoryComponent },
    { label: 'stage_tracking', component: LeadStageTracking },
  ],

  propertyHistory: [{ label: 'history', component: PropertyHistoryComponent }],

  propertyManagmentHistory: [
    { label: 'history', component: PropertyHistoryComponent },
  ],

  LeadsMortgageHistory: [
    { label: 'history', component: LeadHistoryComponent },
    { label: 'stage_tracking', component: LeadStageTracking },
  ],

  contactLeadHistory: [
    { label: 'history', component: LeadHistoryComponent },
    { label: 'stage_tracking', component: LeadStageTracking },
  ],

  leadOwnerUnitSaleHistory: [
    {
      label: 'history',
      component: UnitHistoryComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId: LeadOwnerUnitSalePermissions.ViewUnitHistory.permissionsId,
    },
    {
      label: 'transaction-history',
      component: TransactionHistoryComponent,
      permissionsList: Object.values(LeadOwnerUnitSalePermissions),
      permissionsId:
        LeadOwnerUnitSalePermissions.ViewTransactionDetailsForUnit
          .permissionsId,
    },
  ],
  leadOwnerUnitLeaseHistory: [
    {
      label: 'history',
      component: UnitHistoryComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId:
        LeadOwnerUnitLeasePermissions.ViewUnitHistory.permissionsId,
    },
    {
      label: 'transaction-history',
      component: TransactionHistoryComponent,
      permissionsList: Object.values(LeadOwnerUnitLeasePermissions),
      permissionsId:
        LeadOwnerUnitLeasePermissions.ViewTransactionDetailsForUnit
          .permissionsId,
    },
  ],

  leadOwnerLeadHistory: [
    {
      label: 'history',
      component: LeadHistoryComponent,
    },
    { label: 'stage_tracking', component: LeadStageTracking },
  ],

  rotationSchema: [
    { label: 'history', component: RotationSchemaHistoryComponent },
  ],

  manageAgents: [{ label: 'history', component: ManageAgentsHistoryComponent }],
};
