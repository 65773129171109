import React from "react";
import { ProposalTypes } from "../../Enums/ProposalTypes";
import { CheckboxesComponent } from "../../../../../Components";
import { useTranslation } from "react-i18next";

const ThemeCards = ({ selected, setSelected }) => {
  const parentTranslationPath = "SmartProposal";
  const translationPath = "ProposalThemeDialog.";
  const { t } = useTranslation(parentTranslationPath);

  return (
    <div className="Proposal_selection_Content">
      <div className="Proposal_preview__Container">
        <div
          className={`Proposal_preview__Card ${
            selected.type.key === ProposalTypes.Premium.key ? "active" : ""
          }`}
          onClick={() =>
            setSelected((prevState) => ({
              ...prevState,
              type: ProposalTypes.Premium,
              isDark: false,
            }))
          }
        >
          <div className="Proposal_preview__Header">
            <p>{t(`${translationPath}Premium-proposal`)}</p>
            <span>
              <CheckboxesComponent
                idRef="ProposalPremiumRef"
                wrapperClasses="card-check"
                checkboxClasses="p-0"
                singleChecked={selected.type.key === ProposalTypes.Premium.key}
                themeClass="theme-propx"
              />
            </span>
          </div>
          <div className="Proposal_preview__Body">
            <div
              className={`Proposal_preview__Box ${
                selected.isDark
                  ? ProposalTypes.Premium.darkClass
                  : ProposalTypes.Premium.lightClass
              }`}
            ></div>
          </div>
        </div>
        <div
          className={`Proposal_preview__Card ${
            selected.type.key === ProposalTypes.Classic.key ? "active" : ""
          }`}
          onClick={() =>
            setSelected((prevState) => ({
              ...prevState,
              type: ProposalTypes.Classic,
              isDark: false,
            }))
          }
        >
          <div className="Proposal_preview__Header">
            <p>{t(`${translationPath}Classic-proposal`)}</p>
            <span>
              <CheckboxesComponent
                idRef="ProposalClassicRef"
                wrapperClasses="card-check"
                checkboxClasses="p-0"
                singleChecked={selected.type.key === ProposalTypes.Classic.key}
                themeClass="theme-propx"
              />
            </span>
          </div>
          <div className="Proposal_preview__Body">
            <div
              className={`Proposal_preview__Box ${
                selected.isDark
                  ? ProposalTypes.Classic.darkClass
                  : ProposalTypes.Classic.lightClass
              }`}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeCards;
