export const LocationAndamenitiesStyle = `

/*=================== First section css=================*/
        .locationAndAmenities {
            padding: 120px;
        }

        .locationAndAmenities .cover-x .img {
            width: 100px;
            height: 100px;
        }

        .locationAndAmenities_section_No1 .cover-x {
            position: relative;
            width: 2480px;
            background-color: rgba(255, 255, 255, 1);
        }

        .locationAndAmenities_section_No1 .cover-x .content {
            display: flex;
            flex-direction: column;
            width: 2240px;
            align-items: flex-start;
            gap: 64px;
            position: relative;
            border-radius: 50px;
        }

        .locationAndAmenities_section_No1 .cover-x .frame {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 64px;
            position: relative;
            align-self: stretch;
            width: 100%;
        }

        .locationAndAmenities_section_No1 .cover-x .div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 50px;
            position: relative;
            flex: 1;
            align-self: stretch;
            width: 100%;
            flex-grow: 1;
        }

        .locationAndAmenities_section_No1 .cover-x .frame-2 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            position: relative;
            align-self: stretch;
            width: 100%;
            flex: 0 0 auto;
        }

        .locationAndAmenities_section_No1 .cover-x .text-wrapper {
            position: relative;
            width: fit-content;
            margin-top: -1.00px;
            font-family: "DM Sans-Bold", Helvetica;
            font-weight: 700;
            color: rgba(45, 62, 80, 1);
            font-size: 72px;
            text-align: center;
            letter-spacing: 0;
            line-height: normal;
        }

        .locationAndAmenities_section_No1 .cover-x .frame-3 {
            display: inline-flex;
            align-items: flex-end;
            gap: 5px;
            position: relative;
            flex: 0 0 auto;
        }

        .locationAndAmenities_section_No1 .cover-x .marker-pin {
            position: relative;
            width: 58px;
            height: 58px;
        }

        .locationAndAmenities_section_No1 .cover-x .supporting-text {
            position: relative;
            width: fit-content;
            margin-top: -1.00px;
            font-family: "Inter-Medium", Helvetica;
            font-weight: 500;
            color: #00000080;
            font-size: 48px;
            letter-spacing: 0;
            line-height: normal;
        }

        .locationAndAmenities_section_No1 .cover-x .artboard {
            position: relative;
            flex: 1;
            align-self: stretch;
            width: 100%;
            flex-grow: 1;
            object-fit: cover;
            border-radius: 50px;
        }

        .locationAndAmenities_section_No1 .cover-x .frame-4 {
            display: inline-flex;
            align-items: center;
            gap: 25px;
            padding: 0px 50px;
            position: relative;
            flex: 0 0 auto;
        }

        .locationAndAmenities_section_No1 .cover-x .link-external {
            position: relative;
            width: 54px;
            height: 54px;
        }

        .locationAndAmenities_section_No1 .cover-x .text-wrapper-2 {
            position: relative;
            width: fit-content;
            margin-top: -1.00px;
            font-family: "Inter-Bold", Helvetica;
            font-weight: 700;
            color: rgba(59, 114, 217, 1);
            font-size: 42px;
            letter-spacing: 0;
            line-height: 62px;
            white-space: nowrap;
        }

        .locationAndAmenities_section_No1 .cover-x .frame-5 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 50px;
            position: relative;
            align-self: stretch;
            width: 100%;
            flex: 0 0 auto;
        }

        .locationAndAmenities_section_No1 .cover-x .text-wrapper-3 {
            position: relative;
            width: fit-content;
            margin-top: -1.00px;
            font-family: "Inter-Bold", Helvetica;
            font-weight: 700;
            color: #000000;
            font-size: 72px;
            text-align: center;
            letter-spacing: 0;
            line-height: normal;
        }

        .locationAndAmenities_section_No1 .cover-x .row {
            display: flex;
            justify-content: space-between;
            position: relative;
            align-self: stretch;
            width: 100%;
            flex: 0 0 auto;
        }

        .locationAndAmenities_section_No1 .cover-x .item {
            display: inline-flex;
            flex-direction: column;
            min-width: 252px;
            align-items: center;
            justify-content: center;
            gap: 32px;
            position: relative;
            flex: 0 0 auto;
        }

        .locationAndAmenities_section_No1 .cover-x .frame-6 {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            position: relative;
            flex: 0 0 auto;
        }

        .locationAndAmenities_section_No1 .cover-x .frame-6 .frame-6_1 {
            border-radius: 135.398px;
            background: rgba(0, 0, 0, 0.05);
            display: flex;
            padding: 27.08px;
            align-items: center;
            gap: 27.08px;
        }

        .locationAndAmenities_section_No1 .cover-x .frame-6_1 {
            position: relative;
            flex: 0 0 auto;
        }

        .locationAndAmenities_section_No1 .cover-x .div-wrapper {
            display: inline-flex;
            align-items: center;
            gap: 20px;
            position: relative;
            flex: 0 0 auto;
        }

        .locationAndAmenities_section_No1 .cover-x .text {
            position: relative;
            width: fit-content;
            margin-top: -1.00px;
            font-family: "Inter-Regular", Helvetica;
            font-weight: 400;
            color: #000000;
            font-size: 37px;
            text-align: center;
            letter-spacing: 0;
            line-height: normal;
        }

        .locationAndAmenities_section_No1 .cover-x .vector-1 {
            position: relative;
            align-self: stretch;
            width: 100%;
            height: 2px;
            background: #E5E5EA;
            opacity: 0.6;
        }

        .locationAndAmenities_section_No1 .cover-x .frame-7 {
            display: flex;
            flex-direction: column;
            width: 307px;
            align-items: center;
            justify-content: center;
            gap: 16px;
            position: relative;
            flex: 0 0 auto;
        }

        .locationAndAmenities_section_No1 .cover-x .frame-8 {
            display: flex;
            align-items: center;
            gap: 32px;
            position: relative;
            align-self: stretch;
            width: 100%;
            flex: 0 0 auto;
        }

        .locationAndAmenities_section_No1 .cover-x .text-2 {
            flex: 1;
            position: relative;
            margin-top: -1.00px;
            font-family: "Inter-Regular", Helvetica;
            font-weight: 400;
            color: #000000;
            font-size: 32px;
            letter-spacing: 0;
            line-height: normal;
        }

        .locationAndAmenities_section_No1 .cover-x .text-3 {
            width: fit-content;
            position: relative;
            margin-top: -1.00px;
            font-family: "Inter-Regular", Helvetica;
            font-weight: 400;
            color: #000000;
            font-size: 32px;
            letter-spacing: 0;
            line-height: normal;
        }

        .locationAndAmenities_section_No1 .cover-x .vector-2 {
            position: relative;
            width: 2px;
            height: 200px;
            background: #E5E5EA;
            opacity: 0.6;
        }

        .locationAndAmenities_section_No1 .cover-x .vector-3 {
            position: relative;
            width: 2px;
            height: 201px;
        }

        .locationAndAmenities_section_No1 .cover-x .item-2 {
            display: inline-flex;
            flex-direction: column;
            min-width: 252px;
            align-items: center;
            justify-content: center;
            gap: 32px;
            position: relative;
            flex: 0 0 auto;
        }


        /*=================== Second section css=================*/

        .locationAndAmenities_section_No2 .cover-x {
            position: relative;
            width: 2480px;
            background-color: rgba(255, 255, 255, 1);
        }

        .locationAndAmenities_section_No2 .cover-x .content {
            display: flex;
            flex-direction: column;
            width: 2240px;
            align-items: flex-start;
            gap: 120px;
            position: relative;
            top: 120px;
            border-radius: 50px;
        }

        .locationAndAmenities_section_No2 .cover-x .frame {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 120px;
            position: relative;
            align-self: stretch;
            width: 100%;
        }

        .locationAndAmenities_section_No2 .cover-x .div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 50px;
            position: relative;
            flex: 1;
            align-self: stretch;
            width: 100%;
            flex-grow: 1;
        }

        .locationAndAmenities_section_No2 .cover-x .div-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 10px;
            position: relative;
            align-self: stretch;
            width: 100%;
            flex: 0 0 auto;
        }

        .locationAndAmenities_section_No2 .cover-x .text-wrapper {
            position: relative;
            flex: 1;
            margin-top: -1.00px;
            font-family: "DM Sans-Bold", Helvetica;
            font-weight: 700;
            color: rgba(45, 62, 80, 1);
            font-size: 72px;
            letter-spacing: 0;
            line-height: normal;
        }

        .locationAndAmenities_section_No2 .cover-x .row-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;
            position: relative;
            flex: 1;
            align-self: stretch;
            width: 100%;
            flex-grow: 1;
        }

        .locationAndAmenities_section_No2 .cover-x .row {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 32px;
            position: relative;
            flex: 1;
            align-self: stretch;
            width: 100%;
            flex-grow: 1;
        }

        .locationAndAmenities_section_No2 .cover-x .col {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            row-gap: 32px;
            column-gap: 32px;
            position: relative;
            width: 100%;
        }

        .locationAndAmenities_section_No2 .cover-x .frame-2 {
            display: flex;
            flex-direction: column;
            width: 422px;
            align-items: center;
            justify-content: center;
            gap: 27.08px;
            /*padding: var(--3-spacing-spacing-3xl);*/
            position: relative;
            /*border-radius: var(--2-radius-radius-full);*/
        }

        .locationAndAmenities_section_No2 .cover-x .artboard {
            position: relative;
            width: 100px;
            height: 100px;
        }

        .locationAndAmenities_section_No2 .cover-x .frame-3 {
            display: inline-flex;
            align-items: center;
            gap: 20px;
            position: relative;
            flex: 0 0 auto;
        }

        .locationAndAmenities_section_No2 .cover-x .text {
            position: relative;
            width: fit-content;
            margin-top: -1.00px;
            font-family: "DM Sans-9ptRegular", Helvetica;
            font-weight: 400;
            color: rgba(45, 62, 80, 1);
            font-size: 37px;
            text-align: center;
            letter-spacing: 0;
            line-height: normal;
        }

        .locationAndAmenities_section_No2 .cover-x .frame-4 {
            display: flex;
            flex-direction: column;
            width: 422px;
            align-items: center;
            justify-content: center;
            gap: 27.08px;
            padding: 24px;
            position: relative;
            border-radius: 9999px;
        }

        .locationAndAmenities_section_No2 .cover-x .frame-5 {
            display: inline-flex;
            align-items: center;
            gap: 20px;
            position: relative;
            flex: 0 0 auto;
            margin-left: -19.50px;
            margin-right: -19.50px;
        }

`;
