import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ButtonBase } from "@material-ui/core";
import { showError } from "../../../../../Helper";
import excellogo from "../../../../../assets/images/defaults/microsoft_office_excel_logo.png";
import { HubspotComponentView } from "../../HubspotComponents/HubspotComponentView";

export const SearchAndAddComponent = ({
  parentTranslationPath,
  translationPath,
  state,
  schema,
  ifHaveAnyErrors,
  searchHandler,
  setIsOpenSaveDialog,
  exportDataToFormats,
  searchResponse,
  createHubSpotJson,
}) => {
  const { t } = useTranslation([parentTranslationPath, "Shared"]);
  const [isOpenHubSpot, setIsOpenHubSpot] = useState(false);
  const PullToHubspotContactsHandler = () => {
    if (!searchResponse?.result?.length) {
      showError(
        t(
          `${translationPath}dont-have-data-to-pull-to-hub-spot-contacts-please-select-data-and-search-before-pulling`
        )
      );
      return;
    } else setIsOpenHubSpot(true);
  };

  return (
    <div className="">
      <div className="save-wrapper d-inline-flex-center">
        <div className="d-flex-center">
          <ButtonBase
            className="btns theme-solid bg-cancel c-secondary px-2 "
            onClick={() => PullToHubspotContactsHandler()}
          >
            <span className="mdi mdi-source-pull" />
            {t(`${translationPath}pull-contacts-to-hubspot`)}
          </ButtonBase>
        </div>
        <div className="excelPart">
          {" "}
          <ButtonBase
            className="MuiButtonBase-root MuiButton-root MuiButton-text table-action-btn btns miw-0"
            tabIndex="0"
            id="export-ref"
            type="button"
            onClick={() => exportDataToFormats("xls", "ReportView")}
          >
            <span className="MuiButton-label">
              <div className="">
                <img src={excellogo} alt="arrowImage" className="excelImage1" />
              </div>
              <span className="px-1 info fw-normal">
                {t(`${translationPath}export-excel`)}
              </span>
            </span>
            <span className="MuiTouchRipple-root" />
          </ButtonBase>
        </div>
      </div>
      {isOpenHubSpot && (
        <HubspotComponentView
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          onClose={() => setIsOpenHubSpot(false)}
          isOpen={isOpenHubSpot}
          state={state}
          createHubSpotJson={createHubSpotJson}
          totalCount={
            searchResponse?.totalCount ||
            (searchResponse?.result &&
              searchResponse?.result?.length > 0 &&
              searchResponse?.result[0] &&
              searchResponse?.result[0].TotalCount) ||
            1
          }
        />
      )}
    </div>
  );
};

SearchAndAddComponent.propTypes = {
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string.isRequired,
  state: PropTypes.instanceOf(Object).isRequired,
  schema: PropTypes.instanceOf(Object).isRequired,
  searchHandler: PropTypes.func.isRequired,
  setIsOpenSaveDialog: PropTypes.func.isRequired,
  ifHaveAnyErrors: PropTypes.bool.isRequired,
  exportDataToFormats: PropTypes.func.isRequired,
};
