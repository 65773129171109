import moment from "moment";

const formatDisplayDate = (date, formatStr) => date ? moment(date).format(formatStr) : null;

const formatStandardDate =  (date) => date ? moment(date, "DD MMM YYYY").utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null;

const formatStandardTimezoneDate =  (date) => date ? moment(date).format("YYYY-MM-DDTHH:mm:ss.SSSSSSSZ") : null;

export {
    formatDisplayDate,
    formatStandardDate,
    formatStandardTimezoneDate
}