import { useCallback, useReducer } from "react";
import { GetAllLeads, OrganizationUserSearch } from "../../../../../Services";
import { LeadsStatusEnum } from "../../../../../Enums";

const useGetStartProposalData = () => {

  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [data, setData] = useReducer(reducer, {
    leads: [],
    systemUsers: [],
  });

  const getSystemUsers = async (value) => {
    const res = await OrganizationUserSearch({
      pageIndex: 0,
      pageSize: 10,
      name: value || "",
      userStatusId: 2,
    });
    if (!(res && res.status && res.status !== 200)) {
      const localValue = (res && res.result) || [];
      if (localValue.length > 0)
        setData({ id: "systemUsers", value: localValue });
      else setData({ id: "systemUsers", value: [] });
    }
  };

  const getLeads = async (value) => {
    const response = await GetAllLeads({
      pageIndex: 1,
      pageSize: 10,
      search: value || "",
      leadStatus: LeadsStatusEnum.Open.status,
      leadTypes: null,
    });
    if (!(response && response.status && response.status !== 200))
      setData({ id: "leads", value: response?.result || [] });
    else setData({ id: "leads", value: [] });
  };

  return { getLeads, getSystemUsers, data };
};

export default useGetStartProposalData;
