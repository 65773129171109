export const ProposalTypes = {
    Classic: {
        key: 1,
        name: "Classic",
        lightClass: "classic_light",
        darkClass: "classic_light",
        isDarkAvailable: false,
    },
    Premium: {
        key: 2,
        name: "Premium",
        lightClass: "premium_light",
        darkClass: "premium_dark",
        isDarkAvailable: true,
    }
}