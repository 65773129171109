import { config } from "../../../../../config/config.jsx";

const formatLocationAndamenities = (data) => {
    const { nearestLandmarks, facilitiesAndAmenities, propertyLocation } = data;

    //MAP PARAMS :
    const zoom = 16;
    const size = "600x200"
    const maptype = "roadmap";
    const markersColor = "red";
    const coordinates = (propertyLocation?.lat && propertyLocation?.long) ?
     `${propertyLocation?.lat},${propertyLocation?.long}`
      : null;

    return {
        nearestLandmarks,
        facilitiesAndAmenities,
        propertyLocation: {
            //todo:: find better way to format location text
            locationText: (propertyLocation?.country ? `${propertyLocation.country},` : "") +
             (propertyLocation?.city ? `${propertyLocation.city},` : "") +
              (propertyLocation?.community ? `${propertyLocation.community},` : "") +
               (propertyLocation?.subCommunity ? `${propertyLocation.subCommunity}` : ""),
            mapImageUrl: coordinates?
             `https://maps.googleapis.com/maps/api/staticmap?center=${coordinates}&zoom=${zoom}&size=${size}&maptype=${maptype}&markers=color:${markersColor}%7C${coordinates}&key=${config.GoogleMapApiKey}` : null,
            mapLink: coordinates? `https://www.google.com/maps?q=${coordinates}` : null,
        }
    }

}

export default formatLocationAndamenities;