export const MultiUnitsTableTemplate = `
    <div class="MultiUnitsPage">
    <div class="title-wrapper">
       <div class="title-text-wrapper">Unit details</div>
    </div>
    <div class="MultiUnitsTable">
      <div class="section">
        <div class="row">
          <div class="col">
            <div class="div">
              <div class="frame"><div class="text">Unit ref NO.</div></div>
              <div class="text-wrapper"><div class="text">Unit type</div></div>
              <div class="text-wrapper"><div class="text">Height Category</div></div>
              <div class="text-wrapper"><div class="text">Unit Model</div></div>
              <div class="text-wrapper"><div class="text">Maid rooms</div></div>
              <div class="text-wrapper"><div class="text">Bedrooms</div></div>
              <div class="text-wrapper"><div class="text-2">size (Sq.Ft)</div></div>
              <div class="text-wrapper"><div class="text-2">price (AED)</div></div>
              <div class="text-wrapper"></div>
            </div>
           {{#MultiUnitsDetails.selectedUnits}}
            <div class="row-2">
              <div class="text-wrapper"><div class="text-3">{{unitRefNo}}</div></div>
              <div class="text-wrapper"><div class="text-3">{{unitType}}</div></div>
              <div class="text-wrapper"><div class="text-3">{{heightCategory}}</div></div>
              <div class="text-wrapper"><div class="text-3">{{unitModel}}</div></div>
              <div class="text-wrapper"><div class="text-3">{{maidRoom}}</div></div>
              <div class="text-wrapper"><div class="text-3">{{bedroom}}</div></div>
              <div class="text-wrapper"><div class="text-4">{{totalAreaSize}}</div></div>
              <div class="text-wrapper"><div class="text-4">{{price}}</div></div>
              <div class="div-wrapper">
                <div class="buttons-button-2">
                <img class="img" src="https://psi-crm.com/ProposalIcons/download-02.svg" />
               </div>
              </div>
            </div>
           {{/MultiUnitsDetails.selectedUnits}}
          </div>
        </div>
      </div>
    </div>
    </div>

`;
