import { put, takeEvery } from 'redux-saga/effects';
import { SmartProposalActions } from './Actions';
import { SmartProposalStatus } from './Status';

function* fetchSmartProposal(action) {
  try {
    localStorage.setItem('SmartProposal', JSON.stringify(action.payload));
    yield put(SmartProposalActions.SmartProposalSuccess(action.payload));
  } catch (err) {
    yield put(SmartProposalActions.SmartProposalError(err));
  }
}
export function* watchSmartProposal() {
  yield takeEvery(SmartProposalStatus.REQUEST, fetchSmartProposal);
}
