import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  new_merge_view_page_wrapper: {
    height: "fit-content",
  },
  cancelButton: {
    height: "40px",
    width: "80px",
    backgroundColor: theme.palette.button.secondary_bg,
    color: theme.palette.button.secondary_fg,
    border: `1px solid ${theme.palette.button.secondaryColor_border}`,
    borderRadius: "8px",
    padding: "10px 14px",
    "&:hover": {
      border: `1px solid ${theme.palette.button.secondaryColor_hover}`,
    },
  },
  mergeButton: {
    height: "40px",
    width: "140px",
    backgroundColor: theme.palette.button.primary_bg,
    color: theme.palette.button.primary_fg,
    border: `1px solid ${theme.palette.button.primary_border}`,
    padding: "10px 14px",
    borderRadius: "8px",
    "&:hover": {
      border: `1px solid ${theme.palette.button.primary_border_hover}`,
    },
  },

  bread_crumbs_merge_name_styles: {
    padding: "4px 8px",
    backgroundColor: theme.palette.border.avatarContrast,
    gap: "2px",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "28px",
    maxWidth: "fit-content",
  },
}));
