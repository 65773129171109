import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { CustomBadge, CustomButton } from "../../../../../../../../Components";
import { useSelectedTheme, useTranslate } from "../../../../../../../../Hooks";
import { PaymentPlanSharedComponent } from "../";
import { GetAllPaymentPlans } from "../../../../../../../../Services/PaymentPlanServices";
import { formatPaymentPlansForTableFields } from "../../../../../../PaymentPlans/models";

// styles
import useStyles from "./styles";

function PaymentPlanComponent({ setTabValue, propretyId }) {
  const styles = useStyles();
  const { translate } = useTranslate("NewPropertiesView");
  const {
    theme: { palette },
  } = useSelectedTheme();

  const initialFilter = (propertyId) => ({
    paymentPlanName: null,
    propertyPlanStatus: null,
    developerName: null,
    propertyId,
    isForSpecificProperty: !!propertyId,
  });
  const pagination = { pageIndex: 1, pageSize: 25 };
  const filter = initialFilter(propretyId);
  const [paymentData, setPaymentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllPaymentPlans = useCallback(async () => {
    setIsLoading(true);
    try {
      const body = { ...pagination, ...filter };
      const res = await GetAllPaymentPlans(body);
      res.result = formatPaymentPlansForTableFields(res.result);
      setPaymentData(res);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getAllPaymentPlans();
  }, []);

  return (
    <Box className={styles.wrapperPayment}>
      <Box className={styles.containerHeader}>
        <Box className={styles.containerLeftSide}>
          <Typography className={styles.containerMainTitle}>
            {translate("Payment-plan")}
          </Typography>
          <CustomBadge
            label={`${paymentData?.totalCount} Payment plan`}
            SizeVariant={"large"}
            BackgroundColor={palette.utility["brand_50"]}
            BorderColor={palette.utility["brand_200"]}
            Color={palette.utility["brand_700"]}
          />
        </Box>
        {paymentData?.result?.length > 0 && (
          <CustomButton
            boxShadow="none"
            size="sm"
            variant="text"
            color="tertiaryGray"
            style={{ minWidth: "fit-content" }}
            onClick={() => {
              setTabValue(8);
            }}
          >
            {translate("View-All")}
          </CustomButton>
        )}
      </Box>
      <Box className={styles.cardWrapper}>
        <Box className={styles.containerSections}>
          {paymentData &&
            paymentData?.result?.length > 0 &&
            paymentData?.result
              ?.slice(0, 2)
              ?.map((paymentItem) => (
                <PaymentPlanSharedComponent
                  key={paymentItem?.id}
                  paymentItem={paymentItem}
                />
              ))}
        </Box>
      </Box>
    </Box>
  );
}
export default PaymentPlanComponent;
