import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { CustomBadge, CustomButton } from "../../../../../../../../Components";
import {
  useSelectedTheme,
  useTranslate,
} from "../../../../../../../../Hooks";
import { UnitsAdvanceSearchTest } from "../../../../../../../../Services";
import { UnitMapper } from "../../../../../../UnitsSalesView/UnitMapper";
import { UnitPropertyCard } from "..";

// styles
import useStyles from "./styles";

function UnitesComponent({ setTabValue, propretyId }) {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { translate } = useTranslate("NewPropertiesView");
  const [dataUnit, setDataUnit] = useState({
    result: [],
    totalCount: 0,
  });
  const [isloadingUnits, setIsLoadingUnits] = useState(false);
  const getUnitsSale = async () => {
    setIsLoadingUnits(true);
    try {
      const criteria = {
        propertyId: [
          {
            searchType: 1,
            value: propretyId,
          },
        ],
      };
      const body = {
        criteria,
        filterBy: "createdOn",
        orderBy: 2,
        OperationType: "Sale",
      };
      const res = await UnitsAdvanceSearchTest(
        { pageIndex: 0, pageSize: 10 },
        body
      );
      if (!(res && res.status && res.status !== 200)) {
        const units = ((res && res.result) || []).map(
          (item) =>
            item.unitJson && UnitMapper(item, JSON.parse(item.unitJson).unit)
        );
        setDataUnit({
          result: units || [],
          totalCount: res.totalCount,
        });
      } else
        setDataUnit({
          result: [],
          totalCount: 0,
        });
    } catch (error) {
    } finally {
      setIsLoadingUnits(false);
    }
  };
  useEffect(() => {
    getUnitsSale();
  }, []);

  return (
    <Box className={styles.wapperUnitsComponent}>
      <Box className={styles.containerHeader}>
        <Box className={styles.containerLeftHeader}>
          <Typography className={styles.containerMainTitle}>
            {translate("Unites")}
          </Typography>
          <CustomBadge
            label={dataUnit?.totalCount}
            SizeVariant={"large"}
            BackgroundColor={palette.utility["brand_50"]}
            BorderColor={palette.utility["brand_200"]}
            Color={palette.utility["brand_700"]}
          />
        </Box>
        {dataUnit.result.length > 0 && (
          <CustomButton
            boxShadow="none"
            size="sm"
            variant="text"
            color="tertiaryGray"
            style={{ minWidth: "fit-content" }}
            onClick={() => {
              setTabValue(9);
            }}
          >
            {translate("View-All")}
          </CustomButton>
        )}
      </Box>
      <Box className={styles.containerCards}>
        {isloadingUnits === true ? (
          // skeleton
          <></>
        ) : dataUnit.result.length > 0 ? (
          <>
            {dataUnit.result.length > 0 &&
              dataUnit.result
                .slice(0, 4)
                .map((dataUnitCard, index) => (
                  <UnitPropertyCard key={index} dataUnit={dataUnitCard} />
                ))}
          </>
        ) : (
          // empty state 
          <></>
        )}
      </Box>
    </Box>
  );
}
export default UnitesComponent;
