import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import DragDropPicker from "../../Utilities/DragDropPicker/DragDropPicker";
import { getPublicDownloadableLink } from "../../../../../Helper";


const FloorPlanForm = ({ localFormChangeHandler, localFormSelection }) => {
  const parentTranslationPath = "SmartProposal";
  const translationPath = "FloorPlanForm.";
  const { t } = useTranslation(parentTranslationPath);
  const maxCheckedNo = 4;
  const maxPickerItmesNo = 6;


  const initializeFloorPlanImages = () => {
    const selectedImages = [...(localFormSelection?.floorPlanImages || [])];
    const publishedImages = [
      ...(localFormSelection?.data?.floorPlanImages || []),
    ];

    publishedImages.forEach((item, index) => {
      //Move selected image to first index
      const lastCheckedIndex = selectedImages.reduce(
        (lastIndex, currentItem, idx) =>
          currentItem.isChecked ? idx : lastIndex,
        -1
      );
      const targetIndex = lastCheckedIndex + 1;
      const selectedImageIndex = index;

      const isImageSelected =
        selectedImages.findIndex((element) => element.id === item.fileId) !==
        -1;

      if (isImageSelected) {
        //Make image checked
        const checkedItem = {
          ...item,
          isChecked: true,
        };
        publishedImages.splice(selectedImageIndex, 1);
        publishedImages.splice(targetIndex, 0, checkedItem);
      }
    });

    return publishedImages;
  };

  const floorPlanPublishedImages = useMemo(
    () => initializeFloorPlanImages() || [],
    [localFormSelection]
  );

  const floorPlanSelectHandler = useCallback((result) => {
    const pickedImagesIds =
      (result && Array.isArray(result) && result.map((item) => item.fileId)) ||
      [];
    const floorPlanImageURLs = pickedImagesIds.map((id) =>
      id? getPublicDownloadableLink(id) : null
    );

    const floorPlanImages = pickedImagesIds.map((_, index) => ({
      url: floorPlanImageURLs[index],
      id: pickedImagesIds[index],
    }));

    localFormChangeHandler("floorPlanImages", floorPlanImages);
  }, [localFormChangeHandler]);

  return (
    <>
      <div className="ProposalForm__field_box">
        <div className="ProposalForm__field_label">
          <p>Floor plan images</p>
          <p>{`You can select up to ${maxCheckedNo}`}</p>
        </div>
        <div>
          <DragDropPicker
            onChange={floorPlanSelectHandler}
            itemsList={floorPlanPublishedImages}
            maxChecked={maxCheckedNo}
            maxPickerItmes={maxPickerItmesNo}
          />
        </div>
      </div>
    </>
  );
};

export default FloorPlanForm;
