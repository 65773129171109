import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  wrapperAddNewContactDialog: {
    display: "flex",
    flexDirection: "row",
  },
  containerContactInfo: {
    display: "flex",
    flexDirection: "column",
    backgroundColor:theme.palette.utility.utility_gray_200,
    alignItems:'flex-start',
    padding:'20px 24px 20px 24px',
    gap:'16px',
    width:'336px',
    minHeight:'546px'
  },
  containerContactFiled: {
    display: "flex",
    flexDirection: "column",
    gap:'8px',
    width:'100%',
    padding:'20px 24px',
    minHeight:'546px',
    gap:'16px'

  },containerInfoItem:{
    display: "flex",
    flexDirection: "column",
    gap:'8px'
  },continerFiledItem:{
    display:'flex',
    flexDirection:'row',
    gap:'32px'
  },TitleFiledItem:{
    minWidth:'160px',
    maxWidth:'160px',
    color:theme.palette.text.secondary,
    fontSize:'14px',
    fontWeight:'600',
    lineHeight:'20px'
  }, divider: {
    backgroundColor: theme.palette.border.secondary,
  },InfoValue:{
    color:theme.palette.text.secondary,
    fontSize:'16px',
    fontWeight:'600',
    lineHeight:'24px'
  },InfoTitle:{
    color:theme.palette.text.tertiary,
    fontSize:'14px',
    fontWeight:'500',
    lineHeight:'20px'
  },containerContentDialog:{
    padding: `20px 0px 20px 0px !important`
  }
}));
