/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  Fragment,
} from 'react';
import Button from '@material-ui/core/Button';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import i18next from 'i18next';
import { ButtonBase, Tooltip } from '@material-ui/core';
import {
  AMLStatusEnum,
  ActionsEnum,
  ContactTypeEnum,
  LoadableImageEnum,
  UnitsOperationTypeEnum,
  UnitsStatusEnum,
} from '../../../../../Enums';
import {
  getDownloadableLink,
  GlobalHistory,
  showError,
  showSuccess,
  sideMenuIsOpenUpdate,
  returnPropsByPermissions,
  returnPropsByPermissions2,
} from '../../../../../Helper';
import {
  DialogComponent,
  FacebookGalleryComponent,
  LoadableImageComponant,
  PermissionsComponent,
  PopoverComponent,
  SelectComponet,
  Spinner,
} from '../../../../../Components';
import {
  archiveUnitsPut,
  CanMakeQuickActions,
  CanSetUnitAsReservedOrLeasedOrSale,
  GetFieldsNeedingApprovalForChange,
  GetListingAgentDetails,
  GetPendingUserFields,
  GetSuitableTemplateIdForUnit,
  GetUnitAvailableStatus,
} from '../../../../../Services';
import Chip from '@material-ui/core/Chip';
import { Business, Email, PhoneEnabled } from '@material-ui/icons';
import { UnitStatusDraftDialog } from './Dialogs';
import { ActivitiesManagementDialog } from '../../../../../SharedComponents/ActivitiesManagementDialog/ActivitiesManagementDialog';
import {
  UnitsLeasePermissions,
  UnitPermissions,
  LeaseTransactionJourneyPermissions,
} from '../../../../../Permissions';
import { UnitStatusAvailableDialog } from './Dialogs/UnitStatusAvailableDialog/UnitStatusAvailableDialog';
import { ArchiveState } from '../../../../../assets/json/StaticValue.json';
import { CopyToClipboardComponents } from '../../../../../ReusableComponents/UtilityComponents/CopyToClipboardComponents/CopyToClipboardComponents';
import ActivityDetailsComponent from '../../../../DFMSharedComponents/ActivityDetails/ActivityDetailsComponent';
import { ShareUnitsDialog } from '../../../UnitsView/UnitsUtilities/Dialogs';
import {
  TransactionStepsData,
  TransactionUnitSelectionDialog,
} from '../../../LeaseTransactionJourney/TransactionSteps';
import { useSelector } from 'react-redux';
import { UnitTemplateDialogPreviewDialog } from '../../../UnitsView/UnitsProfileManagementView/Dialogs/UnitTemplateDialog/UnitTemplateDialogPreviewDialog';
import { UnitMPIGaugeChartComponent } from '../../../UnitsSalesView/UnitsSalesUtilities/CardDetailsComponent/UnitMPIGaugeChartComponent/UnitMPIGaugeChartComponent';
import { StaticLookupsIds } from '../../../../../assets/json/StaticLookupsIds';


function CardDetailsComponent({
  activeData,
  cardDetailsActionClicked,
  reloadData,
  parentTranslationPath,
  translationPath,
  from,
  displyOpenFileButton,
  onClickActiveData = () => {},
}) {
  const loginResponse = useSelector((state) => state.login.loginResponse);
  const [isPropertyManagementView, setIsPropertyManagementView] =
    useState(false);
  const pathName = window.location.pathname
    .split('/home/')[1]
    .split('/view')[0];

  const [pendingUserFields, setPendingUserFields] = useState([]);
  const [shouldDisableFields, setShouldDisableFields] = useState(false);
  const [fieldsNeedsApproval, setFieldsNeedsApproval] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [unitAvailableStatuses, setUnitAvailableStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [isOpenDraftDialog, setIsOpenDraftDialog] = useState(false);
  const [isOpenShareUnitDialog, setIsOpenShareUnitDialog] = useState(false);
  const [activitydetails, setActivityDetails] = useState(false);
  const [isTransactionStartOpen, setIsTransactionStartOpen] = useState(false);
  const [attachedWith, setAttachedWith] = useState(null);
  const popoverRef = useRef();
  const [templatesProposal, setTemplatesProposal] = useState([]);
  const [activeTemplateId, setActiveTemplateId] = useState(-1);
  const [templates, setTemplates] = useState([]);
  const [activeTemplateName, setActiveTemplateName] = useState(null);
  const [isOpenTemplateDialog, setIsOpenTemplateDialog] = useState(false);
  const [isOpenAvailableDialog, setIsOpenAvailableDialog] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(null);
  const [addActivity, setAddActivity] = useState(false);
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [makeTransactionOnUnitList, setMakeTransactionOnUnitList] = useState(
    []
  );
  const [listingAgent, setListingAgent] = useState({});
  const [canMakeQuickActions, setCanMakeQuickActions] = useState(false);

  const archiveUnits = useCallback(async () => {
    await archiveUnitsPut(activeData.id);
    showSuccess(t(`${translationPath}Successarchive`));
    sideMenuIsOpenUpdate(false);
    setOpen(false);
    reloadData();
  }, [activeData.id, reloadData, t, translationPath]);
  const unitOperationSource = {
    sale: {
      key: 1,
    },
    lease: {
      key: 2,
    },
  };
  const getIsAlloweGetUnitAvailableStatusus = () =>
    returnPropsByPermissions(
      UnitsLeasePermissions.MakeTransactionOnUnitLeaseToBeAvailable
        .permissionsId
    );
  const getIsAlloweGetUnitReservedOrLeased = () =>
    returnPropsByPermissions2(
      UnitsLeasePermissions.SetUnitAsLease.permissionsId,
      UnitsLeasePermissions.SetUnitAsReserveForLease.permissionsId
    );

  const GetCanSetUnitAsReservedOrLeasedOrSale = async (newStatus) => {
    const res = await CanSetUnitAsReservedOrLeasedOrSale(
      activeData.id,
      newStatus
    );
    if (res) {
      GlobalHistory.push(
        `/home/units-lease/unit-status-management?status=${newStatus}&id=${activeData.id}&from=${from}&rowVersion=${activeData.rowVersion}`
      );
    } else {
      setSelectedStatus(null);
      showError(
        t(`${translationPath}There-is-no-lease-lead-owner-for-this-unit`)
      );
    }
  };

  const onSelectChanged = async (newStatus) => {
    setSelectedStatus(newStatus);
    if (newStatus === UnitsStatusEnum.Draft.key) {
      setIsOpenDraftDialog(true);
      return;
    }

    if (
      newStatus === UnitsStatusEnum.Available.key &&
      !isPropertyManagementView
    ) {
      setIsOpenAvailableDialog(true);
      return;
    }

    if (
      newStatus === UnitsStatusEnum.Available.key &&
      isPropertyManagementView &&
      getIsAlloweGetUnitAvailableStatusus()
    ) {
      setIsOpenAvailableDialog(true);
      return;
    }

    if (
      !isPropertyManagementView ||
      (isPropertyManagementView && getIsAlloweGetUnitReservedOrLeased())
    )
      GetCanSetUnitAsReservedOrLeasedOrSale(newStatus);
    else showError(t(`${translationPath}Dont-have-permissions-in-this-role`));
  };

  const getUnitAvailableStatus = useCallback(async () => {
    setIsLoading(true);
    const res = await GetUnitAvailableStatus(
      activeData.id,
      UnitsOperationTypeEnum.rent.key
    );
    if (!(res && res.status && res.status !== 200))
      setUnitAvailableStatuses(res);
    else setUnitAvailableStatuses([]);
    setIsLoading(false);
  }, [activeData.id]);
  const getListingAgentDetails = async (userId) => {
    const result = await GetListingAgentDetails(userId);
    setListingAgent(result);
  };
  const GetSuitableTemplateIdForUnitApi = useCallback(async (unitId) => {
    setIsLoading(true);
    let res;
    res = await GetSuitableTemplateIdForUnit(
      unitId,
      1,
      10000,
      UnitsOperationTypeEnum.rent.key
    );
    if (!((res && res.data && res.data.ErrorId) || !res))
      setTemplatesProposal((res && res.result) || []);
    else setTemplatesProposal([]);
    setIsLoading(false);
  }, []);
  useEffect(() => {
    getTransactionListForUnit();
    getListingAgentDetails(activeData.rent_listing_agent?.id);
  }, [activeData, unitAvailableStatuses]);

  useEffect(() => {
    if (activeData && activeData.id) getUnitAvailableStatus();
    setSelectedStatus(null);
  }, [getUnitAvailableStatus]);

  const getDefaultUnitImage = useCallback(
    (unitType) =>
      ContactTypeEnum[unitType] && ContactTypeEnum[unitType].defaultImg,
    []
  );

  const checkIsUnitAvailable = () =>
    (activeData?.leaseUnitStatus?.name ||
      activeData?.leaseUnitStatusDetails?.name) === AMLStatusEnum[1].name ||
    null;
  const checkIsTransactionDone = () =>
    (activeData?.leaseUnitStatus?.name ||
      activeData?.leaseUnitStatusDetails?.name) === AMLStatusEnum[3].name ||
    null;

  const checkIfHasLeaseAgentRole = () =>
    loginResponse.roles.findIndex(
      (role) => role.rolesName === 'Lease Agent'
    ) !== -1;
  const checkIsUnitInTransaction = () =>
    ((activeData?.leaseUnitStatus?.name ||
      activeData?.leaseUnitStatusDetails?.name) !== AMLStatusEnum[1].name &&
      (activeData?.leaseUnitStatus?.name ||
        activeData?.leaseUnitStatusDetails?.name) !== AMLStatusEnum[3].name &&
      (activeData?.leaseUnitStatus?.name ||
        activeData?.leaseUnitStatusDetails?.name) !== AMLStatusEnum[10].name) ||
    null;

  const checkCanMakeQuickActions = async () => {
    const can = await CanMakeQuickActions({
      unitId: activeData.id,
      operationTypeId: StaticLookupsIds.Rent,
    });
    setCanMakeQuickActions(can);
  };
  useEffect(() => {
    if (pathName === 'units-property-management')
      setIsPropertyManagementView(true);
    else setIsPropertyManagementView(false);
  }, [pathName]);

  const getTransactionListForUnit = () => {
    const list = [];
    const transactionList = Object.values(UnitsStatusEnum).filter(
      (item) =>
        item.showInSelect &&
        (!item.effectedOperationType ||
          activeData.operationType === item.effectedOperationType ||
          activeData.operationType ===
            UnitsOperationTypeEnum.rentAndSale.key) &&
        unitAvailableStatuses.findIndex((element) => element === item.key) !==
          -1
    );
    if (canMakeQuickActions) {
      setMakeTransactionOnUnitList(transactionList);
      return;
    }
    transactionList.length &&
      transactionList.map((item) => {
        if (
          item &&
          item.value === 'available' &&
          returnPropsByPermissions(
            UnitsLeasePermissions.MakeTransactionOnUnitLeaseToBeAvailable
              .permissionsId
          )
        )
          list.push(item);
        if (
          item &&
          item.value === 'reserved-for-lease' &&
          returnPropsByPermissions(
            UnitsLeasePermissions.SetUnitAsReserveForLease.permissionsId
          )
        )
          list.push(item);
        else if (
          item &&
          item.value === 'leased' &&
          returnPropsByPermissions(
            UnitsLeasePermissions.SetUnitAsLease.permissionsId
          )
        )
          list.push(item);
        else if (
          item &&
          item.value === 'draft' &&
          returnPropsByPermissions(
            UnitsLeasePermissions.MakeTransactionOnUnitLeaseToBeDraft
              .permissionsId
          )
        )
          list.push(item);
      });
    setMakeTransactionOnUnitList(list);
  };

  useEffect(() => {
    getTransactionListForUnit();
  }, [activeData, unitAvailableStatuses, canMakeQuickActions]);

  const getPendingUserFields = async () => {
    const unitId = activeData?.id;
    const res = await GetPendingUserFields(unitId);
    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setPendingUserFields(res.pendingFields);
      return res;
    }
  };

  const getFieldsNeedingApproval = async () => {
    const userRoleIds = loginResponse?.roles.map((item) => item?.rolesId);

    const res = await GetFieldsNeedingApprovalForChange({ userRoleIds });
    if (!(res && res.status && res.status !== 200)) {
      setFieldsNeedsApproval(res);
    } else setFieldsNeedsApproval([]);
  };
  useEffect(() => {
    getPendingUserFields();
    getFieldsNeedingApproval();
    checkCanMakeQuickActions();
  }, [activeData]);

  useEffect(() => {
    if (pendingUserFields?.includes('Status')) {
      setShouldDisableFields(true);
    } else {
      setShouldDisableFields(false);
    }
  }, [pendingUserFields]);
  const AgentListingDetails = () => {
    return (
      <Fragment>
        <div className='p-3 cards-wrapper'>
          <div className='card-header d-flex-v-center-h-between mb-4'>
            <LoadableImageComponant
              type={LoadableImageEnum.image.key}
              classes='user-cover-image'
              alt={t(`${translationPath}user-image`)}
              src={
                (listingAgent.profileImg &&
                  getDownloadableLink(listingAgent.profileImg)) ||
                ContactTypeEnum.man.defaultImg
              }
              style={{ maxWidth: '50px' }}
            />
            <div className='item-wrapper px-2'>
              <span className='item-header'>
                {listingAgent.listingAgentName}
              </span>
            </div>
            <div>
              <Chip
                className='mr-1'
                color='primary'
                label={activeData.unitStatus?.value}
              />
              <Chip color='primary' label={activeData.unitType} />
            </div>
          </div>
          <div className='d-flex-column px-5'>
            {listingAgent.branchName && (
              <div className='item-wrapper mb-3 d-flex-v-center'>
                <span className='item-header px-2'>
                  <Business />
                </span>
                <span className='item-body'>{listingAgent.branchName}</span>
              </div>
            )}
            <div className='item-wrapper d-flex-v-center mb-3'>
              <span className='item-header px-2'>
                <Email />
              </span>
              <span className='item-body'>{listingAgent.email}</span>
            </div>
            <div className='item-wrapper mb-3 d-flex-v-center'>
              <span className='item-header px-2'>
                <PhoneEnabled />
              </span>
              <span className='item-body'>{listingAgent.phoneNumber}</span>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <div className='units-card-detaild-wrapper p-relative'>
      <Spinner isActive={isLoading} isAbsolute />
      <div className='w-100 px-3 mb-3'>
        {makeTransactionOnUnitList.length > 0 && (
          <SelectComponet
            idRef='unitStatusRef'
            data={makeTransactionOnUnitList}
            emptyItem={{
              value: null,
              text: 'make-transaction',
              isHiddenOnOpen: true,
            }}
            value={selectedStatus}
            translationPathForData={translationPath}
            translationPath={translationPath}
            parentTranslationPath={parentTranslationPath}
            onSelectChanged={onSelectChanged}
            valueInput='key'
            textInput='value'
            isDisabled={shouldDisableFields}
          />
        )}
      </div>

      <div className='status-wrapper'>
        <div
          className={`body-status ${
            (activeData &&
              activeData.leaseUnitStatus &&
              activeData.leaseUnitStatus.value) === 'available' &&
            'c-success-light'
          }`}
        >
          <div className='body-status-type mx-1 '>
            {t(`${translationPath}status`)}
          </div>
          {' : '}
          <span
            className={`body-status mx-2 ${
              (activeData &&
                activeData.leaseUnitStatus &&
                activeData.leaseUnitStatus.value) === 'reserved-for-lease' &&
              'status-wrapper-text'
            }`}
          >
            <span className='status-wrapper-text-'>
              {' '}
              {AMLStatusEnum[activeData && activeData.leaseUnitStatus] &&
                AMLStatusEnum[activeData && activeData.leaseUnitStatus].name &&
                t(
                  `Shared:${
                    (AMLStatusEnum[activeData && activeData.leaseUnitStatus] &&
                      AMLStatusEnum[activeData && activeData.leaseUnitStatus]
                        .name) ||
                    ''
                  }`
                )}
            </span>{' '}
            {activeData &&
              activeData.leaseUnitStatus &&
              activeData.leaseUnitStatus.value !== undefined &&
              ((activeData &&
                activeData.leaseUnitStatus &&
                t(
                  `${translationPath}${
                    activeData.leaseUnitStatus.value === 'Resale'
                      ? 'sale'
                      : activeData.leaseUnitStatus.value === 'New Lease'
                      ? 'leased'
                      : activeData.leaseUnitStatus.value
                  }`
                )) ||
                'N/A')}
          </span>
        </div>
        {shouldDisableFields && (
          <div className='information-open-file-2'>
            <Tooltip
              title='This field has a pending approval for changes!'
              className='pending-field'
            >
              <span className='mdi mdi-information-outline' />
            </Tooltip>
          </div>
        )}
      </div>
      <div className='d-flex-center'>
        <div className='archive-bbt'>
          <Button
            onClick={() => setOpen(true)}
            disabled={ArchiveState}
            className='MuiButtonBase-root MuiButton-root MuiButton-text btns-icon theme-solid mx-2'
            title={t(`${translationPath}ArchiveUnit`)}
          >
            <span className='MuiButton-label'>
              <span className='mdi mdi-inbox-multiple' />
            </span>
            <span className='MuiTouchRipple-root' />
          </Button>
        </div>
      </div>
      {isOpenShareUnitDialog && (
        <ShareUnitsDialog
          activeData={activeData}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          isOpen={isOpenShareUnitDialog}
          onClose={() => {
            setIsOpenShareUnitDialog(false);
          }}
          unitOperationSource={unitOperationSource.lease.key}
        />
      )}
      {activeData && (
        <div className='side-menu-wrapper'>
          <div>
            <div className='cards-header-wrapper mb-1'>
              <LoadableImageComponant
                classes='details-img'
                type={LoadableImageEnum.div.key}
                alt={t(`${translationPath}unit-image`)}
                src={
                  (activeData &&
                    activeData.allunitImages &&
                    getDownloadableLink(activeData.allunitImages.fileId)) ||
                  getDefaultUnitImage(activeData && activeData.type)
                }
              />
            </div>
            <div className='my-3'>
              <div className='data-schedule-button  py-2'>
                {returnPropsByPermissions(
                  UnitsLeasePermissions.AddNewActivity.permissionsId
                ) && (
                  <div
                    className={`add-activity-button ${
                      addActivity ? 'selected' : ''
                    }`}
                  >
                    <Button
                      onClick={() => {
                        setAddActivity(true);
                        setActivityDetails(false);
                      }}
                      className={`btns ${addActivity ? 'theme-outline' : ''}`}
                    >
                      {t(`${translationPath}add-activity`)}
                    </Button>
                  </div>
                )}
                <div
                  className={`details-button ${
                    activitydetails ? 'selected' : ''
                  }`}
                >
                  <Button
                    onClick={() => {
                      setActivityDetails(true);
                      setAddActivity(false);
                    }}
                    className={`btns ${activitydetails ? 'theme-outline' : ''}`}
                  >
                    {t(`${translationPath}ActivityDetails`)}
                  </Button>
                </div>
                {checkIsUnitAvailable() && checkIfHasLeaseAgentRole() && (
                  <div>
                    <PermissionsComponent
                      permissionsList={
                        canMakeQuickActions
                          ? []
                          : Object.values(LeaseTransactionJourneyPermissions)
                      }
                      permissionsId={
                        LeaseTransactionJourneyPermissions?.MakeTransaction
                          ?.permissionsId
                      }
                      allowEmptyRoles={canMakeQuickActions}
                    >
                      <ButtonBase
                        id='make-transaction-btn'
                        onClick={() => setIsTransactionStartOpen(true)}
                        className='btns theme-outline mt-1'
                      >
                        {t(`${translationPath}make-transaction`)}
                      </ButtonBase>
                    </PermissionsComponent>
                  </div>
                )}
                {(checkIsUnitInTransaction() || checkIsTransactionDone()) &&
                  checkIfHasLeaseAgentRole() && (
                    <div>
                      <PermissionsComponent
                        permissionsList={Object.values(
                          LeaseTransactionJourneyPermissions
                        )}
                        permissionsId={
                          LeaseTransactionJourneyPermissions
                            ?.ContinueTransaction?.permissionsId
                        }
                      >
                        <ButtonBase
                          id='continue-transaction-btn'
                          onClick={() => {
                            const reservationStep =
                              TransactionStepsData.UnitReservations;
                            const stepInfo =
                              Object.values(TransactionStepsData).find((item) =>
                                item.unitStatus.includes(
                                  activeData?.leaseUnitStatus?.name
                                )
                              ) || reservationStep;

                            GlobalHistory.push(
                              `/home/lease-transaction-journey?id=${activeData.id}&step=${stepInfo?.key}`
                            );
                          }}
                          className='btns theme-outline mt-1'
                        >
                          {checkIsTransactionDone()
                            ? t(`${translationPath}view-transaction`)
                            : t(`${translationPath}continue-transaction`)}
                        </ButtonBase>
                      </PermissionsComponent>
                    </div>
                  )}
              </div>
            </div>
            <div className='properety-plan d-flex-center my-2'>
              {activeData && activeData.name}
            </div>
            <div className='price-wrapper'>
              <div
                className={`for-lable ${
                  activeData && activeData.unitOperationType
                }`}
              >
                {t(`${translationPath}for`)}
              </div>
              <div className={activeData && activeData.unitOperationType}>
                {`  ${activeData && activeData.unitOperationType}`}:
              </div>
              <div className='unit-price'>
                {`   ${
                  activeData && activeData.rent_price_fees
                    ? activeData.rent_price_fees.rentPerYear ||
                      activeData.rentPerYear ||
                      activeData.rent_price_fees
                    : 'N/A'
                } AED`}
              </div>
            </div>
            <div className='flat-contents-wrapper'>
              {activeData &&
                activeData.flatContent &&
                activeData.flatContent.map((subItem, subIndex) => (
                  <div
                    key={`detailsFlatContentsItemRef${subIndex + 1}`}
                    className='flat-content-item'
                  >
                    <span
                      className={`flat-content-icon ${subItem.iconClasses} mdi-18px`}
                    />
                    <span className='px-1'>{subItem.value}</span>
                    <span>
                      {subItem.title && (
                        <span className='flat-content-text'>
                          {t(`${translationPath}${subItem.title}`)}
                        </span>
                      )}
                    </span>
                  </div>
                ))}
              {isOpenTemplateDialog && (
                <UnitTemplateDialogPreviewDialog
                  activeItem={activeTemplateId}
                  isOpen={isOpenTemplateDialog}
                  unitID={activeData.id}
                  maintitleText={activeTemplateName}
                  unitOwner={activeData?.lead_owner}
                  leaseUnitOwner={activeData?.lease_lead_owner}
                  isOpenChanged={() => {
                    setIsOpenTemplateDialog(false);
                    setActiveTemplateId(-1);
                  }}
                  templateName={activeTemplateName}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              )}
            </div>
            <div className='id-date-wrapper pl-2 pr-2'>
              <div className='created-on'>
                <span className='details-icon mdi mdi-calendar mdi-16px' />
                <span>
                  <span className='details-text fw-simi-bold'>
                    {t(`${translationPath}created`)}:
                  </span>
                  <span className='px-1'>
                    {(activeData &&
                      activeData.createdOn &&
                      moment(activeData.createdOn)
                        .locale(i18next.language)
                        .format('DD/MM/YYYY')) ||
                      'N/A'}
                  </span>
                </span>
              </div>
              <div className='contact-id-wrapper'>
                {t(`${translationPath}id`)}:
                <Tooltip title={t(`${translationPath}copy`)}>
                  <CopyToClipboardComponents
                    data={activeData.id}
                    childrenData={activeData.id}
                  />
                </Tooltip>
              </div>
            </div>
            <div className='px-3 mb-3 slider-data'>
              {activeData.details &&
                activitydetails === false &&
                activeData.details.map((item, index) => {
                  return (
                    <React.Fragment key={`detailsRef${index + 1}}`}>
                      {item.value && item.title !== 'owner-mobile-number' && (
                        <div
                          className='px-3 mb-2'
                          onClick={index === 0 ? onClickActiveData : () => {}}
                          style={index === 0 ? { cursor: 'pointer' } : {}}
                          ref={popoverRef}
                        >
                          <span className='texts gray-primary-bold'>
                            {`${t(item.title)}`}:
                          </span>
                          {item.title === 'listing-agent' &&
                          item.value !== null &&
                          item.value !== 'N/A' ? (
                            <a
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                setAttachedWith(popoverRef.current)
                              }
                            >{`  ${item.value}`}</a>
                          ) : item.title === 'unit-model' ? (
                            <span className='texts s-gray-primary'>{`  ${item.value.propertyUnitModelName}`}</span>
                          ) : (
                            <span className='texts s-gray-primary'>{`  ${item.value}`}</span>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              <div className='d-flex-center'>
                <UnitMPIGaugeChartComponent
                  activeData={activeData}
                  unitId={activeData.id}
                  marketComparison={activeData?.marketComparison ||null}
                  operationType={UnitsOperationTypeEnum.rent.key}
                  parentTranslationPath={parentTranslationPath}
                  translationPath={translationPath}
                />
              </div>
              {activitydetails && (
                <ActivityDetailsComponent
                  cardId={activeData?.id}
                  data={activeData}
                  type='unit'
                  translationPath={translationPath}
                  parentTranslationPath='UnitsView'
                />
              )}
            </div>
          </div>

          {activeData.unitStatus?.value === 'available' &&
          returnPropsByPermissions(
            UnitsLeasePermissions.ShareUnits.permissionsId
          ) ? (
            <div className='side-menu-actions'>
              <div className='d-flex fj-around flex-wrap'>
                {displyOpenFileButton && (
                  <Button
                    className='btns theme-solid mx-2 mb-2'
                    onClick={cardDetailsActionClicked(
                      ActionsEnum.folder.key,
                      activeData
                    )}
                  >
                    <span className='icons i-folder-white' />
                    <span className='mx-2'>
                      {t(`${translationPath}open-file`)}
                    </span>
                  </Button>
                )}

                <div className='shareUnit-bbt'>
                  <ButtonBase
                    className='btns theme-outline'
                    onClick={() => {
                      setIsOpenShareUnitDialog(true);
                    }}
                    disabled={activeData.unitStatus?.value !== 'available'}
                  >
                    <span>{t(`${translationPath}Share-Unit`)}</span>
                  </ButtonBase>
                </div>
                <div className='shareUnit-bbt'>
                  <SelectComponet
                    className='hidden-select-component'
                    data={templatesProposal}
                    keyLoopBy='systemTemplateId'
                    valueInput='systemTemplateId'
                    textInput='systemTemplateName'
                    value={activeTemplateId}
                    keyValue='actionsbuttons'
                    idRef='contactsActionsRef'
                    onSelectChanged={(value) => {
                      setActiveTemplateId(value);
                      if (value !== -1) {
                        const itemIndex = templates.findIndex(
                          (item) => item.systemTemplateId === value
                        );
                        if (itemIndex !== -1) {
                          setActiveTemplateName(
                            templates[itemIndex].systemTemplateName
                          );
                        }
                        setIsOpenTemplateDialog(true);
                      }
                    }}
                    themeClass='theme-action-buttons'
                    translationPath={translationPath}
                    translationPathForData={translationPath}
                    // wrapperClasses='bg-secondary c-white mx-2'
                    parentTranslationPath={parentTranslationPath}
                    emptyItem={{
                      value: -1,
                      text: 'templateProposal',
                      isHiddenOnOpen: true,
                    }}
                    onOpen={() => {
                      setTemplatesProposal([]);
                      GetSuitableTemplateIdForUnitApi(activeData.id);
                    }}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className='side-menu-actions'>
              <div className='d-flex-center'>
                {displyOpenFileButton && (
                  <Button
                    className='btns theme-solid mx-2 mb-2'
                    onClick={cardDetailsActionClicked(
                      ActionsEnum.folder.key,
                      activeData
                    )}
                  >
                    <span className='icons i-folder-white' />
                    <span className='mx-2'>
                      {t(`${translationPath}open-file`)}
                    </span>
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      <FacebookGalleryComponent
        data={(activeData && activeData.unitImages) || []}
        isOpen={(activeImageIndex !== null && activeData && true) || false}
        activeImageIndex={activeImageIndex}
        titleText='unit-images'
        parentTranslationPath={parentTranslationPath}
        translationPath={translationPath}
        onOpenChanged={() => {
          setActiveImageIndex(null);
        }}
      />

      {isPropertyManagementView && (
        <PermissionsComponent
          permissionsList={Object.values(UnitPermissions)}
          permissionsId={
            UnitPermissions.MakeTransactionOnUnitToBeDraft.permissionsId
          }
        >
          {isOpenDraftDialog && (
            <UnitStatusDraftDialog
              activeItem={activeData}
              isOpen={isOpenDraftDialog}
              reloadData={() => {
                setIsOpenDraftDialog(false);
                if (reloadData) reloadData();
              }}
              isOpenChanged={() => {
                setIsOpenDraftDialog(false);
              }}
            />
          )}
        </PermissionsComponent>
      )}
      {!isPropertyManagementView && isOpenDraftDialog && (
        <UnitStatusDraftDialog
          activeItem={activeData}
          isOpen={isOpenDraftDialog}
          reloadData={() => {
            setIsOpenDraftDialog(false);
            if (reloadData) reloadData();
          }}
          isOpenChanged={() => {
            setIsOpenDraftDialog(false);
          }}
        />
      )}
      <UnitStatusAvailableDialog
        activeItem={activeData}
        isOpen={isOpenAvailableDialog}
        reloadData={() => {
          setIsOpenAvailableDialog(false);
          getPendingUserFields();
          if (reloadData) reloadData();
        }}
        isOpenChanged={() => {
          setIsOpenAvailableDialog(false);
        }}
        fieldsNeedsApproval={fieldsNeedsApproval}
        loginResponse={loginResponse}
      />
      <DialogComponent
        isOpen={open}
        onCancelClicked={() => setOpen(false)}
        onCloseClicked={() => setOpen(false)}
        translationPath={translationPath}
        parentTranslationPath='UnitsView'
        titleText='ArchiveUnit'
        onSubmit={(e) => {
          e.preventDefault();
          archiveUnits();
        }}
        maxWidth='sm'
        dialogContent={
          <span>{t(`${translationPath}MassageArchiveUnits`)}</span>
        }
      />
      {addActivity && (
        <ActivitiesManagementDialog
          open={addActivity}
          onSave={() => {
            setAddActivity(false);
          }}
          close={() => {
            setAddActivity(false);
          }}
          actionItemId={activeData?.id}
          translationPath={''}
          parentTranslationPath={'ActivitiesView'}
          unitOperationId={activeData?.operation_type?.lookupItemId}
          unitTypeId={activeData?.unit_type?.lookupItemId}
        />
      )}
      {isTransactionStartOpen && (
        <TransactionUnitSelectionDialog
          isDialogOpen={isTransactionStartOpen}
          onClose={() => {
            setIsTransactionStartOpen(false);
          }}
          activeUnitId={activeData?.id}
        />
      )}

      <PopoverComponent
        attachedWith={attachedWith}
        handleClose={() => setAttachedWith(null)}
        component={<AgentListingDetails />}
        idRef='agent-listing-details'
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'top',
        }}
        // transformOrigin={{
        //   vertical: 'left',
        //   horizontal: 'left',
        // }}
      />
    </div>
  );
}

CardDetailsComponent.propTypes = {
  activeData: PropTypes.instanceOf(Object),
  reloadData: PropTypes.func.isRequired,
  parentTranslationPath: PropTypes.string.isRequired,
  translationPath: PropTypes.string,
  from: PropTypes.number,
  cardDetailsActionClicked: PropTypes.func,
  displyOpenFileButton: PropTypes.bool,
};
CardDetailsComponent.defaultProps = {
  translationPath: 'utilities.cardDetailsComponent.',
  activeData: null,
  from: 1,
  cardDetailsActionClicked: () => {},
  displyOpenFileButton: false,
};
export { CardDetailsComponent };
