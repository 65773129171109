import React from 'react';

export const DevelopersTableHeaderData = [
 
  {
    id: 4,
    label: 'Created By',
    input: 'createdBy',
    isHiddenFilter: false,
    fieldType: 'searchField',
    fieldKey: 'createdBy',
    isDefaultFilterColumn: true,
    isSortable: true,
    data: {
      type: "string",
      title: "Created By",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "createdBy",
      displayPath: "createdBy"
    }
  },
  {
    id: 5,
    label: 'Creation Date',
    input: 'createdOn',
    isHiddenFilter: false,
    fieldKey: 'createdOn',
    fieldType: 'alt-date',
    isDefaultFilterColumn: true,
    isDate: true,
    fieldType: 'alt-date',
    isSortable: true,
  },
  {
    id: 6,
    label: 'Updated By',
    input: 'updatedBy',
    isHiddenFilter: false,
    fieldType: 'searchField',
    fieldKey: 'updatedBy',
    isDefaultFilterColumn: true,
    isSortable: true,
    data: {
      type: "string",
      title: "Updated By",
      description: "General Information",
      searchKey: "User",
      iconField: "",
      dependOn: "",
      multi: "false",
      default: "",
      isSearchable: false,
      searchableKey: "updatedBy",
      displayPath: "updatedBy"
    }

  },
  {
    id: 7,
    label: 'UpdateOn Date',
    input: 'updateOn',
    isHiddenFilter: false,
    fieldType: 'alt-date',
    fieldKey: 'updateOn',
    isDefaultFilterColumn: true,
    isDate: true,
    isSortable: true,
    fieldType: 'alt-date'
  },
  {
    id: 8,
    label: 'Progress',
    input: 'data_completed',
    fieldKey: 'data_completed',
    textInputType: 'number',
    textInputMax: 100,
    textInputMin: 0,
    isHiddenFilter: true,
    fieldType: 'number',
    isDefaultFilterColumn: true,
    isNumber: true,
    // isSortable: true,
    component: (item) => <span>
      {' '}
      {item && item.progressWithPercentage}
    </span> || <span />
  },
  {
    id: 9,
    label: 'Email',
    isHiddenFilter: false,
    isDefaultFilterColumn: true,
    input: 'Email',
    fieldType: 'text',
    // isSortable: true,
    data: {
      type: "string",
      title: "Email Address",
      description: "Main Information",
      CommunicationType: "Email",
      duplicateValidation: "true",
      iconField: "",
      regExp: "^(?:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+|\\*{8})$",
      errorMsg: "Fill Email in a correct way",
      isSearchable: true,
      searchableKey: "email_address",
      displayPath: "email_address.email",
      DtoName: "",
      HelperInfo: "",
      sensitive: true,
      caption: ""
    },
    component: (item) =>
      (item && item.details && item.details.map((el) => el.title === 'email' && el.value)) || <span />,
  } ,
  {
    id: 10,
    label: 'Has Agreement',
    input: 'hasAgreement',
    isSortable: true ,
    searchableKey :"hasAgreement" , 
    isHiddenFilter: true ,
    fieldType: "select",
    isDefaultFilterColumn: false  ,
    data: { enum: [{ key : true  , name : 'yes'},{key : false  , name :  'no' }] },
    fieldType: 'select',
    withSelectFilter: true,
    component: (item) => <div>{item?.hasAgreement}</div>,
    optionFilterList: [
      { key: true , value: 'yes' },
      { key: false , value:'no' },
    ],
  },
  {
    id: 11,
    label: 'Agreement Document',
    isSortable: true ,
    input: 'AgreementDocument',
    displayPath :"hasAgreementDocuments" , 
    data: { enum: [{ key : true  , name : 'yes'},{key : false  , name :  'no' }] },
    fieldType: 'select',
    component: (item) => <div>{item?.agreementDocument}</div> ,
    isHiddenFilter: true ,
    isDefaultFilterColumn: false  ,
    withSelectFilter: true,
    optionFilterList: [
      { key: true , value: 'yes' },
      { key: false , value:'no' },
    ],
  },
]; 