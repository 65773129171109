export const UnitsDetailsTemplate = `
<div class="Unit_details">
  {{#UnitsDetails.data.unitsDetailsData}}
  <div class="Unit_details_section_No1">
    <div class="cover-x">
      <div class="content">
        <div class="row">
          <div class="frame">
            <div class="div-wrapper">
              <div class="text-wrapper">Units details</div>
            </div>
          </div>
        </div>
        <div class="div">
          <div class="col">
            <div class="group">
              {{#sellingPrice}}
              <div class="price-wrapper">
                <div class="frame-2">
                  <div class="frame-3">
                    <div class="text-wrapper-2">{{sellingPrice}}</div>
                    <div class="text-wrapper-3">AED</div>
                  </div>
                </div>
              </div>
              {{/sellingPrice}}
              <div class="flexcontainer-wrapper">
                <div class="flexcontainer">
                  <p class="text">
                    <span class="span">Your Dream Property Awaits<br /></span>
                  </p>
                  <p class="text">
                    <span class="text-wrapper-4">Welcome to your exclusive unit proposal! At Property Shop Investment (PSI), we’ve tailored this selection to match your aspirations.<br /></span>
                  </p>
                  <p class="text">
                    <span class="text-wrapper-4">Explore the possibilities and let us bring your vision to life!</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="frame">
              {{#unitType}}
                <div class="text-wrapper-5">{{unitType}}</div>
              {{/unitType}}
              <div class="div-wrapper">
                <div class="text-wrapper-6">Highlight Specifics</div>
              </div>
            </div>
            <div class="info_box_wrapper">
              <div class="info_box">
                <div class="frame-7">
                  <img class="img" src="https://psi-crm.com/ProposalIcons/bed.svg" />
                  <div class="text-wrapper-7">{{#numberOfBedrooms}}{{numberOfBedrooms}}{{/numberOfBedrooms}}{{^numberOfBedrooms}}-{{/numberOfBedrooms}}</div>
                </div>
                <div class="text-wrapper-8">Bedroom</div>
              </div>
              <div class="info_box">
                <div class="frame-7">
                  <img class="img" src="https://psi-crm.com/ProposalIcons/bathtub.svg" />
                  <div class="text-wrapper-7">{{#numberOfBathrooms}}{{numberOfBathrooms}}{{/numberOfBathrooms}}{{^numberOfBathrooms}}-{{/numberOfBathrooms}}</div>
                </div>
                <div class="text-wrapper-8">Bath</div>
              </div>
              <div class="info_box">
                <div class="frame-7">
                  <img class="img" src="https://psi-crm.com/ProposalIcons/resize.svg" />
                  <div class="frame-8">
                    <div class="text-wrapper-9">{{#unitSize}}{{unitSize}}{{/unitSize}}{{^unitSize}}-{{/unitSize}}</div>
                    <div class="text-wrapper-9">sq.ft</div>
                  </div>
                </div>
                <div class="text-wrapper-8">Size</div>
              </div>
              <div class="info_box">
                <div class="frame-7">
                  <img class="artboard" src="https://psi-crm.com/ProposalIcons/floor.svg" />
                  <div class="frame-8">
                    <div class="text-wrapper-9">{{#floorNumber}}{{floorNumber}}{{/floorNumber}}{{^floorNumber}}-{{/floorNumber}}</div>
                  </div>
                </div>
                <div class="text-wrapper-8">Floor</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {{/UnitsDetails.data.unitsDetailsData}}
  {{#UnitsDetails.data.unitsDetailsData}}
  {{#UnitsDetails.financialFees}}
  <div class="Unit_details_section_No2">
    <div class="main">
      <div class="row">
      <div class="frame">
      {{#UnitsDetails.showFinancialFees}}
          {{#showTransferFees}}
          {{#UnitsDetails.data.unitsDetailsData}}
          <div class="div">
            <div class="frame-wrapper">
              <div class="div-wrapper">
                <div class="financial-text">Transfer Fees</div>
              </div>
            </div>
            <div class="frame-2">
              <div class="frame-3">
                {{#transferFees}}
                <div class="financial-text-2">{{transferFees}}</div>
                <div class="financial-text-3">AED</div>
                {{/transferFees}}
                {{^transferFees}}
                <div class="financial-text-2">-</div>
                {{/transferFees}}
              </div>
            </div>
          </div>
          {{/UnitsDetails.data.unitsDetailsData}}
          {{/showTransferFees}}
          {{#showAgencyFees}}
          <div class="div">
            <div class="frame-4">
              <div class="div-wrapper">
                <div class="financial-text-4">Agency Fees</div>
              </div>
            </div>
            <div class="frame-5">
              <div class="frame-3">
                {{#agencyFees}}
                <div class="financial-text-5">{{agencyFees}}</div>
                <div class="financial-text-4">AED</div>
                <div class="financial-text-4">+VAT</div>
                {{/agencyFees}}
                {{^agencyFees}}
                <div class="financial-text-5">-</div>
                {{/agencyFees}}
              </div>
            </div>
          </div>
          {{/showAgencyFees}}
          {{#showNOCFeeBuyer}}
          <div class="div">
            <div class="frame-wrapper">
              <div class="div-wrapper">
                <div class="financial-text">NOC Fee Buyer</div>
              </div>
            </div>
            <div class="frame-2">
              <div class="frame-3">
                {{#nocFeeBuyer}}
                <div class="financial-text-2">{{nocFeeBuyer}}</div>
                <div class="financial-text-3">AED</div>
                {{/nocFeeBuyer}}
                {{^nocFeeBuyer}}
                <div class="financial-text-2">-</div>
                {{/nocFeeBuyer}}
              </div>
            </div>
          </div>
          {{/showNOCFeeBuyer}}
          {{#showServiceCharge}}
          <div class="div">
            <div class="frame-4">
              <div class="div-wrapper">
                <div class="financial-text-4">Service Charge</div>
              </div>
            </div>
            <div class="frame-5">
              <div class="frame-66">
                {{#serviceCharge}}
                <div class="financial-text-5">{{serviceCharge}}</div>
                <div class="financial-text-4">AED</div>
                {{/serviceCharge}}
                {{^serviceCharge}}
                <div class="financial-text-5">-</div>
                {{/serviceCharge}}
              </div>
            </div>
          </div>
          {{/showServiceCharge}}
          {{#showChillerCharges}}
          <div class="div">
            <div class="frame-wrapper">
              <div class="div-wrapper">
                <div class="financial-text">Chiller Charges</div>
              </div>
            </div>
            <div class="frame-2">
              <div class="frame-66">
                {{#chillerCharges}}
                <div class="financial-text-2">{{chillerCharges}}</div>
                <div class="financial-text-3">AED</div>
                {{/chillerCharges}}
                {{^chillerCharges}}
                <div class="financial-text-2">-</div>
                {{/chillerCharges}}
              </div>
            </div>
          </div>
          {{/showChillerCharges}}
          {{#showLeased}}
          <div class="div">
            <div class="frame-4">
              <div class="div-wrapper">
                <div class="financial-text-4">Leased</div>
              </div>
            </div>
            <div class="frame-5">
              <div class="frame-66">
                {{#leased}}
                <div class="financial-text-5">{{leased}}</div>
                {{/leased}}
                {{^leased}}
                <div class="financial-text-5">-</div>
                {{/leased}}
              </div>
            </div>
          </div>
          {{/showLeased}}
          {{#showCurrentRentAmount}}
          <div class="div">
            <div class="frame-wrapper">
              <div class="div-wrapper">
                <div class="financial-text">Current Rent</div>
              </div>
            </div>
            <div class="frame-2">
              <div class="frame-66">
                {{#currentRentAmount}}
                <div class="financial-text-2">{{currentRentAmount}}</div>
                <div class="financial-text-3">AED</div>
                {{/currentRentAmount}}
                {{^currentRentAmount}}
                <div class="financial-text-2">-</div>
                {{/currentRentAmount}}
              </div>
            </div>
          </div>
          {{/showCurrentRentAmount}}
          {{#showRentalExpiryDate}}
          <div class="div">
            <div class="frame-4">
              <div class="div-wrapper">
                <div class="financial-text-4">Rental Expiry</div>
              </div>
            </div>
            <div class="frame-5">
              <div class="frame-66">
                {{#rentalExpiryDate}}
                <div class="financial-text-5">{{rentalExpiryDate}}</div>
                {{/rentalExpiryDate}}
                {{^rentalExpiryDate}}
                <div class="financial-text-5">-</div>
                {{/rentalExpiryDate}}
              </div>
            </div>
          </div>
          {{/showRentalExpiryDate}}
          {{/UnitsDetails.showFinancialFees}}
        </div>
        {{#UnitsDetails.data.unitsDetailsData}}
        <div class="col">
          <div class="info_box_wrapper">
            <div class="info_box">
              <div class="frame-7">
                <img class="img" src="https://psi-crm.com/ProposalIcons/couch.svg" />
                <div class="text-wrapper-7">{{#furnishedStatus}}{{furnishedStatus}}{{/furnishedStatus}}{{^furnishedStatus}}-{{/furnishedStatus}}</div>
              </div>
              <div class="text-wrapper-8">Furnished</div>
            </div>
            <div class="info_box">
              <div class="frame-7">
                <img class="img" src="https://psi-crm.com/ProposalIcons/beach.svg" />
                <div class="frame-11">
                  <div class="text-wrapper-6">{{#view}}{{view}}{{/view}}{{^view}}-{{/view}}</div>
                </div>
              </div>
            </div>
            <div class="info_box">
              <div class="frame-7">
                <img class="img" src="https://psi-crm.com/ProposalIcons/maidroom.svg" />
                <div class="frame-11">
                  <div class="text-wrapper-6">{{#numberOfMaidRooms}}{{numberOfMaidRooms}}{{/numberOfMaidRooms}}{{^numberOfMaidRooms}}-{{/numberOfMaidRooms}}</div>
                </div>
              </div>
              <div class="text-wrapper-7">Maid room</div>
            </div>
            <div class="info_box">
              <div class="frame-7">
                <img class="img" src="https://psi-crm.com/ProposalIcons/balcony.svg" />
                <div class="frame-11">
                  <div class="text-wrapper-6">{{#balconyAvailability}}{{balconyAvailability}}{{/balconyAvailability}}{{^balconyAvailability}}-{{/balconyAvailability}}</div>
                </div>
              </div>
              <div class="text-wrapper-7">Balcony</div>
            </div>
            <div class="info_box">
              <div class="frame-7">
                <img class="element" src="https://psi-crm.com/ProposalIcons/storage.svg" />
                <div class="frame-11">
                  <div class="text-wrapper-6">{{#storageAvailability}}{{storageAvailability}}{{/storageAvailability}}{{^storageAvailability}}-{{/storageAvailability}}</div>
                </div>
              </div>
              <div class="text-wrapper-7">Storage</div>
            </div>
            <div class="info_box">
              <div class="frame-7">
                <img class="img" src="https://psi-crm.com/ProposalIcons/parking.svg" />
                <div class="frame-11">
                  <div class="text-wrapper-6">{{#numberOfAvailableParking}}{{numberOfAvailableParking}}{{/numberOfAvailableParking}}{{^numberOfAvailableParking}}-{{/numberOfAvailableParking}}</div>
                </div>
              </div>
              <div class="text-wrapper-7">Parking</div>
            </div>
          </div>
        </div>
        {{/UnitsDetails.data.unitsDetailsData}}
      </div>
    </div>
  </div>
  {{/UnitsDetails.financialFees}}
  {{/UnitsDetails.data.unitsDetailsData}}
</div>
`;
