import React from "react";
import PropTypes from "prop-types";
import { Box, Divider } from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { CustomIconButton } from "../CustomIconButton";
import { useSelectedTheme, useTranslate } from "../../../Hooks";
import { getDownloadableLink, truncateString } from "../../../Helper";
import { CustomBadge, CustomCopyToClipboard } from "../..";
import { formatCommasForPriceUnit } from "../../../Helper/formatCommas.Helper";

// Icons
import {
  ArrowUpRight,
  UnitSizeIcon,
  BedIcon,
  BathIcon,
  MaidIcon,
  EmptyUnitGalleryIcon,
} from "../../../assets/icons";

// Styles
import useStyles from "./styles";

const UnitCard = ({ item, selectType }) => {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const { translate } = useTranslate("NewContactsView");
  const history = useHistory();

  return (
    <Box key={item?.contactId || item?.id} className={styles.unitCard}>
      <Box className={styles.imageWrapper}>
        {item?.allunitImages ? (
          <Box
            className={styles.unitImage}
            style={{
              backgroundImage: `url(${getDownloadableLink(
                item?.allunitImages.fileId
              )})`,
            }}
          />
        ) : (
          <Box className={styles.emptyImage}>
            <EmptyUnitGalleryIcon
              width="52"
              height="52"
              fill={palette.foreground.quinary}
            />
          </Box>
        )}
      </Box>

      <Box className={styles.details}>
        <Box className={styles.header}>
          <Box className={styles.priceSection}>
            <Box className={styles.priceText}>
              {selectType === "Sale" &&
              item?.selling_price_agency_fee &&
              item?.selling_price_agency_fee !== "N/A"
                ? `${formatCommasForPriceUnit(item.selling_price_agency_fee)}`
                : selectType !== "Sale" &&
                  item?.rent_price_fees &&
                  item?.rent_price_fees !== "N/A"
                ? `${formatCommasForPriceUnit(item.rent_price_fees)}`
                : "N/A"}

              {selectType === "Sale" &&
                item?.selling_price_agency_fee !== "N/A" && <span>AED</span>}
              {selectType !== "Sale" && item?.rent_price_fees !== "N/A" && (
                <span>AED</span>
              )}
            </Box>

            <Box
              onClick={() => {
                if (selectType === "Sale") {
                  history.push(
                    `/home/units-sales/unit-profile-edit?formType=${item?.unitTypeId}&id=${item?.id}&operationType=${item?.operationType}`
                  );
                } else {
                  history.push(
                    `/home/units-lease/unit-profile-edit?formType=${item?.unitTypeId}&id=${item?.id}&operationType=${item?.operationType}`
                  );
                }
              }}
            >
              <CustomIconButton
                variant="text"
                size="xs"
                boxShadow="none"
                color="secondary"
              >
                <ArrowUpRight
                  width="20"
                  height="20"
                  fill={palette.button.secondaryGray_fg}
                />
              </CustomIconButton>
            </Box>
          </Box>

          <Box className={styles.unitName}>{item?.name}</Box>

          <Box className={styles.unitInfo}>
            <CustomCopyToClipboard
              data={item?.unit_ref_no} // copy
              childrenData={"#" + item?.unit_ref_no} // render
              copyDoneShowTime={1000}
            />

            <span>
              {item?.unitType && item?.unit_usage_type
                ? ` ${item?.unitType} / ${item?.unit_usage_type}`
                : item?.unitType}
            </span>
          </Box>

          <Box className={styles.location}>
            {item?.community?.lookupItemName +
              (item?.sub_community?.lookupItemName
                ? ", " + item?.sub_community?.lookupItemName
                : "")}
          </Box>
        </Box>

        <Box className={styles.body}>
          <Box className={styles.badges}>
            <CustomBadge
              label={item?.progress + "%" || "0%"}
              SizeVariant={"small"}
              BackgroundColor={
                palette.utility[item?.progress >= 50 ? "brand_50" : "error_50"]
              }
              BorderColor={
                palette.utility[
                  item?.progress >= 50 ? "brand_200" : "error_200"
                ]
              }
              Color={
                palette.utility[
                  item?.progress >= 50 ? "brand_700" : "error_700"
                ]
              }
            />

            <CustomBadge
              label={
                item?.unitStatus?.value === "sale"
                  ? "sold"
                  : item?.unitStatus?.value
              }
              SizeVariant={"medium"}
              BackgroundColor={palette.utility.gray_blue_50}
              BorderColor={palette.utility.gray_blue_200}
              Color={palette.utility.gray_blue_700}
            />

            <label>{`For ${
              selectType === "Sale" ? item?.unitOperationType : "Rent"
            }`}</label>
          </Box>

          <Box className={styles.unitDetails}>
            <Box className={styles.detailItem}>
              <UnitSizeIcon
                width="20"
                height="20"
                fill={palette.foreground.quinary}
              />
              <span>{item?.total_area_size_sqft || "N/A"} sq.ft</span>
            </Box>
            <Box className={styles.detailItem}>
              <BedIcon
                width="20"
                height="20"
                fill={palette.foreground.quinary}
              />
              <span>{item?.bedrooms || "any"}</span>
            </Box>
            <Box className={styles.detailItem}>
              <BathIcon
                width="20"
                height="20"
                fill={palette.foreground.quinary}
              />
              <span>{item?.bathrooms || "any"}</span>
            </Box>
            <Box className={styles.detailItem}>
              <MaidIcon
                width="20"
                height="20"
                fill={palette.foreground.quinary}
              />
              <span>{item?.maid || "N/A"}</span>
            </Box>
          </Box>
        </Box>

        <Divider className={styles.divider} />

        <Box className={styles.footer}>
          <label>Creation On:</label>
          <Box className={styles.dateInfo}>
            <label>{moment(item?.createdOn).format("DD MMM YYYY")}</label>
            <span>{truncateString(item?.createdBy, 10)}</span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

UnitCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default UnitCard;
