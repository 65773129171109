import React from "react";

//  styles
import useStyles from './styles'

function Specifications({}) {
    const styles =useStyles()

    return(
        <>Specifications</>
    )
}

export default Specifications