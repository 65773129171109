import React from "react";

function IconImageOffLine({ fill, ...restProps }) {
  return (
    <svg
      width="74"
      height="74"
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        d="M64.7513 49.3332V36.9998M64.7513 36.9998V15.4165C64.7513 13.781 64.1016 12.2125 62.9451 11.056C61.7887 9.89954 60.2201 9.24984 58.5846 9.24984H24.668M64.7513 36.9998C60.2034 36.9998 56.1056 37.3205 52.418 37.8971M9.2513 49.3332V58.5832C9.2513 60.2187 9.901 61.7872 11.0575 62.9437C12.214 64.1001 13.7825 64.7498 15.418 64.7498H49.3346M9.2513 49.3332C13.5772 48.6117 20.4654 48.4298 27.5817 50.0824M9.2513 49.3332V15.4165C9.2513 14.2818 9.62747 12.5213 10.793 11.1708M49.3346 64.7498H58.5846C59.7193 64.7498 61.4799 64.3737 62.8304 63.2082M49.3346 64.7498C44.0806 56.2152 35.6847 51.9602 27.5817 50.0824M27.5817 50.0824C30.44 46.774 34.4884 43.6568 40.0846 41.2949M22.6361 23.1248C22.0163 23.8278 21.5846 24.8238 21.5846 26.2082C21.5846 29.9082 24.668 30.8332 26.2096 30.8332C27.327 30.8217 28.4099 30.4448 29.293 29.7602M6.16797 6.1665L67.8346 67.8332"
        stroke="#98A2B3"
        stroke-width="2.5814"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default IconImageOffLine;
