export const UnitFinancialFees = [
    {
        key: "showTransferFees",
        label: "Show Transfer Fees"
    },
    {
        key: "showAgencyFees",
        label: "Show Agency Fees"
    },
    {
        key: "showNOCFeeBuyer",
        label: "Show NOC Fee Buyer"
    },
    {
        key: "showServiceCharge",
        label: "Show Service Charge"
    },
    {
        key: "showChillerCharges",
        label: "Show Chiller Charges"
    },
    {
        key: "showLeased",
        label: "Show Leased"
    },
    {
        key: "showCurrentRentAmount",
        label: "Show Current Rent Amount"
    },
    {
        key: "showRentalExpiryDate",
        label: "Show Rental Expiry Date"
    },
];


