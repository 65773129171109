export const UnitsDetailsStyle = `

/*CSS GENERETED BY ANIMA PPLUGIN */

:root {
--bg-primary-light : linear-gradient(180deg, #F4F4F4 0%, #EDEDED 100%);
--bg-primary-dark : #272963;
--bg-box-light : rgba(255, 255, 255, 0.60);
--bg-box-dark : rgba(0, 0, 0, 0.40);
--bg-frame-light : background: linear-gradient(90deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.40) 100%);
--bg-frame-dark : rgba(39, 41, 99, 0.10);
--bg-gradient-light : linear-gradient(180deg, rgba(244, 244, 244, 0.00) 0%, #F4F4F4 100%);
--bg-gradient-dark : linear-gradient(180deg, rgba(39, 41, 99, 0.00) 0%, #272963 100%);
--text-primary-light : #2D3E50;
--text-primary-dark : #ffffff;
--text-secondery-light : #272963;
--text-secondery-dark : #ffffff;
--icon-filter-light : invert(0.75) sepia(1) hue-rotate(200deg);
--icon-filter-dark : brightness(0) invert(1);
}


[data-mode="light"] {
  --bg-primary: var(--bg-primary-light);
  --bg-gradient: var(--bg-gradient-light);
  --bg-box: var(--bg-box-light);
  --bg-frame: var(--bg-frame-light);
  --text-primary: var(--text-primary-light);
  --text-secondery: var(--text-secondery-light);
  --icon-filter: var(--icon-filter-light);

}

[data-mode="dark"] {
  --bg-primary: var(--bg-primary-dark);
  --bg-gradient: var(--bg-gradient-dark);
  --bg-box: var(--bg-box-dark);
  --bg-frame: var(--bg-frame-dark);
  --text-primary: var(--text-primary-dark);
  --text-secondery: var(--text-secondery-dark);
  --icon-filter: var(--icon-filter-dark);
}

.UnitsDetails{
    background: var(--bg-primary);
}

.first_part {
  position: relative;
  width: 2480px;
  height: 1754px;
  background: var(--bg-primary);
}

.first_part .frame {
  display: flex;
  flex-direction: column;
  width: 1075px;
  align-items: flex-start;
  gap: 45px;
  position: absolute;
  top: 84px;
  left: 1240px;
}

.first_part .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "DM Sans-Bold", Helvetica;
  font-weight: 700;
  color: var(--text-primary);
  font-size: 72px;
  letter-spacing: -1.44px;
  line-height: normal;
}

.first_part .line {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 1px;
  object-fit: cover;
  background: rgb(45 62 80 / 6%);
}

.first_part .div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 38px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.first_part .frame-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.first_part .text-wrapper-2 {
  position: relative;
  width: 400px;
  font-family: "DM Sans-9ptRegular", Helvetica;
  font-weight: 400;
  color: var(--text-primary);
  font-size: 36px;
  letter-spacing: 0;
  line-height: normal;
}

.first_part .img {
  position: relative;
  width: 140px;
  height: 140px;
  filter: var(--icon-filter);

}

.first_part .div-2 {
  position: relative;
  width: 344px;
  font-family: "DM Sans-Medium", Helvetica;
  font-weight: 500;
  color: var(--text-secondery);
  font-size: 64px;
  letter-spacing: 0;
  line-height: normal;
}

.first_part .span {
  font-family: "DM Sans-Medium", Helvetica;
  font-weight: 500;
  color: var(--text-secondery);
  font-size: 64px;
  letter-spacing: 0;
}

.first_part .text-wrapper-3 {
  font-size: 48px;
}

.first_part .artboard {
  position: relative;
  width: 137px;
  height: 137px;
  filter: var(--icon-filter);
}

.first_part .overlap-group {
  position: absolute;
  width: 1075px;
  height: 1754px;
  top: 0;
  left: 0;
  background: var(--bg-gradient),
  url({{{UnitsDetails.unitImage.url}}}) lightgray 50% / cover no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.first_part .flexcontainer-wrapper {
  display: flex;
  width: 1011px;
  align-items: center;
  gap: 32px;
  padding: 48px;
  position: absolute;
  top: 1414px;
  left: 32px;
  background: var(--bg-frame);
  backdrop-filter: blur(40px);
}

.first_part .flexcontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.first_part .text {
  position: relative;
  align-self: stretch;
  font-family: "Inter-Bold", Helvetica;
  font-weight: 400;
  color: var(--text-primary);
  font-size: 30px;
  letter-spacing: 0;
  line-height: normal;
}

.first_part .text-wrapper-4 {
  font-weight: 700;
}

.first_part .text-wrapper-5 {
  font-family: "Inter-Medium", Helvetica;
  font-weight: 500;
}

.first_part .frame-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 48px;
  position: absolute;
  top: 120px;
  left: 279px;
  backdrop-filter: blur(40px) brightness(100%);
  -webkit-backdrop-filter: blur(40px) brightness(100%);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.4) 100%
  );
}

.first_part .div-wrapper {
  display: flex;
  align-items: center;
  gap: 30px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.first_part .frame-3 {
  display: inline-flex;
  align-items: flex-end;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
}

.first_part .text-wrapper-6 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "DM Sans-Bold", Helvetica;
  font-weight: 700;
  color: var(--text-primary);
  font-size: 64px;
  letter-spacing: 0;
  line-height: normal;
}

.first_part .text-wrapper-7 {
  position: relative;
  width: fit-content;
  font-family: "DM Sans-9ptRegular", Helvetica;
  font-weight: 400;
  color: var(--text-primary);
  font-size: 48px;
  letter-spacing: 0;
  line-height: normal;
}









/*================================*/


.second_part {
  display: flex;
  height: 1754px;
  align-items: flex-start;
  justify-content: space-between;
  padding: 120px;
  position: relative;
  background: var(--bg-primary);
}

.second_part .frame {
  display: flex;
  flex-direction: column;
  width: 1075px;
  align-items: flex-start;
  gap: 40px;
  position: relative;
  align-self: stretch;
}

.second_part .div {
  display: flex;
  height: 150px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
}

.second_part .frame-wrapper {
  display: flex;
  flex-direction: column;
  width: 500px;
  align-items: center;
  justify-content: space-around;
  padding: 32px;
  position: relative;
  align-self: stretch;
  background-color: var(--bg-box);
  border-radius: 12px 0px 0px 12px;
  backdrop-filter: blur(8px) brightness(100%);
  -webkit-backdrop-filter: blur(8px) brightness(100%);
}

.second_part .div-wrapper {
  display: inline-flex;
  align-items: flex-end;
  gap: 30px;
  position: relative;
  flex: 0 0 auto;
}

.second_part .text-wrapper {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: var(--text-primary);
  font-size: 52px;
  letter-spacing: -2.08px;
  line-height: normal;
}

.second_part .frame-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 32px;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
  background-color: var(--bg-box);
  border-radius: 0px 12px 12px 0px;
  backdrop-filter: blur(8px) brightness(100%);
  -webkit-backdrop-filter: blur(8px) brightness(100%);
}

.second_part .frame-3 {
  display: inline-flex;
  align-items: center;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
}

.second_part .text-wrapper-2 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "Inter-SemiBold", Helvetica;
  font-weight: 600;
  color: var(--text-primary);
  font-size: 60px;
  letter-spacing: 0;
  line-height: normal;
}

.second_part .text-wrapper-3 {
  position: relative;
  width: fit-content;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: var(--text-primary);
  font-size: 52px;
  letter-spacing: 0;
  line-height: normal;
}

.second_part .text-wrapper-4 {
  position: relative;
  width: fit-content;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  color: var(--text-primary);
  font-size: 52px;
  letter-spacing: -2.08px;
  line-height: normal;
}

.second_part .element-jul-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  position: relative;
  flex: 0 0 auto;
}

.second_part .frame-4 {
  display: flex;
  flex-direction: column;
  width: 1075px;
  align-items: flex-start;
  gap: 45px;
  position: relative;
  align-self: stretch;
}

.second_part .text-wrapper-5 {
  position: relative;
  width: fit-content;
  margin-top: -1.00px;
  font-family: "DM Sans-Bold", Helvetica;
  font-weight: 700;
  color: var(--text-primary);
  font-size: 72px;
  letter-spacing: -1.44px;
  line-height: normal;
}

.second_part .line {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 1px;
  object-fit: cover;
  background: rgb(45 62 80 / 6%);
}

.second_part .frame-5 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 38px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.second_part .frame-6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
}

.second_part .text-wrapper-6 {
  position: relative;
  width: 400px;
  font-family: "DM Sans-9ptRegular", Helvetica;
  font-weight: 400;
  color: var(--text-primary);
  font-size: 36px;
  letter-spacing: 0;
  line-height: normal;
}

.second_part .img {
  position: relative;
  width: 140px;
  height: 140px;
  filter: var(--icon-filter);
}

.second_part .text-wrapper-7 {
  position: relative;
  width: 344px;
  font-family: "DM Sans-Medium", Helvetica;
  font-weight: 500;
  color: var(--text-secondery);
  font-size: 64px;
  letter-spacing: 0;
  line-height: normal;
}

.second_part .line-2 {
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 1px;
  background: rgb(45 62 80 / 6%);
}

.second_part .element {
  position: relative;
  width: 140px;
  height: 142.27px;
}

 .financial-text {
  color: var(--text-primary);
  width: fit-content;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 600;
  font-size: 60px;
  letter-spacing: -2.08px;
  line-height: normal;
}
 .financial-text-sub {
  color: var(--text-primary);
  width: fit-content;
  font-family: "Inter-Regular", Helvetica;
  font-weight: 400;
  font-size: 52px;
  letter-spacing: -2.08px;
  line-height: normal;
}
`


