export const LocationAndamenitiesTemplate = `
  <div class="LocationAndamenities">
    {{#LocationAndamenities.showPage}}
    <div class="first_part">
      <div class="content">
        <div class="frame">
          {{#LocationAndamenities.showLocation}}
          <div class="div">
            <div class="frame-2">
              <div class="text-wrapper">Location</div>
              {{#LocationAndamenities.data.propertyLocation.locationText}}
              <div class="frame-3">
                <img class="marker-pin" src="https://psi-crm.com/ProposalIcons/marker-pin-01-gray.svg" />
                <div class="supporting-text">{{LocationAndamenities.data.propertyLocation.locationText}}</div>
              </div>
              {{/LocationAndamenities.data.propertyLocation.locationText}}
            </div>
            {{#LocationAndamenities.data.propertyLocation.mapImageUrl}}
              <img class="artboard" src={{{LocationAndamenities.data.propertyLocation.mapImageUrl}}} />
            {{/LocationAndamenities.data.propertyLocation.mapImageUrl}}
            {{#LocationAndamenities.data.propertyLocation.mapLink}}
            <div class="map-link">
              <img class="link-external" src="https://psi-crm.com/ProposalIcons/link-external-01.svg" />
              <a href="{{LocationAndamenities.data.propertyLocation.mapLink}}" target="_blank" rel="noopener noreferrer" class="text-wrapper-2">
                Google Map link
              </a>
            </div>
            {{/LocationAndamenities.data.propertyLocation.mapLink}}
            </div>
          {{/LocationAndamenities.showLocation}}
          {{#LocationAndamenities.showNearBy}}
          <div class="frame-4">
            <div class="text-wrapper">Neighborhood/Nearby</div>
            <div class="row">
            {{#LocationAndamenities.selectednNearestLandmarks}}
              <div class="NearBy-card">
                <img class="img" src="https://psi-crm.com/ProposalIcons/{{categoryId}}.svg">
                <div class="div-wrapper">
                  <div class="title">{{categoryName}}</div>
                </div>
                <div class="divider"></div>
                <div class="near-by-list">
                {{#landmarks}}
                    <div class="list-text">{{name}}</div>
                {{/landmarks}}
                </div>
              </div>
            {{/LocationAndamenities.selectednNearestLandmarks}}
            </div>
          </div>
          {{/LocationAndamenities.showNearBy}}
        </div>
      </div>
    </div>
    {{#LocationAndamenities.showFacilities}}
    <div class="second-part">
      <div class="content">
        <div class="frame">
          <div class="div">
            <div class="div-wrapper">
              <div class="text-wrapper">Amenities and Facilities</div>
            </div>
            <div class="row-wrapper">
              <div class="row">
                <div class="col">
                  {{#LocationAndamenities.selectedFacilitiesAndAmenities}}
                  <div class="frame-2">
                    <img class="img" src="https://psi-crm.com/ProposalIcons/{{id}}.svg" />
                    <div class="frame-3">
                      <div class="text">{{name}}</div>
                    </div>
                  </div>
                  {{/LocationAndamenities.selectedFacilitiesAndAmenities}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {{/LocationAndamenities.showFacilities}}
    {{/LocationAndamenities.showPage}}
  </div>
`;
