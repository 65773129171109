export const MultiUnitsCardsTemplate = `
<div class="MultiUnitsCards">
  <div class="cover-x">
    <div class="content-wrapper">
      <div class="content">
        <div class="frame">
          <div class="div">
           {{#MultiUnitsDetails.selectedUnits.0}}
            <div class="div-wrapper">
              <div class="text-wrapper">Unit details</div>
            </div>
           {{/MultiUnitsDetails.selectedUnits.0}}
            <div class="frame-2">
             {{#MultiUnitsDetails.selectedUnits}}
              <div class="blog-post-card">
                <div class="image" style="background-image: url({{{unitImage}}});"></div>
                <div class="content-2">
                  <div class="download-btn"><img src="https://psi-crm.com/ProposalIcons/download.svg" alt="download" /></div>
                  <div class="heading-and-text">
                    <div class="text-and-supporting">
                      <div class="text">{{#price}}{{price}}{{/price}}{{^price}}0{{/price}} </div>
                      <div class="supporting-text">AED</div>
                    </div>
                  {{#propertyName}}
                    <div class="heading-and-icon">
                      <div class="heading">{{propertyName}}</div>
                    </div>
                  {{/propertyName}}
                    <div class="frame-3">
                    {{#unitRefNo}}
                      <div class="frame-4">
                        <div class="text-wrapper-2">#{{unitRefNo}}</div>
                      </div>
                    {{/unitRefNo}}
                    {{#unitType}}
                      <div class="text-2">{{unitType}}</div>
                    {{/unitType}}
                    </div>
                  </div>
                  <div class="extra-info">
                    <p><span class="extra-info-title">Unit Model:</span><span class="text-2">{{#unitModel}}{{unitModel}}{{/unitModel}}{{^unitModel}}-{{/unitModel}}</span></p>
                    <p><span class="extra-info-title">Height:</span><span class="text-2">{{#heightCategory}}{{heightCategory}}{{/heightCategory}}{{^heightCategory}}-{{/heightCategory}}</span></p>
                  </div>
                  <div class="container">
                  {{#bedroom}}
                    <div class="frame-5">
                      <img class="img" src="https://psi-crm.com/ProposalIcons/bed (3).svg" />
                      <div class="list-item">
                        <div class="text-wrapper-3">{{bedroom}}</div>
                      </div>
                    </div>
                  {{/bedroom}}
                  {{#maidRoom}}
                    <div class="frame-5">
                      <img class="img" src="https://psi-crm.com/ProposalIcons/maid-room.svg" />
                      <div class="list-item">
                        <div class="text-wrapper-3">{{maidRoom}}</div>
                      </div>
                    </div>
                  {{/maidRoom}}
                  {{#totalAreaSize}}
                    <div class="frame-5">
                      <img class="img" src="https://psi-crm.com/ProposalIcons/unit-size (1).svg" />
                      <div class="list-item">
                        <div class="text-wrapper-3">{{totalAreaSize}}<span class="area-unit">sqft<span></div>
                      </div>
                    </div>
                  {{/totalAreaSize}}
                  </div>
                </div>
              </div>
              {{/MultiUnitsDetails.selectedUnits}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
