import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Box } from "@material-ui/core";

import { useSelectedTheme } from "../../../Hooks";
import { getDownloadableLink } from "../../../Helper";

// Icons
import { EmptyUnitGalleryIcon } from "../../../assets/icons";

import useStyles from "./styles";

const ImageCarousel = ({ imagesArray, height = 240 }) => {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();

  const imageUrls =
    imagesArray?.map((image) => ({
      id: image.fileId,
      img: getDownloadableLink(image.fileId),
    })) || [];

  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
      onMouseEnter={() => setIsHovered((prev) => (prev !== true ? true : prev))}
      onMouseLeave={() =>
        setIsHovered((prev) => (prev !== false ? false : prev))
      }
      className={clsx(styles.imageWrapper, {
        [styles.imageWrapperHover]: isHovered,
      })}
      style={{ height }}
    >
      {imagesArray?.length === 1 ? (
        <Box
          className={styles.image}
          style={{
            backgroundImage: `url(${getDownloadableLink(
              imagesArray[0].fileId
            )})`,
          }}
        />
      ) : imagesArray?.length > 1 && imageUrls?.[0]?.id ? (
        <Splide
          aria-label="Images"
          options={{
            classes: { arrows: "splide__arrows details-tab-arrows" },
            start: 0,
          }}
        >
          {imageUrls.map((image) => (
            <SplideSlide key={image.id}>
              <div className={styles.imageWrapper} style={{ height }}>
                <img
                  alt={`Image ${image.id}`}
                  src={image.img || ""}
                  width="100%"
                  height="100%"
                />
              </div>
            </SplideSlide>
          ))}
        </Splide>
      ) : (
        <Box className={styles.emptyImage} style={{ height }}>
          <EmptyUnitGalleryIcon
            width="52"
            height="52"
            fill={palette.foreground.quinary}
          />
        </Box>
      )}
    </Box>
  );
};

ImageCarousel.propTypes = {
  imagesArray: PropTypes.any,
  height: PropTypes.number,
};

export default ImageCarousel;
