import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box } from "@material-ui/core";

// Styles
import useStyles from "./styles";

function FBLabelWrapper({ label, required, helperInfo, isLabelSidePosition }) {
  const styles = useStyles();

  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="flex-start"
      className={styles.root}
    >
      {label && (
        <label
          className={clsx(styles.label, {
            [styles.labelSide]: isLabelSidePosition,
            [styles.labelAbove]: !isLabelSidePosition,
          })}
        >
          {label}
          {/* {required && " *"} */}
        </label>
      )}

      {helperInfo && isLabelSidePosition && (
        <label className={styles.helperInfo}>{helperInfo}</label>
      )}
    </Box>
  );
}

FBLabelWrapper.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  helperInfo: PropTypes.string,
  isLabelSidePosition: PropTypes.bool,
};

FBLabelWrapper.defaultProps = {
  label: "",
  required: false,
  helperInfo: "",
  isLabelSidePosition: true,
};

export default FBLabelWrapper;
