import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";

import { PropertiesAdvanceSearchTest } from "../../../Services";
import { PropertiesMapper } from "../../../Views/Home/PropertiesView/PropertiesUtilities/PropertiesMapper";
import { useNewLayoutShared } from "../../../Contexts/NewLayoutSharedContext";

// Properties Context
export const PropertiesLayoutContext = createContext();

export const PropertiesLayoutProvider = ({ children }) => {
  const hasMounted = useRef(false); // Track initial render
  const { advancedSearchBody, pagination } = useNewLayoutShared();
  const [propertiesData, setPropertiesData] = useState({
    result: [],
    totalCount: 0,
  });
  const [isLoading, setIsLoading] = useState(true);

  const fetchProperties = useCallback(async () => {
    setIsLoading(true);

    hasMounted.current = true; // Mark as mounted

    try {
      const response = await PropertiesAdvanceSearchTest(
        {
          pageIndex: pagination.currentPage - 1,
          pageSize: pagination.itemsPerPage,
          search: "",
        },
        advancedSearchBody
      );

      if (!(response && response.status && response.status !== 200)) {
        setPropertiesData({
          result: ((response && response.result) || []).map(
            (item) =>
              item?.propertyJson &&
              PropertiesMapper(item, JSON.parse(item?.propertyJson)?.property)
          ),
          totalCount: (response && response.totalCount) || 0,
        });
      } else {
        setPropertiesData({ result: [], totalCount: 0 });
      }
    } catch (error) {
      console.error("Failed to fetch properties:", error);
    } finally {
      setIsLoading(false);
      hasMounted.current = false;
    }
  }, [pagination, advancedSearchBody]);

  useEffect(() => {
    if (!hasMounted.current) {
      fetchProperties();
    }
  }, [pagination, advancedSearchBody]);

  return (
    <PropertiesLayoutContext.Provider
      value={{ propertiesData, setPropertiesData, isLoading, hasMounted }}
    >
      {children}
    </PropertiesLayoutContext.Provider>
  );
};
