const ReceiverTypes = {
    Lead: {
      key: "Lead",
      value: 1,
    },
    User: {
      key: "User",
      value: 2,
    }
}

export default ReceiverTypes;