import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  TextField,
  Box,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";

import { useSelectedTheme } from "../../../Hooks";
import { EmailIcon, PlusIcon } from "../../../assets/icons";
import { CustomButton } from "../CustomButton";

import useStyles from "./styles";

const EmailInput = ({
  error,
  errorMessage,
  helperText,
  idRef,
  inputClasses,
  inputPlaceholder,
  isDisabled,
  isLoading,
  isRequired,
  labelClasses,
  labelValue,
  onInputBlur,
  onInputChanged,
  onKeyDown,
  onKeyUp,
  themeClass,
  value,
  wrapperClasses,
  Style = {},
  setNewValue,
  isAdding = false,
  setIsAdding,
  AddAdditionalTable = false,
}) => {
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };
  const [inputValue, setInputValue] = useState(""); // State to store input value

  const handleAddEmail = () => {
    setNewValue((prev) => {
      const others = prev?.others || [];

      // Check if inputValue is already in others (case-insensitive)
      if (
        inputValue &&
        !others?.some(
          (email) => email.toLowerCase() === inputValue.toLowerCase()
        ) &&
        inputValue !== value
      ) {
        return {
          ...prev,
          others: [...(others || []), inputValue],
        };
      }

      return prev; // Return unchanged state if duplicate
    });

    setInputValue(""); // Clear the input field after adding
  };

  return (
    <Box
      style={{
        position: "relative",
      }}
      className={`${wrapperClasses} ${themeClass}`}
    >
      <Box className={styles.errorMessageContainer}>
        <TextField
          fullWidth
          id={idRef || ""}
          // label={labelValue}
          placeholder={inputPlaceholder}
          required={isRequired}
          disabled={isDisabled}
          value={isAdding ? inputValue : value || ""}
          error={Boolean(error)}
          helperText={helperText}
          variant="outlined"
          style={{
            ...Style,
          }}
          className={clsx(styles.textField, inputClasses, {
            [styles.inputContainerError]: error && errorMessage,
            [styles.inputContainerFocused]: !error && isFocused,
          })}
          InputLabelProps={{
            className: labelClasses,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ marginRight: "8px" }}>
                <EmailIcon
                  width="20"
                  height="20"
                  fill={palette.utility.utility_gray_500}
                />
              </InputAdornment>
            ),
            endAdornment: isLoading ? (
              <InputAdornment position="end" style={{ marginLeft: "8px" }}>
                <CircularProgress
                  size={20}
                  color={palette.button.brand_solid}
                />
              </InputAdornment>
            ) : null,
            autoComplete: "off",
            onKeyDown,
            onKeyUp,
          }}
          onFocus={handleFocus}
          onBlur={(event) => {
            setIsFocused(false);
            if (onInputBlur) onInputBlur(event);
          }}
          onChange={(e) => {
            if (isAdding) {
              setInputValue(e.target.value);
            } else if (onInputChanged) onInputChanged(e);
          }}
          // style={{
          //   borderColor: isFocused && !error ? palette.primary.main : undefined,
          // }}
        />
        {error && errorMessage && (
          <span className={styles.errorMessage}>{errorMessage}</span>
        )}

        {AddAdditionalTable && (
          <Box className={styles.containerAddItem}>
            <CustomButton
              size="sm"
              variant="text"
              color="tertiaryGray"
              style={{ display: "flex", gap: "12px" }}
              startIcon={
                <PlusIcon
                  width="20"
                  height="20"
                  fill={palette.foreground.quarterary}
                />
              }
              onClick={() => {
                setInputValue("");
                setIsAdding(true);
                if (inputValue?.length > 0) {
                  handleAddEmail();
                }
              }}
            >
              Add another
            </CustomButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EmailInput;

EmailInput.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  helperText: PropTypes.string,
  idRef: PropTypes.string.isRequired,
  inputClasses: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
  labelClasses: PropTypes.string,
  labelValue: PropTypes.string,
  onInputBlur: PropTypes.func,
  onInputChanged: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  themeClass: PropTypes.oneOf(["theme-default"]),
  value: PropTypes.string,
  wrapperClasses: PropTypes.string,
};

EmailInput.defaultProps = {
  error: false,
  errorMessage: "",
  helperText: "",
  inputClasses: "",
  inputPlaceholder: "",
  isDisabled: false,
  isLoading: false,
  isRequired: false,
  labelClasses: "",
  labelValue: "",
  onInputBlur: undefined,
  onInputChanged: undefined,
  onKeyDown: undefined,
  onKeyUp: undefined,
  themeClass: "theme-default",
  value: "",
  wrapperClasses: "",
};
