import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { Box, IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  PageTitle,
  CustomButton,
  PropertyCardSkeleton,
  TableSkeleton,
  BasicTable,
  CustomPagination,
  VIEW_MODES,
  CustomTableHeader,
  ContactsFilterBar,
  SaveFilterModal,
  NoDataFound,
  CustomPermission,
  PropertyCard,
  PropertyDetailsSideMenu,
  ImportPropertyModal,
} from '../../../Components';
import { QuickFilterSection } from './UI';
import {
  useIsDesktop,
  useSelectedTheme,
  useTitle,
  useTranslate,
} from '../../../Hooks';
import { PropertyTableHeaderData } from './PropertyTableHeaderData';
import { PropertiesMapper } from '../../../Views/Home/PropertiesView/PropertiesUtilities/PropertiesMapper';
import { FormsIdsEnum } from '../../../Enums';
import {
  GetAllFormFieldsByFormId,
  PropertiesAdvanceSearchTest,
} from '../../../Services';
import { flattenObject } from '../../../Helper';
import { useVerticalNav } from '../../../Contexts/VerticalNavContext';
import { useNewLayoutShared } from '../../../Contexts/NewLayoutSharedContext';
import { PropertiesLayoutContext } from '../../../Layouts/Home/NewPropertiesCrmLayout/PropertiesLayoutContext';
import { ContactsPermissions } from '../../../Permissions';

// Icons
import { UploadIcon, PlusIcon, ListIcon } from '../../../assets/icons';

// Styles
import useStyles from './styles';

function NewPropertiesView() {
  const { isDesktop } = useIsDesktop();
  const styles = useStyles({ isDesktop });
  const {
    setStickyBottomBox,
    mainLayoutRef,
    setActionableItems,
    setAddNewProprety,
  } = useVerticalNav();

  const dropPagination = useRef(null);
  const stickyPagination = useRef(null);
  const showMoreRef = useRef();

  const { translate } = useTranslate('NewPropertiesView');
  // const { translate: sharedTranslate } = useTranslate("ContactsView");

  const {
    theme: { palette },
  } = useSelectedTheme();

  const { isDarkMode } = useSelector((state) => state.theme);

  const {
    pagination,
    setPagination,
    filterModalData,
    savedFiltersCriteria,
    advancedSearchBody,
    setAdvancedSearchBody,
    setIsAddTaskDialogOpen,
  } = useNewLayoutShared();
  const { propertiesData, setPropertiesData, isLoading, hasMounted } =
    useContext(PropertiesLayoutContext);

  const [inputValue, setInputValue] = useState(pagination.currentPage);

  const [filterItems, setFilterItems] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [nextPage, setNextPage] = useState(pagination.currentPage);

  const flattenRows = (rows) => rows.map((row) => flattenObject(row));
  const flattenedRows = flattenRows([...propertiesData?.result]);

  useTitle(translate('PROPERTIES_PAGE_TITLE'));

  // Modify the fetchMoreProperties logic to append data instead of replacing it
  const fetchMoreProperties = async () => {
    setIsLoadMore(true);

    const totalItems = propertiesData?.totalCount;

    // Check if we've reached the end of data
    if (propertiesData?.result?.length >= totalItems) {
      setIsLoadMore(false); // Stop loading if all items are fetched
      return;
    }

    if (!isLoadMore) {
      try {
        const newPropertiesData = await PropertiesAdvanceSearchTest(
          {
            pageIndex: nextPage,
            pageSize: pagination.itemsPerPage,
            search: '',
          },
          advancedSearchBody
        );
        if (newPropertiesData && newPropertiesData.result.length > 0) {
          const mappedNewProperty = newPropertiesData.result
            .map((item) =>
              item.propertyJson
                ? PropertiesMapper(
                    item,
                    JSON.parse(item?.propertyJson)?.property
                  )
                : null
            )
            .filter(Boolean);
          // Append new data to existing propertiesData
          setPropertiesData((prevData) => ({
            ...prevData,
            result: [...prevData.result, ...mappedNewProperty], // Append new contacts to the existing list
          }));
          // Manually increment the next page number
          setNextPage(nextPage + 1); // Increment for future API requests
        }
      } catch (error) {
      } finally {
        setIsLoadMore(false);
      }
    }
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [activeItem, setActiveItem] = useState(undefined);
  const [viewMode, setViewMode] = useState(
    (localStorage.getItem("ViewType") &&
      JSON.parse(localStorage.getItem("ViewType")).propertiesView) ||
      VIEW_MODES.GRID
  );

  const onChangeViewMode = (value) => {
    setViewMode(value); // Update view mode

    const ViewTypeData = JSON.parse(localStorage.getItem("ViewType"));

    if (ViewTypeData) {
      ViewTypeData.propertiesView = value;
      localStorage.setItem("ViewType", JSON.stringify(ViewTypeData));
    } else
      localStorage.setItem(
        "ViewType",
        JSON.stringify({ propertiesView: value })
      );

    if (viewMode !== value) {
      setActionableItems({
        selectedIds: [],
        action: null,
      });
    }
  };

  const handleFilterConfirm = (
    params,
    title,
    inputValue,
    selectedValue,
    inputNameValue,
    selectedNameValue
  ) => {
    const field = params?.field || params?.colDef?.field;

    setAdvancedSearchBody((prevBody) => {
      const criteria = { ...prevBody.criteria };

      // Update criteria based on normal fields and special fields like createdBy/updatedBy
      criteria[field] = [{ searchType: selectedValue, value: inputValue }];

      const relatedField =
        title === 'Creation Date'
          ? 'createdBy'
          : title === 'Updated Date'
          ? 'updatedBy'
          : null;

      if (relatedField) {
        criteria[relatedField] = [
          { searchType: selectedNameValue, value: inputNameValue },
        ];
      }

      return { ...prevBody, criteria };
    });
  };

  const initialTableColumnsState = localStorage.getItem("TableColumnsFilter")
    ? JSON.parse(localStorage.getItem("TableColumnsFilter"))?.properties
    : null;
  const [allFormFields, setAllFormFields] = useState([]);
  const [updateSelectedColumnItems, setUpdateSelectedColumnItems] = useState(
    PropertyTableHeaderData(isDarkMode)
  );
  const [selectedTableFilterColumns, setSelectedTableFilterColumns] = useState(
    PropertyTableHeaderData(isDarkMode)
      .filter((item) => item.isDefaultFilterColumn)
      .map((column) => column.id)
  );

  // will be used for the duplicated contacts page in future
  const [duplicatedContactsCriteria, setDuplicatedContactsCriteria] = useState({
    result: [],
    totalCount: 0,
  });

  const getAllFormFieldsByFormId = useCallback(async () => {
    const result = await GetAllFormFieldsByFormId(FormsIdsEnum.properties.id);
    const excludedFields = [
      'amenities',
      'map',
      'facilities',
      'property_name',
      'property_owner',
      'unit_types_in_property',
    ];
    if (!(result && result.status && result.status !== 200)) {
      const list = result
        .filter(
          (e) => !excludedFields.includes(e.formFieldName)
          // e.formFieldName !== 'amenities' &&
          // e.formFieldName !== 'map' &&
          // e.formFieldName !== 'facilities' &&
          // e.formFieldName !== 'property_name' &&
          // e.formFieldName !== 'property_owner' &&
          // e.formFieldName !== 'unit_types_in_property'
        )
        .map((field) => ({
          id: field.formFieldId || null,
          key: field.formFieldKey || null,
          isDate: field.uiWidgetType === 'alt-date' || false,
          disableColumnMenu: true,
          minWidth: 190,
          headerName:
            (field.formFieldTitle && field.formFieldTitle.replace('*', '')) ||
            '',
          field: field.displayPath || '',
          isNumber:
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey ===
                'currency') ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === 'decimal') ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema &&
              field.propertyJson &&
              JSON.parse(field.propertyJson).schema.specialKey === 'size'),
          isSortable: true,
          searchableKey: field.searchableKey,
          data: JSON.parse(field.propertyJson).schema,
          fieldType:
            (field.propertyJson &&
              JSON.parse(field.propertyJson).schema.uiType) ||
            (field.propertyJson &&
              JSON.parse(field.propertyJson).uiSchema['ui:widget']) ||
            (field.uiWidgetType === null && 'text'),
          renderHeader: (params) => (
            <CustomTableHeader
              params={undefined}
              title={
                (field.formFieldTitle &&
                  field.formFieldTitle.replace('*', '')) ||
                ''
              }
            />
          ),
        }));
      setAllFormFields([...list]);
    } else setAllFormFields([]);
  }, []);

  useEffect(() => {
    getAllFormFieldsByFormId();
  }, [getAllFormFieldsByFormId]);

  useEffect(() => {
    if (initialTableColumnsState?.length > 0) {
      setUpdateSelectedColumnItems([
        ...PropertyTableHeaderData(isDarkMode),
        ...(allFormFields?.filter((item) =>
          initialTableColumnsState?.includes(item?.id || item?.formFieldId)
        ) || []),
      ]);
    }
  }, [allFormFields]);

  useEffect(() => {
    if (!isLoading) {
      localStorage.setItem('bulk-assign-contacts-items', JSON.stringify([]));
      // APIGetAllDuplicatedContactsCriteria();
      setIsFirstLoad(false); // Update the state after the first load
    }
  }, [isLoading]);

  // Function to observe visibility of the dropPagination element
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            stickyPagination.current.style.display = 'none';
          } else {
            stickyPagination.current.style.display = 'block';
          }
        });
      },
      {
        root: null, // Default to viewport
        threshold: 1, // Adjust this threshold as needed
      }
    );

    // Start observing the dropPagination element
    if (dropPagination.current) {
      observer.observe(dropPagination.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (dropPagination.current) {
        observer.unobserve(dropPagination.current);
      }
    };
  }, []);

  useEffect(() => {
    setStickyBottomBox(
      <CustomPermission
        permissionsList={Object.values(ContactsPermissions)}
        permissionsId={ContactsPermissions.ViewAndSearchContacts.permissionsId}
      >
        <CustomPagination
          hideInMobile
          ref={stickyPagination}
          currentPage={pagination.currentPage}
          inputValue={inputValue}
          setInputValue={setInputValue}
          totalItems={propertiesData?.totalCount}
          itemsPerPage={pagination.itemsPerPage}
          onPageChange={(page) =>
            setPagination((prev) => ({ ...prev, currentPage: page }))
          }
          onItemsPerPageChange={(items) =>
            setPagination((prev) => ({ ...prev, itemsPerPage: items }))
          }
          isLoading={isLoading}
          isSticky
        />
      </CustomPermission>
    );

    return () => {
      setStickyBottomBox(null);
    };
  }, [isLoading]);

  useEffect(() => {
    if (hasMounted.current) {
      return; // Exit early to prevent calling fetchMoreProperties initially
    }

    // Create an IntersectionObserver to observe the `showMoreRef` element
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !isLoadMore) {
          fetchMoreProperties(); // Trigger fetching when the observer comes into view
        }
      },
      {
        root: mainLayoutRef.current, // Observe within the main layout
        threshold: 1.0, // Trigger when 100% of the target is visible
      }
    );

    // Observe the `showMoreRef` element
    if (showMoreRef.current) observer.observe(showMoreRef.current);

    // Cleanup observer on component unmount or re-render
    return () => {
      if (showMoreRef.current) observer.unobserve(showMoreRef.current);
    };
  }, [mainLayoutRef, isLoadMore, isDesktop, viewMode]);

  const hasGridViewOnMobile = !isDesktop && viewMode === VIEW_MODES.GRID;

  return (
    <>
      <Box
        className={styles.pageContainer}
        onClick={() => {
          setActiveItem(undefined);
        }}
      >
        <PageTitle
          hideDivider
          title={translate('PROPERTIES_PAGE_TITLE')}
          subTitle={translate('PROPERTIES_PAGE_SUBTITLE')}
        >
          <CustomButton
            boxShadow='xs'
            size='lg'
            variant='outlined'
            color='secondary'
            onClick={() => {
              setIsImportModalOpen(true);
            }}
            startIcon={
              <UploadIcon
                width='20'
                height='20'
                fill={palette.button.secondary_fg}
              />
            }
          >
            {translate('IMPORT')}
          </CustomButton>

          <CustomButton
            boxShadow='xs'
            size='lg'
            variant='contained'
            color='primary'
            onClick={() => {
              setAddNewProprety((prevState) => ({
                ...prevState,
                addPropretyModalOpened: true,
              }));
            }}
            startIcon={
              <PlusIcon
                width='20'
                height='20'
                fill={palette.button.primary_fg}
              />
            }
          >
            {translate('ADD_NEW')}
          </CustomButton>
        </PageTitle>

        <Box>
          <QuickFilterSection
            viewMode={viewMode}
            onChangeViewMode={onChangeViewMode}
            allFormFields={PropertyTableHeaderData(isDarkMode).concat(
              allFormFields.filter(
                (item) =>
                  PropertyTableHeaderData(isDarkMode).findIndex(
                    (element) =>
                      element?.fieldKey === item?.formFieldKey ||
                      element?.fieldKey === item?.displayPath
                  ) === -1
              )
            )}
            setUpdateSelectedColumnItems={setUpdateSelectedColumnItems}
            tableColumns={updateSelectedColumnItems}
            initialTableColumnsState={initialTableColumnsState}
          />

          <ContactsFilterBar
            viewMode={viewMode}
            filterItems={filterItems}
            setFilterItems={setFilterItems}
            allTableColumns={PropertyTableHeaderData(isDarkMode).concat(
              allFormFields.filter(
                (item) =>
                  PropertyTableHeaderData(isDarkMode).findIndex(
                    (element) =>
                      element?.fieldKey === item?.formFieldKey ||
                      element?.fieldKey === item?.displayPath
                  ) === -1
              )
            )}
            data={flattenedRows || []}
            columnsDetails={updateSelectedColumnItems}
            favoriteFolders={[]} // should be updated !
          />

          {viewMode === VIEW_MODES.TABLE &&
            (isLoading ? (
              <TableSkeleton rowsNum={12} />
            ) : propertiesData?.result?.length > 0 ? (
              <BasicTable
                tableActions={({ anchorPosition }) => (
                  <Box
                    className={styles.actionsContainer}
                    style={{
                      top: anchorPosition.y,
                    }}
                  >
                    <Box className={styles.tableActionsBox}>
                      <IconButton
                        aria-controls='ListIcon'
                        aria-haspopup='true'
                        className={styles.button}
                        onClick={() => setIsAddTaskDialogOpen(true)}
                      >
                        <ListIcon
                          width='20'
                          height='20'
                          fill={palette.button.tertiaryGray_fg}
                        />
                      </IconButton>
                    </Box>
                  </Box>
                )}
                pageSize={pagination.itemsPerPage}
                rowsData={flattenedRows || []}
                setActiveItem={setActiveItem}
                columns={updateSelectedColumnItems?.map((column) => ({
                  ...column,
                  key: 'Property-' + column.fieldKey || column.headerName,
                  renderHeader: (params) => (
                    <CustomTableHeader
                      key={
                        'CustomTableHeader' + column.fieldKey ||
                        column.headerName
                      }
                      params={params}
                      title={column.headerName}
                      handleFilterConfirm={handleFilterConfirm}
                      isHiddenFilter={column.headerName === 'Progress'}
                      criteriaFilter={advancedSearchBody.criteria}
                    />
                  ),
                }))}
                rowHeight={84}
                borderRadius='0 0 8px 8px'
              />
            ) : (
              <NoDataFound title='Properties' />
            ))}

          {viewMode === VIEW_MODES.GRID && (
            <Box className={styles.gridContainer}>
              {isLoading && !isLoadMore ? (
                Array.from({ length: 25 }).map((_, index) => (
                  <PropertyCardSkeleton
                    index={index}
                    key={'PropertyCardSkeleton' + index}
                  />
                ))
              ) : propertiesData?.result?.length > 0 ? (
                propertiesData?.result?.map((item) => (
                  <>
                    <PropertyCard
                      item={item}
                      key={'PropertyCard' + item.id}
                      activeItem={activeItem}
                      setActiveItem={setActiveItem}
                    />
                  </>
                ))
              ) : (
                <NoDataFound title='Properties' />
              )}
            </Box>
          )}
        </Box>
      </Box>

      <PropertyDetailsSideMenu
        activeItem={activeItem}
        open={activeItem?.contactId || activeItem?.id ? true : false}
        onClose={() => setActiveItem({})}
      />

      {isImportModalOpen && (
        <ImportPropertyModal
          onClose={() => {
            setIsImportModalOpen(false);
          }}
        />
      )}

      {(filterModalData?.isOpen || savedFiltersCriteria?.isOpen) && (
        <SaveFilterModal />
      )}
    </>
  );
}

export default NewPropertiesView;
