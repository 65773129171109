import unitDtoMapper from "./unitDto.js";
import projectDescDtoMapper from "./projectDescDto.js";
import imagesDtoMapper from "./unitImagesDto.js";
import locationAndamenitiesDtoMapper from "./locationAndamenitiesDto.js";
import paymentPlansDtoMapper from "./paymentPlansDto.js";
import floorPlanDtoMapper from "./floorPlanDto.js";


const proposalDetailsDtoMapper = (properties) => {
console.log("properties", properties);

  const mappedProperties = properties.map((property) => ({
    showUnitPage: true,//Always Requried
    showPropertyDescriptionPage: property.submittedForm.PropertyDescription?.showPage,
    showImagesPage: property.submittedForm.UnitImages?.showPage,
    showFloorPlans: property.submittedForm.FloorPlan?.showPage,
    showPaymentPlan: property.submittedForm.PaymentPlan?.showPage,
    showLocationAndAmenitiesPage: property.submittedForm.LocationAndamenities?.showPage,
    units: unitDtoMapper(property),
    propertyDescription: projectDescDtoMapper(property),
    images: imagesDtoMapper(property),
    locationAndAmenities: locationAndamenitiesDtoMapper(property),
    paymentPlans: paymentPlansDtoMapper(property),
    floorPlanImages: floorPlanDtoMapper(property),
  }))

  return mappedProperties;
}

export default proposalDetailsDtoMapper;
