import React, {
  useCallback, useState, useEffect, useReducer
} from 'react';
import { useTranslation } from 'react-i18next';
import Joi from 'joi';
import moment from 'moment';
import exportFromJSON from 'export-from-json';
import { Button, Tooltip } from '@material-ui/core';
import { Spinner, SelectComponet, Inputs } from '../../../../Components';
import {
  bottomBoxComponentUpdate, showSuccess, showError, GlobalHistory
} from '../../../../Helper';
import { DateRangePickerComponent } from '../../../../Components/Controls/DateRangePickerComponent/DateRangePickerComponent';
import { SaveClientsSegmentation } from './Dialogs/SaveClientsSegmentation';
import { CreateClientSigmantationServices, GetSystemReportsResultClientsSegmentation, ExportReportServices } from '../../../../Services';
import { QeuriesComponents, SearchClientsSegmentationTable, SearchAndAddComponent } from './Components';

import { PaginationComponent } from '../../../../Components/PaginationComponent/PaginationComponent';

const parentTranslationPath = 'ClientsSegmentation';
const translationPath = '';

export const AddMarketingClientsSegmentation = ({ }) => {
  const { t } = useTranslation([parentTranslationPath, 'Shared']);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState(null);
  const [isOpenSaveDialog, setIsOpenSaveDialog] = useState(false);
  const [tableFilterData, setTableFilterData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fromIndex, setFromIndex] = useState('');
  const [formFields, setFormFields] = useState(null);
  const [orderTypeBy, setOrderTypeBy] = useState({
    index: 3, order: 2, searchKey: 'createdOn', orderByCategory: 'Contacts'
  });
  const [searchResponse, setSearchResponse] = useState({
    result: [],
    totalCount: 0
  });
  const [selectedOrderBy, setSelectedOrderBy] = useState({
    filterBy: null,
    orderBy: null,
  });

  const filterByChanged = (value) => {
    setSelectedOrderBy((item) => ({ ...item, filterBy: value }));
    if (!value)
      setDateFilter(dateRangeDefault);
  };

  const [fields, setFields] = useState([]);
  const [filter, setFilter] = useState({
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    pageIndex: 0,
    search: '',
  });
  const reducer = (state, action) => {
    if (action.id !== 'edit') return { ...state, [action.id]: action.value };
    if (action.id === 'edit') {
      return {
        ...action.value,
      };
    }
    return undefined;
  };

  const [ifHaveAnyErrors, setIfHaveAnyErrors] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [isSearchFirst, setIsSearchFirst] = useState(false);

  const [state, setState] = useReducer(reducer, {
    conditions: [{
      category: null,
      categoryType: null,
      searchKey: null,
      value: null,
      operator: null,
      operand: 'AND',
      fieldName: null,
    }],
    fields: [],
    clientSegmantationName: null,
    orderBy: null,
    orderByCategory: null,
    orderType: null,
    searchKey: null,
    fromDate: null,
    toDate: null,
    description: null,
    privacyType: 0,
    ownerId: null,
    isRolesPermission: false,
    isUsersPermission: false,
    clientSegmentationUserDtos: [],
    clientSegmentationRoleDtos: [],
    isAngryBird : false , 
    isHighProfileMangement : false , 

  });

  const dateRangeDefault = {
    startDate: null,
    endDate: null,
    key: 'selection',
  };

  const [dateFilter, setDateFilter] = useState(dateRangeDefault);

  const schema = Joi.array()
    .items(
      Joi.object({
        category: Joi.any()
          .custom((value, helpers) => {
            if (!value) return helpers.error('state.userNotSet');
            return value;
          }),
        categoryType: Joi.number()
          .required(),
        fieldName: Joi.object()
          .required(),
        value: Joi.any().allow('')
          .custom((value, helpers) => {
            if (!value) return helpers.error('state.typeNotSet');
            return value;
          }),
        operator: Joi.number()
          .required()
      })
    )
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state.conditions);


  const localSchema = Joi.object({
    clientSegmantationName: Joi.string()
      .required()
      .messages({
        'string.empty': t(`${translationPath}client-segmantation-name-is-required`),
        'string.base': t(`${translationPath}client-segmantation-name-is-required`)
      }),
      ownerId: Joi.number()
      .required()
      .messages({
        'number.base': t(`${translationPath}owner-is-required`),
        'number.empty': t(`${translationPath}owner-is-required`),
        'number.required': t(`${translationPath}owner-is-required`),
      }),
  })
    .options({
      abortEarly: false,
      allowUnknown: true,
    })
    .validate(state);

  const onPageIndexChanged = (pageIndex) => { setFilter((item) => ({ ...item, pageIndex })); };

  const onPageSizeChanged = (pageSize) => { setFilter((item) => ({ ...item, pageIndex: 0, pageSize })); };

  const contantValue = (conditionItem) => {
    if (conditionItem) {
      if (conditionItem.fieldName && conditionItem.fieldName && conditionItem.fieldName.field && conditionItem.fieldName.field.id &&
        (conditionItem.fieldName.field.id === 'createdBy' || conditionItem.fieldName.field.id === 'updatedBy'))
        return (conditionItem.value && conditionItem.value.id);

      return (conditionItem.value && (conditionItem.value.lookupItemName || conditionItem.value.name || conditionItem.value));
    }
  };
  const conditionsWithAngryBirdList = [
    {category: "contacts",operand: "AND",operator: 1,searchKey: "angry_bird",value: (state.isAngryBird ? 'Yes' :  'No')},
    {category: "contacts", operand: null, operator: 1,searchKey: "high_profile_management",value: (state.isHighProfileMangement ? 'Yes' :  'No')}];
  

  const returnFinalObject = (isSearch) => {
    const conditions = [];
    let finalObj = null;
    state.conditions.map((element) => {
      conditions.push({
        category: (element.category !== 'activities' ? element.category : 'leads'),
        categoryType: (element.categoryType !== 8 ? element.categoryType : 6),
        formId: element.categoryType,
        searchKey: element.searchKey,
        value: (contantValue(element)),
        operator: element.operator,
        operand: element.operand,
      });
    });
    if (isSearch) {
      finalObj = {
        report:
        {
          conditions : [...conditions ,  ...conditionsWithAngryBirdList], 
          fields: state.fields,
          systemReportFilters: {
            orderBy: orderTypeBy && orderTypeBy.searchKey,
            orderType: (orderTypeBy && orderTypeBy.order),
            orderByCategory: (orderTypeBy && orderTypeBy.orderByCategory),
            fromDate : state.fromDate , 
            toDate : state.toDate , 
            dateType: (selectedOrderBy && selectedOrderBy.filterBy),
          },
        }
      };
      return (finalObj);
    }
    const conditionsWithAngryBird =  [...state.conditions , ...conditionsWithAngryBirdList ] ;
    finalObj =
    {
      conditionsJson: state.conditions ? JSON.stringify(conditionsWithAngryBird) : '',
      fieldsJson: state.fields ? JSON.stringify(state.fields) : '',
      clientSegmentationName: state.clientSegmantationName,
      clientSegmentationFiles: [],
      systemReportFilters: JSON.stringify(
        {
          orderBy: orderTypeBy && orderTypeBy.searchKey,
          orderType: (orderTypeBy && orderTypeBy.order),
          orderByCategory: (orderTypeBy && orderTypeBy.orderByCategory),
          fromDate: (state && state.fromDate),
          toDate: (state && state.toDate),
          dateType: (selectedOrderBy && selectedOrderBy.filterBy),
        }
      ),
      description: state.description,
      ownerId: state.ownerId,
      isRolesPermission: state.isRolesPermission,
      isUsersPermission: state.isUsersPermission,
      privacyType: state.privacyType,
      clientSegmentationUserDtos: state.clientSegmentationUserDtos,
      clientSegmentationRoleDtos: state.clientSegmentationRoleDtos,
      clientSegmentationFilters: JSON.stringify(
        {
          orderBy: orderTypeBy && orderTypeBy.searchKey,
          orderType: (orderTypeBy && orderTypeBy.order),
          orderByCategory: (orderTypeBy && orderTypeBy.orderByCategory),
          fromDate: (state && state.fromDate),
          toDate: (state && state.toDate),
          dateType: (selectedOrderBy && selectedOrderBy.filterBy),
        }
      ),
    };
    return finalObj;
  };

  const searchHandler = useCallback(async () => {
    if (!state.fields.length) {
      showError(t(`${translationPath}please_select_columns`));
      return;
    }

    if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }

    if (orderTypeBy && orderTypeBy.searchKey) {
      const findIndexOfOrderBy = tableColumns.indexOf(
        tableColumns.find((f) => (f.searchableKey === (orderTypeBy && orderTypeBy.searchKey)))
      );

      if (findIndexOfOrderBy === -1) {
        showError(t(`${translationPath}please_select_columns`));
        return;
      }
    }
    const reportJson = returnFinalObject(true);
    const searchObj = { spName: 'GetSegmentationContacts', reportJson };
    setIsLoading(true);
    const res = await GetSystemReportsResultClientsSegmentation(filter.pageIndex + 1, filter.pageSize, searchObj);
    if (!(res && res.status && res.status !== 200)) {
      setSearchResponse({ result: res, totalCount: (res && res.length ? res[0].TotalCount : 0) });
      showSuccess(t(`${translationPath}search-clients-segmentation-successfully`));
      setIsSearchFirst(true);
      setIsLoading(false);
    } else {
      setSearchResponse({ result: [], totalCount: 0 });
      showError(t(`${translationPath}search-clients-segmentation-failure`));
    }
    setIsLoading(false);
  }, [state, tableColumns, sortBy, filter, orderTypeBy, selectedOrderBy]);

  const saveHandler = useCallback(async () => {
    setIsSubmitted(true) ; 
    if (!orderTypeBy || !state.fields.length) {
      showError(t(`${translationPath}please_select_columns`));
      return;
    }
    if (localSchema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }
    else if (schema.error) {
      showError(t('Shared:please-fix-all-errors'));
      return;
    }
    if (orderTypeBy && orderTypeBy.searchKey) {
      const findIndexOfOrderBy = tableColumns.indexOf(
        tableColumns.find((f) => (f.searchableKey === (orderTypeBy && orderTypeBy.searchKey)))
      );

      if (findIndexOfOrderBy === -1) {
        showError(t(`${translationPath}please_select_columns`));
        return;
      }
    }
    setIsLoading(true);
    const addCilent = returnFinalObject(false);
    const res = await CreateClientSigmantationServices(addCilent);
    if (!(res && res.status && res.status !== 200)) {
      showSuccess(t(`${translationPath}add-clients-segmentation-successfully`));
      setState({ id: 'clientSegmantationName', value: null });
      GlobalHistory.goBack();
      setIsOpenSaveDialog(false);
    } else showError(t(`${translationPath}add-clients-segmentation-failure`));
    setIsLoading(false);
  }, [state]);

  useEffect(() => {
    const selectedfields = [];
    tableColumns.map((e) => {
      selectedfields.push({
        name: e.label,
        isHidden: e.isHidden,
        displayPath: e.displayPath,
        category: e.category,
        searchKey: e.searchableKey,
        categoryType: e.categoryType,
      });
    });
    setState({ id: 'fields', value: selectedfields });
  }, [tableColumns]);

  useEffect(() => {
    const currentState = {
      ...state,
      orderBy: (orderTypeBy && orderTypeBy.searchKey) || null,
      orderType: (orderTypeBy && orderTypeBy.order) || null,
      orderByCategory: (orderTypeBy && orderTypeBy.orderByCategory) || null
    };
    setState({ id: 'edit', value: currentState });
    setFilter((item) => ({ ...item, pageIndex: 0 }));
  }, [orderTypeBy]);

  useEffect(() => {
    const currentState = {
      ...state,
      fromDate: (dateFilter.startDate && moment(dateFilter.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss')) || null,
      toDate: (dateFilter.endDate && moment(dateFilter.endDate).format('YYYY-MM-DDTHH:mm:ss')) || null,
    };
    setState({ id: 'edit', value: currentState });
  }, [dateFilter]);


  useEffect(() => {
    if (isSearchFirst)
      searchHandler();
  }, [filter, state.fields , state.fromDate , state.toDate  , state.isAngryBird ,  state.isHighProfileMangement]);

  const exportDataToFormats = async (Format, fileName) => {
    if (!searchResponse.result.length) {
      showError(t(`${translationPath}Dont_have_data_to_export_excel`));
      return;
    }
    if (searchResponse.result.length) {
      setIsLoading(true);
      const reportJson = returnFinalObject(true);
      const searchObj = { spName: 'GetSegmentationContacts', reportJson };
      setIsLoading(true);
      const res = await GetSystemReportsResultClientsSegmentation(1, searchResponse.result[0].TotalCount, searchObj);
      if (!(res && res.status && res.status !== 200)) {
        setIsLoading(false);
        if (res && res.length === 0) return;
        const data = res;
        let exportType = null;
        switch (Format) {
          case 'xls':
            exportType = exportFromJSON.types.xls;
            break;
          case 'csv':
            exportType = exportFromJSON.types.csv;
            break;
          case 'xml':
            exportType = exportFromJSON.types.xml;
            break;
          case 'json':
            exportType = exportFromJSON.types.json;
            break;
          default:
            exportType = exportFromJSON.types.xls;
            break;
        }
        exportFromJSON({ data, fileName, exportType });
        const res2 = await ExportReportServices();
        if (res2) {

        }
      } else
        showError(t(`${translationPath}search-clients-segmentation-failure`));
    }
  };

  const getValue = (object, values) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(object)) {
      if (key === values) {
        const valueIs = ((value !== null) ? value : 'N/A');
        return valueIs;
      }
    }
  };

  useEffect(() => {
    bottomBoxComponentUpdate(
      <PaginationComponent
        pageIndex={filter.pageIndex}
        pageSize={filter.pageSize}
        totalCount={searchResponse.result && searchResponse.result.length ? searchResponse.result[0].TotalCount : 0}
        onPageIndexChanged={onPageIndexChanged}
        onPageSizeChanged={onPageSizeChanged}
      />
    );
  });
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );

  const sortHandler = (item, index) => {
    setOrderTypeBy(
      {
        index,
        order: orderTypeBy && orderTypeBy.order === 2 ? 1 : 2,
        searchKey: item.searchableKey || '',
        orderByCategory: item.category
      }
    );
  };

  const byChanged = (index, item) => {
    setTableColumns((items) => {
      items.splice(fromIndex, 1);
      items.splice(index, 0, formFields);
      if (fromIndex !== -1)
        return [...items];
    });
  };

  const tables = document.getElementsByClassName('resizable');
  for (let i = 0; i < tables.length; i++)
    resizableGrid(tables[i]);

  function resizableGrid(table) {
    const row = table.getElementsByTagName('tr')[0];
    const cols = row ? row.children : undefined;
    if (!cols) return;

    table.style.overflow = 'hidden';

    const tableHeight = table.offsetHeight;

    for (let i = 0; i < cols.length; i++) {
      const div = createDiv(tableHeight);
      cols[i].appendChild(div);
      cols[i].style.position = 'relative';
      setListeners(div);
    }

    function setListeners(div) {
      let pageX; let curCol; let nxtCol; let curColWidth; let nxtColWidth; let
        tableWidth;

      div.addEventListener('mousedown', (e) => {
        tableWidth = 200;
        curCol = e.target.parentElement;
        nxtCol = curCol.nextElementSibling;
        pageX = e.pageX;

        const padding = paddingDiff(curCol);

        curColWidth = curCol.offsetWidth - padding;
        if (nxtCol)
          nxtColWidth = nxtCol.offsetWidth - padding;
      });

      div.addEventListener('mouseover', (e) => {
        e.target.style.borderRight = '2px solid #0000ff';
      });

      div.addEventListener('mouseout', (e) => {
        e.target.style.borderRight = '';
      });

      document.addEventListener('mousemove', (e) => {
        if (curCol) {
          const diffX = e.pageX - pageX;

          if (nxtCol)
            nxtCol.style.width = `${nxtColWidth - (diffX)}px`;

          curCol.style.width = `${curColWidth + diffX}px`;
          document.getElementById('tableId').style.width = `${tableWidth + diffX}px`;
        }
      });

      document.addEventListener('mouseup', (e) => {
        curCol = undefined;
        nxtCol = undefined;
        pageX = undefined;
        nxtColWidth = undefined;
        curColWidth = undefined;
      });
    }

    function createDiv(height) {
      const div = document.createElement('div');
      div.style.top = 0;
      div.style.right = 0;
      div.style.width = '5px';
      div.style.position = 'absolute';
      div.style.cursor = 'col-resize';
      div.style.userSelect = 'none';
      div.style.height = `${height}px`;
      return div;
    }

    function paddingDiff(col) {
      if (getStyleVal(col, 'box-sizing') == 'border-box')
        return 0;

      const padLeft = getStyleVal(col, 'padding-left');
      const padRight = getStyleVal(col, 'padding-right');
      return (parseInt(padLeft) + parseInt(padRight));
    }

    function getStyleVal(elm, css) {
      return (window.getComputedStyle(elm, null).getPropertyValue(css));
    }
  }

  return (
    <div className='ClientsSegmentationView-wrapper view-wrapper'>
      <Spinner isActive={isLoading} />
      <div className='w-100 px-2'>
        <div className='filterByDate'>
          <div className='CenterDate'>
            <SelectComponet
              idRef='filterByRef'
              data={[
                { id: 1, filterBy: 'created-on' },
                { id: 2, filterBy: 'last-updated' },
              ]}
              wrapperClasses='mb-3'
              isRequired
              value={selectedOrderBy.filterBy}
              onSelectChanged={filterByChanged}
              valueInput='id'
              textInput='filterBy'
              emptyItem={{
                value: null,
                text: 'select-filter-by',
                isDisabled: false,
              }}
              parentTranslationPath={parentTranslationPath}
              translationPath={translationPath}
              translationPathForData={translationPath}
              endAdornment={(
                <div className='d-flex-v-center'>
                  <span className='px-2'>   </span>
                  <DateRangePickerComponent
                    onClearClicked={() => {
                      setDateFilter({
                        startDate: null,
                        endDate: null,
                        key: 'selection'
                      });
                      setSelectedOrderBy((item) => ({ ...item, filterBy: null }));
                    }}
                    ranges={[dateFilter]}
                    parentTranslationPath={parentTranslationPath}
                    translationPath={translationPath}
                    onDateChanged={(selectedDate) => {
                      setDateFilter({
                        startDate: selectedDate.selection && selectedDate.selection.startDate,
                        endDate: new Date(moment(selectedDate.selection && selectedDate.selection.endDate).endOf('day')),
                        key: 'selection',
                      });
                    }}
                    isDisabled={!selectedOrderBy.filterBy}
                  />
                </div>
              )}
            />

          </div>
          <SearchAndAddComponent
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            state={state}
            schema={schema}
            ifHaveAnyErrors={ifHaveAnyErrors}
            searchHandler={searchHandler}
            saveHandler={saveHandler}
            setIsOpenSaveDialog={setIsOpenSaveDialog}
            exportDataToFormats={(format, name) => {
              exportDataToFormats(format, name);
            }}
            searchResponse={searchResponse}
            createHubSpotJson={returnFinalObject(true)}
          />
        </div>
      </div>
      <div className='w-100 px-2'>
        <div className='client-segmentation-wrapper'>
          <QeuriesComponents
            state={state}
            schema={schema}
            setState={setState}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            ifHaveAnyErrors={ifHaveAnyErrors}
            setIfHaveAnyErrors={setIfHaveAnyErrors}
            fields={fields}
            setFields={setFields}
            searchHandler={searchHandler}
            saveHandler={saveHandler}
            setIsOpenSaveDialog={setIsOpenSaveDialog}
          />
        </div>
      </div>
      <div className='w-100 px-2 mt-2'>
        <SearchClientsSegmentationTable
          tableColumns={tableColumns}
          parentTranslationPath={parentTranslationPath}
          translationPath={translationPath}
          setTableColumns={setTableColumns}
          setSortBy={setSortBy}
          tableFilterData={tableFilterData}
          filter={filter}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
          setState={setState}
          searchResponse={searchResponse}
        />
      </div>
      {
        isOpenSaveDialog && (
          <SaveClientsSegmentation
            open={isOpenSaveDialog}
            localSchema={localSchema}
            close={() => { setIsOpenSaveDialog(false); }}
            parentTranslationPath={parentTranslationPath}
            translationPath={translationPath}
            onSave={() => {
              saveHandler();
            }}
            setState={setState}
            state={state}
            setIsSubmitted={setIsSubmitted}
            isSubmitted={isSubmitted}
            isLoading={isLoading}
          />
        )
      }
      <div className='w-100 px-2'>
        <div className='TableReportsView-view-wrapers mt-3 searchTable'>
          <table
            id='tableId'
            className='table'
          >
            <thead
              className=''
            >
              <tr>
                {
                  tableColumns.map((item, itemIndex) => (
                    <Tooltip
                      title={item.label}
                      aria-label='CompanyTip'
                      arrow
                    >
                      <th
                        index={itemIndex + 1000}
                        onDragOver={(event) => {
                          event.preventDefault();
                        }}
                        draggable
                        onDrop={() => (byChanged(itemIndex, item)) || ''}
                        onDragCapture={() => {
                          setFromIndex(itemIndex);
                          setFormFields(item);
                        }}
                      >
                        <Inputs
                          idRef={`items${itemIndex + 1}`}
                          parentTranslationPath={parentTranslationPath}
                          translationPath={translationPath}
                          value={item.label}
                          onInputChanged={(event) => {
                          }}
                          endAdornment={(
                            <>
                              <Tooltip

                                size='small'
                                title={t(`${translationPath}sort`)}
                              >
                                <Button
                                  onClick={() => {
                                    sortHandler(item, itemIndex);
                                  }}
                                  className={`trash-bbt  ${item.searchableKey === (orderTypeBy && orderTypeBy.searchKey) && item.category === (orderTypeBy && orderTypeBy.orderByCategory) ? 'is-selected' : ''} is-red`}
                                >
                                  <span className='p-1 youtube-wraper'>
                                    <span className='mdi mdi-swap-vertical-bold mdi-24px' />
                                  </span>
                                </Button>
                              </Tooltip>
                            </>
                          )}
                        />
                      </th>
                    </Tooltip>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {searchResponse && searchResponse.result.map((item, index) => (
                <tr index={index}>
                  {state && state.fields.map((itemrrr, index2) => (
                    <td index={index2 * Math.random()}>
                      {getValue(item, itemrrr.name) || ''}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>

          <div />

        </div>

      </div>

    </div>
  );
};
