import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SwitchRoute } from "../../Components/Route/SwitchRoute";
import { NewHomeRoutes } from "../../routes/HomeRoutes/HomeRoutes";
import { VerticalNavProvider } from "../../Contexts/VerticalNavContext";
import { NewLayoutSharedProvider } from "../../Contexts/NewLayoutSharedContext";
import { ContactValidationProvider } from "../../Contexts/ContactValidationContext/ContactValidationContext";
import { useNewLayoutStyles, useIsDesktop, useIsNewLayout } from "../../Hooks";
import { MobileCalendarPage } from "../../Components/V2/MobileCalendarPage";
import { MobileNotificationsPage } from "../../Components/V2/MobileNotificationsPage";
import { MobileTabsWindow } from "../../Components/V2/MobileTabsWindow";
import { LOGOUt } from "../../store/login/Actions";
import { TOGGLE_LAYOUT } from "../../store/layout/Actions";
import {
  Header,
  MobileSideNav,
  SideNav,
  Footer,
  MobileNav,
  VerticalLayout,
  LayoutContent,
} from "../../Components";

// Styles
import "./_NewHomeLayout.scss";

const NewHomeLayout = () => {
  useNewLayoutStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const isNewLayout = useIsNewLayout();
  
  const { isDesktop } = useIsDesktop();

  const [mobileTabValue, setMobileTabValue] = useState(-1);

  useEffect(() => {
    setTimeout(() => {
      if (!JSON.parse(localStorage.getItem("session"))?.token && isNewLayout) {
        localStorage.removeItem('session');
        window.localStorage.clear();
        dispatch(LOGOUt());
        dispatch(TOGGLE_LAYOUT());
        history.push('/account/login');
      }
    }, 1600);
  }, [JSON.parse(localStorage.getItem("session"))?.token]);

  return (
    <VerticalNavProvider>
      <ContactValidationProvider>
        <NewLayoutSharedProvider>
          <VerticalLayout
            sideNav={<SideNav />}
            header={<Header />}
            footer={<Footer />}
            mobileNav={
              <MobileNav
                mobileTabValue={mobileTabValue}
                setMobileTabValue={setMobileTabValue}
              />
            }
            mobileSideNav={<MobileSideNav />}
          >
            {!isDesktop && mobileTabValue === 1 && (
              <MobileTabsWindow>
                <MobileCalendarPage setMobileTabValue={setMobileTabValue} />
              </MobileTabsWindow>
            )}

            {!isDesktop && mobileTabValue === 2 && (
              <MobileTabsWindow>
                <MobileNotificationsPage
                  setMobileTabValue={setMobileTabValue}
                />
              </MobileTabsWindow>
            )}

            <LayoutContent>
              <SwitchRoute
                routes={NewHomeRoutes?.filter((route) => !route.hasNewLayout)}
              />
            </LayoutContent>
          </VerticalLayout>
        </NewLayoutSharedProvider>
      </ContactValidationProvider>
    </VerticalNavProvider>
  );
};

export { NewHomeLayout };
