import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { CustomBreadCrumb, PageTitle } from "../../../../Components";
import {
  GetParams,
  GlobalHistory,
  showError,
  showSuccess,
} from "../../../../Helper";
import { useSelectedTheme } from "../../../../Hooks";
import {
  contactsDetailsGet,
  GetAllFormFieldsByFormId,
  MergeContact,
} from "../../../../Services";
import { ContactsMergeActions } from "../../../../store/ContactsMerge/Actions";
import { CustomButton } from "../../ResaleUnitsView/ResaleUnitsFilters/utilities";
import useStyles from "./styles";
import MergeContactCardsContainer from "./UI/MergeContactCards/MergeContactCardsContainer";
import NewMergeContactsContextProvider, {
  NewMergeContactsContext,
} from "../../../../Contexts/NewMergeContactsContext/NewMergeContactsContext";
import { Skeleton } from "@material-ui/lab";
function NewMergeContacts() {
  return (
    <>
      <NewMergeContactsContextProvider>
        <NewMergeContactsContent />
      </NewMergeContactsContextProvider>
    </>
  );
}

export default NewMergeContacts;

function BreadCrumbsMergeName({ contact, styles, userTypeId, isLoading }) {
  return (
    <>
      {isLoading ? (
        <Skeleton animation="wave" height={28} width={197} variant="text" />
      ) : (
        <Box className={styles.bread_crumbs_merge_name_styles}>
          <span>
            {userTypeId === 1
              ? contact?.contact?.first_name || ""
              : contact?.contact?.company_name || ""}
          </span>
          <span>
            {" #"}
            {userTypeId === 1
              ? contact?.contactsId || ""
              : contact?.contactsId || ""}
          </span>
        </Box>
      )}
    </>
  );
}

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function NewMergeContactsContent() {
  const {
    setMergeRows,
    translate,
    allContactsInfo,
    setAllContactsInfo,
    selectedRadioValues,
    setSelectedRadioValues,
    originalContact,
    finalContactDetails,
  } = useContext(NewMergeContactsContext);
  const styles = useStyles();
  const {
    theme: { palette },
  } = useSelectedTheme();
  const dispatch = useDispatch();
  const userTypeId = +GetParams("userTypeId");
  const contactsMergeInfo = useSelector((state) => state.ContactsMergeReducer);
  const reducer = useCallback((state, action) => {
    if (action.id !== "edit") return { ...state, [action.id]: action.value };
    return {
      ...action.value,
    };
  }, []);

  const [isLoading, setIsLoading] = useReducer(reducer, {
    contactsInfo: false,
    formLoading: false,
    merging: false,
  });

  async function getContactInfo(contactId) {
    const response = await contactsDetailsGet({ id: contactId });

    if (!(response && response.status && response.status !== 200)) {
      return response;
    }
  }

  async function getAllContactsDetails() {
    const contactsIds = contactsMergeInfo?.ContactsMergeIds || [];
    if (contactsIds?.length > 0) {
      setIsLoading({ id: "contactsInfo", value: true });
      let contactsDetailsBuffer = [];
      for (let i = 0; i < contactsIds.length; i++) {
        const res = await getContactInfo(contactsIds[i]);
        if (res) contactsDetailsBuffer.push(res);
      }

      setAllContactsInfo(contactsDetailsBuffer);
    }
    setIsLoading({ id: "contactsInfo", value: false });
  }

  async function getAllMergeRows() {
    setIsLoading({ id: "formLoading", value: true });
    if (userTypeId) {
      const res = await GetAllFormFieldsByFormId(userTypeId);

      const mappedRows =
        res.map((item) => ({
          fieldId: item.formFieldId,
          fieldName: item.formFieldName,
          fieldType: item.uiWidgetType,
        })) ?? [];

      setMergeRows(mappedRows);
      const arr = res.map((item) => item.formFieldName);
      setSelectedRadioValues({
        id: "edit",
        value: arr.reduce((acc, key) => {
          acc[key] = 0;
          return acc;
        }, {}),
      });
    }
    setIsLoading({ id: "formLoading", value: false });
  }

  async function mergeHandler() {
    setIsLoading({ id: "merging", value: true });
    const mergeIds = allContactsInfo
      .filter((item) => item.contactsId !== originalContact.contactsId)
      .map((item) => item.contactsId);

    const body = {
      originalId: originalContact.contactsId,
      obsoleteIds: mergeIds,
      mergedJson: {
        contact: finalContactDetails.contact,
      },
    };

    const result = await MergeContact(body);

    if (!(result && result.status && result.status !== 200)) {
      showSuccess(translate(`contacts-merge-notification`));
      GlobalHistory.goBack();
      dispatch(ContactsMergeActions.contactsMergeRequest([]));
    } else if (
      result &&
      result.data &&
      result.data.Message.includes("THERE_IS_TRANSACTION_IN_CONTACT")
    )
      showError(translate(`${result.data.Message.split(": ")[1]}`));
    else showError(translate(`contacts-merged-failed`));

    setIsLoading({ id: "merging", value: false });
  }

  async function cancelMergeHandler() {
    GlobalHistory.goBack();
    dispatch(ContactsMergeActions.contactsMergeRequest([]));
  }

  useEffect(() => {
    getAllContactsDetails();
    getAllMergeRows();
  }, []);

  return (
    <>
      <Box className={styles.new_merge_view_page_wrapper}>
        <CustomBreadCrumb
          breadcrumbs={[
            { label: translate("crm"), nonClickable: true },
            { label: translate("contact"), link: "/home/Contacts-CRM/view" },
            {
              label: (
                <BreadCrumbsMergeName
                  contact={allContactsInfo?.[0]}
                  styles={styles}
                  userTypeId={userTypeId}
                  isLoading={isLoading.contactsInfo}
                />
              ),
              link: "/home/Contacts-CRM/view",
            },
          ]}
        ></CustomBreadCrumb>

        <PageTitle
          title={translate("MERGE_CONTACTS_PAGE_TITLE")}
          subTitle={translate("MERGE_CONTACTS_PAGE_SUBTITLE")}
          hideDivider={true}
          showBackIcon={true}
        >
          <CustomButton
            className={styles.cancelButton}
            onClick={() => {
              cancelMergeHandler();
            }}
          >
            {translate("cancel")}
          </CustomButton>
          <CustomButton
            className={styles.mergeButton}
            onClick={() => {
              mergeHandler();
            }}
          >
            {translate("merge-contacts")}
          </CustomButton>
        </PageTitle>

        <MergeContactCardsContainer
          contactsIds={contactsMergeInfo?.ContactsMergeIds}
          allContactsInfo={allContactsInfo}
          selectedRadioValues={selectedRadioValues}
          setSelectedRadioValues={setSelectedRadioValues}
        />
      </Box>
    </>
  );
}
