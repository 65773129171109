import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { GetAllPropertyUnitModelsAPI } from "../../../../../../../../Services";
import { CustomBadge, CustomButton } from "../../../../../../../../Components";
import { useSelectedTheme, useTranslate } from "../../../../../../../../Hooks";
import { UnitsModelsCrad } from "../";

// styles
import useStyles from "./styles";

function UnitsModelsComponent({ setTabValue, propretyId }) {
  const styles = useStyles();
  const { translate } = useTranslate("NewPropertiesView");
  const {
    theme: { palette },
  } = useSelectedTheme();
  const [unitModels, setUnitModels] = useState({
    result: [],
    totalCount: 0,
  });

  const getAllUnitModelAndFilesByPropertyId = useCallback(async () => {
    const res =
      propretyId &&
      (await GetAllPropertyUnitModelsAPI({
        propertyId: propretyId,
        pageIndex: 1,
        pageSize: 100,
      }));

    if (!((res && res.data && res.data.ErrorId) || !res)) {
      setUnitModels({
        result: (res && res.result) || [],
        totalCount: res.totalCount,
      });
    } else setUnitModels({ result: [], totalCount: 0 });
  }, [propretyId]);

  useEffect(() => {
    getAllUnitModelAndFilesByPropertyId();
  }, []);

  return (
    <Box className={styles.wrapperUnitsModelsComponent}>
      <Box className={styles.containerHeader}>
        <Box className={styles.containerLeftSide}>
          <Typography className={styles.containerMainTitle}>
            {translate("Units-Models")}
          </Typography>
          <CustomBadge
            label={`${unitModels?.totalCount} Units models`}
            SizeVariant={"large"}
            BackgroundColor={palette.utility["brand_50"]}
            BorderColor={palette.utility["brand_200"]}
            Color={palette.utility["brand_700"]}
          />
        </Box>
        {unitModels?.result?.length > 0 && (
          <CustomButton
            boxShadow="none"
            size="sm"
            variant="text"
            color="tertiaryGray"
            style={{ minWidth: "fit-content" }}
            onClick={() => {
              setTabValue(1);
            }}
          >
            {translate("View-All")}
          </CustomButton>
        )}
      </Box>
      <Box className={styles.containerCards}>
        {unitModels &&
          unitModels?.result.length > 0 &&
          unitModels?.result
            ?.slice(0, 3)
            ?.map((itemCard, index) => (
              <UnitsModelsCrad unitsModel={itemCard} />
            ))}
      </Box>
    </Box>
  );
}
export default UnitsModelsComponent;
