import CoverPageForm from "../Forms/FormComponents/CoverPageForm";
import FloorPlanForm from "../Forms/FormComponents/FloorPlanForm";
import LocationAndAmenitiesForm from "../Forms/FormComponents/LocationAndAmenitiesForm";
import MultiUnitsDetailsForm from "../Forms/FormComponents/MultiUnitsDetailsForm";
import PaymentPlanForm from "../Forms/FormComponents/PaymentPlanForm";
import PropertyDescriptionForm from "../Forms/FormComponents/PropertyDescriptionForm";
import UnitsDetailsForm from "../Forms/FormComponents/UnitDetailsForm";
import UnitImagesForm from "../Forms/FormComponents/UnitImagesForm";

const ProposalSteps = [
    {
      key: "CoverPage",
      label: "Cover Page",
      component: CoverPageForm,
      hasPageToggle: false,
    },
    {
      key: "UnitsDetails",
      label: "Units details",
      component: UnitsDetailsForm,
      hasPageToggle: false,
    },
    {
      key: "MultiUnitsDetails",
      label: "Units details",
      component: MultiUnitsDetailsForm,
      hasPageToggle: false,
    },
    {
      key: "PropertyDescription",
      label: "Property description",
      component: PropertyDescriptionForm,
      hasPageToggle: true,
    },
    {
      key: "UnitImages",
      label: "Images",
      component: UnitImagesForm,
      hasPageToggle: true,
    },
    {
      key: "LocationAndamenities",
      label: "Location & amenities",
      component: LocationAndAmenitiesForm,
      hasPageToggle: true,
    },
    {
      key: "FloorPlan",
      label: "Floor plan",
      component: FloorPlanForm,
      hasPageToggle: true,
    },
    {
      key: "PaymentPlan",
      label: "Payment plan",
      component: PaymentPlanForm,
      hasPageToggle: true,
    },
  ];

  export default ProposalSteps;