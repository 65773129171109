import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
  return {
    wrapperUnitSucsessValidateDialog :{
      display:'flex',
      flexDirection:'row',
      minHeight:'992px'
    },containerPropretyInfo:{
      background : theme.palette.background.gray_200,
      width:'336px',
      display:'flex',
      flexDirection:'column',
      gap:'16px',
      padding:'20px 24px',
    },containerFillFiled:{
      display:'flex',
      flexDirection:'column',
      gap:'16px',
      padding:'20px 24px',
      width:'688px'
    },
    containerContentDialog:{
      padding: `20px 0px 0px 0px !important`
    },containerProprtyInfoItem:{
      display:'flex',
      flexDirection:'column',
      gap:'8px',
      alignItems:'flex-start'
    },TitleInfo:{
      fontSize:'14px',
      fontWeight:'500',
      lineHeight:'20px',
      color:theme.palette.text.tertiary
    },
    valueInfo:{
      fontSize:'16px',
      fontWeight:'600',
      lineHeight:'24px',
      color:theme.palette.text.secondary 
    },containerFiledItem:{
      display:'flex',
      flexDirection:'row',
      gap:'32px',
      marginBottom:'10px'
    },titleInputFiled:{
      fontSize:'14px',
      fontWeight:'600',
      lineHeight:'20px',
      color:theme.palette.text.secondary,
      maxWidth :'280px',
      minWidth :'160px' 
    },containerInputFileds:{
      display:'flex',
      flexDirection:'row',
      gap :'16px',
      width:'100%',
    },containerSizeInputFiled:{
      display:'flex',
      flexDirection:'column',
      gap:'20px'
    },containerAgencyfeeFileds:{
      display:'flex',
      flexDirection:'row',
      gap:'16px',
      alignItems:'center'
    },containerPriceAndPercentageSection:{
     display:'flex',
      flexDirection:'column',
      gap:"16px"
    }
  }
})