import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './BedsAndBathsFilter.scss';
import { ButtonBase } from '@material-ui/core';
import {
  DropdownIconWrapper,
  StyledButton,
  StyledMenu,
  StyledMenuItem,
} from './utilities';

const RadioItem = ({ value, selected, onClick }) => (
  <span
    className={`radio-item-filter ${selected ? 'selected' : ''}`}
    onClick={onClick}
  >
    {value}
  </span>
);

export const BedsAndBathFilter = ({
  parentTranslationPath,
  selected,
  setSelected,
  restBedAndBath,
  filterBadsAndBaths,
}) => {
  const { t } = useTranslation(parentTranslationPath);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const values = useMemo(() => Array.from({ length: 8 }, (_, i) => i + 1), []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (event) => {
    if (anchorEl && anchorEl.contains(event?.target)) {
      return;
    }
    setAnchorEl(null);
    setOpen(false);
  };
  const handleReset = () => {
    restBedAndBath();
  };

  const handleSelection = async (type, value) => {
    const types = {
      bedrooms: {
        from: 'bedrooms_from',
        to: 'bedrooms_to',
      },
      bathrooms: {
        from: 'bathrooms_from',
        to: 'bathrooms_to',
      },
    };

    if (value === 8) {
      setSelected({
        id: type,
        value: { from: value, to: 10000 },
      });
      filterBadsAndBaths(types[type], { from: value, to: 10000 });
    } else if (value < selected[type]?.from || value === null) {
      setSelected({
        id: type,
        value: { from: value, to: value },
      });
      await filterBadsAndBaths(types[type], { from: value, to: value });
    } else if (selected[type]?.from) {
      setSelected({
        id: type,
        value: { from: selected[type].from, to: value },
      });
      await filterBadsAndBaths(types[type], {
        from: selected[type].from,
        to: value,
      });
    } else {
      setSelected({
        id: type,
        value: { from: value, to: value },
      });
      await filterBadsAndBaths(types[type], { from: value });
    }
  };

  const getButtonLabel = () => {
    const { bedrooms, bathrooms } = selected;

    
    const formatLabel = (type, label) => {
      if (type?.from === null && type?.to === null) {
        return `0 ${label}`;
      }
      if (type?.from === 8 && type?.to === 10000) {
        return `+8 ${label}`;
      }
      return  type?.from
      ? type.from === type.to
        ? `${type.from} ${label}`
        : `${type.from} - ${type.to} ${label}`
      : '';
    }
   

    const bedLabel = formatLabel(bedrooms, 'Beds');
    const bathLabel = formatLabel(bathrooms, 'Baths');

    if (!bedLabel && !bathLabel) {
      return 'beds & baths';
    }

    return `${bedLabel}${
      bathLabel ? (bedLabel ? `; ${bathLabel}` : `${bathLabel}`) : ''
    }`;
  };

  return (
    <div>
      <StyledButton
        aria-controls='customized-menu'
        aria-haspopup='true'
        variant='outlined'
        color='primary'
        onClick={handleClick}
      >
        <span className='bed-bath-button'>{getButtonLabel()}</span>
        <DropdownIconWrapper
            className={`mdi ${open ? 'mdi-chevron-up' : 'mdi-chevron-down'}`}
          />
      </StyledButton>

      <StyledMenu
        id='customized-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div className='filter-section-wrapper'>
          <legend className='filter-section-title'>{t('bedrooms')}</legend>
          <div className='values-row'>
          <StyledMenuItem
              onClick={() => handleSelection('bedrooms', null)}
            >
              <RadioItem
                value={'Any'}
                selected={selected.bedrooms?.from === null || selected.bedrooms?.to === null}
              />
            </StyledMenuItem>
            {values.map((i) => (
              <StyledMenuItem
                key={`bedroom-${i}`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelection('bedrooms', i);
                }}
              >
                <RadioItem
                  value={i === 8 ? '+8' : i}
                  selected={
                    selected.bedrooms?.from <= i && selected.bedrooms?.to >= i
                  }
                />
              </StyledMenuItem>
            ))}
          </div>
        </div>

        <div className='filter-section-wrapper'>
          <legend className='filter-section-title'>{t('bathrooms')}</legend>
          <div className='values-row'>
            <StyledMenuItem
              onClick={() => handleSelection('bathrooms', null)}
            >
              <RadioItem
                value={'Any'}
                selected={selected.bathrooms?.from === null || selected.bathrooms?.to === null}
              />
            </StyledMenuItem>
            {values.map((i) => (
              <StyledMenuItem
                key={`bathroom-${i}`}
                onClick={() => handleSelection('bathrooms', i)}
              >
                <RadioItem
                  value={i === 8 ? '+8' : i}
                  selected={
                    selected.bathrooms?.from <= i && selected.bathrooms?.to >= i
                  }
                />
              </StyledMenuItem>
            ))}
          </div>
        </div>
        <div className='d-flex fj-end'>
          <ButtonBase
            className='btns'
            onClick={() => {
              handleReset();
            }}
          >
            {t('Reset')}
          </ButtonBase>
        </div>
      </StyledMenu>
    </div>
  );
};
