import React, { useRef, useState } from "react";

const useLocationSelectFields = (
  localFormSelection,
  localFormChangeHandler
) => {
  const isCachedDataFilled = useRef({
    nearestLandmarks: false,
    facilitiesAndAmenities: false,
  });

  const [selectedItems, setSelectedItems] = useState({
    nearestLandmarks: [],
    facilitiesAndAmenities: [],
  });

  const fillCachedLandmarks = () => {
    if (!isCachedDataFilled.current.nearestLandmarks) {
      setSelectedItems((prevState) => ({
        ...prevState,
        nearestLandmarks: localFormSelection.selectednNearestLandmarks,
      }));
      isCachedDataFilled.current.nearestLandmarks = true;
    }
  };

  const fillCachedFacilities = () => {
    if (!isCachedDataFilled.current.facilitiesAndAmenities) {
      setSelectedItems((prevState) => ({
        ...prevState,
        facilitiesAndAmenities:
          localFormSelection.selectedFacilitiesAndAmenities,
      }));
      isCachedDataFilled.current.facilitiesAndAmenities = true;
    }
  };

  const landmarksChangeHandler = () => {
    if (isCachedDataFilled.current.nearestLandmarks)
      localFormChangeHandler(
        "selectednNearestLandmarks",
        selectedItems.nearestLandmarks
      );
    else fillCachedLandmarks();
  };

  const facilitiesChangeHandler = () => {
    if (isCachedDataFilled.current.facilitiesAndAmenities)
      localFormChangeHandler(
        "selectedFacilitiesAndAmenities",
        selectedItems.facilitiesAndAmenities
      );
    else fillCachedFacilities();
  };

  return {
    selectedItems,
    setSelectedItems,
    facilitiesChangeHandler,
    landmarksChangeHandler,
  };
};

export default useLocationSelectFields;
