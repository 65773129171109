import { getUrlFromFileObj } from "../filesHelpers";

const floorPlanDtoMapper = (property) => {
    const floorPlans = property.submittedForm?.FloorPlan?.floorPlanImages || [];
  
    const mappedFloorPlans = getUrlFromFileObj(floorPlans);
    
    return mappedFloorPlans;
  };
  
  export default floorPlanDtoMapper;
  