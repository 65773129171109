import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import SharetUnitsCardsComponentV2 from './SharetUnitsUtilties/SharetUnitsCardsComponentV2';
import './styles.scss';
import {
  GetAllListingAgentsServices,
  GetLookupItemsByLookupTypeName,
  ShareUnitsSearchAPI,
} from '../../../Services';
import { useTranslation } from 'react-i18next';
import { useTitle } from '../../../Hooks';
import { isAdminInquiries } from '../../../Helper/isAdmin.Helper';
import {
  AutocompleteComponent,
  DatePickerComponent,
  Inputs,
  NoContentComponent,
  PaginationComponent,
  SelectComponet,
  Spinner,
  ViewTypes,
} from '../../../Components';
import { ButtonBase } from '@material-ui/core';
import { SearchIcon } from '../../../assets/icons';
import BedroomsFilter from './SharetUnitsUtilties/BedroomsFilter';
import PriceFilterShareUnit from './SharetUnitsUtilties/PriceFilterShareUnit ';
import SizeRange from './SharetUnitsUtilties/SizeRange';
import moment from 'moment';
import { LocationFieldsComponentShareUnit } from './SharetUnitsUtilties/LocationFieldsComponentShareUnit';
import { useDispatch, useSelector } from 'react-redux';
import { GlobalOrderFilterActions } from '../../../store/GlobalOrderFilter/GlobalOrderFilterActions';
import {
  bottomBoxComponentUpdate,
  returnPropsByPermissions,
} from '../../../Helper';
import { QACallCenterPermissions } from '../../../Permissions';
import { TableFilterOperatorsEnum, ViewTypesEnum } from '../../../Enums';
import { ShareUnitTableComponent } from '../ShareUnit/ShareUnitUtitities/ShareUnitTableComponent';
import SharedUnitsDialogV2 from './SharetUnitsUtilties/SharedUnitsDialogV2';

const parentTranslationPath = 'ShareUnit';

export default function ShareUnitViewV2() {
  const { t, i18n } = useTranslation(parentTranslationPath);
  useTitle(t('share-unit'));
  const searchTimer = useRef(null);
  const orderFilter = useSelector((state) => state.GlobalOrderFilterReducer);
  const [activeActionType, setActiveActionType] = useState(
    ViewTypesEnum.cards.key
  );

  const [selectedOrderBy, setSelectedOrderBy] = useState({
    orderBy: orderFilter?.ShareUnitFilter?.orderBy || null,
  });
  const [orderBy, setOrderBy] = useState(null);
  const isAdminInquiriesValue = isAdminInquiries();
  const dispatch = useDispatch();
  const defaultFilterValues = {
    pageIndex: 0,
    pageSize: parseInt(localStorage.getItem('Pagination')) || 25,
    criteria: {},
    filterBy: 'CreationDate',
    orderBy: selectedOrderBy?.orderBy || null,
    operationType: null,
    isAdmin: isAdminInquiriesValue,
  };
  const [filter, setFilter] = useState(defaultFilterValues);
  const reducer = useCallback((state, action) => {
    switch (action.type) {
      case 'edit':
        return {
          ...action.value,
        };
      case 'reset':
        const resetState = {};
        Object.keys(state).forEach((key) => {
          resetState[key] = '';
        });
        return resetState;
      default:
        return {
          ...state,
          [action.id]: action.value ?? '',
        };
    }
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [activeItem, setActiveItem] = useState(null);
  const [isSharedDialogOpen, setIsSharedDialogOpen] = useState(false);

  const [isClearFiltersClicked, setIsClearFiltersClicked] = useState(false);
  const [shareTableFilter, setShareTableFilter] = useState({});
  const [searchSharedBy, setSearchSharedBy] = useState('');
  const [selected, setSelected] = useReducer(reducer, {
    bedrooms: {
      from: 0,
      to: 0,
    },
    price: {
      from: 0,
      to: 0,
    },
    size: {
      from: 0,
      to: 0,
    },
    unitType: null,
    listingAagent: null,
    operationType: null,
  });
  const [shareUnitData, setShareUnitData] = useState({
    result: [],
    totalCount: 0,
  });
  const [data, setData] = useReducer(reducer, {
    properties: [],
    unitTypes: [],
    unitRef: [],
    saleType: [],
    operationType: [],
    listingAagent: [],
    completionStatus: [],
  });

  const getUnitType = async () => {
    const res = await GetLookupItemsByLookupTypeName({
      lookUpName: 'UnitType',
      pageSize: 100,
      pageIndex: 1,
    });
    if (!(res && res.status && res.status !== 200)) {
      setData({ id: 'unitTypes', value: res.result || [] });
    } else setData({ id: 'unitTypes', value: [] });
  };
  const getAllAgents = useCallback(async (search) => {
    const res = await GetAllListingAgentsServices({
      pageIndex: 0,
      pageSize: 50,
      search: search || '',
    });

    if (!(res && res.status && res.status !== 200))
      setData({
        id: 'listingAagent',
        value: res.result,
      });
    else
      setData({
        id: 'listingAagent',
        value: [],
      });
  }, []);

  const GetAllShareUnitsData = useCallback(async () => {
    const params = {
      ...filter,
      criteria: {},
    };

    // Helper function to add criteria
    const addCriterion = (key, value, searchType) => {
      if (searchType === 13) {
        params.criteria[key] = [{ searchType }]; // Add only searchType 13, no value
      } else if (value) {
        params.criteria[key] = [{ searchType, value }];
      } else {
        delete params.criteria[key]; // Remove the key if value is falsy
      }
    };

    // Adding search value conditionally
    if (searchValue) {
      addCriterion('unit_ref_no', searchValue, 2);
    } else {
      delete params.criteria.unit_ref_no;
    }

    if (filterCriteria) {
      // Iterate over all filter criteria and apply them dynamically
      const criteriaKeys = [
        'city',
        'country',
        'district',
        'community',
        'subCommunity',
        'property_name',
        'unit_type',
        'sale_type',
        'operation_type',
        'CompletionStatus',
        'listing_agent',
        'fromSellingPrice',
        'toSellingPrice',
        'fromSize',
        'toSize',
        'fromBedroom',
        'toBedroom',
        'sharedBy',
        'sharedOn',
        'unitOperationSource',
      ];

      criteriaKeys.forEach((key) => {
        if (filterCriteria[key]) {
          addCriterion(
            key,
            filterCriteria[key][0]?.value || '',
            filterCriteria[key][0]?.searchType
          );
        } else {
          delete params.criteria[key];
        }
      });
      if (shareTableFilter) {
        Object.values(shareTableFilter)
          .filter((item) => item?.searchableKey || item?.displayPath)
          .map((item) => {
            if (params.criteria[item.searchableKey || item.displayPath]) {
              params.criteria[item.searchableKey || item.displayPath].push({
                searchType: item.operator,
                value: item.value,
              });
            } else if (item?.value) {
              params.criteria[item.searchableKey || item.displayPath] = [
                {
                  searchType: item.operator,
                  value: item.value,
                },
              ];
            } else if (
              !item.value &&
              (item.operator === TableFilterOperatorsEnum.isNotBlank.key ||
                item.operator === TableFilterOperatorsEnum.isBlank.key)
            ) {
              params.criteria[item.searchableKey || item.displayPath] = [
                {
                  searchType: item.operator,
                  value: null,
                },
              ];
            } else if (item?.value === '') {
              delete params.criteria[item.searchableKey || item.displayPath];
            }
            return undefined;
          });
      }

      if (filterCriteria.sharedOn) {
        addCriterion('sharedOn', filterCriteria.sharedOn[0]?.value, 10);
      }
    } else {
      const allCriteria = Object.keys(params.criteria);
      allCriteria.forEach((key) => delete params.criteria[key]);
    }
    setIsLoading(true);
    const result = await ShareUnitsSearchAPI(
      {
        pageSize: filter.pageSize,
        pageIndex: filter.pageIndex,
      },
      params
    );

    const updatedResult = {
      result: result.result,
      totalCount: result.totalCount || 0,
    };

    if (result && result.status && result.status !== 200) {
      setShareUnitData({ result: [], totalCount: 0 });
    } else {
      setShareUnitData(updatedResult);
    }
    setIsClearFiltersClicked(false);
    setIsLoading(false);
  }, [filter, searchValue, filterCriteria, shareTableFilter]);
  const filterCriteriaChangeHandler = (filterKey, filterValue, searchType) => {
    if (filterValue) {
      const newFilterItem = {
        [filterKey]: [
          {
            searchType,
            value: filterValue,
          },
        ],
      };
      const newFilterCriteria = {
        ...(filterCriteria || {}),
        ...newFilterItem,
      };
      setFilterCriteria(newFilterCriteria);
    } else {
      const newFilterCriteria = {
        ...(filterCriteria || {}),
      };
      delete newFilterCriteria[filterKey];
      setFilterCriteria(newFilterCriteria);
    }
  };

  const bedroomsCriteriaChangeHandler = (filterKeys, filterValue) => {
    let newFilter = { ...filterCriteria };

    if (filterValue.from && !filterValue.to) {
      newFilter[filterKeys.from] = [
        { searchType: 1, value: filterValue.from.toString() },
      ];
    } else if (filterValue.from === null && filterValue.to === null) {
      newFilter[filterKeys.from] = [{ searchType: 13 }];
      newFilter[filterKeys.to] = [{ searchType: 13 }];
    } else if (filterValue.from && filterValue.to) {
      newFilter[filterKeys.from] = [
        { searchType: 3, value: filterValue.from.toString() },
      ];
      newFilter[filterKeys.to] = [
        { searchType: 4, value: filterValue.to.toString() },
      ];
    }

    setFilterCriteria(newFilter);
  };
  const PriceAndSizeChangeHandler = (key, value) => {
    let newFilter = { ...filterCriteria };
    if (value.from && !value.to) {
      newFilter[`from${key}`] = [
        { searchType: 3, value: value.from.toString() },
      ];
    } else if (value.from && value.to) {
      newFilter[`from${key}`] = [
        { searchType: 3, value: value.from.toString() },
      ];
      newFilter[`to${key}`] = [{ searchType: 4, value: value.to.toString() }];
    }
    setFilterCriteria(newFilter);
  };
  const orderByChanged = (value) => {
    setSelectedOrderBy({ orderBy: value });

    dispatch(
      GlobalOrderFilterActions.globalOrderFilterRequest({
        ...orderFilter,
        ShareUnitFilter: {
          orderBy: value,
        },
      })
    );

    setOrderBy({ orderBy: value });
  };
  const onPageIndexChanged = (pageIndex) => {
    setFilter((item) => ({ ...item, pageIndex }));
  };
  const onPageSizeChanged = (pageSize) => {
    setFilter((item) => ({ ...item, pageIndex: 0, pageSize }));
  };
  useEffect(() => {
    if (
      returnPropsByPermissions(
        QACallCenterPermissions.ViewQaActivitiesWithLeadInfo.permissionsId
      )
    ) {
      bottomBoxComponentUpdate(
        <PaginationComponent
          pageIndex={filter.pageIndex}
          pageSize={filter.pageSize}
          totalCount={shareUnitData.totalCount}
          onPageIndexChanged={onPageIndexChanged}
          onPageSizeChanged={onPageSizeChanged}
        />
      );
    }
  });
  useEffect(() => {
    if (selectedOrderBy.orderBy !== null) {
      setFilter((prev) => ({
        ...prev,
        orderBy: selectedOrderBy.orderBy,
      }));
    }
  }, [selectedOrderBy.orderBy]);

  useEffect(() => {
    GetAllShareUnitsData();
  }, [filterCriteria, shareTableFilter, filter]);
  useEffect(
    () => () => {
      bottomBoxComponentUpdate(null);
    },
    []
  );
  const onClearedFiltersClicked = useCallback(() => {
    dispatch(GlobalOrderFilterActions.globalOrderFilterRequest({}));
    setOrderBy({ orderBy: null });
    setFilterCriteria({});
    setSelected({ type: 'reset' });
    setSelectedOrderBy({
      orderBy: null,
    });
    setSearchValue('');
    setSearchSharedBy('');
    setShareTableFilter({});
    setIsClearFiltersClicked(true);
    // setSavedShareUnitCriteriaIdValue('');
  }, [dispatch]);
  const onTypeChanged = useCallback(
    (activeType) => {
      setActiveActionType(activeType);
    },
    [setActiveActionType]
  );
  const onFilterValuesChanged = (newValue) => {
    setShareTableFilter(newValue);
  };
  useEffect(() => {
    if (orderBy)
      setFilter((item) => ({
        ...item,
        orderBy: orderBy.orderBy,
      }));
  }, [orderBy]);

  return (
    <>
      <Spinner isActive={isLoading} />
      <div className='share-unit-container'>
        <div className='share-unit-filter-section1'>
          <div
            className='share-unit-filter-left'
            dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}
          >
            <ButtonBase
              className={`type-btn rent-btn ${
                selected?.operationType === 'Rent' ? 'selected-btn' : ''
              }`}
              onClick={() => {
                setSelected({ id: 'operationType', value: 'Rent' });
                const operationTypeKey = 'unitOperationSource';
                const operationTypeValue = '2';
                const searchType = 2;

                filterCriteriaChangeHandler(
                  operationTypeKey,
                  operationTypeValue,
                  searchType
                );
              }}
            >
              {t('rent')}
            </ButtonBase>
            <ButtonBase
              className={`type-btn  ${
                selected?.operationType === 'Sale' ? 'selected-btn' : ''
              }`}
              onClick={() => {
                setSelected({ id: 'operationType', value: 'Sale' });
                const operationTypeKey = 'unitOperationSource';
                const operationTypeValue = '1';
                const searchType = 2;

                filterCriteriaChangeHandler(
                  operationTypeKey,
                  operationTypeValue,
                  searchType
                );
              }}
            >
              {t('buy')}
            </ButtonBase>
            <ButtonBase
              className={`type-btn all-btn ${
                selected?.operationType === 'All' ? 'selected-btn' : ''
              }`}
              onClick={() => {
                setSelected({ id: 'operationType', value: 'All' });
                filterCriteriaChangeHandler('unitOperationSource', null, null);
              }}
            >
              {t('all')}
            </ButtonBase>
          </div>
          <div className='share-unit-filter-right'>
            <Inputs
              idRef='unitRef'
              fieldClasses='unit-ref-content'
              inputPlaceholder='search-by-unit-reference-number'
              wrapperClasses='unit-ref-input'
              parentTranslationPath={parentTranslationPath}
              value={searchValue}
              onInputChanged={(e) => {
                setSearchValue(e?.target?.value || '');
              }}
              onKeyUp={(event) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                const newValue = event.target.value;
                searchTimer.current = setTimeout(() => {
                  setFilter({
                    ...filter,
                    id: 'contactId',
                    value: newValue,
                  });
                }, 700);
              }}
              startAdornment={
                <SearchIcon
                  width='24'
                  height='24'
                  fill='#8E8E93'
                />
              }
            />
            <PriceFilterShareUnit
              PriceAndSizeChangeHandler={PriceAndSizeChangeHandler}
              selected={selected}
              setSelected={setSelected}
              t={t}
            />
            <SizeRange
              PriceAndSizeChangeHandler={PriceAndSizeChangeHandler}
              selected={selected}
              setSelected={setSelected}
              t={t}
            />
            <BedroomsFilter
              selected={selected}
              setSelected={setSelected}
              bedroomsCriteriaChangeHandler={bedroomsCriteriaChangeHandler}
              t={t}
            />
            <Inputs
              idRef='sharedbyRef'
              parentTranslationPath={parentTranslationPath}
              inputPlaceholder='shared-by'
              fieldClasses='shared-by-content'
              wrapperClasses='shared-by-input'
              value={searchSharedBy}
              onInputChanged={(e) => {
                setSearchSharedBy(e?.target?.value || '');
              }}
              onKeyUp={(event) => {
                if (searchTimer.current) clearTimeout(searchTimer.current);
                const newValue = event.target.value;
                searchTimer.current = setTimeout(() => {
                  const sharedbyKey = 'sharedBy';
                  const sharedbyValue = newValue || null;
                  const searchType = 2;
                  filterCriteriaChangeHandler(
                    sharedbyKey,
                    sharedbyValue,
                    searchType
                  );
                }, 600);
              }}
            />
            <div className='styled-data-picker'>
              <DatePickerComponent
                idRef='DateOfBirthRef'
                placeholder='DD/MM/YYYY'
                value={selected.sharedOn || null}
                onDateChanged={(newValue) => {
                  setSelected({ id: 'sharedOn', value: newValue });
                  const sharedOnKey = 'sharedOn';
                  const sharedOnValue =
                    (newValue && moment(newValue).format('YYYY-MM-DD')) || null;
                  const searchType = 1;
                  filterCriteriaChangeHandler(
                    sharedOnKey,
                    sharedOnValue,
                    searchType
                  );
                }}
              />
            </div>
            <div>
              <SelectComponet
                idRef='orderByRef'
                data={[
                  { id: 1, orderBy: 'ascending' },
                  { id: 2, orderBy: 'descending' },
                ]}
                emptyItem={{
                  value: null,
                  text: 'Order by',
                  isDisabled: false,
                  isHiddenOnOpen: false,
                }}
                value={selectedOrderBy.orderBy}
                onSelectChanged={orderByChanged}
                wrapperClasses='StyledSelected'
                valueInput='id'
                textInput='orderBy'
                parentTranslationPath={parentTranslationPath}
              />
            </div>
            <div className='view-search-wrapper-shared-unit'>
              <ViewTypes
                onTypeChanged={onTypeChanged}
                activeTypes={[
                  ViewTypesEnum.tableView.key,
                  ViewTypesEnum.cards.key,
                ]}
                className='mb-3'
              />
              <ButtonBase
                onClick={onClearedFiltersClicked}
                id='onClearedAllFiltersref'
                className='btns theme-solid bg-danger clear-all-btn clear-shared'
              >
                <span className='mdi mdi-filter-remove m-1' />
              </ButtonBase>
            </div>
          </div>
        </div>
        <div className='share-unit-filter-section2'>
          <div className='share-units-filter-left'>
            <AutocompleteComponent
              idRef='unitTypeRef'
              wrapperClasses='styledAutoCompleteRef'
              inputClasses='widthAutoComplete'
              inputPlaceholder={t('unit-Type')}
              selectedValues={selected.unitType || {}}
              data={data.unitTypes || []}
              displayLabel={(option) => option.lookupItemName || ''}
              multiple={false}
              withoutSearchButton
              onChange={(_, newValue) => {
                setSelected({ id: 'unitType', value: newValue });
                const unitTypeKey = 'unit_type';
                const unitTypeValue = newValue?.lookupItemName || null;
                const searchType = 1;

                filterCriteriaChangeHandler(
                  unitTypeKey,
                  unitTypeValue,
                  searchType
                );
              }}
              onOpen={() => {
                if (data.unitTypes.length === 0) getUnitType();
              }}
            />
            <AutocompleteComponent
              idRef='listingAgentRef'
              wrapperClasses='styledAutoCompleteRef'
              inputClasses='widthAutoComplete'
              inputPlaceholder={t('listing-agent')}
              selectedValues={selected.listingAagent || {}}
              data={data.listingAagent}
              onOpen={() => {
                if (data.listingAagent.length === 0) getAllAgents();
              }}
              chipsLabel={(option) => option.agentName || ''}
              displayLabel={(option) => option.agentName || ''}
              multiple={false}
              withoutSearchButton
              onInputKeyUp={(event) => {
                const { value } = event.target;
                if (searchTimer.current) clearTimeout(searchTimer.current);
                searchTimer.current = setTimeout(() => {
                  getAllAgents(value);
                }, 700);
              }}
              onChange={(_, newValue) => {
                setSelected({ id: 'listingAagent', value: newValue });
                const listingAagentKey = 'listing_agent';
                const listingAagentValue = newValue?.agentName || null;
                const searchType = 1;

                filterCriteriaChangeHandler(
                  listingAagentKey,
                  listingAagentValue,
                  searchType
                );
              }}
            />
          </div>
          <div className='share-unit-filter-right'>
            <LocationFieldsComponentShareUnit
              parentTranslationPath={parentTranslationPath}
              isClearFiltersClicked={isClearFiltersClicked}
              filterCriteriaChangeHandler={filterCriteriaChangeHandler}
              onChangeHandlers={{
                countryHandler: (newValue) => {
                  const countryKey = 'country';
                  const countryValue =
                    (newValue && newValue?.lookupItemName) || null;
                  const searchType = 1;

                  filterCriteriaChangeHandler(
                    countryKey,
                    countryValue,
                    searchType
                  );
                  setSelected({
                    id: 'country',
                    value: newValue,
                  });
                },
                cityHandler: (newValue) => {
                  const cityKey = 'city';
                  const cityValue =
                    (newValue && newValue?.lookupItemName) || null;
                  const searchType = 1;

                  filterCriteriaChangeHandler(cityKey, cityValue, searchType);
                  setSelected({
                    id: 'city',
                    value: newValue,
                  });
                },
                districtHandler: (newValue) => {
                  const districtKey = 'district';
                  const districtValue =
                    (newValue && newValue?.lookupItemName) || null;
                  const searchType = 1;

                  filterCriteriaChangeHandler(
                    districtKey,
                    districtValue,
                    searchType
                  );
                  setSelected({
                    id: 'district',
                    value: newValue,
                  });
                },
                communityHandler: (newValue) => {
                  const communityKey = 'community';
                  const communityValue =
                    (newValue && newValue?.lookupItemName) || null;
                  const searchType = 1;

                  filterCriteriaChangeHandler(
                    communityKey,
                    communityValue,
                    searchType
                  );
                  setSelected({
                    id: 'community',
                    value: newValue,
                  });
                },

                subCommunityHandler: (newValue) => {
                  const subCommunityKey = 'subCommunity';
                  const subCommunityValue =
                    (newValue && newValue?.lookupItemName) || null;
                  const searchType = 1;

                  filterCriteriaChangeHandler(
                    subCommunityKey,
                    subCommunityValue,
                    searchType
                  );
                  setSelected({
                    id: 'subCommunity',
                    value: newValue,
                  });
                },
              }}
            />
          </div>
        </div>
        <div className='w-100 px-3'>
          {activeActionType === ViewTypesEnum.tableView.key && (
            <ShareUnitTableComponent
              filter={filter}
              data={shareUnitData?.result || []}
              parentTranslationPath={parentTranslationPath}
              shareTableFilter={shareTableFilter}
              setOrderBy={setOrderBy}
              onFilterValuesChanged={onFilterValuesChanged}
              isClearFiltersClicked={isClearFiltersClicked}
              setIsClearFiltersClicked={setIsClearFiltersClicked}
              setActiveItem={setActiveItem}
              setIsOpenReassign={setIsSharedDialogOpen}
              // setIsDeleteDialogOpen={() => setIsDeleteDialogOpen(true)}
            />
          )}

          {activeActionType === ViewTypesEnum.cards.key && (
            <div className='share-units-cards'>
              {shareUnitData?.result?.length ? (
                <SharetUnitsCardsComponentV2
                  data={shareUnitData}
                  parentTranslationPath={parentTranslationPath}
                  t={t}
                />
              ) : (
                <NoContentComponent />
              )}
            </div>
          )}
        </div>
      </div>
      {isSharedDialogOpen && (
        <SharedUnitsDialogV2
          activeItem={activeItem}
          isOpen={isSharedDialogOpen}
          parentTranslationPath={parentTranslationPath}
          t={t}
          onClose={() => {
            setIsSharedDialogOpen(false);
            setActiveItem(null);
          }}
        />
      )}
    </>
  );
}
